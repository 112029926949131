import {
  Button,
  Container,
  Grid,
  TextField,
  Typography
} from "@material-ui/core";
import axios from "axios";
import { Form, FormikProvider, useFormik } from "formik";
import React, { useEffect, useState } from "react";
import * as yup from "yup";
import { emailSetting } from "../../api/api";
import { FlexView } from "../../commonComponents/StyledComponents";
import {
  ROLE_BASED_PAGES,
  getRoleBasedAccess
} from "../../utils/constants/roleBasedAccess";
import ClientSettings from "./ClientSettings";
import ContactSupport from "./ContactSupport";
import HiringManagerSettings from "./HiringManagerSetting";
import PanelSettings from "./PanelSettings";
import RecruiterSettings from "./RecruiterSettings";

const validationSchema = yup.object({
  manager_email: yup
    .string()
    .nullable()
    .email("Enter a valid email")
    .optional(),
  admin_email: yup.string().nullable().email("Enter a valid email").optional(),
  support_email: yup
    .string()
    .nullable()
    .email("Enter a valid email")
    .optional(),
});

function Settings() {
  const role = localStorage.getItem("role");
  const {
    isAllowClientSetting = true,
    isAllowAdminSetting = true,
    isAllowRecruiterSetting = true,
    isAllowHiringManagerSetting = true,
    isAllowPanelMemberSetting = true,
  } = getRoleBasedAccess(role, ROLE_BASED_PAGES.settingsLanding);

  const [success, setSuccess] = useState(false);
  const [contactSupport, setContactSupport] = useState(false);

  const getAndSetEmailSettings = () => {
    axios.get(emailSetting()).then((response) => {
      if (response.status === 200) {
        if (response.data.length > 0) {
          setValues({
            ...response.data[0],
          });
        }
      }
    });
  };

  useEffect(() => {
    if (isAllowAdminSetting) getAndSetEmailSettings();
  }, []);

  const formik = useFormik({
    initialValues: {
      manager_email: "",
      admin_email: "",
      id: "",
      support_email: "",
    },
    validationSchema: validationSchema,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });

  function handleSubmit(validValues) {
    let requestBody = {};
    requestBody.manager_email = validValues.manager_email || null;
    requestBody.admin_email = validValues.admin_email || null;
    requestBody.support_email = validValues.support_email || null;
    if (values.id) {
      requestBody.id = values.id;
    }
    axios.post(emailSetting(), requestBody).then((response) => {
      if (response.status === 201) {
        setValues({ ...response.data });
        setSuccess(true);
      }
    });
  }

  const { values, touched, errors, setValues, handleBlur, handleChange } =
    formik;

  const handleContactSupportOpen = () => {
    setContactSupport(true);
  };

  const handleContactSupportClose = () => {
    setContactSupport(false);
  };

  return (
    <Container maxWidth={"md"} className={"mt-8"}>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <FlexView justifyContent={"space-between"}>
            <Typography>
              <b>{"Settings"}</b>
            </Typography>
            <Button
              size={"large"}
              variant={"contained"}
              color={"primary"}
              onClick={handleContactSupportOpen}
            >
              {"Contact Support"}
            </Button>
          </FlexView>
        </Grid>
        {isAllowAdminSetting ||
          isAllowClientSetting ||
          isAllowRecruiterSetting ? (
          <Grid item xs={6}>
            {/* Admin Settings */}
            {isAllowAdminSetting ? (
              <FormikProvider value={formik}>
                <Form>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Typography>
                        <b>{"Admin Settings"}</b>
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        name={"admin_email"}
                        fullWidth
                        variant={"outlined"}
                        label={"Admin Mail Id"}
                        value={values.admin_email || ""}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={
                          touched.admin_email && Boolean(errors.admin_email)
                        }
                        helperText={touched.admin_email && errors.admin_email}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        name={"manager_email"}
                        fullWidth
                        variant={"outlined"}
                        label={"Manager Mail Id"}
                        value={values.manager_email || ""}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={
                          touched.manager_email && Boolean(errors.manager_email)
                        }
                        helperText={
                          touched.manager_email && errors.manager_email
                        }
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        name={"support_email"}
                        fullWidth
                        variant={"outlined"}
                        label={"Support Mail Id"}
                        value={values.support_email || ""}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={
                          touched.support_email && Boolean(errors.support_email)
                        }
                        helperText={
                          touched.support_email && errors.support_email
                        }
                      />
                    </Grid>
                    <Grid item xs={12} container alignItems="center">
                      <Button
                        size="large"
                        type={"submit"}
                        variant={"contained"}
                        color={"primary"}
                      >
                        Submit
                      </Button>
                    </Grid>
                    <Grid item xs={12} container alignItems="center">
                      <Typography
                        style={{
                          color: "#008000",
                          visibility: success ? "visible" : "hidden",
                        }}
                      >
                        {success ? "Saved successfully" : "_"}
                      </Typography>
                    </Grid>
                  </Grid>
                </Form>
                <br />
              </FormikProvider>
            ) : null}
            {/* Client Settings */}
            {isAllowClientSetting ? <ClientSettings /> : null}
            {/* Recruiter Settings */}
            {isAllowRecruiterSetting ? <RecruiterSettings /> : null}
          </Grid>
        ) : null}
        {isAllowHiringManagerSetting || isAllowPanelMemberSetting ? (
          <Grid item xs={6}>
            {/* Hiring Manager Settings */}
            {isAllowHiringManagerSetting ? <HiringManagerSettings /> : null}
            {/* Panel Member Settings */}
            {isAllowPanelMemberSetting ? <PanelSettings /> : null}
          </Grid>
        ) : null}
      </Grid>
      <ContactSupport
        open={contactSupport}
        onClose={handleContactSupportClose}
      />
    </Container>
  );
}

export default Settings;
