import React, { useEffect, useRef } from "react";
import { useDownloadExcel } from "react-export-table-to-excel";
import "./style.css";

const TitleMatchReport = (props) => {
  const tableRef = useRef(null);

  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: "Title Match Report",
    sheet: "Report",
  });

  useEffect(() => {
    if (props.isClicked) {
      onDownload();
    }
  }, [props.isClicked]);

  const formatJson = (resumeArr, resumeNames) => {
    let resumeArrLength = resumeArr.length;

    //
    let titleMatchTableContentArr = [];
    for (let skillIndex = -1; skillIndex < 30; skillIndex++) {
      let tableRowArr = [];
      let nonEmptyFields = 0;
      for (
        let resumeArrIndex = 0;
        resumeArrIndex < resumeArrLength;
        resumeArrIndex++
      ) {
        if (resumeArr[resumeArrIndex].skills_display[skillIndex]) {
          tableRowArr.push(
            resumeArr[resumeArrIndex].skills_display[skillIndex]
          );
          nonEmptyFields++;
        } else tableRowArr.push(" ");
      }
      if (nonEmptyFields > 0) {
        let commanSkillName = tableRowArr[0].skill_name;
        tableRowArr.unshift({
          skill_name: commanSkillName,
          isSkillDisplay: true,
        });
        tableRowArr.splice(1, 0, {
          skill_name: commanSkillName,
          isLabel: true,
        });

        titleMatchTableContentArr.push(tableRowArr);
      }
    }
    //

    return titleMatchTableContentArr;
  };

  let { titleMatchResumeList, resumeNames, userNameList, inferred_title } =
    props;

  let titleMatchTableContentArr = [];
  // if (titleMatchResumeList)
  //   titleMatchTableContentArr = formatJson(titleMatchResumeList, resumeNames);
  console.log("titleMatchTableContentArr: ", titleMatchTableContentArr);
  console.log(resumeNames, "resumeNames");
  console.log(inferred_title, "inferred_title");

  return (
    <div className={"fixedTableHead"}>
      <table className="skillTable" ref={tableRef}>
        <thead className="boldPara stickyHead">
          <tr>
            <th colSpan="2" className="headWidth">
              Skills
            </th>
            {/* <th colSpan="2" className="headWidth"></th> */}

            {resumeNames.length > 0 &&
              resumeNames.map((rowValues, rowIndex) => (
                <th key={rowIndex} className="boldPara headWidth" colSpan="2">
                  <p className="whitespace-pre-line">{rowValues}</p>
                </th>
              ))}
          </tr>
          {inferred_title.length > 0 && (
            <tr>
              <th colSpan="2">Inferred Title</th>
              {/* <th colSpan="2"></th> */}

              {inferred_title.map((elem, ind) => {
                return (
                  <th key={ind} className="boldPara" colSpan="2">
                    <p className="whitespace-pre-line">{elem ? elem : "-"}</p>
                  </th>
                );
              })}
            </tr>
          )}
        </thead>
        {/* <tbody>
          {titleMatchTableContentArr.length > 0 &&
            titleMatchTableContentArr.map((rowValues, rowIndex) => (
              <>
                <tr className={rowIndex % 2 === 0 ? "stripedBlue" : ""}>
                  {rowValues.length > 0 &&
                    rowValues.map((colValues, colIndex) =>
                      colValues.search_result === "1" ? (
                        <>
                          <td
                            colSpan="2"
                            className={`tableRightBorder ${
                              colValues.isSkillDisplay && "skillTableData"
                            }`}
                          >
                            {colValues.count}
                          </td>
                        </>
                      ) : colValues.isLabel ? (
                        <td
                          className="boldPara noHide tableRightBorder"
                          colSpan={"2"}
                        >
                          Count :{" "}
                        </td>
                      ) : (
                        <td
                          rowSpan="2"
                          colSpan="2"
                          className={`tableRightBorder tableBottomBorder center-align ${
                            colValues.isSkillDisplay && "skillTableData"
                          }`}
                        >
                          <div className={"flex items-center justify-center"}>
                            <p className={"boldPara dash"}>
                              {colValues.isNotPresent
                                ? "-"
                                : colValues.skill_name}
                            </p>
                          </div>
                        </td>
                      )
                    )}
                </tr>
                <tr className={rowIndex % 2 === 0 ? "stripedBlue" : ""}>
                  {rowValues.length > 0 &&
                    rowValues.map((colValues, colIndex) =>
                      colValues.search_result === "1" ? (
                        <>
                          <td
                            className={"tableRightBorder tableBottomBorder"}
                            colSpan={"2"}
                          >
                            {colValues.time.join(" ")}
                          </td>
                        </>
                      ) : colValues.isLabel ? (
                        <td
                          className={
                            "boldPara noHide tableRightBorder tableBottomBorder whitespace-nowrap"
                          }
                          colSpan={"2"}
                        >
                          Duration :{" "}
                        </td>
                      ) : null
                    )}
                </tr>
              </>
            ))}
        </tbody> */}
      </table>
    </div>
  );
};
export default TitleMatchReport;
