/* This auth service will be called for validating:
  1. API's
  2. Refresh page.
This is not necessary during login screen

Service calls an endpoint to validate. If not it gets refresh token  */

// Import API
import axios from "axios";
import { userAuth_service, userAuthRefresh_API } from "../../api/api";

// axios csrf
axios.defaults.xsrfHeaderName = "X-CSRFToken";
axios.defaults.xsrfCookieName = "csrftoken";

const FetchRefreshToken = async () => {
  //console.log("Checking refresh authorization.");

  // call endpoint
  try {
    var res = await axios({
      method: "post",
      url: userAuthRefresh_API,
      data: {
        refresh: localStorage.getItem("tal_refresh"),
      },
      headers: {
        "content-type": "application/json",
      },
    });
    // Store new token
    localStorage.setItem("tal_token", res.data.access);
    return { code: 200, login: true, msg: "User logged in." };
  } catch (error) {
    localStorage.clear();
    return {
      code: 403,
      login: false,
      msg: "User could not be authenticated. Please login again!",
    };
  }
};

const ValidateAuth = async () => {
  //console.log("Authenticating user before use.");
  //console.log(localStorage.getItem("tal_token"));
  //console.log(localStorage.getItem("tal_refresh"));
  // call endpoint
  try {
    await axios({
      method: "get",
      url: userAuth_service,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("tal_token"),
      },
    });
    return { code: 200, login: true, msg: "User logged in." };
  } catch (error) {
    if (error.response?.status === 403) {
      return {
        code: 403,
        login: false,
        msg: "User could not be authenticated. Please login again!",
      };
    } else {
      return {
        code: error.response?.status || 502,
        login: false,
        msg: "No response from server. Please try again later.",
      };
    }
  }
};

const AuthService = async () => {
  // call validate endpoint
  var val = await ValidateAuth();

  // if 401, get refresh token
  if (val.code === 403) {
    val = await FetchRefreshToken();
    return val;
  }
  //console.log(val);
  return val;
};

export default AuthService;
