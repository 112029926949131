import React from "react";

import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import CancelIcon from "@material-ui/icons/Cancel";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import InfoIcon from "@material-ui/icons/Info";
import WarningIcon from "@material-ui/icons/Warning";

import LinearProgress from "@material-ui/core/LinearProgress";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";

import RepeatedData from "./RepeatedData";
import TopSkilsData from "./TopSkillsData";

import Paper from "@material-ui/core/Paper";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: "#rgb(182, 188, 226)",
  },
  bar: {
    borderRadius: 8,
    backgroundColor: "#1a90ff",
  },
}))(LinearProgress);

class TopSkills extends React.Component {
  getHighlightedText = (text, highlight) => {
    // Split on highlight term and include term into parts, ignore case
    const parts = text.split(new RegExp(`(${highlight})`, "gi"));
    return (
      <p style={{ fontWeight: "700", fontSize: "13px", lineHeight: "25px" }}>
        {" "}
        {parts.map((part, i) => (
          <span
            key={i}
            style={
              part.toLowerCase() === highlight.toLowerCase()
                ? {
                    fontWeight: "bold",
                    color: "#fff",
                    background: "red",
                    padding: "2px",
                    fontSize: "14px",
                  }
                : {}
            }
          >
            {part}
          </span>
        ))}{" "}
      </p>
    );
  };

  getHighlightedText2 = (text, highlight) => {
    // Split on highlight term and include term into parts, ignore case
    const parts = text?.split(
      new RegExp(`(${highlight === "(" ? "-" : highlight})`, "gi")
    );
    return (
      <p style={{ fontWeight: "700", fontSize: "13px", lineHeight: "25px" }}>
        {" "}
        {parts.map((part, i) => (
          <span
            key={i}
            style={
              part.toLowerCase() === highlight.toLowerCase()
                ? {
                    fontWeight: "bold",
                    color: "#000",
                    background: "yellow",
                    padding: "2px",
                  }
                : {}
            }
          >
            {part}
          </span>
        ))}{" "}
      </p>
    );
  };

  render() {
    //console.log("test new", this.props);

    var weekwords = this.props.processedResumes.insights.irrelevant_line_items;
    var gcheck = this.props.processedResumes.insights.grammar_check;
    var plist = this.props.processedResumes.insights.punctuation_check_list;
    var itilte = this.props.processedResumes.insights.inferred_title;
    var kscore = this.props.processedResumes.insights.keyword_score;
    var flags = this.props.processedResumes.insights.flags;

    console.log(gcheck, "***********");

    return (
      <div className="top_skill_right">
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>Top Skills </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <TopSkilsData Skills={this.props.processedResumes} />
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography>Weak Words</Typography>
          </AccordionSummary>

          {weekwords.length > 0 &&
            weekwords.map((row) => (
              <AccordionDetails>
                {this.getHighlightedText(row.line, row.word)}
              </AccordionDetails>
            ))}

          {weekwords.length <= 0 && (
            <AccordionDetails>
              <p className="novalues">Weak Words not found</p>
            </AccordionDetails>
          )}
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography>Grammer Check</Typography>
          </AccordionSummary>

          <Typography className="gtitle">Misspelled Words</Typography>

          {gcheck.length > 0 &&
            gcheck.map((row) => (
              <AccordionDetails>
                {this.getHighlightedText2(row.line, row.word)}
              </AccordionDetails>
            ))}

          {gcheck.length <= 0 && (
            <AccordionDetails>
              <p className="novalues"> Misspelled Words not found </p>
            </AccordionDetails>
          )}

          <Typography className="gtitle punction">Punctuation List</Typography>
          <AccordionDetails>
            <TableContainer component={Paper} className="topskillcontent">
              <Table aria-label="customized table">
                {plist.length > 0 && (
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>Sentence</StyledTableCell>
                      <StyledTableCell>Error Type</StyledTableCell>
                    </TableRow>
                  </TableHead>
                )}

                <TableBody>
                  {plist.length > 0 &&
                    plist.map((row) => (
                      <StyledTableRow>
                        <StyledTableCell align="right">
                          {this.getHighlightedText2(row.line, row.word)}
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          {" "}
                          {row.error_type}
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                  {plist.length <= 0 && (
                    <StyledTableRow>
                      {" "}
                      Punctuation error not found{" "}
                    </StyledTableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography>Repeated Work Experience </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <RepeatedData RepeatedData={this.props.processedResumes} />
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography>Additional Info</Typography>
          </AccordionSummary>
          <Typography className="gtitle">Flags</Typography>
          {flags.length > 0 &&
            flags.map((row) => (
              <AccordionDetails>
                <div className="w-full px-12 lg:w-12/12 kscore">
                  <p className={"kcate " + row[1]}>{row[1]}</p>
                  {row[1] === "warning" && (
                    <p className="ksc warning">
                      {" "}
                      <WarningIcon />
                    </p>
                  )}
                  {row[1] === "critical" && (
                    <p className="ksc critical">
                      {" "}
                      <CancelIcon />
                    </p>
                  )}
                  {row[1] === "information" && (
                    <p className="ksc information">
                      {" "}
                      <InfoIcon />
                    </p>
                  )}
                  <p className={"kper " + row[1]}>{row[0]}</p>
                </div>
              </AccordionDetails>
            ))}

          {flags.length <= 0 && (
            <AccordionDetails>
              {" "}
              <p className="novalues"> Flags not found </p>
            </AccordionDetails>
          )}
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography>Match Factor</Typography>
          </AccordionSummary>

          <Typography className="gtitle">Inferred Title</Typography>

          {itilte.length > 0 &&
            itilte.map((row) => (
              <AccordionDetails>
                <div className="w-full px-12 lg:w-12/12 kscore">
                  <p className="kcate">{row.title}</p>
                  <BorderLinearProgress
                    className="kper"
                    variant="determinate"
                    value={row.score * 100}
                  />
                  <p className="ksc">{(row.score * 100).toFixed(2)}%</p>
                </div>
              </AccordionDetails>
            ))}

          {itilte.length <= 0 && (
            <AccordionDetails>
              {" "}
              <p className="novalues"> Inferred Title not found </p>
            </AccordionDetails>
          )}

          {/*} <AccordionDetails>
            <TableContainer component={Paper} className="topskillcontent">
              <Table aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell>title</StyledTableCell>
                    <StyledTableCell>Score</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {itilte.map((row) => (
                    <StyledTableRow>
                      <StyledTableCell align="right">
                        {row.title}
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        {row.score}
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
                  </AccordionDetails>*/}

          <Typography className="gtitle punction">Keyword Score</Typography>

          {kscore.length > 0 &&
            kscore.map((row) => (
              <AccordionDetails>
                <div className="w-full px-12 lg:w-12/12 kscore">
                  <p className="kcate">{row.category}</p>
                  <BorderLinearProgress
                    className="kper"
                    variant="determinate"
                    value={row.score}
                  />
                  <p className="ksc">{row.score}%</p>
                </div>
              </AccordionDetails>
            ))}

          {kscore.length <= 0 && (
            <AccordionDetails>
              {" "}
              <p className="novalues">Score not found</p>{" "}
            </AccordionDetails>
          )}
        </Accordion>
        <a href="#submit_gain" id="myBtn" title="parse">
          <ArrowUpwardIcon />
        </a>
        <p className="parse">parse</p>
      </div>
    );
  }
}

export default TopSkills;
