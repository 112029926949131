import {
  Button,
  FormControl,
  FormHelperText,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import "../TestEvent/style.css";
import * as yup from "yup";
import { Form, FormikProvider, useFormik } from "formik";
import { useParams } from "react-router-dom";
import axios from "axios";
import { getPhoneQuestionAnswer, postPhoneQuestionAnswer } from "../../api/api";
import { Alert } from "@material-ui/lab";
import YesOrNoSelect from "../../commonComponents/YesOrNoSelect/YesOrNoSelect";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { MESSAGES } from "../../utils/constants/constants";

function QuestionContainer({ question, children }) {
  return (
    <>
      <Grid item xs={12}>
        <Typography>
          <b>{question}</b>
        </Typography>
      </Grid>
      <Grid item xs={12}>
        {children}
      </Grid>
    </>
  );
}

function Phone({
  isSubmitted,
  header_config,
  isClient,
  isAccessibleActionButton = true,
  ...props
}) {
  const { jobCode, applicantId } = useParams();

  const [timeSlotOneValue, setTimeSlotOneValue] = useState(new Date());
  const [alertBox, setAlertBox] = useState({
    open: false,
    success: false,
    message: "",
  });

  const requiredMessage = "Please fill the required field";
  const numberOnlyMessage = "Please enter valid number";
  const futureDateMessage = "Please enter valid date";
  const afterFourDaysMessage =
    "Please select the date after four days from the Time Slot One";

  const validationSchema = yup.object({
    // currentLocation: yup.string().required(requiredMessage),
    // reLocation: yup.string().required(requiredMessage),
    // workOption: yup.string().required(requiredMessage),
    // variablePay: yup.string().required(requiredMessage),
    // annualSalary: yup.string().required(requiredMessage),
    // currentCtc: yup.string().required(requiredMessage),
    // expectedCtc: yup
    //   .number()
    //   .min(0, numberOnlyMessage)
    //   .required(requiredMessage),
    // currentNoticePeriod: yup.string().required(requiredMessage),
    // underNoticePeriod: yup.string().required(requiredMessage),
    // dateIfYouSelected: yup.date().min(new Date(), futureDateMessage).optional(),
    // hybridWorkingModel: yup.string().required(requiredMessage),
    // internationalTimeZone: yup.string().required(requiredMessage),
    timeSlotOne: yup
      .date()
      .min(new Date(), futureDateMessage)
      .required(requiredMessage)
      .nullable(),
    timeSlotTwo: yup
      .date()
      .min(new Date(), futureDateMessage)
      // .when("timeSlotOne", (timeSlotOne, schema) => {
      //   let tempTimeSlotOne = new Date(timeSlotOne);
      //   tempTimeSlotOne.setDate(tempTimeSlotOne.getDate() + 4);
      //   return timeSlotOne
      //     ? schema.min(tempTimeSlotOne, afterFourDaysMessage)
      //     : schema;
      // })
      .required(requiredMessage)
      .nullable(),
    // backgroundCheck: yup.string().required(requiredMessage),
  });

  const formik = useFormik({
    initialValues: {
      // homePhoneNumber: "+919677000212",
      // currentLocation: "",
      // reLocation: "",
      // workOption: "",
      // variablePay: "",
      // annualSalary: "",
      // currentCtc: "",
      // expectedCtc: "",
      // currentNoticePeriod: "",
      // underNoticePeriod: "",
      // dateIfYouSelected: "",
      // hybridWorkingModel: "",
      // internationalTimeZone: "",
      timeSlotOne: "",
      timeSlotTwo: "",
      // timeSlotThree: "",
      // backgroundCheck: "",
    },
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: handleSubmit,
  });

  function handleSubmit(values) {
    let quesAnsArr = [];

    for (const key in values) {
      if (key === "timeSlotOne" || key === "timeSlotTwo") {
        quesAnsArr.push({ [key]: new Date(values[key]).toISOString() });
      }
      // else {
      //   quesAnsArr.push({ [key]: values[key] });
      // }
    }

    let requestBody = {
      applicant_id: applicantId,
      job_code: jobCode,
      answers: quesAnsArr,
    };

    if (isClient) {
      requestBody.status = "submitted";
    }

    axios
      .post(postPhoneQuestionAnswer(), requestBody, header_config)
      .then((response) => {
        if (response.status === 200) {
          setAlertBox({
            open: true,
            success: true,
            message: MESSAGES.testEventScheduleViaPhoneOrFormLink,
          });

          // Show Thank You message on ChatForm after user successfully submitted.
          if (props.hasOwnProperty("showThankYou")) {
            props.showThankYou();
          }
        }
      })
      .catch((err) => {
        console.log("err", err);
        setAlertBox({
          open: true,
          success: false,
          message: "Something Went Wrong",
        });
      })
      .finally(() => {
        setTimeout(() => {
          setAlertBox({
            open: false,
            success: false,
            message: "",
          });
        }, 2000);
      });
  }

  const getAndSetPhoneQuestionAnswer = () => {
    axios
      .get(getPhoneQuestionAnswer(applicantId, jobCode), header_config)
      .then((response) => {
        if (response.status === 200) {
          let formattedObj = {};
          console.log("formattedObj", response.data);
          response.data.map((el, ind) => {
            if (el.title === "timeSlotOne" || el.title === "timeSlotTwo") {
              if (el.answer) {
                const formattedDate = new Date(el.answer);
                formattedObj[el.title] = formattedDate;
              } else {
                formattedObj[el.title] = el.answer;
              }
            } else {
              formattedObj[el.title] = el.answer;
            }
          });
          console.log("formattedObj", formattedObj);
          setValues({ ...formattedObj });
        }
      });
  };

  useEffect(() => {
    getAndSetPhoneQuestionAnswer();
  }, []);

  const {
    values,
    handleChange,
    touched,
    errors,
    setFieldValue,
    handleBlur,
    setValues,
    setFieldTouched,
  } = formik;

  console.log(values, errors, touched, "+++++");

  return (
    <FormikProvider value={formik}>
      <Form
        style={{ pointerEvents: isClient && isSubmitted ? "none" : "auto" }}
      >
        <Grid container spacing={2}>
          {/* {props.showPhoneNumber ? (
            <QuestionContainer>
              <TextField
                id="standard-read-only-input"
                variant="filled"
                label="Phone Number"
                defaultValue={values.homePhoneNumber}
                InputProps={{
                  readOnly: true,
                }}
                fullWidth
              />
            </QuestionContainer>
          ) : (
            ""
          )} */}

          {/* <QuestionContainer question={"Where are you currently located?"}>
            <TextField
              name={"currentLocation"}
              value={values.currentLocation}
              error={touched.currentLocation && Boolean(errors.currentLocation)}
              helperText={touched.currentLocation && errors.currentLocation}
              onChange={handleChange}
              onBlur={handleBlur}
              fullWidth
            />
          </QuestionContainer> */}

          {/* <QuestionContainer question={"Are you willing to relocate ?"}>
            <YesOrNoSelect
              error={touched.reLocation && Boolean(errors.reLocation)}
              helperText={touched.reLocation && errors.reLocation}
              name={"reLocation"}
              value={values.reLocation}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </QuestionContainer> */}

          {/* <QuestionContainer question={"Your work option ?"}>
            <FormControl
              size={"medium"}
              variant={"standard"}
              error={touched.workOption && Boolean(errors.workOption)}
            >
              <Select
                value={values.workOption}
                onChange={handleChange}
                onBlur={handleBlur}
                name={"workOption"}
              >
                <MenuItem value={"Onsite"}>Onsite</MenuItem>
                <MenuItem value={"Hybrid"}>Hybrid</MenuItem>
                <MenuItem value={"Remote"}>Remote</MenuItem>
              </Select>
              <FormHelperText>
                {touched.workOption && errors.workOption}
              </FormHelperText>
            </FormControl>
          </QuestionContainer> */}

          {/* <QuestionContainer
            question={
              "What is your current salary (LPA) inclusive of all benefits ?"
            }
          >
            <TextField
              error={touched.currentCtc && Boolean(errors.currentCtc)}
              helperText={touched.currentCtc && errors.currentCtc}
              name={"currentCtc"}
              value={values.currentCtc}
              onChange={handleChange}
              onBlur={handleBlur}
              // type={"number"}
              fullWidth
            />
          </QuestionContainer> */}

          {/* <QuestionContainer question={"Variable pay if any explain ?"}>
            <TextField
              error={touched.variablePay && Boolean(errors.variablePay)}
              helperText={touched.variablePay && errors.variablePay}
              name={"variablePay"}
              value={values.variablePay}
              onChange={handleChange}
              onBlur={handleBlur}
              fullWidth
            />
          </QuestionContainer> */}

          {/* <QuestionContainer
            question={
              "What is your expected annual salary for the above role ?"
            }
          >
            <TextField
              error={touched.annualSalary && Boolean(errors.annualSalary)}
              helperText={touched.annualSalary && errors.annualSalary}
              name={"annualSalary"}
              value={values.annualSalary}
              // type={"number"}
              onChange={handleChange}
              onBlur={handleBlur}
              fullWidth
            />
          </QuestionContainer> */}

          {/* <QuestionContainer question={"What is your expected CTC ?"}>
            <TextField
              error={touched.expectedCtc && Boolean(errors.expectedCtc)}
              helperText={touched.expectedCtc && errors.expectedCtc}
              name={"expectedCtc"}
              value={values.expectedCtc}
              onChange={handleChange}
              onBlur={handleBlur}
              type={"number"}
              fullWidth
            />
          </QuestionContainer> */}

          {/* <QuestionContainer
            question={
              "What is your notice period to join if selected for the above role ?"
            }
          >
            <TextField
              error={
                touched.currentNoticePeriod &&
                Boolean(errors.currentNoticePeriod)
              }
              helperText={
                touched.currentNoticePeriod && errors.currentNoticePeriod
              }
              name={"currentNoticePeriod"}
              value={values.currentNoticePeriod}
              onChange={handleChange}
              onBlur={handleBlur}
              fullWidth
            />
          </QuestionContainer> */}

          {/* <QuestionContainer
            question={"Are you currently under notice period ?"}
          >
            <YesOrNoSelect
              error={
                touched.underNoticePeriod && Boolean(errors.underNoticePeriod)
              }
              helperText={touched.underNoticePeriod && errors.underNoticePeriod}
              name={"underNoticePeriod"}
              value={values.underNoticePeriod}
              onChange={(e) => {
                setFieldValue("underNoticePeriod", e.target.value);
                if (e.target.value === "No") {
                  setFieldValue("dateIfYouSelected", "");
                }
              }}
              onBlur={handleBlur}
            />
          </QuestionContainer> */}

          {/* {values.underNoticePeriod === "Yes" && (
            <QuestionContainer
              question={
                "What is the earliest date you can join if you selected ?"
              }
            >
              <TextField
                label=""
                type="date"
                error={
                  touched.dateIfYouSelected && Boolean(errors.dateIfYouSelected)
                }
                helperText={
                  touched.dateIfYouSelected && errors.dateIfYouSelected
                }
                name={"dateIfYouSelected"}
                value={values.dateIfYouSelected}
                onChange={handleChange}
                onBlur={handleBlur}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </QuestionContainer>
          )} */}

          {/* <QuestionContainer
            question={"Do you Open to a Hybrid working model ?"}
          >
            <YesOrNoSelect
              error={
                touched.hybridWorkingModel && Boolean(errors.hybridWorkingModel)
              }
              helperText={
                touched.hybridWorkingModel && errors.hybridWorkingModel
              }
              name={"hybridWorkingModel"}
              value={values.hybridWorkingModel}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </QuestionContainer> */}

          {/* <QuestionContainer
            question={"Are you willing to work in an International timezone ?"}
          >
            <YesOrNoSelect
              error={
                touched.internationalTimeZone &&
                Boolean(errors.internationalTimeZone)
              }
              helperText={
                touched.internationalTimeZone && errors.internationalTimeZone
              }
              name={"internationalTimeZone"}
              value={values.internationalTimeZone}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </QuestionContainer> */}

          <Grid item xs={12}>
            <div className="bg-gray-200 p-5 mt-2 rounded-lg">
              <Typography>
                <b>The Interview process for this position requires</b>
              </Typography>
              <ul className="mt-1">
                <li className="listStyle">
                  This position requires 1 hour online technical test
                </li>
              </ul>
            </div>
          </Grid>
          <QuestionContainer
            question={"Please select the time slots suitable for you."}
          >
            <div className="flex items-center gap-10">
              {/* <TextField
                label="Time Slot One"
                name={"timeSlotOne"}
                value={values.timeSlotOne}
                onChange={handleChange}
                onBlur={handleBlur}
                type="datetime-local"
                InputLabelProps={{
                  shrink: true,
                }}
                id={"dateTimePicker"}
                error={touched.timeSlotOne && Boolean(errors.timeSlotOne)}
                helperText={touched.timeSlotOne && errors.timeSlotOne}
              /> */}
              <div className="dateTimeContainer flex flex-col">
                <Typography
                  className={`${
                    touched.timeSlotOne &&
                    Boolean(errors.timeSlotOne) &&
                    "text-red-500"
                  }`}
                  variant="caption"
                >
                  Time Slot One
                </Typography>
                <DatePicker
                  className={`outline-none border-b-2 ${
                    touched.timeSlotOne && Boolean(errors.timeSlotOne)
                      ? "border-red-500"
                      : "border-gray-300"
                  } `}
                  selected={values.timeSlotOne}
                  onChange={(date) => {
                    setFieldTouched("timeSlotOne", true);
                    setFieldValue("timeSlotOne", date);
                    setTimeSlotOneValue(date);
                    console.log(setFieldValue);
                  }}
                  minDate={new Date()}
                  showTimeSelect
                  name={"timeSlotOne"}
                  customInput={<input name="timeSlotOne" />}
                  dateFormat="MMMM d, yyyy h:mm aa"
                  autoComplete={"off"}
                />
                <FormHelperText className="errorText">
                  {touched.timeSlotOne && errors.timeSlotOne}
                </FormHelperText>
              </div>
              <div className="dateTimeContainer flex flex-col">
                <Typography
                  className={`${
                    touched.timeSlotTwo &&
                    Boolean(errors.timeSlotTwo) &&
                    "text-red-500"
                  }`}
                  variant="caption"
                >
                  Time Slot Two
                </Typography>
                <DatePicker
                  className={`outline-none border-b-2 ${
                    touched.timeSlotTwo && Boolean(errors.timeSlotTwo)
                      ? "border-red-500"
                      : "border-gray-300"
                  } `}
                  name={"timeSlotTwo"}
                  minDate={
                    values.timeSlotOne
                      ? new Date(
                          new Date(values.timeSlotOne).setDate(
                            new Date(values.timeSlotOne).getDate() + 1
                          )
                        )
                      : new Date()
                  }
                  customInput={<input name="timeSlotTwo" />}
                  selected={values.timeSlotTwo}
                  onChange={(date) => {
                    setFieldTouched("timeSlotTwo", true);
                    setFieldValue("timeSlotTwo", date);
                  }}
                  showTimeSelect
                  dateFormat="MMMM d, yyyy h:mm aa"
                  autoComplete={"off"}
                />
                <FormHelperText className="errorText">
                  {touched.timeSlotTwo && errors.timeSlotTwo}
                </FormHelperText>
              </div>
              {/* <TextField
                label="Time Slot Two"
                error={touched.timeSlotTwo && Boolean(errors.timeSlotTwo)}
                helperText={touched.timeSlotTwo && errors.timeSlotTwo}
                name={"timeSlotTwo"}
                value={values.timeSlotTwo}
                onChange={handleChange}
                onBlur={handleBlur}
                type="datetime-local"
                InputLabelProps={{
                  shrink: true,
                }}
                id={"dateTimePicker"}
              /> */}
              {/* <TextField
                label=""
                error={touched.timeSlotThree && Boolean(errors.timeSlotThree)}
                helperText={touched.timeSlotThree && errors.timeSlotThree}
                name={"timeSlotThree"}
                value={values.timeSlotThree}
                onChange={handleChange}
                onBlur={handleBlur}
                type="datetime-local"
                InputLabelProps={{
                  shrink: true,
                }}
              /> */}
            </div>
          </QuestionContainer>

          {/* <QuestionContainer
            question={
              "This position requires you to complete pre-onboarding formalities like Drug Test, Background Check will you be open for this ?"
            }
          >
            <YesOrNoSelect
              error={touched.backgroundCheck && Boolean(errors.backgroundCheck)}
              helperText={touched.backgroundCheck && errors.backgroundCheck}
              name={"backgroundCheck"}
              value={values.backgroundCheck}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </QuestionContainer> */}

          <Grid
            item
            xs={12}
            container
            alignItems="center"
            justifyContent="flex-end"
          >
            <Button
              variant={"contained"}
              color={"primary"}
              type={"submit"}
              disabled={!isAccessibleActionButton || (isSubmitted && isClient)}
            >
              Submit
            </Button>
          </Grid>
          {alertBox.open && (
            <Grid item xs={12}>
              <Alert severity={`${alertBox.success ? "success" : "error"}`}>
                {alertBox.message}
              </Alert>
            </Grid>
          )}
        </Grid>
      </Form>
    </FormikProvider>
  );
}

export default Phone;
