import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";

export default function DeleteDialog({
  dialogOpen,
  handleClose,
  handleYesClick,
  message,
}) {
  return (
    <Dialog
      open={dialogOpen}
      onClose={handleClose}
      aria-labelledby={"alert-dialog-title"}
      aria-describedby={"alert-dialog-description"}
    >
      <DialogTitle id={"alert-dialog-title"}>
        {message || "Are you sure want to delete ?"}
      </DialogTitle>

      <DialogActions>
        <Button onClick={handleClose} color={"primary"}>
          <b>{"No"}</b>
        </Button>
        <Button onClick={handleYesClick} color={"secondary"} autoFocus>
          <b>{"Yes"}</b>
        </Button>
      </DialogActions>
    </Dialog>
  );
}
