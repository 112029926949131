import { Box, Divider, Grid, Typography } from "@material-ui/core";
import React from "react";

const colors = ["dodgerblue", "gold", "red"];

function MatchFactor({ inferredTitle, keywordScore }) {
  let sum = 0;
  return (
    <Box p={1}>
      <Box pb={3}>
        <Typography variant={"h6"} align={"center"}>
          <b>{"Inferred Title"}</b>
        </Typography>
        <Box pt={1}>
          {inferredTitle && inferredTitle.length ? (
            inferredTitle.map(({ title, score }, index) => (
              <Grid
                container
                className="inferredTitleRow"
                key={"inferred-title-" + index}
              >
                <Grid item className="inferredTitleBox inferredTitleNumber">
                  <Typography>{index + 1}</Typography>
                </Grid>
                <Grid
                  item
                  xs={index === 0 ? 2 : 4}
                  className="inferredTitleLine"
                ></Grid>
                <Grid item xs={index === 0 ? 8 : 6}>
                  <Typography className="inferredTitleBox inferredTitleName">
                    {title}
                  </Typography>
                </Grid>
              </Grid>
            ))
          ) : (
            <Typography align={"center"}>
              <b>{"Inferred Title not found"}</b>
            </Typography>
          )}
        </Box>
        <Box pt={2} pb={2}>
          <Divider />
        </Box>
        <Typography variant={"h6"} align={"center"}>
          <b>{"Keyword Score"}</b>
        </Typography>
        <Box pt={1}>
          {keywordScore && keywordScore.length ? (
            keywordScore.map(({ score, category }, index) => {
              if (index) sum += keywordScore[index - 1].score;
              return (
                <Box pt={2} key={index}>
                  <Grid container spacing={3} key={index}>
                    <Grid item xs={3}>
                      <span>
                        <b>{category}</b>
                      </span>
                    </Grid>
                    <Grid item xs={7}>
                      <div className="progress-bar">
                        <div
                          className="progress animate"
                          style={{
                            width: score + "%",
                            marginLeft: sum + "%",
                            background: colors[index],
                          }}
                        >
                          <span>
                            <span />
                          </span>
                        </div>
                      </div>
                    </Grid>

                    <Grid item xs={2}>
                      <span>
                        <b>{Math.round(score) + "%"}</b>
                      </span>
                    </Grid>
                  </Grid>
                </Box>
              );
            })
          ) : (
            <Typography align={"center"}>
              <b>{"Score not found"}</b>
            </Typography>
          )}
        </Box>
      </Box>
    </Box>
  );
}

export default MatchFactor;
