import React from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

/*function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}*/

/*const rows = [
  createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
  createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
  createData('Eclair', 262, 16.0, 24, 6.0),
  createData('Cupcake', 305, 3.7, 67, 4.3),
  createData('Gingerbread', 356, 16.0, 49, 3.9),
]; */

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

export default function RepeatedData(props) {
  const classes = useStyles();

  const rowss = props.RepeatedData.insights.repeated_work_exp;
  return (
    <div style={{ overflow: "hidden" }}>
      {rowss.length > 0 && (
        <TableContainer component={Paper} className="RepeatedData">
          <Table className={classes.table} aria-label="customized table">
            {rowss.length > 0 && (
              <TableHead>
                <TableRow>
                  <StyledTableCell>Repeated Lines</StyledTableCell>
                  <StyledTableCell align="right">
                    Repeated In Projects
                  </StyledTableCell>
                </TableRow>
              </TableHead>
            )}
            <TableBody>
              {rowss.length > 0 &&
                rowss.map((row) => (
                  <StyledTableRow>
                    <StyledTableCell align="right">{row.line}</StyledTableCell>
                    <StyledTableCell align="right">
                      {row.project}
                    </StyledTableCell>
                  </StyledTableRow>
                ))}

              {rowss.length <= 0 && (
                <StyledTableRow> Repeated line items not found </StyledTableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {rowss.length <= 0 && (
        <p className="novalues">Repeated line items not found</p>
      )}
    </div>
  );
}
