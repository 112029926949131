import {
  Box,
  Container,
  IconButton,
  Tooltip,
  Typography,
} from "@material-ui/core";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { applyJobAuthenticate, getApplicantByID, applicantClientAccess } from "../../api/api";
import CoverSheetForm from "../Scheduling/CoverSheetForm";
import { Alert } from "@material-ui/lab";
import { useHistory } from "react-router-dom";
import Loader from "../../commonComponents/Loader/Loader";
import { ArrowBack } from "@material-ui/icons";

function ClientForm() {
  const history = useHistory();
  const findIsClient = () => {
    const { pathname } = history.location;
    return pathname.includes("yes");
  };
  const header_config = findIsClient()
    ? {}
    : {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("tal_token")}`,
      },
    };
  const { applicant_id, job_code } = history.location.state || {};
  const { applicantId, jobCode, token, randomString } = useParams();
  const isCandidateApplyForm = jobCode && token && findIsClient();
  const applicantIdValue = applicantId || applicant_id;
  const jobCodeValue = jobCode || job_code;

  const [showThankYou, setShowThankYou] = useState(false);
  const [skillList, setSkillList] = useState(null);
  const [resume, setResume] = useState(null);
  const [loading, setLoading] = useState(true);
  const [status, setStatus] = useState(null);
  const [applyFormAccess, setApplyFormAccess] = useState(false);
  const [ifAccessible, setIfAccessible] = useState(false);

  useEffect(() => {
    if (isCandidateApplyForm) {
      axios
        .get(applyJobAuthenticate(), {
          params: {
            token: token,
          },
        })
        .then((res) => {
          if (res.status === 200) {
            setApplyFormAccess(true);
          } else {
            setLoading(false);
          }
        })
        .catch((error) => {
          setLoading(false);
        });
    }
    else if (randomString) {
      axios.get(applicantClientAccess(), {
        params: {
          applicant_id: applicantIdValue,
          random_string: randomString,
        }
      })
        .then((res) => {
          if (res.status === 200) {
            setApplyFormAccess(true);
          } else {
            setLoading(false);
          }
        })
        .catch((error) => {
          setLoading(false);
        });
    } else {
      setApplyFormAccess(true);
    }
  }, []);

  useEffect(() => {
    if (applicantIdValue && applyFormAccess) {
      axios
        .get(getApplicantByID(applicantIdValue), header_config)
        .then((response) => {
          if (response.status === 200) {
            if (response.data) {
              setResume(response.data.resume);
              setStatus(response.data?.coversheet_formlink_status);
              if (response.data.skills_list) {
                setSkillList(response.data.skills_list);
              } else {
                setSkillList([]);
              }
              if (
                (response.data?.coversheet_formlink_status === "submitted" ||
                  response.data?.formlink_status === "submitted") &&
                findIsClient()
              ) {
                setShowThankYou(true);
              }
            }
          } else {
            setSkillList([]);
          }
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
        });
    } else if (applyFormAccess) {
      setLoading(false);
    }
  }, [applicantIdValue, applyFormAccess]);

  const handleShowThankYou = () => {
    setShowThankYou(true);
  };

  const handleBack = () => {
    if (jobCodeValue)
      history.push(`/dashboard/${jobCodeValue}/applicants`);
    else
      history.push(`/home/applicants`);
    // history.push(`/dashboard/${jobCodeValue}/applicants`);
  };

  return (
    <Container maxWidth={"md"} className={"mt-8 mb-8"}>
      {findIsClient() ? null : (
        <Tooltip title={"Back"}>
          <IconButton
            style={{ background: "#25a9da", color: "#ffffff", padding: "8px" }}
            onClick={handleBack}
          >
            <ArrowBack />
          </IconButton>
        </Tooltip>
      )}

      {loading ? (
        <Loader />
      ) : status !== "sent" &&
        !applyFormAccess &&
        !showThankYou &&
        findIsClient() ? (
        <Box pt={2}>
          <Alert severity={"error"}>
            <p className="text-base font-bold">
              {`Invalid URL. Access Denied`}
            </p>
          </Alert>
        </Box>
      ) : !showThankYou ? (
        <>
          <Typography
            variant={"h6"}
            align={"center"}
            style={{ color: "#25a9da" }}
          >
            <b>{"Titan Data Group Inc"}</b>
          </Typography>
          <Typography variant={"h6"} align={"center"}>
            <b>{isCandidateApplyForm ? "Job Application" : "Cover Sheet"}</b>
          </Typography>
          <CoverSheetForm
            showThankYou={handleShowThankYou}
            skillList={skillList}
            resume={resume}
            header_config={header_config}
            isClient={findIsClient()}
            applicant_id={applicantIdValue}
            job_code={jobCodeValue}
            isCandidateApplyForm={isCandidateApplyForm}
            token={token}
          />
        </>
      ) : null}
      {showThankYou ? (
        <Box pt={2}>
          <Alert severity={"success"}>
            <p className="text-base font-bold">
              {`Thank you for providing the information. We will process and share your profile details and get back to you in next few days.`}
            </p>
            {/* <p className="text-base font-bold">We will communicate shortly!</p> */}
          </Alert>
        </Box>
      ) : null}
    </Container>
  );
}

export default ClientForm;
