import { Box, Grid, TextField, Typography } from "@material-ui/core";
import React from "react";
import YesOrNoSelect from "../../../commonComponents/YesOrNoSelect/YesOrNoSelect";
import SelectDropDown from "./SelectDropDown";

const SCORE = [
  {
    value: "",
    label: "Select",
  },
  {
    value: "1",
    label: "1",
  },
  {
    value: "2",
    label: "2",
  },
];

const QuestionContainer = ({ question, children }) => {
  return (
    <Grid item xs={12}>
      <Typography>
        <b>{question}</b>
      </Typography>
      <Box pt={1} pl={2}>
        {children}
      </Box>
    </Grid>
  );
};

function HobbiesAndExtraCurricularActivities({
  values = {},
  touched = {},
  errors = {},
  handleHobbiesInputChange = () => {},
  handleChange = () => {},
  isAdmin,
}) {
  return (
    <Grid container spacing={2} style={{ rowGap: 5 }}>
      <Grid item xs={12}>
        <Typography variant={"h6"}>
          <b>{"Hobbies/ Extra Curricular Activities"}</b>
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Box padding={"0 15px"}>
          <Grid container spacing={3}>
            <QuestionContainer
              question={"a. Have you participated in Hackathon/Coding test:"}
            >
              <Box width={"120px"}>
                <YesOrNoSelect
                  error={
                    touched.hobbies?.coding_test?.answer &&
                    Boolean(errors.hobbies?.coding_test?.answer)
                  }
                  helperText={
                    touched.hobbies?.coding_test?.answer &&
                    errors.hobbies?.coding_test?.answer
                  }
                  name={"answer"}
                  value={values.hobbies?.coding_test?.answer || ""}
                  onChange={(e) => handleHobbiesInputChange(e, "coding_test")}
                  variant={"outlined"}
                  size={"small"}
                  fullWidth
                />
              </Box>
              {values.hobbies?.coding_test?.answer === "Yes" ? (
                <Box pt={2}>
                  <TextField
                    label={"Explain"}
                    error={
                      touched.hobbies?.coding_test?.explain &&
                      Boolean(errors.hobbies?.coding_test?.explain)
                    }
                    helperText={
                      touched.hobbies?.coding_test?.explain &&
                      errors.hobbies?.coding_test?.explain
                    }
                    name={"explain"}
                    value={values.hobbies?.coding_test?.explain || ""}
                    onChange={(e) => handleHobbiesInputChange(e, "coding_test")}
                    variant={"outlined"}
                    multiline
                    minRows={1}
                    size={"small"}
                    fullWidth
                  />
                </Box>
              ) : null}
            </QuestionContainer>
            <QuestionContainer
              question={
                "b. Hobbies (like Reading, Writing, Debate, Sports/Fitness):"
              }
            >
              <Box pt={1}>
                <TextField
                  label={"Explain"}
                  error={
                    touched.hobbies?.hobbies?.explain &&
                    Boolean(errors.hobbies?.hobbies?.explain)
                  }
                  helperText={
                    touched.hobbies?.hobbies?.explain &&
                    errors.hobbies?.hobbies?.explain
                  }
                  name={"explain"}
                  value={values.hobbies?.hobbies?.explain || ""}
                  onChange={(e) => handleHobbiesInputChange(e, "hobbies")}
                  variant={"outlined"}
                  multiline
                  minRows={2}
                  size={"small"}
                  fullWidth
                />
              </Box>
            </QuestionContainer>
          </Grid>
        </Box>
      </Grid>
      {isAdmin ? (
        <Grid item xs={12} container justifyContent={"flex-end"}>
          <Box width={"175px"} pr={2}>
            <SelectDropDown
              label={"Soft Skill Score"}
              name={"soft_skill_score"}
              options={SCORE}
              variant={"outlined"}
              size={"small"}
              value={values.soft_skill_score || ""}
              onChange={handleChange}
            />
          </Box>
        </Grid>
      ) : null}
      <Grid item xs={12}>
        <Box pt={1}>
          <Typography>
            <b>{`Note: `}</b>
            {"Please verify the above info and click Next."}
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
}

export default HobbiesAndExtraCurricularActivities;
