import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Paper,
} from "@mui/material";
import { Grid, Typography } from "@material-ui/core";

const MatchResult = ({ custom, currentResumeData }) => {
  const [titleSkills, setTitleSkills] = useState([]);
  const [titleMatch, setTitleMatch] = useState([]);
  const [skillData, setSkillData] = useState([]);
  const [titleData, setTitleData] = useState([]);
  const [serachTitle, setSerachTitle] = useState([]);
  const [serachSkill, setSerachSkill] = useState([]);

  useEffect(() => {
    if (custom?.result && Array.isArray(custom.result)) {
      const skills =
        custom.result.map((value) => value?.skill?.skills_display) || [];
      const titles =
        custom.result.map((value) => value?.title?.skills_display) || [];
      const titleMatches =
        custom.result.map((value) => value?.title_match) || [];
      setSerachTitle(custom?.search_criteria?.title);
      setSerachSkill(custom?.search_criteria?.skill);
      setResultAndTitle(skills, titles, titleMatches);
    }
  }, [custom.result]);

  useEffect(() => {
    titleSkills.forEach((entry, index) => {
      setTitleData(entry);
    });
  }, [titleSkills]);

  const setResultAndTitle = (skills, titles, titleMatches) => {
    if (titles.length > 0) {
      setTitleSkills(titles);
    } else if (skills.length > 0) {
      setTitleSkills(skills);
    }

    setTitleMatch(titleMatches);
  };

  useEffect(() => {
    const jsonData =
      currentResumeData?.match_content?.title?.skills_display || [];
    const jsonData2 =
      currentResumeData?.match_content?.skill?.skills_display || [];
    const jsonData3 = currentResumeData?.match_content?.title_match || [];
    const data = currentResumeData?.match_content?.search_criteria[0].title;
    setSerachTitle(data);
    const data2 = currentResumeData?.match_content?.search_criteria[0].skill;
    setSerachSkill(data2);

    setTitleData(jsonData);

    setSkillData(jsonData2);

    setTitleMatch(jsonData3);
  }, [currentResumeData]);
  console.log(skillData);
  return (
    <div>
      {serachTitle?.action && (
        <Box
          sx={{
            mb: 2,
            display: "flex",
          }}
        >
          <Box fontWeight="bold" sx={{ textTransform: "capitalize" }}>
            Custom Match For {serachTitle?.action} {":"}
          </Box>

          <Box sx={{ ml: 1 }}> {serachTitle?.values}</Box>
        </Box>
      )}
      {titleData?.length > 0 && (
        <Box sx={{ mb: 2 }}>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Skill Name</TableCell>
                  <TableCell>Time</TableCell>
                  <TableCell>Recent Projects</TableCell>
                  <TableCell>Count</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Array.isArray(titleData) &&
                  titleData?.map((skill, index) => (
                    <TableRow key={index}>
                      <TableCell>{skill.skill_name}</TableCell>
                      <TableCell>{skill.time}</TableCell>
                      <TableCell>{skill.recent_projects}</TableCell>
                      <TableCell>{skill.count}</TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      )}
      {serachSkill?.action && (
        <Box
          sx={{
            mb: 2,
            display: "flex",
          }}
        >
          <Box fontWeight="bold" sx={{ textTransform: "capitalize" }}>
            Custom Match For {serachSkill?.action} {" : "}
          </Box>
          <Box sx={{ ml: 1 }}> {serachSkill?.values?.join(", ")}</Box>
        </Box>
      )}
      {skillData?.length > 0 && (
        <Box sx={{ mb: 2 }}>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Skill Name</TableCell>
                  <TableCell>Time</TableCell>
                  <TableCell>Recent Projects</TableCell>
                  <TableCell>Count</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Array.isArray(skillData) &&
                  skillData?.map((skill, index) => (
                    <TableRow key={index}>
                      <TableCell>{skill.skill_name}</TableCell>
                      <TableCell>{skill.time}</TableCell>
                      <TableCell>{skill.recent_projects}</TableCell>
                      <TableCell>{skill.count}</TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      )}
      {/* 
      {Array?.isArray(titleMatch) &&
        titleMatch?.map((value, index) => (
          <div key={index}>
            <Box sx={{ mb: 2 }}>
              <Typography variant="body1" fontWeight="bold" component="div">
                Custom Match For Title {value.title_search}
              </Typography>
            </Box>
            <Box sx={{ mb: 2 }} color="red">
              <Typography variant="body1" fontWeight="bold" component="div">
                {value.message}
              </Typography>
            </Box>
          </div>
        ))}
      {titleMatch?.message && (
        <div>
          <Box sx={{ mb: 2, display: "flex" }}>
            <Typography variant="body1" fontWeight="bold" component="div">
              Custom Match For Title :
            </Typography>
            <Typography variant="body1" fontWeight="bold" component="div">
              {titleMatch.title_search}
            </Typography>
          </Box>
          <Box sx={{ mb: 2 }} color="red">
            <Typography variant="body1" component="div">
              {titleMatch.message}
            </Typography>
          </Box>
        </div>
      )} */}
    </div>
  );
};

export default MatchResult;
