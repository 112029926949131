import * as sampleJson from "../sampleJson/sampleParseJson";
//dsfrd
// export const SERVER_URL = 'http://localhost:8000/';

/**-- Authorization Tokens --**/
export const TOKEN_ACCESS = "access";
export const TOKEN_REFRESH = "refresh";

/**-- Page names in the app --**/
export const PAGE_LOGIN = "Login";
export const PAGE_DASHBOARD = "Dashboard";
export const PAGE_DEMO = "Demo";
export const PAGE_PARSE = "Parse";
export const PAGE_RESULT = "Result";
export const MY_PROJECTS = "Projects";
export const PAGE_USERS = "ListUsers";
export const PAGE_STATUS = "InputStatus";
export const PAGE_INFERENCE = "Inference";
export const CREATE_DATASET = "Createdatasets";
export const CREATE_PROJECT = "Createprojects";
export const UPDATE_DATASET = "Updatedatasets";
export const PROJECT_DETAILS = "Projectdetails";
export const TEXT_HIGHLIGHT = "TextHighlight";
export const PAGE_USER = "NewUser";

/**-- Define the Landing Page after signing in --**/
//export const LANDING_PAGE   = PAGE_USERS;
export const LANDING_PAGE = MY_PROJECTS;

/**-- Url for each page --**/
export const PAGE_URLS = {};
PAGE_URLS[PAGE_LOGIN] = "/";
PAGE_URLS[PAGE_PARSE] = "/parseInput";
PAGE_URLS[PAGE_DASHBOARD] = "/dashboard";
PAGE_URLS[PAGE_DEMO] = "/demo";
PAGE_URLS[PAGE_RESULT] = "/parseResult";
PAGE_URLS[MY_PROJECTS] = "/myProjects";
PAGE_URLS[PAGE_USERS] = "/listusers";
PAGE_URLS[PAGE_STATUS] = "/inputstatus";
PAGE_URLS[PAGE_INFERENCE] = "/inference";
PAGE_URLS[CREATE_DATASET] = "/createDatasets";
PAGE_URLS[CREATE_PROJECT] = "/createProjects";
PAGE_URLS[UPDATE_DATASET] = "/updateDatasets";
PAGE_URLS[PROJECT_DETAILS] = "/projectDetails";
PAGE_URLS[TEXT_HIGHLIGHT] = "/texthighlight";
PAGE_URLS[PAGE_USER] = "/newUser";
/**-------------------------------**/

/**-- Supprting File Types for uploading file --**/
export const SUPPORTING_FILE_TYPES_UPLOAD = [".csv", ".xlsx", ".xls"];
export const SUPPORTING_FILE_TYPES = [".doc, .docx, .pdf"];
//export const SUPPORTING_FILE_TYPES = ['.xlsx','.json'];
/**-- Supprting File Types for showing File Results with File Viewer --**/
export const SUPPORTING_FILE_TYPES_FOR_FILE_RESULTS = [];

/**-- Supprting File Types for showing Stacked Results --**/
//export const SUPPORTING_FILE_TYPES_FOR_STACK_RESULTS = ['.txt', '.pdf', '.docx', '.doc', '.json', '.csv', '.xls' , '.xlsx'];
export const SUPPORTING_FILE_TYPES_FOR_STACK_RESULTS = [
  ".txt",
  ".pdf",
  ".docx",
  ".doc",
  ".json",
  ".csv",
  ".xls",
  ".xlsx",
];
/**-------------------------------**/

/**-- HTTP Response Status--**/

export const STATUS_SUCCESS = "success";
export const STATUS_FAILURE = "failure";
export const STATUS_NULL = "null";
export const STATUS_LOADING = "loading";

export const STATUS_MSG = [STATUS_SUCCESS, STATUS_FAILURE, STATUS_NULL];

/**-------------------------------**/

/** Error Message to be displayed in the screen **/
export const ERROR_MSG_LOADING = "loading";
export const ERROR_MSG_NORESULT = "noResult";
export const ERROR_MSG_SERVER_ERROR = "serverError";
export const ERROR_MSG_ACCESS_ERROR = "accessError";
export const NO_ENTITY_DETECTED = "noEntityError";

export const ERROR_MSG = [
  ERROR_MSG_LOADING,
  ERROR_MSG_NORESULT,
  ERROR_MSG_SERVER_ERROR,
];

/**-------------------------------**/

/**------------- Formatting the Json got from the server ---------------**/

export const formatResultsJson = (resultsJson) => {
  console.log("in formatResultsJson func  ", resultsJson);
  let formattedJson = [];

  let currentIndex = 0;

  let previousEntity = { previousStartIndex: 0, previousEndIndex: 0 };

  let content = resultsJson.content;

  // Loop through the entity details under each meta key
  if (JSON.parse(resultsJson.meta) && JSON.parse(resultsJson.meta).length > 0) {
    //resultsJson.meta.sort((a, b) => (a.start_index > b.start_index) ? 1 : -1);

    JSON.parse(resultsJson.meta).forEach((metaObj, index) => {
      console.log("currentIndex  ", currentIndex);
      console.log("metaObj.start.index  ", metaObj.start_index);
      // For normal text without any enitities
      if (currentIndex < metaObj.start_index)
        formattedJson.push(
          formatNoEntityContent(content, currentIndex, metaObj.start_index)
        );

      // For the text that is tagged with entity

      formattedJson = formatOverlappingEntity(
        previousEntity,
        metaObj,
        content,
        formattedJson
      );
      // console.log("9666666666666  ",formattedJson);
      // switch the current index and previous entity
      let currentObj = formattedJson[formattedJson.length - 1];

      currentIndex = currentObj.fullEndIndex;
      previousEntity = {
        previousStartIndex: currentObj.fullEndIndex,
        previousEndIndex: currentObj.fullEndIndex,
      };
    });
  }

  // Once we looped through and if any text is left then push it to the content sentence
  if (currentIndex < content.length)
    formattedJson.push(
      formatNoEntityContent(content, currentIndex, content.length)
    );

  console.log("formattedJson mainnnnnnn", formattedJson);
  //  console.log('type of formattedjson  ', typeof(formattedJson));
  //  formattedJson.reduce((form,b) => a+b);

  console.log("json", formattedJson);
  return formattedJson;
};

/**------If there is no entity taged then it is a normal text, so define the Obj accordingly-----**/
export const formatNoEntityContent = (content, startIndex, endIndex) => {
  console.log("startIndex ", startIndex);
  console.log("endIndex ", endIndex);
  var fulltet = content.substring(startIndex, endIndex);

  console.log("fulltet ", fulltet);

  let normalTextObj = {
    fullText: content.substring(startIndex, endIndex),
    overlappingEntities: [],
    fullStartIndex: startIndex,
    fullEndIndex: endIndex,
  };
  // console.log("======no entity======");
  console.log("normalTextObj ", normalTextObj);
  console.log("normalTextObj.fullText  ", normalTextObj.fullText);
  return normalTextObj;
};

/**----- For the Text that contains the Entity tagged in it -----**/
export const formatOverlappingEntity = (
  previousEntity,
  metaObj,
  content,
  formattedJson
) => {
  //  console.log("=============Entity========");
  console.log("previousEntity ", previousEntity);
  console.log("metaObj ", metaObj);
  console.log("content ", content);
  console.log("formattedJson ", formattedJson);
  /* Considering
    s1 = previousStartIndex;    e1 = previousEndIndex;

    s2 = currentStartIndex;     e2 = currentEndIndex;
    */

  /* No overlapping, so set the default entityObj 
        Scenario 1 : 

        s1 ----------------- e1
                               s2-------------------e2
                                   
        ****    e1 < s2   ****
    */

  if (previousEntity.previousEndIndex <= metaObj.start_index) {
    let entityObj = {
      fullText: metaObj.element,
      overlappingEntities: [metaObj],
      fullStartIndex: metaObj.start_index,
      fullEndIndex: metaObj.end_index,
    };

    formattedJson.push(entityObj);
    console.log("entityObj.fullText ", entityObj);
    console.log("formattedJson ", formattedJson);
  }

  // ****    e1 > s2   ****
  // Overlapping Content
  else {
    let previousObj = formattedJson[formattedJson.length - 1];

    // Take the smallest start index as the full text start index
    if (previousObj.fullStartIndex > metaObj.start_index)
      previousObj.fullStartIndex = metaObj.start_index;

    // Take the largest end index as the full text end index
    if (previousObj.fullEndIndex < metaObj.end_index)
      previousObj.fullEndIndex = metaObj.end_index;

    // Get the overlapped full text content with many tags
    previousObj.fullText = content.substring(
      previousObj.fullStartIndex,
      previousObj.fullEndIndex
    );
    console.log("previousObj.fullText ", previousObj.fullText);

    previousObj.overlappingEntities.push(metaObj);

    previousObj.overlappingEntities.sort((a, b) =>
      a.start_index !== b.start_index
        ? a.start_index > b.start_index
          ? 1
          : -1
        : a.end_index > b.end_index
        ? 1
        : -1
    );

    formattedJson[formattedJson.length - 1] = previousObj;
  }

  console.log("formattedjsonnnnnnnnn ", formattedJson);
  return formattedJson;
};
/**-------------------------------**/

export const formatResultsJsonnew = (resultsJson) => {
  let formattedJson = [];

  let currentIndex = 0;

  let previousEntity = { previousStartIndex: 0, previousEndIndex: 0 };

  let content = resultsJson.content;

  // Loop through the entity details under each meta key
  if (resultsJson.meta && resultsJson.meta.length > 0) {
    resultsJson.meta.sort((a, b) => (a.start_index > b.start_index ? 1 : -1));

    resultsJson.meta.forEach((metaObj, index) => {
      // For normal text without any enitities
      if (currentIndex < metaObj.start_index)
        formattedJson.push(
          formatNoEntityContent(content, currentIndex, metaObj.start_index)
        );

      // For the text that is tagged with entity
      formattedJson = formatOverlappingEntitynew(
        previousEntity,
        metaObj,
        content,
        formattedJson
      );

      // switch the current index and previous entity
      let currentObj = formattedJson[formattedJson.length - 1];

      currentIndex = currentObj.fullEndIndex;
      previousEntity = {
        previousStartIndex: currentObj.fullEndIndex,
        previousEndIndex: currentObj.fullEndIndex,
      };
    });
  }

  // Once we looped through and if any text is left then push it to the content sentence
  if (currentIndex < content.length)
    formattedJson.push(
      formatNoEntityContentnew(content, currentIndex, content.length)
    );

  // console.log('formattedJson', formattedJson);
  return formattedJson;
};

/**------If there is no entity taged then it is a normal text, so define the Obj accordingly-----**/
export const formatNoEntityContentnew = (content, startIndex, endIndex) => {
  let normalTextObj = {
    fullText: content.substring(startIndex, endIndex),
    overlappingEntities: [],
    fullStartIndex: startIndex,
    fullEndIndex: endIndex,
  };
  // console.log('normalTextObj',normalTextObj.fullText);
  return normalTextObj;
};

/**----- For the Text that contains the Entity tagged in it -----**/
export const formatOverlappingEntitynew = (
  previousEntity,
  metaObj,
  content,
  formattedJson
) => {
  /* Considering
    s1 = previousStartIndex;    e1 = previousEndIndex;

    s2 = currentStartIndex;     e2 = currentEndIndex;
    */

  /* No overlapping, so set the default entityObj 
        Scenario 1 : 

        s1 ----------------- e1
                               s2-------------------e2
                                   
        ****    e1 < s2   ****
    */

  if (previousEntity.previousEndIndex <= metaObj.start_index) {
    let entityObj = {
      fullText: metaObj.element,
      overlappingEntities: [metaObj],
      fullStartIndex: metaObj.start_index,
      fullEndIndex: metaObj.end_index,
    };

    formattedJson.push(entityObj);
  }

  // ****    e1 > s2   ****
  // Overlapping Content
  else {
    let previousObj = formattedJson[formattedJson.length - 1];

    // Take the smallest start index as the full text start index
    if (previousObj.fullStartIndex > metaObj.start_index)
      previousObj.fullStartIndex = metaObj.start_index;

    // Take the largest end index as the full text end index
    if (previousObj.fullEndIndex < metaObj.end_index)
      previousObj.fullEndIndex = metaObj.end_index;

    // Get the overlapped full text content with many tags
    previousObj.fullText = content.substring(
      previousObj.fullStartIndex,
      previousObj.fullEndIndex
    );

    previousObj.overlappingEntities.push(metaObj);

    previousObj.overlappingEntities.sort((a, b) =>
      a.start_index !== b.start_index
        ? a.start_index > b.start_index
          ? 1
          : -1
        : a.end_index > b.end_index
        ? 1
        : -1
    );

    formattedJson[formattedJson.length - 1] = previousObj;
  }
  return formattedJson;
};
/**-------------------------------**/

/**----- 100 static Stack results for UI testing  -----**/
export const staticStackResults = () => {
  let stackResultsJsonArr = [];
  let resultsJsonArr = [];
  resultsJsonArr.push(sampleJson.parseTextContentResultJson0);
  resultsJsonArr.push(sampleJson.parseTextContentResultJson1);
  resultsJsonArr.push(sampleJson.parseTextContentResultJson2);
  resultsJsonArr.push(sampleJson.parseTextContentResultJson3);
  resultsJsonArr.push(sampleJson.parseTextContentResultAllTagsJson2);
  while (stackResultsJsonArr.length < 100) {
    let element =
      resultsJsonArr[Math.floor(Math.random() * resultsJsonArr.length)];
    stackResultsJsonArr.push(element);
  }

  return stackResultsJsonArr;
};

/**-------------------------------**/
