import React, { Component } from "react";

// Importing HTML Elements from Material UI
import { Card, Box } from "@material-ui/core";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";

// Importing Common utils file
import * as c from "../../utils/constants/contants";
import "./style.css";

class FileUpload extends Component {
  render() {
    let { toggle, handleFileUpload } = this.props;
    console.log(this.props, "****asdf");
    return (
      <Card raised={toggle ? true : false} className="uploadFileCol">
        {/*--- Input Field for the File type and also Drag and Drop functionalities ----*/}
        {this.props.loading && (
          <input
            type="file"
            name="videofile"
            className="uploadFiles"
            title=" "
            /* multiple keyword lets you add multiple files */
            // multiple
            disabled
            id="uploadFile"
            accept={c.SUPPORTING_FILE_TYPES}
            onChange={handleFileUpload}
            onDragOver={(event) =>
              event.target.parentNode.classList.add("fileDragOver")
            }
            onDragLeave={(event) =>
              event.target.parentNode.classList.remove("fileDragOver")
            }
            onDrop={(event) =>
              event.target.parentNode.classList.remove("fileDragOver")
            }
          />
        )}

        {!this.props.loading && (
          <input
            type="file"
            name="videofile"
            className="uploadFiles"
            title=" "
            /* multiple keyword lets you add multiple files */
            // multiple
            disabled={this.props.files.name}
            id="uploadFile"
            accept={c.SUPPORTING_FILE_TYPES}
            onChange={handleFileUpload}
            onDragOver={(event) =>
              event.target.parentNode.classList.add("fileDragOver")
            }
            onDragLeave={(event) =>
              event.target.parentNode.classList.remove("fileDragOver")
            }
            onDrop={(event) =>
              event.target.parentNode.classList.remove("fileDragOver")
            }
          />
        )}

        {/*------------------------------------------------------------*/}

        {/*---- Drag and Drop Lines to display in the UI ------*/}

        <Box align="center" p={0}>
          <CloudUploadIcon className="material-icons" />

          <div class="fileWordings">
            <p>DRAG AND DROP YOUR FILE(S) HERE</p>

            <p>OR</p>

            <div class="btn"> Select file </div>
            <br />

            <span>Supported Formats (.doc, .docx, .pdf)</span>
            <br />
            {/*<span class="errorColor"> File processing limit : 10 files at a time</span>*/}
            <br />
          </div>
        </Box>
        {/*------------------------------------------------------------*/}
      </Card>
    );
  }
}

export default FileUpload;
