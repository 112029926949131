import moment from "moment";
import { MESSAGES } from "./constants";

export const debounce = (func) => {
  let timer;
  return function (...args) {
    const context = this;
    if (timer) clearTimeout(timer);
    timer = setTimeout(() => {
      timer = null;
      func.apply(context, args);
    }, 1000);
  };
};

export function getPosition(string, subString, index) {
  return string.split(subString, index).join(subString).length;
}

export const downloadClick = (download_url) => {
  window.open(download_url);
};

export const formatDate = (date, format = "YYYY/MM") =>
  date ? moment(new Date(date)).format(format) : null;

/**
 *
 * @param {Object} schema
 * @param {Boolean} required
 * @returns
 */
export const requiredSchema = (
  schema,
  required = true,
  requiredMessage = "Please fill the required field"
) => {
  return required ? schema.required(requiredMessage) : schema;
};

export const replacePlainURLWithLink = (text = "") => {
  // Put the URL to variable $1 after visiting the URL
  var Rexp =
    /((http|https|ftp):\/\/[\w?=&.\/-;#~%-]+(?![\w\s?&.\/;#~%"=-]*>))/g;

  // Replace the RegExp content by HTML element
  return text.replace(
    Rexp,
    "<a href='$1' id='client_cover_sheet_form_link' target='_blank'>$1</a>"
  );
};

export const chunkSubstr = (str, size) => {
  const numChunks = Math.ceil(str.length / size);
  const chunks = new Array(numChunks);

  for (let i = 0, o = 0; i < numChunks; ++i, o += size) {
    chunks[i] = str.substr(o, size);
  }

  return chunks;
};

export const hyphenationCallback = (word) => {
  // Return word syllables in an array
  if (word.length > 16) {
    return chunkSubstr(word, 14);
  } else {
    return [word];
  }
};

export const formattedDateStringToDate = (str) => {
  var date = null;
  if (str && typeof str === "string") {
    var val = str.split(/\-|\//);
    if (val[0]) {
      if (val[0].length !== 4) {
        val = val.reverse();
      }
      val = val.join("-");
      if (moment(val).isValid()) date = new Date(val);
    }
  }
  return date;
};

export const convertTZ = (date, tzString = "Asia/Kolkata") => {
  return new Date(
    (typeof date === "string" ? new Date(date) : date).toLocaleString("en-US", {
      timeZone: tzString,
    })
  );
};

export const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export const getOffset = (page = 1, size = 10) => {
  let pageValue = Number(page);
  pageValue = pageValue > 0 ? pageValue - 1 : 0;
  let offset = pageValue * size;
  return offset;
};

export const getPageUsingRemovingAnElement = (
  totalElement = 0,
  page = 1,
  size = 10
) => {
  let newPage = Math.ceil((totalElement - 1) / size) || 1;
  if (page <= newPage) newPage = page;
  return newPage;
};

/**
 * To parse the local storage value
 * @param {String} key
 * @returns Objet / String
 */
export const getLocalStorageParseValue = (key) =>
  JSON.parse(localStorage.getItem(key) || "{}");

export const errorResponse = (error) => {
  let obj = {
    status: 400,
    message: MESSAGES.error,
  };
  if (error && error.response && typeof error.response === "object") {
    obj = {
      ...obj,
      ...error.response,
    };
    if (
      typeof error.response.data === "object" &&
      error.response.data.message
    ) {
      obj.message = error.response.data.message;
    } else if (
      typeof error.response.data === "object" &&
      error.response.data.error
    ) {
      obj.message = error.response.data.error;
    } else if (error.response.statusText) {
      obj.message = error.response.statusText;
    }
  }
  return obj;
};
