import React, { Component } from "react";
import { Autocomplete, Col, Row } from "react-materialize";

import { Button } from "@material-ui/core";

import TopSkillsTableReadOnly from "../TopSkillsTableReadOnly";

import CloseIcon from "@material-ui/icons/Close";

import commonAPI from "../../utils/API/commonAPI";
import resumeAPI from "../../utils/API/resumeAPI";

import * as c from "../../utils/constants/constants";

import $ from "jquery";
import "./style.css";

class CustomMatch extends Component {
  state = {
    showTitleMatch: false,
    showSkillMatch: false,
    customSkillQueryList: {
      id: "",
      action: "",
      values: [],
      skill: {},
      title: {},
      // resumeNames: [],
      // date: '',
    },
    querySkillList: [],
    customMatchRoleList: [],
    autoCompleteSkillsData: {},
    customMatchskillList: [],
    customMatchskillNotFound: [],
  };

  getAndSetSkillList = () => {
    commonAPI
      .fetchAutoCompleteSkillList(this.props.category)
      .then((data) => {
        this.setState({
          autoCompleteSkillsData: c.formatAutocompleteSkills(data),
        });
      })
      .catch((err) => {
        console.log("Autocomplete Skills error");
      });
  };

  getAndSetRoleList = () => {
    commonAPI
      .fetchCustomMatchRolelList(this.props.category)
      .then((data) => {
        var rdata = Array(data);
        if (JSON.parse(rdata).length > 0)
          this.setState({ customMatchRoleList: JSON.parse(rdata) });
        // console.log(this.state.customMatchRoleList);
      })
      .catch((err) => {
        console.log("Autocomplete Role error");
      });
  };

  componentDidMount = () => {
    this.getAndSetSkillList();
    this.getAndSetRoleList();

    if (this.props.actionPage === c.MAIN_SECTION_DIV_PARSE_RESUME) {
      this.customMatchDisableCheck("check");
      this.setState({ showSkillMatch: true });
      this.setState({ showTitleMatch: true });
    }
  };

  componentDidUpdate = (prevProps) => {
    const { category } = this.props.category;
    $("input.autocomplete").val("");
    console.log(this.props.category, "this.props.category=================");
    console.log(prevProps.category, "prevProps.category=================");

    if (category !== prevProps.category.category) {
      if (category) {
        this.getAndSetRoleList();
        this.getAndSetSkillList();
        this.setState({
          autoCompleteSkillsData: [],
          customMatchRoleList: [],
          querySkillList: [],
          customSkillQueryList: {
            id: "",
            action: "",
            values: [],
            skill: {},
            title: {},
            // resumeNames: [],
            // date: '',
          },
        });
      }
    }
  };

  handleOnAutocomplete = () => {
    let completedText = $("input.autocomplete").val();

    let updateQuerySkillList = this.state.querySkillList;

    var updatedArr = updateQuerySkillList.filter(
      (element) => completedText !== element
    );

    if (completedText.indexOf("keyboard_arrow_right") < 0)
      updatedArr.push(completedText);

    this.setState({ querySkillList: updatedArr });
    this.handleCustomMatchQueryChange("skill", updatedArr);

    if (this.props.actionPage === c.MAIN_SECTION_DIV_PARSE_RESUME)
      this.customMatchDisableCheck("check");
  };

  handleRemoveQuerySkill = (event, querySkill) => {
    // event.preventDefault();
    var querySkillListArr = this.state.querySkillList;

    var updatedArr = querySkillListArr.filter(
      (element) => querySkill !== element
    );

    this.setState({ querySkillList: updatedArr });
    this.handleCustomMatchQueryChange("skill", updatedArr);

    if (this.props.actionPage === c.MAIN_SECTION_DIV_PARSE_RESUME)
      this.customMatchDisableCheck("check");
  };

  handleMatchRadioChange = (action) => {
    if (action === "title") {
      this.setState({ showTitleMatch: true });
      this.setState({ showSkillMatch: false });
    } else if (action === "skill") {
      this.setState({ showTitleMatch: false });
      this.setState({ showSkillMatch: true });
    } else {
      $("#roleListDropDownInput").val("");
      this.handleCustomMatchQueryChange("id", "");
    }
    this.props.handleClear();
    this.setState({ querySkillList: [] });
    this.handleCustomMatchQueryChange("clear", []);
  };

  handleCustomMatchQueryChange = (key, value) => {
    console.log(key, value, "value___________");
    let updateCustomSkillList = this.state.customSkillQueryList;

    if (this.props.currentResumeId && this.props.currentResumeId !== "")
      updateCustomSkillList.id = this.props.currentResumeId;

    if (key === "title") {
      let checkInTitleList = this.state.customMatchRoleList.filter(
        (element) => value === element
      );

      if (value !== "" && checkInTitleList.length === 1) {
        $("#roleListDropDownInput").removeClass("changedInputText");
        value = [value];
      } else {
        if (value !== "")
          $("#roleListDropDownInput").addClass("changedInputText");
        else $("#roleListDropDownInput").removeClass("changedInputText");
        value = [];
      }
    }

    if (key === "skill" || key === "title") {
      if (value.length !== 0) {
        updateCustomSkillList[key] = { action: key, values: value };
        updateCustomSkillList.action = key;
        updateCustomSkillList.values = value;
      } else {
        updateCustomSkillList[key] = {};

        let otherAction = key === "skill" ? "title" : "skill";
        if (
          updateCustomSkillList[otherAction].values &&
          updateCustomSkillList[otherAction].values.length > 0
        ) {
          updateCustomSkillList.values =
            updateCustomSkillList[otherAction].values;
          updateCustomSkillList.action =
            updateCustomSkillList[otherAction].action;
        } else {
          updateCustomSkillList.values = [];
          updateCustomSkillList.action = "";
        }
      }
    } else if (key === "clear") {
      updateCustomSkillList = {
        id: "",
        action: "",
        values: [],
        skill: {},
        title: {},
      };
      this.props.clearValues();
    } else updateCustomSkillList[key] = value;

    this.setState({ customSkillQueryList: updateCustomSkillList });

    if (this.props.actionPage === c.MAIN_SECTION_DIV_PARSE_RESUME) {
      // console.log('CustomMatchQuery',updateCustomSkillList)
      this.props.updateCustomMatchQuery(updateCustomSkillList);
      this.customMatchDisableCheck("check");
    }
  };

  fetchCustomSkillMatchList = (event) => {
    resumeAPI
      .fetchMatchedCustomSkillList(this.state.customSkillQueryList)
      .then((res) => {
        let action = this.state.customSkillQueryList.action;

        let responseData = res.data[action];

        this.setState({ customMatchskillList: responseData.skills_display });

        console.log("responseData.skills_display", responseData);

        if (responseData.skills_display.length === 0)
          this.setState({
            customMatchskillNotFound:
              this.state.customSkillQueryList[action].values,
          });
        else
          this.setState({
            customMatchskillNotFound: responseData.not_found_list,
          });

        let customMatchResultHeadingText =
          "Custom Skill Matched for " +
          action.toUpperCase() +
          " : " +
          this.state.customSkillQueryList[action].values.join(" , ");
        $("#customMatchResultHeading").html(customMatchResultHeadingText);
      })
      .catch((err) => {
        c.APIErrorResponse(err, "Custom Skill Match Failure");

        if (window.location.host === c.LOCAL_ENVIORNMENT) {
        }
      });
    $(".autocomplete").val("");
  };

  customMatchDisableCheck = (action) => {
    //console.log('testjp',this.state.customSkillQueryList.title);
    if (
      $("#roleListDropDownInput").val() !== "" &&
      Object.keys(this.state.customSkillQueryList.title).length === 0 &&
      Object.keys(this.state.customSkillQueryList.skill).length === 0
    ) {
      console.log("Please select Role from the given options");
      $("#customMatchClickErrorMsg").html(
        "Please select Role from the given options"
      );
      $("#customMatchClickErrorMsg").show();
      if (action === "disableCheck") return true;
    } else {
      console.log("Please select Role from the given options");
      /*else if (this.props.isResumeNotSelected) {
            $('#customMatchClickErrorMsg').html('Upload Resumes to start Custom Match');
            $('#customMatchClickErrorMsg').show();
            if(action === 'disableCheck') return true;
        }

        else if ($('#resumeDropDown').val().length === 0) {
            $('#customMatchClickErrorMsg').html('Select Resumes from the DropDown');
            $('#customMatchClickErrorMsg').show();
            if(action === 'disableCheck') return true;
        }
*/
      /*else if (this.state.customSkillQueryList.action === '' ||
            this.props.isDisabled === true) {
            $('#customMatchClickErrorMsg').html('Select Custom Match Criteria');
            $('#customMatchClickErrorMsg').show();
            if(action === 'disableCheck') return true;
        }*/
      $("#customMatchClickErrorMsg").hide();
      // if (action === "disableCheck") return false;
      // else
      if (action === "customMatch") this.props.formatCustomMatchSkillJson();
    }
  };

  render() {
    console.log(this.state.customSkillQueryList, "|||||||");
    const { actionPage, isDisabled, isHidden, updateIsHidden } = this.props;
    let {
      querySkillList,
      customMatchRoleList,
      autoCompleteSkillsData,
      showSkillMatch,
      customMatchskillList,
    } = this.state;

    let isDisable = (isDisabled && isDisabled) === true ? true : false;

    return (
      <>
        {actionPage === c.MAIN_SECTION_DIV_PARSE_RESUME && (
          <div hidden={isHidden}>
            <Row>
              <Col s={12}>
                <p>
                  <span hidden={!isHidden}> Please select a </span>
                  Match criteria<span className="mandatory boldPara">*</span> :
                </p>
              </Col>
            </Row>
          </div>
        )}
        {/* Title Custom Match radio button and its DropDown Row */}
        <Row>
          <Col s={12}>
            <div className="carousal carousal-slider">
              <p className="carousal-item">
                Please select a title (max 1) and/or skills (max 9)
              </p>
            </div>
          </Col>
        </Row>
        <br />
        <Row>
          <Col s={3} className="inputFields">
            <p className="boldPara"> Title</p>
          </Col>
          <Col l={9} s={8} className="inputFields">
            <div className="input-field">
              <input
                list="roleListDropDown"
                className="col s12 rolename"
                type="text"
                disabled={isDisable}
                id="roleListDropDownInput"
                onChange={(event) => {
                  // let value = event.target.value !== '' ? [event.target.value] : [];
                  this.handleCustomMatchQueryChange(
                    "title",
                    event.target.value
                  );
                }}
              />
              <datalist id="roleListDropDown">
                {customMatchRoleList &&
                  customMatchRoleList.length !== 0 &&
                  customMatchRoleList.map((item, index) => (
                    <option
                      value={item}
                      key={index}
                      style={{ width: "1000px", padding: "10px" }}
                    >
                      {" "}
                    </option>
                  ))}
              </datalist>
            </div>
          </Col>
        </Row>
        <Col s={9} className="offset-s2">
          <p className="errMsg" id="customMatchClickErrorMsg" hidden>
            {" "}
          </p>
        </Col>

        {/* Skill Custom Match radio button and its Autocomplete Row */}
        <Row>
          <Col s={3} className="inputFields">
            <p className="boldPara"> Skill</p>
          </Col>
          <Col l={9} s={8} className="inputFields">
            <Autocomplete
              className="col s12"
              hidden={querySkillList.length >= 9 || isDisabled === true}
              options={{
                data: autoCompleteSkillsData,
                onAutocomplete: this.handleOnAutocomplete,
              }}
              preventDefault=""
              placeholder="Insert here"
            />
          </Col>
        </Row>

        {/* Selected skills for the custom Match showed as chips   */}
        {showSkillMatch && (
          <Row>
            <Col s={9} className="offset-s2">
              {querySkillList.length > 0 &&
                querySkillList.map((querySkill, index) => (
                  <div className="chip" key={index}>
                    {querySkill}
                    <CloseIcon
                      onClick={(event) =>
                        this.handleRemoveQuerySkill(event, querySkill)
                      }
                    />
                  </div>
                ))}
            </Col>
          </Row>
        )}

        {/* Button for the Custom Match Section */}
        {actionPage === c.MAIN_SECTION_DIV_PARSE_RESUME && (
          <>
            {" "}
            <Row hidden={isHidden}>
              <Col s={12} className="buttonDiv">
                <Button
                  //disabled={this.customMatchDisableCheck('disableCheck')}
                  // disabled={querySkillList.length == 0}
                  // onClick={()=>  $('#resumeDropDown').val().length === 0 && formatCustomMatchSkillJson}
                  onClick={() => this.customMatchDisableCheck("customMatch")}
                >
                  Compare
                </Button>

                <Button onClick={(event) => this.handleMatchRadioChange("")}>
                  Clear
                </Button>
              </Col>

              {/*<Col s={12} className='center-align reports'>
                            <span className='errorColor'>
                                Custom match report limit : 5 reports at a time
							</span>
                            <br />
                </Col>*/}
            </Row>
            <div hidden={!isHidden} className="buttonDiv">
              <Button
                onClick={updateIsHidden}
                disabled={this.state.customSkillQueryList.values.length === 0}
              >
                Done
              </Button>

              {/*<Button onClick={updateIsHidden}> Skip for now</Button>*/}
              <br />
            </div>
          </>
        )}
        {actionPage === c.MAIN_SECTION_DIV_SEARCH_RESUME && (
          <Row>
            <Col s={12}>
              <p className="customMatchResultSkillList">
                <span
                  className="boldPara sideNavHeader"
                  id="customMatchResultHeading"
                ></span>
              </p>
            </Col>
            {this.state.customMatchskillNotFound.length > 0 && (
              <p className="skillMatchNotFound">
                Match not found for &nbsp;&nbsp;
                <span className="boldPara">
                  {this.state.customMatchskillNotFound.join(" , ")}
                </span>
              </p>
            )}
            {customMatchskillList && customMatchskillList.length > 0 && (
              <Col l={12} s={10} className="skillMatchTable offset-s1">
                <TopSkillsTableReadOnly
                  topSkills={this.state.customMatchskillList}
                />
              </Col>
            )}
          </Row>
        )}
      </>
    );
  }
}

export default CustomMatch;
