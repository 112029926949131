import { Box, Button, Typography } from "@material-ui/core";
import Autocomplete from "@mui/material/Autocomplete";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";
import RadioGroup from "@mui/material/RadioGroup";
import TextField from "@mui/material/TextField";
import React, { useState } from "react";
import LoadingButton from "../../../commonComponents/LoadingButton";
function CustomMatch({
  setSelectedSkill,
  setSelectedTitle,
  selectedTitle,
  selectedSkill,
  skillList,
  titleList,
  errormatch,
  handleMatch,
}) {
  const [selectedOption, setSelectedOption] = useState("Title");

  const handleChangeOption = (event) => {
    setSelectedOption(event.target.value);
  };
  const handleChangeTitle = (event, newValues) => {
    if (newValues) {
      setSelectedTitle([newValues]);
    }
  };

  const handleChangeSkill = (event, newValues) => {
    setSelectedSkill(newValues);
  };
  console.log(skillList);

  return (
    <Box p={1}>
      <Box sx={{ width: "100%", p: 2 }}>
        <Box>
          <Typography
            variant="body1"
            color="primary"
            fontWeight="bold"
            component="div"
          >
            Please select either a title (max 1) or skill (max 9).
          </Typography>
        </Box>
        <RadioGroup value={selectedOption} onChange={handleChangeOption}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              mt: 2,
            }}
          >
            <FormControlLabel value="Title" control={<Radio />} label="Title" />
            {selectedOption === "Title" && (
              <Autocomplete
                sx={{ width: "70%" }}
                size="small"
                value={selectedTitle}
                onChange={handleChangeTitle}
                options={titleList}
                getOptionLabel={(option) => option}
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" />
                )}
              />
            )}
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              mt: 2,
            }}
          >
            <FormControlLabel value="Skill" control={<Radio />} label="Skill" />
            {selectedOption === "Skill" && (
              <Autocomplete
                sx={{ width: "70%" }}
                size="small"
                multiple
                value={selectedSkill}
                onChange={handleChangeSkill}
                options={skillList}
                getOptionLabel={(option) => option?.display}
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" />
                )}
              />
            )}
          </Box>
        </RadioGroup>
        <Box sx={{ display: "flex", justifyContent: "center", mt: 1 }}>
          <LoadingButton onClick={handleMatch} autoFocus>
            <b>{"match"}</b>
          </LoadingButton>
        </Box>
        {errormatch && (
          <Box color="red" fontWeight="bold">
            {errormatch}
          </Box>
        )}
      </Box>
    </Box>
  );
}

export default CustomMatch;
