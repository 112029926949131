// Sample Json from the backend for testing front end results section
export const parseTextContentResultJson0 ={
	"content":"I recently purchased the new Oil Free Toner and was so excited to try it, as I've always been a loyal Neutrogena customer. After a couple days of using this product, my cheeks broke out horribly with a rash. I've never had this kind of reaction to any product, especially not Neutrogena products. I didn't keep the receipt from where I purchased it, as I expected great results from a Neutrogena product. Any way you could get me a refund would be greatly appreciated. Thank you.   Consumer used about a cotton swab once a day for two days. She states she uses 10 milligrams of Lexapro for about 3 months now. The use of the medication is for depression. She does not recall the exact date when she started using the medication is still using it. Consumer does not recall when she first started using it. She estimated between a time period of 2 to 3 weeks, she only used it twice. Two days after she started, she noticed the rash. She does not recall the exact date when she stopped but a few days later of not using it, her rash cleared up.",
	"meta":[
		{
			"entity_type":"DrugProd",
			"element":"Oil Free Toner",
			"start_index":29,
			"end_index":43,
			"confidence_score":0.998
		},
		{
			"entity_type":"DrugProd",
			"element":"Neutrogena",
			"start_index":102,
			"end_index":112,
			"confidence_score":0.998
		},
		{
			"entity_type":"Event",
			"element":"broke out",
			"start_index":176,
			"end_index":185,
			"confidence_score":0.998
		},
		{
			"entity_type":"Event",
			"element":"rash",
			"start_index":202,
			"end_index":206,
			"confidence_score":0.998
		},
		{
			"entity_type":"MedHistory",
			"element":"never had this kind of reaction",
			"start_index":213,
			"end_index":244,
			"confidence_score":0.998
		},
		{
			"entity_type":"frequency",
			"element":"once a day for two days",
			"start_index":516,
			"end_index":539,
			"confidence_score":0.998
		},
		{
			"entity_type":"Dosage",
			"element":"10 milligrams",
			"start_index":561,
			"end_index":574,
			"confidence_score":0.998
		},
		{
			"entity_type":"DrugProd",
			"element":"Lexapro",
			"start_index":578,
			"end_index":585,
			"confidence_score":0.998
		},
		{
			"entity_type":"Duration",
			"element":"3 months",
			"start_index":596,
			"end_index":604,
			"confidence_score":0.998
		},
		{
			"entity_type":"Indication",
			"element":"depression",
			"start_index":643,
			"end_index":653,
			"confidence_score":0.998
		},
		{
			"entity_type":"Duration",
			"element":"2 to 3 weeks",
			"start_index":844,
			"end_index":856,
			"confidence_score":0.998
		},
		{
			"entity_type":"frequency",
			"element":"twice",
			"start_index":875,
			"end_index":880,
			"confidence_score":0.998
		},
		{
			"entity_type":"Outcome",
			"element":"rash cleared up",
			"start_index":1026,
			"end_index":1041,
			"confidence_score":0.998
		}
	]
}

// Sample Json for the Reults Section of the Parse Content
export const parseTextContentResultJson1 = {
    content : 'In this patient the rippling phenomena worsened with pyridostigmine treatment but markedly improved after immunosuppression with azathioprine and she had no vomiting.Severe rash on face and leg, slightly itchy',
    performance_matrix: {
        accuracy: '5',
    },
    meta :[{
            entity_type: 'drug',
            element : 'pyridostigmine',
            start_index : 53,
            end_index : 67,
            confidence_score : 3
        },
        {
            entity_type: 'treatment',
            element : 'treatment',
            start_index : 68,
            end_index : 77,
            confidence_score : 1
        },
        {
            entity_type: 'treatment',
            element : 'immunosuppression',
            start_index : 106,
            end_index : 123,
            confidence_score : 6
        },
        {
            entity_type: 'drug',
            element : 'immunosuppression',
            start_index : 106,
            end_index : 123,
            confidence_score : 5
        },
        {
            entity_type: 'event',
            element : 'immunosuppression',
            start_index : 106,
            end_index : 123,
            confidence_score : 1
        },
        {
            entity_type: 'drug',
            element : 'azathioprine',
            start_index : 129,
            end_index : 141,
            confidence_score : 3
        },
        {
            entity_type: 'negatedEvent',
            element : 'no vomiting',
            start_index : 154,
            end_index : 165,
            confidence_score : 4
        },
        {
            entity_type: 'event',
            element : 'Severe rash',
            start_index : 166,
            end_index : 184,
            confidence_score : 2
        },
        // {
        //     entity_type: 'disease',
        //     element : 'rash',
        //     start_index : 173,
        //     end_index : 177,
        //     confidence_score : 2
        // },
    ]
}

// Sample Json for the Reults Section of the Parse Content
export const parseTextContentResultJson2 = {
    content : 'Sleeping Trouble on precent dosage of Clonidine. Severe rash on face and leg, slightly itchy',
    meta :[{
            entity_type: 'drug',
            element : 'Clonidine',
            start_index : 38,
            end_index : 47,
        },
        {
            entity_type: 'disease',
            element : 'rash',
            start_index : 56,
            end_index : 60,
        },
        {
            entity_type: 'event',
            element : 'itchy',
            start_index : 87,
            end_index : 92,
        }
    ]
}

// Sample Json for the Reults Section of the Parse Content
export const parseTextContentResultJson3 = {
	"content":"I used a facial cleanser of yours for about 2 weeks, but it caused my skin to become very dry and begin to flake . I started using this product in August for 2 weeks solid. I did not seek out medical attention for this reaction, but the problems I was having with my face prompted me to ask if there was a prescription bar soap that I could use for my face. He prescribed penoxil bar soap. I t took about 2 days for my face to clear up . I am recovered now.",
	"meta":[
		{
			"entity_type":"DrugProd",
			"element":"facial cleanser",
			"start_index":9,
			"end_index":24,
			"confidence_score":0.999
		},
		{
			"entity_type":"Duration",
			"element":"2 weeks",
			"start_index":44,
			"end_index":51,
			"confidence_score":0.999
		},
		{
			"entity_type":"Event",
			"element":"very dry",
			"start_index":85,
			"end_index":93,
			"confidence_score":0.999
		},
		{
			"entity_type":"Outcome",
			"element":"recovered",
			"start_index":443,
			"end_index":452,
			"confidence_score":0.999
		}
	]
}

// Sample Json for the Reults Section of the Parse Content
export const parseFileUploadResultJson1 = {
    content :   "Acute erythroid leukemia after cyclophosphamide therapy for multiple myeloma: report of two cases. Near fatal acute colchicine intoxication in a child. Toxicity related to chloroquine treatment of resistant vivax malaria.",
        meta :[{
                entity_type: 'event',
                element : 'Acute erythroid leukemia',
                start_index : 0,
                end_index : 24,
            },
            {
                entity_type: 'drug',
                element : 'cyclophosphamide',
                start_index : 31,
                end_index : 47,
            },
            {
                entity_type: 'event',
                element : 'multiple myeloma',
                start_index : 60,
                end_index : 76,
            },
            {
                entity_type: 'drug',
                element : 'colchicine',
                start_index : 116,
                end_index : 126,
            },
            {
                entity_type: 'event',
                element : 'Toxicity',
                start_index : 152,
                end_index : 160,
            },
            {
                entity_type: 'drug',
                element : 'chloroquine',
                start_index : 172,
                end_index : 183,
            },
            {
                entity_type: 'event',
                element : 'vivax malaria',
                start_index : 207,
                end_index : 220,
            },
            
        ]
}

export const parseTextContentResultAllTagsJson2 = {
	"content":"As a rule i love the johnsons products but xmas week I bought the johnsons 2in1 bubble bath and wash (orange bottle) 2 days after the first use i noticed a rash on my daughters skin and put it down to the cough and cold she had (she sumtimes gets a viral rash) but after a couple more days the rash was more like eczema (which she does not have) I then put 2 and 2 together and realised it must be a reaction to the wash. It covered her back and face and also arms and still hasnt fully cleared up having had to put her on steroid cream to stop the itching. Wont buy that again ;-(; ------------------ KN::Mar-10-2014 Verbatim:As a rule i love the johnsons products but xmas week I bought the johnsons 2in1 bubble bath and wash (orange bottle) 2 days after the first use i noticed a rash on my daughters skin and put it down to the cough and cold she had (she sumtimes gets a viral rash) but after a couple more days the rash was more like eczema (which she does not have) I then put 2 and 2 together and realised it must be a reaction to the wash. It covered her back and face and also arms and still hasnt fully cleared up having had to put her on steroid cream to stop the itching. Wont buy that again ;-(;",
	"meta":[
		{
			"entity_type":"DrugProd",
			"element":"johnsons 2in1 bubble bath and wash",
			"start_index":66,
			"end_index":100,
			"confidence_score":0.9989999999999999
		},
		{
			"entity_type":"Onset",
			"element":"2 days",
			"start_index":117,
			"end_index":123,
			"confidence_score":0.9989999999999999
		},
		{
			"entity_type":"Event",
			"element":"rash",
			"start_index":156,
			"end_index":160,
			"confidence_score":0.9989999999999999
		},
		{
			"entity_type":"Duration",
			"element":"2 and 2",
			"start_index":357,
			"end_index":364,
			"confidence_score":0.9989999999999999
		},
		{
			"entity_type":"ConMed",
			"element":"steroid",
			"start_index":523,
			"end_index":530,
			"confidence_score":0.9989999999999999
		},
		{
			"entity_type":"DrugProd",
			"element":"johnsons 2in1 bubble bath and wash",
			"start_index":693,
			"end_index":727,
			"confidence_score":0.9989999999999999
		},
		{
			"entity_type":"Onset",
			"element":"2 days",
			"start_index":744,
			"end_index":750,
			"confidence_score":0.9989999999999999
		},
		{
			"entity_type":"Event",
			"element":"rash",
			"start_index":783,
			"end_index":787,
			"confidence_score":0.9989999999999999
		},
		{
			"entity_type":"Duration",
			"element":"2 and 2",
			"start_index":984,
			"end_index":991,
			"confidence_score":0.9989999999999999
		},
		{
			"entity_type":"ConMed",
			"element":"steroid",
			"start_index":1150,
			"end_index":1157,
			"confidence_score":0.9989999999999999
		}
	]
}

export const sampleOverlappingText1 = {
    "content" : "My daughter is 16 and she is lactose intolerant. It was reported today, she took 2 caplets of LACTAID at the first bite of dairy on 05/30/2014 and again on 06/01/2014 and each time with in 10 min of finishing her meal. The patient will typically take no more than 8 total caplets in any given 24 hour period.  The patient was given a dose of Pepto Bismol to stop the diarrhea symptoms and she is completely recovered as of 06/01/2014.",
    "meta" : []
}
export const sampleOverlappingText = {
    
    "content" : "My daughter is 30, pregnant and lactose intolerant. As a mother, I noticed she took 2 caplets of LACTAID by mouthat the first bite of dairy on 05/30/2014 and again on 06/01/2014 and each time with in 10 min of finishing her meal over 24 hour period with no effect. The patient was given a dose of Pepto Bismol to stop the diarrhea symptoms and she is completely recovered.",
    "meta" : 
    [
        {
            "entity_type":"Patients",
            "element":"daughter",
            "start_index":3,
            "end_index":11,
            "confidence_score" : 0.89
        },
        {
            "entity_type":"Gender",
            "element":"daughter",
            "start_index":3,
            "end_index":11,
            "confidence_score" : 0.89
        },
        {
            "entity_type":"Age",
            "element":"30",
            "start_index":15,
            "end_index":17,
            "confidence_score" : 0.89
        },
        {
            "entity_type":"SS_Pregnancy",
            "element":"pregnant",
            "start_index":19,
            "end_index":27,
            "confidence_score" : 0.89
        },
        {
            "entity_type":"MedHistory",
            "element":"lactose intolerant",
            "start_index":32,
            "end_index":50,
            "confidence_score" : 0.89
        },
        {
            "entity_type":"Reporter",
            "element":"mother",
            "start_index":57,
            "end_index":63,
            "confidence_score" : 0.89
        },
        
        {
            "entity_type":"Dosage",
            "element":"2 caplets",
            "start_index":84,
            "end_index":93,
            "confidence_score" : 0.95
        },
        {
            "entity_type":"DrugProd",
            "element":"LACTAID",
            "start_index":97,
            "end_index":104,
            "confidence_score" : 0.87
        },
        {
            "entity_type":"Body_part",
            "element":"mouth",
            "start_index":108,
            "end_index":113,
            "confidence_score" : 0.95
        },
        {
            "entity_type":"Onset",
            "element":"05/30/2014",
            "start_index":143,
            "end_index":153,
            "confidence_score" : 0.90
        },
        {
            "entity_type":"Offset",
            "element":"06/01/2014",
            "start_index":167,
            "end_index":177,
            "confidence_score" : 0.92
        },
        {
            "entity_type":"frequency",
            "element":"10 min",
            "start_index":200,
            "end_index":206,
            "confidence_score" : 0.85
        },
        {
            "entity_type":"Duration",
            "element":"24 hour period",
            "start_index":234,
            "end_index":248,
            "confidence_score" : 0.94
        },
        {
            "entity_type":"SS_LOE",
            "element":"no effect",
            "start_index":254,
            "end_index":263,
            "confidence_score" : 0.85
        },
       
        {
            "entity_type":"ConMed",
            "element":"Pepto Bismol to",
            "start_index":297,
            "end_index":311,
            "confidence_score" : 0.93
        },
        {
            "entity_type":"Treatment",
            "element":"to stop",
            "start_index":310,
            "end_index":317,
            "confidence_score" : 0.93
        },
        {
            "entity_type":"ActionTaken",
            "element":"to stop the diarrhea symptoms",
            "start_index":310,
            "end_index":339,
            "confidence_score" : 0.95
        },
        {
            "entity_type":"Event",
            "element":"diarrhea symptoms",
            "start_index":322,
            "end_index":339,
            "confidence_score" : 0.91
        },
        {
            "entity_type":"Outcome",
            "element":"recovered",
            "start_index":362,
            "end_index":372,
            "confidence_score" : 0.80
        },
        {
            "entity_type":"NO_AE",
            "element":"recovered",
            "start_index":362,
            "end_index":372,
            "confidence_score" : 0.80
        },

    ]
}


