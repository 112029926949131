import { Box, Popper, Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import React from "react";

// Popper used for avoid scrolling container
const CustomSnack = ({ open, color, message, onClose }) => {
  return (
    <Popper
      open={open}
      anchorEl={document.getElementById("root")}
      className={"snackbar_wrapper"}
    >
      <Snackbar
        open={open}
        autoHideDuration={2500}
        onClose={onClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        {open ? (
          <Alert
            severity={color || "success"}
            variant={"filled"}
            style={{ color: "white" }}
          >
            {message}
          </Alert>
        ) : null}
      </Snackbar>
    </Popper>
  );
};

export default CustomSnack;
