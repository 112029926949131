import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Typography,
} from "@material-ui/core";
import { Add } from "@material-ui/icons";
import React, { Fragment } from "react";
import CustomDatePickers from "../../../commonComponents/DatePicker/CustomDatePicker";
import InputField from "./InputField";

function EmployeeHistory({
  values,
  handleCurrentEmployerInputChange,
  handleAddPreviousEmployment,
  handleRemovePreviousEmployment,
  handleInputChange,
  handleInputValueChange,
  handleIsPreviousEmployerChange,
  errors,
  touched,
}) {
  return (
    <Grid container spacing={2} style={{ rowGap: 5 }}>
      <Grid item xs={12}>
        <Typography variant={"h6"}>
          <b>{"Employment History"}</b>
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant={"body1"}>
          <b>{"Current Employer:"}</b>
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Box
          border={"1px solid rgba(0, 0, 0, 0.23)"}
          padding={"16px"}
          borderRadius={"4px"}
          overflow={"hidden"}
        >
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <InputField
                label={"Company Name"}
                id={"company_name"}
                name={"company_name"}
                placeholder={"Enter company name"}
                value={values.current_employer?.company_name || ""}
                onChange={handleCurrentEmployerInputChange}
                error={
                  touched.current_employer?.company_name &&
                  errors.current_employer?.company_name
                }
              />
            </Grid>
            <Grid item xs={6}>
              <InputField
                label={"Job Title/Designation"}
                name={"job_title"}
                id={"job_title"}
                placeholder={"Enter job title/designation"}
                value={values.current_employer?.job_title || ""}
                onChange={handleCurrentEmployerInputChange}
                error={
                  touched.current_employer?.job_title &&
                  errors.current_employer?.job_title
                }
              />
            </Grid>
            <Grid item xs={3}>
              <CustomDatePickers
                id={"duration_from"}
                label={"Duration From"}
                name={"duration_from"}
                error={
                  touched.current_employer?.duration_from &&
                  Boolean(errors.current_employer?.duration_from)
                }
                helperText={
                  touched.current_employer?.duration_from &&
                  errors.current_employer?.duration_from
                }
                value={
                  values.current_employer?.duration_from
                    ? new Date(values.current_employer.duration_from)
                    : null
                }
                onChange={(date) =>
                  handleCurrentEmployerInputChange({
                    target: { value: date, name: "duration_from" },
                  })
                }
                views={["year", "month"]}
                openTo={"year"}
                format={"MMM YYYY"}
                disableFuture
              />
            </Grid>
            <Grid item xs={3}>
              <CustomDatePickers
                id={"duration_to"}
                label={"Duration To"}
                name={"duration_to"}
                error={
                  touched.current_employer?.duration_to &&
                  Boolean(errors.current_employer?.duration_to)
                }
                helperText={
                  touched.current_employer?.duration_to &&
                  errors.current_employer?.duration_to
                }
                value={
                  values.current_employer?.duration_to
                    ? new Date(values.current_employer.duration_to)
                    : null
                }
                onChange={(date) =>
                  handleCurrentEmployerInputChange({
                    target: { value: date, name: "duration_to" },
                  })
                }
                views={["year", "month"]}
                openTo={"year"}
                format={"MMM YYYY"}
                disableFuture
              />
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box display={"flex"}>
          <FormControlLabel
            control={
              <Checkbox
                checked={values.isPreviousEmployer}
                onChange={handleIsPreviousEmployerChange}
                name={"isPreviousEmployer"}
                color={"primary"}
              />
            }
            label={"Previous employment if any"}
          />
        </Box>
      </Grid>
      {values.isPreviousEmployer ? (
        <>
          <Grid item xs={12}>
            <Typography variant={"body1"}>
              <b>{"Previous Employer:"}</b>
            </Typography>
          </Grid>
          {values.previous_employer && values.previous_employer.length
            ? values.previous_employer.map((el, index) => {
                return (
                  <Fragment key={`previous_employer_history_${index + 1}`}>
                    <Grid item xs={12}>
                      <Box
                        border={"1px solid rgba(0, 0, 0, 0.23)"}
                        padding={"16px"}
                        borderRadius={"4px"}
                        overflow={"hidden"}
                      >
                        <Box
                          padding={"8px 16px"}
                          margin={"-16px -16px 16px"}
                          className={"bg-gray-200"}
                        >
                          <Typography>
                            <b>{`Employment ${index + 1}:`}</b>
                          </Typography>
                        </Box>
                        <Grid container spacing={2}>
                          <Grid item xs={6}>
                            <InputField
                              label={"Company Name"}
                              id={"company_name"}
                              name={"company_name"}
                              placeholder={"Enter company name"}
                              value={el.company_name || ""}
                              onChange={(e) =>
                                handleInputChange(e, index, "previous_employer")
                              }
                              error={
                                touched.previous_employer?.[index]
                                  ?.company_name &&
                                errors.previous_employer?.[index]?.company_name
                              }
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <InputField
                              label={"Job Title/Designation"}
                              name={"job_title"}
                              id={"job_title"}
                              placeholder={"Enter Job title/designation"}
                              value={el.job_title || ""}
                              onChange={(e) =>
                                handleInputChange(e, index, "previous_employer")
                              }
                              error={
                                touched.previous_employer?.[index]?.job_title &&
                                errors.previous_employer?.[index]?.job_title
                              }
                            />
                          </Grid>
                          <Grid item xs={3}>
                            <CustomDatePickers
                              id={"duration_from"}
                              label={"Duration From"}
                              name={"duration_from"}
                              error={
                                touched.previous_employer?.[index]
                                  ?.duration_from &&
                                Boolean(
                                  errors.previous_employer?.[index]
                                    ?.duration_from
                                )
                              }
                              helperText={
                                touched.previous_employer?.[index]
                                  ?.duration_from &&
                                errors.previous_employer?.[index]?.duration_from
                              }
                              value={
                                el.duration_from
                                  ? new Date(el.duration_from)
                                  : null
                              }
                              onChange={(date) =>
                                handleInputValueChange(
                                  date,
                                  "duration_from",
                                  index,
                                  "previous_employer"
                                )
                              }
                              views={["year", "month"]}
                              openTo={"year"}
                              format={"MMM YYYY"}
                              disableFuture
                            />
                          </Grid>
                          <Grid item xs={3}>
                            <CustomDatePickers
                              id={"duration_to"}
                              label={"Duration To"}
                              name={"duration_to"}
                              error={
                                touched.previous_employer?.[index]
                                  ?.duration_to &&
                                Boolean(
                                  errors.previous_employer?.[index]?.duration_to
                                )
                              }
                              helperText={
                                touched.previous_employer?.[index]
                                  ?.duration_to &&
                                errors.previous_employer?.[index]?.duration_to
                              }
                              value={
                                el.duration_to ? new Date(el.duration_to) : null
                              }
                              onChange={(date) =>
                                handleInputValueChange(
                                  date,
                                  "duration_to",
                                  index,
                                  "previous_employer"
                                )
                              }
                              views={["year", "month"]}
                              openTo={"year"}
                              format={"MMM YYYY"}
                              disableFuture
                            />
                          </Grid>
                        </Grid>
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Box
                        display={"flex"}
                        justifyContent={"space-between"}
                        mt={"-5px"}
                        px={2}
                      >
                        <Button
                          variant={"contained"}
                          color={"secondary"}
                          size={"small"}
                          onClick={() => handleRemovePreviousEmployment(index)}
                          disabled={values.previous_employer.length === 1}
                        >
                          <b>{"Delete"}</b>
                        </Button>

                        {values.previous_employer.length - 1 === index ? (
                          <Button
                            variant={"contained"}
                            color={"primary"}
                            size={"small"}
                            startIcon={<Add />}
                            onClick={handleAddPreviousEmployment}
                          >
                            <b>{"Add"}</b>
                          </Button>
                        ) : (
                          <Box />
                        )}
                      </Box>
                    </Grid>
                  </Fragment>
                );
              })
            : null}
        </>
      ) : null}
      <Grid item xs={12} />
    </Grid>
  );
}

export default EmployeeHistory;
