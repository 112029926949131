import {
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@material-ui/core";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Route, useHistory, useParams } from "react-router";
import { getApplicantSession } from "../../api/api";
import Scheduling from "../Scheduling/Scheduling";
import TestEvent from "../TestEvent/TestEvent";
import TestOutcome from "../TestOutcome/TestOutcome";

function Test({ getAndSetCount = () => {} }) {
  const history = useHistory();
  const { jobCode, applicantId, applicantsession } = useParams();
  const [title, setTitle] = useState("");
  const [applicantSessionData, setApplicantSessionData] = useState(null);

  console.log(applicantsession, "applicantsession=======");

  const handleMenuClick = (path, titleName) => {
    setTitle(titleName);
    history.push(path);
  };

  const isSelected = (pathName) => {
    return history.location.pathname.search(pathName) !== -1 ? true : false;
  };

  const titleArr = ["scheduling", "test-event", "test-outcome"];
  const titleObj = {
    scheduling: "Scheduling",
    "test-event": "Test Event",
    "test-outcome": "Test Outcome",
  };

  const setInitialTitle = () => {
    const { pathname } = history.location;
    let findInd;
    titleArr.map((el, ind) => {
      if (pathname.includes(el)) {
        findInd = ind;
      }
    });
    setTitle(titleObj[titleArr[findInd]]);
  };

  const getAndSetApplicantSessionData = () => {
    axios.get(getApplicantSession(applicantsession)).then((response) => {
      if (response.status === 200) {
        setApplicantSessionData(response.data);
      }
    });
  };

  useEffect(() => {
    setInitialTitle();
    getAndSetApplicantSessionData();
  }, []);

  console.log(applicantSessionData, "+++++++");

  return (
    <Grid container spacing={2}>
      <Grid item xs={4}>
        <div className="flex flex-col rounded shadow p-5 min-h-screen">
          <Typography>
            <b>Test</b>
          </Typography>
          <List component="nav">
            <ListItem
              button
              onClick={() =>
                handleMenuClick(
                  `/dashboard/${jobCode}/test/${applicantId}/${applicantsession}/scheduling`,
                  "Scheduling"
                )
              }
              selected={isSelected("scheduling")}
              className="listButton"
            >
              <ListItemText primary="Scheduling" />
            </ListItem>
            <ListItem
              button
              onClick={() =>
                handleMenuClick(
                  `/dashboard/${jobCode}/test/${applicantId}/${applicantsession}/test-event`,
                  "Test Event"
                )
              }
              selected={isSelected("test-event")}
              className="listButton"
            >
              <ListItemText primary="Test Event" />
            </ListItem>
            <ListItem
              button
              onClick={() =>
                handleMenuClick(
                  `/dashboard/${jobCode}/test/${applicantId}/${applicantsession}/test-outcome`,
                  "Test Outcome"
                )
              }
              selected={isSelected("test-outcome")}
              className="listButton"
            >
              <ListItemText primary="Test Outcome" />
            </ListItem>
          </List>
        </div>
      </Grid>
      <Grid item xs={8}>
        <div className="rounded shadow p-6 min-h-screen">
          <Typography className={"headingText"}>
            <b>{title}</b>
          </Typography>
          <Route
            exact
            path={
              "/dashboard/:jobCode/test/:applicantId/:applicantsession/scheduling"
            }
            render={(props) => (
              <Scheduling
                {...props}
                sessionData={applicantSessionData}
                getAndSetApplicantSessionData={getAndSetApplicantSessionData}
              />
            )}
          />
          <Route
            exact
            path={
              "/dashboard/:jobCode/test/:applicantId/:applicantsession/test-event"
            }
            render={(props) => (
              <TestEvent
                {...props}
                sessionData={applicantSessionData}
                getAndSetApplicantSessionData={getAndSetApplicantSessionData}
              />
            )}
          />
          <Route
            exact
            path={
              "/dashboard/:jobCode/test/:applicantId/:applicantsession/test-outcome"
            }
            render={(props) => (
              <TestOutcome
                {...props}
                getAndSetCount={getAndSetCount}
                sessionData={applicantSessionData}
                getAndSetApplicantSessionData={getAndSetApplicantSessionData}
              />
            )}
          />
        </div>
      </Grid>
    </Grid>
  );
}

export default Test;
