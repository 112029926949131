import {
  FormControl,
  FormHelperText,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  createStyles,
  makeStyles,
} from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) =>
  createStyles({
    selectAdornment: {
      marginRight: theme.spacing(3.5),
    },
  })
);

function SelectDropDown({
  error,
  value,
  onChange = () => {},
  name,
  errorMessage,
  options = [],
  variant = "standard",
  size = "medium",
  label,
  adornmentText,
}) {
  const classes = useStyles();
  return (
    <FormControl size={size} variant={variant} error={error} fullWidth>
      {label ? <InputLabel>{label}</InputLabel> : null}
      <Select
        value={value}
        onChange={onChange}
        name={name}
        label={label}
        endAdornment={
          adornmentText ? (
            <InputAdornment
              className={classes.selectAdornment}
              position={"end"}
            >
              {adornmentText}
            </InputAdornment>
          ) : null
        }
      >
        {options && options.length
          ? options.map((el) => (
              <MenuItem key={el.value} value={el.value}>
                {el.label}
              </MenuItem>
            ))
          : null}
      </Select>
      <FormHelperText>{errorMessage}</FormHelperText>
    </FormControl>
  );
}

export default SelectDropDown;
