import { Box, IconButton, Tooltip, Typography } from "@material-ui/core";
import { ArrowBack } from "@material-ui/icons";
import React from "react";

function BackNavigator({ handleBack, title }) {
  return (
    <Box className={"flex items-center gap-6"}>
      <div>
        <Tooltip title={"Back"}>
          <IconButton
            style={{
              background: "#25a9da",
              color: "#ffffff",
              padding: "8px",
            }}
            size={"small"}
            onClick={handleBack}
          >
            <ArrowBack />
          </IconButton>
        </Tooltip>
      </div>
      {title ? (
        <Typography style={{ fontWeight: "bold" }}>{title}</Typography>
      ) : null}
    </Box>
  );
}

export default BackNavigator;
