import {
  Button,
  CircularProgress,
  Popover,
  Typography,
} from "@material-ui/core";
import React, { Component } from "react";
import { Col, Collapsible, Row } from "react-materialize";

import FileUpload from "../../commonComponents/FileUpload";
import CustomMatchReport from "../../components/UploadParseResumeComponents/CustomMatchReport";
import SkillMatchReportPopUp from "../../components/UploadParseResumeComponents/CustomMatchReport/SkillMatchReportPopUp";
import Processed from "../../components/UploadParseResumeComponents/ProcessedResume/Processed";
import TopSkills from "../../components/UploadParseResumeComponents/ProcessedResume/TopSkills";

import $ from "jquery";
import API from "../../utils/API/resumeAPI";
import * as c from "../../utils/constants/constants";
import * as urc from "../../utils/constants/uploadResumeConstants";
import * as sampleJson from "../../utils/sampleJson/sampleUploadParseResumeJson";
import "./style.css";

class UploadParseResume extends Component {
  state = {
    uploadedResumes: {},
    processedResumes: {},
    updatedProcessedResumes: {},
    overWrittenFiles: {},
    savedResumeFileNames: [],
    ErrorResumeFileNames: [],
    saveProcessedResumesArr: [],
    customMatchQuery: {
      id: "",
      action: "",
      values: [],
      resumeNames: [],
      date: "",
    },
    customMatchResultSkillTableValues: [],
    isHidden: true,
    customMatchReportAction: { action: "", timeStamp: "" },
    isAllFileTypeMatch: true,
    editable: false,
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    address: "",
    linkedin: "",
    summary: "",
    skill_matrix: "",
    education: "",
    certification: "",
    work_experience: [],
    flags: [],
    grammar_check: [],
    inferred_title: [],
    irrelevant_line_items: [],
    keyword_score: [],
    other_links: [],
    pdf_link: "",
    punctuation_check_list: [],
    repeated_work_exp: [],
    top_skills_hierarchy: [],
    top_skills_raw: [],
    disabled: false,
    loadData: {},
    loading: false,
    anchorEl: null,
    message: null,
    format: false,
  };

  handleFileUpload = (event) => {
    this.setState({ processedResumes: {} });
    this.setState({ uploadedResumes: {} });

    this.setState({ loading: true, message: null });

    let files = event.target.files;
    let fileLen = files.length;
    //console.log('files', files);
    let timeoutDiff = 0;

    // let uploadedResumeLength = Object.keys(this.state.uploadedResumes).length;
    // console.log('uploadedResumeLength',uploadedResumeLength);
    let uploadedResumesObjLength = this.state.uploadedResumes
      ? Object.keys(this.state.uploadedResumes).length
      : 0;

    let filesLeftToReachLimit =
      urc.FILE_UPLOAD_RESUME_LIMIT - uploadedResumesObjLength;

    if (fileLen <= filesLeftToReachLimit) {
      for (let fileIndex = 0; fileIndex < files.length; fileIndex++) {
        var eachFile = files[fileIndex];
        //eslint-disable-next-line
        let fileName = eachFile.name.replace(/[\/\\+'"–]/g, "_");

        // let fileName = files[fileIndex].name;
        var isFileTypeMatch = fileName
          .toLowerCase()
          .match(/^[\w~`!@#$%^&*(){}[\]|:";'<>,.?+= _\-–]+(.doc|.docx|.pdf)$/);
        console.log(this.state, "asdfasdf");
        if (isFileTypeMatch) {
          let uploadedResumesObj = this.state.uploadedResumes;
          let processedResumesObj = this.state.processedResumes;
          let overWrittenFilesObj = this.state.overWrittenFiles;

          if (uploadedResumesObj.hasOwnProperty(fileName)) {
            delete processedResumesObj[fileName];
            delete uploadedResumesObj[fileName];

            overWrittenFilesObj[fileName] = true;

            this.setState({ processedResumes: processedResumesObj });
            this.setState({ loading: false, message: null, format: false });
            this.setState({ uploadedResumes: uploadedResumesObj });
          } else overWrittenFilesObj[fileName] = false;
          this.uploadResumeAPI(eachFile, fileName);
          setTimeout(() => {
            this.updateProgressBar(fileName, 10);
          }, timeoutDiff);
          timeoutDiff += 1000;
        } else {
          console.log("THE FUNCTION");
          this.setState({
            isAllFileTypeMatch: false,
            uploadedResumes: {},
            loading: false,
            format: true,
            message: "Files in unsupported formats are not processed.",
          });
          $("#openFileUploadErrorMsgPopUp").click();
        }
      }
    } else if (fileLen > filesLeftToReachLimit)
      $("#openFileUploadErrorMsgPopUp").click();

    // else if (isAllFilesMatch === false)
    // {
    // 	this.setState({isAllFileTypeMatch : isAllFilesMatch});
    // 	$('#openFileUploadErrorMsgPopUp').click();
    // }
  };
  handleCheckAll = (event) => {
    let isChecked = event.target.checked;
    let uploadedResumes = this.state.uploadedResumes;
    for (let fileName in uploadedResumes) {
      if (this.isResumeValid(fileName)) {
        let changeValueObj = {};
        changeValueObj[urc.UPLOADED_RESUME_RESULTS_OBJ_IS_SELECTED] = isChecked;
        this.updateUploadedResumeDetails(fileName, changeValueObj);
      }
    }
  };
  checkAllValidResumes = () => {
    let checked = 0;
    let unchecked = 0;
    let uploadedResumes = this.state.uploadedResumes;
    for (let fileName in uploadedResumes) {
      if (this.isResumeValid(fileName)) {
        uploadedResumes[fileName][
          urc.UPLOADED_RESUME_RESULTS_OBJ_IS_SELECTED
        ] === true
          ? checked++
          : unchecked++;
      }
    }
    if (unchecked === 0) return true;
    else return false;
  };

  handleResumeResultsCheckBox = (event, fileName) => {
    let uploadedResumes = this.state.uploadedResumes;
    let currentFileValues = uploadedResumes[fileName];
    currentFileValues[urc.UPLOADED_RESUME_RESULTS_OBJ_IS_SELECTED] =
      event.target.checked;
    this.updateUploadedResumeDetails(fileName, currentFileValues);
  };

  isResumeValid = (fileName) => {
    let uploadedResumes = this.state.uploadedResumes;
    let currentFileValues = uploadedResumes[fileName];

    if (
      currentFileValues[urc.UPLOADED_RESUME_RESULTS_OBJ_PROCESSED_STATUS] !==
        c.FILE_STATUS_ERROR &&
      currentFileValues[urc.UPLOADED_RESUME_RESULTS_OBJ_PROCESSED_STATUS] !==
        c.FILE_STATUS_PROCESSING
    ) {
      if (
        this.props.inUploadResumePage === true &&
        currentFileValues[
          urc.UPLOADED_RESUME_RESULTS_OBJ_UPLOADED_USER_MSG_CATERGORY
        ] !== urc.PROCESSED_RESUME_JSON_WORK_EXPERIENCE &&
        currentFileValues[
          urc.UPLOADED_RESUME_RESULTS_OBJ_UPLOADED_USER_MSG_CATERGORY
        ] !== ""
      )
        return false;
      else return true;
    } else return false;
  };

  handlePopoverOpen = (event) => {
    // console.log(event.currentTarget);
    this.setState({ anchorEl: event.currentTarget.innerText });
  };

  handlePopoverClose = () => {
    this.setState({ anchorEl: null });
  };

  updateUploadedResumeDetails = (fileName, changeValues) => {
    let resumes = this.state.uploadedResumes;
    let fileValues = resumes[fileName] ? resumes[fileName] : {};
    // console.log('fileValues',fileValues);

    // fileValues = {
    // 	progress: progress,
    // 	isSelected: isSelected,
    // 	status: status,
    // 	userMsg: userMsg,
    // 	iscustomMatched : iscustomMatched
    // };

    if (!fileValues) {
      fileValues[urc.UPLOADED_RESUME_RESULTS_OBJ_PROGRESS] = 0;
      fileValues[urc.UPLOADED_RESUME_RESULTS_OBJ_IS_SELECTED] = false;
      fileValues[urc.UPLOADED_RESUME_RESULTS_OBJ_PROCESSED_STATUS] =
        c.FILE_STATUS_PROCESSING;
      fileValues[urc.UPLOADED_RESUME_RESULTS_OBJ_UPLOADED_USER_MSG] = "";
      fileValues[urc.UPLOADED_RESUME_RESULTS_OBJ_UPLOADED_USER_MSG_CATERGORY] =
        "";
      fileValues[urc.UPLOADED_RESUME_RESULTS_OBJ_IS_CUSTOM_MATCHED] = false;
    }

    for (let key in changeValues) {
      fileValues[key] = changeValues[key];
    }

    resumes[fileName] = fileValues;
    this.setState({ uploadedResumes: resumes });
  };

  updateProgressBar = (fileName, progress) => {
    let interval = setInterval(() => {
      let resumes = this.state.uploadedResumes;
      let fileValues = resumes[fileName];

      // console.log(fileValues && fileValues.progress ? true : false);

      if (
        progress < 95 &&
        (fileValues && fileValues.progress === 100 ? false : true)
      ) {
        let changeValueObj = {};
        changeValueObj[urc.UPLOADED_RESUME_RESULTS_OBJ_PROGRESS] = progress;
        changeValueObj[urc.UPLOADED_RESUME_RESULTS_OBJ_IS_SELECTED] = false;
        changeValueObj[urc.UPLOADED_RESUME_RESULTS_OBJ_PROCESSED_STATUS] =
          c.FILE_STATUS_PROCESSING;
        changeValueObj[urc.UPLOADED_RESUME_RESULTS_OBJ_UPLOADED_USER_MSG] = "";
        changeValueObj[
          urc.UPLOADED_RESUME_RESULTS_OBJ_UPLOADED_USER_MSG_CATERGORY
        ] = "";
        changeValueObj[
          urc.UPLOADED_RESUME_RESULTS_OBJ_IS_CUSTOM_MATCHED
        ] = false;

        this.updateUploadedResumeDetails(fileName, changeValueObj);
        progress += 5;
      } else clearInterval(interval);
    }, 500);
  };

  uploadResumeAPI = (file, fileName) => {
    var formData = new FormData();
    formData.append("files[0]", file, fileName);

    if (localStorage.getItem("tal_token") != null) {
      var options = {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + localStorage.getItem("tal_token"),
        },
      };
    } else {
      var options = {
        headers: { "Content-Type": "multipart/form-data" },
      };
    }

    API.uploadResume(formData, options)
      .then((res) => {
        //				console.log("Upload Resume Success");

        let status = c.FILE_STATUS_COMPLETED;

        if (this.state.overWrittenFiles[fileName])
          status = c.FILE_STATUS_OVERWRITTEN;

        //console.log('res.data',res.data);

        // this.setState({name:res.data.first_name});
        this.setState({ first_name: res.data.first_name });
        this.setState({ last_name: res.data.last_name });
        this.setState({ email: res.data.email });
        this.setState({ phone: res.data.phone });
        this.setState({ address: res.data.address });
        this.setState({ linkedin: res.data.linked_in });
        this.setState({ summary: res.data.summary });
        this.setState({ skill_matrix: res.data.skill_matrix });
        this.setState({ education: res.data.education });
        this.setState({ certification: res.data.certification });
        this.setState({ work_experience: res.data.work_experience });

        this.setState({ flags: res.data.insights.flags });
        this.setState({ grammar_check: res.data.insights.grammar_check });
        this.setState({ inferred_title: res.data.insights.inferred_title });
        this.setState({
          irrelevant_line_items: res.data.insights.irrelevant_line_items,
        });
        this.setState({ keyword_score: res.data.insights.keyword_score });
        this.setState({ other_links: res.data.insights.other_links });

        this.setState({ pdf_link: res.data.pdf_link });
        this.setState({
          punctuation_check_list: res.data.insights.punctuation_check_list,
        });
        this.setState({
          repeated_work_exp: res.data.insights.repeated_work_exp,
        });
        this.setState({ top_skills_hierarchy: res.data.top_skills_hierarchy });
        this.setState({ top_skills_raw: res.data.top_skills_raw });

        this.setState({ loadData: res.data });
        this.setState({ loading: false, message: null });

        this.updateUploadResumeFromResponse(res.data, fileName, status, true);
      })
      .catch((error) => {
        console.log(error);
        this.setState({ loading: false });
        this.setState({
          message: "Resume Not Parsed Successfully",
          format: false,
        });
        let status = c.FILE_STATUS_ERROR;
        if (window.location.host === c.LOCAL_ENVIORNMENT) {
          status = c.FILE_STATUS_COMPLETED;
          // let status = c.FILE_STATUS_COMPLETED;
          this.updateUploadResumeFromResponse(
            sampleJson.uploadResumeResponseJson2,
            fileName,
            status,
            false
          );
        } else this.updateUploadResumeFromResponse("", fileName, status, false);
        // Error
        c.APIErrorResponse(error, "Upload Resume Request");
      });
  };

  updateUploadResumeFromResponse = (
    uploadResponse,
    fileName,
    status,
    isSelected
  ) => {
    this.updateProgressBar(fileName, 100);
    let changeValueObj = urc.validationBeforeSavingResume(
      "uploadJson",
      uploadResponse,
      isSelected,
      status,
      this.props.inUploadResumePage
    );
    this.updateUploadedResumeDetails(fileName, changeValueObj);

    if (uploadResponse !== "") {
      let processedResumesObj = this.state.processedResumes;
      processedResumesObj[fileName] = uploadResponse;
      this.setState({ processedResumes: processedResumesObj });
    }
  };

  handleUpdateProcessedResume = (fileName, updatedResume) => {
    let isSelected = true;

    let changeValueObj = urc.validationBeforeSavingResume(
      "saveJson",
      updatedResume,
      isSelected,
      "",
      this.props.inUploadResumePage
    );
    this.updateUploadedResumeDetails(fileName, changeValueObj);

    let updatedProcessedResumes = this.state.updatedProcessedResumes;
    updatedProcessedResumes[fileName] = updatedResume;

    // console.log('Updated Resume for saving', updatedProcessedResumes);
    this.setState({ updatedProcessedResumes: updatedProcessedResumes });
  };

  saveProcessedResume = () => {
    // event.target.disabled = true;

    let saveProcessedResumesArr = [];

    let uploadedResumes = this.state.uploadedResumes;
    let savedResumeFileNames = [];
    let ErrorResumeFileNames = [];
    let savedCandidateNames = [];
    for (let key in uploadedResumes) {
      if (uploadedResumes[key]["isSelected"]) {
        let updatedResumeForSaving = this.state.updatedProcessedResumes[key];
        console.log("updatedResumeForSaving", updatedResumeForSaving);

        let fullName = updatedResumeForSaving.name.trim();
        if (fullName.indexOf(" ") > 0) {
          savedCandidateNames.push(fullName.split(" ")[0]);
          savedCandidateNames.push(fullName.split(" ")[1]);
        } else savedCandidateNames.push(fullName);

        saveProcessedResumesArr.push(updatedResumeForSaving);
        savedResumeFileNames.push(key);
      } else ErrorResumeFileNames.push(key);
    }
    if (this.props.inUploadResumePage)
      this.props.sendCandidateNames(savedCandidateNames);

    this.setState({ savedResumeFileNames: savedResumeFileNames });
    this.setState({ ErrorResumeFileNames: ErrorResumeFileNames });
    this.setState({ saveProcessedResumesArr: saveProcessedResumesArr });
  };

  saveProcessedResumeAPI = () => {
    let savedResumeFileNames = this.state.savedResumeFileNames;

    if (this.props.inUploadResumePage) this.props.loadingPage(true);
    else {
      for (let key in savedResumeFileNames) {
        let changeValueObj = {};
        changeValueObj[urc.UPLOADED_RESUME_RESULTS_OBJ_PROCESSED_STATUS] =
          c.FILE_STATUS_PROCESSING;
        this.updateUploadedResumeDetails(
          savedResumeFileNames[key],
          changeValueObj
        );
      }
    }

    let saveProcessedResumesArr = this.state.saveProcessedResumesArr;

    console.log("saveProcessedResumesArr", saveProcessedResumesArr);

    // if (saveProcessedResumesArr.length !== 0) {
    API.saveProcessedResumes(saveProcessedResumesArr)
      .then((res) => {
        console.log("Saved Processed Resume Success");

        if (this.props.inUploadResumePage) this.props.loadingPage(false);
        else {
          let savedResumeFileNames = this.state.savedResumeFileNames;
          for (let key in savedResumeFileNames) {
            let changeValueObj = {};
            changeValueObj[urc.UPLOADED_RESUME_RESULTS_OBJ_PROCESSED_STATUS] =
              c.FILE_STATUS_SAVED;
            this.updateUploadedResumeDetails(
              savedResumeFileNames[key],
              changeValueObj
            );
          }
        }
      })
      .catch((error) => {
        if (this.props.inUploadResumePage) this.props.loadingPage(false);
        c.APIErrorResponse(error, "Save Processed Resumes");
      });
    // }
  };

  formatCustomMatchSkillJson = () => {
    // customMatchQuery : { action: '', values: [], resumeNames:[] },
    let customMatchQueryObj = this.state.customMatchQuery;
    customMatchQueryObj.resumeNames = $("#resumeDropDown").val();
    let selectedResumes = Object.entries(
      this.state.updatedProcessedResumes
    ).filter(
      ([key, value]) => customMatchQueryObj.resumeNames.indexOf(key) >= 0
    );

    let customMatchContentArr = [];

    for (let index in selectedResumes) {
      customMatchContentArr.push({
        file_name: selectedResumes[index][0],
        skill_list:
          selectedResumes[index][1][
            urc.PROCESSED_RESUME_JSON_TOP_SKILLS_HIERARCHY
          ],
      });
    }
    // console.log('customMatchContentArr',customMatchContentArr);

    // let customMatchRequestJson = {
    // 		skill:{action : 'skill', values : ['Junit','HTML']},
    // 		title:{action : 'title', values : ['Java Developer']},
    // 		match_content : [
    // 							{file_name : '', skill_list : []},
    // 							{file_name : '', skill_list : []}
    // 						],
    // 	};

    let customMatchRequestJson = {
      skill: customMatchQueryObj.skill,
      title: customMatchQueryObj.title,
      match_content: customMatchContentArr,
    };

    this.customMatchSkillAPI(customMatchRequestJson, customMatchQueryObj);
  };

  customMatchSkillAPI = (customMatchRequestJson, customMatchQueryObj) => {
    let formattedCustomMatchResultSkillTableValues =
      this.state.customMatchResultSkillTableValues;

    API.customMatchSkillWithResumes(customMatchRequestJson)
      .then((res) => {
        console.log("Custom Skill Match Success", res.data);

        let reportObj = urc.formatCustomSkillMatchJsonFromResponse(
          res.data,
          customMatchQueryObj
        );

        formattedCustomMatchResultSkillTableValues.unshift(reportObj);
        console.log(
          "customMatchResultSkillTableValues",
          formattedCustomMatchResultSkillTableValues
        );
        this.setState({
          customMatchResultSkillTableValues:
            formattedCustomMatchResultSkillTableValues,
        });
      })
      .catch((error) => {
        if (window.location.host === c.LOCAL_ENVIORNMENT) {
          let reportObj = urc.formatCustomSkillMatchJsonFromResponse(
            sampleJson.customMatchResponse2,
            customMatchQueryObj
          );

          formattedCustomMatchResultSkillTableValues.unshift(reportObj);

          this.setState({
            customMatchResultSkillTableValues:
              formattedCustomMatchResultSkillTableValues,
          });
        }
        c.APIErrorResponse(error, "Custom Match Skills");
      });
  };

  customMatchReportUpdate = (action, timeStamp, downloadFlag) => {
    let formattedValues = this.state.customMatchResultSkillTableValues;
    let updatedformattedValues = [];

    if (
      (action === "download" && timeStamp !== "" && downloadFlag === false) ||
      (action === "downloadAll" &&
        formattedValues.filter((element) => element.downloadFlag === false)
          .length > 0)
    ) {
      urc.downloadReport(formattedValues, timeStamp);
      formattedValues.map((element) => {
        if (timeStamp === "" || timeStamp === element.timeStamp)
          element.downloadFlag = true;

        return null;
      });
      this.setState({ customMatchResultSkillTableValues: formattedValues });
    } else if (
      (action === "delete" && timeStamp !== "" && downloadFlag === true) ||
      (action === "deleteAll" &&
        formattedValues.filter((element) => element.downloadFlag === false)
          .length === 0)
    ) {
      updatedformattedValues =
        timeStamp !== ""
          ? formattedValues.filter((element) => element.timeStamp !== timeStamp)
          : [];
      this.setState({
        customMatchResultSkillTableValues: updatedformattedValues,
      });
    } else if (
      (action === "delete" && timeStamp !== "" && downloadFlag === false) ||
      (action === "download" && timeStamp !== "" && downloadFlag === true) ||
      (action === "deleteAll" &&
        timeStamp === "" &&
        formattedValues.filter((element) => element.downloadFlag === false)
          .length > 0) ||
      (action === "downloadAll" &&
        formattedValues.filter((element) => element.downloadFlag === false)
          .length === 0)
    ) {
      this.setState({
        customMatchReportAction: {
          action: action,
          timeStamp: timeStamp,
          downloadFlag: downloadFlag,
        },
      });
      $("#skillMatchReportPopupTrigger").click();
    }
  };

  handleEditable = () => {
    this.setState({ editable: !this.state.editable });
  };

  handleSubmit = (event) => {
    this.setState({ editable: true });
    this.setState({ [event.target.name]: event.target.value });
  };
  handleWorkSubmit = (event) => {
    this.setState({ editable: true });
    //console.log(event.target.id);
    //console.log(event.target.name);
    //console.log(event.target.value);

    const work = this.state.work_experience.slice();

    work[event.target.id][event.target.name] = event.target.value;
    console.log(work);
    this.setState({ work_experience: work });
  };

  handleFormSubmit = () => {
    //console.log(this.state.work_experience);

    /*	var insights = {
			flags:this.state.flags,
			grammar_check:this.state.grammar_check,
			inferred_title: this.state.inferred_title,
			irrelevant_line_items:this.state.irrelevant_line_items,
			keyword_score:this.state.keyword_score,
			punctuation_check_list:this.state.punctuation_check_list,
			repeated_work_exp:this.state.repeated_work_exp,
			top_skills_hierarchy:this.state.top_skills_hierarchy,

		}*/

    var formData = new FormData();

    // formData.append('name', this.state.name);
    formData.append("first_name", this.state.first_name);
    formData.append("last_name", this.state.last_name);
    formData.append("email", this.state.email);
    formData.append("phone", this.state.phone);
    formData.append("address", this.state.address);
    formData.append("linkedin", this.state.linkedin);
    formData.append("summary", this.state.summary);
    formData.append("skill_matrix", this.state.skill_matrix);
    formData.append("education", this.state.education);
    formData.append("certification", this.state.certification);
    formData.append(
      "work_experience",
      JSON.stringify(this.state.work_experience)
    );

    // formData.append('flags', this.state.flags);
    // formData.append('grammar_check', this.state.grammar_check);
    // formData.append('inferred_title', this.state.inferred_title);
    // formData.append('irrelevant_line_items', this.state.irrelevant_line_items);
    // formData.append('keyword_score', this.state.keyword_score);
    // formData.append('other_links', this.state.other_links);
    // formData.append('pdf_link', this.state.pdf_link);

    // formData.append('punctuation_check_list', this.state.punctuation_check_list);
    // formData.append('repeated_work_exp', this.state.repeated_work_exp);
    formData.append(
      "top_skills_hierarchy",
      JSON.stringify(this.state.top_skills_hierarchy)
    );
    formData.append(
      "top_skills_raw",
      JSON.stringify(this.state.top_skills_raw)
    );

    if (localStorage.getItem("tal_token") != null) {
      var options = {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + localStorage.getItem("tal_token"),
        },
      };
    } else {
      var options = {
        headers: { "Content-Type": "multipart/form-data" },
      };
    }

    API.inferResume(formData, options)
      .then((res) => {
        //console.log("Upload Resume Success");
        var insigts = res.data;
        var element = this.state.loadData;
        var data = {
          address: element.address,
          certification: element.certification,
          education: element.education,
          email: element.email,
          file_name: element.file_name,
          first_name: element.first_name,
          insights: insigts,
          last_name: element.last_name,
          linkedin: element.linkedin,
          pdf_link: element.pdf_link,
          phone: element.phone,
          skill_matrix: element.skill_matrix,
          summary: element.summary,
          top_skills_hierarchy: element.top_skills_hierarchy,
          top_skills_raw: element.top_skills_raw,
          work_experience: element.work_experience,
        };
        console.log(data);
        this.setState({ loadData: data });
      })
      .catch((error) => {
        // Error
        c.APIErrorResponse(error, "Upload Resume Request");
      });
  };

  render() {
    let isHidden =
      this.props.inUploadResumePage === false && this.state.isHidden === true;
    //let isCustomMatchDisabled = this.state.customMatchResultSkillTableValues.length >=urc.CUSTOM_MATCH_REPORT_LIMIT ? true : false;
    //console.log(this.state.updatedProcessedResumes);
    // let isCustomMatchDisabled =  true;
    const open = Boolean(this.state.anchorEl);

    return (
      <section className="w-full px-12 lg:w-12/12">
        <section className="w-full px-6 lg:w-6/12" style={{ margin: "auto" }}>
          <div className="demoinput">
            <h2 className="upload_title">Upload Resume</h2>

            <FileUpload
              isFileLimitReached={
                this.state.uploadedResumes &&
                Object.keys(this.state.uploadedResumes).length <
                  urc.FILE_UPLOAD_RESUME_LIMIT
                  ? false
                  : true
              }
              handleFileUpload={this.handleFileUpload}
              uploadLimit={urc.FILE_UPLOAD_RESUME_LIMIT}
              alreadyUploadedFilesLen={
                this.state.uploadedResumes
                  ? Object.keys(this.state.uploadedResumes).length
                  : 0
              }
              isResumePage={true}
              isAllFileTypeMatch={this.state.isAllFileTypeMatch}
              files={this.state.uploadedResumes}
              loading={this.state.loading}
            />
          </div>
        </section>
        {console.log(this.state.message, this.state.loading, "***********Adsf")}

        <div
          id="uploadResume"
          className={isHidden ? "navPages dimBackground" : "navPages"}
        >
          {/* Heading and Upload Box for the page */}

          {/* ------------------------------------ */}

          {this.state.customMatchResultSkillTableValues.length > 0 && (
            <>
              <CustomMatchReport
                customMatchResultSkillTableValues={
                  this.state.customMatchResultSkillTableValues
                }
                customMatchReportUpdate={this.customMatchReportUpdate}
              />
              <SkillMatchReportPopUp
                updateReportActionValues={(updatedValues) =>
                  this.setState({
                    customMatchResultSkillTableValues: updatedValues,
                  })
                }
                customMatchReportAction={this.state.customMatchReportAction}
                reportActionValues={
                  this.state.customMatchResultSkillTableValues
                }
              />
            </>
          )}

          {/* -------- Uploaded Files and its progress and status --------- */}

          {!this.state.loading && this.state.message && this.state.format && (
            <div
              className="uploadResumeResults valign-wrapper"
              style={{
                textAlign: "center",
                display: "block",
                width: "100%",
              }}
            >
              <div style={{ textAlign: "center", color: "red" }}>
                {this.state.message}
              </div>
            </div>
          )}
          {this.state.uploadedResumes &&
            Object.entries(this.state.uploadedResumes).length > 0 && (
              <>
                {Object.entries(this.state.uploadedResumes).map(
                  ([fileName, fileValues]) => (
                    <Row
                      key={fileName}
                      className="uploadResumeResults valign-wrapper"
                    >
                      {(this.state.loading || this.state.message) && (
                        <div
                          style={{
                            textAlign: "center",
                            display: "block",
                            width: "100%",
                          }}
                        >
                          {this.state.loading && (
                            <CircularProgress
                              color={"#25a9da"}
                              loading={this.state.loading}
                              size={30}
                            />
                          )}
                        </div>
                      )}
                      {!this.state.loading && this.state.message && (
                        <div
                          style={{
                            textAlign: "center",
                            display: "block",
                            width: "100%",
                          }}
                        >
                          <div style={{ textAlign: "center", color: "red" }}>
                            {this.state.message}
                          </div>
                        </div>
                      )}
                      {!this.state.loading && (
                        <Col className="basic_info_processed">
                          {/* Kept the Checkbox seperately outside so that if checkbox is clicked,
it will not open the collapsible section */}

                          <Collapsible>
                            {/* All Collapsible should be open so make it active*/}
                            {this.state.processedResumes[fileName] && (
                              <li key={fileName}>
                                {/*<UploadedFileResults fileName={fileName}
								fileValues={fileValues}
								isResumeValid ={this.isResumeValid}
		/>*/}

                                <Processed
                                  processedResumes={this.state.loadData}
                                  handleUpdateProcessedResume={
                                    this.handleUpdateProcessedResume
                                  }
                                  fileName={fileName}
                                  handleEditable={this.handleEditable}
                                  handleSubmit={this.handleSubmit}
                                  handleWorkSubmit={this.handleWorkSubmit}
                                  disabled={this.state.disabled}
                                />
                              </li>
                            )}
                          </Collapsible>
                        </Col>
                      )}
                      {this.state.processedResumes[fileName] && (
                        <Col className="get_insigts_button">
                          {!this.state.editable && (
                            <>
                              <Button
                                id="submit_gain"
                                className="submit_gain"
                                variant="contained"
                                aria-owns={
                                  open ? "mouse-over-popover" : undefined
                                }
                                aria-haspopup="true"
                                onMouseEnter={this.handlePopoverOpen}
                                onMouseLeave={this.handlePopoverClose}
                              >
                                Re-Evaluate
                              </Button>

                              <Popover
                                id="mouse-over-popover"
                                sx={{
                                  pointerEvents: "none",
                                }}
                                open={open}
                                anchorEl={this.state.anchorEl}
                                anchorOrigin={{
                                  vertical: "bottom",
                                  horizontal: "left",
                                }}
                                transformOrigin={{
                                  vertical: "top",
                                  horizontal: "left",
                                }}
                                onClose={this.handlePopoverClose}
                                disableRestoreFocus
                              >
                                <Typography sx={{ p: 1 }}>
                                  No Changes Detected
                                </Typography>
                              </Popover>
                            </>
                          )}

                          {this.state.editable && (
                            <Button
                              id="submit_gain"
                              className="submit_gain enabled"
                              onClick={this.handleFormSubmit}
                              variant="contained"
                            >
                              Re-Evaluate
                            </Button>
                          )}
                        </Col>
                      )}
                      {!this.state.loading && (
                        <Col className="top_skills_right">
                          {/* Kept the Checkbox seperately outside so that if checkbox is clicked,
							   it will not open the collapsible section */}

                          <Collapsible>
                            {/* All Collapsible should be open so make it active*/}

                            <li key={fileName}>
                              {/*<UploadedFileResults fileName={fileName}
															   fileValues={fileValues}
															   isResumeValid ={this.isResumeValid}
									   />*/}

                              {this.state.processedResumes[fileName] && (
                                <TopSkills
                                  processedResumes={this.state.loadData}
                                  handleUpdateProcessedResume={
                                    this.handleUpdateProcessedResume
                                  }
                                  fileName={fileName}
                                  disabled={this.state.disabled}
                                />
                              )}
                            </li>
                          </Collapsible>
                        </Col>
                      )}
                    </Row>
                  )
                )}
              </>
            )}

          {/* ------------------------------------ */}
        </div>
      </section>
    );
  }
}
export default UploadParseResume;
