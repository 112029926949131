import { Box, Paper, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@material-ui/core';
import React from 'react';
import { Table } from 'react-materialize';

function TopSkillsTableRead({ topSkills }) {
    return (
        <Box p={1}>
            <Box pb={2}>
                <Typography align={"center"} variant={"h6"}>
                    <b>{"Top Skills from Resume"}</b>
                </Typography>
            </Box>
            <Box>
                {topSkills && topSkills.length !== 0 ? (
                    <TableContainer component={Paper}>
                        <Table style={{ width: 'inherit' }}>
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        <Typography>
                                            <b>{"Skill Name"}</b>
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography>
                                            <b>{"Time"}</b>
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography>
                                            <b>{"Recent Projects"}</b>
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography>
                                            <b>{"Count"}</b>
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {topSkills.map((skill, index) => {
                                    return (
                                        <TableRow key={`topSkills-${index}`}>
                                            <TableCell>
                                                <Typography>{skill.skill_name}</Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography>{(skill.time[0].match(/[0-9]+/g))[0]}y &nbsp; {(skill.time[1].match(/[0-9]+/g))[1]}m</Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography>{skill.recent_projects.split('|')[0]}
                                                    {skill.recent_projects.split('|')[1] && skill.recent_projects.split('|')[1] && <span> - {skill.recent_projects.split('|')[1]} </span>}</Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography>{skill.count}</Typography>
                                            </TableCell>

                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                ) : (
                    <Typography align={"center"}>
                        <b>{"Skills not found"}</b>
                    </Typography>
                )}
            </Box>
        </Box>



    )

}
export default TopSkillsTableRead;