import { Box, Button, Grid, Typography } from "@material-ui/core";
import { Add } from "@material-ui/icons";
import React, { Fragment } from "react";
import CustomDatePicker from "../../../commonComponents/DatePicker/CustomDatePicker";
import { isNumberWithDecimal } from "../../../utils/constants/validation";
import InputField from "./InputField";
import SelectDropDown from "./SelectDropDown";

const COURSE = [
  {
    value: "",
    label: "Select",
  },
  {
    value: "UG",
    label: "UG",
  },
  {
    value: "PG",
    label: "PG",
  },
];

const SCORE = [
  {
    value: "",
    label: "Select",
  },
  {
    value: "1",
    label: "1",
  },
  {
    value: "2",
    label: "2",
  },
];

function EducationAndCertification({
  values = {},
  handleAddCertification = () => {},
  handleRemoveCertification = () => {},
  handleAddEduction = () => {},
  handleRemoveEduction = () => {},
  handleInputChange = () => {},
  handleInputValueChange = () => {},
  handleChange = () => {},
  touched,
  errors,
  isAdmin,
}) {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant={"h6"}>
          <b>{"Education"}</b>
        </Typography>
      </Grid>
      {values.education && values.education.length
        ? values.education.map((el, index) => (
            <Fragment key={`education-${index + 1}`}>
              <Grid item xs={12}>
                <Box
                  border={"1px solid rgba(0, 0, 0, 0.23)"}
                  padding={"16px"}
                  borderRadius={"4px"}
                >
                  <Box
                    padding={"8px 16px"}
                    margin={"-16px -16px 16px"}
                    className={"bg-gray-200"}
                  >
                    <Typography>
                      <b>{`Education ${index + 1}:`}</b>
                    </Typography>
                  </Box>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <SelectDropDown
                        label={"Graduation (UG/PG)"}
                        name={"graduation"}
                        options={COURSE}
                        variant={"outlined"}
                        size={"small"}
                        value={el.graduation || ""}
                        onChange={(e) =>
                          handleInputChange(e, index, "education")
                        }
                        error={
                          touched.education?.[index]?.graduation &&
                          Boolean(errors.education?.[index]?.graduation)
                        }
                        errorMessage={
                          touched.education?.[index]?.graduation &&
                          errors.education?.[index]?.graduation
                        }
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <InputField
                        label={"Degree"}
                        name={"degree"}
                        value={el.degree || ""}
                        placeholder={"Enter degree"}
                        onChange={(e) =>
                          handleInputChange(e, index, "education")
                        }
                        error={
                          touched.education?.[index]?.degree &&
                          errors.education?.[index]?.degree
                        }
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <InputField
                        label={"Major/Department"}
                        name={"major"}
                        value={el.major || ""}
                        placeholder={"Enter major/department"}
                        onChange={(e) =>
                          handleInputChange(e, index, "education")
                        }
                        error={
                          touched.education?.[index]?.major &&
                          errors.education?.[index]?.major
                        }
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <InputField
                        label={"University/College"}
                        name={"university"}
                        value={el.university || ""}
                        placeholder={"Enter university/college"}
                        onChange={(e) =>
                          handleInputChange(e, index, "education")
                        }
                        error={
                          touched.education?.[index]?.university &&
                          errors.education?.[index]?.university
                        }
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <CustomDatePicker
                        label={"Year Passed"}
                        name={"year_of_passing"}
                        error={
                          touched.education?.[index]?.year_of_passing &&
                          Boolean(errors.education?.[index]?.year_of_passing)
                        }
                        helperText={
                          touched.education?.[index]?.year_of_passing &&
                          errors.education?.[index]?.year_of_passing
                        }
                        value={
                          el.year_of_passing
                            ? new Date(el.year_of_passing)
                            : null
                        }
                        onChange={(date) =>
                          handleInputValueChange(
                            date,
                            "year_of_passing",
                            index,
                            "education"
                          )
                        }
                        views={["year"]}
                        openTo={"year"}
                        format={"YYYY"}
                        disableFuture
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <InputField
                        label={"% / CGPA"}
                        name={"cgpa"}
                        value={el.cgpa || ""}
                        placeholder={"Enter % / CGPA"}
                        onChange={(e) => {
                          let { value } = e.target;
                          let number = Number(value);
                          if (
                            (isNumberWithDecimal(value) &&
                              number >= 0 &&
                              number <= 100) ||
                            value.length === 0
                          )
                            handleInputChange(e, index, "education");
                          else e.preventDefault();
                        }}
                        error={
                          touched.education?.[index]?.cgpa &&
                          errors.education?.[index]?.cgpa
                        }
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box
                  display={"flex"}
                  justifyContent={"space-between"}
                  mt={"-5px"}
                  px={2}
                >
                  <Button
                    variant={"contained"}
                    color={"secondary"}
                    size={"small"}
                    onClick={() => handleRemoveEduction(index)}
                    disabled={values.education.length === 1}
                  >
                    <b>{"Delete"}</b>
                  </Button>
                  {values.education.length - 1 === index ? (
                    <Button
                      variant={"contained"}
                      color={"primary"}
                      size={"small"}
                      startIcon={<Add />}
                      onClick={handleAddEduction}
                    >
                      <b>{"Add"}</b>
                    </Button>
                  ) : (
                    <Box />
                  )}
                </Box>
              </Grid>
            </Fragment>
          ))
        : null}
      {isAdmin ? (
        <Grid item xs={12} container justifyContent={"flex-end"}>
          <Box width={"175px"}>
            <SelectDropDown
              label={"Education Score"}
              name={"education_score"}
              options={SCORE}
              variant={"outlined"}
              size={"small"}
              value={values.education_score || ""}
              onChange={handleChange}
            />
          </Box>
        </Grid>
      ) : null}
      <Grid item xs={12}>
        <Box pt={1}>
          <Typography variant={"h6"}>
            <b>{"Certifications"}</b>
          </Typography>
        </Box>
      </Grid>
      {values.certification && values.certification.length
        ? values.certification.map((el, index) => {
            return (
              <Fragment key={`certification-${index + 1}`}>
                <Grid item xs={12} key={`certification-${index + 1}`}>
                  <Box
                    border={"1px solid rgba(0, 0, 0, 0.23)"}
                    padding={"16px"}
                    borderRadius={"4px"}
                    overflow={"hidden"}
                  >
                    <Box
                      padding={"8px 16px"}
                      margin={"-16px -16px 16px"}
                      className={"bg-gray-200"}
                    >
                      <Typography>
                        <b>{`Certification ${index + 1}:`}</b>
                      </Typography>
                    </Box>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <InputField
                          label={"Certification Name"}
                          name={"name"}
                          value={el.name || ""}
                          placeholder={"Enter certification name"}
                          onChange={(e) =>
                            handleInputChange(e, index, "certification")
                          }
                          error={
                            touched.certification?.[index]?.name &&
                            errors.certification?.[index]?.name
                          }
                        />
                      </Grid>
                      <Grid item xs>
                        <CustomDatePicker
                          label={"Year of Completion"}
                          name={"year_of_passing"}
                          error={
                            touched.certification?.[index]?.year_of_passing &&
                            Boolean(
                              errors.certification?.[index]?.year_of_passing
                            )
                          }
                          helperText={
                            touched.certification?.[index]?.year_of_passing &&
                            errors.certification?.[index]?.year_of_passing
                          }
                          value={
                            el.year_of_passing
                              ? new Date(el.year_of_passing)
                              : null
                          }
                          onChange={(date) =>
                            handleInputValueChange(
                              date,
                              "year_of_passing",
                              index,
                              "certification"
                            )
                          }
                          views={["year"]}
                          openTo={"year"}
                          format={"YYYY"}
                          disableFuture
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box
                    display={"flex"}
                    justifyContent={"space-between"}
                    mt={"-5px"}
                    px={2}
                  >
                    <Button
                      variant={"contained"}
                      color={"secondary"}
                      size={"small"}
                      onClick={() => handleRemoveCertification(index)}
                      disabled={values.certification.length === 1}
                    >
                      <b>{"Delete"}</b>
                    </Button>
                    {values.certification.length - 1 === index ? (
                      <Button
                        variant={"contained"}
                        color={"primary"}
                        size={"small"}
                        startIcon={<Add />}
                        onClick={handleAddCertification}
                        disabled={values.certification.length > 2}
                      >
                        <b>{"Add"}</b>
                      </Button>
                    ) : (
                      <Box />
                    )}
                  </Box>
                </Grid>
              </Fragment>
            );
          })
        : null}
      {/* <Grid item xs={12}>
        <Typography>
          <b>{`Note: `}</b>
          {"Please verify the above info and click Next."}
        </Typography>
      </Grid> */}
      <Grid item xs={12} />
    </Grid>
  );
}

export default EducationAndCertification;
