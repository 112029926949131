import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Box } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

export default function Loader({ minHeight = "50px" }) {
  const classes = useStyles();

  return (
    <Box className={classes.root} minHeight={minHeight}>
      <CircularProgress color={"primary"} />
    </Box>
  );
}
