import {
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
} from "@material-ui/core";
import React from "react";

export default function YesOrNoSelect({
  name,
  value,
  onChange,
  onBlur,
  helperText,
  error = false,
  fullWidth = false,
  variant = "standard",
  size = "medium",
  disabled,
}) {
  return (
    <FormControl
      size={size}
      variant={variant}
      fullWidth={fullWidth}
      error={error}
      disabled={disabled}
    >
      <Select
        disabled={disabled}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        name={name}
      >
        <MenuItem value={"Yes"}>Yes</MenuItem>
        <MenuItem value={"No"}>No</MenuItem>
      </Select>
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  );
}
