import React, { Component } from 'react';
import { Row, Col, Modal, Button } from 'react-materialize';


import * as urc   from '../../../../utils/constants/uploadResumeConstants';

class SkillMatchReportPopUp extends Component {


    render() {
        const {reportActionValues, updateReportActionValues} = this.props;

        const {action, downloadFlag, timeStamp} = this.props.customMatchReportAction;

        return (
            <>
                <div hidden>
                    <Button href="#skillMatchReportPopup"
                        id='skillMatchReportPopupTrigger'
                        className="modal-trigger">
                    </Button>
                </div>

                <Modal header='Information'
                    id='skillMatchReportPopup'
                    actions= ''
                    // actions={
                    //     <Button type='submit' className='modal-close center-align'
                    //     > Close </Button>
                    // }
                >
                    {((action === 'delete' && timeStamp !== '' && downloadFlag === false) ||
                        (action === 'deleteAll' && timeStamp === '')) &&
                    <>
                    <Row>
                        <Col s={10} className='offset-s1'>
                            {action === 'deleteAll' ?
                                <h6>  Some of the reports have not been downloaded. Are you sure you want to delete all the reports?</h6>
                                :
                                <h6> This report has not been downloaded. Are you sure you want to delete the report?</h6>
                            }
                        </Col>
                    </Row>
                    <Row className='buttonDiv'>
                        <Button className='modal-close'
                                onClick={ () => { if (action === 'deleteAll') updateReportActionValues([]);
                                                else updateReportActionValues((reportActionValues.filter((element) => element.timeStamp !== timeStamp)))
                                        }}
                        > Yes </Button>
                        <Button className='modal-close'> Cancel </Button>
                    </Row>
                    </>
                    }

                    {((action === 'download' && timeStamp !== '' && downloadFlag === true)  ||
                        (action === 'downloadAll' && timeStamp === '')) &&
                        <>
                        <Row>
                            <Col s={10} className='offset-s1'>
                                {action === 'downloadAll' ?
                                <h6> All the reports have been already downloaded. Are you sure you want to download it again?</h6>
                                :
                                <h6>  This report has been already downloaded. Are you sure you want to download it again?</h6>
                                }
                            </Col>
                        </Row>
                        <Row className='buttonDiv'>
                            <Button className='modal-close'
                                    onClick={ () => urc.downloadReport(reportActionValues,timeStamp)}
                            > Yes </Button>
                            <Button className='modal-close'> Cancel </Button>
                        </Row>
                        </>
                        }
                </Modal>
            </>
        )
    }
}

export default SkillMatchReportPopUp;