import {
  Button,
  Container,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import SearchIcon from "@material-ui/icons/Search";
import Pagination from "@material-ui/lab/Pagination";
import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import {
  addSkills,
  deleteSkills,
  getAllSkills,
  updateSkills,
} from "../../api/api";
import DeleteDialog from "../../commonComponents/DeleteDialog/DeleteDialog";
import Loader from "../../commonComponents/Loader/Loader";
import {
  StyledTableContainer,
  StyledTableHead,
} from "../../commonComponents/StyledComponents";
import "../../styles/style.css";
import {
  debounce,
  getOffset,
  getPageUsingRemovingAnElement,
} from "../../utils/constants/helpers";
import { isEmptyString } from "../../utils/constants/validation";
import "./style.css";

export const Header = [
  {
    id: 2,
    name: "Skills",
  },
  {
    id: 3,
    name: "Action",
  },
];

export const Options = [
  {
    id: 1,
    value: "10",
  },
  {
    id: 2,
    value: "20",
  },
  {
    id: 3,
    value: "30",
  },
  {
    id: 4,
    value: "40",
  },
  {
    id: 5,
    value: "50",
  },
  {
    id: 6,
    value: "100",
  },
];

axios.defaults.xsrfHeaderName = "X-CSRFToken";
axios.defaults.xsrfCookieName = "csrftoken";

export default function SkillMaster() {
  const [state, setState] = useState({
    show: false,
    skillSet: [],
    isEditing: false,
    skill: "",
    newSkill: "",
  });

  const [error, setError] = useState({ isError: false, errorMessage: "" });

  const [editError, setEditError] = useState({
    isError: false,
    errorMessage: "",
  });

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const [deleteId, setDeleteId] = useState("");

  const [searchSkill, setSearchSkill] = useState("");

  const [totalCount, setTotalCount] = useState(0);

  const [pagination, setPagination] = useState({
    activePage: 1,
    items: 10,
  });

  const [loading, setLoading] = useState(false);

  const getAndSetSkillSet = (limit, page, search = searchSkill) => {
    setLoading(true);
    axios
      .get(getAllSkills(limit, getOffset(page, limit), search), {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("tal_token"),
        },
      })
      .then((response) => {
        setState({
          ...state,
          skillSet: response.data.results,
          isEditing: false,
          newSkill: "",
          show: false,
        });
        setTotalCount(response.data.count);
        setLoading(false);
      });
  };

  useEffect(() => {
    getAndSetSkillSet(pagination.items, 0);
  }, []);

  const onClickEdit = (id, data, index) => {
    console.log(state.isEditing, "====");

    if (!state.isEditing) {
      let copyOfSkillSet = [...state.skillSet];

      copyOfSkillSet[index].edit = true;

      setState({
        ...state,
        skillSet: copyOfSkillSet,
        skill: data,
        isEditing: true,
      });
    }
  };

  const onClickClose = (id, name, index) => {
    let copyOfSkillSet = [...state.skillSet];

    copyOfSkillSet[index].edit = false;

    setState({
      ...state,
      skillSet: copyOfSkillSet,
      skill: "",
      isEditing: false,
    });
  };

  const onChangeCapture = (id, data, index) => {
    setState({ ...state, skill: data });
    setEditError({
      isError: false,
      errorMessage: "",
    });
  };
  const onSelectPage = (e, value) => {
    getAndSetSkillSet(pagination.items, value);

    setPagination({
      ...pagination,
      activePage: value,
    });
  };

  const handlePageLimitChange = (e) => {
    const { value } = e.target;
    getAndSetSkillSet(parseInt(value), pagination.activePage);
    setPagination({
      ...pagination,
      items: parseInt(value),
    });
  };

  const handleEdit = (id) => {
    if (!isEmptyString(state.skill)) {
      axios
        .put(updateSkills(id), { name: state.skill })
        .then((response) => {
          if (response.status === 200) {
            getAndSetSkillSet(pagination.items, pagination.activePage);
          }
        })
        .catch((err) => {
          setEditError({
            isError: true,
            errorMessage: "Skill should unique",
          });
        });
    } else {
      //  Error
      setEditError({
        isError: true,
        errorMessage: "Skill should not be empty",
      });
    }
  };

  const handleAddNewSkillChange = (e) => {
    setState({
      ...state,
      newSkill: e.target.value,
    });
    setError({
      isError: false,
      errorMessage: "",
    });
  };

  const handleAddNewSkillClick = () => {
    if (!isEmptyString(state.newSkill)) {
      axios
        .post(addSkills, { name: state.newSkill })
        .then((response) => {
          if (response.status === 201) {
            getAndSetSkillSet(pagination.items, 0);
            setPagination({
              ...pagination,
              activePage: 1,
            });
          }
        })
        .catch((err) => {
          setError({
            isError: true,
            errorMessage: "Skill should unique",
          });
        });
    } else {
      //  Error
      setError({
        isError: true,
        errorMessage: "Skill should not be empty",
      });
    }
  };

  const handleDeleteClick = () => {
    axios.delete(deleteSkills(deleteId)).then((response) => {
      if (response.status === 204) {
        let page = getPageUsingRemovingAnElement(
          totalCount,
          pagination.activePage,
          pagination.items
        );
        getAndSetSkillSet(pagination.items, page);
        setPagination({
          ...pagination,
          activePage: 1,
        });
      }
    });
    setDeleteDialogOpen(false);
    setDeleteId("");
  };

  const handleSearchChange = (value) => {
    getAndSetSkillSet(pagination.items, 0, value);
    setSearchSkill(value);
    setPagination({
      ...pagination,
      activePage: 1,
    });
  };

  const handleDeleteRowClick = (id) => {
    setDeleteId(id);
    setDeleteDialogOpen(true);
  };

  const optimizedFn = useCallback(debounce(handleSearchChange), [pagination]);

  console.log(state.show, "_______");

  return (
    <Container maxWidth={"md"} className={"containerStyle mt-8"}>
      <Grid container spacing={4}>
        <Grid item xs={3} container alignItems="center">
          <Typography>
            <b>Skill Master</b>
          </Typography>
        </Grid>
        <Grid
          item
          xs={7}
          container
          alignItems="center"
          justifyContent="flex-end"
        >
          <TextField
            id="search"
            label="Search..."
            onChange={(e) => optimizedFn(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton>
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={2} container alignItems="center">
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setState({ ...state, show: true });
            }}
          >
            Add Skill
          </Button>
        </Grid>
        <Grid item xs={12}>
          <StyledTableContainer component={Paper} variant={"outlined"}>
            <Table size={"small"}>
              <StyledTableHead>
                <TableRow>
                  {Header.map(({ name }, ind) => (
                    <TableCell align={ind === 1 ? "center" : ""}>
                      {name}
                    </TableCell>
                  ))}
                </TableRow>
              </StyledTableHead>

              <TableBody>
                {state.show ? (
                  <TableRow>
                    <TableCell>
                      <TextField
                        error={error.isError}
                        helperText={error.errorMessage}
                        onChange={handleAddNewSkillChange}
                        value={state.newSkill}
                        id="new-skill"
                        placeholder="Add New Skill Here..."
                        className="input"
                      />
                    </TableCell>
                    <TableCell>
                      <div className="actionTableCell">
                        <Button
                          size={"small"}
                          variant="contained"
                          color="primary"
                          disabled={state.newSkill ? false : true}
                          onClick={handleAddNewSkillClick}
                        >
                          Add
                        </Button>
                        <Button
                          size={"small"}
                          variant="contained"
                          color="secondary"
                          onClick={() => {
                            setError({
                              isError: false,
                              errorMessage: "",
                            });
                            setState({ ...state, show: false, newSkill: "" });
                          }}
                        >
                          Cancel
                        </Button>
                      </div>
                    </TableCell>
                  </TableRow>
                ) : null}
                {!loading && state.skillSet.length ? (
                  state.skillSet?.map(({ id, name, slug, edit }, index) => (
                    <TableRow key={id}>
                      {/* <TableCell>{index + 1}</TableCell> */}
                      <TableCell>
                        {edit ? (
                          <TextField
                            id="search"
                            value={state.isEditing ? state.skill : name}
                            onChange={(e) =>
                              onChangeCapture(id, e.target.value, index)
                            }
                            className="inputText"
                            error={editError.isError}
                            helperText={editError.errorMessage}
                          />
                        ) : (
                          name
                        )}
                      </TableCell>
                      <TableCell>
                        {edit ? (
                          <div className="flex items-center justify-center">
                            <Button
                              size={"small"}
                              variant={"contained"}
                              color="primary"
                              onClick={() => handleEdit(id)}
                              disabled={state.skill ? false : true}
                            >
                              Save
                            </Button>
                            <IconButton
                              color={"secondary"}
                              onClick={() => {
                                setEditError({
                                  isError: false,
                                  errorMessage: "",
                                });
                                onClickClose(id, name, index);
                              }}
                            >
                              <CloseIcon />
                            </IconButton>
                          </div>
                        ) : (
                          <div className="editTab">
                            <IconButton
                              color="primary"
                              onClick={() => onClickEdit(id, name, index)}
                            >
                              <EditIcon />
                            </IconButton>
                            <IconButton
                              color="secondary"
                              onClick={() => handleDeleteRowClick(id)}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </div>
                        )}
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={2} align={"center"}>
                      {loading ? (
                        <Loader />
                      ) : (
                        <Typography>
                          <b>No results found</b>
                        </Typography>
                      )}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </StyledTableContainer>
        </Grid>
        <Grid
          item
          container
          xs={12}
          alignItems={"center"}
          justifyContent={"flex-end"}
        >
          <Typography>
            Total Count: <b>{totalCount}</b>
          </Typography>
        </Grid>
        <Grid item xs={2} container alignItems="center">
          <FormControl style={{ width: 120 }}>
            <InputLabel id="demo-simple-select-label">Rows per page</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={pagination.items}
              onChange={handlePageLimitChange}
            >
              {Options.map(({ id, value }) => (
                <MenuItem key={id} value={value}>
                  {value}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid
          item
          xs={10}
          container
          alignItems="center"
          justifyContent="flex-end"
        >
          <Pagination
            color={"primary"}
            page={pagination.activePage}
            onChange={onSelectPage}
            count={Math.ceil(totalCount / pagination.items)}
            showFirstButton
            showLastButton
          />
        </Grid>
      </Grid>

      <DeleteDialog
        dialogOpen={deleteDialogOpen}
        handleClose={() => {
          setDeleteDialogOpen(false);
          setDeleteId("");
        }}
        handleYesClick={handleDeleteClick}
      />
    </Container>
  );
}
