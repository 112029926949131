import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Container,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Typography,
  Chip,
  Tooltip,
  Icon,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import GetAppIcon from "@material-ui/icons/GetApp";
import SearchIcon from "@material-ui/icons/Search";
import { Alert, Autocomplete, Pagination } from "@material-ui/lab";
import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  applyJobToCandidate,
  clientEmailSetting,
  deleteIntakeById,
  deleteMultipleIntakeForm,
  downloadExcelFileIntake,
  downloadIntakeFormExcelTemplate,
  downloadIntakeFormTemplate,
  getAllClients,
  getAllJobIntakeForm,
  intakeClientAddEmail,
  intakeSendNotify,
  uploadExcelFileintake,
} from "../../api/api";
import CloseModal from "../../commonComponents/CloseModal/CloseModal";
import DeleteDialog from "../../commonComponents/DeleteDialog/DeleteDialog";
import Loader from "../../commonComponents/Loader/Loader";
import LoadingButton from "../../commonComponents/LoadingButton";
import SendToManagerPopup from "../../commonComponents/SendToManagerPopup";
import ShareJobToCandidate from "../../commonComponents/ShareJobToCandidate";
import CustomSnack from "../../commonComponents/Snack/CustomSnack";
import {
  FlexCenterView,
  FlexColumnCenterView,
  StyledTableContainer,
  StyledTableHead,
} from "../../commonComponents/StyledComponents";
import { setIntakeFormState } from "../../redux/actions/intakeForm";
import { INTAKE_ACTIONS, MESSAGES } from "../../utils/constants/constants";
import {
  debounce,
  formatDate,
  getOffset,
  getPageUsingRemovingAnElement,
  validateEmail,
} from "../../utils/constants/helpers";
import {
  ADMINS,
  ROLES,
  ROLE_BASED_PAGES,
  getRoleBasedAccess,
} from "../../utils/constants/roleBasedAccess";
import { ArrowDropDown, ArrowDropDownCircle, Cancel, FilterList } from "@material-ui/icons";
import CloseIcon from '@material-ui/icons/Close';
import Menu from '@mui/material/Menu';


axios.defaults.xsrfHeaderName = "X-CSRFToken";
axios.defaults.xsrfCookieName = "csrftoken";

export const Options = [
  {
    id: 1,
    value: "10",
  },
  {
    id: 2,
    value: "20",
  },
  {
    id: 3,
    value: "30",
  },
  {
    id: 4,
    value: "40",
  },
  {
    id: 5,
    value: "50",
  },
  {
    id: 6,
    value: "100",
  },
];

export default function IntakeForm() {
  const { push, location } = useHistory();
  const role = localStorage.getItem("role");
  const isAdmin = ADMINS.includes(role);
  const username = localStorage.getItem("username");
  const user_email = localStorage.getItem("user_email");
  const {
    isAllowEdit = true,
    isAllowDelete = true,
    isAllowDownload = true,
    isAllowUpload = true,
    isAllowSelect = true,
    isAllowAddIntakeForm = true,
  } = getRoleBasedAccess(role, ROLE_BASED_PAGES.intakeFormLanding);


  const [isLoading, setIsLoading] = useState(false);
  const [alertBox, setAlertBox] = useState({
    open: false,
    success: true,
    message: "",
  });
  const [jobIntake, setJobIntake] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [multiSelectedId, setMultiSelectedId] = useState([]);
  const [singleDeleteId, setSingleDeleteId] = useState(null);
  const [singleJobDetail, setSingleJobDetail] = useState({});
  const [snack, setSnack] = useState({
    open: false,
    color: "",
    message: "",
  });
  const [addIntakeSelectionPopupOpen, setAddIntakeSelectionPopupOpen] =
    useState(false);
  const [sendToManagerEmail, setSendToManagerEmail] = useState([]);
  const [sendToManagerPopupOpen, setSendToManagerPopupOpen] = useState(false);
  const [sendToManagerLoading, setSendToManagerLoading] = useState(false);
  const [managerEmailList, setManagerEmailList] = useState([]);
  const [candidateEmail, setCandidateEmail] = useState("");
  const [shareJobToCandidatePopupOpen, setShareJobToCandidatePopupOpen] =
    useState(false);
  const [shareJobToCandidateLoading, setShareJobToCandidateLoading] =
    useState(false);
  const [filterDialogOpen, setFilterDialogOpen] = useState(false);
  const [clientNameFilter, setClientNameFilter] = useState(null);
  const [fromDateFilter, setFromDateFilter] = useState(null);
  const [toDateFilter, setToDateFilter] = useState(null);
  const [dateValidationMessage, setDateValidationMessage] = useState('');
  const [filters, setFilters] = useState({})


  const { pagination, searchIntake } = useSelector((state) => state.intakeForm);
  const dispatch = useDispatch();
  const [client, setClient] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState([]);

  const jobIntakeIdList =
    jobIntake && Array.isArray(jobIntake) && jobIntake.length !== 0
      ? jobIntake.map(({ id }) => id)
      : [];

  const singleSelectedIntakeObj =
    multiSelectedId.length === 1
      ? jobIntake?.find((el) => el.id === multiSelectedId[0])
      : null;

  const handleSnackClose = () => {
    setSnack({
      open: false,
      color: "",
      message: "",
    });
  };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSnackOpen = (message = MESSAGES.error, color = "error") => {
    setSnack({
      open: true,
      color: color,
      message: message || MESSAGES.error,
    });
  };

  const sendNotify = ({ job_title, job_code, action }) => {
    let payload = {
      role: isAdmin ? ROLES.admin : role,
      username: username,
      job_title: job_title,
      job_code: job_code,
      action: action,
    };
    axios.post(intakeSendNotify(), payload);
  };

  const getAndSetJobIntake = (
    limit = pagination.items,
    page = pagination.activePage,
    search = searchIntake,
    filter = filters,
  ) => {
    setLoading(true);
    axios
      .get(getAllJobIntakeForm(), {
        params: {
          limit: limit,
          offset: getOffset(page, limit),
          search: search,
          client_name: filter.client_name,
          from_date: filter.from_date,
          to_date: filter.to_date
        }
      })
      .then((response) => {
        if (response.status === 200) {
          setTotalCount(response.data.count);
          setJobIntake(response.data.results);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getAndSetClient = (
  ) => {
    setLoading(true);
    axios
      .get(getAllClients())
      .then((response) => {
        if (response.status === 200) {
          setClient(response.data);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onSelectPage = (e, value) => {
    // Call getAndSetJobIntake with filter values, current page limit, and the new page
    getAndSetJobIntake(pagination.items, value);

    // Update the pagination settings
    dispatch(
      setIntakeFormState({
        pagination: { ...pagination, activePage: value },
      })
    );
  };

  const handlePageLimitChange = (e) => {
    const { value } = e.target;

    // Call getAndSetJobIntake with filter values, new page limit, and current page
    getAndSetJobIntake(parseInt(value), pagination.activePage);

    // Update the pagination settings
    dispatch(
      setIntakeFormState({
        pagination: { ...pagination, items: parseInt(value) },
      })
    );
  };


  // Function to handle opening and closing of filter dialog
  const handleFilterDialogOpen = () => {
    setFilterDialogOpen(true);
  };

  const handleDeleteFilter = (index) => {
    const updatedFilters = [...selectedFilters];
    const removedFilter = updatedFilters.splice(index, 1)[0]; // Remove the filter at the specified index
    setSelectedFilters(updatedFilters);
    let filter = {
      ...filters
    }

    // Update the API call by removing the undone filter
    if (removedFilter.label === 'Client Name') {
      setClientNameFilter(null); // Clear client name filter
      filter.client_name = null;
    } else {
      setFromDateFilter(null);
      setToDateFilter(null);
      filter.from_date = null;
      filter.to_date = null;
    }
    setFilters(filter)
    getAndSetJobIntake(pagination.items, 1, searchIntake, filter);
  };

  const handleFilterDialogClose = () => {

    setFilterDialogOpen(false);
    if (!filters.client_name)
      setClientNameFilter(null);
    if (!filters.from_date)
      setFromDateFilter(null);
    if (!filters.to_date)
      setToDateFilter(null);

  };

  const clearFilters = () => {
    setClientNameFilter(null); // Clear client name filter
    setFromDateFilter(null); // Clear from date filter
    setToDateFilter(null); // Clear to date filter
    setSelectedFilters([]);
    setFilters({})
    setDateValidationMessage('');
    if (filters.client_name || filters.from_date || filters.to_date)
      getAndSetJobIntake(pagination.items, 1, searchIntake, {});
  };

  // Function to apply filters
  const applyFilters = () => {

    // Validation logic
    if ((fromDateFilter && !toDateFilter) || (!fromDateFilter && toDateFilter)) {
      // Update validation message
      setDateValidationMessage("Both 'From Date' and 'To Date' are required for the date filter.");
      return; // Do not apply filters if validation fails
    }

    // Clear validation message if validation passes
    setDateValidationMessage('');
    let filter = {
      ...filters
    }
    const filtersArr = [];
    if (clientNameFilter) {
      // Add client name filter to selectedFilters
      filtersArr.push({ label: 'Client Name', value: clientNameFilter });
      filter.client_name = clientNameFilter
    }
    if (fromDateFilter && toDateFilter) {
      // Add date range filter to selectedFilters
      filtersArr.push({ label: 'Date Range', value: `${fromDateFilter} - ${toDateFilter}` });
      filter.from_date = fromDateFilter
      filter.to_date = toDateFilter
    }
    setSelectedFilters(filtersArr);
    setFilters(filter)

    // Call getAndSetJobIntake with filter values
    // Remember to implement this function with your logic
    getAndSetJobIntake(pagination.items, 1, searchIntake, filter);

    // Close the filter dialog
    setFilterDialogOpen(false);
  };

  useEffect(() => {
    getAndSetJobIntake(pagination.items, pagination.activePage);
    getAndSetClient();
  }, []);

  const handleUploadClick = (e) => {
    setIsLoading(true);
    const { files } = e.target;
    let formData = new FormData();
    formData.append("file", files[0]);

    axios
      .post(uploadExcelFileintake(), formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        if (response.status === 201) {
          setAlertBox({
            open: true,
            success: true,
            message: "File uploaded successfully.",
          });
          getAndSetJobIntake(pagination.items, 1);
          dispatch(
            setIntakeFormState({
              pagination: { ...pagination, activePage: 1 },
            })
          );
        } else {
          setAlertBox({
            open: true,
            success: false,
            message: "Something went wrong!",
          });
        }
      })
      .catch((err) => {
        setAlertBox({
          open: true,
          success: false,
          message: "Something went wrong!",
        });
      })
      .finally(() => {
        setIsLoading(false);
        setTimeout(() => {
          setAlertBox({
            open: false,
            success: true,
            message: "",
          });
        }, 2000);
      });
  };

  const handleEditClick = (id) => {
    push(`/intake/addForm/${id}`);
  };

  const handleDeleteClick = (id, job_code, job_title) => {
    setSingleDeleteId(id);
    setSingleJobDetail({
      job_code,
      job_title,
    });
  };

  const handleDownloadClick = (id, job_title, job_code) => {
    /*
     * What is the proper way to download the excel file using axios?
     */
    //  window.open(downloadExcelFileIntake(id))
    axios
      .get(downloadExcelFileIntake(id), { responseType: "blob" })
      .then((response) => {
        // https://stackoverflow.com/a/53230807
        // create file link in browser's memory
        const href = URL.createObjectURL(response.data);

        // create "a" HTML element with href to file & click
        const link = document.createElement("a");
        link.href = href;
        link.setAttribute("download", `${job_title}(${job_code}).pdf`); //or any other extension
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);

        sendNotify({
          job_code: job_code,
          job_title: job_title,
          action: INTAKE_ACTIONS.download,
        });
        getAndSetJobIntake(pagination.items, pagination.activePage);
      });
  };


  const handleSearchChange = (value) => {
    getAndSetJobIntake(pagination.items, 1, value);
    dispatch(
      setIntakeFormState({
        searchIntake: value,
        pagination: { ...pagination, activePage: 1 },
      })
    );
  };

  const optimizedFn = useCallback(debounce(handleSearchChange), [pagination]);

  const handleDeleteDialogClose = () => {
    setDeleteDialogOpen(false);
    setSingleDeleteId(null);
  };

  const handleConfirmDelete = () => {
    let payload = {
      intakeform_ids: multiSelectedId,
    };
    axios
      .delete(deleteMultipleIntakeForm(), { data: payload })
      .then((response) => {
        handleDeleteDialogClose();
        setMultiSelectedId([]);
        if (response.status === 204) {
          setSnack({
            open: true,
            color: "success",
            message: "Deleted Successfully",
          });
          setTimeout(() => {
            getAndSetJobIntake(pagination.items, 1);
            dispatch(
              setIntakeFormState({
                pagination: { ...pagination, activePage: 1 },
              })
            );
          }, 2500);
        } else {
          handleDeleteDialogClose();
          handleSnackOpen();
        }
      })
      .catch((error) => {
        handleDeleteDialogClose();
        handleSnackOpen();
      });
  };

  const handleSingleDelete = () => {
    axios
      .delete(deleteIntakeById(singleDeleteId))
      .then((response) => {
        if (response.status === 204) {
          handleDeleteDialogClose();
          setSnack({
            open: true,
            color: "success",
            message: "Deleted Successfully",
          });

          sendNotify({
            job_code: singleJobDetail.job_code,
            job_title: singleJobDetail.job_code,
            action: INTAKE_ACTIONS.delete,
          });

          let arr = [...multiSelectedId];
          let index = arr.indexOf(singleDeleteId);
          if (index >= 0) arr.splice(index, 1);
          setMultiSelectedId(arr);

          setTimeout(() => {
            setSingleJobDetail({});
            let page = getPageUsingRemovingAnElement(
              totalCount,
              pagination.activePage,
              pagination.items
            );
            getAndSetJobIntake(pagination.items, page);
            dispatch(
              setIntakeFormState({
                pagination: { ...pagination, activePage: page },
              })
            );
          }, 2500);
        } else {
          handleDeleteDialogClose();
          handleSnackOpen();
          setSingleJobDetail({});
        }
      })
      .catch((err) => {
        handleDeleteDialogClose();
        handleSnackOpen();
        setSingleJobDetail({});
      });
  };

  const handleSelectAllClick = () => {
    let arr = [...multiSelectedId];
    let multipleExist = jobIntakeIdList.every((value) => arr.includes(value));
    let remainingId = jobIntakeIdList.filter((value) => !arr.includes(value));

    if (multipleExist)
      arr = arr.filter((value) => !jobIntakeIdList.includes(value));
    else arr = [...arr, ...remainingId];
    setMultiSelectedId(arr);
  };

  const handleSelect = (e) => {
    const { id } = e.target;
    let arr = [...multiSelectedId];
    let index = arr.indexOf(id);
    if (index >= 0) arr.splice(index, 1);
    else arr.push(id);
    setMultiSelectedId(arr);
  };

  const handleDelete = () => {
    setDeleteDialogOpen(true);
  };

  const handleAddIntakeForm = () => {
    // if (isAdmin) {
    //   setAddIntakeSelectionPopupOpen(true);
    // } else {
    push(`/intake/addForm`);
    // }
  };

  const handleAddIntake = () => {
    push(`/intake/addForm`);
  };

  const handleSendToManagerModalOpen = () => {
    axios
      .get(clientEmailSetting())
      .then((response) => {
        if (response.status === 200) {
          setAddIntakeSelectionPopupOpen(false);
          setSendToManagerPopupOpen(true);
          let obj = response.data[0];
          if (obj) {
            let options = [];
            for (const [key, value] of Object.entries(obj)) {
              // If check the client & hiring manager email
              if (
                (key.includes("client_manager_email") ||
                  key.includes("hm_email")) &&
                validateEmail(value)
              )
                options.push(value);
            }
            setManagerEmailList(options);
          }
        } else {
          handleSnackOpen();
        }
      })
      .catch((error) => {
        handleSnackOpen();
      });
  };

  const handleDownloadTemplate = () => {
    axios
      .get(downloadIntakeFormTemplate(), { responseType: "blob" })
      .then((response) => {
        if (response.status === 200) {
          const href = URL.createObjectURL(response.data);
          const link = document.createElement("a");
          link.href = href;
          link.setAttribute("download", `Intake_Form_Template.docx`);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          URL.revokeObjectURL(href);
        } else {
          handleSnackOpen();
        }
      })
      .catch((error) => {
        handleSnackOpen();
      });
    setAnchorEl(null);
  };

  const handleExcelDownloadTemplate = () => {
    axios
      .get(downloadIntakeFormExcelTemplate(), { responseType: "blob" })
      .then((response) => {
        if (response.status === 200) {
          const href = URL.createObjectURL(response.data);
          const link = document.createElement("a");
          link.href = href;
          link.setAttribute("download", `Intake_Form_Template.xlsx`);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          URL.revokeObjectURL(href);
        } else {
          handleSnackOpen();
        }
      })
      .catch((error) => {
        handleSnackOpen();
      });
    setAnchorEl(null);
  };

  const handleAddIntakeSelectionModalClose = () => {
    setAddIntakeSelectionPopupOpen(false);
  };

  const handleEmailChange = (val) => {
    let isNotValid = val.some((el) => !validateEmail(el));
    if (val.length && isNotValid) {
      setSnack({
        open: true,
        message: "Please enter a valid email",
        color: "error",
      });
    } else {
      setSendToManagerEmail(val);
    }
  };

  const handleSentToManagerPopupClose = () => {
    setSendToManagerPopupOpen(false);
    setSendToManagerEmail([]);
  };

  const handleSentToManager = () => {
    if (sendToManagerEmail.length) {
      setSendToManagerLoading(true);
      let payload = {
        to_email: sendToManagerEmail,
      };
      axios
        .post(intakeClientAddEmail(), payload)
        .then((response) => {
          if (response.status === 200) {
            handleSnackOpen(
              MESSAGES.addIntakeClientEmailSuccessMessage,
              "success"
            );
            handleSentToManagerPopupClose();
          } else {
            handleSnackOpen();
          }
          setSendToManagerLoading(false);
        })
        .catch((error) => {
          setSendToManagerLoading(false);
          handleSnackOpen();
        });
    } else {
      handleSnackOpen("Please select the To Email");
    }
  };

  const handleShareJobToCandidateClick = () => {
    setShareJobToCandidatePopupOpen(true);
  };

  const handleCandidateEmailChange = (e) => {
    const { value } = e.target;
    setCandidateEmail(value);
  };

  const handleShareJobToCandidatePopupClose = () => {
    setShareJobToCandidatePopupOpen(false);
    setCandidateEmail("");
  };

  const handleShareJobToCandidate = () => {
    if (candidateEmail?.trim().length) {
      const delimiter = ";";
      const separatedEmail = candidateEmail.split(delimiter);

      const isNotValidEmail = separatedEmail.some(
        (el) => el.trim().length && !validateEmail(el.trim())
      );
      if (isNotValidEmail) {
        handleSnackOpen("Please enter the valid Candidate Email");
        return;
      }

      setShareJobToCandidateLoading(true);
      let payload = {
        to_email: candidateEmail,
        job_code: singleSelectedIntakeObj.job_code,
        from_email: user_email,
      };
      axios
        .post(applyJobToCandidate(), payload)
        .then((response) => {
          if (response.status === 200) {
            setMultiSelectedId([]);
            handleSnackOpen(
              MESSAGES.shareJobToCandidateEmailSuccessMessage,
              "success"
            );
            handleShareJobToCandidatePopupClose();
          } else {
            handleSnackOpen();
          }
          setShareJobToCandidateLoading(false);
        })
        .catch((error) => {
          setShareJobToCandidateLoading(false);
          handleSnackOpen();
        });
    } else {
      handleSnackOpen("Please enter the Candidate Email");
    }
  };

  const isAllSelected = Boolean(
    jobIntake?.length &&
    jobIntake.every((el) => multiSelectedId.includes(el.id))
  );

  return (
    <Container maxWidth={"lg"} className={"containerStyle mt-8"}>
      <Grid container spacing={2}>
        <Grid item xs={2} container alignItems="center">
          <Typography>
            <b>Intake Form</b>
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          container
          alignItems={"center"}
          justifyContent={"flex-end"}
          style={{ gap: "15px" }}
        >
          <TextField
            id={"search"}
            label={"Search..."}
            onChange={(e) => optimizedFn(e.target.value)}
            defaultValue={searchIntake}
            InputProps={{
              endAdornment: (
                <InputAdornment position={"end"}>
                  <IconButton>
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            size={"small"}
          />
          {isAllowAddIntakeForm ? (
            <Button
              size={"small"}
              variant={"contained"}
              color={"primary"}
              onClick={handleAddIntakeForm}
            >
              {"Add"}
            </Button>
          ) : null}
          <Box minWidth={"76px"}>
            {isLoading ? (
              <CircularProgress />
            ) : (
              <>
                <input
                  className={"hidden"}
                  id="contained-button-file"
                  type="file"
                  onChange={handleUploadClick}
                  disabled={!isAllowUpload}
                />
                <label htmlFor="contained-button-file">
                  <Button
                    size="small"
                    variant="contained"
                    color="primary"
                    component="span"
                    disabled={!isAllowUpload}
                  >
                    Upload
                  </Button>
                </label>
              </>
            )}
          </Box>
          <Button
            size={"small"}
            variant={"contained"}
            color={"primary"}
            onClick={handleShareJobToCandidateClick}
            disabled={!singleSelectedIntakeObj}
          >
            {"Share Job"}
          </Button>
          <Button
            size={"small"}
            variant={"contained"}
            color={"primary"}
            disabled={!isAllowDelete || !multiSelectedId.length}
            onClick={handleDelete}
          >
            {"Delete"}
          </Button>
          <Button
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={handleClick}
            color={"primary"}
            variant="contained"
            endIcon={<ArrowDropDown />}
            style={{ padding: '4px 10px' }} // Set padding
          >
            Download Template
          </Button>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
          >
            <MenuItem onClick={handleExcelDownloadTemplate}>Excel</MenuItem>
            <MenuItem onClick={handleDownloadTemplate}>Word</MenuItem>
          </Menu>
          {/* FilterList button */}
          <Tooltip title={"Filter"} arrow>
            <IconButton
              color="primary"
              onClick={handleFilterDialogOpen}
            >
              <FilterList />
            </IconButton>
          </Tooltip>

        </Grid>
        {/* Selected Filters */}
        {selectedFilters.length ? <Grid item xs={12} >
          {selectedFilters.map((filter, index) => (
            <Chip
              key={index}
              label={<b>{`${filter.label}: ${filter.value}`}</b>}
              onDelete={() => handleDeleteFilter(index)}
            />
          ))}
        </Grid> : null}
        {alertBox.open && (
          <Grid item xs={12}>
            <Alert severity={`${alertBox.success ? "success" : "error"}`}>
              {alertBox.message}
            </Alert>
          </Grid>
        )}
        <Grid item xs={12}>
          <StyledTableContainer component={Paper} variant={"outlined"}>
            <Table size={"small"}>
              <StyledTableHead>
                <TableRow>
                  <TableCell align="center">
                    <Checkbox
                      checked={isAllSelected}
                      onChange={handleSelectAllClick}
                      disabled={!isAllowSelect}
                    />
                  </TableCell>
                  {["Client Name", "Job Title", "Job Code"].map((el, ind) => (
                    <TableCell key={`table_head_cell_${ind}`}>{el}</TableCell>
                  ))}
                  <TableCell align={"center"}>{"Job Location"}</TableCell>
                  <TableCell align={"center"}>{"Created Date"}</TableCell>
                  {isAllowEdit || isAllowDelete || isAllowDownload ? (
                    <TableCell align={"center"}>{"Actions"}</TableCell>
                  ) : null}
                </TableRow>
              </StyledTableHead>

              <TableBody>
                {!loading && jobIntake.length ? (
                  jobIntake?.map(
                    (
                      { id, client_name, job_title, job_code, job_location, created, is_downloaded },
                      index
                    ) => (
                      <TableRow key={id}>
                        <TableCell align="center" padding="none">
                          <Checkbox
                            id={id}
                            checked={multiSelectedId.includes(id)}
                            onClick={handleSelect}
                            disabled={!isAllowSelect}
                          />
                        </TableCell>
                        <TableCell>
                          <b>{client_name}</b>
                        </TableCell>
                        <TableCell>
                          <b>{job_title}</b>
                        </TableCell>
                        <TableCell>{job_code}</TableCell>
                        <TableCell align={"center"}>{job_location}</TableCell>
                        <TableCell>{formatDate(created, 'DD MMM YYYY')}</TableCell>
                        {isAllowEdit || isAllowDelete || isAllowDownload ? (
                          <TableCell>
                            <div className="flex items-center justify-center">
                              <IconButton
                                color="primary"
                                onClick={() => handleEditClick(id)}
                                disabled={!isAllowEdit}
                              >
                                <EditIcon />
                              </IconButton>
                              {/* <IconButton> */}
                              <IconButton
                                color="secondary"
                                onClick={() =>
                                  handleDeleteClick(id, job_code, job_title)
                                }
                                disabled={!isAllowDelete}
                              >
                                <DeleteIcon />
                              </IconButton>
                              <IconButton
                                disabled={!isAllowDownload || is_downloaded}
                                onClick={() =>
                                  handleDownloadClick(id, job_title, job_code)
                                }
                                color={"primary"}
                              >
                                <GetAppIcon />
                              </IconButton>
                            </div>
                          </TableCell>
                        ) : null}
                      </TableRow>
                    )
                  )
                ) : (
                  <TableRow>
                    <TableCell colSpan={5} align={"center"}>
                      {loading ? (
                        <Loader />
                      ) : (
                        <Typography>
                          <b>No results found</b>
                        </Typography>
                      )}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </StyledTableContainer>
        </Grid>
        <Grid
          item
          container
          xs={12}
          alignItems={"center"}
          justifyContent={"flex-end"}
        >
          <Typography>
            Total Count: <b>{totalCount}</b>
          </Typography>
        </Grid>
        <Grid item xs={2} container alignItems="center">
          <FormControl style={{ width: 120 }}>
            <InputLabel id="demo-simple-select-label">Rows per page</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={pagination.items}
              onChange={handlePageLimitChange}
            >
              {Options.map(({ id, value }) => (
                <MenuItem key={id} value={value}>
                  {value}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid
          item
          xs={10}
          container
          alignItems="center"
          justifyContent="flex-end"
        >
          <Pagination
            color={"primary"}
            page={pagination.activePage}
            onChange={onSelectPage}
            count={Math.ceil(totalCount / pagination.items)}
            showFirstButton
            showLastButton
          />
        </Grid>
      </Grid>
      <DeleteDialog
        dialogOpen={deleteDialogOpen}
        handleClose={handleDeleteDialogClose}
        handleYesClick={handleConfirmDelete}
        message={
          multiSelectedId.length > 1
            ? `Are you sure you want to delete the selected ${multiSelectedId.length} items?`
            : `Are you sure you want to delete?`
        }
      />
      <CustomSnack
        open={snack.open}
        color={snack.color}
        message={snack.message}
        onClose={handleSnackClose}
      />
      <DeleteDialog
        dialogOpen={!!singleDeleteId}
        handleClose={handleDeleteDialogClose}
        handleYesClick={handleSingleDelete}
        message={`Are you sure you want to delete?`}
      />
      <CloseModal
        open={addIntakeSelectionPopupOpen}
        handleClose={handleAddIntakeSelectionModalClose}
      >
        <FlexColumnCenterView gridGap={"24px"}>
          <Typography
            align={"center"}
            variant={"body1"}
            style={{ maxWidth: "650px" }}
          >
            {`To add a new Job, Kindly click `}
            <b>{`Add Intake Form`}</b>
            {` to fill up manually or `}
            <b>{`Send to Manager`}</b>
            {` to get filled up the job details.`}
          </Typography>
          <FlexCenterView
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
            gridGap={"24px"}
          >
            <Button
              color={"primary"}
              variant={"contained"}
              onClick={handleAddIntake}
            >
              {"Add Intake Form"}
            </Button>
            <LoadingButton onClick={handleSendToManagerModalOpen}>
              {"Send To Manager"}
            </LoadingButton>
          </FlexCenterView>
        </FlexColumnCenterView>
      </CloseModal>
      <SendToManagerPopup
        title={"Send To Manager"}
        open={sendToManagerPopupOpen}
        options={managerEmailList}
        toEmail={sendToManagerEmail}
        handleClose={handleSentToManagerPopupClose}
        handleSubmit={handleSentToManager}
        handleEmailChange={handleEmailChange}
        loading={sendToManagerLoading}
      />
      <ShareJobToCandidate
        open={shareJobToCandidatePopupOpen}
        candidateEmail={candidateEmail}
        handleEmailChange={handleCandidateEmailChange}
        handleClose={handleShareJobToCandidatePopupClose}
        handleSubmit={handleShareJobToCandidate}
        loading={shareJobToCandidateLoading}
      />
      {/* Filter Dialog */}
      <Dialog
        open={filterDialogOpen}
        onClose={handleFilterDialogClose}
        aria-labelledby="filter-dialog-title"
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle id="filter-dialog-title">
          Filter Options
          <IconButton aria-label="close" onClick={handleFilterDialogClose} style={{ position: 'absolute', right: '8px', top: '8px' }}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {/* Display validation message */}
          {dateValidationMessage && <Typography variant="body2" color="error">{dateValidationMessage}</Typography>}

          {/* Client Name Filter */}
          <Autocomplete
            id="clientNameFilter"
            options={client}
            getOptionLabel={(option) => option}
            value={clientNameFilter || ''}
            onChange={(event, newValue) => setClientNameFilter(newValue)}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Client Name"
                size="small"
                fullWidth
                margin="normal"
              />
            )}
          />

          {/* Date Range Filter */}
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                id="fromDateFilter"
                label="From Date"
                type="date"
                value={fromDateFilter || ''}
                onChange={(e) => setFromDateFilter(e.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
                size="small"
                fullWidth
                margin="normal"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="toDateFilter"
                label="To Date"
                type="date"
                value={toDateFilter || ''}
                onChange={(e) => setToDateFilter(e.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
                size="small"
                fullWidth
                margin="normal"
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          {/* Clear button */}
          <Button onClick={clearFilters} color="secondary">
            Clear
          </Button>
          {/* Apply Filters button */}
          <Button onClick={applyFilters} color="primary">
            Apply Filters
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}
