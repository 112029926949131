import { Container } from "@material-ui/core";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getApplicantByID } from "../../api/api";
import Phone from "../Scheduling/Phone";
import { useHistory } from "react-router-dom";
import Loader from "../../commonComponents/Loader/Loader";

function ClientForm() {
  const history = useHistory();
  const findIsClient = () => {
    const { pathname } = history.location;
    return pathname.includes("yes");
  };
  const header_config = findIsClient()
    ? {}
    : {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("tal_token")}`,
        },
      };

  const { applicantId } = useParams();
  const [jobCode, setJobCode] = useState("");
  const [jobTitle, setJobTitle] = useState("");
  const [showThankYou, setShowThankYou] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    axios.get(getApplicantByID(applicantId), header_config).then((response) => {
      if (response.status === 200) {
        console.log(response);
        if (response.data) {
          setJobCode(response.data.job_code);
          setJobTitle(response.data.job_title);
          if (
            response.data?.test_formlink_status === "submitted" &&
            findIsClient()
          ) {
            setShowThankYou(true);
          }
        }
      }
    });
  }, []);

  const handleShowThankYou = () => {
    setShowThankYou(true);
  };
  return loading ? (
    <Loader />
  ) : (
    <Container maxWidth={"md"} className={"mt-8 mb-8"}>
      <p className="text-xl font-bold mb-2">Welcome</p>
      <p className="text-xl font-bold my-2">
        Thank you for applying to Job Number: {jobCode} & Job title:{" "}
        {jobTitle}
      </p>
      <p className="text-xl font-bold mt-2 mb-8">
        Congratulations! You have been qualified for next steps interview
        process
      </p>
      <Phone
        showPhoneNumber={false}
        showThankYou={handleShowThankYou}
        isSubmitted={showThankYou}
        header_config={header_config}
        isClient={findIsClient()}
      />
      {showThankYou ? (
        <di>
          <p className="text-xl font-bold">
            Thank you for completing the above.
          </p>
          <p className="text-xl font-bold">We will communicate shortly!</p>
        </di>
      ) : (
        ""
      )}
    </Container>
  );
}

export default ClientForm;
