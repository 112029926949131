import {
  Button,
  Checkbox,
  Chip,
  Container,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { Edit, History, Info } from "@material-ui/icons";
import GetAppIcon from "@material-ui/icons/GetApp";
import { Alert, Pagination } from "@material-ui/lab";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import {
  clientEmailSetting,
  downloadRecommendReport,
  downloadResume,
  getAllRecommendation,
  getCoverSheetFormQuestionAnswer,
  rejectApplicant,
  sendToManager,
  updateInterviewStatus,
} from "../../api/api";
import Loader from "../../commonComponents/Loader/Loader";
import SimpleModal from "../../commonComponents/Modal/SimpleModal";
import CustomPopper from "../../commonComponents/Popper/CustomPopper";
import SendToManagerPopup from "../../commonComponents/SendToManagerPopup";
import CustomSnack from "../../commonComponents/Snack/CustomSnack";
import {
  StyledTableContainer,
  StyledTableHead,
} from "../../commonComponents/StyledComponents";
import { setClientInterviewState } from "../../redux/actions/clientInterview";
import {
  INTERVIEW_SCHEDULE_STATUS,
  INTERVIEW_STATUS,
  JOINING_PROBABILITY_BG_COLOR,
  MESSAGES,
  REJECT_MODULE,
} from "../../utils/constants/constants";
import coverSheetDataFormat from "../../utils/constants/coverSheetDataFormat";
import {
  FEEDBACK_RELATED_PAGES,
  getFeedbackLevelBasedAccess,
} from "../../utils/constants/feedbackLevelBasedAccess";
import {
  downloadClick,
  errorResponse,
  getOffset,
  getPageUsingRemovingAnElement,
  validateEmail,
} from "../../utils/constants/helpers";
import { ROLES } from "../../utils/constants/roleBasedAccess";
import { navigateTo } from "../../utils/constants/routePaths";
import CoverSheetPDF from "../Dashboard/Qualify/CoverSheetPDF";
import RecommendSkillMatchReport from "../Recommendations/RecommendSkillMatchReport";

export const Options = [
  {
    id: 1,
    value: "10",
  },
  {
    id: 2,
    value: "20",
  },
  {
    id: 3,
    value: "30",
  },
  {
    id: 4,
    value: "40",
  },
  {
    id: 5,
    value: "50",
  },
  {
    id: 6,
    value: "100",
  },
];

axios.defaults.xsrfHeaderName = "X-CSRFToken";
axios.defaults.xsrfCookieName = "csrftoken";

function Index({ getAndSetCount = () => {} }) {
  const { jobCode, jobTitle } = useParams();
  const history = useHistory();
  const { pathname } = history.location;
  const role = localStorage.getItem("role");
  const isManager = role === ROLES.manager;
  const feedbackLevel = sessionStorage.getItem("feedbackLevel");
  const { isRejectCandidate = () => false } = getFeedbackLevelBasedAccess(
    feedbackLevel,
    FEEDBACK_RELATED_PAGES.clientInterviewLanding
  );

  const [totalCount, setTotalCount] = useState(0);
  const [clientInterviewList, setClientInterviewList] = useState([]);
  const [resumeIds, setResumeIds] = useState([]);
  const [error, setError] = useState({
    message: "",
    success: false,
  });
  const [pdfData, setPdfData] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [coverSheetFormData, setCoverSheetFormData] = useState(null);
  const [snack, setSnack] = useState({
    open: false,
    color: "",
    message: "",
  });
  const [modalOpen, setModalOpen] = useState(false);
  const [rejectNote, setRejectNote] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [interviewStatusElement, setInterviewStatusElement] = useState({
    modelOpen: false,
    id: null,
    status: null,
  });
  const [rejectedApplicant, setRejectedApplicant] = useState({});
  const [sendToManagerEmail, setSendToManagerEmail] = useState([]);
  const [sendToManagerPopupOpen, setSendToManagerPopupOpen] = useState(false);
  const [sendToManagerLoading, setSendToManagerLoading] = useState(false);
  const [managerEmailList, setManagerEmailList] = useState([]);

  const { pagination } = useSelector((state) => state.clientInterview);

  const dispatch = useDispatch();

  const handleSnackClose = () => {
    setSnack({
      open: false,
      color: "",
      message: "",
    });
  };

  const handleSnackOpen = (message = MESSAGES.error, color = "error") => {
    setSnack({
      open: true,
      color: color,
      message: message || MESSAGES.error,
    });
  };

  const setData = (data) => {
    setClientInterviewList(data.results);
    setTotalCount(data.count);
  };

  const findIsClient = () => {
    return pathname.includes("yes");
  };

  const fetchClientInterviewList = async (limit, page) => {
    try {
      const res = await axios.get(getAllRecommendation(jobCode), {
        params: {
          limit: limit,
          offset: getOffset(page, limit),
          is_interviewed: "True",
        },
      });
      return res;
    } catch (error) {
      return errorResponse(error);
    }
  };

  const getAndSetClientInterviewList = async (limit, page) => {
    setLoading(true);
    const response = await fetchClientInterviewList(limit, page);
    if (response.status === 200) {
      setData(response.data);
    } else {
      handleSnackOpen(response.message);
    }
    setLoading(false);
  };

  const onSelectPage = (e, value) => {
    getAndSetClientInterviewList(pagination.items, value);
    dispatch(
      setClientInterviewState({
        pagination: { ...pagination, activePage: value },
      })
    );
  };

  const handlePageLimitChange = (e) => {
    const { value } = e.target;
    getAndSetClientInterviewList(parseInt(value), pagination.activePage);
    dispatch(
      setClientInterviewState({
        pagination: { ...pagination, items: parseInt(value) },
      })
    );
  };

  const handleSelectAllClick = (e) => {
    let { checked } = e.target;
    let tempSelectedIds = [...resumeIds];
    if (checked) {
      let filteredClientInterviewList = [...clientInterviewList];

      filteredClientInterviewList.map((el) => {
        if (!tempSelectedIds.includes(el.resume_id)) {
          tempSelectedIds.push(el.resume_id);
        }
      });
    } else {
      tempSelectedIds = [];
    }

    setResumeIds(tempSelectedIds);
  };

  const handleCheckboxClick = (e, id) => {
    let tempResumeIds = [...resumeIds];

    let indexOfId = tempResumeIds.findIndex((el) => el === id);

    if (indexOfId !== -1) {
      tempResumeIds.splice(indexOfId, 1);
    } else {
      tempResumeIds.push(id);
    }

    setResumeIds(tempResumeIds);
  };

  /***
   * Export single document
   */
  const handleExportClick = (applicantId) => {
    let formData = new FormData();
    formData.append("job_code", jobCode);
    formData.append("applicant_ids", `["${applicantId}"]`);

    axios
      .post(downloadRecommendReport(), formData)
      .then((response) => {
        if (response.status === 201) {
          setPdfData(response.data);
        } else {
          handleSnackOpen();
        }
      })
      .catch((err) => {
        handleSnackOpen();
      });
  };

  const handleSendToManagerClick = () => {
    axios
      .get(clientEmailSetting())
      .then((response) => {
        if (response.status === 200) {
          setSendToManagerPopupOpen(true);
          let obj = response.data[0];
          if (obj) {
            let options = [];
            for (const [key, value] of Object.entries(obj)) {
              // If check the hiring manager email
              if (key.includes("hm_email") && validateEmail(value))
                options.push(value);
            }
            setManagerEmailList(options);
          }
        } else {
          handleSnackOpen();
        }
      })
      .catch((error) => {
        handleSnackOpen();
      });
  };

  const fetchAndCheck = async () => {
    setLoading(true);
    const response = await fetchClientInterviewList(
      pagination.items,
      pagination.activePage
    );

    if (response.status === 200) {
      // If the count and result data was not satisfied, then again call the same api with first page of the data
      if (!response.data.results.length && response.data.count) {
        getAndSetClientInterviewList(pagination.items, 1);
        dispatch(
          setClientInterviewState({
            pagination: { ...pagination, activePage: 1 },
          })
        );
        return;
      } else {
        setData(response.data);
      }
    } else {
      handleSnackOpen(response.message);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchAndCheck();
  }, []);

  const positionTitle = sessionStorage.getItem("jobTitle");

  const handleExportCoverSheet = (applicant) => {
    const applicantId = applicant;
    axios
      .get(getCoverSheetFormQuestionAnswer(applicantId, jobCode))
      .then(async (response) => {
        if (response.status === 200) {
          let data = await coverSheetDataFormat(response);
          setCoverSheetFormData(data);
        }
      });
  };

  const handleResumeDownload = (applicantId) => {
    let payload = {
      applicant_id: applicantId,
    };
    axios
      .post(downloadResume(), payload)
      .then((response) => {
        window.open(response.data.url);
      })
      .catch((error) => {
        handleSnackOpen();
      });
  };

  const handleRejectClick = (applicant) => {
    setModalOpen(true);
    setRejectedApplicant(applicant);
  };

  const handleModalClose = () => {
    setModalOpen(false);
    setRejectNote(null);
    setRejectedApplicant({});
  };

  const handleReject = () => {
    let payload = {
      applicant_id: rejectedApplicant.applicant,
      job_code: rejectedApplicant.job_code,
      reject_note: rejectNote,
      reject_module: REJECT_MODULE.clientInterview,
    };
    axios
      .post(rejectApplicant(), payload)
      .then((response) => {
        setModalOpen(false);
        setRejectNote(null);
        setResumeIds([]);
        setRejectedApplicant({});
        if (response.status === 200) {
          setSnack({
            open: true,
            color: "success",
            message: "Applicant Rejected Successfully",
          });
          setTimeout(() => {
            let page = getPageUsingRemovingAnElement(
              totalCount,
              pagination.activePage,
              pagination.items
            );
            getAndSetClientInterviewList(pagination.items, page);
            dispatch(
              setClientInterviewState({
                pagination: { ...pagination, activePage: page },
              })
            );
            getAndSetCount();
          }, 2500);
        } else {
          handleSnackOpen();
        }
      })
      .catch((err) => {
        setModalOpen(false);
        setRejectNote(null);
        setRejectedApplicant({});
        handleSnackOpen();
      });
  };

  const handleRejectNoteChange = (e) => {
    const { value } = e.target;
    setRejectNote(value);
  };

  const handlePopperClose = () => {
    setAnchorEl(null);
    setInterviewStatusElement({
      ...interviewStatusElement,
      id: null,
      status: null,
    });
  };

  const handleEditClick = (e, id, status) => {
    setAnchorEl(e.currentTarget);
    setInterviewStatusElement({
      ...interviewStatusElement,
      id: id,
      status: status,
    });
  };

  const handleInterviewStatusUpdate = () => {
    let payload = {
      is_interviewed: true,
      interview_status: interviewStatusElement.status,
    };
    axios
      .put(updateInterviewStatus(jobCode, interviewStatusElement.id), payload)
      .then((response) => {
        handlePopperClose();
        if (response.status === 200) {
          setSnack({
            open: true,
            color: "success",
            message: "Status Updated Successfully",
          });
          setTimeout(() => {
            getAndSetClientInterviewList(
              pagination.items,
              pagination.activePage
            );
            getAndSetCount();
          }, 2500);
        } else {
          handleSnackOpen();
        }
      })
      .catch((err) => {
        handleSnackOpen();
      });
  };

  const handleInterviewStatusChange = (e) => {
    const { value } = e.target;
    setInterviewStatusElement({
      ...interviewStatusElement,
      status: value,
    });
  };

  const handleEmailChange = (val) => {
    let isNotValid = val.some((el) => !validateEmail(el));
    if (val.length && isNotValid) {
      setSnack({
        open: true,
        message: "Please enter a valid email",
        color: "error",
      });
    } else {
      setSendToManagerEmail(val);
    }
  };

  const handleSentToManagerPopupClose = () => {
    setSendToManagerPopupOpen(false);
    setSendToManagerEmail([]);
  };

  const handleSendToManager = () => {
    if (sendToManagerEmail.length) {
      setSendToManagerLoading(true);

      let stringData = [...resumeIds.map((el) => `"${el}"`)];
      let to_email = `[${sendToManagerEmail.map((email) => `"${email}"`)}]`;

      let formData = new FormData();
      formData.append("job_code", jobCode);
      formData.append(
        "subject",
        `Interview Candidates for Job Code ${jobCode}`
      );
      formData.append("file_name", "send_interview");
      formData.append("recommend_applicants_ids", `[${stringData}]`);
      formData.append("to_email", to_email);

      axios
        .post(sendToManager(), formData)
        .then((response) => {
          if (response.status === 201) {
            handleSnackOpen(
              "Email successfully sent to the hiring manager.",
              "success"
            );
            setResumeIds([]);
            handleSentToManagerPopupClose();
          } else {
            handleSnackOpen();
          }
          setSendToManagerLoading(false);
        })
        .catch((err) => {
          handleSnackOpen();
          setSendToManagerLoading(false);
        });
    } else {
      handleSnackOpen("Please select the To Email");
    }
  };

  const handleViewHistory = (id) => {
    history.push(`/applicant-history/${jobCode}/${id}`, {
      reference_name: "clientInterview",
    });
  };

  const handleSchedule = (applicantId, applicant_session, status) => {
    let path = navigateTo.clientInterviewScheduling(
      jobCode,
      applicantId,
      applicant_session,
      status === "submitted" ? "phone" : "chat-bot"
    );
    if (status === "submitted") {
      path += "#submitted";
    }
    history.push(path);
  };

  return (
    <Container maxWidth={"xl"} className={"mb-4 mt-7"}>
      {pdfData ? (
        <RecommendSkillMatchReport
          data={pdfData}
          setData={setPdfData}
          jobCode={jobCode}
        />
      ) : null}
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          container
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography>
            <b>{`${positionTitle ? positionTitle : jobTitle} - ${jobCode}`}</b>
          </Typography>
          <div className="flex items-center gap-3">
            {isManager ? (
              <>
                <Button
                  variant={"contained"}
                  onClick={handleSendToManagerClick}
                  color={"primary"}
                  disabled={resumeIds.length === 0}
                >
                  {"Send To Hiring Manager"}
                </Button>
                {isManager ? (
                  <Tooltip
                    title={
                      <Typography>
                        <b>{"Note: "}</b>
                        {
                          "If you wish to send the profiles to hiring manager, make sure to add the emails in the settings page."
                        }
                      </Typography>
                    }
                    classes={{ tooltip: "tooltip_custom_style" }}
                    arrow
                  >
                    <Info color={"primary"} style={{ cursor: "pointer" }} />
                  </Tooltip>
                ) : null}
              </>
            ) : null}
          </div>
        </Grid>
        {error.message ? (
          <Grid item xs={12}>
            <Alert severity={`${!error.success ? "error" : "success"}`}>
              {error.message}
            </Alert>
          </Grid>
        ) : (
          <></>
        )}
        <Grid item xs={12}>
          <StyledTableContainer component={Paper} variant={"outlined"}>
            <Table size={"small"}>
              <StyledTableHead>
                <TableRow>
                  <TableCell align="center">
                    <Checkbox
                      checked={clientInterviewList.length === resumeIds.length}
                      onChange={handleSelectAllClick}
                    />
                  </TableCell>
                  <TableCell>{"Applicant Name"}</TableCell>
                  <TableCell>{"Resume Score"}</TableCell>
                  <TableCell>{"Preferred Company Score"}</TableCell>
                  <TableCell>{"Bonus Score"}</TableCell>
                  <TableCell>{"Total Score"}</TableCell>
                  <TableCell>{"Test Score"}</TableCell>
                  <TableCell>{"Joining Probability"}</TableCell>
                  <TableCell>{"Interview Scheduling"}</TableCell>
                  <TableCell>{"Resume"}</TableCell>
                  <TableCell>{"Skill Match Report"}</TableCell>
                  <TableCell>{"Cover Sheet"}</TableCell>
                  <TableCell>{"Test Report"}</TableCell>
                  <TableCell>{"Interview Status"}</TableCell>
                  {isRejectCandidate(role) || findIsClient() ? (
                    <TableCell align={"center"}>{"Reject Applicant"}</TableCell>
                  ) : null}
                  <TableCell align={"center"}>{"View History"}</TableCell>
                </TableRow>
              </StyledTableHead>
              <TableBody>
                {!loading && clientInterviewList.length > 0 ? (
                  clientInterviewList?.map(
                    (
                      {
                        full_name,
                        resume_score,
                        test_score,
                        overall_score,
                        applicant,
                        preferred_company_weightage,
                        test_report_url,
                        id,
                        resume_id,
                        bonus_score,
                        probability_of_joining,
                        interview_status,
                        job_code,
                        applicant_session,
                        interview_schedule_status,
                      },
                      index
                    ) => {
                      const interviewStatus =
                        interview_status ?? INTERVIEW_STATUS.interviewPending;
                      return (
                        <TableRow key={`client-interview-row-${index}`}>
                          <TableCell align="center" padding="none">
                            <Checkbox
                              checked={resumeIds.includes(resume_id)}
                              onClick={(e) => handleCheckboxClick(e, resume_id)}
                            />
                          </TableCell>
                          <TableCell>{full_name}</TableCell>
                          <TableCell>{resume_score}</TableCell>
                          <TableCell>{preferred_company_weightage}</TableCell>
                          <TableCell>{bonus_score}</TableCell>
                          <TableCell>{overall_score}</TableCell>
                          <TableCell>{test_score}</TableCell>
                          <TableCell>
                            <Chip
                              size={"small"}
                              style={{
                                background:
                                  JOINING_PROBABILITY_BG_COLOR[
                                    probability_of_joining || "Not Applicable"
                                  ],
                                color: "#ffffff",
                              }}
                              label={<b>{probability_of_joining}</b>}
                            />
                          </TableCell>
                          <TableCell
                            className={"cursor-pointer"}
                            onClick={() =>
                              handleSchedule(
                                applicant,
                                applicant_session,
                                interview_schedule_status
                              )
                            }
                          >
                            <b className={"link-text"}>
                              {INTERVIEW_SCHEDULE_STATUS[
                                interview_schedule_status
                              ] ?? interview_schedule_status}
                            </b>
                          </TableCell>
                          <TableCell>
                            <IconButton
                              color={"primary"}
                              onClick={() => {
                                handleResumeDownload(applicant);
                              }}
                            >
                              <GetAppIcon />
                            </IconButton>
                          </TableCell>

                          <TableCell>
                            <IconButton
                              color={"primary"}
                              onClick={() => handleExportClick(resume_id)}
                            >
                              <GetAppIcon />
                            </IconButton>
                          </TableCell>
                          <TableCell>
                            <IconButton
                              color={"primary"}
                              onClick={() => handleExportCoverSheet(applicant)}
                            >
                              <GetAppIcon />
                            </IconButton>
                          </TableCell>
                          <TableCell>
                            <IconButton
                              color={"primary"}
                              onClick={() => {
                                downloadClick(test_report_url);
                              }}
                            >
                              <GetAppIcon />
                            </IconButton>
                          </TableCell>
                          <TableCell>
                            <Grid
                              container
                              spacing={1}
                              alignItems={"center"}
                              justifyContent={"space-between"}
                              wrap={"nowrap"}
                              style={{ whiteSpace: "nowrap" }}
                            >
                              <Grid item>
                                <b>{interviewStatus}</b>
                              </Grid>
                              <Grid item>
                                <Tooltip
                                  title={
                                    <Typography style={{ fontSize: "14px" }}>
                                      {"Update Interview Status"}
                                    </Typography>
                                  }
                                >
                                  <IconButton
                                    onClick={(e) =>
                                      handleEditClick(e, id, interviewStatus)
                                    }
                                    color={"primary"}
                                    size={"small"}
                                  >
                                    <Edit style={{ fontSize: "1.3rem" }} />
                                  </IconButton>
                                </Tooltip>
                              </Grid>
                            </Grid>
                          </TableCell>
                          {isRejectCandidate(role) || findIsClient() ? (
                            <TableCell align={"center"}>
                              <Button
                                variant={"contained"}
                                color={"secondary"}
                                size={"small"}
                                onClick={() =>
                                  handleRejectClick({ applicant, job_code })
                                }
                              >
                                {"Reject"}
                              </Button>
                            </TableCell>
                          ) : null}
                          <TableCell align={"center"}>
                            <IconButton
                              color={"primary"}
                              onClick={() => handleViewHistory(applicant)}
                            >
                              <History />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      );
                    }
                  )
                ) : (
                  <TableRow>
                    <TableCell align={"center"} colSpan={16}>
                      {loading ? (
                        <Loader />
                      ) : (
                        <Typography>
                          <b>No Result Found!</b>
                        </Typography>
                      )}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </StyledTableContainer>
        </Grid>
        <Grid
          item
          xs={12}
          container
          justifyContent="flex-end"
          alignItems="center"
        >
          <Typography>
            Total Count: <b>{totalCount}</b>
          </Typography>
        </Grid>
        <Grid item xs={2} container alignItems="center">
          <FormControl style={{ width: 120 }}>
            <InputLabel id="demo-simple-select-label">Rows per page</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={pagination.items}
              onChange={handlePageLimitChange}
            >
              {Options.map(({ id, value }) => (
                <MenuItem key={id} value={value}>
                  {value}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid
          item
          xs={10}
          container
          alignItems="center"
          justifyContent="flex-end"
        >
          <Pagination
            color={"primary"}
            page={pagination.activePage}
            onChange={onSelectPage}
            count={Math.ceil(totalCount / pagination.items)}
            showFirstButton
            showLastButton
          />
        </Grid>
      </Grid>
      {coverSheetFormData ? (
        <CoverSheetPDF
          data={coverSheetFormData}
          setData={setCoverSheetFormData}
          jobCode={jobCode}
        />
      ) : null}
      <CustomSnack
        open={snack.open}
        color={snack.color}
        message={snack.message}
        onClose={handleSnackClose}
      />
      <SimpleModal
        open={modalOpen}
        title={"Reject Applicant"}
        saveButtonText={"Submit"}
        handleClose={handleModalClose}
        handleSave={handleReject}
        maxWidth={"md"}
      >
        <TextField
          name={"rejectNote"}
          label={"Reject Note"}
          value={rejectNote || ""}
          onChange={handleRejectNoteChange}
          variant={"outlined"}
          multiline
          fullWidth
        />
      </SimpleModal>

      <CustomPopper
        anchorEl={anchorEl}
        placement={"bottom-end"}
        onClose={handlePopperClose}
        marginTop={"10px"}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              name={"status"}
              label={"Interview Status"}
              value={interviewStatusElement.status || ""}
              onChange={handleInterviewStatusChange}
              variant={"outlined"}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <div className={"flex justify-end items-center gap-2"}>
              <Button
                color={"inherit"}
                variant={"contained"}
                size={"small"}
                onClick={handlePopperClose}
              >
                {"Cancel"}
              </Button>
              <Button
                color={"primary"}
                variant={"contained"}
                size={"small"}
                onClick={handleInterviewStatusUpdate}
              >
                {"Save"}
              </Button>
            </div>
          </Grid>
        </Grid>
      </CustomPopper>
      <SendToManagerPopup
        title={"Send To Hiring Manager"}
        open={sendToManagerPopupOpen}
        options={managerEmailList}
        toEmail={sendToManagerEmail}
        handleClose={handleSentToManagerPopupClose}
        handleSubmit={handleSendToManager}
        handleEmailChange={handleEmailChange}
        loading={sendToManagerLoading}
      />
    </Container>
  );
}

export default Index;
