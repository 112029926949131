import { RECOMMEND } from "../actionTypes";

export const setRecommendState = (values) => ({
  type: RECOMMEND.setState,
  payload: values,
});

export const clearRecommendState = () => ({
  type: RECOMMEND.clearState,
});
