import { FUNNEL } from "../actionTypes";

const initialState = {
  pagination: {
    activePage: 1,
    items: 10,
  },
};

const funnel = (state = initialState, { type, payload }) => {
  switch (type) {
    case FUNNEL.setState: {
      return { ...state, ...payload };
    }
    case FUNNEL.clearState: {
      return initialState;
    }
    default:
      return state;
  }
};

export default funnel;
