import {
  Box,
  Breadcrumbs,
  Button,
  Container,
  Grid,
  Typography,
} from "@material-ui/core";
import { NavigateNext } from "@material-ui/icons";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Route, useHistory, useParams } from "react-router-dom";
import { getModuleCount } from "../../api/api";
import ModuleCard from "../../commonComponents/ModuleCard/ModuleCard";
import {
  FEEDBACK_LEVELS,
  FEEDBACK_LEVEL_TEXT,
  FEEDBACK_RELATED_PAGES,
  getFeedbackLevelBasedAccess,
} from "../../utils/constants/feedbackLevelBasedAccess";
import { ROLES } from "../../utils/constants/roleBasedAccess";
import Applicants from "../Applicants/Applicants";
import Recommendations from "../Recommendations/Recommendations";
import ClientInterview from "../ClientInterview/Index";
import Report from "../Report/Report";
import TestModule from "../TestModule/TestModule";
import Qualify from "./Qualify";
import Test from "./Test";
import "./style.css";
import { Alert } from "@material-ui/lab";
import Scheduling from "../Recommendations/Scheduling";

const modules = [
  {
    title: "Funnel",
    path: (jobCode) => `/dashboard/${jobCode}/applicants`,
    id: "applicants",
    count: "funnel",
  },
  {
    title: "Qualify",
    path: (jobCode) => `/dashboard/${jobCode}/qualify`,
    id: "qualify",
    count: "qualify",
  },
  {
    title: "Test",
    path: (jobCode) => `/dashboard/${jobCode}/testModule`,
    id: "testModule",
    count: "test",
  },
  {
    title: "Recommend",
    path: (jobCode) => `/dashboard/${jobCode}/recommended`,
    id: "recommended",
    count: "recommend",
  },
  {
    title: "Client Interview",
    path: (jobCode) => `/dashboard/${jobCode}/clientInterview`,
    id: "clientInterview",
    count: "client_interview",
  },
  // {
  //   title: "Summary",
  //   path: (jobCode) => `/dashboard/${jobCode}/testModule`,
  //   id: "summary",
  // },
];

function Dashboard() {
  const history = useHistory();
  const params = useParams();
  const [currentPage, setCurrentPage] = useState("applicants");
  const role = localStorage.getItem("role");
  const isManager = ROLES.manager === role;
  const isManagerUrl = history.location.pathname.includes(ROLES.manager);
  const isManagerAccessLink = isManager && isManagerUrl;

  if (isManagerAccessLink) {
    if (history.location.pathname.includes("/manager/qualify")) {
      sessionStorage.setItem("feedbackLevel", FEEDBACK_LEVELS.qualified);
    } else if (history.location.pathname.includes("/manager/recommended")) {
      sessionStorage.setItem("feedbackLevel", FEEDBACK_LEVELS.recommend);
    }
  }

  const findIsClient = () => {
    const { pathname } = history.location;
    return pathname.includes("yes");
  };
  const [count, setCount] = useState({
    funnel: 0,
    qualify: 0,
    test: 0,
  });
  const feedbackLevel = sessionStorage.getItem("feedbackLevel");
  const { isAllowModule = () => false } = getFeedbackLevelBasedAccess(
    feedbackLevel,
    FEEDBACK_RELATED_PAGES.mainLayout
  );

  const handleModuleClick = (path, id) => {
    setCurrentPage(id);
    history.push(path);
  };

  const hasFeedbackStatus = (level) => {
    return level === feedbackLevel ? "True" : null;
  };

  const getAndSetCount = () => {
    if (!findIsClient()) {
      axios
        .get(getModuleCount(), {
          params: {
            jobcode: params.jobCode,
            is_applied: hasFeedbackStatus(FEEDBACK_LEVELS.funnel),
            is_qualified: hasFeedbackStatus(FEEDBACK_LEVELS.qualified),
            is_rejected: hasFeedbackStatus(FEEDBACK_LEVELS.rejected),
            is_selected:
              hasFeedbackStatus(FEEDBACK_LEVELS.movedToTest) ||
              hasFeedbackStatus(FEEDBACK_LEVELS.testPending),
            is_testcompleted:
              FEEDBACK_LEVELS.testPending === feedbackLevel
                ? "False"
                : hasFeedbackStatus(FEEDBACK_LEVELS.testCompleted),
            is_moved: hasFeedbackStatus(FEEDBACK_LEVELS.recommend),
            is_interviewed: hasFeedbackStatus(FEEDBACK_LEVELS.clientInterview),
            role: isManager || findIsClient() ? ROLES.manager : null,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            setCount({
              ...response.data,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  useEffect(() => {
    const { pathname } = history.location;
    let selectedCard = "applicants";
    modules.map(({ id }, ind) => {
      if (pathname.includes(id)) {
        selectedCard = id;
      }
    });
    setCurrentPage(selectedCard);
    getAndSetCount();
  }, []);

  const handleBreadCrumbClick = () => {
    history.push("/home/feedbackDashboard");
  };

  const accessibleModule = modules.filter(
    (el) =>
      !(
        (el.id === "testModule" &&
          count.technical_test?.toLocaleLowerCase() === "no") ||
        (el.id === "applicants" && ROLES.manager === role) ||
        !isAllowModule(el.id)
      )
  );

  return isManagerAccessLink || !isManagerUrl ? (
    <Container className="mt-8" style={{ maxWidth: "1600px" }}>
      <Grid container spacing={2} className={"upperContainer"}>
        {feedbackLevel ? (
          <Grid item xs={12}>
            <Breadcrumbs
              separator={<NavigateNext fontSize={"small"} />}
              aria-label={"breadcrumb"}
            >
              <Button size={"small"} onClick={handleBreadCrumbClick}>
                <Typography
                  color={"primary"}
                  style={{ textTransform: "capitalize" }}
                >
                  {"Job Posting"}
                </Typography>
              </Button>
              <Typography color={"textPrimary"}>
                {FEEDBACK_LEVEL_TEXT[feedbackLevel]}
              </Typography>
            </Breadcrumbs>
          </Grid>
        ) : null}
        {accessibleModule.length
          ? accessibleModule.map((el, ind) => (
              <Grid item xs={3} key={`module-card-${ind}`}>
                <ModuleCard
                  count={count[el.count]}
                  selectedCard={el.id === currentPage}
                  handleModuleClick={() =>
                    handleModuleClick(el.path(params.jobCode), el.id)
                  }
                  moduleNumber={ind + 1}
                  moduleTitle={el.title}
                  isModuleCount={accessibleModule.length !== 1}
                />
              </Grid>
            ))
          : null}
      </Grid>
      <Route
        path={"/dashboard/:jobCode/test/:applicantId/:applicantsession"}
        render={(props) => <Test {...props} getAndSetCount={getAndSetCount} />}
      />
      <Route
        exact
        path={"/dashboard/:jobCode/applicants"}
        render={(props) => (
          <Applicants {...props} getAndSetCount={getAndSetCount} />
        )}
      />
      <Route
        exact
        path={"/dashboard/:jobCode/applicants/:applicantId"}
        render={(props) => (
          <Report {...props} getAndSetCount={getAndSetCount} />
        )}
      />
      <Route
        exact
        path={"/dashboard/:jobCode/qualify"}
        render={(props) => (
          <Qualify {...props} getAndSetCount={getAndSetCount} />
        )}
      />
      <Route
        exact
        path={"/dashboard/:jobCode/manager/qualify"}
        render={(props) => (
          <Qualify {...props} getAndSetCount={getAndSetCount} />
        )}
      />
      <Route
        exact
        path={"/dashboard/:jobCode/testModule"}
        render={(props) => (
          <TestModule {...props} getAndSetCount={getAndSetCount} />
        )}
      />
      <Route
        exact
        path={"/dashboard/:jobCode/recommended"}
        render={(props) => (
          <Recommendations {...props} getAndSetCount={getAndSetCount} />
        )}
      />
      <Route
        exact
        path={"/dashboard/:jobCode/manager/recommended"}
        render={(props) => (
          <Recommendations {...props} getAndSetCount={getAndSetCount} />
        )}
      />
      <Route
        exact
        path={
          "/dashboard/:jobCode/recommended/:applicantId/:applicant_session/scheduling/:type?"
        }
        render={(props) => (
          <Scheduling {...props} getAndSetCount={getAndSetCount} />
        )}
      />
      <Route
        exact
        path={"/dashboard/:jobCode/clientInterview"}
        render={(props) => (
          <ClientInterview {...props} getAndSetCount={getAndSetCount} />
        )}
      />
      <Route
        exact
        path={
          "/dashboard/:jobCode/clientInterview/:applicantId/:applicant_session/scheduling/:type?"
        }
        render={(props) => (
          <Scheduling {...props} getAndSetCount={getAndSetCount} />
        )}
      />
    </Container>
  ) : (
    <Container maxWidth={"md"} className={"mt-8 mb-8"}>
      <Box pt={2}>
        <Alert severity={"error"}>
          <p className="text-base font-bold">
            {`The link will not be accessible other than the manager Login`}
          </p>
        </Alert>
      </Box>
    </Container>
  );
}

export default Dashboard;
