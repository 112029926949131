import React, { forwardRef,useState } from "react";

// Import API
import axios from "axios";
//import { createNewAccount_API } from "../../api/api";
import { recurringPayment_API } from "../../api/api";

const CheckoutForm = (_, ref) => {
  // Ref
  const { createPaymentModal } = ref;

  const [card_name, setcardName] = useState("");
  const [email, setemail] = useState("");
  const [card_number, setcard_number] = useState("");
  const [exp_date, setexp_date] = useState("");
  const [cvc, setcvc] = useState("");


  const  validateEmail = (email) => {
    //eslint-disable-next-line
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }




  const  onKeyPressed = (event) => {
    event.target.value = event.target.value.replace(/[^\dA-Z]/g, '').replace(/(.{4})/g, '$1 ').trim();
   var k = event.which;

    if ((k >= 96 && k <= 105) || k === 8 || (k <= 60 && k >= 48) || k === 9) {
        if ((event.target.value).length === 19) {
            if (k === 8 || k === 9) {
                return true;
            } else {
                event.preventDefault();
                return false;
            }
        }
    } else {
        event.preventDefault();
        return false;
    }
};

const onKeyPressedExp = (event) =>{
  //eslint-disable-next-line
  event.target.value = event.target.value.replace(/^(\d\d)(\d)$/g,'$1/$2').replace(/^(\d\d\/\d\d)(\d+)$/g,'$1/$2').replace(/[^\d\/]/g,'');
  var k = event.which;
  if ((k >= 96 && k <= 105) || k === 8 || (k <= 60 && k >= 48) || k === 9) {
      if ((event.target.value).length === 5) {
          if (k === 8 || k === 9) {
              return true;
          } else {
              event.preventDefault();
              return false;
          }
      }
  } else {
      event.preventDefault();
      return false;
  }
}

const onKeyPressedCVC = (event) =>{
  var k = event.which;
  if ((k >= 96 && k <= 105) || k === 8 || (k <= 60 && k >= 48) || k === 9) {
      if ((event.target.value).length === 4) {
          if (k === 8 || k === 9) {
              return true;
          } else {
              event.preventDefault();
              return false;
          }
      }
  } else {
      event.preventDefault();
      return false;
  }
}

const handleSubmitData = (event) => {

  event.preventDefault();
  console.log(cvc);
  console.log(cvc.length);
  if(card_number.length !== 19){
    document.querySelector('.cnumerror').style.display = "block";
  }else if(exp_date.length !== 5){
    document.querySelector('.cderror').style.display = "block";
  }else if(cvc.length === 4 || cvc.length === 3){
   var datanew = {
    card_name:card_name,
    email: email,
    card_number: card_number,
    exp_date:exp_date,
    cvc:cvc,
    amount:10
  }

  console.log(datanew);

  axios
      .post(recurringPayment_API, datanew,{
        headers: {
          Authorization: "Bearer " + localStorage.getItem("tal_token"),
        },
      }
      )
      .then((response) => {
        console.log(response);
      })
      .then(() => {
        // if success then take back to login
        //createAccountModal.current.close();
       // signInModal.current.open();
      })
      .catch(() => {
       // console.log(data);
       // // remove below
        //createAccountModal.current.close();
       // signInModal.current.open();
      });
  }
else{
  document.querySelector('.cvcerror').style.display = "block";
}

}


 const validateInput = (event) =>{
    if(event.target.name === "card_name"){
      if(event.target.value === ""){
        document.querySelector('.cnerror').style.display="block";
      }else{
        setcardName(event.target.value);
        document.querySelector('.cnerror').style.display="none";
      }
    }else if(event.target.name === "email"){
      if(event.target.value === ""){
        document.querySelector('.eerror').style.display="block";
      }else{
        if (validateEmail(event.target.value)) {
          setemail(event.target.value);
          document.querySelector('.eerror').style.display = "none";
       } else {
        setemail(event.target.value);
          document.querySelector('.eerror').style.display = "block";
      }
      }
    }else if(event.target.name === "card_number"){
      if(event.target.value === ""){
        document.querySelector('.cnumerror').style.display="block";
      }else{
        setcard_number(event.target.value);
        if ((event.target.value).length === 19) {
          document.querySelector('.cnumerror').style.display = "none";
      } else {
        setcard_number(event.target.value);
          document.querySelector('.cnumerror').style.display = "block";
      }
      }
    }else if(event.target.name === "exp_date"){
      if(event.target.value === ""){
        document.querySelector('.cderror').style.display="block";
      }else{
        if ((event.target.value).length === 5) {
          //var newStr = event.target.value.slice(0,2) + '/' + event.target.value.slice(2)
         // document.querySelector('#exp').value = newStr;
          setexp_date(event.target.value);
          document.querySelector('.cderror').style.display = "none";
      } else {
        setexp_date(event.target.value);
          document.querySelector('.cderror').style.display = "block";
      }
      }
    }else if(event.target.name === "cvc"){
      if(event.target.value === ""){
        document.querySelector('.cvcerror').style.display="block";
      }else{
        setcvc(event.target.value);
        if ((event.target.value).length === 4 || (event.target.value).length === 3) {

          document.querySelector('.cvcerror').style.display = "none";
      } else {
        setcvc(event.target.value);
          document.querySelector('.cvcerror').style.display = "block";
      }
      }
    }

  }

  return (
    <div className="container mx-auto p-2 h-full">
      <div className="flex content-center items-center justify-center h-full">
        <div className="w-full lg:w-4/12 px-4">
          <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-primary-color border-0">
            <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
              <div className="text-white text-center mt-5 font-bold">
                <large>Premium Subscription</large>
              </div>
              <div className="text-white text-center mb-5 font-bold">
                <small>Recurring Payment (USD) per month</small>
              </div>
              <form onSubmit={handleSubmitData}>
              <div className="relative w-full mb-3">
                  <input
                    type="text"
                    className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full"
                    placeholder="Name on Card"
                    name="card_name"
                    onBlur={validateInput}
                    style={{ transition: "all .15s ease" }}
                    required
                  />
                  <p className="cnerror">Card name is a required field</p>
                </div>

                <div className="relative w-full mb-3">
                  <input
                    type="email"
                    className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full"
                    placeholder="Email"
                    name="email"
                    onBlur={validateInput}
                    style={{ transition: "all .15s ease" }}
                    required
                  />
                  <p className="eerror">Enter valid email</p>
                </div>


                <div className="relative w-full mb-3">
                  <input
                    type="text"
                    className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full"
                    placeholder="Card Number"
                    name="card_number"
                    style={{ transition: "all .15s ease" }}
                    onBlur={validateInput}
                    onKeyDown={onKeyPressed}
                    id="cardNumber"
                    required
                  />
                  <p className="cnumerror">Card number min 16 digits and max 16 digits</p>
                </div>

                <div className="relative w-full mb-3">
                  <input
                    type="text"
                    className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full"
                    placeholder="MM / YY"
                    name="exp_date"
                    id="exp"
                    style={{ transition: "all .15s ease" }}
                    onBlur={validateInput}
                    onKeyDown={onKeyPressedExp}
                    required
                  />
                 <p className="cderror"> Date field is required</p>
                </div>
                <div className="relative w-full mb-3">
                  <input
                    type="number"
                    className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full"
                    placeholder="CVC"
                    name="cvc"
                    style={{ transition: "all .15s ease" }}
                    required
                    onBlur={validateInput}
                    onKeyDown={onKeyPressedCVC}
                  />
                  <p className="cvcerror">CVC is a required field</p>

                </div>

                <div className="text-center mt-6">
                  <button
                    className="bg-secondary-color text-white active:bg-gray-700 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full"
                    type="submit"
                    style={{ transition: "all .15s ease" }}
                  >
                    Submit
                  </button>
                </div>
              </form>

              <div className="flex flex-wrap mt-4">
                <div className="w-1/2">
                  <a
                    href="/#"
                    onClick={(e) => {
                      e.preventDefault();
                      createPaymentModal.current.close();
                     // signInModal.current.open();
                    }}
                    className="text-white"
                  >
                    <small> &#8592; Back </small>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default forwardRef(CheckoutForm);
