import { AppBar, Box, Grid, Tab, Tabs, Typography } from "@material-ui/core";
import React, { useState } from "react";
import PropTypes from "prop-types";
import ChatbotAndFormLink from "./ChatbotAndFormLink";
import Phone from "./Phone";
import { useHistory, useParams } from "react-router-dom";
import * as yup from "yup";
import { useFormik } from "formik";
import {
  ROLE_BASED_PAGES,
  getRoleBasedAccess,
} from "../../utils/constants/roleBasedAccess";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box mt={4}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function Scheduling({ sessionData, getAndSetApplicantSessionData }) {
  const [value, setValue] = useState(0);

  const { jobCode, applicantId } = useParams();

  const { push } = useHistory();

  const role = localStorage.getItem("role");
  const { isAccessibleActionButton = true } = getRoleBasedAccess(
    role,
    ROLE_BASED_PAGES.testScheduling
  );

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleFormLinkSubmit = () => {
    push(`/client/yes/form/jobCode/${jobCode}/applicantId/${applicantId}`);
  };

  console.log(sessionData, "____________");

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <AppBar position="static" color="inherit">
          <Tabs
            indicatorColor="primary"
            value={value}
            onChange={handleTabChange}
            aria-label="simple tabs example"
          >
            <Tab label="Chat-Bot" {...a11yProps(0)} />
            <Tab label="Form Link" {...a11yProps(1)} />
            <Tab label="Phone" {...a11yProps(2)} />
          </Tabs>
        </AppBar>
      </Grid>
      <Grid item xs={12}>
        <TabPanel value={value} index={0}>
          <ChatbotAndFormLink
            getAndSetApplicantSessionData={getAndSetApplicantSessionData}
            sessionData={sessionData}
            buttonLabel={"Send Bot"}
            isAccessibleActionButton={isAccessibleActionButton}
            isSendBot
          />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <ChatbotAndFormLink
            getAndSetApplicantSessionData={getAndSetApplicantSessionData}
            sessionData={sessionData}
            buttonLabel={"Send Form Link"}
            isAccessibleActionButton={isAccessibleActionButton}
          />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <Phone
            showPhoneNumber={true}
            isAccessibleActionButton={isAccessibleActionButton}
          />
        </TabPanel>
      </Grid>
    </Grid>
  );
}

export default Scheduling;
