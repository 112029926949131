import React, { useEffect, useState } from "react";
import {
  Button,
  Container,
  FormControl,
  FormHelperText,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import Icon from "@material-ui/core/Icon";
import DatePicker from "react-datepicker";
import "./style.css";
import axios from "axios";
import { getAllCoversation, sendUserMessage } from "../../api/api";
import { useParams } from "react-router-dom";
import { Form, FormikProvider, useFormik } from "formik";
import * as yup from "yup";
import YesOrNoSelect from "../../commonComponents/YesOrNoSelect/YesOrNoSelect";
import moment, { max } from "moment";

function ChatForm() {
  const { jobCode, applicantId } = useParams();
  const [conversation, setConversation] = useState([]);
  const [status, setStatus] = useState("");
  const [quesName, setQuesName] = useState("");
  const [loading, setLoading] = useState(false);
  const chatBox = document.getElementById("chatBox");
  const [quesOrder, setQuesOrder] = useState(0);
  const [previousQuestionAnswer, setPreviousQuestionAnswer] = useState("");

  const requiredMessage = "Please fill the required field";
  const numberOnlyMessage = "Please enter valid number";
  const futureDateMessage = "Please enter valid date";

  const getValidationCondition = () => {
    if (
      quesName === "currentLocation" ||
      quesName === "reLocation" ||
      quesName === "currentNoticePeriod" ||
      quesName === "workOption" ||
      quesName === "variablePay" ||
      quesName === "backgroundCheck" ||
      quesName === "currentCtc" ||
      quesName === "annualSalary"
    ) {
      return yup.string().required(requiredMessage);
    } else if (quesName === "timeSlotOne" || quesName === "timeSlotTwo") {
      return yup
        .date()
        .min(new Date(), futureDateMessage)
        .required(requiredMessage);
    } else {
      return yup.string().required(requiredMessage);
    }
  };

  const validationSchema = yup.object({
    message: getValidationCondition(),
  });

  const formik = useFormik({
    initialValues: {
      message: "",
    },
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    setLoading(true);
    axios
      .get(getAllCoversation(jobCode, applicantId))
      .then((response) => {
        if (response.status === 200) {
          if (response.data) {
            setLoading(false);
            setStatus(response.data.status);
            setConversation(response.data.conversations);
            setQuesName(response.data.last_question_title);
            setQuesOrder(response.data.last_question_order);
            setPreviousQuestionAnswer(response.data.previous_question_answer);
            if (response.data.last_question_title === "skipCheck") {
              setLoading(true);
              handleSubmit({
                message: "",
                title: response.data.last_question_title,
                order: response.data.last_question_order,
              });
            }
          }
        }
      })
      .catch((err) => {
        console.log(err, "==============");
      });
  }, []);

  function handleSubmit(values) {
    // Set user message as soon as it's entered
    if (quesName === "timeSlotOne" || quesName === "timeSlotTwo") {
      if (values.message) {
        values.message = new Date(values.message).toISOString();
        setPreviousQuestionAnswer(values.message);
      }
    }
    if (values.message) {
      setConversation((conver) => [...conver, { user: values.message }]);
    }

    // Scroll into view for the user entered message as well.
    if (chatBox !== null) {
      chatBox.scrollTop = chatBox.scrollHeight;
    }

    var rand = Math.round(Math.random() * (3000 - 500)) + 500;
    setLoading(true);
    setTimeout(() => {
      axios
        .post(sendUserMessage(jobCode, applicantId), {
          user_input: values.message,
          last_question_title: values.title ? values.title : quesName,
          last_question_order: values.order ? values.order : quesOrder,
        })
        .then((response) => {
          if (response.status === 201) {
            setLoading(false);
            setStatus(response.data.status);
            if (response.data.bot) {
              let thisConversation = {};
              thisConversation["bot"] = response.data.bot;
              setConversation((conver) => [...conver, thisConversation]);

              resetForm({
                message: "",
              });
              if (response.data.title) {
                setQuesName(response.data.title);
                setQuesOrder(response.data.order);
                if (response.data.title === "skipCheck") {
                  if (chatBox !== null) {
                    chatBox.scrollTop = chatBox.scrollHeight;
                  }
                  setLoading(true);
                  handleSubmit({
                    message: "",
                    title: response.data.title,
                    order: response.data.order,
                  });
                }
              }
            }

            if (chatBox !== null) {
              chatBox.scrollTop = chatBox.scrollHeight;
            }
          }
        })
        .catch((err) => {
          console.log(err, "+++++++++++++++++");
        });
    }, rand);
  }

  const {
    values,
    handleChange,
    touched,
    errors,
    setFieldValue,
    handleBlur,
    setValues,
    setTouched,
    setErrors,
    resetForm,
    setFieldTouched,
  } = formik;

  const renderComponent = () => {
    if (quesName === "currentLocation") {
      return (
        <TextField
          size={"small"}
          name={"message"}
          variant={"outlined"}
          value={values.message}
          error={touched.message && Boolean(errors.message)}
          helperText={touched.message && errors.message}
          onChange={handleChange}
          onBlur={handleBlur}
          fullWidth
          autoComplete="off"
          disabled={status === "submitted" || loading}
        />
      );
    } else if (
      quesName === "reLocation" ||
      // quesName === "underNoticePeriod" ||
      // quesName === "hybridWorkingModel" ||
      // quesName === "internationalTimeZone" ||
      quesName === "backgroundCheck"
    ) {
      return (
        <YesOrNoSelect
          size={"small"}
          fullWidth
          variant={"outlined"}
          error={touched.message && Boolean(errors.message)}
          helperText={touched.message && errors.message}
          name={"message"}
          value={values.message}
          onChange={handleChange}
          onBlur={handleBlur}
          disabled={status === "submitted" || loading}
        />
      );
    } else if (quesName === "currentCtc" || quesName === "annualSalary") {
      return (
        <TextField
          size={"small"}
          variant={"outlined"}
          error={touched.message && Boolean(errors.message)}
          helperText={touched.message && errors.message}
          name={"message"}
          value={values.message}
          onChange={handleChange}
          onBlur={handleBlur}
          // type={"number"}
          fullWidth
          disabled={status === "submitted" || loading}
        />
      );
    } else if (quesName === "timeSlotOne") {
      let minDate = new Date();
      return (
        <div className="dateTimeContainer flex flex-col">
          <DatePicker
            className={`outline-none border-b-2 ${
              touched.timeSlotOne && Boolean(errors.timeSlotOne)
                ? "border-red-500"
                : "border-gray-300"
            } `}
            selected={values.timeSlotOne}
            onChange={(date) => {
              setFieldTouched("timeSlotOne", true);
              setFieldValue("message", date);
              setFieldValue("timeSlotOne", date);
            }}
            minDate={minDate}
            values={values.message}
            showTimeSelect
            placeholder="Please click to select the date"
            autoComplete="off"
            name={"message"}
            customInput={
              <TextField
                aria-readonly
                fullWidth
                size={"small"}
                variant={"outlined"}
                label=""
                value={"timeSlotOne"}
                type="text"
                autoComplete="off"
                InputLabelProps={{
                  shrink: true,
                }}
                error={touched.message && Boolean(errors.message)}
                helperText={touched.message && errors.message}
                placeholder="Please click to select the date"
                disabled={status === "submitted" || loading}
              />
            }
            dateFormat="MMMM d, yyyy h:mm aa"
          />
          <FormHelperText className="errorText">
            {touched.timeSlotOne && errors.timeSlotOne}
          </FormHelperText>
        </div>
      );
    } else if (quesName === "timeSlotTwo") {
      let minDate = new Date(
        new Date(previousQuestionAnswer).setDate(
          new Date(previousQuestionAnswer).getDate() + 1
        )
      );
      return (
        <div className="dateTimeContainer flex flex-col">
          <DatePicker
            className={`outline-none border-b-2 ${
              touched.timeSlotOne && Boolean(errors.timeSlotOne)
                ? "border-red-500"
                : "border-gray-300"
            } `}
            selected={values.timeSlotOne}
            onChange={(date) => {
              setFieldTouched("timeSlotOne", true);
              setFieldValue("message", date);
              setFieldValue("timeSlotOne", date);
            }}
            minDate={minDate}
            values={values.message}
            showTimeSelect
            placeholder="Please click to select the date"
            autoComplete="off"
            name={"message"}
            customInput={
              <TextField
                aria-readonly
                fullWidth
                size={"small"}
                variant={"outlined"}
                label=""
                value={"timeSlotOne"}
                type="text"
                autoComplete="off"
                InputLabelProps={{
                  shrink: true,
                }}
                error={touched.message && Boolean(errors.message)}
                helperText={touched.message && errors.message}
                placeholder="Please click to select the date"
                disabled={status === "submitted" || loading}
              />
            }
            dateFormat="MMMM d, yyyy h:mm aa"
          />
          <FormHelperText className="errorText">
            {touched.timeSlotOne && errors.timeSlotOne}
          </FormHelperText>
        </div>
      );
    } else if (quesName === "workOption") {
      return (
        <FormControl
          size={"small"}
          fullWidth
          disabled={status === "submitted" || loading}
          variant={"standard"}
          error={touched.message && Boolean(errors.message)}
        >
          <Select
            disabled={status === "submitted" || loading}
            value={values.message}
            onChange={handleChange}
            onBlur={handleBlur}
            name={"message"}
          >
            <MenuItem value={"Onsite"}>Onsite</MenuItem>
            <MenuItem value={"Hybrid"}>Hybrid</MenuItem>
            <MenuItem value={"Remote"}>Remote</MenuItem>
          </Select>
          <FormHelperText>{touched.message && errors.message}</FormHelperText>
        </FormControl>
      );
    } else {
      return (
        <TextField
          disabled={status === "submitted" || loading}
          size={"small"}
          variant={"outlined"}
          name={"message"}
          value={values.message}
          error={touched.message && Boolean(errors.message)}
          helperText={touched.message && errors.message}
          onChange={handleChange}
          onBlur={handleBlur}
          fullWidth
        />
      );
    }
  };

  const dateOrNormalMessage = (message) => {
    var timestamp = Date.parse(message);
    if (isNaN(timestamp) === false) {
      var message = moment(message).format("LLL");
    }

    return message;
  };

  return (
    <Container maxWidth={"md"} className={"mt-8 mb-8"}>
      <FormikProvider value={formik}>
        <Form className="h-full">
          <div className="shadow-xl rounded h-full flex flex-col p-4">
            <div
              id={"chatBox"}
              className="flex-1 flex items-start flex-col gap-2 chatBox"
            >
              {conversation.map(({ bot, user, title }, ind) => {
                return (
                  <div
                    className={`${
                      user
                        ? "inline-flex w-full justify-between"
                        : "inline-flex w-full"
                    }`}
                  >
                    <div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        enable-background="new 0 0 24 24"
                        height="34px"
                        viewBox="0 0 24 24"
                        width="34px"
                        fill="#25a9da"
                        className={`${user ? "hidden" : "mr-2 mt-2"}`}
                      >
                        <g>
                          <rect fill="none" height="24" width="24" y="0" />
                        </g>
                        <g>
                          <g>
                            <path d="M20,9V7c0-1.1-0.9-2-2-2h-3c0-1.66-1.34-3-3-3S9,3.34,9,5H6C4.9,5,4,5.9,4,7v2c-1.66,0-3,1.34-3,3s1.34,3,3,3v4 c0,1.1,0.9,2,2,2h12c1.1,0,2-0.9,2-2v-4c1.66,0,3-1.34,3-3S21.66,9,20,9z M18,19L6,19V7h12V19z M9,13c-0.83,0-1.5-0.67-1.5-1.5 S8.17,10,9,10s1.5,0.67,1.5,1.5S9.83,13,9,13z M16.5,11.5c0,0.83-0.67,1.5-1.5,1.5s-1.5-0.67-1.5-1.5S14.17,10,15,10 S16.5,10.67,16.5,11.5z M8,15h8v2H8V15z" />
                          </g>
                        </g>
                      </svg>
                    </div>
                    <div
                      className={`${
                        user ? "bg-gray-300" : "bg-primary-color text-white"
                      } rounded-lg p-3 ${user && "self-end"} max-w-xl`}
                    >
                      <Typography variant={"body2"}>
                        <b className="whitespace-pre-wrap">
                          {bot ? bot : ""}
                          {user ? dateOrNormalMessage(user) : ""}
                        </b>
                      </Typography>
                    </div>
                  </div>
                );
              })}
              {loading ? (
                <div className="flex">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    enable-background="new 0 0 24 24"
                    height="34px"
                    viewBox="0 0 24 24"
                    width="34px"
                    fill="#25a9da"
                    className={"mt-2 mr-2"}
                  >
                    <g>
                      <rect fill="none" height="24" width="24" y="0" />
                    </g>
                    <g>
                      <g>
                        <path d="M20,9V7c0-1.1-0.9-2-2-2h-3c0-1.66-1.34-3-3-3S9,3.34,9,5H6C4.9,5,4,5.9,4,7v2c-1.66,0-3,1.34-3,3s1.34,3,3,3v4 c0,1.1,0.9,2,2,2h12c1.1,0,2-0.9,2-2v-4c1.66,0,3-1.34,3-3S21.66,9,20,9z M18,19L6,19V7h12V19z M9,13c-0.83,0-1.5-0.67-1.5-1.5 S8.17,10,9,10s1.5,0.67,1.5,1.5S9.83,13,9,13z M16.5,11.5c0,0.83-0.67,1.5-1.5,1.5s-1.5-0.67-1.5-1.5S14.17,10,15,10 S16.5,10.67,16.5,11.5z M8,15h8v2H8V15z" />
                      </g>
                    </g>
                  </svg>
                  <div className="chat-bubble">
                    <div class="typing">
                      <div class="dot"></div>
                      <div class="dot"></div>
                      <div class="dot"></div>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="flex items-center gap-2 pt-4">
              <div className="flex-1">
                {/* <TextField
                  size={"small"}
                  variant="outlined"
                  disabled={status === "submitted"}
                  fullWidth
                  onChange={handleMessageChange}
                  value={message}
                /> */}
                {renderComponent()}
              </div>
              <div className="self-start">
                <Button
                  disabled={status === "submitted" || loading}
                  type={"submit"}
                  variant={"contained"}
                  color={"primary"}
                >
                  Send
                </Button>
              </div>
            </div>
          </div>
        </Form>
      </FormikProvider>
    </Container>
  );
}

export default ChatForm;
