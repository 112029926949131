import {
  Box,
  Button,
  Chip,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import { AttachFile } from "@material-ui/icons";
import axios from "axios";
import { Form, FormikProvider, useFormik } from "formik";
import React, { useEffect, useState } from "react";
import * as yup from "yup";
import { getContactSupportMail, postContactSupport } from "../../api/api";
import SimpleModal from "../../commonComponents/Modal/SimpleModal";
import CustomSnack from "../../commonComponents/Snack/CustomSnack";
import { MESSAGES } from "../../utils/constants/constants";

const validationSchema = yup.object({
  subject: yup.string().nullable().required("Please fill the required field"),
  message: yup.string().nullable().required("Please fill the required field"),
});

function ContactSupport({ open, onClose }) {
  const [modalOpen, setModalOpen] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [snack, setSnack] = useState({
    open: false,
    color: "",
    message: "",
  });

  const handleClose = () => {
    onClose();
    setModalOpen(false);
  };

  const handleSnackOpen = (message = MESSAGES.error, color = "error") => {
    setSnack({
      open: true,
      color: color,
      message: message || MESSAGES.error,
    });
  };

  const handleSnackClose = () => {
    setSnack({
      open: false,
      color: "",
      message: "",
    });
  };

  const onSubmit = async (values, { resetForm }) => {
    let user_email = localStorage.getItem("user_email");
    if (user_email) {
      try {
        setSubmitLoading(true);
        let formData = new FormData();
        formData.append("user_email", user_email);
        formData.append("to_email", values.toEmail);
        formData.append("subject", values.subject);
        formData.append("message", values.message);
        if (values.attachments.length) {
          values.attachments.map((el) => {
            formData.append("attachments", el);
          });
        }
        const response = await axios.post(postContactSupport(), formData, {
          headers: { "Content-Type": "multipart/form-data" },
        });
        if (response.status === 200) {
          resetForm();
          handleClose();
          handleSnackOpen(MESSAGES.contactSupportMailSuccessMessage, "success");
        } else {
          handleSnackOpen();
        }
      } catch (err) {
        handleSnackOpen();
      }
    } else {
      handleSnackOpen(MESSAGES.sessionExpiredInSupportEmail);
      resetForm();
      handleClose();
    }
    setSubmitLoading(false);
  };

  const formik = useFormik({
    initialValues: {
      toEmail: "",
      subject: "",
      message: "",
      attachments: [],
    },
    validationSchema: validationSchema,
    onSubmit: onSubmit,
    enableReinitialize: true,
  });

  const {
    values,
    touched,
    errors,
    setFieldValue,
    handleBlur,
    handleChange,
    handleSubmit,
    resetForm,
  } = formik;

  const getAndSetContactSupportSettings = () => {
    axios
      .get(getContactSupportMail())
      .then((response) => {
        if (response.status === 200) {
          setModalOpen(true);
          setFieldValue("toEmail", response.data.to_mail);
        } else {
          handleSnackOpen();
          handleClose();
        }
      })
      .catch((err) => {
        handleSnackOpen();
        handleClose();
      });
  };

  useEffect(() => {
    if (open) {
      getAndSetContactSupportSettings();
    }
  }, [open]);

  const handleAttachment = (e) => {
    const { files } = e.target;
    if (files.length) {
      let arr = [...values.attachments];
      for (let i = 0; i < files.length; i++) {
        let isExist = arr.some(
          (a) =>
            a.name === files[i].name &&
            a.type === files[i].type &&
            a.size === files[i].size
        );
        if (isExist) continue;
        arr.push(files[i]);
      }
      setFieldValue("attachments", arr);
    } else {
      handleSnackOpen("Please choose a valid file");
    }
    e.currentTarget.value = null;
  };

  const handleDelete = (index) => {
    let arr = [...values.attachments];
    arr.splice(index, 1);
    setFieldValue("attachments", arr);
  };

  const handleModalClose = () => {
    resetForm();
    handleClose();
  };

  return (
    <Box>
      <SimpleModal
        open={modalOpen}
        title={"Contact Support"}
        saveButtonText={"Submit"}
        handleClose={handleModalClose}
        handleSave={handleSubmit}
        loading={submitLoading}
        maxWidth={"sm"}
      >
        <FormikProvider value={formik}>
          <Form>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography
                  color={"textSecondary"}
                  className={"flex items-center gap-2"}
                >
                  <span>{"To Email:"}</span>
                  <b>{values.toEmail}</b>
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name={"subject"}
                  variant={"outlined"}
                  label={"Subject"}
                  value={values.subject}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.subject && Boolean(errors.subject)}
                  helperText={touched.subject && errors.subject}
                  size={"small"}
                  required
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name={"message"}
                  variant={"outlined"}
                  label={"Message"}
                  value={values.message}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.message && Boolean(errors.message)}
                  helperText={touched.message && errors.message}
                  size={"small"}
                  minRows={2}
                  multiline
                  required
                  fullWidth
                />
              </Grid>
              {values.attachments.length ? (
                <Grid item xs={12}>
                  <Typography variant={"subtitle2"}>
                    <b>{"Attachments"}</b>
                  </Typography>
                  <div className={"input-border mt-2"}>
                    <Box className={"flex items-center gap-2 flex-wrap"}>
                      {values.attachments.map(({ name }, index) => (
                        <Chip
                          label={name}
                          onDelete={() => handleDelete(index)}
                        />
                      ))}
                    </Box>
                  </div>
                </Grid>
              ) : null}
              <Grid item xs={12} container alignItems={"center"}>
                <input
                  className={"hidden"}
                  id={"contained-button-file"}
                  type={"file"}
                  onChange={handleAttachment}
                  multiple
                />
                <label htmlFor={"contained-button-file"}>
                  <Button
                    size={"small"}
                    variant={"contained"}
                    color={"primary"}
                    startIcon={<AttachFile />}
                    component={"span"}
                  >
                    <b className={"capitalize"}>{"Attach"}</b>
                  </Button>
                </label>
              </Grid>
            </Grid>
          </Form>
        </FormikProvider>
      </SimpleModal>
      <CustomSnack
        open={snack.open}
        color={snack.color}
        message={snack.message}
        onClose={handleSnackClose}
      />
    </Box>
  );
}

export default ContactSupport;
