import moment from "moment";

export const LOCAL_ENVIORNMENT = "localhost:3002";

/**-- Top Nav Bar Links --**/
export const MAIN_SECTION_DIV_HELP = "Help";
export const MAIN_SECTION_DIV_GUIDE = "Guide";
export const MAIN_SECTION_DIV_INSTRUCTIONS = "Instructions";
export const MAIN_SECTION_DIV_EMAIL_TEMPLATES = "Email Templates";
export const MAIN_SECTION_DIV_USER_SIGNUP = "User SignUp";

/**-----**/

/**-- Side Nav Bar Links --**/
export const MAIN_SECTION_DIV_DASHBOARD = "Dashboard";

// SECTION UNDER TRUE REQ
export const MAIN_SECTION_DIV_TRUE_REQ = "True Req";
export const MAIN_SECTION_DIV_CREATE_INTAKE = "Create Intake";
export const MAIN_SECTION_DIV_SEARCH_INTAKE = "Advanced True Req Search";

// SECTION UNDER RESUME INTL
export const MAIN_SECTION_DIV_RESUME_INTL = "Resume Intelligence"; // Upload Resume Page
export const MAIN_SECTION_DIV_PARSE_RESUME = "Parse Resume";
export const MAIN_SECTION_DIV_SEARCH_RESUME = "Advanced Resume Search";

/**-----**/

/** Main Section Starting Page Order **/
export const MAIN_SECTION_DIVS = [
  MAIN_SECTION_DIV_DASHBOARD,
  MAIN_SECTION_DIV_INSTRUCTIONS,
  MAIN_SECTION_DIV_TRUE_REQ,
  MAIN_SECTION_DIV_CREATE_INTAKE,
  MAIN_SECTION_DIV_SEARCH_INTAKE,
  MAIN_SECTION_DIV_RESUME_INTL,
  MAIN_SECTION_DIV_PARSE_RESUME,
  MAIN_SECTION_DIV_SEARCH_RESUME,
  MAIN_SECTION_DIV_HELP,
  MAIN_SECTION_DIV_EMAIL_TEMPLATES,
  MAIN_SECTION_DIV_USER_SIGNUP,
  // MAIN_SECTION_DIV_GUIDE,
];
/**-----**/

//Top Nav Bar Links
export const TOP_NAV_LINKS = [
  MAIN_SECTION_DIV_HELP,
  // MAIN_SECTION_DIV_GUIDE,
  MAIN_SECTION_DIV_INSTRUCTIONS,
  MAIN_SECTION_DIV_EMAIL_TEMPLATES,
  MAIN_SECTION_DIV_USER_SIGNUP,
];

// Side Nav Bar Links
export const SIDE_BAR_ALL_LINKS = [
  MAIN_SECTION_DIV_DASHBOARD,
  MAIN_SECTION_DIV_TRUE_REQ,
  MAIN_SECTION_DIV_CREATE_INTAKE,
  MAIN_SECTION_DIV_SEARCH_INTAKE,
  MAIN_SECTION_DIV_RESUME_INTL,
  MAIN_SECTION_DIV_PARSE_RESUME,
  MAIN_SECTION_DIV_SEARCH_RESUME,
];

// Side Nav Bar link with no section
export const SIDE_BAR_OTHER_LINKS = [MAIN_SECTION_DIV_DASHBOARD];

// Side Nav Bar link under True Req Section
export const SIDE_BAR_TRUE_REQ_LINKS = [
  MAIN_SECTION_DIV_CREATE_INTAKE,
  MAIN_SECTION_DIV_TRUE_REQ,
  MAIN_SECTION_DIV_SEARCH_INTAKE,
];

// Side Nav Bar link under Resume Intl Section
export const SIDE_BAR_RESUME_INTL_LINKS = [
  MAIN_SECTION_DIV_RESUME_INTL,
  MAIN_SECTION_DIV_PARSE_RESUME,
  MAIN_SECTION_DIV_SEARCH_RESUME,
];

/** Main Section Deatils for each Link **/

export const MAIN_SECTION_DIVS_DETAILS = {};

MAIN_SECTION_DIVS_DETAILS[MAIN_SECTION_DIV_GUIDE] = {
  linkName: MAIN_SECTION_DIV_GUIDE,
  displayName: MAIN_SECTION_DIV_GUIDE,
  iconName: "chrome_reader_mode",
  cssClassName: "topNavLinks",
  accessName: "guide_access",
};

MAIN_SECTION_DIVS_DETAILS[MAIN_SECTION_DIV_INSTRUCTIONS] = {
  linkName: MAIN_SECTION_DIV_INSTRUCTIONS,
  displayName: MAIN_SECTION_DIV_INSTRUCTIONS,
  iconName: "library_books",
  cssClassName: "topNavLinks",
  accessName: "instructions_access",
};

MAIN_SECTION_DIVS_DETAILS[MAIN_SECTION_DIV_EMAIL_TEMPLATES] = {
  linkName: MAIN_SECTION_DIV_EMAIL_TEMPLATES,
  displayName: MAIN_SECTION_DIV_EMAIL_TEMPLATES,
  iconName: "mail_outline",
  cssClassName: "topNavLinks",
  accessName: "email_template_access",
};

MAIN_SECTION_DIVS_DETAILS[MAIN_SECTION_DIV_USER_SIGNUP] = {
  linkName: MAIN_SECTION_DIV_USER_SIGNUP,
  displayName: MAIN_SECTION_DIV_USER_SIGNUP,
  iconName: "person_add",
  cssClassName: "topNavLinks",
  accessName: "signup_access",
};

MAIN_SECTION_DIVS_DETAILS[MAIN_SECTION_DIV_HELP] = {
  linkName: MAIN_SECTION_DIV_HELP,
  displayName: MAIN_SECTION_DIV_HELP,
  iconName: "help_outline",
  cssClassName: "topNavLinks",
  accessName: "faq_access",
};
MAIN_SECTION_DIVS_DETAILS[MAIN_SECTION_DIV_DASHBOARD] = {
  linkName: MAIN_SECTION_DIV_DASHBOARD,
  displayName: MAIN_SECTION_DIV_DASHBOARD,
  iconName: "insert_chart",
  accessName: "dashboard_access",
};

MAIN_SECTION_DIVS_DETAILS[MAIN_SECTION_DIV_CREATE_INTAKE] = {
  linkName: MAIN_SECTION_DIV_CREATE_INTAKE,
  displayName: MAIN_SECTION_DIV_CREATE_INTAKE,
  iconName: "assignment",
  accessName: "req_intl_level_1",
};

MAIN_SECTION_DIVS_DETAILS[MAIN_SECTION_DIV_TRUE_REQ] = {
  linkName: MAIN_SECTION_DIV_TRUE_REQ,
  displayName: "Upload Intake",
  iconName: "storage",
  accessName: "req_intl_level_1",
};

MAIN_SECTION_DIVS_DETAILS[MAIN_SECTION_DIV_SEARCH_INTAKE] = {
  linkName: MAIN_SECTION_DIV_SEARCH_INTAKE,
  displayName: "Advanced Search",
  iconName: "search",
  accessName: "req_intl_level_2",
};

MAIN_SECTION_DIVS_DETAILS[MAIN_SECTION_DIV_RESUME_INTL] = {
  linkName: MAIN_SECTION_DIV_RESUME_INTL,
  displayName: "Upload Resume",
  iconName: "receipt",
  accessName: "resume_intl_level_1",
};

MAIN_SECTION_DIVS_DETAILS[MAIN_SECTION_DIV_PARSE_RESUME] = {
  linkName: MAIN_SECTION_DIV_PARSE_RESUME,
  displayName: MAIN_SECTION_DIV_PARSE_RESUME,
  iconName: "flip",
  accessName: "resume_intl_level_3",
};

MAIN_SECTION_DIVS_DETAILS[MAIN_SECTION_DIV_SEARCH_RESUME] = {
  linkName: MAIN_SECTION_DIV_SEARCH_RESUME,
  displayName: "Advanced Search",
  iconName: "search",
  accessName: "resume_intl_level_2",
};
/**-----**/

// Error Message to be displayed in the screen
export const ERROR_MSG_LOADING = "loading";
export const ERROR_MSG_NORESULT = "noResult";
export const ERROR_MSG_SERVER_ERROR = "serverError";
export const ERROR_MSG_ACCESS_ERROR = "accessError";

export const ERROR_MSG = [
  ERROR_MSG_LOADING,
  ERROR_MSG_NORESULT,
  ERROR_MSG_SERVER_ERROR,
];

// HTTP Response Status
export const STATUS_SUCCESS = "success";
export const STATUS_FAILURE = "failure";
export const STATUS_NULL = "null";

export const STATUS_MSG = [STATUS_SUCCESS, STATUS_FAILURE, STATUS_NULL];

// File Status for the Upload File Section
export const FILE_STATUS_COMPLETED = "Completed";
export const FILE_STATUS_PROCESSING = "Processing";
export const FILE_STATUS_OVERWRITTEN = "OverWritten";
export const FILE_STATUS_ERROR = "Error";
export const FILE_STATUS_SAVED = "Saved";

// Snack Messages
export const SOMETHING_WENT_WRONG = "Something Went Wrong";
export const CREATED_MESSAGE = "Created Successfully";
export const UPDATED_MESSAGE = "Updated Successfully";
export const DELETED_MESSAGE = "Deleted Successfully";

// Error Response for all the HTTP Request
export const APIErrorResponse = (error, ReqDetails) => {
  // Error
  if (error.response) {
    console.log("Failed for", ReqDetails);
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    // console.log(error.response.data);
    console.log("Error Status : ", error.response.status);
    // console.log(error.response.headers);
  } else if (error.request) {
    // The request was made but no response was received
    console.log("Error: The request was made but no response was received");
    // `error.request` is an instance of XMLHttpRequest in the
    console.log(error.request);
  } else {
    // Something happened in setting up the request that triggered an Error
    console.log("Error", error.message);
  }
};

//Fomatting the Autocomplete Skill List

export const formatAutocompleteSkills = (data) => {
  let autoCompleteSkills = {};
  if (data.length > 0) {
    data.forEach((eachSkill) => {
      let skillValue = eachSkill.display.trim();
      autoCompleteSkills[skillValue] = null;
    });
  }
  return autoCompleteSkills;
};

//  Get Phone number value from the provided string and return formatted phone number
export const getPhoneNumbervalue = (phoneNum) => {
  if (phoneNum.length > 10) phoneNum = phoneNum.substr(phoneNum.length - 10);

  let finalValue = phoneNum;
  if (phoneNum.length > 6)
    finalValue =
      "( " +
      phoneNum.substr(0, 3) +
      " ) " +
      phoneNum.substr(3, 3) +
      " - " +
      phoneNum.substr(6);
  else if (phoneNum.length > 3)
    finalValue = "( " + phoneNum.substr(0, 3) + " ) " + phoneNum.substr(3, 3);
  else if (phoneNum.length >= 1) finalValue = "( " + phoneNum.substr(0, 3);
  else if (phoneNum.length < 1) finalValue = "";

  return finalValue;
};

// Get Local Time for the Activity Log in Search Intake/ Search Resume Page
export const getLocalTime = (activityUTCDate, dateORtime) => {
  var activityTime = moment
    .utc(activityUTCDate, "YYYY-MM-DD HH:mm:ss")
    .toDate();
  var localtime = moment(activityTime).format("HH:mm:ss");
  var localDate = moment(activityTime).format("DD MMM YYYY");
  return dateORtime === "date" ? localDate : localtime;
};

// From the Database UTC time, get the local time for the user and return the last logged in Duration for Top Nav Bar
export const getLocalDuration = (activityUTCDate) => {
  // Acitivity Time to be changed from the UTC format to Normal format to display in the page
  var activityTime = moment
    .utc(activityUTCDate, "YYYY-MM-DD HH:mm:ss")
    .toDate();
  var lastLoggedTime = moment(activityTime).format("YYYY-MM-DD HH:mm:ss");
  var duration = moment().diff(lastLoggedTime, "days");

  // If the difference is less than 1 day then show it as logged in 2 hours ago.
  if (duration < 1) {
    duration = moment(lastLoggedTime, "YYYY-MM-DD HH:mm:ss").fromNow();
    duration = duration + " at " + moment(activityTime).format("hh:mm:ss a");
    //   duration = 'today at '+ moment(lastLoggedTime).format('hh:mm:ss a');
  } else
    duration = duration === 1 ? duration + " day ago" : duration + " days ago";

  return duration;
};

export const JOINING_PROBABILITY_BG_COLOR = {
  "Not Applicable": "gray",
  Low: "red",
  Medium: "orange",
  High: "#00c100",
  "Very High": "green",
};

export const JOB_POSTING_STATUS = {
  active: "Active",
  hold: "Hold",
  closed: "Closed",
};

export const REJECT_MODULE = {
  funnel: "Funnel",
  qualify: "Qualify",
  test: "Test",
  recommend: "Recommend",
  clientInterview: "Interview",
};

export const INTERVIEW_STATUS = {
  interviewPending: "Interview Pending",
  offerTaken: "Offer Taken",
  rejected: "Rejected",
  interviewCompleted: "Interview Completed",
};

export const QUALIFY_REPORT_TYPE = {
  send_to_client_manager: "send_to_client_manager",
  send_to_hiring_manager: "send_to_hiring_manager",
  export: "export",
};

export const MESSAGES = {
  error: "Something went wrong",
  testEventInviteByEmail: "Test event invite email sent successfully",
  testEventInformedByPhone: "Test event successfully communicated via phone",
  recommendedForInterview:
    "The applicant has been successfully recommended for an interview",
  testEventScheduleViaPhoneOrFormLink:
    "Test event has been successfully scheduled",
  testEventScheduleFormLink: "Test event schedule form link sent successfully",
  testEventScheduleChatbot: "Test event schedule chatbot sent successfully",
  required: "Please fill the required field",
  moveToInterview:
    "Applicants has been successfully moved to Interview Module ",
  resetPasswordExpireMessage:
    "Your reset link has been expired. Please re-verify to change the password",
  resetPasswordSuccessMessage: "Your password has been changed successfully",
  forgotPasswordSuccessMessage:
    "Password reset request email was sent successfully",
  contactSupportMailSuccessMessage: "Support email has been sent successfully",
  sessionExpiredInSupportEmail:
    "Session Expired. Kindly re-login to send the support email",
  shareJobToCandidateEmailSuccessMessage:
    "Shared the JD with the candidate successfully",
  addIntakeClientEmailSuccessMessage:
    "Add intake form link has been sent successfully",
  validFileErrorMessage: "Please select a valid file",
  resumeUploadSuccessMessage: "Resume upload successfully",
  interviewScheduleChatbot: "Interview schedule chatbot sent successfully",
  interviewScheduleFormLink: "Interview schedule form link sent successfully",
  interviewScheduleViaPhoneOrFormLink:
    "Interview has been successfully scheduled",
};

export const INTAKE_ACTIONS = {
  add: "add",
  edit: "edit",
  delete: "delete",
  download: "download",
};

export const INTERVIEW_SCHEDULE_STATUS = {
  sent: "Sent",
  in_progress: "In Progress",
  submitted: "Submitted",
  opened: "Opened",
  new: "New",
};

export const RESUME_UPLOAD_INSTRUCTION = [
  `Make sure the mentioned sections are present in a resume for better parsing (Summary, Education, Certification if present, Technical Skills, Professional experience)`,
  `Maintain atleast a line gap between different sections and one line within sections.`,
  `"Work Experience" / "Professional Experience" heading should not be more than one.`,
  `For every work experience block, duration must be present in the first line.`,
  `Every work experience block should have the heading "Responsibilities:"`,
  `Date should not have "-" in between (e.g. Jan-2018 - Jan-2019 format is not acceptable) and "to" between the two dates (e.g. Jan-2018 to Jan-2019 format is not acceptable)`,
];
