import {
  Box,
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { recommendForInterview, rejectApplicant } from "../../api/api";
import {
  JOINING_PROBABILITY_BG_COLOR,
  MESSAGES,
  REJECT_MODULE,
} from "../../utils/constants/constants";
import {
  ROLE_BASED_PAGES,
  getRoleBasedAccess,
} from "../../utils/constants/roleBasedAccess";
import {
  FEEDBACK_RELATED_PAGES,
  getFeedbackLevelBasedAccess,
} from "../../utils/constants/feedbackLevelBasedAccess";
import SimpleModal from "../../commonComponents/Modal/SimpleModal";
import CustomSnack from "../../commonComponents/Snack/CustomSnack";
import { useDispatch, useSelector } from "react-redux";
import { getPageUsingRemovingAnElement } from "../../utils/constants/helpers";
import { setTestState } from "../../redux/actions/test";

const JOIN_PROBABILITY = [
  {
    name: "Low",
    value: "Low",
  },
  {
    name: "Medium",
    value: "Medium",
  },
  {
    name: "High",
    value: "High",
  },
  {
    name: "Very High",
    value: "Very High",
  },
];

function TestOutcome({
  getAndSetCount = () => {},
  sessionData,
  getAndSetApplicantSessionData,
}) {
  const history = useHistory();

  const [value, setValue] = React.useState("Yes");
  const [joiningProbability, setJoiningProbability] = useState({
    probability_of_joining: "Low",
    probability_notes: "",
  });
  const [rejectNote, setRejectNote] = useState("");
  const [snack, setSnack] = useState({
    open: false,
    color: "",
    message: "",
  });

  const { jobCode, applicantId } = useParams();
  const role = localStorage.getItem("role");
  const { isAccessibleActionButton = true } = getRoleBasedAccess(
    role,
    ROLE_BASED_PAGES.testOutcome
  );

  const feedbackLevel = sessionStorage.getItem("feedbackLevel");
  const { isRejectCandidate = () => false } = getFeedbackLevelBasedAccess(
    feedbackLevel,
    FEEDBACK_RELATED_PAGES.testOutcome
  );

  const { pagination, totalCount } = useSelector((state) => state.test);
  const dispatch = useDispatch();

  const handleSnackClose = () => {
    setSnack({
      open: false,
      color: "",
      message: "",
    });
  };

  const handleSnackOpen = (message = MESSAGES.error, color = "error") => {
    setSnack({
      open: true,
      color: color,
      message: message || MESSAGES.error,
    });
  };

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const getTestStatus = () => {
    if (sessionData?.test_event?.report_candidate?.status === "Complete") {
      return "Completed";
    } else {
      return "Pending";
    }
  };

  const handleRecommendClick = () => {
    let flag = value === "Yes" ? "True" : "False";
    let formData = new FormData();
    formData.append("job_code", jobCode);
    formData.append("applicant", applicantId);
    formData.append("is_recommended", flag);
    formData.append(
      "probability_of_joining",
      joiningProbability.probability_of_joining
    );
    formData.append("probability_notes", joiningProbability.probability_notes);

    axios
      .post(recommendForInterview(), formData)
      .then((response) => {
        if (response.status === 201) {
          setSnack({
            open: true,
            color: "success",
            message: MESSAGES.recommendedForInterview,
          });
          setTimeout(() => {
            getAndSetApplicantSessionData();
            getAndSetCount();
            history.push(`/dashboard/${jobCode}/testModule`);
          }, 2500);
        }
      })
      .catch((err) => {
        setSnack({
          open: true,
          color: "error",
          message: "Something Went Wrong",
        });
      });
  };

  useEffect(() => {
    if (sessionData?.recommend) {
      if (!sessionData.recommend.is_recommended) {
        setValue("No");
      } else {
        setValue("Yes");
      }
    }
    if (sessionData?.probability_of_joining || sessionData?.probability_notes) {
      setJoiningProbability({
        probability_of_joining: sessionData?.probability_of_joining ?? "Low",
        probability_notes: sessionData?.probability_notes ?? "",
      });
    }
  }, [sessionData]);

  const handleProbabilityValueChange = (e) => {
    const { value, name } = e.target;
    setJoiningProbability({
      ...joiningProbability,
      [name]: value,
    });
  };

  const handleReject = () => {
    const applicantObj = sessionData.applicant;
    if (applicantObj) {
      let payload = {
        applicant_id: applicantObj.id,
        job_code: applicantObj.job_code,
        reject_note: rejectNote,
        reject_module: REJECT_MODULE.test,
      };
      axios
        .post(rejectApplicant(), payload)
        .then((response) => {
          setRejectNote(null);
          if (response.status === 200) {
            setSnack({
              open: true,
              color: "success",
              message: "Applicant Rejected Successfully",
            });
            setTimeout(() => {
              getAndSetApplicantSessionData();
              getAndSetCount();

              // To reset the page number in test landing page
              let page = getPageUsingRemovingAnElement(
                totalCount,
                pagination.activePage,
                pagination.items
              );
              dispatch(
                setTestState({
                  pagination: { ...pagination, activePage: page },
                })
              );
              history.push(`/dashboard/${jobCode}/testModule`);
            }, 2500);
          } else {
            handleSnackOpen();
          }
        })
        .catch((err) => {
          setRejectNote(null);
          handleSnackOpen();
        });
    } else {
      setRejectNote(null);
      handleSnackOpen("Applicant not found");
    }
  };

  const handleRejectNoteChange = (e) => {
    const { value } = e.target;
    setRejectNote(value);
  };

  return (
    <Grid container spacing={4}>
      <Grid item xs={10}>
        <div className="flex items-center justify-between">
          <div className="flex flex-col gap-2">
            <Typography>
              Total Score:
              <b className="ml-1">
                {sessionData?.test_event?.report_candidate?.total_score}
              </b>
            </Typography>
            <Typography>
              Candidate Score:
              <b className="ml-1">
                {sessionData?.test_event?.report_candidate?.candidate_score}
              </b>
            </Typography>
          </div>
          <div className="flex flex-col gap-2">
            <Typography>
              Test Status: <b>{getTestStatus()}</b>
            </Typography>
            <Typography>
              {`Test Result: `}
              <b>
                {
                  sessionData?.test_event?.report_candidate
                    ?.performance_category
                }
              </b>
            </Typography>
          </div>
        </div>
      </Grid>
      <Grid item xs={2}></Grid>
      <Grid item xs={7}>
        <Button
          disabled={
            sessionData?.test_event?.report_candidate?.report_pdf_url
              ? false
              : true
          }
          onClick={() => {
            window.open(
              sessionData?.test_event?.report_candidate?.report_pdf_url
            );
          }}
          color={"primary"}
        >
          <b>Download Test Report</b>
        </Button>
      </Grid>
      <Grid item xs={10}>
        <div className={"flex items-center ml-1"}>
          <Typography>
            <b className="mr-3">{"Joining Probability:"}</b>
          </Typography>
          <Box width={"130px"}>
            <FormControl size={"small"} variant={"outlined"} fullWidth>
              <Select
                name={"probability_of_joining"}
                value={joiningProbability.probability_of_joining || ""}
                onChange={handleProbabilityValueChange}
                color={"primary"}
              >
                {JOIN_PROBABILITY.map((el) => (
                  <MenuItem key={el.value} value={el.value}>
                    <b
                      style={{ color: JOINING_PROBABILITY_BG_COLOR[el.value] }}
                    >
                      {el.name}
                    </b>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </div>
        <Box paddingTop={"20px"}>
          <TextField
            variant={"outlined"}
            label={"Explain"}
            name={"probability_notes"}
            value={joiningProbability.probability_notes}
            onChange={handleProbabilityValueChange}
            size={"small"}
            multiline
            fullWidth
          />
        </Box>
      </Grid>
      <Grid item xs={10}>
        <div className="flex items-start flex-col gap-2 border border-gray-300 rounded-lg p-6">
          <Typography>{"Recommended for interview"}</Typography>
          <FormControl component="fieldset">
            <RadioGroup
              row
              name="recommended"
              value={value}
              onChange={handleChange}
            >
              <FormControlLabel
                labelPlacement="end"
                value="Yes"
                control={<Radio />}
                label="Yes"
              />
              <FormControlLabel
                labelPlacement="end"
                value="No"
                control={<Radio />}
                label="No"
              />
            </RadioGroup>
          </FormControl>
          <Button
            color="primary"
            variant="contained"
            onClick={handleRecommendClick}
            disabled={!isAccessibleActionButton}
          >
            Submit
          </Button>
        </div>
      </Grid>
      {isRejectCandidate(role) ? (
        <Grid item xs={10}>
          <div className="flex items-start flex-col gap-4 border border-gray-300 rounded-lg p-6">
            <Typography>{"Reject Applicant"}</Typography>
            <TextField
              name={"rejectNote"}
              label={"Reject Note"}
              value={rejectNote || ""}
              onChange={handleRejectNoteChange}
              variant={"outlined"}
              size={"small"}
              multiline
              fullWidth
            />
            <Button
              color={"primary"}
              variant={"contained"}
              onClick={handleReject}
            >
              {"Submit"}
            </Button>
          </div>
        </Grid>
      ) : null}
      <CustomSnack
        open={snack.open}
        color={snack.color}
        message={snack.message}
        onClose={handleSnackClose}
      />
    </Grid>
  );
}

export default TestOutcome;
