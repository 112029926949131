import { Box } from "@material-ui/core";
import ReactPDF, {
  Document,
  Page,
  PDFViewer,
  StyleSheet,
  Text,
  View,
  Font,
} from "@react-pdf/renderer";
import React, { useEffect } from "react";
import {
  formatDate,
  hyphenationCallback,
} from "../../../utils/constants/helpers";

const viewPdf = false;

Font.registerHyphenationCallback(hyphenationCallback);

const styles = StyleSheet.create({
  row: {
    fontStyle: "bold",
  },
  header: {
    textAlign: "center",
    color: "#008ae6",
    textDecoration: "underline",
    fontSize: "14px",
    fontFamily: "Helvetica-Bold",
  },
  viewer: {
    width: "100%",
    height: window.innerHeight,
  },
  page: {
    flexDirection: "column",
    backgroundColor: "white",
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
    fontFamily: "Helvetica",
  },
  subHeader: {
    textAlign: "center",
    color: "black",
    textDecoration: "underline",
    paddingTop: "10px",
    fontSize: "14px",
    fontFamily: "Helvetica-Bold",
  },
  title: {
    textAlign: "left",
    color: "black",
    textDecoration: "underline",
    paddingTop: "20px",
    fontSize: "14px",
    fontWeight: "extrabold",
    marginBottom: "10px",
    fontFamily: "Helvetica-Bold",
  },
  valueText: {
    color: "black",
    padding: "3px",
    margin: "auto 0",
    fontSize: "10px",
    fontFamily: "Helvetica-Bold",
  },
  table: {
    display: "flex",
    marginTop: "5px",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    overflow: "hidden",
  },
  tableRow: {
    margin: "auto",
    minHeight: "20px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
  },
  tableCol: {
    width: "25%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  customTableCol: (width = "25%") => ({
    width: width,
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  }),
  customChildTableCol: (width = "25%") => ({
    width: width,
    borderRight: "1px solid black",
  }),
  tableCell: {
    textAlign: "left",
    margin: "auto",
    marginTop: 5,
    fontSize: 10,
    marginLeft: "2.5px",
    fontWeight: "bold",
  },
  tableCellValue: {
    textAlign: "left",
    margin: "auto 0",
    padding: "3px",
    fontSize: 10,
    fontFamily: "Helvetica-Bold",
  },
  linkedInCol: {
    width: "25%",
    borderStyle: "solid",
    // borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    borderBottom: 1,
  },
  linkedInCol2: {
    width: "75%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableColTwo: {
    width: "50%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCellTwo: {
    fontWeight: "bold",
    margin: "auto",
    marginTop: 5,
    fontSize: 10,
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    marginLeft: "2.5px",
  },
  subTitle: {
    textAlign: "left",
    color: "black",
    paddingTop: "10px",
    fontSize: "12px",
    fontWeight: "bold",
    marginBottom: "10px",
    fontFamily: "Helvetica-Bold",
  },
  helloText: {
    textAlign: "left",
    color: "black",
    paddingTop: "10px",
    fontSize: "10px",
    fontWeight: "bold",
  },
  queries: {
    textAlign: "left",
    color: "black",
    marginLeft: "20px",
    paddingTop: "15px",
    fontSize: "11px",
    fontWeight: "bold",
  },
  answer: {
    textAlign: "left",
    color: "black",
    marginLeft: "30px",
    padding: "10px 0 3px",
    fontSize: "11px",
    fontWeight: "bold",
    fontFamily: "Helvetica-Bold",
    borderBottom: "0.5px solid back",
    minHeight: "20px",
  },
  project1: {
    textAlign: "left",
    margin: "auto",
    marginTop: 5,
    fontSize: 10,
    marginLeft: "2px",
    fontFamily: "Helvetica-Bold",
  },
  personalDetails1: {
    textAlign: "left",
    color: "black",
    textDecoration: "underline",
    margin: "auto",
    marginTop: 5,
    fontSize: 10,
    marginLeft: "2.5px",
    fontWeight: "bold",
    fontFamily: "Helvetica-Bold",
  },
  footerView: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    position: "absolute",
    bottom: 25,
    left: 40,
    right: 0,
    width: "100%",
    borderTop: "0.5px solid gray",
    paddingTop: "10px",
    marginTop: "50px",
  },
  flexView: (gap = "0px") => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "row",
    gap: gap,
  }),
  captionText: {
    fontSize: "12px",
    color: "rgb(37, 42, 48)",
  },
  captionValueText: {
    fontSize: "12px",
    color: "rgb(37, 42, 48)",
    fontFamily: "Helvetica-Bold",
  },
});

// Create Document Component
const MyDocument = ({ formData, jobCode }) => {
  const certification =
    formData?.certification && Array.isArray(formData?.certification)
      ? formData?.certification
      : [];
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.row}>
          <Text style={styles.header}>Titan Data Group Inc</Text>
          <Text style={styles.subHeader}>Cover Sheet </Text>

          {/* Personal Details */}
          <View style={styles.flexView("5px")}>
            <Text style={styles.title}>Personal Details: </Text>
            <View style={{ ...styles.flexView("5px"), paddingTop: "10px" }}>
              <Text style={styles.captionText}>{"Job ID:"}</Text>
              <Text style={styles.captionValueText}>{jobCode}</Text>
            </View>
          </View>

          <View style={{ paddingBottom: "5px" }}>
            <View style={styles.table}>
              <View style={styles.tableRow}>
                <View style={styles.customTableCol("12%")}>
                  <Text style={styles.personalDetails1}>First Name</Text>
                </View>
                <View style={styles.customTableCol("38%")}>
                  <Text style={styles.valueText}>
                    {formData?.personal_details?.first_name || ""}
                  </Text>
                </View>
                <View style={styles.customTableCol("10%")}>
                  <Text style={styles.personalDetails1}>Phone</Text>
                </View>
                <View style={styles.customTableCol("40%")}>
                  <Text style={styles.valueText}>
                    {`${formData?.personal_details?.phone || ""}`}
                  </Text>
                </View>
              </View>
              <View style={styles.tableRow}>
                <View style={styles.customTableCol("12%")}>
                  <Text style={styles.personalDetails1}>Last Name</Text>
                </View>
                <View style={styles.customTableCol("38%")}>
                  <Text style={styles.valueText}>
                    {formData?.personal_details?.last_name || ""}
                  </Text>
                </View>
                <View style={styles.customTableCol("10%")}>
                  <Text style={styles.personalDetails1}>Email</Text>
                </View>
                <View style={styles.customTableCol("40%")}>
                  <Text style={styles.valueText}>
                    {formData?.personal_details?.email || ""}
                  </Text>
                </View>
              </View>
            </View>
            <View style={styles.table}>
              <View style={styles.tableRow}>
                <View style={styles.linkedInCol}>
                  <Text style={styles.personalDetails1}>LinkedIn Profile:</Text>
                </View>
                <View style={styles.linkedInCol2}>
                  <Text style={styles.valueText}>
                    {formData?.personal_details?.linkedin_profile || ""}
                  </Text>
                </View>
              </View>
            </View>
          </View>

          {/* Education */}
          <Text style={styles.subTitle}>{"Education:"}</Text>
          <View style={{ paddingBottom: "5px" }}>
            <View style={styles.table}>
              <View style={styles.tableRow}>
                <View style={styles.customTableCol("11%")}>
                  <Text style={styles.tableCell}>{"Graduation"}</Text>
                </View>
                <View style={styles.customTableCol("20%")}>
                  <Text style={styles.tableCell}>{"Degree"}</Text>
                </View>
                <View style={styles.customTableCol("23%")}>
                  <Text style={styles.tableCell}>{"Major/Department"}</Text>
                </View>
                <View style={styles.customTableCol("24%")}>
                  <Text style={styles.tableCell}>{"University/College"}</Text>
                </View>
                <View style={styles.customTableCol("12%")}>
                  <Text style={styles.tableCell}>{"Year Passed"}</Text>
                </View>
                <View style={styles.customTableCol("10%")}>
                  <Text style={styles.tableCell}>{"% / CGPA"}</Text>
                </View>
              </View>
              {formData?.education?.length ? (
                formData?.education.map(
                  (
                    {
                      graduation,
                      degree,
                      major,
                      university,
                      year_of_passing,
                      cgpa,
                    },
                    index
                  ) => (
                    <View style={styles.tableRow} key={`education_${index}`}>
                      <View style={styles.customTableCol("11%")}>
                        <Text style={styles.tableCellValue}>
                          {graduation || ""}
                        </Text>
                      </View>
                      <View style={styles.customTableCol("20%")}>
                        <Text style={styles.tableCellValue}>
                          {degree || ""}
                        </Text>
                      </View>
                      <View style={styles.customTableCol("23%")}>
                        <Text style={styles.tableCellValue}>{major || ""}</Text>
                      </View>
                      <View style={styles.customTableCol("24%")}>
                        <Text style={styles.tableCellValue}>
                          {university || ""}
                        </Text>
                      </View>
                      <View style={styles.customTableCol("12%")}>
                        <Text style={styles.tableCellValue}>
                          {year_of_passing || ""}
                        </Text>
                      </View>
                      <View style={styles.customTableCol("10%")}>
                        <Text style={styles.tableCellValue}>{cgpa || ""}</Text>
                      </View>
                    </View>
                  )
                )
              ) : (
                <View style={styles.tableRow}>
                  <View style={styles.customTableCol("11%")}>
                    <Text style={styles.tableCellValue}>{""}</Text>
                  </View>
                  <View style={styles.customTableCol("20%")}>
                    <Text style={styles.tableCellValue}>{""}</Text>
                  </View>
                  <View style={styles.customTableCol("23%")}>
                    <Text style={styles.tableCellValue}>{""}</Text>
                  </View>
                  <View style={styles.customTableCol("24%")}>
                    <Text style={styles.tableCellValue}>{""}</Text>
                  </View>
                  <View style={styles.customTableCol("12%")}>
                    <Text style={styles.tableCellValue}>{""}</Text>
                  </View>
                  <View style={styles.customTableCol("10%")}>
                    <Text style={styles.tableCellValue}>{""}</Text>
                  </View>
                </View>
              )}
            </View>
          </View>

          {/* Certifications */}
          <Text style={styles.subTitle}>{"Certifications:"}</Text>
          <View style={{ paddingBottom: "10px" }}>
            <View style={styles.table}>
              <View style={styles.tableRow}>
                {certification.length ? (
                  <View style={styles.customTableCol("5%")}>
                    <Text style={styles.tableCell}></Text>
                  </View>
                ) : null}
                <View
                  style={styles.customTableCol(
                    certification.length ? "75%" : "80%"
                  )}
                >
                  <Text style={styles.tableCell}>{"Certification Name"}</Text>
                </View>
                <View style={styles.customTableCol("20%")}>
                  <Text style={styles.tableCell}>{"Year of Completion"}</Text>
                </View>
              </View>
              {certification.length ? (
                certification.map(({ name, year_of_passing }, index) => (
                  <View
                    style={styles.tableRow}
                    key={`certification_${index + 1}`}
                  >
                    <View style={styles.customTableCol("5%")}>
                      <Text style={styles.tableCellValue}>{`${index + 1
                        }.`}</Text>
                    </View>
                    <View style={styles.customTableCol("75%")}>
                      <Text style={styles.tableCellValue}>{name || ""}</Text>
                    </View>
                    <View style={styles.customTableCol("20%")}>
                      <Text style={styles.tableCellValue}>
                        {year_of_passing || ""}
                      </Text>
                    </View>
                  </View>
                ))
              ) : (
                <View style={styles.tableRow}>
                  <View style={styles.customTableCol("80%")}>
                    <Text style={styles.tableCellValue}></Text>
                  </View>
                  <View style={styles.customTableCol("20%")}>
                    <Text style={styles.tableCellValue}></Text>
                  </View>
                </View>
              )}
            </View>
          </View>

          {/* Other Details */}
          <Text style={styles.subTitle}>{"Other Details:"}</Text>
          <Text style={styles.helloText}>
            {
              "To process your application further, let me collect some additional details:"
            }
          </Text>
          <View style={{ paddingBottom: "10px" }}>
            <Text style={styles.queries}>
              {`1. Have you applied for and/or interviewed with this Organisation’s Jobs in the past 6 months:`}
            </Text>
            <Text style={styles.answer}>
              {formData?.past_experience?.answer ?? ""}
            </Text>
            {formData?.past_experience?.answer === "Yes" ? (
              <>
                <Text
                  style={{ ...styles.queries, marginLeft: "30px" }}
                >{`Explain:`}</Text>
                <Text style={styles.answer}>
                  {formData?.past_experience?.explain ?? ""}
                </Text>
              </>
            ) : null}

            <Text style={styles.queries}>
              {`2. Where are you currently located (City/State):`}
            </Text>
            <Text style={styles.answer}>{formData?.currentLocation ?? ""}</Text>

            <Text style={styles.queries}>
              {`3. Are you willing to relocate:`}
            </Text>
            <Text style={styles.answer}>{formData?.reLocation ?? ""}</Text>

            <Text style={styles.queries}>{`4. Your work option:`}</Text>
            <Text style={styles.answer}>{formData?.workOption ?? ""}</Text>

            <Text style={styles.queries}>
              {`5. What is your current salary($) inclusive of all benefits:`}
            </Text>
            <Text style={styles.answer}>{formData?.currentCtc ?? ""}</Text>

            <Text style={styles.queries}>
              {`6. Variable pay if any explain:`}
            </Text>
            <Text style={styles.answer}>{formData?.variablePay ?? ""}</Text>

            <Text style={styles.queries} break>
              {`7. What is your expected annual salary($) for the above role:`}
            </Text>
            <Text style={styles.answer}>{formData?.annualSalary ?? ""}</Text>

            <Text style={styles.queries}>
              {`8. What is your notice period to join if selected for the above role:`}
            </Text>
            <Text style={styles.answer}>
              {formData?.currentNoticePeriod
                ? Number(formData.currentNoticePeriod) > 1
                  ? `${formData.currentNoticePeriod} days`
                  : `${formData.currentNoticePeriod} day`
                : formData?.currentNoticePeriod ?? ""}
            </Text>

            <Text style={styles.queries}>
              {`9. Why are you looking to change your current employment:`}
            </Text>
            <Text style={styles.answer}>
              {formData?.employment_change?.answer?.join(", ") ?? ""}
            </Text>
            {formData?.employment_change?.answer?.includes("Other") ? (
              <>
                <Text
                  style={{ ...styles.queries, marginLeft: "30px" }}
                >{`Explain:`}</Text>
                <Text style={styles.answer}>
                  {formData?.employment_change?.explain ?? ""}
                </Text>
              </>
            ) : null}

            <Text style={styles.queries}>
              {`10. This position requires you to complete pre-onboarding formalities like Drug Test, Background Check will you be open for this:`}
            </Text>
            <Text style={styles.answer}>{formData?.backgroundCheck ?? ""}</Text>
          </View>

          {/* Hobbies/ Extra Curricular Activities */}
          <Text style={styles.subTitle}>
            {"Hobbies/ Extra Curricular Activities:"}
          </Text>
          <View style={{ paddingBottom: "10px" }}>
            <Text style={styles.queries}>
              {`a. Have you participated in Hackathon/Coding test:`}
            </Text>
            <Text style={styles.answer}>
              {formData?.hobbies?.coding_test?.answer ?? ""}
            </Text>
            {formData?.hobbies?.coding_test?.answer === "Yes" ? (
              <>
                <Text
                  style={{ ...styles.queries, marginLeft: "30px" }}
                >{`Explain:`}</Text>
                <Text style={styles.answer}>
                  {formData?.hobbies?.coding_test?.explain ?? ""}
                </Text>
              </>
            ) : null}

            <Text style={styles.queries}>
              {`b. Hobbies (like Reading, Writing, Debate, Sports/Fitness):`}
            </Text>
            <Text style={styles.answer}>
              {formData?.hobbies?.hobbies?.explain ?? ""}
            </Text>
          </View>

          {/* Employment & Skills */}
          <Text style={styles.title}>{`Employment & Skills:`}</Text>
          {/* Current Employer */}
          <View style={{ paddingBottom: "10px" }}>
            <Text style={styles.subTitle}>{`Current Employer:`}</Text>
            <View style={styles.table}>
              <View style={styles.tableRow}>
                <View style={styles.customTableCol("40%")}>
                  <Text style={styles.tableCell}>{"Company Name"}</Text>
                </View>
                <View style={styles.customTableCol("30%")}>
                  <Text style={styles.tableCell}>
                    {"Job Title/Designation"}
                  </Text>
                </View>
                <View style={styles.customTableCol("15%")}>
                  <Text style={styles.tableCell}>{"Duration From"}</Text>
                </View>
                <View style={styles.customTableCol("15%")}>
                  <Text style={styles.tableCell}>{"Duration To"}</Text>
                </View>
              </View>
              <View style={styles.tableRow}>
                <View style={styles.customTableCol("40%")}>
                  <Text style={styles.tableCellValue}>
                    {formData?.current_employer?.company_name || ""}
                  </Text>
                </View>
                <View style={styles.customTableCol("30%")}>
                  <Text style={styles.tableCellValue}>
                    {formData?.current_employer?.job_title || ""}
                  </Text>
                </View>
                <View style={styles.customTableCol("15%")}>
                  <Text style={styles.tableCellValue}>
                    {formData?.current_employer?.duration_from
                      ? formatDate(
                        formData?.current_employer?.duration_from,
                        "MMM YYYY"
                      )
                      : ""}
                  </Text>
                </View>
                <View style={styles.customTableCol("15%")}>
                  <Text style={styles.tableCellValue}>
                    {formData?.current_employer?.duration_to
                      ? formatDate(
                        formData?.current_employer?.duration_to,
                        "MMM YYYY"
                      )
                      : ""}
                  </Text>
                </View>
              </View>
            </View>
          </View>

          {/* Previous Employer */}
          {formData?.previous_employer?.length ? (
            <View style={{ paddingBottom: "10px" }}>
              <Text style={styles.subTitle}>{`Previous Employer:`}</Text>
              <View style={styles.table}>
                <View style={styles.tableRow}>
                  <View style={styles.customTableCol("40%")}>
                    <Text style={styles.tableCell}>{"Company Name"}</Text>
                  </View>
                  <View style={styles.customTableCol("30%")}>
                    <Text style={styles.tableCell}>
                      {"Job Title/Designation"}
                    </Text>
                  </View>
                  <View style={styles.customTableCol("15%")}>
                    <Text style={styles.tableCell}>{"Duration From"}</Text>
                  </View>
                  <View style={styles.customTableCol("15%")}>
                    <Text style={styles.tableCell}>{"Duration To"}</Text>
                  </View>
                </View>
                {formData?.previous_employer.map(
                  (
                    {
                      company_name,
                      job_title,
                      duration,
                      duration_from,
                      duration_to,
                    },
                    index
                  ) => (
                    <View
                      style={styles.tableRow}
                      key={`previous_employer_${index + 1}`}
                    >
                      <View style={styles.customTableCol("40%")}>
                        <Text style={styles.tableCellValue}>
                          {company_name || ""}
                        </Text>
                      </View>
                      <View style={styles.customTableCol("30%")}>
                        <Text style={styles.tableCellValue}>
                          {job_title || ""}
                        </Text>
                      </View>
                      <View style={styles.customTableCol("15%")}>
                        <Text style={styles.tableCellValue}>
                          {duration_from
                            ? formatDate(duration_from, "MMM YYYY")
                            : ""}
                        </Text>
                      </View>
                      <View style={styles.customTableCol("15%")}>
                        <Text style={styles.tableCellValue}>
                          {duration_to
                            ? formatDate(duration_to, "MMM YYYY")
                            : ""}
                        </Text>
                      </View>
                    </View>
                  )
                )}
              </View>
            </View>
          ) : null}

          {/* Project Details */}
          {formData?.project_details?.length ? (
            <View break>
              <Text style={styles.subTitle}>{"Project Details:"}</Text>
              {formData.project_details.map(
                (
                  { how_many_projects, project_details: proj_details },
                  index
                ) => (
                  <View key={`project_details_${index + 1}`}>
                    <View>
                      <Text
                        style={{ ...styles.queries, marginLeft: "30px" }}
                      >{`How many projects:`}</Text>
                      <Text style={styles.answer}>{how_many_projects}</Text>
                    </View>
                    {proj_details?.length
                      ? proj_details.map(
                        (
                          {
                            client,
                            location,
                            domain,
                            duration,
                            project_experience,
                            responsibilities,
                            duration_from,
                            duration_to,
                          },
                          proj_index
                        ) => (
                          <View
                            style={{
                              paddingLeft: "30px",
                              paddingTop: "10px",
                              paddingBottom: "5px",
                            }}
                            key={`proj_details_${proj_index + 1}`}
                          >
                            <View style={styles.table}>
                              <View style={styles.tableRow}>
                                <View style={styles.customTableCol("100%")}>
                                  <Text style={styles.project1}>{`Project ${proj_index + 1
                                    }:`}</Text>
                                </View>
                              </View>
                              <View style={styles.tableRow}>
                                <View style={styles.customTableCol("30%")}>
                                  <Text
                                    style={styles.tableCell}
                                  >{`Client Name:`}</Text>
                                </View>
                                <View style={styles.customTableCol("70%")}>
                                  <Text style={styles.tableCellValue}>
                                    {client || ""}
                                  </Text>
                                </View>
                              </View>
                              <View style={styles.tableRow}>
                                <View style={styles.customTableCol("30%")}>
                                  <Text
                                    style={styles.tableCell}
                                  >{`Location (City/State):`}</Text>
                                </View>
                                <View style={styles.customTableCol("70%")}>
                                  <Text style={styles.tableCellValue}>
                                    {location || ""}
                                  </Text>
                                </View>
                              </View>
                              <View style={styles.tableRow}>
                                <View style={styles.customTableCol("30%")}>
                                  <Text
                                    style={styles.tableCell}
                                  >{`Domain:`}</Text>
                                </View>
                                <View style={styles.customTableCol("70%")}>
                                  <Text style={styles.tableCellValue}>
                                    {domain || ""}
                                  </Text>
                                </View>
                              </View>
                              <View style={styles.tableRow}>
                                <View style={styles.customTableCol("30%")}>
                                  <Text
                                    style={styles.tableCell}
                                  >{`Duration:`}</Text>
                                </View>
                                <View style={styles.customTableCol("70%")}>
                                  <View style={styles.tableRow}>
                                    <View
                                      style={styles.customChildTableCol(
                                        "15%"
                                      )}
                                    >
                                      <Text
                                        style={styles.tableCell}
                                      >{`From:`}</Text>
                                    </View>
                                    <View
                                      style={styles.customChildTableCol(
                                        "35%"
                                      )}
                                    >
                                      <Text style={styles.tableCellValue}>
                                        {duration_from
                                          ? formatDate(
                                            duration_from,
                                            "MMM YYYY"
                                          )
                                          : ""}
                                      </Text>
                                    </View>
                                    <View
                                      style={styles.customChildTableCol(
                                        "15%"
                                      )}
                                    >
                                      <Text
                                        style={styles.tableCell}
                                      >{`To:`}</Text>
                                    </View>
                                    <View style={{ width: "35%" }}>
                                      <Text style={styles.tableCellValue}>
                                        {duration_to
                                          ? formatDate(
                                            duration_to,
                                            "MMM YYYY"
                                          )
                                          : ""}
                                      </Text>
                                    </View>
                                  </View>
                                </View>
                              </View>
                              <View style={styles.tableRow}>
                                <View style={styles.customTableCol("30%")}>
                                  <Text
                                    style={styles.tableCell}
                                  >{`Project Description:`}</Text>
                                </View>
                                <View style={styles.customTableCol("70%")}>
                                  <Text style={styles.tableCellValue}>
                                    {project_experience || ""}
                                  </Text>
                                </View>
                              </View>
                              <View style={styles.tableRow}>
                                <View style={styles.customTableCol("30%")}>
                                  <Text
                                    style={styles.tableCell}
                                  >{`Responsibilities:`}</Text>
                                </View>
                                <View style={styles.customTableCol("70%")}>
                                  <Text style={styles.tableCellValue}>
                                    {responsibilities || ""}
                                  </Text>
                                </View>
                              </View>
                            </View>
                          </View>
                        )
                      )
                      : null}
                  </View>
                )
              )}
            </View>
          ) : null}

          {/* Skills summary */}
          {formData?.skill_details?.length ? (
            <View>
              <Text style={styles.subTitle}>{"Skills summary:"}</Text>
              {formData.skill_details.map(({ skill, year, month }, index) => {
                let yearValue =
                  year && !Number.isNaN(parseInt(year)) ? parseInt(year) : null;
                let monthValue =
                  month && !Number.isNaN(parseInt(month))
                    ? parseInt(month)
                    : null;
                let experience =
                  yearValue && monthValue
                    ? `${year} yr, ${month} m`
                    : yearValue
                      ? `${year} yr`
                      : monthValue
                        ? `${month} m`
                        : "-";

                return (
                  <View key={`skill_summary_${index + 1}`}>
                    <View>
                      <Text style={styles.queries}>
                        {`${index + 1
                          }. How many years of experience in ${skill}:`}
                      </Text>
                      <Text style={styles.answer}>{experience}</Text>
                    </View>
                  </View>
                );
              })}
            </View>
          ) : null}

          {/* <Text style={styles.helloText}>
            Thank you providing the above information. We will process and share
            your profile details with UPS and get back to you in the next days.
          </Text> */}
        </View>
        {/* <View fixed style={styles.footerView}>
          <Text render={({ pageNumber }) => `Page ${pageNumber}`} />
        </View> */}
      </Page>
    </Document>
  );
};

const CoverSheetPDF = ({ data, setData = () => { }, jobCode }) => {
  const file_name = `Cover_Sheet_Form_${data?.personal_details?.first_name || ""
    }${data?.personal_details?.last_name
      ? `_${data?.personal_details?.last_name}`
      : ""
    } (${jobCode}).pdf`;

  const download = () => {
    new Promise(async (resolve, reject) => {
      const blob = await ReactPDF.pdf(
        <MyDocument formData={data} jobCode={jobCode} />
      ).toBlob();
      const url = URL.createObjectURL(blob);
      if (url && url.length > 0) {
        resolve(url);
      }
    })
      .then((generatedUrl) => {
        if (generatedUrl) {
          let aTag = document.createElement("a");
          aTag.href = generatedUrl;
          aTag.style = "display: none";
          aTag.download = file_name;
          document.body.appendChild(aTag);
          aTag.click();
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    if (data) {
      download();
      setData(null);
    }
  }, [data]);

  return viewPdf ? (
    <Box position={"fixed"} top={0} left={0} right={0} bottom={0}>
      <PDFViewer style={styles.viewer}>
        <MyDocument formData={data} jobCode={jobCode} />
      </PDFViewer>
    </Box>
  ) : null;
};

export default CoverSheetPDF;
