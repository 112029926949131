import { Box, Grid, Typography } from "@material-ui/core";
import { ArrowBack } from "@material-ui/icons";
import axios from "axios";
import { useFormik } from "formik";
import React, { forwardRef, useState } from "react";
import * as yup from "yup";
import { resetPasswordRequest } from "../../api/api";
import CustomSnack from "../../commonComponents/Snack/CustomSnack";
import { MESSAGES } from "../../utils/constants/constants";
import { errorResponse } from "../../utils/constants/helpers";

const ForgotPassword = (_, ref) => {
  const { signInModal, forgotPasswordModal } = ref;
  const [snack, setSnack] = useState({
    open: false,
    color: "",
    message: "",
  });

  const handleSnackOpen = (message = MESSAGES.error, color = "error") => {
    setSnack({
      open: true,
      color: color,
      message: message || MESSAGES.error,
    });
  };

  const handleSnackClose = () => {
    setSnack({
      open: false,
      color: "",
      message: "",
    });
  };

  const validationSchema = yup.object().shape({
    username: yup.string().nullable().required("Username is required"),
  });

  const onSubmit = async (values, { setFieldError }) => {
    try {
      let payload = {
        username: values.username,
      };
      const response = await axios.post(resetPasswordRequest(), payload);
      if (response.status === 200) {
        formik.resetForm();
        handleSnackOpen(MESSAGES.forgotPasswordSuccessMessage, "success");
        setTimeout(() => {
          forgotPasswordModal.current.close();
        }, 2500);
      } else {
        setFieldError("username", "Username not found. Please try again.");
      }
    } catch (err) {
      handleSnackOpen(errorResponse(err).message);
    }
  };

  const formik = useFormik({
    initialValues: {
      username: "",
    },
    validationSchema: validationSchema,
    onSubmit: onSubmit,
  });

  const {
    values,
    errors,
    touched,
    isSubmitting,
    handleBlur,
    handleChange,
    handleSubmit,
  } = formik;

  const handleBackToLogin = (e) => {
    e.preventDefault();
    forgotPasswordModal.current.close();
    signInModal.current.open();
  };

  return (
    <Box>
      <div className={"container mx-auto p-2 h-full"}>
        <div
          className={"flex content-center items-center justify-center h-full"}
        >
          <div className={"w-full lg:w-4/12 px-4"}>
            <div
              className={
                "relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-primary-color border-0"
              }
            >
              <div className={"flex-auto px-4 lg:px-10 py-10 pt-6"}>
                <Typography
                  variant={"h5"}
                  className={"text-white text-center my-3 font-bold"}
                >
                  {"Forgot Password"}
                </Typography>
                <div className={"text-white text-center my-3 font-bold"}>
                  {
                    "Please enter your username, and we'll send you instructions on how to reset your password"
                  }
                </div>
                <form onSubmit={handleSubmit}>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <label
                        className={
                          "block uppercase text-white text-xs font-bold mb-2"
                        }
                        htmlFor={"username"}
                      >
                        {"Username"}
                      </label>
                      <input
                        type={"text"}
                        id={"username"}
                        name={"username"}
                        className={
                          "px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full"
                        }
                        placeholder={"Username"}
                        style={{ transition: "all .15s ease" }}
                        value={values.username}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <p className={"custom-error"}>
                        {touched.username && errors.username}
                      </p>
                    </Grid>
                    <Grid item xs={12}>
                      <button
                        className={
                          "bg-secondary-color text-white active:bg-gray-700 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full"
                        }
                        type={"submit"}
                        disabled={isSubmitting}
                        style={{ transition: "all .15s ease" }}
                      >
                        {"Submit"}
                      </button>
                    </Grid>
                  </Grid>
                </form>
                <div className={"mt-4"}>
                  <a
                    href={"/#"}
                    onClick={handleBackToLogin}
                    className={"flex items-center gap-2 text-white text-sm"}
                  >
                    <ArrowBack style={{ fontSize: "16px" }} />
                    <b>{"Back to login"}</b>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CustomSnack
        open={snack.open}
        color={snack.color}
        message={snack.message}
        onClose={handleSnackClose}
      />
    </Box>
  );
};

export default forwardRef(ForgotPassword);
