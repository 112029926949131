import { FUNNEL } from "../actionTypes";

export const setFunnelState = (values) => ({
  type: FUNNEL.setState,
  payload: values,
});

export const clearFunnelState = () => ({
  type: FUNNEL.clearState,
});
