import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import React from "react";
import YesOrNoSelect from "../../../commonComponents/YesOrNoSelect/YesOrNoSelect";
import { isNumberWithDecimal } from "../../../utils/constants/validation";
import SelectDropDown from "./SelectDropDown";

const RELOCATE_OPTIONS = [
  {
    label: "Yes",
    value: "Yes",
  },
  {
    label: "No",
    value: "No",
  },
  {
    label: "Not applicable",
    value: "Not applicable",
  },
];

const NOTICE_PERIOD_DAYS = [
  { label: "15", value: "15" },
  { label: "30", value: "30" },
  { label: "45", value: "45" },
  { label: "60", value: "60" },
  { label: "90", value: "90" },
];

const EMPLOYMENT_OPTIONS = [
  {
    label: "More Salary",
    value: "More Salary",
  },
  {
    label: "Better career growth",
    value: "Better career growth",
  },
  {
    label: "Project ending",
    value: "Project ending",
  },
  {
    label: "Layoff",
    value: "Layoff",
  },
  {
    label: "Night shift",
    value: "Night shift",
  },
  {
    label: "Other",
    value: "Other",
  },
];

const QuestionContainer = ({ question, children }) => {
  return (
    <Grid item xs={12}>
      <Typography>
        <b>{question}</b>
      </Typography>
      <Box pt={1} pl={2}>
        {children}
      </Box>
    </Grid>
  );
};

function AdditionalDetails({
  values = {},
  touched = {},
  errors = {},
  handleChange = () => {},
  handleBlur = () => {},
  handleDynamicValueChange = () => {},
  handleEmploymentOptionChange = () => {},
}) {
  return (
    <Grid container spacing={2} style={{ rowGap: 5 }}>
      <Grid item xs={12}>
        <Typography variant={"h6"}>
          <b>{"Other Details"}</b>
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant={"body1"}>
          <b>{`To process your application further, let me collect some additional details:`}</b>
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Box padding={"0 15px"}>
          <Grid container spacing={3}>
            <QuestionContainer
              question={
                "1. Have you applied for and/or interviewed with this Organisation’s Jobs in the past 6 months:"
              }
            >
              <Box width={"135px"}>
                <YesOrNoSelect
                  name={"past_experience.answer"}
                  value={values.past_experience?.answer || ""}
                  error={
                    touched.past_experience?.answer &&
                    Boolean(errors.past_experience?.answer)
                  }
                  helperText={
                    touched.past_experience?.answer &&
                    errors.past_experience?.answer
                  }
                  onChange={handleDynamicValueChange}
                  onBlur={handleBlur}
                  fullWidth
                />
              </Box>
              {values.past_experience?.answer === "Yes" ? (
                <Box pt={2}>
                  <TextField
                    label={"Explain"}
                    name={"past_experience.explain"}
                    value={values.past_experience?.explain || ""}
                    error={
                      touched.past_experience?.explain &&
                      Boolean(errors.past_experience?.explain)
                    }
                    helperText={
                      touched.past_experience?.explain &&
                      errors.past_experience?.explain
                    }
                    variant={"outlined"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    multiline
                    minRows={1}
                    fullWidth
                  />
                </Box>
              ) : null}
            </QuestionContainer>
            <QuestionContainer
              question={"2. Where are you currently located (City/State):"}
            >
              <TextField
                name={"currentLocation"}
                value={values.currentLocation || ""}
                error={
                  touched.currentLocation && Boolean(errors.currentLocation)
                }
                helperText={touched.currentLocation && errors.currentLocation}
                onChange={handleChange}
                onBlur={handleBlur}
                fullWidth
              />
            </QuestionContainer>
            <QuestionContainer
              question={"3. Are you willing to relocate:"}
            >
              <Box width={"135px"}>
                <SelectDropDown
                  name={"reLocation"}
                  options={RELOCATE_OPTIONS}
                  value={values.reLocation || ""}
                  error={touched.reLocation && Boolean(errors.reLocation)}
                  errorMessage={touched.reLocation && errors.reLocation}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  fullWidth
                />
              </Box>
            </QuestionContainer>
            <QuestionContainer question={"4. Your work option:"}>
              <Box width={"135px"}>
                <FormControl
                  size={"medium"}
                  variant={"standard"}
                  error={touched.workOption && Boolean(errors.workOption)}
                  fullWidth
                >
                  <Select
                    value={values.workOption || ""}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name={"workOption"}
                  >
                    <MenuItem value={"Onsite"}>Onsite</MenuItem>
                    <MenuItem value={"Hybrid"}>Hybrid</MenuItem>
                  </Select>
                  <FormHelperText>
                    {touched.workOption && errors.workOption}
                  </FormHelperText>
                </FormControl>
              </Box>
            </QuestionContainer>
            <QuestionContainer
              question={
                "5. What is your current salary($) inclusive of all benefits:"
              }
            >
              <TextField
                error={touched.currentCtc && Boolean(errors.currentCtc)}
                helperText={touched.currentCtc && errors.currentCtc}
                name={"currentCtc"}
                value={values.currentCtc || ""}
                onChange={handleChange}
                onBlur={handleBlur}
                // onChange={(e) => {
                //   let { value } = e.target;
                //   let number = Number(value);
                //   if (
                //     (isNumberWithDecimal(value) &&
                //       number >= 0 &&
                //       number <= 100) ||
                //     value.length === 0
                //   )
                //     handleChange(e);
                //   else e.preventDefault();
                // }}
                // onBlur={handleBlur}
                // type={"number"}
                fullWidth
              />
            </QuestionContainer>
            <QuestionContainer
              question={"6. Variable pay if any explain:"}
            >
              <TextField
                error={touched.variablePay && Boolean(errors.variablePay)}
                helperText={touched.variablePay && errors.variablePay}
                name={"variablePay"}
                value={values.variablePay || ""}
                onChange={handleChange}
                onBlur={handleBlur}
                fullWidth
              />
            </QuestionContainer>
            <QuestionContainer
              question={
                "7. What is your expected annual salary($) for the above role:"
              }
            >
              <TextField
                error={touched.annualSalary && Boolean(errors.annualSalary)}
                helperText={touched.annualSalary && errors.annualSalary}
                name={"annualSalary"}
                value={values.annualSalary || ""}
                onChange={handleChange}
                onBlur={handleBlur}
                // type={"number"}
                // onChange={(e) => {
                //   let { value } = e.target;
                //   let number = Number(value);
                //   if (
                //     (isNumberWithDecimal(value) &&
                //       number >= 0 &&
                //       number <= 100) ||
                //     value.length === 0
                //   )
                //     handleChange(e);
                //   else e.preventDefault();
                // }}
                // onBlur={handleBlur}
                fullWidth
              />
            </QuestionContainer>
            <QuestionContainer
              question={
                "8. What is your notice period to join if selected for the above role:"
              }
            >
              <Box width={"135px"}>
                <SelectDropDown
                  name={"currentNoticePeriod"}
                  options={NOTICE_PERIOD_DAYS}
                  value={values.currentNoticePeriod || ""}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={
                    touched.currentNoticePeriod &&
                    Boolean(errors.currentNoticePeriod)
                  }
                  errorMessage={
                    touched.currentNoticePeriod && errors.currentNoticePeriod
                  }
                  adornmentText={"Days"}
                  fullWidth
                />
              </Box>
            </QuestionContainer>
            <QuestionContainer
              question={
                "9. Why are you looking to change your current employment:"
              }
            >
              <Box>
                <FormControl
                  component={"fieldset"}
                  error={
                    touched.employment_change?.answer &&
                    Boolean(errors.employment_change?.answer)
                  }
                >
                  <FormGroup row>
                    {EMPLOYMENT_OPTIONS.map((el) => {
                      return (
                        <FormControlLabel
                          key={el.value}
                          control={
                            <Checkbox
                              checked={values.employment_change?.answer?.includes(
                                el.value
                              )}
                              color={"primary"}
                              name={el.value}
                              onChange={handleEmploymentOptionChange}
                            />
                          }
                          label={el.label}
                        />
                      );
                    })}
                  </FormGroup>
                  <FormHelperText>
                    {touched.employment_change?.answer &&
                      errors.employment_change?.answer}
                  </FormHelperText>
                </FormControl>
              </Box>
              {values.employment_change?.answer?.includes("Other") ? (
                <Box pt={2}>
                  <TextField
                    label={"Explain"}
                    name={"employment_change.explain"}
                    value={values.employment_change?.explain || ""}
                    error={
                      touched.employment_change?.explain &&
                      Boolean(errors.employment_change?.explain)
                    }
                    helperText={
                      touched.employment_change?.explain &&
                      errors.employment_change?.explain
                    }
                    variant={"outlined"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    multiline
                    minRows={1}
                    fullWidth
                  />
                </Box>
              ) : null}
            </QuestionContainer>
            <QuestionContainer
              question={
                "10. This position requires you to complete pre-onboarding formalities like Drug Test, Background Check will you be open for this:"
              }
            >
              <Box width={"135px"}>
                <YesOrNoSelect
                  error={
                    touched.backgroundCheck && Boolean(errors.backgroundCheck)
                  }
                  helperText={touched.backgroundCheck && errors.backgroundCheck}
                  name={"backgroundCheck"}
                  value={values.backgroundCheck || ""}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  fullWidth
                />
              </Box>
            </QuestionContainer>
          </Grid>
        </Box>
      </Grid>
      <Grid item xs={12} />
    </Grid>
  );
}

export default AdditionalDetails;
