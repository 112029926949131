import { ROLES } from "./roleBasedAccess";

const modules = {
  applicants: "applicants",
  qualify: "qualify",
  testModule: "testModule",
  recommended: "recommended",
  clientInterview: "clientInterview",
};

// Feedback levels
const FEEDBACK_LEVELS = {
  funnel: "funnel",
  qualified: "qualified",
  rejected: "rejected",
  movedToTest: "movedToTest",
  testPending: "testPending",
  testCompleted: "testCompleted",
  recommend: "recommend",
  clientInterview: "clientInterview",
  applicant: "applicant"
};

// Feedback related FEEDBACK_RELATED_PAGES
const FEEDBACK_RELATED_PAGES = {
  // Job Posting
  mainLayout: "mainLayout",
  funnelLanding: "funnelLanding",
  qualifyLanding: "qualifyLanding",
  testLanding: "testLanding",
  testScheduling: "testScheduling",
  testEvent: "testEvent",
  testOutcome: "testOutcome",
  recommendLanding: "recommendLanding",
  clientInterviewLanding: "clientInterviewLanding",
  applicantlanding: "applicantlanding",
};

// Feedback level based access
const FEEDBACK_LEVEL_BASED_ACCESS = {
  [FEEDBACK_LEVELS.applicant]: {
    // Job Posting
    [FEEDBACK_RELATED_PAGES.mainLayout]: {
      isAllowModule: (module) =>
        [modules.applicants, modules.qualify].includes(module),
    },
    [FEEDBACK_RELATED_PAGES.applicantlanding]: {
      isReject: (role) => [ROLES.admin, ROLES.clientAdmin].includes(role),
      isCompletionStatus: true,
      isAddResume: true,
      isSendBot: true,
      isUpdateForm: true,
      isChatbotStatus: true,
      isCommonDelete: false,
      isRestoreCandidate: false,
      isModuleStatus: false,
      isEditAccess: (role) => [ROLES.admin, ROLES.clientAdmin, ROLES.recruiter].includes(role),
    }
  },
  [FEEDBACK_LEVELS.funnel]: {
    // Job Posting
    [FEEDBACK_RELATED_PAGES.mainLayout]: {
      isAllowModule: (module) =>
        [modules.applicants, modules.qualify].includes(module),
    },
    // [FEEDBACK_RELATED_PAGES.applicantlanding]: {
    //   isReject: (role) => [ROLES.admin, ROLES.clientAdmin].includes(role),
    //   isCompletionStatus: true,
    //   isAddResume: true,
    //   isSendBot: true,
    //   isUpdateForm: true,
    //   isChatbotStatus: true,
    //   isCommonDelete: false,
    //   isRestoreCandidate: false,
    //   isModuleStatus: false,
    //   isEditAccess: (role) => [ROLES.admin, ROLES.clientAdmin, ROLES.recruiter].includes(role),
    // },
    [FEEDBACK_RELATED_PAGES.funnelLanding]: {
      isReject: (role) => [ROLES.admin, ROLES.clientAdmin].includes(role),
      isCompletionStatus: true,
      isAddResume: true,
      isSendBot: true,
      isUpdateForm: true,
      isChatbotStatus: true,
      isCommonDelete: false,
      isRestoreCandidate: false,
      isModuleStatus: false,
      isEditAccess: () => false,
    },
    [FEEDBACK_RELATED_PAGES.qualifyLanding]: {
      isSendToManager: false,
      isReject: () => false,
      isApprovalStatus: true,
      isModuleStatus: false,
    },
    [FEEDBACK_RELATED_PAGES.testLanding]: {},
    [FEEDBACK_RELATED_PAGES.testScheduling]: {},
    [FEEDBACK_RELATED_PAGES.testEvent]: {},
    [FEEDBACK_RELATED_PAGES.testOutcome]: {
      isRejectCandidate: () => false,
    },
    [FEEDBACK_RELATED_PAGES.recommendLanding]: {
      isRejectCandidate: () => false,
    },
    [FEEDBACK_RELATED_PAGES.clientInterviewLanding]: {
      isRejectCandidate: () => false,
    },
  },
  [FEEDBACK_LEVELS.qualified]: {
    // Job Posting
    [FEEDBACK_RELATED_PAGES.mainLayout]: {
      isAllowModule: (module) =>
        [modules.applicants, modules.qualify].includes(module),
    },
    [FEEDBACK_RELATED_PAGES.funnelLanding]: {
      isReject: () => false,
      isCompletionStatus: true,
      isAddResume: true,
      isSendBot: true,
      isUpdateForm: true,
      isChatbotStatus: true,
      isCommonDelete: false,
      isRestoreCandidate: false,
      isModuleStatus: false,
      isEditAccess: () => false,
    },
    [FEEDBACK_RELATED_PAGES.qualifyLanding]: {
      isSendToManager: true,
      isReject: (role) =>
        [ROLES.admin, ROLES.manager, ROLES.clientAdmin].includes(role),
      isApprovalStatus: true,
      isModuleStatus: false,
    },
    [FEEDBACK_RELATED_PAGES.testLanding]: {},
    [FEEDBACK_RELATED_PAGES.testScheduling]: {},
    [FEEDBACK_RELATED_PAGES.testEvent]: {},
    [FEEDBACK_RELATED_PAGES.testOutcome]: {
      isRejectCandidate: () => false,
    },
    [FEEDBACK_RELATED_PAGES.recommendLanding]: {
      isRejectCandidate: () => false,
    },
    [FEEDBACK_RELATED_PAGES.clientInterviewLanding]: {
      isRejectCandidate: () => false,
    },
  },
  [FEEDBACK_LEVELS.rejected]: {
    // Job Posting
    [FEEDBACK_RELATED_PAGES.mainLayout]: {
      isAllowModule: (module) =>
        [modules.applicants, modules.qualify].includes(module),
    },
    [FEEDBACK_RELATED_PAGES.funnelLanding]: {
      isReject: () => false,
      isCompletionStatus: false,
      isAddResume: false,
      isSendBot: false,
      isUpdateForm: false,
      isChatbotStatus: false,
      isCommonDelete: true,
      isRestoreCandidate: true,
      isModuleStatus: true,
      isEditAccess: (role) => [ROLES.admin, ROLES.clientAdmin].includes(role),
    },
    [FEEDBACK_RELATED_PAGES.qualifyLanding]: {
      isSendToManager: false,
      isReject: () => false,
      isApprovalStatus: false,
      isModuleStatus: true,
    },
    [FEEDBACK_RELATED_PAGES.testLanding]: {},
    [FEEDBACK_RELATED_PAGES.testScheduling]: {},
    [FEEDBACK_RELATED_PAGES.testEvent]: {},
    [FEEDBACK_RELATED_PAGES.testOutcome]: {
      isRejectCandidate: () => false,
    },
    [FEEDBACK_RELATED_PAGES.recommendLanding]: {
      isRejectCandidate: () => false,
    },
    [FEEDBACK_RELATED_PAGES.clientInterviewLanding]: {
      isRejectCandidate: () => false,
    },
  },
  [FEEDBACK_LEVELS.movedToTest]: {
    // Job Posting
    [FEEDBACK_RELATED_PAGES.mainLayout]: {
      isAllowModule: (module) =>
        [modules.applicants, modules.qualify, modules.testModule].includes(
          module
        ),
    },
    [FEEDBACK_RELATED_PAGES.funnelLanding]: {
      isReject: () => false,
      isCompletionStatus: true,
      isAddResume: true,
      isSendBot: true,
      isUpdateForm: true,
      isChatbotStatus: true,
      isCommonDelete: false,
      isRestoreCandidate: false,
      isModuleStatus: false,
      isEditAccess: () => false,
    },
    [FEEDBACK_RELATED_PAGES.qualifyLanding]: {
      isSendToManager: true,
      isReject: () => false,
      isApprovalStatus: true,
      isModuleStatus: false,
    },
    [FEEDBACK_RELATED_PAGES.testLanding]: {},
    [FEEDBACK_RELATED_PAGES.testScheduling]: {},
    [FEEDBACK_RELATED_PAGES.testEvent]: {},
    [FEEDBACK_RELATED_PAGES.testOutcome]: {
      isRejectCandidate: (role) =>
        [ROLES.admin, ROLES.manager, ROLES.clientAdmin].includes(role),
    },
    [FEEDBACK_RELATED_PAGES.recommendLanding]: {
      isRejectCandidate: () => false,
    },
    [FEEDBACK_RELATED_PAGES.clientInterviewLanding]: {
      isRejectCandidate: () => false,
    },
  },
  [FEEDBACK_LEVELS.testPending]: {
    // Job Posting
    [FEEDBACK_RELATED_PAGES.mainLayout]: {
      isAllowModule: (module) =>
        [modules.applicants, modules.qualify, modules.testModule].includes(
          module
        ),
    },
    [FEEDBACK_RELATED_PAGES.funnelLanding]: {
      isReject: () => false,
      isCompletionStatus: true,
      isAddResume: true,
      isSendBot: true,
      isUpdateForm: true,
      isChatbotStatus: true,
      isCommonDelete: false,
      isRestoreCandidate: false,
      isModuleStatus: false,
      isEditAccess: () => false,
    },
    [FEEDBACK_RELATED_PAGES.qualifyLanding]: {
      isSendToManager: true,
      isReject: () => false,
      isApprovalStatus: true,
      isModuleStatus: false,
    },
    [FEEDBACK_RELATED_PAGES.testLanding]: {},
    [FEEDBACK_RELATED_PAGES.testScheduling]: {},
    [FEEDBACK_RELATED_PAGES.testEvent]: {},
    [FEEDBACK_RELATED_PAGES.testOutcome]: {
      isRejectCandidate: (role) =>
        [ROLES.admin, ROLES.manager, ROLES.clientAdmin].includes(role),
    },
    [FEEDBACK_RELATED_PAGES.recommendLanding]: {
      isRejectCandidate: () => false,
    },
    [FEEDBACK_RELATED_PAGES.clientInterviewLanding]: {
      isRejectCandidate: () => false,
    },
  },
  [FEEDBACK_LEVELS.testCompleted]: {
    // Job Posting
    [FEEDBACK_RELATED_PAGES.mainLayout]: {
      isAllowModule: (module) =>
        [modules.applicants, modules.qualify, modules.testModule].includes(
          module
        ),
    },
    [FEEDBACK_RELATED_PAGES.funnelLanding]: {
      isReject: () => false,
      isCompletionStatus: true,
      isAddResume: true,
      isSendBot: true,
      isUpdateForm: true,
      isChatbotStatus: true,
      isCommonDelete: false,
      isRestoreCandidate: false,
      isModuleStatus: false,
      isEditAccess: () => false,
    },
    [FEEDBACK_RELATED_PAGES.qualifyLanding]: {
      isSendToManager: true,
      isReject: () => false,
      isApprovalStatus: true,
      isModuleStatus: false,
    },
    [FEEDBACK_RELATED_PAGES.testLanding]: {},
    [FEEDBACK_RELATED_PAGES.testScheduling]: {},
    [FEEDBACK_RELATED_PAGES.testEvent]: {},
    [FEEDBACK_RELATED_PAGES.testOutcome]: {
      isRejectCandidate: (role) =>
        [ROLES.admin, ROLES.manager, ROLES.clientAdmin].includes(role),
    },
    [FEEDBACK_RELATED_PAGES.recommendLanding]: {
      isRejectCandidate: () => false,
    },
    [FEEDBACK_RELATED_PAGES.clientInterviewLanding]: {
      isRejectCandidate: () => false,
    },
  },
  [FEEDBACK_LEVELS.recommend]: {
    // Job Posting
    [FEEDBACK_RELATED_PAGES.mainLayout]: {
      isAllowModule: (module) =>
        [
          modules.applicants,
          modules.qualify,
          modules.testModule,
          modules.recommended,
        ].includes(module),
    },
    [FEEDBACK_RELATED_PAGES.funnelLanding]: {
      isReject: () => false,
      isCompletionStatus: true,
      isAddResume: true,
      isSendBot: true,
      isUpdateForm: true,
      isChatbotStatus: true,
      isCommonDelete: false,
      isRestoreCandidate: false,
      isModuleStatus: false,
      isEditAccess: () => false,
    },
    [FEEDBACK_RELATED_PAGES.qualifyLanding]: {
      isSendToManager: true,
      isReject: () => false,
      isApprovalStatus: true,
      isModuleStatus: false,
    },
    [FEEDBACK_RELATED_PAGES.testLanding]: {},
    [FEEDBACK_RELATED_PAGES.testScheduling]: {},
    [FEEDBACK_RELATED_PAGES.testEvent]: {},
    [FEEDBACK_RELATED_PAGES.testOutcome]: {
      isRejectCandidate: () => false,
    },
    [FEEDBACK_RELATED_PAGES.recommendLanding]: {
      isRejectCandidate: (role) =>
        [ROLES.admin, ROLES.manager, ROLES.clientAdmin].includes(role),
    },
    [FEEDBACK_RELATED_PAGES.clientInterviewLanding]: {
      isRejectCandidate: () => false,
    },
  },
  [FEEDBACK_LEVELS.clientInterview]: {
    // Job Posting
    [FEEDBACK_RELATED_PAGES.mainLayout]: {
      isAllowModule: (module) => [modules.clientInterview].includes(module),
    },
    [FEEDBACK_RELATED_PAGES.funnelLanding]: {
      isReject: () => false,
      isCompletionStatus: true,
      isAddResume: true,
      isSendBot: true,
      isUpdateForm: true,
      isChatbotStatus: true,
      isCommonDelete: false,
      isRestoreCandidate: false,
      isModuleStatus: false,
      isEditAccess: () => false,
    },
    [FEEDBACK_RELATED_PAGES.qualifyLanding]: {
      isSendToManager: true,
      isReject: () => false,
      isApprovalStatus: true,
      isModuleStatus: false,
    },
    [FEEDBACK_RELATED_PAGES.testLanding]: {},
    [FEEDBACK_RELATED_PAGES.testScheduling]: {},
    [FEEDBACK_RELATED_PAGES.testEvent]: {},
    [FEEDBACK_RELATED_PAGES.testOutcome]: {
      isRejectCandidate: () => false,
    },
    [FEEDBACK_RELATED_PAGES.recommendLanding]: {
      isRejectCandidate: () => false,
    },
    [FEEDBACK_RELATED_PAGES.clientInterviewLanding]: {
      isRejectCandidate: (role) =>
        [ROLES.admin, ROLES.manager, ROLES.clientAdmin].includes(role),
    },
  },
};

// Get feedback level based access
const getFeedbackLevelBasedAccess = (feedbackLevel, pageName) => {
  switch (feedbackLevel) {
    case FEEDBACK_LEVELS.funnel:
    case FEEDBACK_LEVELS.qualified:
    case FEEDBACK_LEVELS.rejected:
    case FEEDBACK_LEVELS.movedToTest:
    case FEEDBACK_LEVELS.testPending:
    case FEEDBACK_LEVELS.testCompleted:
    case FEEDBACK_LEVELS.recommend:
    case FEEDBACK_LEVELS.clientInterview:
    case FEEDBACK_LEVELS.applicant:
      return FEEDBACK_LEVEL_BASED_ACCESS[feedbackLevel][pageName] || {};
    default:
      return {};
  }
};

export const FEEDBACK_LEVEL_TEXT = {
  [FEEDBACK_LEVELS.funnel]: "Funnel",
  [FEEDBACK_LEVELS.qualified]: "Qualified",
  [FEEDBACK_LEVELS.rejected]: "Rejected",
  [FEEDBACK_LEVELS.movedToTest]: "Moved To Test",
  [FEEDBACK_LEVELS.testPending]: "Test Pending",
  [FEEDBACK_LEVELS.testCompleted]: "Test Completed",
  [FEEDBACK_LEVELS.recommend]: "Recommend",
  [FEEDBACK_LEVELS.clientInterview]: "Client Interview",
  [FEEDBACK_LEVELS.applicant]: "Applicants",
};

export {
  FEEDBACK_LEVELS,
  FEEDBACK_RELATED_PAGES,
  FEEDBACK_LEVEL_BASED_ACCESS,
  getFeedbackLevelBasedAccess,
};
