import {
  Box,
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@material-ui/core";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { getInterviewApplicationSession } from "../../api/api";
import {
  ROLE_BASED_PAGES,
  getRoleBasedAccess,
} from "../../utils/constants/roleBasedAccess";
import { navigateTo } from "../../utils/constants/routePaths";
import ChatbotAndFormLink from "./Scheduling/ChatbotAndFormLink";
import Phone from "./Scheduling/Phone";

const SCHEDULE_TYPE = {
  chatBot: "chat-bot",
  formLink: "form-link",
  phone: "phone",
};

const TITLE = {
  [SCHEDULE_TYPE.chatBot]: "Chat-Bot",
  [SCHEDULE_TYPE.formLink]: "Form Link",
  [SCHEDULE_TYPE.phone]: "Phone",
};

const LISTS = [
  {
    label: TITLE[SCHEDULE_TYPE.chatBot],
    value: SCHEDULE_TYPE.chatBot,
  },
  {
    label: TITLE[SCHEDULE_TYPE.formLink],
    value: SCHEDULE_TYPE.formLink,
  },
  {
    label: TITLE[SCHEDULE_TYPE.phone],
    value: SCHEDULE_TYPE.phone,
  },
];

function Scheduling() {
  const { jobCode, applicantId, applicant_session, type } = useParams();
  const {
    replace,
    location: { hash, pathname },
  } = useHistory();
  const role = localStorage.getItem("role");
  const { isAccessibleActionButton = true } = getRoleBasedAccess(
    role,
    ROLE_BASED_PAGES.interviewScheduling
  );
  const isSubmitted = Boolean(hash?.includes("submitted"));
  const activeType = LISTS.some((el) => el.value === type)
    ? type
    : SCHEDULE_TYPE.chatBot;
  const title = TITLE[activeType];

  const [applicantSessionData, setApplicantSessionData] = useState(null);

  const getAndSetApplicantSessionData = () => {
    axios
      .get(getInterviewApplicationSession(applicant_session))
      .then((response) => {
        if (response.status === 200) {
          setApplicantSessionData(response.data);
        }
      });
  };

  useEffect(() => {
    getAndSetApplicantSessionData();
  }, []);

  const handleMenuClick = (val) => {
    if (val !== activeType) {
      if (pathname.includes("recommended")) {
        replace(
          navigateTo.recommendedInterviewScheduling(
            jobCode,
            applicantId,
            applicant_session,
            val
          )
        );
      } else {
        replace(
          navigateTo.clientInterviewScheduling(
            jobCode,
            applicantId,
            applicant_session,
            val
          )
        );
      }
    }
  };

  const renderContent = () => {
    switch (activeType) {
      case SCHEDULE_TYPE.formLink:
        return (
          <ChatbotAndFormLink
            getAndSetApplicantSessionData={getAndSetApplicantSessionData}
            sessionData={applicantSessionData}
            buttonLabel={"Send Form Link"}
            isAccessibleActionButton={isAccessibleActionButton}
          />
        );
      case SCHEDULE_TYPE.phone:
        return <Phone isAccessibleActionButton={isAccessibleActionButton} />;
      default:
        return (
          <ChatbotAndFormLink
            getAndSetApplicantSessionData={getAndSetApplicantSessionData}
            sessionData={applicantSessionData}
            buttonLabel={"Send Bot"}
            isAccessibleActionButton={isAccessibleActionButton}
            isSendBot
          />
        );
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={4}>
        <div className={"flex flex-col rounded shadow p-5 min-h-full gap-4"}>
          <Typography className={"pl-1"}>
            <b>{"Interview Schedule"}</b>
          </Typography>
          <List component={"nav"} className={"flex flex-col gap-4"}>
            {LISTS.map(({ label, value }) => (
              <ListItem
                onClick={() => handleMenuClick(value)}
                selected={activeType === value}
                key={value}
                disabled={value !== SCHEDULE_TYPE.phone && isSubmitted}
                button
              >
                <ListItemText primary={label} />
              </ListItem>
            ))}
          </List>
        </div>
      </Grid>
      <Grid item xs={8}>
        <Box className={"rounded shadow p-6"} minHeight={"54vh"}>
          <Typography className={"headingText"}>
            <b>{title}</b>
          </Typography>
          <Box pt={2}>{renderContent()}</Box>
        </Box>
      </Grid>
    </Grid>
  );
}

export default Scheduling;
