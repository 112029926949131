import { TEST } from "../actionTypes";

export const setTestState = (values) => ({
  type: TEST.setState,
  payload: values,
});

export const clearTestState = () => ({
  type: TEST.clearState,
});
