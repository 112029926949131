import React, { Component } from "react";
import { Col, Row } from "react-materialize";

import SkillMatchResumeHeaderReport from "./SkillMatchResumeHeaderReport";
// import SkillMatchSkillHeaderReport  from './SkillMatchSkillHeaderReport';
import TitleMatchReport from "./TitleMatchReport";

import moment from "moment";
import "./style.css";
class CustomMatchReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isClicked: false,
      isSkillClicked: false,
    };
  }

  render() {
    const { customMatchResultSkillTableValues, userNameList, inferred_title } =
      this.props;

    var farray = [];
    farray.push(customMatchResultSkillTableValues[0]);

    console.log(farray, "Farray---------------------");
    console.log(inferred_title, "+++++++++++++");
    return (
      <>
        <h5 className=" col s12 valign-wrapper">
          <span> Custom Match Reports</span>
        </h5>

        {farray.map((skillTableValue, index) => (
          <Row key={index} className="uploadResumeResults valign-wrapper">
            <Col s={12}>
              <li
                key={index}
                className="active"
                style={{ listStyleType: "none" }}
              >
                <div className="collapsible-header valign-wrapper">
                  <Col s={6} l={6} className="pull-s4">
                    <span className="hide-on-med-and-down">
                      Custom Match Report For
                    </span>
                    {skillTableValue.job_id && (
                      <span className="boldPara">
                        {" "}
                        Job id : {skillTableValue.job_id} , &nbsp;
                      </span>
                    )}
                    {skillTableValue.title.values &&
                      skillTableValue.title.values.length > 0 && (
                        <span className="boldPara">
                          {" "}
                          Title : {skillTableValue.title.values.join(", ")}
                        </span>
                      )}
                    {skillTableValue.title.values &&
                      skillTableValue.title.values.length > 0 &&
                      skillTableValue.skill.values &&
                      skillTableValue.skill.values.length > 0 && (
                        <span className="generalText">&nbsp;and</span>
                      )}
                    {skillTableValue.skill.values &&
                      skillTableValue.skill.values.length > 0 && (
                        <span className="boldPara">
                          {" "}
                          Skill : {skillTableValue.skill.values.join(", ")}
                        </span>
                      )}
                    <br />
                    <span className="timeStampDisplay">
                      &nbsp;Created At:&nbsp;
                      {/* {skillTableValue.timeStamp.substr(0,20)}  */}
                      {moment(
                        skillTableValue.timeStamp.substr(0, 20),
                        "YYYY-MM-DD HH:mm:ss.SSSSSSZ"
                      ).format("DD MMM YYYY HH:mm:ss")}
                    </span>
                  </Col>
                  <Col
                    s={6}
                    l={6}
                    className={"flex-1 flex justify-end mr-1 buttonDiv"}
                  >
                    {skillTableValue.titleMatchResumeList.length > 0 ? (
                      <button
                        disabled={this.state.isClicked}
                        onClick={() =>
                          this.setState({ isClicked: !this.state.isClicked })
                        }
                        className={
                          this.state.isClicked
                            ? "disabledDownloadButton"
                            : "downloadButton"
                        }
                      >
                        Export
                      </button>
                    ) : null}
                  </Col>
                </div>
                <div className="collapsible-body customMatchReportTable">
                  <hr />
                  {skillTableValue.title.values &&
                    skillTableValue.title.values.length > 0 &&
                    skillTableValue.titleMatchResumeList.length > 0 && (
                      <>
                        <p className="sideNavHeader">
                          {" "}
                          Title : {skillTableValue.title.values}
                        </p>
                        <br />

                        <TitleMatchReport
                          isClicked={this.state.isClicked}
                          inferred_title={inferred_title}
                          title={skillTableValue.title.values}
                          userNameList={userNameList}
                          titleMatchResumeList={
                            skillTableValue.titleMatchResumeList
                          }
                          resumeNames={skillTableValue.resumeNames}
                        />
                        <br />
                      </>
                    )}
                  {skillTableValue.skill.values &&
                    skillTableValue.skill.values.length > 0 &&
                    Object.keys(skillTableValue.skillMatchSkillObj).length >
                      0 && (
                      <>
                        <div className="flex relative justify-center items-center">
                          <p className="sideNavHeader flex-1">
                            {" "}
                            Skill : {skillTableValue.skill.values.join(", ")}
                          </p>
                          <button
                            disabled={this.state.isSkillClicked}
                            onClick={() =>
                              this.setState({
                                isSkillClicked: !this.state.isSkillClicked,
                              })
                            }
                            className={`absolute right-0 ${
                              this.state.isSkillClicked
                                ? "disabledDownloadButtonNoMt"
                                : "downloadButtonNoMt"
                            }`}
                          >
                            Export
                          </button>
                        </div>
                        <br />
                        <SkillMatchResumeHeaderReport
                          skillMatchSkillObj={
                            skillTableValue.skillMatchSkillObj
                          }
                          skillValues={skillTableValue.skill.values}
                          resumeNames={skillTableValue.resumeNames}
                          isSkillClicked={this.state.isSkillClicked}
                        />
                        <br />
                      </>
                    )}
                </div>
              </li>
            </Col>
          </Row>
        ))}
        <hr />
      </>
    );
  }
}
export default CustomMatchReport;
