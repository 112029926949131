import { yupResolver } from "@hookform/resolvers/yup";
import AOS from "aos";
import React, { useEffect, useRef, useState } from "react";
import { useAlert } from "react-alert";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useParams,
  useLocation,
} from "react-router-dom";
import * as yup from "yup";

// import styles
import "aos/dist/aos.css";
import "./styles/style.css";
import "./styles/utilities.css";

// import images
import undraw_Analytics_re_dkf8 from "./images/svgs/undraw_Analytics_re_dkf8.svg";
import undraw_Invest_re_8jl5 from "./images/svgs/undraw_Invest_re_8jl5.svg";
import undraw_Select_re_3kbd from "./images/svgs/undraw_Select_re_3kbd.svg";

// import components
import IntakeForm from "./components/IntakeForm/IntakeForm";
import CreateAccount from "./components/Login/CreateAccount";
import Login from "./components/Login/Login";
import Modal from "./components/Modal";
import Pricing from "./components/Pricing/Pricing";

// Redux
import { userLoginStatus } from "./redux/actions/actionCreator";

// API
import axios from "axios";
import { API_BASE_URL, contactUs_API } from "./api/api";

// auth service
import ChatForm from "./components/ChatForm/ChatForm";
import CoverSheetChatForm from "./components/ChatForm/CoverSheetChartForm";
import ClientCoverSheetForm from "./components/ClientForm/ClientCoverSheetForm";
import ClientForm from "./components/ClientForm/ClientForm";
import Dashboard from "./components/Dashboard/Dashboard";
import Qualify from "./components/Dashboard/Qualify";
import AddIntakeForm from "./components/IntakeForm/AddIntakeForm";
import AuthService from "./components/Login/AuthService";
import NavCards from "./components/NavCards/NavCards";
import Recommendations from "./components/Recommendations/Recommendations";
import ClientInterview from "./components/ClientInterview/Index";
import RestPassword from "./components/Login/ResetPassword";
import {
  ROLE_BASED_PAGES,
  getRoleBasedAccess,
} from "./utils/constants/roleBasedAccess";
import { clearIntakeState } from "./redux/actions/intakeForm";
import { clearFeedbackDashboardState } from "./redux/actions/feedbackDashboard";
import { clearFunnelState } from "./redux/actions/funnel";
import { clearQualifyState } from "./redux/actions/qualify";
import { clearTestState } from "./redux/actions/test";
import { clearRecommendState } from "./redux/actions/recommend";
import { clearClientInterviewState } from "./redux/actions/clientInterview";
import ForgotPassword from "./components/Login/ForgotPassword";
import ApplicantHistory from "./components/Applicants/ApplicantHistory";

import ClientInterviewScheduleForm from "./components/Recommendations/Scheduling/ClientInterviewScheduleForm";
import ClientInterviewScheduleChatBot from "./components/Recommendations/Scheduling/ClientInterviewScheduleChatBot";
import GrammarChecker from "./components/Applicants/GrammarChecker";
import FunnelApplicants from "./components/Funnel/Index";
import FunnelApplicantHistory from "./components/Funnel/ApplicantHistory";
import FunnelGrammarChecker from "./components/Funnel/GrammarChecker";

AOS.init();

axios.defaults.baseURL = API_BASE_URL;

const App = () => {
  // Ref for modals
  const signInModal = useRef(null);
  const pricingModal = useRef(null);
  const createAccountModal = useRef(null);
  const forgotPasswordModal = useRef(null);

  // Ref for navigation to modules on click
  const jumpToModules = useRef(null);
  const executeScroll = () =>
    jumpToModules.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });

  // User login status
  const userLoginStatusVal = useSelector((state) => state.loginStatus);

  // dispatch login status to redux
  const dispatch = useDispatch();

  // use alert hook
  const alert = useAlert();

  const history = useHistory();
  const location = useLocation();
  const params = useParams();
  const role = localStorage.getItem("role");
  const {
    isSkillMasterLandingPageAllow = true,
    isPreferredCompanyLandingPageAllow = true,
    isSettingsLandingPageAllow = true,
    isReportsLandingPageAllow = true,
    isHierarchyLandingPageAllow = true,
  } = getRoleBasedAccess(role, ROLE_BASED_PAGES.mainLayout);

  useEffect(() => {
    if (!history.location.pathname.startsWith("/client")) {
      // **** FOR AUTHSERVICE ****
      if (!userLoginStatusVal.login) {
        const authStatus = async () => {
          var resp = await AuthService();

          if (resp.code === 200) {
            dispatch(
              userLoginStatus({
                code: resp.code,
                login: true,
                msg: resp.msg,
              })
            );
          }
        };
        authStatus();
      }
    } else {
      dispatch(
        userLoginStatus({
          code: 200,
          login: true,
          msg: "Login success",
        })
      );
    }

    // eslint-disable-next-line
  }, [userLoginStatusVal.login]);

  const handleSkillMasterClick = () => {
    setCurrentPage("skillMaster");
    history.push("/home/skillMaster");
  };
  const handleIntakeFormClick = () => {
    setCurrentPage("intake");
    history.push("/intake/form");
  };
  const handleApplicantClick = () => {
    setCurrentPage("applicants");
    history.push("/home/applicants");
  };

  const handleJobDetailClick = () => {
    setCurrentPage("jobDetail");
    history.push("/home/jobDetail");
  };

  const handleCompetitorCompanyClick = () => {
    setCurrentPage("competitorCompany");
    history.push("/home/competitorCompany");
  };

  const handleSettingsClick = () => {
    setCurrentPage("settings");
    history.push("/home/settings");
  };

  const handleHierarchyClick = () => {
    setCurrentPage("hierarchy");
    history.push("/home/hierarchy/skill-hierarchy");
  };

  const handleReportClick = () => {
    setCurrentPage("reports");
    history.push("/home/reports");
  };

  // Contact us values
  const [didSubmit] = useState(false);
  const [currentPage, setCurrentPage] = useState("feedbackDashboard");
  // Define form schema
  const schema = yup.object().shape({
    name: yup.string().required().min(3).max(30),
    company: yup.string(),
    email: yup
      .string()
      .email("Invalid email address")
      .required()
      .min(3)
      .max(30),
    comment: yup.string().required().min(10).max(100),
  });

  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema),
  });

  // Contact Us
  const contactUS = (data) => {
    // call endpoint
    axios
      .post(contactUs_API, data)
      .then((response) => {
        //console.log(data);

        alert.show("Request has been sent successfully", {
          header: "Info",
          ctype: "info",
        });

        document.getElementById("contactUS").reset();
        //setDidSubmit(true);
      })
      .catch(() => {
        //console.log(data);
        //setDidSubmit(true); // remove this
      });
  };

  const getPremium = () => {
    const authStatus = async () => {
      var resp = await AuthService();
      if (resp.code === 200) {
        pricingModal.current.open();
      } else if (resp.code !== 200) {
        alert.show("Please signin to purchase premium features.", {
          header: "Info",
          ctype: "info",
        });
        if (resp.code === 404) {
          dispatch(
            userLoginStatus({
              code: 0,
              login: false,
              msg: "User logged out!",
            })
          );
        }
      }
    };
    authStatus();
  };

  const Logoutuser = () => {
    localStorage.clear();
    dispatch(
      userLoginStatus({
        code: 0,
        login: false,
        msg: "User logged out!",
      })
    );
    /*alert.show(
    "Your have been successfully logged out of the system",
    {
      header: "Logged Out!",
      ctype: "info",
    }
  );*/
    history.push("/intake/form");
    window.location.reload();
  };

  const handleFeedbackDashboardClick = () => {
    setCurrentPage("feedbackDashboard");
    history.push("/home/feedbackDashboard");
  };

  const findIsClient = () => {
    const { pathname } = history.location;
    return pathname.includes("yes");
  };

  //console.log(userLoginStatusVal);

  //console.log(userLoginStatusVal.code);

  const navItems = [
    {
      id: "intake",
      name: "Intake Form",
      onClick: handleIntakeFormClick,
      isShow: true,
    },
    {
      id: "applicants",
      name: "Applicants",
      onClick: handleApplicantClick,
      isShow: true,
    },
    {
      id: "feedbackDashboard",
      name: "Job Posting",
      onClick: handleFeedbackDashboardClick,
      isShow: true,
    },
    {
      id: "skillMaster",
      name: "Skill Master",
      onClick: handleSkillMasterClick,
      isShow: isSkillMasterLandingPageAllow,
    },
    // {
    //   id: "competitorCompany",
    //   name: "Preferred Company",
    //   onClick: handleCompetitorCompanyClick,
    //   isShow: isPreferredCompanyLandingPageAllow,
    // },
    {
      id: "hierarchy",
      name: "Hierarchy",
      onClick: handleHierarchyClick,
      isShow: isHierarchyLandingPageAllow,
    },
    {
      id: "reports",
      name: "Reports",
      onClick: handleReportClick,
      isShow: isReportsLandingPageAllow,
    },
    {
      id: "settings",
      name: "Settings",
      onClick: handleSettingsClick,
      isShow: isSettingsLandingPageAllow,
    },
  ];

  useEffect(() => {
    const { pathname } = history.location;
    let findedPath = "feedbackDashboard";

    navItems.map((el, ind) => {
      if (pathname.includes(el.id)) {
        findedPath = el.id;
      }
    });
    setCurrentPage(findedPath);
  }, []);

  useEffect(() => {
    // Clear intake form reducer state for the pathname not related the intake flow
    if (!location.pathname.includes("/intake")) {
      dispatch(clearIntakeState());
    }

    // Clear feedback dashboard reducer state for the pathname not related the feedback dashboard flow
    if (
      !location.pathname.includes("/dashboard") &&
      !location.pathname.includes("/coversheetform") &&
      !location.pathname.includes("/applicant-history") &&
      !location.pathname.includes("/grammar-checker")
    ) {
      if (!location.pathname.includes("/feedbackDashboard")) {
        dispatch(clearFeedbackDashboardState());
      }
      dispatch(clearFunnelState());
      dispatch(clearQualifyState());
      dispatch(clearTestState());
      dispatch(clearRecommendState());
      dispatch(clearClientInterviewState());
    }
  }, [location.pathname]);

  if (userLoginStatusVal.code !== 200) {
    return (
      <>
        {/*-- Navbar -->*/}
        <div className="c-navbar sticky top-0 z-10">
          <div className="c-container-log-out c-flex">
            <h1>
              <a href={findIsClient() ? "#" : "/home"}>Talentium - X</a>
            </h1>

            <ul>
              <li>
                {userLoginStatusVal.login ? (
                  <button
                    className="text-white text-lg px-4 rounded outline-none focus:outline-none hover:bg-white hover:text-gray-700 hover:shadow-sm inline-flex items-center"
                    type="button"
                    onClick={() => {
                      Logoutuser();
                    }}
                  >
                    <b>Log Out</b>
                  </button>
                ) : (
                  <button
                    className="text-white text-lg px-4 rounded outline-none focus:outline-none hover:bg-white hover:text-gray-700 hover:shadow-sm inline-flex items-center"
                    type="button"
                    onClick={() => {
                      dispatch(
                        userLoginStatus({
                          code: 0,
                          login: false,
                          msg: "User logged out!",
                        })
                      );
                      signInModal.current.open();
                    }}
                  >
                    Sign In
                  </button>
                )}
              </li>
            </ul>
          </div>
        </div>

        {/*<!-- Showcase -->*/}
        <section className="c-showcase flex-grow">
          <div className="c-container c-grid">
            <div className="c-showcase-text">
              <h1>Talent Analyzer</h1>
              <p>
                Harnesses the power of AI/ML to enable stakeholders to use a
                single source of truth, which can help streamline analytical
                models for more informed decision-making.
              </p>
              <a
                href="/#"
                className="c-btn c-btn-secondary"
                onClick={(e) => {
                  e.preventDefault();
                  executeScroll();
                }}
              >
                Try For Free
              </a>
            </div>
            <div className="relative">
              <div className="absolute c-showcase-form c-card md:top-12 sm:top-24 h-full inset-0 bg-gradient-to-r from-blue-300 to-secondary-color shadow-lg transform -skew-y-6 sm:skew-y-0 sm:-rotate-6 sm:rounded-xl"></div>
              <div className="c-showcase-form c-card">
                <h2 className="c-lead">Request a Demo</h2>
                <form id="contactUS" onSubmit={handleSubmit(contactUS)}>
                  <div className="c-form-control">
                    <input
                      type="text"
                      name="name"
                      placeholder="Name *"
                      required
                      ref={register}
                    />
                    <small className="text-red-500">
                      {errors.name?.message}
                    </small>
                  </div>
                  <div className="c-form-control">
                    <input
                      type="text"
                      name="company"
                      placeholder="Company"
                      ref={register}
                    />
                  </div>
                  <div className="c-form-control">
                    <input
                      type="email"
                      name="email"
                      placeholder="Email *"
                      required
                      ref={register}
                    />
                    <small className="text-red-500">
                      {errors.email?.message}
                    </small>
                  </div>
                  <div className="c-form-control">
                    <input
                      type="text"
                      name="comment"
                      placeholder="How can we help? *"
                      required
                      ref={register}
                    />
                    <small className="text-red-500">
                      {errors.comment?.message}
                    </small>
                  </div>
                  {didSubmit ? (
                    <>
                      <button
                        type="button"
                        className="c-btn c-btn-primary"
                        disabled
                      >
                        <span style={{ color: "white" }}>
                          <i className="fas fa-check"></i>
                        </span>
                      </button>
                    </>
                  ) : (
                    <input
                      type="submit"
                      value="Send"
                      className="c-btn c-btn-primary"
                    />
                  )}
                </form>
              </div>
            </div>
          </div>
        </section>

        <section className="c-stats">
          <div className="c-container">
            <h3 className="c-stats-heading c-lead c-text-center c-my-1">
              Leverage our patent-pending AI accelerator and digital
              transformation enabler.
            </h3>

            <div className="c-grid c-grid-3 c-text-center c-my-4">
              <div>
                <img
                  className="c-card c-svg-img"
                  src={undraw_Analytics_re_dkf8}
                  alt=""
                />
                <p className=" c-text-secondary">Analyze Documents</p>
              </div>
              <div>
                <img
                  className="c-card c-svg-img"
                  src={undraw_Select_re_3kbd}
                  alt=""
                />
                <p className="c-text-secondary">Compare & Select</p>
              </div>
              <div>
                <img
                  className="c-card c-svg-img"
                  src={undraw_Invest_re_8jl5}
                  alt=""
                />
                <p className="c-text-secondary">Generate Value Stream</p>
              </div>
            </div>
          </div>
        </section>

        {/* <section className="c-cloud c-bg-primary c-my-2 c-py-2">
          <div className="c-container c-grid">
            <div className="c-text-center">
              <h2 className="c-lg">Intelligent Document Engine</h2>
              <p className="c-lead c-my-1">
                Scanning and comparing resumes made possible. Fast, efficient
                and scalable
              </p>
              <a
                href="/#"
                className="c-btn c-btn-secondary"
                onClick={(e) => {
                  e.preventDefault();
                  //getPremium();
                  pricingModal.current.open();
                }}
              >
                Purchase Premium
              </a>
              <p className="c-lead c-my-1">
                Premium service provides unlimited usage!
              </p>
            </div>
            <img src={undraw_Done_checking_re_6vyx} alt="" />
          </div>
        </section> */}

        {/*<!-- Tool Options -->*/}
        {/* <section className="c-tool">
          <p className="c-sm c-text-center c-my-1">
            Click one of the below options
          </p>
 
          <div className="c-container c-flex">
          <Link to="/home/skillMaster">
          <div className="c-card" data-aos="flip-left">
            <h4>Skills Master</h4>
            <img
              className="c-svg-icons"
              src={undraw_File_searching_re_3evy}
              alt=""
            />
          </div>
        </Link>
            <Link to="/home/extractSkills">
              <div className="c-card" data-aos="flip-left">
                <h4>Extract Skills</h4>
                <img
                  className="c-svg-icons"
                  src={undraw_File_searching_re_3evy}
                  alt=""
                />
              </div>
            </Link>
            <Link to="/home/parseResume">
              <div className="c-card" data-aos="flip-left">
                <h4>Resume Parser</h4>
                <img
                  className="c-svg-icons"
                  src={undraw_updated_resume_u4fy}
                  alt=""
                />
              </div>
            </Link>
            <Link to="/home/compareResume">
              <div className="c-card" data-aos="flip-right">
                <h4>Resume Compare</h4>
                <img
                  className="c-svg-icons"
                  src={undraw_contrast_vb82}
                  alt=""
                />
              </div>
            </Link>
            <Link to="/home/candidateLink">
              <div className="c-card" data-aos="flip-right">
                <h4>Candidate Link</h4>
                <img
                  className="c-svg-icons"
                  src={undraw_social_user_lff0}
                  alt=""
                />
              </div>
            </Link>
          </div>
        </section> */}

        {/*<!-- Modules -->*/}
        {/* <div
          ref={jumpToModules}
          data-aos="zoom-in"
          className="flex justify-center"
        >
          <Route path="/" component={App}>
            <Switch>
              <Route path="/home/extractSkills" component={ExtractSkills} />
              <Route path="/home/parseResume" component={UploadParseResume} />
              <Route path="/home/compareResume" component={CompareResume} />
              <Route path="/home/candidateLink" component={CandidateLink} />
              <Route path="/home/skillsMaster" component={SkillMaster} />
              <Route path="/home/competitorCompany" component={CompetitorCompany} />
            </Switch>
          </Route>
        </div> */}

        {/*<!-- Footer -->*/}
        {!findIsClient() && (
          <footer className="c-footer c-bg-dark c-py-1">
            <div className="c-container c-grid">
              <div>
                <h1>Talentium.ai</h1>
                <p>Copyright &copy; 2022</p>
                <p>
                  Registered under Titan Data Group Inc. All Rights Reserved.
                </p>
              </div>
              {/* <div className="c-footer-nav">
                <ul>
                  <li>
                    <a href="https://www.titandata.com/company">About</a>
                  </li>
                  <li>
                    <a href="https://www.titandata.com/accelerators">
                      Value Stream
                    </a>
                  </li>
                  <li>
                    <a href="https://www.titandata.com/sites/all/themes/titandata/assets/pdf/CaseStudy_TDG.pdf">
                      Case Study
                    </a>
                  </li>
                </ul>
              </div> */}
            </div>
          </footer>
        )}

        {/*-- Modals -->*/}
        {/* Attach Ref+Component together. Pass them to Modal */}
        <Modal
          ref={signInModal}
          component={
            <Login
              ref={{ createAccountModal, signInModal, forgotPasswordModal }}
            />
          }
        ></Modal>
        <Modal ref={pricingModal} component={<Pricing />}></Modal>
        <Modal
          ref={createAccountModal}
          component={
            <CreateAccount ref={{ createAccountModal, signInModal }} />
          }
        ></Modal>
        <Modal
          ref={forgotPasswordModal}
          component={
            <ForgotPassword ref={{ forgotPasswordModal, signInModal }} />
          }
        ></Modal>
      </>
    );
  } else {
    return (
      <>
        <div className="c-navbar sticky top-0 z-10">
          <div
            className="header-container c-flex gap-4 2xl:gap-5"
            style={{ justifyContent: "center" }}
          >
            <h1 className="title">
              <a className={"flex"} href={findIsClient() ? "#" : "/home"}>
                <span className={"whitespace-nowrap"}>
                  {"Talentium - X"}
                </span>
              </a>
            </h1>
            <nav className="header_nav">
              {!findIsClient() && (
                <ul>
                  {navItems.map(({ id, name, onClick, isShow }, ind) => {
                    return isShow ? (
                      <li
                        key={id}
                        onClick={onClick}
                        className={`${id === currentPage &&
                          `bg-white text-gray-700 shadow-sm`
                          } text-white text-lg px-3 rounded outline-none focus:outline-none hover:bg-white hover:text-gray-700 hover:shadow-sm inline-flex items-center cursor-pointer`}
                      >
                        <b>{name}</b>
                      </li>
                    ) : null;
                  })}
                  <li>
                    {userLoginStatusVal.login ? (
                      <button
                        className="text-white text-lg px-3 rounded outline-none focus:outline-none hover:bg-white hover:text-gray-700 hover:shadow-sm inline-flex items-center"
                        type="button"
                        onClick={() => {
                          Logoutuser();
                        }}
                      >
                        <b>Log Out</b>
                      </button>
                    ) : (
                      <button
                        className="text-white text-lg px-3 rounded outline-none focus:outline-none hover:bg-white hover:text-gray-700 hover:shadow-sm inline-flex items-center"
                        type="button"
                        onClick={() => {
                          dispatch(
                            userLoginStatus({
                              code: 0,
                              login: false,
                              msg: "User logged out!",
                            })
                          );
                          signInModal.current.open();
                        }}
                      >
                        Sign In
                      </button>
                    )}
                  </li>
                </ul>
              )}
            </nav>
          </div>
        </div>

        {/*<!-- Modules -->*/}
        <div
          ref={jumpToModules}
          data-aos="zoom-in"
          className="flex justify-center flex-grow"
        >
          <Switch>
            <Route path="/home" component={NavCards} />

            <Route path="/dashboard/:jobCode" component={Dashboard} />
            <Route
              path="/client/:isClient/form/jobCode/:jobCode/applicantId/:applicantId"
              component={ClientForm}
            />
            <Route
              path="/client/:isClient/coversheetform/jobCode/:jobCode/applicantId/:applicantId/:randomString"
              component={ClientCoverSheetForm}
            />
            <Route path="/coversheetform" component={ClientCoverSheetForm} />
            <Route
              path="/client/:isClient/jobCode/:jobCode/apply/:token/:applicantId?"
              component={ClientCoverSheetForm}
            />
            <Route
              path="/client/:isClient/chat/jobCode/:jobCode/applicantId/:applicantId"
              component={ChatForm}
            />
            <Route
              path="/client/:isClient/coversheetchat/jobCode/:jobCode/applicantId/:applicantId/:randomString"
              component={CoverSheetChatForm}
            />
            <Route
              path="/client/:isClient/qualify/jobCode/:jobCode/jobTitle/:jobTitle"
              component={Qualify}
            />
            <Route
              path="/client/:isClient/recommend/jobCode/:jobCode/jobTitle/:jobTitle"
              component={Recommendations}
            />
            <Route
              path="/client/:isClient/interview/jobCode/:jobCode/jobTitle/:jobTitle"
              component={ClientInterview}
            />
            <Route
              path="/client/:isClient/reset-password/:id/:token"
              component={RestPassword}
            />
            <Route exact path="/intake/form" component={IntakeForm} />
            <Route exact path="/intake/addForm" component={AddIntakeForm} />
            <Route exact path="/intake/addForm/:id" component={AddIntakeForm} />
            <Route
              exact
              path="/intake/:roleName/addForm"
              component={AddIntakeForm}
            />

            {/* Job Basesd Applicant History */}
            <Route
              path="/applicant-history/:jobCode/:applicantId"
              component={ApplicantHistory}
            />

            {/* Applicant History */}
            <Route
              path="/applicant-history/:applicantId"
              component={FunnelApplicantHistory}
            />

            {/* Interview Schedule */}
            <Route
              path="/client/:isClient/interview-form/jobCode/:jobCode/applicantId/:applicantId"
              component={ClientInterviewScheduleForm}
            />
            <Route
              path="/client/:isClient/interview-chat/jobCode/:jobCode/applicantId/:applicantId"
              component={ClientInterviewScheduleChatBot}
            />

            {/* Grammar Checker */}
            <Route
              path="/grammar-checker/:jobCode/:applicantId"
              component={GrammarChecker}
            />

            {/* Grammar Checker */}
            <Route
              path="/grammar-checker/:applicantId"
              component={FunnelGrammarChecker}
            />

            {/* Applicants */}
            <Route
              path="/home/applicants"
              component={FunnelApplicants}
            />

            <Redirect from={"/"} to={"/home/feedbackDashboard"} />
          </Switch>
        </div>

        {/*<!-- Footer -->*/}
        {!findIsClient() && (
          <footer className="c-footer c-bg-dark c-py-1">
            <div className="c-container c-grid">
              <div>
                <h1>Talentium.ai</h1>
                <p>Copyright &copy; 2022</p>
                <p>
                  Registered under Titan Data Group Inc. All Rights Reserved.
                </p>
              </div>
              {/* <nav className="navClass">
                <ul>
                  <li>
                    <a href="https://www.titandata.com/company">About</a>
                  </li>
                  <li>
                    <a href="https://www.titandata.com/accelerators">
                      Value Stream
                    </a>
                  </li>
                  <li>
                    <a href="https://www.titandata.com/sites/all/themes/titandata/assets/pdf/CaseStudy_TDG.pdf">
                      Case Study
                    </a>
                  </li>
                </ul>
              </nav> */}
            </div>
          </footer>
        )}

        {/*-- Modals -->*/}
        {/* Attach Ref+Component together. Pass them to Modal */}
        <Modal
          ref={signInModal}
          component={
            <Login
              ref={{ createAccountModal, signInModal, forgotPasswordModal }}
            />
          }
        ></Modal>
        <Modal ref={pricingModal} component={<Pricing />}></Modal>
        <Modal
          ref={createAccountModal}
          component={
            <CreateAccount ref={{ createAccountModal, signInModal }} />
          }
        ></Modal>
        <Modal
          ref={forgotPasswordModal}
          component={
            <ForgotPassword ref={{ forgotPasswordModal, signInModal }} />
          }
        ></Modal>
      </>
    );
  }
};

export default App;
