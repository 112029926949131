import { Box, Grid, Typography } from "@material-ui/core";
import dayjs from "dayjs";
import React, { Fragment } from "react";
import CustomDatePickers from "../../../commonComponents/DatePicker/CustomDatePicker";
import InputField from "./InputField";
import SelectDropDown from "./SelectDropDown";

const YEARS = [
  {
    value: "0",
    label: "0",
  },
  {
    value: "1",
    label: "1",
  },
  {
    value: "2",
    label: "2",
  },
  {
    value: "3",
    label: "3",
  },
  {
    value: "4",
    label: "4",
  },
  {
    value: "5",
    label: "5",
  },
  {
    value: "6",
    label: "6",
  },
  {
    value: "7",
    label: "7",
  },
  {
    value: "8",
    label: "8",
  },
  {
    value: "9",
    label: "9",
  },
  {
    value: "10",
    label: "10",
  },
  {
    value: "11",
    label: "11",
  },
  {
    value: "12",
    label: "12",
  },
  {
    value: "13",
    label: "13",
  },
  {
    value: "14",
    label: "14",
  },
  {
    value: "15",
    label: "15",
  },
  {
    value: "16",
    label: "16",
  },
  {
    value: "17",
    label: "17",
  },
  {
    value: "18",
    label: "18",
  },
  {
    value: "19",
    label: "19",
  },
  {
    value: "20",
    label: "20",
  },
];

const MONTHS = [
  {
    value: "0",
    label: "0",
  },
  {
    value: "1",
    label: "1",
  },
  {
    value: "2",
    label: "2",
  },
  {
    value: "3",
    label: "3",
  },
  {
    value: "4",
    label: "4",
  },
  {
    value: "5",
    label: "5",
  },
  {
    value: "6",
    label: "6",
  },
  {
    value: "7",
    label: "7",
  },
  {
    value: "8",
    label: "8",
  },
  {
    value: "9",
    label: "9",
  },
  {
    value: "10",
    label: "10",
  },
  {
    value: "11",
    label: "11",
  },
];

const PROJECT_COUNT = [
  {
    value: "0",
    label: "0",
  },
  {
    value: "1",
    label: "1",
  },
  {
    value: "2",
    label: "2",
  },
  {
    value: "3",
    label: "3",
  },
  {
    value: "4",
    label: "4",
  },
  {
    value: "5",
    label: "5",
  },
  {
    value: "6",
    label: "6",
  },
  {
    value: "7",
    label: "7",
  },
  {
    value: "8",
    label: "8",
  },
  {
    value: "9",
    label: "9",
  },
  {
    value: "10",
    label: "10",
  },
  {
    value: "11",
    label: "11",
  },
  {
    value: "12",
    label: "12",
  },
  {
    value: "13",
    label: "13",
  },
  {
    value: "14",
    label: "14",
  },
  {
    value: "15",
    label: "15",
  },
  {
    value: "16",
    label: "16",
  },
  {
    value: "17",
    label: "17",
  },
  {
    value: "18",
    label: "18",
  },
  {
    value: "19",
    label: "19",
  },
  {
    value: "20",
    label: "20",
  },
];

function SkillSummary({
  values = {},
  handleProjectDetails = () => { },
  handleDurationEndDate = () => { },
  handleDurationStartDate = () => { },
  handleSkillDetails = () => { },
  handleProjectCount = () => { },
  touched = {},
  errors = {},
  isSkill,
}) {
  return (
    <Box>
      {/* Project Details */}
      <Grid container spacing={2} style={{ rowGap: 5 }}>
        <Grid item xs={12}>
          <Typography variant={"h6"} style={{ paddingLeft: "5px" }}>
            <b>{"Project Details"}</b>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Box padding={"0 5px 5px"}>
            <Grid container spacing={2}>
              {values.project_details &&
                Array.isArray(values.project_details) &&
                values.project_details.length > 0
                ? values.project_details.map((el, index) => {
                  return (
                    <Fragment key={`project_details-${index + 1}`}>
                      <Grid item xs={12}>
                        <Box pl={2}>
                          <Typography>
                            <b>{`How many projects:`}</b>
                          </Typography>
                          <Box width={"80px"} paddingTop={"10px"}>
                            <SelectDropDown
                              options={PROJECT_COUNT}
                              name={"how_many_projects"}
                              value={el.how_many_projects || ""}
                              onChange={(e) => handleProjectCount(e, index)}
                            />
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item xs={12}>
                        <Box marginTop={"15px"} pl={2}>
                          <Grid container spacing={2}>
                            {el.project_details &&
                              el.project_details.length > 0
                              ? el.project_details.map(
                                (project_el, project_index) => (
                                  <Grid
                                    item
                                    xs={12}
                                    key={`project_details-${index + 1}${project_index + 1
                                      }`}
                                  >
                                    <Box
                                      border={
                                        "1px solid rgba(0, 0, 0, 0.23)"
                                      }
                                      padding={"16px"}
                                      borderRadius={"4px"}
                                      overflow={"hidden"}
                                    >
                                      <Box
                                        padding={"8px 16px"}
                                        margin={"-16px -16px 16px"}
                                        className={"bg-gray-200"}
                                      >
                                        <Typography>
                                          <b>{`Project ${project_index + 1
                                            }:`}</b>
                                        </Typography>
                                      </Box>
                                      <Grid container spacing={2}>
                                        <Grid item xs={6}>
                                          <InputField
                                            name={"client"}
                                            label={"Client Name"}
                                            id={"client"}
                                            placeholder={
                                              "Enter Client Name"
                                            }
                                            value={project_el.client || ""}
                                            onChange={(e) =>
                                              handleProjectDetails(
                                                e,
                                                index,
                                                project_index
                                              )
                                            }
                                            error={
                                              touched.project_details?.[
                                                index
                                              ]?.project_details?.[
                                                project_index
                                              ]?.client &&
                                              errors.project_details?.[
                                                index
                                              ]?.project_details?.[
                                                project_index
                                              ]?.client
                                            }
                                          />
                                        </Grid>
                                        <Grid item xs={6}>
                                          <InputField
                                            label={"Location (City/State)"}
                                            id={"location"}
                                            name={"location"}
                                            placeholder={"Enter Location"}
                                            value={
                                              project_el.location || ""
                                            }
                                            onChange={(e) =>
                                              handleProjectDetails(
                                                e,
                                                index,
                                                project_index
                                              )
                                            }
                                            error={
                                              touched.project_details?.[
                                                index
                                              ]?.project_details?.[
                                                project_index
                                              ]?.location &&
                                              errors.project_details?.[
                                                index
                                              ]?.project_details?.[
                                                project_index
                                              ]?.location
                                            }
                                          />
                                        </Grid>
                                        <Grid item xs={6}>
                                          <InputField
                                            label={"Domain"}
                                            name={"domain"}
                                            id={"domain"}
                                            placeholder={"Enter Domain"}
                                            value={project_el.domain || ""}
                                            onChange={(e) =>
                                              handleProjectDetails(
                                                e,
                                                index,
                                                project_index
                                              )
                                            }
                                            error={
                                              touched.project_details?.[
                                                index
                                              ]?.project_details?.[
                                                project_index
                                              ]?.domain &&
                                              errors.project_details?.[
                                                index
                                              ]?.project_details?.[
                                                project_index
                                              ]?.domain
                                            }
                                          />
                                        </Grid>
                                        <Grid item xs={3}>
                                          <CustomDatePickers
                                            id={"durationStartDate"}
                                            label={"Duration From"}
                                            name={"durationStartDate"}
                                            error={
                                              touched.project_details?.[
                                                index
                                              ]?.project_details?.[
                                                project_index
                                              ]?.durationStartDate &&
                                              Boolean(
                                                errors.project_details?.[
                                                  index
                                                ]?.project_details?.[
                                                  project_index
                                                ]?.durationStartDate
                                              )
                                            }
                                            helperText={
                                              touched.project_details?.[
                                                index
                                              ]?.project_details?.[
                                                project_index
                                              ]?.durationStartDate &&
                                              errors.project_details?.[
                                                index
                                              ]?.project_details?.[
                                                project_index
                                              ]?.durationStartDate
                                            }
                                            value={
                                              project_el.durationStartDate
                                                ? new Date(
                                                  project_el.durationStartDate
                                                )
                                                : null
                                            }
                                            onChange={(date) =>
                                              handleDurationStartDate(
                                                date,
                                                index,
                                                project_index
                                              )
                                            }
                                            views={["year", "month"]}
                                            openTo={"year"}
                                            format={"MMM YYYY"}
                                            maxDate={
                                              project_el.durationEndDate
                                                ? dayjs(
                                                  new Date(
                                                    project_el.durationEndDate
                                                  )
                                                )
                                                : dayjs(new Date())
                                            }
                                            disableFuture
                                          />
                                        </Grid>
                                        <Grid item xs={3}>
                                          <CustomDatePickers
                                            id={"durationEndDate"}
                                            label={"Duration To"}
                                            name={"durationEndDate"}
                                            error={
                                              touched.project_details?.[
                                                index
                                              ]?.project_details?.[
                                                project_index
                                              ]?.durationEndDate &&
                                              Boolean(
                                                errors.project_details?.[
                                                  index
                                                ]?.project_details?.[
                                                  project_index
                                                ]?.durationEndDate
                                              )
                                            }
                                            helperText={
                                              touched.project_details?.[
                                                index
                                              ]?.project_details?.[
                                                project_index
                                              ]?.durationEndDate &&
                                              errors.project_details?.[
                                                index
                                              ]?.project_details?.[
                                                project_index
                                              ]?.durationEndDate
                                            }
                                            value={
                                              project_el.durationEndDate
                                                ? new Date(
                                                  project_el.durationEndDate
                                                )
                                                : null
                                            }
                                            onChange={(date) =>
                                              handleDurationEndDate(
                                                date,
                                                index,
                                                project_index
                                              )
                                            }
                                            views={["year", "month"]}
                                            openTo={"year"}
                                            format={"MMM YYYY"}
                                            disableFuture
                                            minDate={
                                              project_el.durationStartDate
                                                ? dayjs(
                                                  new Date(
                                                    project_el.durationStartDate
                                                  )
                                                )
                                                : undefined
                                            }
                                          />
                                        </Grid>
                                        <Grid item xs={12}>
                                          <InputField
                                            id={"project_experience"}
                                            name={"project_experience"}
                                            label={"Project Description"}
                                            placeholder={
                                              "Explain what the Project is all about (not about Client company details)"
                                            }
                                            value={
                                              project_el.project_experience ||
                                              ""
                                            }
                                            onChange={(e) =>
                                              handleProjectDetails(
                                                e,
                                                index,
                                                project_index
                                              )
                                            }
                                            minRows={2}
                                            multiline
                                            error={
                                              touched.project_details?.[
                                                index
                                              ]?.project_details?.[
                                                project_index
                                              ]?.project_experience &&
                                              errors.project_details?.[
                                                index
                                              ]?.project_details?.[
                                                project_index
                                              ]?.project_experience
                                            }
                                          />
                                        </Grid>
                                        <Grid item xs={12}>
                                          <InputField
                                            id={"responsibilities"}
                                            name={"responsibilities"}
                                            label={"Responsibilities"}
                                            placeholder={
                                              "What was your Primary Responsibilities using the above tool"
                                            }
                                            value={
                                              project_el.responsibilities ||
                                              ""
                                            }
                                            onChange={(e) =>
                                              handleProjectDetails(
                                                e,
                                                index,
                                                project_index
                                              )
                                            }
                                            minRows={2}
                                            multiline
                                            error={
                                              touched.project_details?.[
                                                index
                                              ]?.project_details?.[
                                                project_index
                                              ]?.responsibilities &&
                                              errors.project_details?.[
                                                index
                                              ]?.project_details?.[
                                                project_index
                                              ]?.responsibilities
                                            }
                                          />
                                        </Grid>
                                      </Grid>
                                    </Box>
                                  </Grid>
                                )
                              )
                              : null}
                            <Grid item xs={12}></Grid>
                          </Grid>
                        </Box>
                      </Grid>
                    </Fragment>
                  );
                })
                : null}
            </Grid>
          </Box>
        </Grid>
        {/* <Grid item xs={12}>
          <Typography>
            <b>{`Note: `}</b>
            {"Please verify the above info and click Submit."}
          </Typography>
        </Grid> */}
      </Grid>

      {/* Skills Summary   */}
      {isSkill ? <Grid container spacing={2} style={{ rowGap: 5 }}>
        <Grid item xs={12}>
          <Typography variant={"h6"} style={{ paddingLeft: "5px" }}>
            <b>{"Skills Summary"}</b>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          {values.skill_details &&
            Array.isArray(values.skill_details) &&
            values.skill_details.length > 0 ? (
            <Box padding={"0 5px"}>
              <Grid container spacing={2}>
                {values.skill_details.map((el, index) => {
                  return (
                    <Fragment key={`skill_details-${index + 1}`}>
                      <Grid item xs={12}>
                        <Typography>
                          <b>{`${index + 1}. How many years of experience in ${el.skill
                            }:`}</b>
                        </Typography>
                        <Box
                          display={"flex"}
                          alignItems={"center"}
                          gridGap={"15px"}
                          paddingTop={2}
                          pl={2}
                        >
                          <Box width={"68px"}>
                            <SelectDropDown
                              label={"YY"}
                              options={YEARS}
                              value={el.year || ""}
                              name={"year"}
                              onChange={(e) => handleSkillDetails(e, index)}
                              error={
                                touched.skill_details?.[index]?.year &&
                                errors.skill_details?.[index]?.year
                              }
                              variant={"outlined"}
                              size={"small"}
                            />
                          </Box>
                          <Box>
                            <Box width={"68px"}>
                              <SelectDropDown
                                label={"MM"}
                                options={MONTHS}
                                value={el.month || ""}
                                name={"month"}
                                onChange={(e) => handleSkillDetails(e, index)}
                                error={
                                  touched.skill_details?.[index]?.month &&
                                  errors.skill_details?.[index]?.month
                                }
                                variant={"outlined"}
                                size={"small"}
                              />
                            </Box>
                          </Box>
                        </Box>
                      </Grid>
                    </Fragment>
                  );
                })}
              </Grid>
            </Box>
          ) : (
            <Box pl={2}>
              <Typography variant={"body1"} color={"textSecondary"}>
                {"No Skills"}
              </Typography>
            </Box>
          )}
        </Grid>
        <Grid item xs={12} />
      </Grid> : null}
      <Box>
        <Typography>
          <b>{`Note: `}</b>
          {"Please verify the above info and click Submit."}
        </Typography>
      </Box>
    </Box>
  );
}

export default SkillSummary;
