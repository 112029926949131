import { TEST } from "../actionTypes";

const initialState = {
  pagination: {
    activePage: 1,
    items: 10,
  },
  totalCount: 0,
};

const test = (state = initialState, { type, payload }) => {
  switch (type) {
    case TEST.setState: {
      return { ...state, ...payload };
    }
    case TEST.clearState: {
      return initialState;
    }
    default:
      return state;
  }
};

export default test;
