import React, { Component } from 'react';
import { Table } from 'react-materialize';

class TopSkillsTableReadOnly extends Component {

    render() {
        let {topSkills} = this.props;
        return (
            <Table className='skillsTable'
                striped='true'
            >
                <thead>
                    <tr>
						<th s={4} data-field="id"> Skill Name </th>
						<th s={2}> Time </th>
						<th s={4}> Recent Projects </th>
						<th s={1}> Count </th>
					</tr>
                </thead>
                <tbody>
                    {topSkills && topSkills.map((skill, topSkillIndex) =>
                        topSkillIndex < 9 &&
                        <tr key={topSkillIndex}>
                            <td s={4}> {skill.skill} </td>
                            <td s={2}> 
                                {(skill.time.match(/[0-9]+/g))[0]}y &nbsp; {(skill.time.match(/[0-9]+/g))[1]}m
                            </td>
                            <td s={4}> {skill.recent.split('|')[0]} 
                                    {skill.recent.split('|')[1] && skill.recent.split('|')[1] && <span> - {skill.recent.split('|')[1]} </span>}
                                    </td>
							<td s={1}> {skill.count} </td>
                        </tr>
                    )}
                </tbody>
            </Table>
        )
    }
}
export default TopSkillsTableReadOnly;