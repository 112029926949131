import React, { Component } from "react";
import TextField from "@material-ui/core/TextField";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";

class BasicInfo extends Component {
  state = {
    data: this.props.BasicData,
  };

  componentWillReceiveProps(nextProps) {
    //console.log('nextprops',nextProps)
  }

  render() {
    var bdata = this.state.data;
    return (
      <form className="basic_form" noValidate autoComplete="off">
        <div className="bcontent">
          <label className="blabe">
            First Name: <span style={{ color: "red" }}>*</span>
          </label>
          <TextField
            id="outlined-size-small"
            defaultValue={bdata.first_name}
            variant="outlined"
            size="small"
            name="first_name"
            disabled={this.props.disabled ? "disabled" : ""}
            onChange={this.props.handleSubmit}
            className="btextfied"
          />
        </div>
        <div className="bcontent">
          <label className="blabe">
            Last Name: <span style={{ color: "red" }}>*</span>
          </label>
          <TextField
            id="outlined-size-small"
            defaultValue={bdata.last_name}
            variant="outlined"
            size="small"
            name="last_name"
            disabled={this.props.disabled ? "disabled" : ""}
            onChange={this.props.handleSubmit}
            className="btextfied"
          />
        </div>
        <div className="bcontent">
          <label className="blabe">
            Email: <span style={{ color: "red" }}>*</span>
          </label>
          <TextField
            id="outlined-size-small"
            defaultValue={bdata.email}
            variant="outlined"
            size="small"
            name="email"
            disabled={this.props.disabled ? "disabled" : ""}
            onChange={this.props.handleSubmit}
            className="btextfied"
          />
        </div>
        <div className="bcontent">
          <label className="blabe">
            Phone: <span style={{ color: "red" }}>*</span>
          </label>
          <TextField
            id="outlined-size-small"
            defaultValue={bdata.phone}
            variant="outlined"
            size="small"
            onChange={this.props.handleSubmit}
            name="phone"
            disabled={this.props.disabled ? "disabled" : ""}
            className="btextfied"
          />
        </div>
        <div className="bcontent">
          <label className="blabe">Address:</label>
          <TextField
            id="outlined-size-small"
            defaultValue={bdata.address}
            variant="outlined"
            size="small"
            name="address"
            onChange={this.props.handleSubmit}
            disabled={this.props.disabled ? "disabled" : ""}
            className="btextfied"
          />
        </div>
        <div className="bcontent">
          <label className="blabe">LinkedIn: </label>
          <TextField
            id="outlined-size-small"
            defaultValue={bdata.linked_in}
            variant="outlined"
            size="small"
            name="linkedin"
            onChange={this.props.handleSubmit}
            disabled={this.props.disabled ? "disabled" : ""}
            className="btextfied"
          />
        </div>
        <div className="bcontent">
          <label className="blabe">Summary: </label>
          <TextareaAutosize
            disabled={this.props.disabled ? "disabled" : ""}
            name="summary"
            onChange={this.props.handleSubmit}
            className="btextfied"
            aria-label="minimum height"
            defaultValue={bdata.summary}
          />
        </div>
        <div className="bcontent">
          <label className="blabe">Skill Matrix:</label>
          <TextareaAutosize
            disabled={this.props.disabled ? "disabled" : ""}
            name="skill_matrix"
            onChange={this.props.handleSubmit}
            className="btextfied"
            aria-label="minimum height"
            defaultValue={bdata.skill_matrix}
          />
        </div>
        <div className="bcontent">
          <label className="blabe">Education:</label>
          <TextareaAutosize
            disabled={this.props.disabled ? "disabled" : ""}
            name="education"
            onChange={this.props.handleSubmit}
            className="btextfied"
            aria-label="minimum height"
            defaultValue={bdata.education}
          />
        </div>
        <div className="bcontent">
          <label className="blabe">Certification:</label>
          <TextareaAutosize
            name="certification"
            disabled={this.props.disabled ? "disabled" : ""}
            onChange={this.props.handleSubmit}
            className="btextfied"
            aria-label="minimum height"
            defaultValue={bdata.certification}
          />
        </div>
      </form>
    );
  }
}
export default BasicInfo;
