import { CircularProgress } from "@material-ui/core";
import React from "react";
import { StyledLoadingButton } from "./StyledComponents";

function LoadingButton({
  onClick,
  variant = "contained",
  color = "primary",
  startIcon,
  loading = false,
  disabled,
  children,
  ...props
}) {
  return (
    <StyledLoadingButton
      onClick={onClick}
      variant={variant}
      color={color}
      startIcon={
        loading ? (
          <CircularProgress
            className={startIcon ? "icon_loading" : "text_loading"}
            size={20}
          />
        ) : (
          startIcon || null
        )
      }
      disabled={loading || disabled}
      {...props}
    >
      <span className={"text"}>{children}</span>
    </StyledLoadingButton>
  );
}

export default LoadingButton;
