import { RECOMMEND } from "../actionTypes";

const initialState = {
  pagination: {
    activePage: 1,
    items: 10,
  },
};

const recommend = (state = initialState, { type, payload }) => {
  switch (type) {
    case RECOMMEND.setState: {
      return { ...state, ...payload };
    }
    case RECOMMEND.clearState: {
      return initialState;
    }
    default:
      return state;
  }
};

export default recommend;
