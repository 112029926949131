import {
  Button,
  Container,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import Pagination from "@material-ui/lab/Pagination";
import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import {
  addCompetitorCompany,
  deleteCompetitorCompany,
  getAllCompetitorCompany,
  updateCompetitorCompany,
} from "../../api/api";
import DeleteDialog from "../../commonComponents/DeleteDialog/DeleteDialog";
import Loader from "../../commonComponents/Loader/Loader";
import {
  StyledTableContainer,
  StyledTableHead,
} from "../../commonComponents/StyledComponents";
import "../../styles/style.css";
import {
  debounce,
  getOffset,
  getPageUsingRemovingAnElement,
} from "../../utils/constants/helpers";
import { isEmptyString } from "../../utils/constants/validation";
import "./style.css";

export const Header = [
  // {
  //     id: 1,
  //     name: "SI. No",
  // },
  {
    id: 2,
    name: "Company Name",
  },
  {
    id: 3,
    name: "Action",
  },
];

export const Options = [
  {
    id: 1,
    value: "10",
  },
  {
    id: 2,
    value: "20",
  },
  {
    id: 3,
    value: "30",
  },
  {
    id: 4,
    value: "40",
  },
  {
    id: 5,
    value: "50",
  },
  {
    id: 6,
    value: "100",
  },
];

axios.defaults.xsrfHeaderName = "X-CSRFToken";
axios.defaults.xsrfCookieName = "csrftoken";

export default function CompetitorCompany() {
  const [state, setState] = useState({
    show: false,
    companySet: [],
    isEditing: false,
    company: "",
    newCompany: "",
  });

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const [deleteId, setDeleteId] = useState("");

  const [editError, setEditError] = useState({
    isError: false,
    errorMessage: "",
  });

  const [error, setError] = useState({ isError: false, errorMessage: "" });

  const [searchCompany, setSearchCompany] = useState("");

  const [totalCount, setTotalCount] = useState(0);

  const [pagination, setPagination] = useState({
    activePage: 1,
    items: 10,
  });

  const [loading, setLoading] = useState(false);

  const getAndSetCompanyList = (limit, page, search = searchCompany) => {
    setLoading(true);
    axios
      .get(getAllCompetitorCompany(limit, getOffset(page, limit), search), {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("tal_token"),
        },
      })
      .then((response) => {
        setState({
          ...state,
          isEditing: false,
          company: "",
          show: false,
          newCompany: "",
          companySet: response.data.results,
        });
        setTotalCount(response.data.count);
        setLoading(false);
      });
  };

  useEffect(() => {
    getAndSetCompanyList(pagination.items, 0);
  }, []);

  const onClickEdit = (id, data, index) => {
    if (!state.isEditing) {
      let copyOfCompanySet = [...state.companySet];

      copyOfCompanySet[index].edit = true;

      setState({
        ...state,
        companySet: copyOfCompanySet,
        company: data,
        isEditing: true,
      });
    }
  };
  const onClickClose = (id, name, index) => {
    let copyOfCompanySet = [...state.companySet];

    copyOfCompanySet[index].edit = false;

    setState({
      ...state,
      companySet: copyOfCompanySet,
      company: "",
      isEditing: false,
    });
  };

  const onChangeCapture = (id, data, index) => {
    setState({ ...state, company: data });
    setEditError({
      isError: false,
      errorMessage: "",
    });
  };

  const onSelectPage = (e, value) => {
    getAndSetCompanyList(pagination.items, value);
    setPagination({
      ...pagination,
      activePage: value,
    });
  };

  const handlePageLimitChange = (e) => {
    const { value } = e.target;

    getAndSetCompanyList(parseInt(value), pagination.activePage);
    setPagination({
      ...pagination,
      items: parseInt(value),
    });
  };

  const handleEdit = (id) => {
    if (!isEmptyString(state.company)) {
      axios
        .put(updateCompetitorCompany(id), { company_name: state.company })
        .then((response) => {
          if (response.status === 200) {
            getAndSetCompanyList(pagination.items, pagination.activePage);
          }
        })
        .catch((err) => {
          setEditError({
            isError: true,
            errorMessage: "Company name should unique",
          });
        });
    } else {
      setEditError({
        isError: true,
        errorMessage: "Company name should not be empty",
      });
    }
  };

  const handleAddNewCompanyChange = (e) => {
    setState({
      ...state,
      newCompany: e.target.value,
    });
    setError({
      isError: false,
      errorMessage: "",
    });
  };

  const handleAddNewCompanyClick = () => {
    if (!isEmptyString(state.newCompany)) {
      axios
        .post(addCompetitorCompany, { company_name: state.newCompany })
        .then((response) => {
          if (response.status === 201) {
            getAndSetCompanyList(pagination.items, pagination.activePage);
          }
        })
        .catch((err) => {
          setError({
            isError: true,
            errorMessage: "Company name should unique",
          });
        });
    } else {
      // Error
      setError({
        isError: true,
        errorMessage: "Company name should not be empty",
      });
    }
  };

  const handleDeleteClick = () => {
    axios.delete(deleteCompetitorCompany(deleteId)).then((response) => {
      if (response.status === 204) {
        let page = getPageUsingRemovingAnElement(
          totalCount,
          pagination.activePage,
          pagination.items
        );
        getAndSetCompanyList(pagination.items, page);
        setPagination({
          ...pagination,
          activePage: page,
        });
      }
    });
    setDeleteDialogOpen(false);
    setDeleteId("");
  };

  const handleSearchChange = (value) => {
    getAndSetCompanyList(pagination.items, 0, value);
    setSearchCompany(value);
    setPagination({
      ...pagination,
      activePage: 1,
    });
  };

  const handleDeleteRowClick = (id) => {
    setDeleteId(id);
    setDeleteDialogOpen(true);
  };

  const optimizedFn = useCallback(debounce(handleSearchChange), [pagination]);

  return (
    <Container maxWidth={"md"} className={"containerStyle mt-8"}>
      <Grid container spacing={4}>
        <Grid item xs={3} container alignItems="center">
          <Typography>
            <b>Preferred Company</b>
          </Typography>
        </Grid>
        <Grid
          item
          xs={6}
          container
          alignItems="center"
          justifyContent="flex-end"
        >
          {/* <TextField
            id="search"
            label="Search..."
            onChange={(e) => optimizedFn(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton>
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          /> */}
        </Grid>
        <Grid item xs={3} container alignItems="center">
          <Button
            variant="contained"
            disabled={state.companySet.length >= 5}
            color="primary"
            onClick={() => {
              setState({ ...state, show: true });
            }}
          >
            Add Company
          </Button>
        </Grid>
        {/* <Grid item xs={12}>
          <Alert severity="warning">You must add only five companies</Alert>
        </Grid> */}
        <Grid item xs={12}>
          <StyledTableContainer component={Paper} variant={"outlined"}>
            <Table size={"small"}>
              <StyledTableHead>
                <TableRow>
                  {Header.map(({ name }, ind) => (
                    <TableCell align={ind === 1 ? "center" : ""}>
                      {name}
                    </TableCell>
                  ))}
                </TableRow>
              </StyledTableHead>

              <TableBody>
                {state.show ? (
                  <TableRow>
                    <TableCell>
                      <TextField
                        error={error.isError}
                        helperText={error.errorMessage}
                        onChange={handleAddNewCompanyChange}
                        value={state.newCompany}
                        id={"new-skill"}
                        placeholder={"Add New Company Here..."}
                        className={"input"}
                      />
                    </TableCell>
                    <TableCell>
                      <div className="actionTableCell">
                        <Button
                          disabled={state.newCompany ? false : true}
                          size={"small"}
                          variant="contained"
                          color="primary"
                          onClick={handleAddNewCompanyClick}
                        >
                          Add
                        </Button>
                        <Button
                          size={"small"}
                          variant="contained"
                          color="secondary"
                          onClick={() => {
                            setState({ ...state, show: false, newCompany: "" });
                            setError({ isError: false, errorMessage: "" });
                          }}
                        >
                          Cancel
                        </Button>
                      </div>
                    </TableCell>
                  </TableRow>
                ) : null}
                {!loading && state.companySet?.length ? (
                  state.companySet?.map(({ id, company_name, edit }, index) => (
                    <TableRow key={id}>
                      {/* <TableCell className="id">{index + 1}</TableCell> */}
                      <TableCell>
                        {edit ? (
                          <TextField
                            id="search"
                            placeholder=""
                            value={
                              state.isEditing ? state.company : company_name
                            }
                            onChange={(e) =>
                              onChangeCapture(id, e.target.value, index)
                            }
                            className="inputText"
                            error={editError.isError}
                            helperText={editError.errorMessage}
                          />
                        ) : (
                          company_name
                        )}
                      </TableCell>
                      <TableCell>
                        <span className="span">
                          {edit ? (
                            <div className="flex items-center justify-center">
                              <Button
                                size={"small"}
                                variant={"contained"}
                                color={"primary"}
                                onClick={() => handleEdit(id)}
                                disabled={state.company ? false : true}
                              >
                                Save
                              </Button>
                              <IconButton
                                color={"secondary"}
                                onClick={() => {
                                  setEditError({
                                    isError: false,
                                    errorMessage: "",
                                  });
                                  onClickClose(id, company_name, index);
                                }}
                              >
                                <CloseIcon />
                              </IconButton>
                            </div>
                          ) : (
                            <div className="editTab">
                              <IconButton
                                color={"primary"}
                                onClick={() =>
                                  onClickEdit(id, company_name, index)
                                }
                              >
                                <EditIcon />
                              </IconButton>

                              <IconButton
                                color="secondary"
                                onClick={() => handleDeleteRowClick(id)}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </div>
                          )}
                        </span>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={2} align={"center"}>
                      {loading ? (
                        <Loader />
                      ) : (
                        <Typography>
                          <b>No results found</b>
                        </Typography>
                      )}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </StyledTableContainer>
        </Grid>
        <Grid
          item
          container
          xs={12}
          alignItems={"center"}
          justifyContent={"flex-end"}
        >
          <Typography>
            Total Count: <b>{totalCount}</b>
          </Typography>
        </Grid>
        <Grid item xs={2} container alignItems="center">
          <FormControl style={{ width: 120 }}>
            <InputLabel id="demo-simple-select-label">Rows per page</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={pagination.items}
              onChange={handlePageLimitChange}
            >
              {Options.map(({ id, value }) => (
                <MenuItem key={id} value={value}>
                  {value}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid
          item
          xs={10}
          container
          alignItems="center"
          justifyContent="flex-end"
        >
          <Pagination
            color={"primary"}
            page={pagination.activePage}
            onChange={onSelectPage}
            count={Math.ceil(totalCount / pagination.items)}
            showFirstButton
            showLastButton
          />
        </Grid>
      </Grid>
      <DeleteDialog
        dialogOpen={deleteDialogOpen}
        handleClose={() => {
          setDeleteDialogOpen(false);
          setDeleteId("");
        }}
        handleYesClick={handleDeleteClick}
      />
    </Container>
  );
}
