import {
    Box,
    Button,
    FormControl,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
    Typography,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import SearchIcon from "@material-ui/icons/Search";
import Pagination from "@material-ui/lab/Pagination";
import axios from "axios";
import { useFormik } from "formik";
import React, { useCallback, useEffect, useState } from "react";
import * as yup from "yup";
import {
    createHobbiesHierarchy,
    deleteHobbiesHierarchy,
    getHobbiesHierarchy,
    getSingleHobbiesHierarchy,
    updateHobbiesHierarchy,
} from "../../../api/api";
import InputField from "../../../commonComponents/Controllers/InputField";
import DeleteDialog from "../../../commonComponents/DeleteDialog/DeleteDialog";
import Loader from "../../../commonComponents/Loader/Loader";
import SimpleModal from "../../../commonComponents/Modal/SimpleModal";
import CustomSnack from "../../../commonComponents/Snack/CustomSnack";
import "../../../styles/style.css";
import {
    CREATED_MESSAGE,
    DELETED_MESSAGE,
    SOMETHING_WENT_WRONG,
    UPDATED_MESSAGE,
} from "../../../utils/constants/constants";
import { debounce } from "../../../utils/constants/helpers";

export const Header = [
    {
        id: 2,
        name: "Hobby",
        className: "thChild",
    },
    {
        id: 3,
        name: "Action",
        className: "th",
    },
];

export const Options = [
    {
        id: 1,
        value: "10",
    },
    {
        id: 2,
        value: "20",
    },
    {
        id: 3,
        value: "30",
    },
    {
        id: 4,
        value: "40",
    },
    {
        id: 5,
        value: "50",
    },
    {
        id: 6,
        value: "100",
    },
];

axios.defaults.xsrfHeaderName = "X-CSRFToken";
axios.defaults.xsrfCookieName = "csrftoken";

function Index() {
    const [HobbiesHierarchy, setHobbiesHierarchy] = useState([]);

    const [snack, setSnack] = useState({
        open: false,
        color: "",
        message: "",
    });

    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

    const [deleteId, setDeleteId] = useState(null);

    const [editId, setEditId] = useState(null);

    const [searchHobbies, setSearchHobbies] = useState("");

    const [totalCount, setTotalCount] = useState(0);

    const [pagination, setPagination] = useState({
        activePage: 1,
        items: 10,
    });

    const [HobbiesHierarchyDialogOpen, setHobbiesHierarchyDialogOpen] =
        useState(false);

    const [loading, setLoading] = useState(false);

    const validationSchema = yup.object({
        hobbie_name: yup
            .string()
            .nullable()
            .required("Please fill the required field"),
    });

    const handleSnackClose = () => {
        setSnack({
            open: false,
            color: "",
            message: "",
        });
    };

    const handleSave = (values, { resetForm }) => {
        let payload = {
            hobbie_name: values.hobbie_name,
        };

        if (editId) {
            // Edit Hobbies Hierarchy
            axios
                .put(updateHobbiesHierarchy(editId), payload)
                .then((response) => {
                    setHobbiesHierarchyDialogOpen(false);
                    setSnack({
                        open: true,
                        color: "success",
                        message: UPDATED_MESSAGE,
                    });
                    if (response.status === 200) {
                        resetForm();
                        setTimeout(() => {
                            let offset =
                                pagination.activePage === 0
                                    ? 0
                                    : (pagination.activePage - 1) * parseInt(pagination.items);
                            getAndSetHobbiesHierarchy(pagination.items, offset);
                        }, 2500);
                    } else {
                        setSnack({
                            open: true,
                            color: "error",
                            message: SOMETHING_WENT_WRONG,
                        });
                    }
                })
                .catch((err) => {
                    setSnack({
                        open: true,
                        color: "error",
                        message: SOMETHING_WENT_WRONG,
                    });
                });
        } else {
            // Add Hobbies Hierarchy
            axios
                .post(createHobbiesHierarchy(), payload)
                .then((response) => {
                    if (response.status === 201) {
                        setHobbiesHierarchyDialogOpen(false);
                        setSnack({
                            open: true,
                            color: "success",
                            message: CREATED_MESSAGE,
                        });
                        setTimeout(() => {
                            resetForm();
                            getAndSetHobbiesHierarchy(pagination.items, 0);
                            setPagination({
                                activePage: 1,
                                items: 10,
                            });
                        }, 2500);
                    } else {
                        setSnack({
                            open: true,
                            color: "error",
                            message: SOMETHING_WENT_WRONG,
                        });
                    }
                })
                .catch((err) => {
                    setSnack({
                        open: true,
                        color: "error",
                        message: SOMETHING_WENT_WRONG,
                    });
                });
        }
    };

    const formik = useFormik({
        initialValues: {
            hobbie_name: "",
        },
        validationSchema: validationSchema,
        enableReinitialize: true,
        onSubmit: handleSave,
    });

    const {
        values,
        setValues,
        errors,
        touched,
        setFieldValue,
        handleChange,
        handleSubmit,
        resetForm,
    } = formik;

    const getAndSetHobbiesHierarchy = (limit, offset, search = searchHobbies) => {
        setLoading(true);
        axios
            .get(getHobbiesHierarchy(), {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("tal_token")}`,
                },
                params: {
                    limit: limit,
                    offset: offset,
                    search: search,
                },
            })
            .then((response) => {
                setHobbiesHierarchy(response.data.results);
                setTotalCount(response.data.count);
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
                setSnack({
                    open: true,
                    color: "error",
                    message: SOMETHING_WENT_WRONG,
                });
            });
    };

    useEffect(() => {
        getAndSetHobbiesHierarchy(pagination.items, 0);
    }, []);

    const onClickEdit = (id) => {
        axios
            .get(getSingleHobbiesHierarchy(id), {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("tal_token")}`,
                },
            })
            .then((response) => {
                setEditId(id);
                setValues({
                    hobbie_name: response?.data.hobbie_name || "",
                });
                setHobbiesHierarchyDialogOpen(true);
            })
            .catch((error) => {
                setSnack({
                    open: true,
                    color: "error",
                    message: SOMETHING_WENT_WRONG,
                });
            });
    };

    const onSelectPage = (e, value) => {
        let offset = value === 0 ? 0 : value - 1;
        getAndSetHobbiesHierarchy(pagination.items, offset * pagination.items);

        setPagination({
            ...pagination,
            activePage: value,
        });
    };

    const handlePageLimitChange = (e) => {
        const { value } = e.target;
        getAndSetHobbiesHierarchy(
            parseInt(value),
            (pagination.activePage - 1) * parseInt(value)
        );
        setPagination({
            ...pagination,
            items: parseInt(value),
        });
    };

    const handleDeleteClick = () => {
        axios
            .delete(deleteHobbiesHierarchy(deleteId))
            .then((response) => {
                setDeleteDialogOpen(false);
                setDeleteId(null);
                if (response.status === 204) {
                    setSnack({
                        open: true,
                        color: "success",
                        message: DELETED_MESSAGE,
                    });
                    setTimeout(() => {
                        let offset =
                            pagination.activePage === 0 ? 0 : pagination.activePage - 1;
                        getAndSetHobbiesHierarchy(
                            pagination.items,
                            offset * pagination.items
                        );
                    }, 2500);
                } else {
                    setSnack({
                        open: true,
                        color: "error",
                        message: SOMETHING_WENT_WRONG,
                    });
                }
            })
            .catch((err) => {
                setDeleteDialogOpen(false);
                setDeleteId(null);
                setSnack({
                    open: true,
                    color: "error",
                    message: SOMETHING_WENT_WRONG,
                });
            });
    };

    const handleSearchChange = (value) => {
        getAndSetHobbiesHierarchy(pagination.items, 0, value);
        setSearchHobbies(value);
    };

    const handleDeleteRowClick = (id) => {
        setDeleteId(id);
        setDeleteDialogOpen(true);
    };

    const handleDeleteCancel = () => {
        setDeleteDialogOpen(false);
        setDeleteId(null);
    };

    const handleAdd = () => {
        setHobbiesHierarchyDialogOpen(true);
    };

    const optimizedFn = useCallback(debounce(handleSearchChange), [pagination]);

    const handleHobbiesHierarchyDialogClose = () => {
        resetForm();
        setHobbiesHierarchyDialogOpen(false);
        setEditId(null);
    };

    return (
        <Box>
            <Grid container spacing={4}>
                <Grid item xs={12}>
                    <Grid container spacing={4} alignItems={"flex-end"}>
                        <Grid item xs>
                            <Typography style={{ paddingBottom: "5px" }}>
                                <b>{"Hobbies"}</b>
                            </Typography>
                        </Grid>
                        <Grid item>
                            <TextField
                                id={"search"}
                                label={"Search"}
                                onChange={(e) => optimizedFn(e.target.value)}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton>
                                                <SearchIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                        <Grid item>
                            <Button
                                variant={"contained"}
                                color={"primary"}
                                onClick={handleAdd}
                            >
                                <b className={"capitalize"}>{"Add Hobby"}</b>
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Table size={"small"} component={Paper} variant={"outlined"}>
                        <TableHead>
                            <TableRow>
                                {Header.map(({ name, className }, ind) => (
                                    <TableCell
                                        className={className}
                                        align={ind === 1 ? "center" : "inherit"}
                                        key={`head-${ind + 1}`}
                                    >
                                        <Typography className={"tableHead"}>{name}</Typography>
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {!loading && HobbiesHierarchy?.length ? (
                                HobbiesHierarchy.map(({ id, hobbie_name }) => (
                                    <TableRow key={id}>
                                        <TableCell>{hobbie_name}</TableCell>
                                        <TableCell>
                                            <div className={"editTab"}>
                                                <IconButton
                                                    color={"primary"}
                                                    onClick={() => onClickEdit(id)}
                                                >
                                                    <EditIcon />
                                                </IconButton>
                                                <IconButton
                                                    color={"secondary"}
                                                    onClick={() => handleDeleteRowClick(id)}
                                                >
                                                    <DeleteIcon />
                                                </IconButton>
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                ))
                            ) : (
                                <TableRow>
                                    <TableCell colSpan={3} align={"center"}>
                                        {loading ? (
                                            <Loader />
                                        ) : (
                                            <Typography>
                                                <b>{"No results found"}</b>
                                            </Typography>
                                        )}
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </Grid>
                <Grid
                    item
                    container
                    xs={12}
                    alignItems={"center"}
                    justifyContent={"flex-end"}
                >
                    <Typography>
                        Total Count: <b>{totalCount}</b>
                    </Typography>
                </Grid>
                <Grid item xs={2} container alignItems={"center"}>
                    <FormControl style={{ width: 120 }}>
                        <InputLabel id={"demo-simple-select-label"}>
                            {"Rows per page"}
                        </InputLabel>
                        <Select
                            labelId={"demo-simple-select-label"}
                            id={"demo-simple-select"}
                            value={pagination.items}
                            onChange={handlePageLimitChange}
                        >
                            {Options.map(({ id, value }) => (
                                <MenuItem key={id} value={value}>
                                    {value}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid
                    item
                    xs={10}
                    container
                    alignItems={"center"}
                    justifyContent={"flex-end"}
                >
                    <Pagination
                        color={"primary"}
                        page={pagination.activePage}
                        onChange={onSelectPage}
                        count={Math.ceil(totalCount / pagination.items)}
                        showFirstButton
                        showLastButton
                    />
                </Grid>
            </Grid>
            <DeleteDialog
                dialogOpen={deleteDialogOpen}
                handleClose={handleDeleteCancel}
                handleYesClick={handleDeleteClick}
            />
            <SimpleModal
                open={HobbiesHierarchyDialogOpen}
                title={`${editId ? "Edit" : "Add"} Hobby`}
                handleClose={handleHobbiesHierarchyDialogClose}
                handleSave={handleSubmit}
            >
                <Box minHeight={"80px"}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <InputField
                                id={"hobbie_name"}
                                label={"Hobby"}
                                name={"hobbie_name"}
                                value={values.hobbie_name || ""}
                                onChange={handleChange}
                                error={touched.hobbie_name && Boolean(errors.hobbie_name)}
                                helperText={touched.hobbie_name && errors.hobbie_name}
                            />
                        </Grid>
                    </Grid>
                </Box>
            </SimpleModal>
            <CustomSnack
                open={snack.open}
                color={snack.color}
                message={snack.message}
                onClose={handleSnackClose}
            />
        </Box>
    );
}

export default Index;
