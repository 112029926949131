import {
  Button,
  Grid,
  TextField,
  Typography,
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
  InputLabel,
} from "@material-ui/core";
import { Form, FormikProvider, useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "./style.css";
import * as yup from "yup";
import axios from "axios";
import { getAllTestList, postApplicantSession } from "../../api/api";
import { Alert, Autocomplete } from "@material-ui/lab";
import { getPosition } from "../../utils/constants/helpers";
import moment from "moment";
import {
  ROLE_BASED_PAGES,
  getRoleBasedAccess,
} from "../../utils/constants/roleBasedAccess";
import { MESSAGES } from "../../utils/constants/constants";

const listItems = [
  "Turn on camera",
  "Join 5 minutes earlier",
  "Have enough lights",
  "Dont't talk to anyone during test",
];

function TestEvent({ sessionData, getAndSetApplicantSessionData }) {
  const { jobCode, applicantId, applicantsession } = useParams();
  const role = localStorage.getItem("role");
  const { isAccessibleActionButton = true } = getRoleBasedAccess(
    role,
    ROLE_BASED_PAGES.testEvent
  );

  const [alertBox, setAlertBox] = useState({
    open: false,
    success: false,
    message: "",
  });
  const [sendType, setSendType] = useState("");
  const [testList, setTestList] = useState([]);
  const [scheduleList, setScheduleList] = useState([]);

  const requiredMsg = "Please fill the required field";
  const invalidMail = "Invalid Mail ID";
  const futureDateMessage = "Please enter valid date";

  const validationSchema = yup.object().shape({
    email: yup.string().required(requiredMsg).email(invalidMail),
    schedule: yup.string().required(requiredMsg).nullable(),
    hackerRankTest: yup.string().required(requiredMsg),
  });

  const formik = useFormik({
    initialValues: {
      email: sessionData?.applicant.email,
      schedule: "",
      hackerRankTest: sessionData?.test_event?.tests_lists?.testid,
      tests_lists: sessionData?.test_event?.tests_lists,
    },
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: handleSubmit,
  });

  function handleSubmit(values) {
    let key =
      sendType === "email"
        ? "test_event_send_invite"
        : "test_event_informed_by_phone";
    let requestBody = {
      [key]: { schedule: values.schedule, testid: values.hackerRankTest },
    };
    axios
      .post(postApplicantSession(applicantsession), requestBody)
      .then((response) => {
        if (response.status === 200) {
          setAlertBox({
            open: true,
            success: true,
            message:
              sendType === "email"
                ? MESSAGES.testEventInviteByEmail
                : MESSAGES.testEventInformedByPhone,
          });
          getAndSetApplicantSessionData();
        }
      })
      .catch((err) => {
        setAlertBox({
          open: true,
          success: false,
          message: "Something Went Wrong",
        });
      })
      .finally(() => {
        setTimeout(() => {
          setAlertBox({
            open: false,
            success: false,
            message: "",
          });
        }, 2000);
      });
  }

  const getAndSetTestList = () => {
    axios
      .get(getAllTestList())
      .then((response) => {
        if (response.status === 200) {
          setTestList(response.data);
        }
      })
      .catch((err) => {});
  };

  useEffect(() => {
    getAndSetTestList();
    getAndSetApplicantSessionData();
  }, []);

  useEffect(() => {
    if (sessionData) {
      if (sessionData.answers.length !== 0) {
        let scheduleOptions = sessionData.answers
          .filter(
            (el) =>
              el.question_title === "timeSlotOne" ||
              el.question_title === "timeSlotTwo"
          )
          .map((el) => el.text);
        setScheduleList(scheduleOptions);
      }
      if (sessionData?.test_event?.schedule) {
        let scheduleDate = sessionData.test_event.schedule.substring(
          0,
          getPosition(sessionData.test_event.schedule, ":", 2)
        );
        setFieldValue("schedule", scheduleDate);
      }
    }
  }, [sessionData]);

  const { values, handleChange, touched, errors, setFieldValue, handleBlur } =
    formik;

  return (
    <FormikProvider value={formik}>
      <Form>
        <Grid container spacing={4}>
          <Grid item xs={6}>
            <TextField
              fullWidth
              disabled
              name={"email"}
              value={values.email}
              error={touched.email && Boolean(errors.email)}
              helperText={touched.email && errors.email}
              onChange={handleChange}
              onBlur={handleBlur}
              InputLabelProps={{ shrink: true }}
              variant="outlined"
              label={"Mail ID"}
              size={"small"}
            />
          </Grid>
          <Grid item xs={6}>
            <Button
              onClick={() => setSendType("email")}
              variant="contained"
              color={"primary"}
              type={"submit"}
              disabled={!isAccessibleActionButton}
            >
              Send Invite
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Autocomplete
              id="combo-box-demo"
              options={scheduleList}
              fullWidth
              disabled={scheduleList.length !== 2}
              value={values.schedule}
              onChange={(e, value) => {
                setFieldValue("schedule", value);
              }}
              size={"small"}
              getOptionLabel={(option) =>
                option ? moment(option).format("LLL") : option
              }
              onBlur={handleBlur}
              renderInput={(params) => (
                <TextField
                  fullWidth
                  disabled={scheduleList.length !== 2}
                  onBlur={handleBlur}
                  name={"schedule"}
                  error={touched.schedule && Boolean(errors.schedule)}
                  helperText={touched.schedule && errors.schedule}
                  {...params}
                  label="Schedule"
                  variant="outlined"
                  value={values.schedule}
                />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <Button
              onClick={() => setSendType("phone")}
              variant="contained"
              color={"primary"}
              type={"submit"}
              disabled={!isAccessibleActionButton}
            >
              Informed By Phone
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Autocomplete
              id="combo-box-demo"
              options={testList}
              fullWidth
              value={values.tests_lists ? values.tests_lists : null}
              onChange={(e, value) => {
                if (value) {
                  setFieldValue("hackerRankTest", value.testid);
                } else {
                  setFieldValue("hackerRankTest", "");
                }
                setFieldValue("tests_lists", value);
              }}
              size={"small"}
              getOptionLabel={(option) => option.testname}
              onBlur={handleBlur}
              renderInput={(params) => (
                <TextField
                  fullWidth
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name={"hackerRankTest"}
                  error={
                    touched.hackerRankTest && Boolean(errors.hackerRankTest)
                  }
                  helperText={touched.hackerRankTest && errors.hackerRankTest}
                  {...params}
                  label="iMocha Test"
                  variant="outlined"
                />
              )}
            />
          </Grid>
          <Grid item xs={6}></Grid>
          <Grid item xs={6}>
            <Typography>
              <b>List Of Instructions</b>
            </Typography>
            <div className="bg-gray-200 p-5 mt-4 rounded-lg">
              <ul>
                {listItems.map((el) => (
                  <li className="listStyle">
                    <Typography>{el}</Typography>
                  </li>
                ))}
              </ul>
            </div>
          </Grid>
          {alertBox.open && (
            <Grid item xs={12}>
              <Alert severity={`${alertBox.success ? "success" : "error"}`}>
                {alertBox.message}
              </Alert>
            </Grid>
          )}
        </Grid>
      </Form>
    </FormikProvider>
  );
}

export default TestEvent;
