import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import React from "react";

const editorConfiguration = {
  toolbar: {
    items: [
      "undo",
      "redo",
      "|",
      "textPartLanguage",
      "|",
      "fontfamily",
      "fontsize",
      "fontColor",
      "fontBackgroundColor",
      "highlight",
    ],
    shouldNotGroupWhenFull: true,
  },
  removePlugins: ["MediaEmbedToolbar"],
  highlight: {
    options: [
      {
        model: "greenMarker",
        class: "marker-green",
        title: "Green marker",
        color: "var(--ck-highlight-marker-green)",
        type: "marker",
      },
      {
        model: "yellowMarker",
        class: "marker-yellow",
        title: "Yellow marker",
        color: "var(--ck-highlight-marker-yellow)",
        type: "marker",
      },
      {
        model: "pinkMarker",
        class: "marker-pink",
        title: "Pink marker",
        color: "var(--ck-highlight-marker-pink)",
        type: "marker",
      },
      {
        model: "greenPen",
        class: "pen-green",
        title: "Green pen",
        color: "var(--ck-highlight-pen-green)",
        type: "pen",
      },
      {
        model: "yellowPen",
        class: "pen-yellow",
        title: "Yellow pen",
        color: "var(--ck-highlight-pen-yellow)",
        type: "pen",
      },
      {
        model: "redPen",
        class: "pen-red",
        title: "Red pen",
        color: "var(--ck-highlight-pen-red)",
        type: "pen",
      },
    ],
  },
};

function CKEditor5({ value, onChange = {} }) {
  return (
    <CKEditor
      editor={Editor}
      config={editorConfiguration}
      data={value || ""}
      onChange={(_, editor) => {
        const data = editor.getData();
        onChange(data);
      }}
      onReady={(editor) => {
        const data = editor.getData();
        onChange(data);
      }}
    />
  );
}

export default CKEditor5;
