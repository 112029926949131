//////////////////////////////////////////////////////////////////////////////////////////
//                        Set Redux state
//////////////////////////////////////////////////////////////////////////////////////////

var initialState = { code: -1, login: false, msg: "" };
const loginStatus = (state = initialState, { type, payload }) => {
  switch (type) {
    case "LOGIN_STATUS": {
      return (state = payload);
    }
    default:
      return state;
  }
};

export default loginStatus;
