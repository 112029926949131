import { FEEDBACK_DASHBOARD } from "../actionTypes";

const initialState = {
  searchJobPosting: "",
  filters: {
    status: "Active",
    client: null,
  },
  pagination: {
    activePage: 1,
    items: 10,
  },
};

const feedbackDashboard = (state = initialState, { type, payload }) => {
  switch (type) {
    case FEEDBACK_DASHBOARD.setState: {
      return { ...state, ...payload };
    }
    case FEEDBACK_DASHBOARD.clearState: {
      return initialState;
    }
    default:
      return state;
  }
};

export default feedbackDashboard;
