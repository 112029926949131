import {
  Box,
  Button,
  Chip,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import SearchIcon from "@material-ui/icons/Search";
import { Autocomplete } from "@material-ui/lab";
import Pagination from "@material-ui/lab/Pagination";
import axios from "axios";
import { useFormik } from "formik";
import React, { useCallback, useEffect, useState } from "react";
import * as yup from "yup";
import {
  createPreferredCompanyHierarchy,
  deletePreferredCompanyHierarchy,
  getPreferredCompanyHierarchy,
  getSinglePreferredCompanyHierarchy,
  updatePreferredCompanyHierarchy,
} from "../../../api/api";
import InputField from "../../../commonComponents/Controllers/InputField";
import DeleteDialog from "../../../commonComponents/DeleteDialog/DeleteDialog";
import Loader from "../../../commonComponents/Loader/Loader";
import SimpleModal from "../../../commonComponents/Modal/SimpleModal";
import CustomSnack from "../../../commonComponents/Snack/CustomSnack";
import "../../../styles/style.css";
import {
  CREATED_MESSAGE,
  DELETED_MESSAGE,
  SOMETHING_WENT_WRONG,
  UPDATED_MESSAGE,
} from "../../../utils/constants/constants";
import { debounce } from "../../../utils/constants/helpers";

export const Header = [
  {
    id: 1,
    name: "Parent",
    className: "thChild",
  },
  {
    id: 2,
    name: "Child List",
  },
  {
    id: 3,
    name: "Action",
    className: "th",
  },
];

export const Options = [
  {
    id: 1,
    value: "10",
  },
  {
    id: 2,
    value: "20",
  },
  {
    id: 3,
    value: "30",
  },
  {
    id: 4,
    value: "40",
  },
  {
    id: 5,
    value: "50",
  },
  {
    id: 6,
    value: "100",
  },
];

axios.defaults.xsrfHeaderName = "X-CSRFToken";
axios.defaults.xsrfCookieName = "csrftoken";

function Index() {
  const [preferredCompanyHierarchy, setPreferredCompanyHierarchy] = useState(
    []
  );

  const [snack, setSnack] = useState({
    open: false,
    color: "",
    message: "",
  });

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const [deleteId, setDeleteId] = useState(null);

  const [editId, setEditId] = useState(null);

  const [searchPreferredCompany, setSearchPreferredCompany] = useState("");

  const [totalCount, setTotalCount] = useState(0);

  const [pagination, setPagination] = useState({
    activePage: 1,
    items: 10,
  });

  const [
    preferredCompanyHierarchyDialogOpen,
    setPreferredCompanyHierarchyDialogOpen,
  ] = useState(false);

  const [loading, setLoading] = useState(false);

  const validationSchema = yup.object({
    company_name: yup
      .string()
      .nullable()
      .required("Please fill the required field"),
    // child_names: yup.array().min(
    //   1,
    //   // "Please fill the required field"
    //   "Company child name should not be empty"
    // ),
  });

  const handleSnackClose = () => {
    setSnack({
      open: false,
      color: "",
      message: "",
    });
  };

  const handleSave = (values, { resetForm }) => {
    let payload = {
      company_name: values.company_name,
      child_names: values.child_names,
    };

    if (editId) {
      // Edit Preferred Company Hierarchy
      axios
        .put(updatePreferredCompanyHierarchy(editId), payload)
        .then((response) => {
          setPreferredCompanyHierarchyDialogOpen(false);
          setSnack({
            open: true,
            color: "success",
            message: UPDATED_MESSAGE,
          });
          if (response.status === 200) {
            resetForm();
            setTimeout(() => {
              let offset =
                pagination.activePage === 0
                  ? 0
                  : (pagination.activePage - 1) * parseInt(pagination.items);
              getAndSetPreferredCompanyHierarchy(pagination.items, offset);
            }, 2500);
          } else {
            setSnack({
              open: true,
              color: "error",
              message: SOMETHING_WENT_WRONG,
            });
          }
        })
        .catch((err) => {
          setSnack({
            open: true,
            color: "error",
            message: SOMETHING_WENT_WRONG,
          });
        });
    } else {
      // Add Preferred Company Hierarchy
      axios
        .post(createPreferredCompanyHierarchy(), payload)
        .then((response) => {
          if (response.status === 201) {
            setPreferredCompanyHierarchyDialogOpen(false);
            setSnack({
              open: true,
              color: "success",
              message: CREATED_MESSAGE,
            });
            setTimeout(() => {
              resetForm();
              getAndSetPreferredCompanyHierarchy(pagination.items, 0);
              setPagination({
                activePage: 1,
                items: 10,
              });
            }, 2500);
          } else {
            setSnack({
              open: true,
              color: "error",
              message: SOMETHING_WENT_WRONG,
            });
          }
        })
        .catch((err) => {
          setSnack({
            open: true,
            color: "error",
            message: SOMETHING_WENT_WRONG,
          });
        });
    }
  };

  const formik = useFormik({
    initialValues: {
      company_name: "",
      child_names: [],
    },
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: handleSave,
  });

  const {
    values,
    setValues,
    errors,
    touched,
    setFieldValue,
    handleChange,
    handleSubmit,
    resetForm,
  } = formik;

  const getAndSetPreferredCompanyHierarchy = (
    limit,
    offset,
    search = searchPreferredCompany
  ) => {
    setLoading(true);
    axios
      .get(getPreferredCompanyHierarchy(), {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("tal_token")}`,
        },
        params: {
          limit: limit,
          offset: offset,
          search: search,
        },
      })
      .then((response) => {
        setPreferredCompanyHierarchy(response.data.results);
        setTotalCount(response.data.count);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        setSnack({
          open: true,
          color: "error",
          message: SOMETHING_WENT_WRONG,
        });
      });
  };

  useEffect(() => {
    getAndSetPreferredCompanyHierarchy(pagination.items, 0);
  }, []);

  const onClickEdit = (id) => {
    axios
      .get(getSinglePreferredCompanyHierarchy(id), {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("tal_token")}`,
        },
      })
      .then((response) => {
        setEditId(id);
        console.log(response.data)
        setValues({
          company_name: response?.data.company_name || "",
          child_names: response?.data.child_names || [],
        });
        setPreferredCompanyHierarchyDialogOpen(true);
      })
      .catch((error) => {
        setSnack({
          open: true,
          color: "error",
          message: SOMETHING_WENT_WRONG,
        });
      });
  };

  const onSelectPage = (e, value) => {
    let offset = value === 0 ? 0 : value - 1;
    getAndSetPreferredCompanyHierarchy(
      pagination.items,
      offset * pagination.items
    );

    setPagination({
      ...pagination,
      activePage: value,
    });
  };

  const handlePageLimitChange = (e) => {
    const { value } = e.target;
    getAndSetPreferredCompanyHierarchy(
      parseInt(value),
      (pagination.activePage - 1) * parseInt(value)
    );
    setPagination({
      ...pagination,
      items: parseInt(value),
    });
  };

  const handleDeleteClick = () => {
    axios
      .delete(deletePreferredCompanyHierarchy(deleteId))
      .then((response) => {
        setDeleteDialogOpen(false);
        setDeleteId(null);
        if (response.status === 204) {
          setSnack({
            open: true,
            color: "success",
            message: DELETED_MESSAGE,
          });
          setTimeout(() => {
            let offset =
              pagination.activePage === 0 ? 0 : pagination.activePage - 1;
            getAndSetPreferredCompanyHierarchy(
              pagination.items,
              offset * pagination.items
            );
          }, 2500);
        } else {
          setSnack({
            open: true,
            color: "error",
            message: SOMETHING_WENT_WRONG,
          });
        }
      })
      .catch((err) => {
        setDeleteDialogOpen(false);
        setDeleteId(null);
        setSnack({
          open: true,
          color: "error",
          message: SOMETHING_WENT_WRONG,
        });
      });
  };

  const handleSearchChange = (value) => {
    getAndSetPreferredCompanyHierarchy(pagination.items, 0, value);
    setSearchPreferredCompany(value);
  };

  const handleDeleteRowClick = (id) => {
    setDeleteId(id);
    setDeleteDialogOpen(true);
  };

  const handleDeleteCancel = () => {
    setDeleteDialogOpen(false);
    setDeleteId(null);
  };

  const handleAdd = () => {
    setPreferredCompanyHierarchyDialogOpen(true);
  };

  const optimizedFn = useCallback(debounce(handleSearchChange), [pagination]);

  const handlePreferredCompanyHierarchyDialogClose = () => {
    resetForm();
    setPreferredCompanyHierarchyDialogOpen(false);
    setEditId(null);
  };

  return (
    <Box>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Grid container spacing={4} alignItems={"flex-end"}>
            <Grid item xs>
              <Typography style={{ paddingBottom: "5px" }}>
                <b>{"Preferred Company"}</b>
              </Typography>
            </Grid>
            <Grid item>
              <TextField
                id={"search"}
                label={"Search"}
                onChange={(e) => optimizedFn(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton>
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item>
              <Button
                variant={"contained"}
                color={"primary"}
                onClick={handleAdd}
              >
                <b className={"capitalize"}>
                  {"Add Preferred Company Hierarchy"}
                </b>
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Table size={"small"} component={Paper} variant={"outlined"}>
            <TableHead>
              <TableRow>
                {Header.map(({ name, className }, ind) => (
                  <TableCell
                    className={className}
                    align={ind === 2 ? "center" : "inherit"}
                    key={`head-${ind + 1}`}
                  >
                    <Typography className={"tableHead"}>{name}</Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {!loading && preferredCompanyHierarchy?.length ? (
                preferredCompanyHierarchy.map(
                  ({ id, company_name, child_names }) => {
                    const childList = child_names?.length
                      ? child_names.join(", ")
                      : "-";
                    return (
                      <TableRow key={id}>
                        <TableCell>{company_name}</TableCell>
                        <TableCell>{childList}</TableCell>
                        <TableCell>
                          <div className={"editTab"}>
                            <IconButton
                              color={"primary"}
                              onClick={() => onClickEdit(id)}
                            >
                              <EditIcon />
                            </IconButton>
                            <IconButton
                              color={"secondary"}
                              onClick={() => handleDeleteRowClick(id)}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </div>
                        </TableCell>
                      </TableRow>
                    );
                  }
                )
              ) : (
                <TableRow>
                  <TableCell colSpan={3} align={"center"}>
                    {loading ? (
                      <Loader />
                    ) : (
                      <Typography>
                        <b>{"No results found"}</b>
                      </Typography>
                    )}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </Grid>
        <Grid
          item
          container
          xs={12}
          alignItems={"center"}
          justifyContent={"flex-end"}
        >
          <Typography>
            Total Count: <b>{totalCount}</b>
          </Typography>
        </Grid>
        <Grid item xs={2} container alignItems={"center"}>
          <FormControl style={{ width: 120 }}>
            <InputLabel id={"demo-simple-select-label"}>
              {"Rows per page"}
            </InputLabel>
            <Select
              labelId={"demo-simple-select-label"}
              id={"demo-simple-select"}
              value={pagination.items}
              onChange={handlePageLimitChange}
            >
              {Options.map(({ id, value }) => (
                <MenuItem key={id} value={value}>
                  {value}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid
          item
          xs={10}
          container
          alignItems={"center"}
          justifyContent={"flex-end"}
        >
          <Pagination
            color={"primary"}
            page={pagination.activePage}
            onChange={onSelectPage}
            count={Math.ceil(totalCount / pagination.items)}
            showFirstButton
            showLastButton
          />
        </Grid>
      </Grid>
      <DeleteDialog
        dialogOpen={deleteDialogOpen}
        handleClose={handleDeleteCancel}
        handleYesClick={handleDeleteClick}
      />
      <SimpleModal
        open={preferredCompanyHierarchyDialogOpen}
        title={`${editId ? "Edit" : "Add"} Preferred Company Hierarchy`}
        handleClose={handlePreferredCompanyHierarchyDialogClose}
        handleSave={handleSubmit}
      >
        <Box minHeight={"180px"}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <InputField
                id={"company_name"}
                label={"Company Name"}
                name={"company_name"}
                value={values.company_name || ""}
                onChange={handleChange}
                error={touched.company_name && Boolean(errors.company_name)}
                helperText={touched.company_name && errors.company_name}
              />
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                id={"tags-filled"}
                options={[]}
                value={values.child_names || []}
                onChange={(event, newValue) => {
                  setFieldValue("child_names", newValue);
                }}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      variant={"outlined"}
                      label={option}
                      {...getTagProps({ index })}
                    />
                  ))
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant={"outlined"}
                    label={"Company Child Name"}
                    error={
                      touched.child_names &&
                      Boolean(errors.child_names)
                    }
                    helperText={
                      touched.child_names &&
                      errors.child_names
                    }
                  />
                )}
                freeSolo
                multiple
              />
            </Grid>
          </Grid>
        </Box>
      </SimpleModal>
      <CustomSnack
        open={snack.open}
        color={snack.color}
        message={snack.message}
        onClose={handleSnackClose}
      />
    </Box>
  );
}

export default Index;
