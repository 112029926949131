import { Box, Button, Grid, Typography } from "@material-ui/core";
import React from "react";
import CKEditor5 from "../../../commonComponents/CKEditor/CKEditor";
import SimpleModal from "../../../commonComponents/Modal/SimpleModal";

function WeakWords({
  weakWordCheck,
  weakWordCheckData,
  wordHighlightList,
  handleWeakWordCheck,
  handleWeakWordCheckPopupClose,
  handleSaveWeakWordCheck,
  handleEditorValueChange,
  handleIgnoreWeakWord,
}) {
  return (
    <Box p={1}>
      <Box pb={2}>
        {wordHighlightList && wordHighlightList.length ? (
          <Typography align={"center"} variant={"h6"}>
            <b>{"Weak Words"}</b>
          </Typography>
        ) : null}
      </Box>
      <Box>
        {wordHighlightList && wordHighlightList.length !== 0 ? (
          wordHighlightList.map((wordHightLight, index) => {
            return (
              <Grid
                container
                spacing={3}
                style={{ paddingBottom: "10px" }}
                alignItems={"center"}
                key={`weak-word-${index}`}
              >
                {/* Grammar Check Sentence */}
                <Grid item xs>
                  <Typography>
                    {/* Grammar Check sentence before the check word */}
                    {wordHightLight.line.split(wordHightLight.word)[0]}

                    {/* use the class to highlight the Incorrect Word */}
                    <span className="weakWordHighlightWord">
                      {wordHightLight.word}
                    </span>

                    {/* Grammar Check sentence after the check word */}
                    {wordHightLight.line.split(wordHightLight.word)[1]}
                  </Typography>
                </Grid>
                <Grid item>
                  <Button
                    variant={"contained"}
                    color={"primary"}
                    size={"small"}
                    onClick={() =>
                      handleWeakWordCheck(
                        wordHightLight.line.replace(
                          wordHightLight.word,
                          `<mark class='marker-pink'>${wordHightLight.word}</mark>`
                        ),
                        wordHightLight.line
                      )
                    }
                  >
                    <b>{"Edit"}</b>
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant={"contained"}
                    color={"secondary"}
                    size={"small"}
                    onClick={() => handleIgnoreWeakWord(wordHightLight.line)}
                    style={{ fontWeight: "bold", minWidth: "78px" }}
                  >
                    {"Ignore"}
                  </Button>
                </Grid>
              </Grid>
            );
          })
        ) : (
          <Typography align={"center"}>
            <b>{"Weak words not found"}</b>
          </Typography>
        )}
      </Box>
      <SimpleModal
        title={"Edit The Weak Work"}
        open={weakWordCheck}
        handleClose={handleWeakWordCheckPopupClose}
        handleSave={handleSaveWeakWordCheck}
      >
        <CKEditor5
          value={weakWordCheckData?.word}
          onChange={(val) => handleEditorValueChange(val, "weakWordCheckData")}
        />
      </SimpleModal>
    </Box>
  );
}

export default WeakWords;
