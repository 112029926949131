import { Box, Container, Divider, Grid, Typography } from "@material-ui/core";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  getApplicantByID,
  getResumeDetails,
  updateResumeContent,
  getRoleList,
  getSkillList,
  compareResumeApi,
} from "../../api/api";
import Accordion from "../../commonComponents/Accordion/Accordion";
import BackNavigator from "../../commonComponents/BackNavigator/BackNavigator";
import CustomSnack from "../../commonComponents/Snack/CustomSnack";
import { FlexColumnView } from "../../commonComponents/StyledComponents";
import { MESSAGES } from "../../utils/constants/constants";
import { errorResponse } from "../../utils/constants/helpers";
import MisspelledWords from "./GrammarlyComponents/MisspelledWords";
import PunctuationList from "./GrammarlyComponents/PunctuationList";
import WeakWords from "./GrammarlyComponents/WeakWords";
import RepeatedWorkExperience from "./Components/RepeatedWorkExperience";
import AdditionalInfo from "./Components/AdditionalInfo";
import CustomMatch from "./Components/CustomMatch";
import MatchFactor from "./Components/MatchFactor";
import MatchResult from "./Components/MatchResult";

axios.defaults.xsrfHeaderName = "X-CSRFToken";
axios.defaults.xsrfCookieName = "csrftoken";

const ACCORDIONS = {
  basicInfo: "basicInfo",
  grammarCheck: "grammarCheck",
  weakWords: "weakWords",
  repeatedWorkExperience: "repeatedWorkExperience",
  additionalInfo: "additionalInfo",
  customMatch: "customMatch",
  matchFactor: "matchFactor,",
};

const DEFAULT_OPEN_ACCORDIONS = [
  ACCORDIONS.basicInfo,
  ACCORDIONS.grammarCheck,
  ACCORDIONS.weakWords,
  ACCORDIONS.repeatedWorkExperience,
  ACCORDIONS.additionalInfo,
  ACCORDIONS.customMatch,
  ACCORDIONS.matchFactor,
];

function GrammarChecker() {
  const { jobCode, applicantId } = useParams();
  const history = useHistory();
  const { reference_name } = history.location.state || {};
  const [snack, setSnack] = useState({
    open: false,
    color: "",
    message: "",
  });
  const [applicantDetails, setApplicantDetails] = useState(null);
  const [accordionExpanded, setAccordionExpanded] = useState(
    DEFAULT_OPEN_ACCORDIONS
  );
  const [grammarCheck, setGrammarCheck] = useState(false);
  const [misSpelledCheck, setMisSpelledCheck] = useState(false);
  const [weakWordCheck, setWeakWordCheck] = useState(false);
  const [grammarCheckData, setGrammarCheckData] = useState({});
  const [misSpelledCheckData, setMisSpelledCheckData] = useState({});
  const [weakWordCheckData, setWeakWordCheckData] = useState({});
  const [currentResumeData, setCurrentResumeData] = useState({});
  //Custom Match
  const [selectedSkill, setSelectedSkill] = useState([]);
  const [selectedTitle, setSelectedTitle] = useState([]);
  const [skillList, setSkillList] = useState([]);
  const [titleList, setTitleList] = useState([]);
  const [errormatch, setErrormatch] = useState("");
  const [custom, setCustom] = useState({});

  useEffect(() => {
    fetchSkillList();
    fetchTitleList();
  }, []);

  const fetchSkillList = () => {
    axios.get(getSkillList).then((res) => {
      if (res.status === 200) {
        const uniqueData = filterDuplicates(res?.data?.data);
        setSkillList(uniqueData);
      }
    });
  };
  const filterDuplicates = (data) => {
    const uniqueDisplays = new Set();
    return data.filter((item) => {
      if (!uniqueDisplays.has(item?.display)) {
        uniqueDisplays.add(item?.display);
        return true;
      }
      return false;
    });
  };
  const fetchTitleList = () => {
    axios.get(getRoleList).then((res) => {
      if (res.status === 200) {
        setTitleList(JSON.parse(res.data));
      }
    });
  };
  const handleMatch = () => {
    if (!selectedSkill.length && !selectedTitle.length) {
      setErrormatch("Please choose at least one skill or title.");

      return;
    }

    if (selectedSkill.length > 8) {
      setErrormatch("Maximum 8 skills allowed.");

      return;
    }

    let formData = new FormData();
    formData.append("applicant_id", applicantId);

    if (selectedSkill?.length) {
      formData.append(
        "skill",
        JSON.stringify({
          action: "skill",
          values: selectedSkill.map((option) => option.display),
        })
      );
    }

    if (selectedTitle?.length) {
      formData.append(
        "title",
        JSON.stringify({ action: "title", values: selectedTitle })
      );
    }

    axios
      .post(compareResumeApi, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((response) => {
        if (response) {
          setCustom(response?.data);
        }

        setSelectedSkill([]);
        setSelectedTitle([]);
        setErrormatch("");
        setSnack({
          open: true,
          color: "success",
          message: "Resume compared successfully.",
        });
      })
      .catch((err) => {
        setSnack({
          open: true,
          color: "error",
          message: "Failed to compare resumes. Please try again.",
        });
      });
  };

  const fetchResumeDetails = async () => {
    try {
      const res = await axios.get(getResumeDetails(), {
        params: {
          applicant_id: applicantId,
        },
      });
      return res;
    } catch (error) {
      return errorResponse(error);
    }
  };

  const handleSnackClose = () => {
    setSnack({
      open: false,
      color: "",
      message: "",
    });
  };

  const handleSnackOpen = (message = MESSAGES.error, color = "error") => {
    setSnack({
      open: true,
      color: color,
      message: message || MESSAGES.error,
    });
  };

  const getAndSetResumeDetails = async () => {
    const response = await fetchResumeDetails();
    if (response.status === 200) {
      setCurrentResumeData(response.data[0]);
    } else {
      handleSnackOpen(response.message);
    }
  };

  const getApplicant = () => {
    axios.get(getApplicantByID(applicantId)).then((response) => {
      if (response.status === 200) {
        setApplicantDetails(response.data);
      }
    });
  };

  useEffect(() => {
    getAndSetResumeDetails();
    getApplicant();
  }, []);

  const handleBack = () => {
    history.push(`/dashboard/${jobCode}/${reference_name || "applicants"}`);
  };

  const handleCollapsible = (value) => {
    let arr = [...accordionExpanded];
    let indexOf = arr.indexOf(value);
    if (indexOf >= 0) {
      arr.splice(indexOf, 1);
    } else {
      arr.push(value);
    }
    setAccordionExpanded(arr);
  };

  const handleGrammarCheck = (word, old_text) => {
    setGrammarCheck(true);
    setGrammarCheckData({ word, old_text });
  };

  const handleGrammarCheckPopupClose = () => {
    setGrammarCheck(false);
    setGrammarCheckData({});
  };

  const handleMisSpelledCheck = (word, old_text) => {
    setMisSpelledCheck(true);
    setMisSpelledCheckData({ word, old_text });
  };

  const handleMisSpelledCheckPopupClose = () => {
    setMisSpelledCheck(false);
    setMisSpelledCheckData({});
  };

  const handleWeakWordCheck = (word, old_text) => {
    setWeakWordCheck(true);
    setWeakWordCheckData({ word, old_text });
  };

  const handleWeakWordCheckPopupClose = () => {
    setWeakWordCheck(false);
    setWeakWordCheckData({});
  };

  const handleEditorValueChange = (value, name) => {
    switch (name) {
      case "grammarCheckData":
        var obj = grammarCheckData;
        obj.word = value;
        setGrammarCheckData(obj);
        break;
      case "misSpelledCheckData":
        var obj = misSpelledCheckData;
        obj.word = value;
        setMisSpelledCheckData(obj);
        break;
      case "weakWordCheckData":
        var obj = weakWordCheckData;
        obj.word = value;
        setWeakWordCheckData(obj);
        break;
      default:
        break;
    }
  };

  const replaceResumeContent = async (payload = {}) => {
    if (payload.replace_text) {
      try {
        const res = await axios.post(updateResumeContent(), payload);
        if (res.status === 200) {
          handleSnackOpen("Updated Successfully", "success");
          getAndSetResumeDetails();

          switch (payload.type) {
            case "grammer_check":
              handleGrammarCheckPopupClose();
              break;
            case "punctuation":
              handleMisSpelledCheckPopupClose();
              break;
            case "weak_words":
              handleWeakWordCheckPopupClose();
              break;
            default:
              break;
          }
        } else {
          handleSnackOpen();
        }
      } catch (error) {
        handleSnackOpen(errorResponse(error).message);
      }
    } else {
      handleSnackOpen("Field required");
    }
  };

  const ignoreResumeContent = async (payload = {}) => {
    try {
      const res = await axios.post(updateResumeContent(), payload);
      if (res.status === 200) {
        handleSnackOpen("Ignored Successfully", "success");
        getAndSetResumeDetails();
      } else {
        handleSnackOpen();
      }
    } catch (error) {
      handleSnackOpen(errorResponse(error).message);
    }
  };

  const handleSaveGrammarCheckWork = () => {
    let payload = {
      applicant_id: applicantId,
      old_text: grammarCheckData.old_text,
      replace_text: grammarCheckData.word,
      type: "grammer_check",
    };
    replaceResumeContent(payload);
  };

  const handleSaveMisSpelledCheck = () => {
    let payload = {
      applicant_id: applicantId,
      old_text: misSpelledCheckData.old_text,
      replace_text: misSpelledCheckData.word,
      type: "punctuation",
    };
    replaceResumeContent(payload);
  };

  const handleSaveWeakWordCheck = () => {
    let payload = {
      applicant_id: applicantId,
      old_text: weakWordCheckData.old_text,
      replace_text: weakWordCheckData.word,
      type: "weak_words",
    };
    replaceResumeContent(payload);
  };

  const handleIgnoreGrammarCheck = (old_text) => {
    let payload = {
      applicant_id: applicantId,
      old_text: old_text,
      ignore: true,
      type: "grammer_check",
    };
    ignoreResumeContent(payload);
  };

  const handleIgnorePunctuationCheck = (old_text) => {
    let payload = {
      applicant_id: applicantId,
      old_text: old_text,
      ignore: true,
      type: "punctuation",
    };
    ignoreResumeContent(payload);
  };

  const handleIgnoreWeakWord = (old_text) => {
    let payload = {
      applicant_id: applicantId,
      old_text: old_text,
      ignore: true,
      type: "weak_words",
    };
    ignoreResumeContent(payload);
  };

  return (
    <Container
      className={"container-style mt-5"}
      style={{ maxWidth: "1440px" }}
    >
      <Box
        display={"flex"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <BackNavigator handleBack={handleBack} title={"Applicant Details"} />
        {applicantDetails ? (
          <Typography>
            <b>{`Name: ${applicantDetails.firstname}${
              applicantDetails.lastname ? ` ${applicantDetails.lastname}` : ""
            }`}</b>
          </Typography>
        ) : null}
      </Box>

      <Box pt={3}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Grid container spacing={2}>
              {/* Basic Info */}
              <Grid item xs={12}>
                <Accordion
                  open={accordionExpanded.includes(ACCORDIONS.basicInfo)}
                  title={"Basic Info"}
                  name={ACCORDIONS.basicInfo}
                  handleOpen={handleCollapsible}
                >
                  <Grid container spacing={3}>
                    <Grid item xs={2}>
                      <b>{"First Name"}</b>
                    </Grid>
                    <Grid item xs={1}>
                      <b>{":"}</b>
                    </Grid>
                    <Grid item xs={9}>
                      <Typography
                        variant="body1"
                        fontWeight="bold"
                        component="div"
                      >
                        {currentResumeData.first_name}
                      </Typography>
                    </Grid>

                    <Grid item xs={2}>
                      <b>{"Last Name"}</b>
                    </Grid>
                    <Grid item xs={1}>
                      <b>{":"}</b>
                    </Grid>
                    <Grid item xs={9}>
                      <Typography
                        variant="body1"
                        fontWeight="bold"
                        component="div"
                      >
                        {currentResumeData.last_name}
                      </Typography>
                    </Grid>

                    <Grid item xs={2}>
                      <b>{"Phone"}</b>
                    </Grid>
                    <Grid item xs={1}>
                      <b>{":"}</b>
                    </Grid>
                    <Grid item xs={9}>
                      <Typography
                        variant="body1"
                        fontWeight="bold"
                        component="div"
                      >
                        {currentResumeData.phone}
                      </Typography>
                    </Grid>

                    <Grid item xs={2}>
                      <b>{"Email"}</b>
                    </Grid>
                    <Grid item xs={1}>
                      <b>{":"}</b>
                    </Grid>
                    <Grid item xs={9}>
                      <Typography
                        variant="body1"
                        fontWeight="bold"
                        component="div"
                      >
                        {currentResumeData.email}
                      </Typography>
                    </Grid>

                    <Grid item xs={2}>
                      <b>{"LinkedIn"}</b>
                    </Grid>
                    <Grid item xs={1}>
                      <b>{":"}</b>
                    </Grid>
                    <Grid item xs={9}>
                      <Typography
                        variant="body1"
                        fontWeight="bold"
                        component="div"
                      >
                        {currentResumeData.linkedin}
                      </Typography>
                    </Grid>
                  </Grid>
                </Accordion>
              </Grid>
              {/* Grammar Check */}
              <Grid item xs={12}>
                <Accordion
                  open={accordionExpanded.includes(ACCORDIONS.grammarCheck)}
                  title={"Grammar Check"}
                  name={ACCORDIONS.grammarCheck}
                  handleOpen={handleCollapsible}
                >
                  <FlexColumnView gridGap={"15px"}>
                    <MisspelledWords
                      wordHighlightList={currentResumeData.grammar_check}
                      grammarCheck={grammarCheck}
                      grammarCheckData={grammarCheckData}
                      handleGrammarCheck={handleGrammarCheck}
                      handleGrammarCheckPopupClose={
                        handleGrammarCheckPopupClose
                      }
                      handleIgnoreGrammarCheck={handleIgnoreGrammarCheck}
                      handleEditorValueChange={handleEditorValueChange}
                      handleSaveGrammarCheckWork={handleSaveGrammarCheckWork}
                    />
                    <Divider />
                    <PunctuationList
                      punctuationList={currentResumeData.punctuation_check_list}
                      misSpelledCheck={misSpelledCheck}
                      handleMisSpelledCheck={handleMisSpelledCheck}
                      handleEditorValueChange={handleEditorValueChange}
                      handleMisSpelledCheckPopupClose={
                        handleMisSpelledCheckPopupClose
                      }
                      handleSaveMisSpelledCheck={handleSaveMisSpelledCheck}
                      misSpelledCheckData={misSpelledCheckData}
                      handleIgnorePunctuationCheck={
                        handleIgnorePunctuationCheck
                      }
                    />
                  </FlexColumnView>
                </Accordion>
              </Grid>
              {/* Weak Words */}
              <Grid item xs={12}>
                <Accordion
                  open={accordionExpanded.includes(ACCORDIONS.weakWords)}
                  title={"Weak Words"}
                  name={ACCORDIONS.weakWords}
                  handleOpen={handleCollapsible}
                >
                  <WeakWords
                    weakWordCheck={weakWordCheck}
                    weakWordCheckData={weakWordCheckData}
                    wordHighlightList={currentResumeData.irrelevant_line_items}
                    handleWeakWordCheck={handleWeakWordCheck}
                    handleWeakWordCheckPopupClose={
                      handleWeakWordCheckPopupClose
                    }
                    handleSaveWeakWordCheck={handleSaveWeakWordCheck}
                    handleEditorValueChange={handleEditorValueChange}
                    handleIgnoreWeakWord={handleIgnoreWeakWord}
                  />
                </Accordion>
              </Grid>
              {/* Repeated Work Experience */}
              <Grid item xs={12}>
                <Accordion
                  open={accordionExpanded.includes(
                    ACCORDIONS.repeatedWorkExperience
                  )}
                  title={"Repeated Work Experience"}
                  name={ACCORDIONS.repeatedWorkExperience}
                  handleOpen={handleCollapsible}
                >
                  <RepeatedWorkExperience
                    repeatedWorkExp={currentResumeData.repeated_work_exp}
                  />
                </Accordion>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Grid container spacing={2}>
              {/* Additional Info */}
              <Grid item xs={12}>
                <Accordion
                  open={accordionExpanded.includes(ACCORDIONS.additionalInfo)}
                  title={"Additional Info"}
                  name={ACCORDIONS.additionalInfo}
                  handleOpen={handleCollapsible}
                >
                  <AdditionalInfo flags={currentResumeData.flags} />
                </Accordion>
              </Grid>
              {/* Custom Match */}
              <Grid item xs={12}>
                <Accordion
                  open={accordionExpanded.includes(ACCORDIONS.customMatch)}
                  title={"Custom Match"}
                  name={ACCORDIONS.customMatch}
                  handleOpen={handleCollapsible}
                >
                  <CustomMatch
                    setSelectedSkill={setSelectedSkill}
                    setSelectedTitle={setSelectedTitle}
                    selectedTitle={selectedTitle}
                    selectedSkill={selectedSkill}
                    skillList={skillList}
                    titleList={titleList}
                    errormatch={errormatch}
                    handleMatch={handleMatch}
                  />
                  <MatchResult
                    custom={custom}
                    currentResumeData={currentResumeData}
                  />
                </Accordion>
              </Grid>
              {/* Match Factor */}
              <Grid item xs={12}>
                <Accordion
                  open={accordionExpanded.includes(ACCORDIONS.matchFactor)}
                  title={"Match Factor"}
                  name={ACCORDIONS.matchFactor}
                  handleOpen={handleCollapsible}
                >
                  <MatchFactor
                    inferredTitle={currentResumeData.inferred_title}
                    keywordScore={currentResumeData.keyword_score}
                  />
                </Accordion>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>

      <CustomSnack
        open={snack.open}
        color={snack.color}
        message={snack.message}
        onClose={handleSnackClose}
      />
    </Container>
  );
}

export default GrammarChecker;
