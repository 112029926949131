import API from "../API/resumeAPI";
import * as c from "./constants";

import $ from "jquery";
import moment from "moment";

export const FILE_UPLOAD_RESUME_LIMIT = 10;
export const CUSTOM_MATCH_REPORT_LIMIT = 10;

// Processed Resume Json from backend

// fileValues = {
// 	progress: 100 or 10 ,
// 	isSelected: true or false,
// 	status: error or completed or processing or overwritten,
// 	userMsg: 'Please enter name or please enter contact information, etc.,',
//  userMsgCatergory : email or phone oe linkedin
// 	customMatchDetails: {action:'',values: [], found_list:[]},
// 	iscustomMatched : true or false
// };

export const UPLOADED_RESUME_RESULTS_OBJ_PROGRESS = "progress";
export const UPLOADED_RESUME_RESULTS_OBJ_IS_SELECTED = "isSelected";
export const UPLOADED_RESUME_RESULTS_OBJ_PROCESSED_STATUS = "processedStatus";
export const UPLOADED_RESUME_RESULTS_OBJ_UPLOADED_USER_MSG = "uploadedUserMsg";
export const UPLOADED_RESUME_RESULTS_OBJ_UPLOADED_USER_MSG_CATERGORY =
  "uploadedUserMsgCatergory";
export const UPLOADED_RESUME_RESULTS_OBJ_IS_CUSTOM_MATCHED = "iscustomMatched";
export const UPLOADED_RESUME_RESULTS_OBJ_CUSTOM_MATCHED_DETAILS =
  "customMatchedDetails";

// Information not displayed in the front end
export const PROCESSED_RESUME_JSON_FILE_NAME = "file_name";
export const PROCESSED_RESUME_JSON_PDF_LINK = "pdf_link";

// Information displayed in text box
export const PROCESSED_RESUME_JSON_NAME = "name";
export const PROCESSED_RESUME_JSON_EMAIL = "email";
export const PROCESSED_RESUME_JSON_PHONE = "phone";
export const PROCESSED_RESUME_JSON_ADDRESS = "address";
export const PROCESSED_RESUME_JSON_LINKEDIN = "linkedin";
export const PROCESSED_RESUME_JSON_LATEST_TITLE = "latest_title";

// Information displayed in text Area
export const PROCESSED_RESUME_JSON_SUMMARY = "summary";
export const PROCESSED_RESUME_JSON_SKILL_MATRIX = "skill_matrix";
export const PROCESSED_RESUME_JSON_EDUCATION = "education";
export const PROCESSED_RESUME_JSON_CERTIFICATION = "certification";

// Information displayed under Work Experience Section
export const PROCESSED_RESUME_JSON_WORK_EXPERIENCE = "work_experience";
export const PROCESSED_RESUME_JSON_WORK_EXPERIENCE_CLIENT = "client";
export const PROCESSED_RESUME_JSON_WORK_EXPERIENCE_LOCATION = "location";
export const PROCESSED_RESUME_JSON_WORK_EXPERIENCE_DURATION = "duration";
export const PROCESSED_RESUME_JSON_WORK_EXPERIENCE_ROLE = "role";
export const PROCESSED_RESUME_JSON_WORK_EXPERIENCE_PROJECT_DETAILS =
  "project_details";
export const PROCESSED_RESUME_JSON_WORK_EXPERIENCE_ENVIORNMENT = "environment";

// Information displayed under Top Skills Section
export const PROCESSED_RESUME_JSON_TOP_SKILLS = "top_skills";
export const PROCESSED_RESUME_JSON_TOP_SKILLS_HIERARCHY =
  "top_skills_hierarchy";

export const PROCESSED_RESUME_JSON_CUSTOM_MATCH_SKILL_DISPLAY =
  "skills_display";

export const PROCESSED_RESUME_JSON_TOP_SKILLS_SKILL_NAME = "skill";
export const PROCESSED_RESUME_JSON_TOP_SKILLS_TIME = "time";
export const PROCESSED_RESUME_JSON_TOP_SKILLS_COUNT = "count";
export const PROCESSED_RESUME_JSON_TOP_SKILLS_RECENT_PROJECTS = "recent";
export const PROCESSED_RESUME_JSON_TOP_SKILLS_IN_RESPONSIBILITIES =
  "responsibilities";
export const PROCESSED_RESUME_JSON_TOP_SKILLS_IN_ENVIORNMENT = "environment";
export const PROCESSED_RESUME_JSON_TOP_SKILLS_IN_OTHERS = "others";

export const MAPPING_PROCESSED_RESUME_JSON_WITH_LABEL = {};
MAPPING_PROCESSED_RESUME_JSON_WITH_LABEL[PROCESSED_RESUME_JSON_NAME] = "Name";
MAPPING_PROCESSED_RESUME_JSON_WITH_LABEL[PROCESSED_RESUME_JSON_EMAIL] = "Email";

MAPPING_PROCESSED_RESUME_JSON_WITH_LABEL[PROCESSED_RESUME_JSON_PHONE] = "Phone";
MAPPING_PROCESSED_RESUME_JSON_WITH_LABEL[PROCESSED_RESUME_JSON_ADDRESS] =
  "Address";
MAPPING_PROCESSED_RESUME_JSON_WITH_LABEL[PROCESSED_RESUME_JSON_LINKEDIN] =
  "LinkedIn";

// Information displayed in text Area
MAPPING_PROCESSED_RESUME_JSON_WITH_LABEL[PROCESSED_RESUME_JSON_SUMMARY] =
  "Summary";
MAPPING_PROCESSED_RESUME_JSON_WITH_LABEL[PROCESSED_RESUME_JSON_SKILL_MATRIX] =
  "Skill Matrix";
MAPPING_PROCESSED_RESUME_JSON_WITH_LABEL[PROCESSED_RESUME_JSON_EDUCATION] =
  "Education";
MAPPING_PROCESSED_RESUME_JSON_WITH_LABEL[PROCESSED_RESUME_JSON_CERTIFICATION] =
  "Certification";

// Information displayed under Work Experience Section
MAPPING_PROCESSED_RESUME_JSON_WITH_LABEL[
  PROCESSED_RESUME_JSON_WORK_EXPERIENCE_CLIENT
] = "Client";
MAPPING_PROCESSED_RESUME_JSON_WITH_LABEL[
  PROCESSED_RESUME_JSON_WORK_EXPERIENCE_LOCATION
] = "Location";
MAPPING_PROCESSED_RESUME_JSON_WITH_LABEL[
  PROCESSED_RESUME_JSON_WORK_EXPERIENCE_DURATION
] = "Duration";
MAPPING_PROCESSED_RESUME_JSON_WITH_LABEL[
  PROCESSED_RESUME_JSON_WORK_EXPERIENCE_ROLE
] = "Role";
MAPPING_PROCESSED_RESUME_JSON_WITH_LABEL[
  PROCESSED_RESUME_JSON_WORK_EXPERIENCE_ENVIORNMENT
] = "Environment";
MAPPING_PROCESSED_RESUME_JSON_WITH_LABEL[
  PROCESSED_RESUME_JSON_WORK_EXPERIENCE_PROJECT_DETAILS
] = "Responsibilities";

// Mandatory Fields
export const PROCESSED_RESUME_BASIC_INFO_MANDATORY_FIELDS = [
  PROCESSED_RESUME_JSON_NAME,
  PROCESSED_RESUME_JSON_EMAIL,
  PROCESSED_RESUME_JSON_PHONE,
];

// Basic Info section Fields
export const PROCESSED_RESUME_BASIC_INFO_FIELDS = [
  PROCESSED_RESUME_JSON_NAME,
  PROCESSED_RESUME_JSON_EMAIL,
  PROCESSED_RESUME_JSON_PHONE,
  PROCESSED_RESUME_JSON_ADDRESS,
  PROCESSED_RESUME_JSON_LINKEDIN,
  PROCESSED_RESUME_JSON_LATEST_TITLE,
  PROCESSED_RESUME_JSON_SUMMARY,
  PROCESSED_RESUME_JSON_SKILL_MATRIX,
  PROCESSED_RESUME_JSON_EDUCATION,
  PROCESSED_RESUME_JSON_CERTIFICATION,
];

// Basic Info Fields with textbox
export const PROCESSED_RESUME_BASIC_INFO_TEXTBOX = [
  PROCESSED_RESUME_JSON_NAME,
  PROCESSED_RESUME_JSON_EMAIL,
  PROCESSED_RESUME_JSON_PHONE,
  PROCESSED_RESUME_JSON_ADDRESS,
  PROCESSED_RESUME_JSON_LINKEDIN,
  PROCESSED_RESUME_JSON_LATEST_TITLE,
];

// Basic Info Fields with textarea
export const PROCESSED_RESUME_BASIC_INFO_TEXTAREA = [
  PROCESSED_RESUME_JSON_SUMMARY,
  PROCESSED_RESUME_JSON_SKILL_MATRIX,
  PROCESSED_RESUME_JSON_EDUCATION,
  PROCESSED_RESUME_JSON_CERTIFICATION,
];

// Work Experience section Fields
export const PROCESSED_RESUME_WORK_EXPERIENCE_FIELDS = [
  PROCESSED_RESUME_JSON_WORK_EXPERIENCE_CLIENT,
  PROCESSED_RESUME_JSON_WORK_EXPERIENCE_LOCATION,
  PROCESSED_RESUME_JSON_WORK_EXPERIENCE_DURATION,
  PROCESSED_RESUME_JSON_WORK_EXPERIENCE_ROLE,
  PROCESSED_RESUME_JSON_WORK_EXPERIENCE_PROJECT_DETAILS,
  PROCESSED_RESUME_JSON_WORK_EXPERIENCE_ENVIORNMENT,
];

//Work Experience Fields with textbox
export const PROCESSED_RESUME_WORK_EXPERIENCE_TEXTBOX = [
  PROCESSED_RESUME_JSON_WORK_EXPERIENCE_CLIENT,
  PROCESSED_RESUME_JSON_WORK_EXPERIENCE_LOCATION,
  PROCESSED_RESUME_JSON_WORK_EXPERIENCE_DURATION,
  PROCESSED_RESUME_JSON_WORK_EXPERIENCE_ROLE,
];

//Work Experience Fields with textarea
export const PROCESSED_RESUME_WORK_EXPERIENCE_TEXTAREA = [
  PROCESSED_RESUME_JSON_WORK_EXPERIENCE_ENVIORNMENT,
  PROCESSED_RESUME_JSON_WORK_EXPERIENCE_PROJECT_DETAILS,
];

//Top Skills Fields with "Readonly" textbox
export const PROCESSED_RESUME_TOP_SKILLS_TEXTBOX = [
  PROCESSED_RESUME_JSON_TOP_SKILLS_SKILL_NAME,
  PROCESSED_RESUME_JSON_TOP_SKILLS_COUNT,
  PROCESSED_RESUME_JSON_TOP_SKILLS_TIME,
];

//Top Skills Fields with "Hidden" textbox
export const PROCESSED_RESUME_TOP_SKILLS_HIDDEN = [
  PROCESSED_RESUME_JSON_TOP_SKILLS_RECENT_PROJECTS,
  PROCESSED_RESUME_JSON_TOP_SKILLS_IN_RESPONSIBILITIES,
  PROCESSED_RESUME_JSON_TOP_SKILLS_IN_ENVIORNMENT,
  PROCESSED_RESUME_JSON_TOP_SKILLS_IN_OTHERS,
];

export const formatProcessedResumeJson = (originalJson) => {
  let formattedObj = {};

  for (let key in originalJson) {
    // For every Basic Info Details and Work Experience, remove the inference Array format
    if (
      PROCESSED_RESUME_BASIC_INFO_TEXTBOX.indexOf(key) >= 0 ||
      PROCESSED_RESUME_BASIC_INFO_TEXTAREA.indexOf(key) >= 0 ||
      PROCESSED_RESUME_JSON_WORK_EXPERIENCE === key
    ) {
      if (originalJson[key][0] && originalJson[key][0]["inference"])
        formattedObj[key] = originalJson[key][0]["inference"];
      else
        formattedObj[key] =
          originalJson[0] === undefined ? "" : originalJson[0];

      if (PROCESSED_RESUME_JSON_WORK_EXPERIENCE === key) {
        let workExperienceDetails = formattedObj[key];
        for (let workExpindex in workExperienceDetails) {
          Object.entries(workExperienceDetails[workExpindex]).forEach(
            ([workExpKey, value]) => {
              if (
                workExpKey ===
                PROCESSED_RESUME_JSON_WORK_EXPERIENCE_PROJECT_DETAILS
              )
                workExperienceDetails[workExpindex][workExpKey] =
                  value.length >= 1 ? value.join("\n") : value;
              // else if (workExpKey === PROCESSED_RESUME_JSON_WORK_EXPERIENCE_DURATION && value[0].indexOf('Dec 2999') >0)
              // workExperienceDetails[workExpindex][workExpKey] = value[0].replace('Dec 2999','present');
              else if (workExpKey !== "duration_for_calculation")
                workExperienceDetails[workExpindex][workExpKey] = value[0];

              if (
                workExpKey === PROCESSED_RESUME_JSON_WORK_EXPERIENCE_ROLE ||
                workExpKey === PROCESSED_RESUME_JSON_WORK_EXPERIENCE_ENVIORNMENT
              )
                workExperienceDetails[workExpindex][workExpKey] =
                  value[0].length >= 1 ? value.join("\n") : value;
            }
          );
        }
        formattedObj[key] = workExperienceDetails;
      }
    }

    // Once the inference is removed,Remove the Extra Array format from Work Experience object
    else if (
      PROCESSED_RESUME_JSON_TOP_SKILLS === key ||
      PROCESSED_RESUME_JSON_TOP_SKILLS_HIERARCHY === key
    ) {
      formattedObj[key] = formatSkillsInJson(originalJson[key]);
    } else formattedObj[key] = originalJson[key];
  }
  formattedObj[PROCESSED_RESUME_JSON_CUSTOM_MATCH_SKILL_DISPLAY] = [];
  // console.log('work_experience',formattedObj[PROCESSED_RESUME_JSON_WORK_EXPERIENCE]);
  return formattedObj;
};

export const formatSkillsInJson = (topSkillsOriginalArr) => {
  var topSkillsFormattedObjArr = [];
  for (let index in topSkillsOriginalArr) {
    let topSkillsFormattedObj = {};
    topSkillsFormattedObj[PROCESSED_RESUME_JSON_TOP_SKILLS_SKILL_NAME] =
      topSkillsOriginalArr[index]["skill_name"] + "";
    topSkillsFormattedObj[PROCESSED_RESUME_JSON_TOP_SKILLS_COUNT] =
      topSkillsOriginalArr[index]["count"] + "";
    topSkillsFormattedObj[PROCESSED_RESUME_JSON_TOP_SKILLS_TIME] =
      topSkillsOriginalArr[index]["time"] + "";
    topSkillsFormattedObj[PROCESSED_RESUME_JSON_TOP_SKILLS_RECENT_PROJECTS] =
      topSkillsOriginalArr[index]["recent_projects"] + "";
    topSkillsFormattedObj[
      PROCESSED_RESUME_JSON_TOP_SKILLS_IN_RESPONSIBILITIES
    ] = topSkillsOriginalArr[index]["in_responsibilities"] + "";
    topSkillsFormattedObj[PROCESSED_RESUME_JSON_TOP_SKILLS_IN_ENVIORNMENT] =
      topSkillsOriginalArr[index]["in_environment"] + "";
    topSkillsFormattedObj[PROCESSED_RESUME_JSON_TOP_SKILLS_IN_OTHERS] =
      topSkillsOriginalArr[index]["in_others"] + "";
    topSkillsFormattedObjArr.push(topSkillsFormattedObj);
  }
  return topSkillsFormattedObjArr;
};

export const formatCustomSkillMatchJsonFromResponse = (
  customMatchResponseDetails,
  customMatchQueryObj
) => {
  var activityTime = moment(new Date())
    .utc()
    .format("YYYY-MM-DD HH:mm:ss.SSSSSSZ");

  let reportObj = {};

  console.log(customMatchQueryObj, "++++++++++++");

  reportObj.resumeNames = customMatchQueryObj.resumeNames;
  reportObj.job_id = customMatchQueryObj.id;
  reportObj.timeStamp = activityTime;
  reportObj.downloadFlag = false;

  reportObj.skill = customMatchQueryObj.skill;
  reportObj.title = customMatchQueryObj.title;

  /**  Different Format Not used anymore **/
  // reportObj.action = customMatchQueryObj.action;
  // reportObj.values = customMatchQueryObj.values;
  // let reportObjResumeArr = [];

  let reportObjResumeObj = {};
  let reportObjTitleObj = [];

  customMatchResponseDetails.map((detail) => {
    let fileName = detail.file_name;

    if (
      customMatchQueryObj.title.values &&
      customMatchQueryObj.title.values.length > 0
    )
      reportObjTitleObj.push(detail.title);

    let skillDetail = detail.skill;
    if (
      customMatchQueryObj.skill.values &&
      customMatchQueryObj.skill.values.length > 0
    ) {
      let nonEmptySkillDisplay = skillDetail.skills_display.filter(
        (value) => value.matched_skills !== ""
      );

      customMatchQueryObj.skill.values.forEach((matchSkill) => {
        if (
          skillDetail.not_found_list.length === 0 ||
          matchSkill.indexOf(skillDetail.not_found_list) < 0
        )
          var skillDisplayValues = nonEmptySkillDisplay.filter(
            (value) => value.matched_skills.indexOf(matchSkill) >= 0
          );

        if (skillDisplayValues === undefined) skillDisplayValues = [];

        if (reportObjResumeObj[matchSkill])
          reportObjResumeObj[matchSkill][fileName] = skillDisplayValues;
        else
          reportObjResumeObj[matchSkill] = { [fileName]: skillDisplayValues };
      });
    }

    return null;
    /**  Different Format Not used anymore **/
    // reportObjResumeArr.push(formatSkillMatchResumeList(detail,customMatchQueryObj));
  });

  /**  Different Format Not used anymore **/
  // reportObj.skillMatchResumeList = reportObjResumeArr;

  reportObj.skillMatchSkillObj = reportObjResumeObj;
  reportObj.titleMatchResumeList = reportObjTitleObj;
  return reportObj;
};

/**  Different Format Not used anymore **/
export const formatSkillMatchResumeList = (detail, customMatchQueryObj) => {
  let nonEmptySkillDisplay = detail.skills_display.filter(
    (value) => value.matched_skills !== ""
  );

  let reportObjResumeListObj = {};
  reportObjResumeListObj.file_name = detail.file_name;
  reportObjResumeListObj.not_found_list = detail.not_found_list;

  // removes the skill details that is not a match
  customMatchQueryObj.skill.values.forEach((matchSkill) => {
    if (
      detail.not_found_list.length === 0 ||
      matchSkill.indexOf(detail.not_found_list) < 0
    )
      reportObjResumeListObj[matchSkill] = nonEmptySkillDisplay.filter(
        (value) => value.matched_skills.indexOf(matchSkill) >= 0
      );
  });

  return reportObjResumeListObj;
};

// export const downloadReport = (customMatchResultSkillTableValues,timeStamp) =>
// {
//     let downloadReportJson = {};
//     var formData = new FormData();

//     customMatchResultSkillTableValues.forEach((element,index) => {
//         console.log(element)

//         if (timeStamp === '' || timeStamp === element.timeStamp)
//         {
//                 downloadReportJson[element.timeStamp] = {
//                     action : element.action,
//                     values : element.values,
//                     job_id : element.job_id ?  element.job_id : '',
//                     match_report : element.titleMatchResumeList
//                 }

//         }

//     formData.append((element.timeStamp), downloadReportJson);
//     });
//     // $('#skillMatchReportPopupTrigger').click();
//     downloadReportAPI(downloadReportJson);
// }

export const downloadReport = (
  customMatchResultSkillTableValues,
  timeStamp
) => {
  let downloadReportJson = {};
  var formData = new FormData();

  customMatchResultSkillTableValues.forEach((element, index) => {
    console.log(element);

    if (timeStamp === "" || timeStamp === element.timeStamp) {
      downloadReportJson[element.timeStamp] = {
        action: element.action,
        skill_values: element.skill.values,
        title_values: element.title.values,
        job_id: element.job_id ? element.job_id : "",
        title_match_report: element.titleMatchResumeList,
        skill_match_report: element.skillMatchSkillObj,
        resume_names: element.resumeNames,
      };
    }

    formData.append(element.timeStamp, downloadReportJson);
  });
  // $('#skillMatchReportPopupTrigger').click();
  downloadReportAPI(downloadReportJson);
};

export const downloadReportAPI = (downloadReportJson) => {
  API.customMatchDownloadReport(downloadReportJson)
    .then((res) => {
      console.log("Download Report Success", res.data);
      $("#downloadReportLink").attr(
        "href",
        "/resume_intl/custom_match_report/?path=" + res.data.file_name
      );
      $("#downloadReportLink")[0].click();
    })
    .catch((error) => {
      // Error
      c.APIErrorResponse(error, "Download Report Failure");
    });
};

export const validationBeforeSavingResume = (
  actionJson,
  resumeJson,
  isSelected,
  status,
  inUploadResumePage
) => {
  let userMsg = "";
  let userMsgCatergory = "";

  let name = "";
  let phone = "";
  let email = "";
  let linkedIn = "";
  let workExperience = [];

  if (actionJson === "uploadJson") {
    name =
      resumeJson[PROCESSED_RESUME_JSON_NAME] &&
      resumeJson[PROCESSED_RESUME_JSON_NAME].length > 0
        ? resumeJson[PROCESSED_RESUME_JSON_NAME][0]["inference"]
        : "";
    phone =
      resumeJson[PROCESSED_RESUME_JSON_PHONE] &&
      resumeJson[PROCESSED_RESUME_JSON_PHONE].length > 0
        ? resumeJson[PROCESSED_RESUME_JSON_PHONE][0]["inference"]
        : "";
    email =
      resumeJson[PROCESSED_RESUME_JSON_EMAIL] &&
      resumeJson[PROCESSED_RESUME_JSON_EMAIL].length > 0
        ? resumeJson[PROCESSED_RESUME_JSON_EMAIL][0]["inference"]
        : "";
    linkedIn =
      resumeJson[PROCESSED_RESUME_JSON_LINKEDIN] &&
      resumeJson[PROCESSED_RESUME_JSON_LINKEDIN].length > 0
        ? resumeJson[PROCESSED_RESUME_JSON_LINKEDIN][0]["inference"]
        : "";
    workExperience =
      resumeJson[PROCESSED_RESUME_JSON_WORK_EXPERIENCE] &&
      resumeJson[PROCESSED_RESUME_JSON_WORK_EXPERIENCE].length > 0
        ? resumeJson[PROCESSED_RESUME_JSON_WORK_EXPERIENCE][0]["inference"]
        : [];
  } else if (actionJson === "saveJson") {
    name = resumeJson[PROCESSED_RESUME_JSON_NAME];
    phone = resumeJson[PROCESSED_RESUME_JSON_PHONE];
    email = resumeJson[PROCESSED_RESUME_JSON_EMAIL];
    linkedIn = resumeJson[PROCESSED_RESUME_JSON_LINKEDIN];
    workExperience = resumeJson[PROCESSED_RESUME_JSON_WORK_EXPERIENCE];
  }

  if (name === "") {
    userMsg = "Please enter Name";
    isSelected = false;
    userMsgCatergory = PROCESSED_RESUME_JSON_NAME;
  } else if (phone === "" && email === "") {
    userMsg = "Please enter Phone or Email";
    isSelected = false;
    userMsgCatergory = "contact";
  } else if (fieldValidation(PROCESSED_RESUME_JSON_EMAIL, email)) {
    userMsg = "Please enter a valid Email Id";
    isSelected = false;
    userMsgCatergory = PROCESSED_RESUME_JSON_EMAIL;
  } else if (fieldValidation(PROCESSED_RESUME_JSON_PHONE, phone)) {
    userMsg = "Please enter a valid Phone number";
    isSelected = false;
    userMsgCatergory = PROCESSED_RESUME_JSON_PHONE;
  } else if (fieldValidation(PROCESSED_RESUME_JSON_LINKEDIN, linkedIn)) {
    userMsg = "Please enter a valid Linkedin link";
    isSelected = false;
    userMsgCatergory = PROCESSED_RESUME_JSON_LINKEDIN;
  } else if (workExperience.length === 0) {
    userMsg = "Work Experience missing or not captured";
    userMsgCatergory = PROCESSED_RESUME_JSON_WORK_EXPERIENCE;
  }
  // console.log('inUploadResumePage',inUploadResumePage);
  if (inUploadResumePage === false) isSelected = true;

  let changeValueObj = {};

  if (actionJson === "uploadJson") {
    changeValueObj[UPLOADED_RESUME_RESULTS_OBJ_PROGRESS] = 100;
    changeValueObj[UPLOADED_RESUME_RESULTS_OBJ_IS_SELECTED] = isSelected;
    changeValueObj[UPLOADED_RESUME_RESULTS_OBJ_PROCESSED_STATUS] = status;
    changeValueObj[UPLOADED_RESUME_RESULTS_OBJ_UPLOADED_USER_MSG] = userMsg;
    changeValueObj[UPLOADED_RESUME_RESULTS_OBJ_UPLOADED_USER_MSG_CATERGORY] =
      userMsgCatergory;
    changeValueObj[UPLOADED_RESUME_RESULTS_OBJ_IS_CUSTOM_MATCHED] = false;
  } else {
    changeValueObj[UPLOADED_RESUME_RESULTS_OBJ_UPLOADED_USER_MSG] = userMsg;
    changeValueObj[UPLOADED_RESUME_RESULTS_OBJ_UPLOADED_USER_MSG_CATERGORY] =
      userMsgCatergory;
    changeValueObj[UPLOADED_RESUME_RESULTS_OBJ_IS_SELECTED] = isSelected;
  }
  return changeValueObj;
};

export const fieldValidation = (fieldName, fieldValue) => {
  var emailRegex = new RegExp(
    /^([a-zA-Z0-9_.+-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/
  );
  var linkedInRegex = new RegExp(
    /(^|^(http(s)?):(\/\/)?)(([\w]{2,3})\.)?linkedin.([\w]{2,3})?\/in\/[a-zA-Z0-9_.-]+\/?$/
  );

  // var linkedInRegex = new RegExp(/^(http(s)?:\/\/)?([\w]+\.)?linkedin\.com\/(pub|in|profile)\/[a-zA-Z0-9_.-]+\/$/);

  /* Success Test Cases
    http://www.linkedin.com/in/aname/
    http://www.linkedin.com/in/another-name
    https://linkedin.com/in/name
    https://nl.linkedin.com/in/name
    http://nl.linkedin.com/in/name/
    http://nl.linkedin.in/in/name/
    nl.linkedin.com/in/name
    linkedin.com/in/name
    */

  let invalidField = false;
  let splitField = fieldValue.split(/[,&]+/);

  if (fieldValue === "") invalidField = false;
  else if (
    fieldName === PROCESSED_RESUME_JSON_LINKEDIN &&
    !linkedInRegex.test(fieldValue.trim())
  )
    invalidField = true;
  else if (splitField.length > 0) {
    for (let index in splitField) {
      if (
        fieldName === PROCESSED_RESUME_JSON_EMAIL &&
        !emailRegex.test(splitField[index].trim())
      )
        invalidField = true;
      else if (
        fieldName === PROCESSED_RESUME_JSON_PHONE &&
        /[a-zA-Z]/.test(splitField[index].trim())
      )
        invalidField = true;
      else if (
        fieldName === PROCESSED_RESUME_JSON_PHONE &&
        splitField[index].trim().match(/\d+/g).join("").length < 10
      )
        invalidField = true;
    }
  }

  return invalidField;
};
