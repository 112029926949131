import "react-app-polyfill/ie11"; // For IE 11 support
import "react-app-polyfill/stable";
import "./polyfill";
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { Provider as AlertProvider } from "react-alert";
import AlertMsg from "./components/AlertMsg";

import { Provider } from "react-redux";
import store from "./redux/store";
import { ThemeProvider } from "@material-ui/core";
import theme from './Theme';

// Alert template
const AlertTemplate = ({ options, message, close }) => {
  return (
    <AlertMsg
      message={message}
      header={options.header}
      ctype={options.ctype}
      close={close}
    />
  );
};

ReactDOM.render(
  <ThemeProvider theme={theme()}>
    <Provider store={store}>
      <BrowserRouter>
        <AlertProvider template={AlertTemplate}>
          <App />
        </AlertProvider>
      </BrowserRouter>
    </Provider>
  </ThemeProvider>
  ,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
