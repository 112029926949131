// Roles
const ROLES = {
  manager: "manager",
  recruiter: "recruiter",
  admin: "admin",
  clientAdmin: "client_admin",
};

const ADMINS = [ROLES.admin, ROLES.clientAdmin];

// Role based pages
const ROLE_BASED_PAGES = {
  // Main layout
  mainLayout: "mainLayout",

  // Intake Form
  intakeFormLanding: "intakeFormLanding",

  // Job Posting
  jobPostingLanding: "jobPostingLanding",
  funnelLanding: "funnelLanding",
  qualifyLanding: "qualifyLanding",
  testLanding: "testLanding",
  testScheduling: "testScheduling",
  testEvent: "testEvent",
  testOutcome: "testOutcome",
  recommendLanding: "recommendLanding",
  clientInterviewLanding: "clientInterviewLanding",
  interviewScheduling: "interviewScheduling",

  // Skill Master
  skillMasterLanding: "skillMasterLanding",

  // Preferred Company
  preferredCompanyLanding: "preferredCompanyLanding",

  // Settings
  settingsLanding: "settingsLanding",

  // Hierarchy
  hierarchyLanding: "hierarchyLanding",

  // Reports
  reportsLanding: "reportsLanding",
};

// Role based access
const ROLE_BASED_ACCESS = {
  [ROLES.manager]: {
    // Main layout
    [ROLE_BASED_PAGES.mainLayout]: {
      isSkillMasterLandingPageAllow: false,
      isPreferredCompanyLandingPageAllow: false,
      isSettingsLandingPageAllow: true,
      isReportsLandingPageAllow: true,
      isHierarchyLandingPageAllow: false,
    },

    // Intake Form
    [ROLE_BASED_PAGES.intakeFormLanding]: {
      isAllowEdit: true,
      isAllowDelete: true,
      isAllowDownload: true,
      isAllowUpload: true,
      isAllowSelect: true,
      isAllowAddIntakeForm: true,
    },

    // Job Posting
    [ROLE_BASED_PAGES.jobPostingLanding]: {
      isAllowDelete: false,
      isAllowFilter: false,
      isAllowJobPostingStatus: false,
    },
    [ROLE_BASED_PAGES.funnelLanding]: {
      isAllowCoverSheetUpdateForm: false,
      isAllowSentBot: false,
      isAllowDelete: false,
      isAllowAddResume: false,
      isAllowStatusUpdate: false,
      isAllowApplyJobToCandidate: false,
    },
    [ROLE_BASED_PAGES.qualifyLanding]: {
      isAllowSendToManager: true,
      isAllowMoveToRecommend: true,
      isAllowStatusUpdate: false,
    },
    [ROLE_BASED_PAGES.testLanding]: {},
    [ROLE_BASED_PAGES.testScheduling]: {
      isAccessibleActionButton: false,
    },
    [ROLE_BASED_PAGES.testEvent]: {
      isAccessibleActionButton: false,
    },
    [ROLE_BASED_PAGES.testOutcome]: {
      isAccessibleActionButton: false,
    },
    [ROLE_BASED_PAGES.recommendLanding]: {
      isAllowSendToManager: true,
    },
    [ROLE_BASED_PAGES.clientInterviewLanding]: {
      isAllowSendToManager: true,
    },
    [ROLE_BASED_PAGES.interviewScheduling]: {
      isAccessibleActionButton: false,
    },

    // Skill Master
    [ROLE_BASED_PAGES.skillMasterLanding]: {
      isSkillMasterLandingPageAllow: false,
    },

    // Preferred Company
    [ROLE_BASED_PAGES.preferredCompanyLanding]: {
      isPreferredCompanyLandingPageAllow: false,
    },

    // Settings
    [ROLE_BASED_PAGES.settingsLanding]: {
      isSettingsLandingPageAllow: true,
      isAllowClientSetting: true,
      isAllowAdminSetting: false,
      isAllowRecruiterSetting: true,
      isAllowHiringManagerSetting: true,
      isAllowPanelMemberSetting: true,
      isScoringSettingsLandingPageAllow: false,
      isUserSettingsLandingPageAllow: false,
    },

    // Reports
    [ROLE_BASED_PAGES.reportsLanding]: {
      isReportsLandingPageAllow: true,
    },
  },
  [ROLES.recruiter]: {
    // Main layout
    [ROLE_BASED_PAGES.mainLayout]: {
      isSkillMasterLandingPageAllow: false,
      isPreferredCompanyLandingPageAllow: false,
      isSettingsLandingPageAllow: false,
      isReportsLandingPageAllow: true,
      isHierarchyLandingPageAllow: false,
    },

    // Intake Form
    [ROLE_BASED_PAGES.intakeFormLanding]: {
      isAllowEdit: true,
      isAllowDelete: true,
      isAllowDownload: true,
      isAllowUpload: true,
      isAllowSelect: true,
      isAllowAddIntakeForm: true,
    },

    // Job Posting
    [ROLE_BASED_PAGES.jobPostingLanding]: {
      isAllowDelete: false,
      isAllowFilter: false,
      isAllowJobPostingStatus: false,
    },
    [ROLE_BASED_PAGES.funnelLanding]: {
      isAllowCoverSheetUpdateForm: true,
      isAllowSentBot: true,
      isAllowDelete: true,
      isAllowAddResume: true,
      isAllowStatusUpdate: true,
      isAllowApplyJobToCandidate: true,
    },
    [ROLE_BASED_PAGES.qualifyLanding]: {
      isAllowSendToManager: true,
      isAllowMoveToRecommend: true,
      isAllowStatusUpdate: true,
    },
    [ROLE_BASED_PAGES.testLanding]: {},
    [ROLE_BASED_PAGES.testScheduling]: {
      isAccessibleActionButton: true,
    },
    [ROLE_BASED_PAGES.testEvent]: {
      isAccessibleActionButton: true,
    },
    [ROLE_BASED_PAGES.testOutcome]: {
      isAccessibleActionButton: false,
    },
    [ROLE_BASED_PAGES.recommendLanding]: {
      isAllowSendToManager: true,
    },
    [ROLE_BASED_PAGES.clientInterviewLanding]: {
      isAllowSendToManager: false,
    },
    [ROLE_BASED_PAGES.interviewScheduling]: {
      isAccessibleActionButton: true,
    },

    // Skill Master
    [ROLE_BASED_PAGES.skillMasterLanding]: {
      isSkillMasterLandingPageAllow: false,
    },

    // Preferred Company
    [ROLE_BASED_PAGES.preferredCompanyLanding]: {
      isPreferredCompanyLandingPageAllow: false,
    },

    // Settings
    [ROLE_BASED_PAGES.settingsLanding]: {
      isSettingsLandingPageAllow: false,
      isAllowClientSetting: false,
      isAllowAdminSetting: false,
      isAllowRecruiterSetting: false,
      isAllowHiringManagerSetting: false,
      isAllowPanelMemberSetting: false,
      isScoringSettingsLandingPageAllow: false,
      isUserSettingsLandingPageAllow: false,
    },

    // Reports
    [ROLE_BASED_PAGES.reportsLanding]: {
      isReportsLandingPageAllow: true,
    },
  },
  [ROLES.clientAdmin]: {
    // Main layout
    [ROLE_BASED_PAGES.mainLayout]: {
      isSkillMasterLandingPageAllow: false,
      isPreferredCompanyLandingPageAllow: false,
      isSettingsLandingPageAllow: true,
      isReportsLandingPageAllow: true,
    },

    // Intake Form
    [ROLE_BASED_PAGES.intakeFormLanding]: {
      isAllowEdit: true,
      isAllowDelete: true,
      isAllowDownload: true,
      isAllowUpload: true,
      isAllowSelect: true,
      isAllowAddIntakeForm: true,
    },

    // Job Posting
    [ROLE_BASED_PAGES.jobPostingLanding]: {
      isAllowDelete: true,
      isAllowFilter: true,
      isAllowJobPostingStatus: true,
    },
    [ROLE_BASED_PAGES.funnelLanding]: {
      isAllowCoverSheetUpdateForm: true,
      isAllowSentBot: true,
      isAllowDelete: true,
      isAllowAddResume: true,
      isAllowStatusUpdate: true,
      isAllowApplyJobToCandidate: true,
    },
    [ROLE_BASED_PAGES.qualifyLanding]: {
      isAllowSendToManager: true,
      isAllowMoveToRecommend: true,
      isAllowStatusUpdate: true,
    },
    [ROLE_BASED_PAGES.testLanding]: {},
    [ROLE_BASED_PAGES.testScheduling]: {
      isAccessibleActionButton: true,
    },
    [ROLE_BASED_PAGES.testEvent]: {
      isAccessibleActionButton: true,
    },
    [ROLE_BASED_PAGES.testOutcome]: {
      isAccessibleActionButton: true,
    },
    [ROLE_BASED_PAGES.recommendLanding]: {
      isAllowSendToManager: true,
    },
    [ROLE_BASED_PAGES.clientInterviewLanding]: {
      isAllowSendToManager: true,
    },
    [ROLE_BASED_PAGES.interviewScheduling]: {
      isAccessibleActionButton: true,
    },

    // Skill Master
    [ROLE_BASED_PAGES.skillMasterLanding]: {
      isSkillMasterLandingPageAllow: false,
    },

    // Preferred Company
    [ROLE_BASED_PAGES.preferredCompanyLanding]: {
      isPreferredCompanyLandingPageAllow: false,
    },

    // Settings
    [ROLE_BASED_PAGES.settingsLanding]: {
      isSettingsLandingPageAllow: true,
      isAllowClientSetting: true,
      isAllowAdminSetting: true,
      isAllowRecruiterSetting: true,
      isAllowHiringManagerSetting: true,
      isAllowPanelMemberSetting: true,
      isScoringSettingsLandingPageAllow: false,
      isUserSettingsLandingPageAllow: false,
    },

    // Reports
    [ROLE_BASED_PAGES.reportsLanding]: {
      isReportsLandingPageAllow: true,
    },
  },
};

// Get role based access
const getRoleBasedAccess = (role, pageName) => {
  switch (role) {
    case ROLES.manager:
    case ROLES.recruiter:
    case ROLES.clientAdmin:
      return ROLE_BASED_ACCESS[role][pageName] || {};
    default:
      return {};
  }
};

// Send to manager button text based on role
const SEND_TO_MANAGER_BUTTON_TEXT = {
  [ROLES.manager]: "Send To Hiring Manager",
  [ROLES.recruiter]: "Send To Client Manager",
  [ROLES.admin]: "Send To Client Manager",
  [ROLES.clientAdmin]: "Send To Client Manager",
};

// Send to manager button text based on role in qualify page
const SEND_TO_MANAGER_BUTTON_TEXT_QUALIFY_PAGE = {
  [ROLES.manager]: "Send To Hiring Manager",
  [ROLES.recruiter]: "Send For Approval",
  [ROLES.admin]: "Send To Client Manager",
  [ROLES.clientAdmin]: "Send To Client Manager",
};

// Send to manager success message based on role in qualify page
const SEND_TO_MANAGER_SUCCESS_MESSAGE = {
  [ROLES.manager]: "Email successfully sent to the hiring manager.",
  [ROLES.recruiter]:
    "Approval email for the selected candidates sent successfully.",
  [ROLES.admin]: "Email successfully sent to the client manager.",
  [ROLES.clientAdmin]: "Email successfully sent to the client manager.",
  undefined: "Email successfully sent to the manager.",
};

// Send to manager success message based on role in recommend page
const SEND_TO_MANAGER_SUCCESS_MESSAGE_RECOMMEND_PAGE = {
  [ROLES.manager]: "Email successfully sent to the hiring manager.",
  [ROLES.recruiter]: "Email successfully sent to the client manager.",
  [ROLES.admin]: "Email successfully sent to the client manager.",
  [ROLES.clientAdmin]: "Email successfully sent to the client manager.",
  client: "Email successfully sent to the hiring manager.",
};

export {
  ROLES,
  ADMINS,
  ROLE_BASED_PAGES,
  ROLE_BASED_ACCESS,
  SEND_TO_MANAGER_BUTTON_TEXT,
  SEND_TO_MANAGER_BUTTON_TEXT_QUALIFY_PAGE,
  SEND_TO_MANAGER_SUCCESS_MESSAGE,
  SEND_TO_MANAGER_SUCCESS_MESSAGE_RECOMMEND_PAGE,
  getRoleBasedAccess,
};
