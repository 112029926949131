import { QUALIFY } from "../actionTypes";

export const setQualifyState = (values) => ({
  type: QUALIFY.setState,
  payload: values,
});

export const clearQualifyState = () => ({
  type: QUALIFY.clearState,
});
