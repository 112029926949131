import React, { useCallback, useEffect, useState } from "react";
import axios from "axios";
import { postJobDetail } from "../../api/api";
import {
  Button,
  Container,
  Grid,
  CircularProgress,
  TextField,
  Typography,
} from "@material-ui/core";

axios.defaults.xsrfHeaderName = "X-CSRFToken";
axios.defaults.xsrfCookieName = "csrftoken";

export default function JobDetail() {
  const [state, setState] = useState({
    positionTitle: "",
    jobCode: "",
    description: "",
    isLoading: false,
    successMessage: "",
  });

  const handlePositionTitle = (e) => {
    setState({
      ...state,
      positionTitle: e.target.value,
      successMessage: "",
      errorMessage: "",
    });
  };

  const handleJobCode = (e) => {
    setState({
      ...state,
      jobCode: e.target.value,
      successMessage: "",
      errorMessage: "",
    });
  };

  const handleDescription = (e) => {
    setState({
      ...state,
      description: e.target.value,
      successMessage: "",
      errorMessage: "",
    });
  };

  const handleJobDetailCreate = (event) => {
    event.preventDefault();
    setState({
      ...state,
      isLoading: true,
    });
    axios
      .post(postJobDetail(), {
        job_code: state.jobCode,
        position_title: state.positionTitle,
        requisition_description: state.description,
      })
      .then((response) => {
        if (response.status == 200) {
          setState({
            ...state,
            positionTitle: "",
            jobCode: "",
            description: "",
            isLoading: false,
            successMessage: "Successfully submitted.",
          });
          
        }
     
      })
      .catch(err => {
        setState({
          ...state,
          isLoading: false,
          errorMessage: "Job Code already exist",
        });
    })

  };

  return (
    <Container maxWidth={"md"} className={"containerStyle mt-8"}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography>
            <b>Job Detail - Create</b>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <form onSubmit={handleJobDetailCreate}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  required
                  label="Position Title"
                  placeholder="Enter the title of the Job Description. i.e) Junior Java Developer"
                  onChange={handlePositionTitle}
                  value={state.positionTitle}
                  id="position-title"
                  className="input"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  required
                  label="Job Code"
                  placeholder="Enter the Job Code i.e) JE - 123"
                  onChange={handleJobCode}
                  value={state.jobCode}
                  id="job-code"
                  className="input"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  required
                  multiline
                  minRows={8}
                  label="Description"
                  onChange={handleDescription}
                  value={state.description}
                  id="description"
                  className="input"
                />
              </Grid>
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                xs={12}
              >
                <Button
                  size={"small"}
                  variant="contained"
                  color="primary"
                  type="submit"
                >
                  Create
                </Button>
                {state.isLoading ? (
                  <CircularProgress />
                ) : 
                  state.successMessage?
                  (<Typography style={{ color: "green" }}>
                    {state.successMessage}
                  </Typography>)
                :<Typography style={{ color: "red" }}>
                {state.errorMessage}
              </Typography>}
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
    </Container>
  );
}