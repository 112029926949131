import { Backdrop, Box } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: "transparent",
    flexDirection: "column",
    gap: "16px",
  },
}));

function PageLoader({ open = true, children }) {
  const classes = useStyles();

  return (
    <Backdrop className={classes.backdrop} open={open}>
      <CircularProgress color={"primary"} size={45} />
      <Box textAlign={"center"}>{children}</Box>
    </Backdrop>
  );
}

export default PageLoader;
