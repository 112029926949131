import { INTAKE_FORM } from "../actionTypes";

export const setIntakeFormState = (values) => ({
  type: INTAKE_FORM.setState,
  payload: values,
});

export const clearIntakeState = () => ({
  type: INTAKE_FORM.clearState,
});
