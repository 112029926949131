import React from "react";

import "./style.css";

function UploadInstruction() {
  return (
    <div className="instructionUpload">
      <h5 className="headText">Instructions for Upload</h5>
      <div className="cardContainer">
        <p className="boldPara">
          Please make the below changes before uploading the resumes for
          accurate parsing:
        </p>
        <ol className="instructionList">
          <li>
            {" "}
            Maintain atleast a line gap between different sections and one line
            within sections.{" "}
          </li>
          <li> "Work Experience" heading should not be more than one. </li>
          <li>
            {" "}
            For every work experience block, duration must be present in the
            first line.{" "}
          </li>
          <li>
            {" "}
            Every work experience block should have the heading
            "Responsibilities:"{" "}
          </li>
          <li>
            {" "}
            Date should not have '-' in between (e.g. Jan-2018 - Jan-2019 format
            is not acceptable){" "}
          </li>
        </ol>
      </div>
    </div>
  );
}

export default UploadInstruction;
