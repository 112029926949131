import { Box, Typography } from "@material-ui/core";
import { Cancel, Info, Warning } from "@material-ui/icons";
import React from "react";
import {
  FlexColumnView,
  FlexView,
} from "../../../commonComponents/StyledComponents";

const icons = {
  warning: <Warning style={{ fill: "#ffc83d" }} />,
  critical: <Cancel color={"error"} />,
  information: <Info color={"primary"} />,
};

function AdditionalInfo({ flags }) {
  return (
    <Box p={1}>
      <Box pb={3}>
        <Typography align={"center"}>
          <b>{"Flags"}</b>
        </Typography>
      </Box>
      {flags && flags.length ? (
        <FlexColumnView gridGap={"15px"}>
          {flags.map((el, index) => (
            <FlexView key={index} gridGap={"20px"}>
              {icons[el[1]]}
              <Typography>{el[0]}</Typography>
            </FlexView>
          ))}
        </FlexColumnView>
      ) : (
        <Typography align={"center"}>
          <b>{"Flags not found"}</b>
        </Typography>
      )}
    </Box>
  );
}

export default AdditionalInfo;
