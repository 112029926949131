import { Box, Grid, Typography } from "@material-ui/core";
import React from "react";
import { isAllowNumberCommaSpacePlusMinus } from "../../../utils/constants/validation";
import InputField from "./InputField";

function PersonalDetails({
  values = {},
  handlePersonalDetails = () => {},
  touched,
  errors,
}) {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant={"h6"}>
          <b>{"Personal Details"}</b>
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Box
          border={"1px solid rgba(0, 0, 0, 0.23)"}
          padding={"16px"}
          borderRadius={"4px"}
        >
          <Grid container spacing={2} style={{ rowGap: 5 }}>
            <Grid item xs={6}>
              <InputField
                label={"First Name"}
                name={"first_name"}
                placeholder={"Enter first name"}
                value={values.personal_details.first_name || ""}
                onChange={handlePersonalDetails}
                error={
                  touched.personal_details?.first_name &&
                  errors.personal_details?.first_name
                }
              />
            </Grid>
            <Grid item xs={6}>
              <InputField
                label={"Last Name"}
                name={"last_name"}
                placeholder={"Enter lase name"}
                value={values.personal_details.last_name || ""}
                onChange={handlePersonalDetails}
                error={
                  touched.personal_details?.last_name &&
                  errors.personal_details?.last_name
                }
              />
            </Grid>
            <Grid item xs={6}>
              <InputField
                name={"phone"}
                label={"Phone"}
                placeholder={"Enter phone number"}
                value={values.personal_details.phone || ""}
                onChange={(e) => {
                  let { value } = e.target;
                  if (
                    isAllowNumberCommaSpacePlusMinus(value) ||
                    value?.length === 0
                  )
                    handlePersonalDetails(e);
                  else e.preventDefault();
                }}
                error={
                  touched.personal_details?.phone &&
                  errors.personal_details?.phone
                }
              />
            </Grid>
            <Grid item xs={6}>
              <InputField
                label={"Email"}
                name={"email"}
                placeholder={"Enter email"}
                value={values.personal_details.email || ""}
                onChange={handlePersonalDetails}
                error={
                  touched.personal_details?.email &&
                  errors.personal_details?.email
                }
              />
            </Grid>
            <Grid item xs={12}>
              <InputField
                label={"LinkedIn Profile"}
                name={"linkedin_profile"}
                placeholder={"Enter linkedin profile"}
                value={values.personal_details.linkedin_profile || ""}
                onChange={handlePersonalDetails}
                error={
                  touched.personal_details?.linkedin_profile &&
                  errors.personal_details?.linkedin_profile
                }
              />
            </Grid>
          </Grid>
        </Box>
      </Grid>
      {/* <Grid item xs={12}>
        <Typography>
          <b>{`Note: `}</b>
          {"Please verify the above info and click Next."}
        </Typography>
      </Grid> */}
      <Grid item xs={12} />
    </Grid>
  );
}

export default PersonalDetails;
