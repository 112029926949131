import {
  Box,
  Button,
  Chip,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import SearchIcon from "@material-ui/icons/Search";
import { Autocomplete, createFilterOptions } from "@material-ui/lab";
import Pagination from "@material-ui/lab/Pagination";
import axios from "axios";
import { useFormik } from "formik";
import React, { useCallback, useEffect, useState } from "react";
import * as yup from "yup";
import {
  createSkillHierarchy,
  deleteSkillHierarchy,
  getAllSkills,
  getSingleSkillHierarchy,
  getSkillHierarchy,
  updateSkillHierarchy,
} from "../../../api/api";
import InputField from "../../../commonComponents/Controllers/InputField";
import DeleteDialog from "../../../commonComponents/DeleteDialog/DeleteDialog";
import Loader from "../../../commonComponents/Loader/Loader";
import SimpleModal from "../../../commonComponents/Modal/SimpleModal";
import CustomSnack from "../../../commonComponents/Snack/CustomSnack";
import "../../../styles/style.css";
import { debounce } from "../../../utils/constants/helpers";
import {
  CREATED_MESSAGE,
  DELETED_MESSAGE,
  SOMETHING_WENT_WRONG,
  UPDATED_MESSAGE,
} from "../../../utils/constants/constants";

export const Header = [
  {
    id: 1,
    name: "Parent",
    className: "thChild",
  },
  {
    id: 2,
    name: "Child List",
    className: "thChild",
  },
  {
    id: 3,
    name: "Action",
    className: "th",
  },
];

export const Options = [
  {
    id: 1,
    value: "10",
  },
  {
    id: 2,
    value: "20",
  },
  {
    id: 3,
    value: "30",
  },
  {
    id: 4,
    value: "40",
  },
  {
    id: 5,
    value: "50",
  },
  {
    id: 6,
    value: "100",
  },
];

axios.defaults.xsrfHeaderName = "X-CSRFToken";
axios.defaults.xsrfCookieName = "csrftoken";

const filter = createFilterOptions();

function Index() {
  const [skillHierarchy, setSkillHierarchy] = useState([]);

  const [snack, setSnack] = useState({
    open: false,
    color: "",
    message: "",
  });

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const [deleteId, setDeleteId] = useState(null);

  const [editId, setEditId] = useState(null);

  const [searchParentSkill, setSearchParentSkill] = useState("");

  const [totalCount, setTotalCount] = useState(0);

  const [pagination, setPagination] = useState({
    activePage: 1,
    items: 10,
  });

  const [skillList, setSkillList] = useState([]);
  const [skillDialogOpen, setSkillDialogOpen] = useState(false);

  const [loading, setLoading] = useState(false);

  const validationSchema = yup.object({
    parent: yup.string().nullable().required("Please fill the required field"),
    child: yup
      .array()
      .of(
        yup.object().shape({
          id: yup.string().nullable(),
        })
      )
      .min(
        1,
        // "Please fill the required field"
        "Child skill name should not be empty"
      ),
  });

  const handleSnackClose = () => {
    setSnack({
      open: false,
      color: "",
      message: "",
    });
  };

  const handleSave = (values, { resetForm }) => {
    let payload = {
      parent: values.parent,
      child: values.child.map(({ id }) => ({ id })),
    };

    if (editId) {
      // Edit Skill Hierarchy
      axios
        .put(updateSkillHierarchy(editId), payload)
        .then((response) => {
          setSkillDialogOpen(false);
          setSnack({
            open: true,
            color: "success",
            message: UPDATED_MESSAGE,
          });
          if (response.status === 200) {
            resetForm();
            setTimeout(() => {
              let offset =
                pagination.activePage === 0
                  ? 0
                  : (pagination.activePage - 1) * parseInt(pagination.items);
              getAndSetSkillHierarchy(pagination.items, offset);
            }, 2500);
          } else {
            setSnack({
              open: true,
              color: "error",
              message: SOMETHING_WENT_WRONG,
            });
          }
        })
        .catch((err) => {
          setSnack({
            open: true,
            color: "error",
            message: SOMETHING_WENT_WRONG,
          });
        });
    } else {
      // Add Skill Hierarchy
      axios
        .post(createSkillHierarchy(), payload)
        .then((response) => {
          if (response.status === 201) {
            setSkillDialogOpen(false);
            setSnack({
              open: true,
              color: "success",
              message: CREATED_MESSAGE,
            });
            setTimeout(() => {
              resetForm();
              getAndSetSkillHierarchy(pagination.items, 0);
              setPagination({
                activePage: 1,
                items: 10,
              });
            }, 2500);
          } else {
            setSnack({
              open: true,
              color: "error",
              message: SOMETHING_WENT_WRONG,
            });
          }
        })
        .catch((err) => {
          setSnack({
            open: true,
            color: "error",
            message: SOMETHING_WENT_WRONG,
          });
        });
    }
  };

  const formik = useFormik({
    initialValues: {
      parent: "",
      child: [],
    },
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: handleSave,
  });

  const {
    values,
    setValues,
    errors,
    touched,
    setFieldValue,
    handleChange,
    handleSubmit,
    resetForm,
  } = formik;

  const getAndSetSkillHierarchy = (
    limit,
    offset,
    search = searchParentSkill
  ) => {
    setLoading(true);
    axios
      .get(getSkillHierarchy(), {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("tal_token")}`,
        },
        params: {
          limit: limit,
          offset: offset,
          search: search,
        },
      })
      .then((response) => {
        setSkillHierarchy(response.data.results);
        setTotalCount(response.data.count);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        setSnack({
          open: true,
          color: "error",
          message: SOMETHING_WENT_WRONG,
        });
      });
  };

  const getSkillList = () => {
    axios
      .get(getAllSkills("", "", ""), {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("tal_token")}`,
        },
      })
      .then((response) => {
        setSkillList(response.data);
      });
  };

  useEffect(() => {
    getSkillList();
    getAndSetSkillHierarchy(pagination.items, 0);
  }, []);

  const onClickEdit = (id) => {
    axios
      .get(getSingleSkillHierarchy(id), {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("tal_token")}`,
        },
      })
      .then((response) => {
        setEditId(id);
        setValues({
          parent: response?.data.parent || "",
          child: response?.data.child || [],
        });
        setSkillDialogOpen(true);
      })
      .catch((error) => {
        setSnack({
          open: true,
          color: "error",
          message: SOMETHING_WENT_WRONG,
        });
      });
  };

  const onSelectPage = (e, value) => {
    let offset = value === 0 ? 0 : value - 1;
    getAndSetSkillHierarchy(pagination.items, offset * pagination.items);

    setPagination({
      ...pagination,
      activePage: value,
    });
  };

  const handlePageLimitChange = (e) => {
    const { value } = e.target;
    getAndSetSkillHierarchy(
      parseInt(value),
      (pagination.activePage - 1) * parseInt(value)
    );
    setPagination({
      ...pagination,
      items: parseInt(value),
    });
  };

  const handleDeleteClick = () => {
    axios
      .delete(deleteSkillHierarchy(deleteId))
      .then((response) => {
        if (response.status === 204) {
          setDeleteDialogOpen(false);
          setDeleteId(null);
          setSnack({
            open: true,
            color: "success",
            message: DELETED_MESSAGE,
          });
          setTimeout(() => {
            let offset =
              pagination.activePage === 0 ? 0 : pagination.activePage - 1;
            getAndSetSkillHierarchy(
              pagination.items,
              offset * pagination.items
            );
          }, 2500);
        } else {
          setSnack({
            open: true,
            color: "error",
            message: SOMETHING_WENT_WRONG,
          });
        }
      })
      .catch((err) => {
        setSnack({
          open: true,
          color: "error",
          message: SOMETHING_WENT_WRONG,
        });
      });
  };

  const handleSearchChange = (value) => {
    getAndSetSkillHierarchy(pagination.items, 0, value);
    setSearchParentSkill(value);
  };

  const handleDeleteRowClick = (id) => {
    setDeleteId(id);
    setDeleteDialogOpen(true);
  };

  const handleDeleteCancel = () => {
    setDeleteDialogOpen(false);
    setDeleteId(null);
  };

  const handleAdd = () => {
    setSkillDialogOpen(true);
  };

  const optimizedFn = useCallback(debounce(handleSearchChange), [pagination]);

  const handleSkillDialogClose = () => {
    resetForm();
    setSkillDialogOpen(false);
    setEditId(null);
  };

  return (
    <Box>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Grid container spacing={4} alignItems={"flex-end"}>
            <Grid item xs>
              <Typography style={{ paddingBottom: "5px" }}>
                <b>{"Skill Hierarchy"}</b>
              </Typography>
            </Grid>
            <Grid item>
              <TextField
                id={"search"}
                label={"Search"}
                onChange={(e) => optimizedFn(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton>
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item>
              <Button
                variant={"contained"}
                color={"primary"}
                onClick={handleAdd}
              >
                <b className={"capitalize"}>{"Add Skill Hierarchy"}</b>
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Table size={"small"} component={Paper} variant={"outlined"}>
            <TableHead>
              <TableRow>
                {Header.map(({ name, className }, ind) => (
                  <TableCell
                    className={className}
                    align={ind === 2 ? "center" : "inherit"}
                    key={`head-${ind + 1}`}
                  >
                    <Typography className={"tableHead"}>{name}</Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {!loading && skillHierarchy?.length ? (
                skillHierarchy.map(({ id, parent, child }) => {
                  const childList = child?.length
                    ? child.map((el) => el.name).join(", ")
                    : "-";
                  return (
                    <TableRow key={id}>
                      <TableCell>{parent}</TableCell>
                      <TableCell>{childList}</TableCell>
                      <TableCell>
                        <div className={"editTab"}>
                          <IconButton
                            color={"primary"}
                            onClick={() => onClickEdit(id)}
                          >
                            <EditIcon />
                          </IconButton>
                          <IconButton
                            color={"secondary"}
                            onClick={() => handleDeleteRowClick(id)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </div>
                      </TableCell>
                    </TableRow>
                  );
                })
              ) : (
                <TableRow>
                  <TableCell colSpan={3} align={"center"}>
                    {loading ? (
                      <Loader />
                    ) : (
                      <Typography>
                        <b>{"No results found"}</b>
                      </Typography>
                    )}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </Grid>
        <Grid
          item
          container
          xs={12}
          alignItems={"center"}
          justifyContent={"flex-end"}
        >
          <Typography>
            Total Count: <b>{totalCount}</b>
          </Typography>
        </Grid>
        <Grid item xs={2} container alignItems={"center"}>
          <FormControl style={{ width: 120 }}>
            <InputLabel id={"demo-simple-select-label"}>
              {"Rows per page"}
            </InputLabel>
            <Select
              labelId={"demo-simple-select-label"}
              id={"demo-simple-select"}
              value={pagination.items}
              onChange={handlePageLimitChange}
            >
              {Options.map(({ id, value }) => (
                <MenuItem key={id} value={value}>
                  {value}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid
          item
          xs={10}
          container
          alignItems={"center"}
          justifyContent={"flex-end"}
        >
          <Pagination
            color={"primary"}
            page={pagination.activePage}
            onChange={onSelectPage}
            count={Math.ceil(totalCount / pagination.items)}
            showFirstButton
            showLastButton
          />
        </Grid>
      </Grid>
      <DeleteDialog
        dialogOpen={deleteDialogOpen}
        handleClose={handleDeleteCancel}
        handleYesClick={handleDeleteClick}
      />
      <SimpleModal
        open={skillDialogOpen}
        title={`${editId ? "Edit" : "Add"} Skill Hierarchy`}
        handleClose={handleSkillDialogClose}
        handleSave={handleSubmit}
      >
        <Box minHeight={"160px"}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <InputField
                id={"parent"}
                label={"Skill Name"}
                name={"parent"}
                value={values.parent || ""}
                onChange={handleChange}
                error={touched.parent && Boolean(errors.parent)}
                helperText={touched.parent && errors.parent}
              />
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                value={values.child || []}
                id={"tags-filled"}
                options={skillList}
                //   freeSolo
                onChange={(event, newValue) => {
                  // if (typeof newValue === "string") {
                  //   setFieldValue("child", newValue);
                  // } else if (newValue && newValue.inputValue) {
                  //   // Create a new value from the user input
                  //   setFieldValue("child", newValue.inputValue);
                  // } else {
                  setFieldValue("child", newValue);
                  // }
                }}
                // filterOptions={(options, params) => {
                //   const filtered = filter(options, params);

                // Suggest the creation of a new value
                //   if (params.inputValue !== "") {
                //     filtered.push({
                //       inputValue: params.inputValue,
                //       child: `Add "${params.inputValue}"`,
                //     });
                //   }

                //   return filtered;
                // }}
                getOptionLabel={(option) => {
                  // Value selected with enter, right from the input
                  // if (typeof option === "string") {
                  //   return option;
                  // }
                  // Add "xxx" option created dynamically
                  // if (option.inputValue) {
                  //   return option.inputValue;
                  // }
                  // Regular option
                  return option.name;
                }}
                renderOption={(option) => option.name}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                getOptionSelected={(option, value) => option.id === value.id}
                filterSelectedOptions
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      variant={"outlined"}
                      label={
                        option.inputValue ? option.inputValue : option.name
                      }
                      {...getTagProps({ index })}
                    />
                  ))
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant={"outlined"}
                    label={"Child Skill Name"}
                    inputProps={{
                      ...params.inputProps,
                      onKeyDown: (e) => {
                        if (e.key === "Enter") {
                          e.stopPropagation();
                        }
                      },
                    }}
                    error={touched.child && Boolean(errors.child)}
                    helperText={touched.child && errors.child}
                  />
                )}
                multiple
                fullWidth
              />
            </Grid>
          </Grid>
        </Box>
      </SimpleModal>
      <CustomSnack
        open={snack.open}
        color={snack.color}
        message={snack.message}
        onClose={handleSnackClose}
      />
    </Box>
  );
}

export default Index;
