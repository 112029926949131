import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { Alert } from "@material-ui/lab";
import axios from "axios";
import { FieldArray, Form, FormikProvider, getIn, useFormik } from "formik";
import * as React from "react";
import * as yup from "yup";
import { update_skills_api } from "../../api/api";
import "../../styles/tailwind.generated.css";

// eslint-disable-next-line no-extend-native
Array.prototype.sum = function (prop) {
  var total = 0;
  for (var i = 0, _len = this.length; i < _len; i++) {
    total += this[i][prop];
  }
  return total;
};

// axios csrf
axios.defaults.xsrfHeaderName = "X-CSRFToken";
axios.defaults.xsrfCookieName = "csrftoken";

const useStyles = makeStyles((theme) => ({
  boldText: {
    fontWeight: "bold",
  },
}));

export default function ExtractSkillDisplayTable({
  skills,
  prevExpValue,
  compExpValue,
}) {
  const [snack, setSnack] = React.useState({
    open: false,
    message: "",
  });
  console.log(skills, "||||||");
  const validationSchema = yup.object({
    data: yup.array().of(
      yup.object().shape({
        skill: yup.string().required(),
        years: yup
          .number("Years should be number")
          .max(100, "It should be less than 100")
          .required("Years is required"),
        tone: yup.string().required(),
        weightage: yup
          .number("Weightage should be number")
          .max(100, "Weightage should be less than 100")
          .required("Weightage is required"),
      })
    ),
    total: yup.number("Total should be number"),
    // .max(100, "Total should be equal to 100")
    // .min(100, "Total should be equal to 100"),
  });

  const formik = useFormik({
    initialValues: {
      id: skills.id,
      data: skills?.results
        ? [...skills.results].map((el) => ({
            ...el,
            weightage: el.weightage,
          }))
        : [],
      total: 0,
      prevExp: true,
      compExp: true,
      prevExpValue: prevExpValue,
      compExpValue: compExpValue,
    },
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      const requestBody = {
        response: values.data,
        msp_experience_weightage: values.prevExpValue,
        preferred_company_weightage: values.compExpValue,
      };
      axios
        .post(update_skills_api(values.id), requestBody, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("tal_token"),
          },
        })
        .then((response) => {
          setSnack({
            open: true,
            message: "Saved Successfully",
          });
          setTimeout(() => {
            setSnack({
              open: false,
              message: "",
            });
          }, 2000);
        })
        .catch((error) => {
          console.log(error, "******");
        });
    },
  });

  const { values, handleChange, touched, errors, setFieldValue, handleBlur } =
    formik;

  React.useEffect(() => {
    let tempData = [...values.data];

    let isValidNumbers = true;

    for (let i = 0; i < tempData.length; i++) {
      if (typeof tempData[i].weightage === "string") {
        isValidNumbers = false;
        break;
      }
    }

    if (isValidNumbers) {
      setFieldValue("total", tempData.sum("weightage"));
    }
  }, [values.data]);

  const classes = useStyles();

  return (
    <Box
      sx={{
        margin: "15px 0px",
        minHeight: "45vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <FormikProvider value={formik}>
        <Form>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TableContainer component={Paper} variant={"outlined"}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <Typography className={classes.boldText}>
                          {" "}
                          Skill
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography className={classes.boldText}>
                          Years
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography className={classes.boldText}>
                          Tone
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography className={classes.boldText}>
                          Weightage
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <FieldArray
                      name="data"
                      render={(arrayHelpers) => (
                        <React.Fragment>
                          {values.data.map((row, index) => {
                            const years = `data[${index}].years`;
                            const touchedYears = getIn(touched, years);
                            const errorYears = getIn(errors, years);

                            const tone = `data[${index}].tone`;
                            const touchedTone = getIn(touched, tone);
                            const errorTone = getIn(errors, tone);

                            const weightage = `data[${index}].weightage`;
                            const touchedWeightage = getIn(touched, weightage);
                            const errorWeightage = getIn(errors, weightage);

                            return (
                              <TableRow
                                key={row.skill}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell component="th" scope="row">
                                  <Typography className={classes.boldText}>
                                    {row.skill}
                                  </Typography>
                                </TableCell>
                                <TableCell>
                                  <TextField
                                    fullWidth
                                    error={Boolean(touchedYears && errorYears)}
                                    helperText={
                                      touchedYears && errorYears
                                        ? errorYears
                                        : ""
                                    }
                                    onChange={handleChange}
                                    name={`data[${index}].years`}
                                    value={row.years}
                                    onBlur={handleBlur}
                                    type={"number"}
                                  />{" "}
                                </TableCell>
                                <TableCell>
                                  <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">
                                      Select
                                    </InputLabel>
                                    <Select
                                      fullWidth
                                      labelId="demo-simple-select-label"
                                      id="demo-simple-select"
                                      value={row.tone}
                                      error={Boolean(touchedTone && errorTone)}
                                      name={`data[${index}].tone`}
                                      label="Select"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                    >
                                      <MenuItem value={"required"}>
                                        Required
                                      </MenuItem>
                                      <MenuItem value={"desired"}>
                                        Desired
                                      </MenuItem>
                                      <MenuItem value={"nice_to_have"}>
                                        Nice to have
                                      </MenuItem>
                                    </Select>
                                  </FormControl>
                                </TableCell>
                                <TableCell>
                                  {" "}
                                  <TextField
                                    fullWidth
                                    error={Boolean(
                                      touchedWeightage && errorWeightage
                                    )}
                                    helperText={
                                      touchedWeightage && errorWeightage
                                        ? errorWeightage
                                        : ""
                                    }
                                    onBlur={handleBlur}
                                    name={`data[${index}].weightage`}
                                    value={row.weightage}
                                    onChange={handleChange}
                                    type={"number"}
                                  />{" "}
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        </React.Fragment>
                      )}
                    />

                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell>
                        {" "}
                        <Typography className={classes.boldText}>
                          Total
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <TextField
                          fullWidth
                          name={"total"}
                          onBlur={handleBlur}
                          value={values.total}
                          error={Boolean(errors.total)}
                          helperText={Boolean(errors.total) ? errors.total : ""}
                          onChange={(e) => e.preventDefault()}
                          // disabled
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={values.prevExp}
                              onChange={handleChange}
                              value={values.prevExp}
                              name={"prevExp"}
                            />
                          }
                          label="Previous MSP Experience"
                        />
                      </TableCell>
                      <TableCell>
                        {values.prevExp && (
                          <TextField
                            fullWidth
                            // error={Boolean(
                            //   touchedWeightage && errorWeightage
                            // )}
                            // helperText={
                            //   touchedWeightage && errorWeightage
                            //     ? errorWeightage
                            //     : ""
                            // }
                            onBlur={handleBlur}
                            name={`prevExpValue`}
                            value={values.prevExpValue}
                            onChange={handleChange}
                            type={"number"}
                          />
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell>
                        <FormControlLabel
                          control={
                            <Checkbox
                              onChange={handleChange}
                              value={values.compExp}
                              name={"compExp"}
                              checked={values.compExp}
                            />
                          }
                          label="Preferred Company Experience"
                        />
                      </TableCell>
                      <TableCell>
                        {values.compExp && (
                          <TextField
                            fullWidth
                            // error={Boolean(
                            //   touchedWeightage && errorWeightage
                            // )}
                            // helperText={
                            //   touchedWeightage && errorWeightage
                            //     ? errorWeightage
                            //     : ""
                            // }
                            onBlur={handleBlur}
                            name={`compExpValue`}
                            value={values.compExpValue}
                            onChange={handleChange}
                            type={"number"}
                          />
                        )}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid item xs={12} container justifyContent={"flex-end"}>
              <button
                type={"submit"}
                className="c-btn c-btn-secondary c-btn-outline text-center"
              >
                Submit
              </button>
            </Grid>
            {snack.open && (
              <Grid item xs={12}>
                <Alert severity={"success"}>{snack.message}</Alert>
              </Grid>
            )}
          </Grid>
        </Form>
      </FormikProvider>
    </Box>
  );
}
