const layout = {
  home: "/home",
};

const pages = {
  // Reports
  reports: `${layout.home}/reports`,
  // Settings
  settings: `${layout.home}/settings`,
};

const path = {
  // Reports
  reports: pages.reports,
  viewSubmissionReport: `${pages.reports}/submission`,
  viewTestReport: `${pages.reports}/test`,
  viewRecommendReport: `${pages.reports}/recommend`,
  viewRejectedReport: `${pages.reports}/rejected`,
  viewApplicationHistoryReport: `${pages.reports}/application-history`,
  // Settings
  settings: pages.settings,
};

const navigateTo = {
  // Reports
  reports: path.reports,
  viewSubmissionReport: path.viewSubmissionReport,
  viewTestReport: path.viewTestReport,
  viewRecommendReport: path.viewRecommendReport,
  viewRejectedReport: path.viewRejectedReport,
  viewApplicationHistoryReport: path.viewApplicationHistoryReport,
  // Settings
  settings: (type) => `${path.settings}/${type}`,

  // Apply Job
  applyJobWithApplicant: (jobCode, token, applicantId) =>
    `/client/yes/jobCode/${jobCode}/apply/${token}/${applicantId}`,

  // Recommended Interview Scheduling
  recommendedInterviewScheduling: (
    jobCode,
    applicantId,
    applicant_session,
    type
  ) =>
    `/dashboard/${jobCode}/recommended/${applicantId}/${applicant_session}/scheduling/${type}`,

  // Client Interview Page Interview Schedule
  clientInterviewScheduling: (jobCode, applicantId, applicant_session, type) =>
    `/dashboard/${jobCode}/clientInterview/${applicantId}/${applicant_session}/scheduling/${type}`,
  viewGrammarChecker: (jobCode, applicantId) =>
    `/grammar-checker/${jobCode}/${applicantId}`,
  viewFunnelGrammarChecker: (applicantId) =>
    `/grammar-checker/${applicantId}`,
};

export { pages, path, navigateTo };
