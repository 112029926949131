import { TextField } from "@material-ui/core";
import React from "react";

function InputField({
  label,
  error,
  name,
  value,
  placeholder,
  onChange,
  ...props
}) {
  return (
    <TextField
      name={name}
      value={value}
      onChange={onChange}
      label={label}
      variant={"outlined"}
      error={Boolean(error)}
      helperText={error}
      placeholder={placeholder}
      size={"small"}
      fullWidth
      {...props}
    />
  );
}

export default InputField;
