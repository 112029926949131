import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Typography,
} from "@material-ui/core";
import React from "react";
import LoadingButton from "../LoadingButton";

function SimpleModal({
  open,
  title = "Title",
  closeButtonText = "Cancel",
  saveButtonText = "Save",
  handleClose,
  handleSave,
  maxWidth = "md",
  loading = false,
  children,
}) {
  return (
    <Dialog open={open} maxWidth={maxWidth} fullWidth disableEnforceFocus>
      <DialogTitle>
        <Typography component={"span"} variant={"h6"}>
          <b>{title}</b>
        </Typography>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Box py={2}>{children}</Box>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Box
          display={"flex"}
          justifyContent={"flex-end"}
          p={1.2}
          gridGap={"20px"}
        >
          <Button
            onClick={handleClose}
            variant={"contained"}
            color={"inherit"}
            disabled={loading}
          >
            <b>{closeButtonText}</b>
          </Button>
          <LoadingButton onClick={handleSave} loading={loading} autoFocus>
            <b>{saveButtonText}</b>
          </LoadingButton>
        </Box>
      </DialogActions>
    </Dialog>
  );
}

export default SimpleModal;
