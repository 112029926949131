import React, {useRef } from "react";
import { createPortal } from "react-dom";
import CheckoutForm from "../components/PaymentGateway/CheckoutForm";
import Modal from "../components/Modal";

const modalElement = document.getElementById("modal-root");

const options = {
  success: {
    "bg-color": "green",
    "fa-icon": "fas fa-check-square fa-2x",
  },
  failure: {
    "bg-color": "red",
    "fa-icon": "fas fa-exclamation-triangle fa-2x",
  },
  info: {
    "bg-color": "blue",
    "fa-icon": "fas fa-info-circle fa-2x",
  },
  close: {
    "bg-color": "gray",
    "fa-icon": "fas fa-times-circle fa-2x",
  },
};



const AlertMsg = (props) => {


const createPaymentModal = useRef(null);

const openModal= () =>{
  //props.close();
  createPaymentModal.current.open();
 }

  return createPortal(
    <div id={props.header === "Confirmation"?"paypop":"no"} className="fixed z-100 inset-0 overflow-y-auto">
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
          <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>

        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        >
          &#8203;
        </span>

        <div
          className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >

           {props.header === "Confirmation" &&
            <div className="mx-auto flex-shrink-0 flex items-end justify-center h-8 w-8 rounded-full sm:mx-0 sm:h-10 sm:w-10 c-closepay">
            <i
              className={options.close["fa-icon"]}
              style={{ color: options.close["bg-color"],fontSize:"22px" }}
              onClick={props.close}
            ></i>
          </div>
            
            }
          <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">         
            <div className="sm:flex sm:items-start">
              <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full sm:mx-0 sm:h-10 sm:w-10">
                <i
                  className={options[props.ctype]["fa-icon"]}
                  style={{ color: options[props.ctype]["bg-color"] }}
                ></i>
              </div>
              <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                <h3
                  className="text-lg leading-6 font-medium text-gray-900"
                  id="modal-headline"
                >
                  {props.header}
                </h3>
                <div className="mt-2">
                  <p className="text-sm text-gray-600">{props.message}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
          {props.header !== "Confirmation" &&
            <button
              type="button"
              className="mt-3 w-full z-100 inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
              onClick={props.close}
            >
              Close
            </button>      
             }  

          {/*props.header == "Confirmation" &&
             <Checkout amount={10} messages={props} Close={closeConfirm}/>
            */}

          {props.header === "Confirmation" &&
            <button
            className="bg-secondary-color text-white active:bg-gray-700 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full"
            type="button"
            style={{ transition: "all .15s ease" }}      
            onClick={openModal}
          >
            Continue
          </button>     
          } 
           
          </div>

        </div>
      </div>
      <Modal
    ref={createPaymentModal}
    component={<CheckoutForm ref={{ createPaymentModal}} />}
  ></Modal>
    </div>,
    modalElement
  
  );
};

export default AlertMsg;
