import { Typography } from "@material-ui/core";
import React from "react";

function ReportLoadingMessage() {
  return (
    <>
      <Typography color={"textSecondary"}>
        <b>{"Please wait while we retrieve and prepare the report for you."}</b>
      </Typography>
      <Typography color={"textSecondary"}>
        <b>
          {
            "This may take a moment, depending on the size and complexity of the data."
          }
        </b>
      </Typography>
    </>
  );
}

export default ReportLoadingMessage;
