import React, {
  useEffect,
  useImperativeHandle,
  useState,
  forwardRef,
  useCallback,
} from "react";
import { createPortal } from "react-dom";

const modalElement = document.getElementById("modal-root");

const Modal = ({ component, defaultOpened = false }, ref) => {
  const [isOpen, setIsOpen] = useState(defaultOpened);

  const close = useCallback(() => setIsOpen(false), []);

  // Expose method to parent component
  useImperativeHandle(
    ref,
    () => ({
      open: () => setIsOpen(true),
      close,
    }),
    [close]
  );

  const handleEscape = useCallback(
    (event) => {
      if (event.keyCode === 27) close();
    },
    [close]
  );

  // Key stroke activity works only when component is open (did mount)
  useEffect(() => {
    if (isOpen) document.addEventListener("keydown", handleEscape, false);
    return () => {
      document.removeEventListener("keydown", handleEscape, false);
    };
  }, [handleEscape, isOpen]);

  // Portal to a new div root
  return createPortal(
    isOpen ? (
      /* If not modal will be placed at bottom */
      <div className="c-modal fixed z-100 inset-0 overflow-y-auto">
        {/* If not modal will be placed at right */}
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          {/* Makes background transparent */}
          <div
            className="fixed inset-0 transition-opacity"
            aria-hidden="true"
            onClick={close}
          >
            <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
          </div>

          {/*<!-- This element is to trick the browser into centering the modal contents. --> */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <div
            className="inline-block text-left sm:align-middle sm:w-full"
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-headline"
          >
            {component}
          </div>
        </div>
      </div>
    ) : null,
    modalElement
  );
};

// Forward reference to the modal component
export default forwardRef(Modal);
