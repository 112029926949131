import { Box, Button, Card, Typography } from "@material-ui/core";
import Autocomplete from "@mui/material/Autocomplete";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import React, { useRef } from "react";
import LoadingButton from "../../../commonComponents/LoadingButton";

const Upload = ({
  setSelectedSkill,
  setSelectedTitle,
  handleFileChange,
  selectedTitle,
  selectedSkill,
  skillList,
  titleList,
  errormatch,
  loading,
}) => {
  // Changed state variable name to plural

  const handleChangeTitle = (event, newValues) => {
    setSelectedTitle([newValues]);
  };

  const fileInputRef = useRef(null);

  const handleFileUpload = () => {
    fileInputRef.current.click();
  };

  return (
    <Card style={{ minHeight: "320px" }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          p: 2,
        }}
      >
        <Box sx={{ ml: 2 }}>
          <Typography variant="h6" component="div">
            Custom Match
          </Typography>
        </Box>
      </Box>

      <Box sx={{ width: "100%", p: 2 }}>
        <Box>
          <Typography
            variant="h6"
            color="primary"
            fontWeight="bold"
            component="div"
          >
            Please select a title (max 1) and/or skills (max 9)
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            mt: 2,
          }}
        >
          <Typography variant="body1" fontWeight="bold" component="div">
            Title
          </Typography>
          <Autocomplete
            sx={{ width: "70%" }}
            size="small"
            value={selectedTitle}
            onChange={handleChangeTitle}
            options={titleList}
            getOptionLabel={(option) => option}
            renderInput={(params) => (
              <TextField {...params} variant="outlined" />
            )}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            mt: 2,
          }}
        >
          <Typography variant="body1" fontWeight="bold" component="div">
            Skill
          </Typography>
          <Autocomplete
            sx={{ width: "70%" }}
            size="small"
            multiple // Enable multiple selections
            value={selectedSkill}
            onChange={(event, newValues) => {
              setSelectedSkill(newValues); // Changed state variable name
            }}
            options={skillList}
            getOptionLabel={(option) => option.display}
            renderInput={(params) => (
              <TextField {...params} variant="outlined" />
            )}
          />
        </Box>
        <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
          <input
            type="file"
            accept=".pdf, .doc, .docx" // Specify accepted file types if needed
            style={{ display: "none" }}
            ref={fileInputRef}
            onChange={handleFileChange}
          />
          <LoadingButton loading={loading} onClick={handleFileUpload} autoFocus>
            <b>{" Upload File"}</b>
          </LoadingButton>
        </Box>
        {errormatch && (
          <Box color="red" fontWeight="bold">
            {errormatch}
          </Box>
        )}
      </Box>
    </Card>
  );
};

export default Upload;
