import { Box, Collapse, IconButton, Typography } from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import React from "react";
import {
  CollapsibleWrapper,
  FlexColumnView,
  StyledAccordionHeader,
} from "../StyledComponents";

function Accordion({ title, open, name, handleOpen = () => {}, children }) {
  return (
    <FlexColumnView>
      <Box onClick={() => handleOpen(name)}>
        <StyledAccordionHeader open={open}>
          <Typography noWrap>{title}</Typography>
          <IconButton color={"primary"}>
            <ExpandMore className={"expand"} />
          </IconButton>
        </StyledAccordionHeader>
      </Box>
      <Collapse in={open} timeout={"auto"} unmountOnExit>
        <CollapsibleWrapper>{children}</CollapsibleWrapper>
      </Collapse>
    </FlexColumnView>
  );
}

export default Accordion;
