import { Button, Grid, TextField, Typography } from "@material-ui/core";
import axios from "axios";
import { Form, FormikProvider, useFormik } from "formik";
import React, { useEffect, useState } from "react";
import * as yup from "yup";
import { panelSetting } from "../../api/api";

const validationSchema = yup.object({
  panel_1: yup.string().nullable().email("Enter a valid email").optional(),
  panel_2: yup.string().nullable().email("Enter a valid email").optional(),
  panel_3: yup.string().nullable().email("Enter a valid email").optional(),
  panel_4: yup.string().nullable().email("Enter a valid email").optional(),
});

function PanelSettings() {
  const getAndSetPanelSettings = () => {
    axios.get(panelSetting()).then((response) => {
      if (response.status === 200) {
        if (response.data.length > 0) {
          setValues({
            ...response.data[0],
          });
        }
      }
    });
  };

  const [success, setSuccess] = useState(false);

  useEffect(() => {
    getAndSetPanelSettings();
  }, []);

  const formik = useFormik({
    initialValues: {
      id: "",
      panel_1: "",
      panel_2: "",
      panel_3: "",
      panel_4: "",
    },
    validationSchema: validationSchema,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });

  function handleSubmit(validValues) {
    let requestBody = {};
    requestBody.panel_1 = validValues.panel_1 || null;
    requestBody.panel_2 = validValues.panel_2 || null;
    requestBody.panel_3 = validValues.panel_3 || null;
    requestBody.panel_4 = validValues.panel_4 || null;

    if (values.id) {
      requestBody.id = values.id;
    }
    axios.post(panelSetting(), requestBody).then((response) => {
      if (response.status === 201) {
        setValues({ ...response.data });
        setSuccess(true);
      }
    });
  }

  const { values, touched, errors, setValues, handleBlur, handleChange } =
    formik;

  return (
    <FormikProvider value={formik}>
      <Form>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography>
              <b>{"Panel Settings"}</b>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              name={"panel_1"}
              variant={"outlined"}
              label={"Panel Member 1"}
              value={values.panel_1 || ""}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.panel_1 && Boolean(errors.panel_1)}
              helperText={touched.panel_1 && errors.panel_1}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              name={"panel_2"}
              variant={"outlined"}
              label={"Panel Member 2"}
              value={values.panel_2 || ""}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.panel_2 && Boolean(errors.panel_2)}
              helperText={touched.panel_2 && errors.panel_2}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              name={"panel_3"}
              variant={"outlined"}
              label={"Panel Member 3"}
              value={values.panel_3 || ""}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.panel_3 && Boolean(errors.panel_3)}
              helperText={touched.panel_3 && errors.panel_3}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              name={"panel_4"}
              variant={"outlined"}
              label={"Panel Member 4"}
              value={values.panel_4 || ""}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.panel_4 && Boolean(errors.panel_4)}
              helperText={touched.panel_4 && errors.panel_4}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} container alignItems={"center"}>
            <Button
              size={"large"}
              type={"submit"}
              variant={"contained"}
              color={"primary"}
            >
              Submit
            </Button>
          </Grid>
          <Grid item xs={12} container alignItems="center">
            <Typography
              style={{
                color: "#008000",
                visibility: success ? "visible" : "hidden",
              }}
            >
              {success ? "Saved successfully" : "_"}
            </Typography>
          </Grid>
        </Grid>
      </Form>
      <br />
    </FormikProvider>
  );
}

export default PanelSettings;
