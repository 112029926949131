import React from "react";

const CandidateLink = () => {
  return (
    <section className="c-modules c-py-2">
      <p className="c-sm c-text-center c-my-1">
        <h2>Candidate Submit Form</h2> coming soon...
      </p>
    </section>
  );
};

export default CandidateLink;
