import { Container, Grid } from "@material-ui/core";
import React from "react";
import { Link, Route } from "react-router-dom";
import Applicants from "../Applicants/Applicants";
import CandidateLink from "../CandidateLink";
import CompareResume from "../CompareResume";
import CompetitorCompany from "../CompetitorCompany/CompetitorCompany";
import ExtractSkills from "../ExtractSkills/ExtractSkills";
import FeedbackDashboard from "../FeedbackDashboard/FeedbackDashboard";
import SkillMaster from "../SkillMaster/SkillMaster";
import UploadParseResume from "../UploadParseResume";
import undraw_contrast_vb82 from "../../images/svgs/undraw_contrast_vb82.svg";
import undraw_File_searching_re_3evy from "../../images/svgs/undraw_File_searching_re_3evy.svg";
import undraw_social_user_lff0 from "../../images/svgs/undraw_social_user_lff0.svg";
import undraw_updated_resume_u4fy from "../../images/svgs/undraw_updated_resume_u4fy.svg";
import JobDetail from "../JobDetail/JobDetail";
import JobDesWeightage from "../JobDesWeighatage/JobDesWeightage";
import Settings from "../Settings/Index";
import Hierarchy from "../Hierarchy/Index";
import Reports from "../Reports/Index";
import SubmissionReport from "../Reports/SubmissionReport";
import TestReport from "../Reports/TestReport";
import RecommendReport from "../Reports/RecommendReport";
import RejectedReport from "../Reports/RejectedReport";
import { path } from "../../utils/constants/routePaths";
import ApplicationHistoryReport from "../Reports/ApplicationHistoryReport";
import FunnelApplicants from "../Funnel/Index";

function NavCards() {
  return (
    <Grid container>
      <Grid item xs={12}>
        <Route exact path="/home/extractSkills" component={ExtractSkills} />
        <Route exact path="/home/parseResume" component={UploadParseResume} />
        <Route exact path="/home/compareResume" component={CompareResume} />
        <Route exact path="/home/candidateLink" component={CandidateLink} />
        <Route exact path="/home/skillMaster" component={SkillMaster} />
        <Route
          exact
          path="/home/competitorCompany"
          component={CompetitorCompany}
        />
        <Route
          exact
          path="/home/feedbackDashboard"
          component={FeedbackDashboard}
        />
        <Route
          exact
          path="/home/applicants"
          component={FunnelApplicants}
        />
        <Route
          exact
          path="/home/applicants/:jobCode/:status"
          component={Applicants}
        />
        <Route path="/home/jobDetail" component={JobDetail} />
        <Route
          exact
          path="/home/jobDesWeightage/:jobCode"
          component={JobDesWeightage}
        />
        {/* Hierarchy */}
        <Route exact path="/home/hierarchy" component={Hierarchy} />
        <Route exact path="/home/hierarchy/:name" component={Hierarchy} />
        {/* Reports */}
        <Route exact path={path.reports} component={Reports} />
        <Route
          exact
          path={path.viewSubmissionReport}
          component={SubmissionReport}
        />
        <Route exact path={path.viewTestReport} component={TestReport} />
        <Route
          exact
          path={path.viewRecommendReport}
          component={RecommendReport}
        />
        <Route
          exact
          path={path.viewRejectedReport}
          component={RejectedReport}
        />
        {/* Settings */}
        <Route exact path={path.settings} component={Settings} />
        <Route exact path={`${path.settings}/:type`} component={Settings} />
        <Route
          exact
          path={path.viewApplicationHistoryReport}
          component={ApplicationHistoryReport}
        />
      </Grid>
    </Grid>
  );
}

export default NavCards;
