import axios from "axios";

if (localStorage.getItem("tal_token") != null) {
  var options = {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: "Bearer " + localStorage.getItem("tal_token"),
    },
  };
} else {
  var options = {
    headers: { "Content-Type": "multipart/form-data" },
  };
}

export default {
  // Share the uploaded resume to the backend for processing

  uploadResume: function (file, options) {
    return axios.post("/resume/extract-resume/", file, options);
  },

  inferResume: function (file, options) {
    return axios.post("/resume/infer-resume/", file, options);
  },

  // It is not used anymore
  // uploadedResumePDF: function (file) {
  //     return axios.post( "/resume_intl/uploaded_resume/",file, { headers: { 'Content-Type': 'multipart/form-data' } });
  // },

  // Once the recruiter update the uploaded resume, save the updated resume in the backend
  saveProcessedResumes: function (processedResumes, options) {
    return axios.post("/resume_intl/upload/save", processedResumes, options);
  },

  //share the Custom match  skill or title and the selected resumes for doing custom match
  customMatchSkillWithResumes: function (customMatchRequestJsonArr) {
    return axios.post(
      "/resume_intl/custom_skills_upload/",
      customMatchRequestJsonArr
    );
  },

  //share the Custom match  skill or title and the selected resumes for doing custom match
  customMatchSkillResumes: function (formData, options) {
    return axios.post("/resume-compare/compare-resume/", formData, options);
  },

  customMatchSkillResumesTest: function (formData) {
    return axios.post("/resume-compare/compare-resume-test/", formData);
  },

  // Once the Custom Match is shown in the front end, user can download this report from UI
  customMatchDownloadReport: function (downloadReportJson) {
    return axios.post("/resume_intl/custom_match_report/", downloadReportJson);
  },

  // Get the Filter List values for the Resume Parsing
  getFilterList: function (reqData) {
    return axios.post("/resume_intl/filter_list/", reqData);
  },

  // Get the Search Results values based on the filter Criteria
  getUpdateView: function (reqData) {
    return axios.post("/resume_intl/update_view/", reqData);
  },

  // Get the Resume Details based on the clicked Resume Id
  getCurrentResume: function (id) {
    return axios.get("/resume_intl/current_resume?q1=" + id);
  },

  // After the changes are made on the current resume save it
  saveCurrentResumeChanges: function (saveData) {
    return axios.post("/resume_intl/save_changes/", saveData);
  },

  // get the list of Skills to custom Match against
  fetchMatchedCustomSkillList: function (customSkillList) {
    return axios.post("/resume_intl/custom_skills/", customSkillList);
  },

  getInferredTitle: function (resumes) {
    return axios.post("/resume-compare/compare-title/", resumes);
  },
};
