import React from "react";
import { useAlert } from "react-alert";
import 'react-confirm-alert/src/react-confirm-alert.css';

// Define constants
const chargePerMonthFree = "$0";
const chargePerMonthPremium = "$10";
const chargePerMonthProfessional = "$50";

const Pricing = () => {
  // use alert hook
  const alert = useAlert();

  const learnMore = () => {
    // Show custom alert
    alert.show(
      "No action is required. You can continue using the free options until rate limited.",
      {
        header: "Welcome Free User!",
        ctype: "info",
      }
    );
  };

  const contactUs = () => {
    // Show custom alert
    alert.show(
      "Please email us with your questions at support@talentium.ai or fill out the form in the homepage.",
      {
        header: "Interested?",
        ctype: "info",
      }
    );
  };

  const Premium = () => {  
    alert.show(
      "Titan Data partners with Stripe to fulfill your payment. By continuing you agree to provide your card details and other payment information to Stripe. Titan Data does not store your card information and Stripe fully handles the transaction.",
      {
        header: "Confirmation",
        ctype: "info",       
      }
    );
  };


  return (
    <div className="container lg:w-6/12 md:w-10/12 sm:w-full mx-auto px-4 h-full">
      <div className="flex content-center items-center flex-col md:flex-row md:transform md:scale-75 lg:scale-100 justify-center">
        <div className="w-10/12 md:w-4/12 border-0 rounded-lg text-center p-5 mx-auto md:mx-0 my-2 bg-primary-color text-white font-medium z-10 shadow-lg transform hover:scale-110">
          <div className="font-bold text-2xl">Basic</div>
          <p className="text-sm">per license monthly cost</p>
          <div id="monthly" className="font-bold text-4xl p-2">
            {chargePerMonthFree}
          </div>
          <hr />
          <div className="text-md my-3">All features</div>
          <hr />
          <div className="text-md my-3">Limited Attempts</div>
          <hr />
          <div className="text-md my-3">No Report Download</div>
          <hr />
          <div className="text-center mt-6">
            <button
              className="bg-secondary-color text-white active:bg-gray-700 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full"
              type="button"
              style={{ transition: "all .15s ease" }}
              onClick={learnMore}
            >
              Learn More
            </button>
          </div>
        </div>

        <div className="w-10/12 md:w-4/12 border-0 rounded-lg text-center p-5 mx-auto md:mx-0 my-2 bg-primary-color text-white font-medium z-10 shadow-lg transform hover:scale-110">
          <div className="font-bold text-2xl">Premium</div>
          <p className="text-sm">per license monthly cost</p>
          <div id="monthly" className="font-bold text-4xl p-2">
            {chargePerMonthPremium}
          </div>
          <hr />
          <div className="text-md my-3">All features</div>
          <hr />
          <div className="text-md my-3">No Usage Limit</div>
          <hr />
          <div className="text-md my-3">Downloadable Report</div>
          <hr />
          <div className="text-md my-3">Queued Customer Support</div>
          <hr />
         <div className="text-center mt-6">
          <button
              className="bg-secondary-color text-white active:bg-gray-700 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full"
              type="button"
              style={{ transition: "all .15s ease" }}
              onClick={Premium}
            >
              Purchase
            </button>
  </div>
           {/*<div className="text-center mt-6">
          <Checkout amount={10} />
</div>*/}
        </div>

        <div className="w-10/12 md:w-4/12 border-0 rounded-lg text-center p-5 mx-auto md:mx-0 my-2 bg-gradient-to-r from-purple-400 to-blue-500 text-white font-medium z-10 shadow-lg transform hover:scale-110">
          <div className="font-bold text-2xl">Professional</div>
          <p className="text-sm">per license monthly cost</p>
          <div id="monthly" className="font-bold text-4xl p-2">
            {chargePerMonthProfessional}
          </div>
          <hr />
          <div className="text-md my-3">All Features + Custom Features</div>
          <hr />
          <div className="text-md my-3">No Usage Limit</div>
          <hr />
          <div className="text-md my-3">Dashboard & Analytics</div>
          <hr />
          <div className="text-md my-3">Save & Access History</div>
          <hr />
          <div className="text-md my-3">Priority Customer Support</div>
          <hr />
          <div className="text-center mt-6">
            <button
              className="bg-secondary-color text-white active:bg-gray-700 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full"
              type="button"
              style={{ transition: "all .15s ease" }}
              onClick={contactUs}
            >
              Contact Us
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pricing;
