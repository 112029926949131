import {
    Box,
    Button,
    FormControl,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
    Typography,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import SearchIcon from "@material-ui/icons/Search";
import Pagination from "@material-ui/lab/Pagination";
import axios from "axios";
import { useFormik } from "formik";
import React, { useCallback, useEffect, useState } from "react";
import * as yup from "yup";
import {
    getUser,
    getSingleUser,
    createUser,
    deleteUser,
    updateUser,
} from "../../api/api";
import InputField from "../../commonComponents/Controllers/InputField";
import DeleteDialog from "../../commonComponents/DeleteDialog/DeleteDialog";
import Loader from "../../commonComponents/Loader/Loader";
import SimpleModal from "../../commonComponents/Modal/SimpleModal";
import CustomSnack from "../../commonComponents/Snack/CustomSnack";
import "../../styles/style.css";
import {
    CREATED_MESSAGE,
    DELETED_MESSAGE,
    SOMETHING_WENT_WRONG,
    UPDATED_MESSAGE,
} from "../../utils/constants/constants";
import { debounce } from "../../utils/constants/helpers";

export const Header = [
    {
        id: 1,
        name: "First Name",
        className: "thChild",
        align: "left",
    },
    {
        id: 2,
        name: "Last Name",
        className: "thlast",
        align: "left",
    },
    {
        id: 3,
        name: "Email",
        className: "thC",
        align: "left",
    },
    {
        id: 4,
        name: "Role",
        className: "throle",
        align: "left",
    },
    {
        id: 5,
        name: "Action",
        className: "th",
        align: "center",
    },
];

export const Options = [
    {
        id: 1,
        value: "10",
    },
    {
        id: 2,
        value: "20",
    },
    {
        id: 3,
        value: "30",
    },
    {
        id: 4,
        value: "40",
    },
    {
        id: 5,
        value: "50",
    },
    {
        id: 6,
        value: "100",
    },
];

axios.defaults.xsrfHeaderName = "X-CSRFToken";
axios.defaults.xsrfCookieName = "csrftoken";

function UserSetting() {
    const [UserHierarchy, setUserHierarchy] = useState([]);

    const [snack, setSnack] = useState({
        open: false,
        color: "",
        message: "",
    });

    const roles = [
        { value: 'admin', label: 'Admin' },
        { value: 'manager', label: 'Manager' },
        { value: 'recruiter', label: 'Recruiter' },
        { value: 'client_admin', label: 'Client Admin' },
        // Add more roles as needed
    ];

    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

    const [deleteId, setDeleteId] = useState(null);

    const [editId, setEditId] = useState(null);

    const [searchUser, setSearchUser] = useState("");

    const [totalCount, setTotalCount] = useState(0);

    const [pagination, setPagination] = useState({
        activePage: 1,
        items: 10,
    });

    const [UserDialogOpen, setUserDialogOpen] =
        useState(false);

    const [loading, setLoading] = useState(false);

    const validationSchema = yup.object({
        username: yup
            .string()
            .nullable()
            .required("Please fill the required field"),
    });

    const handleSnackClose = () => {
        setSnack({
            open: false,
            color: "",
            message: "",
        });
    };

    const handleSave = (values, { resetForm }) => {
        let payload = {
            first_name: values.first_name,
            last_name: values.last_name,
            username: values.username,
            email: values.email,
            role: values.role,
            password: values.password,
        };

        if (editId) {
            // Edit Hobbies Hierarchy
            axios
                .put(updateUser(editId), payload)
                .then((response) => {
                    setUserDialogOpen(false);
                    setSnack({
                        open: true,
                        color: "success",
                        message: UPDATED_MESSAGE,
                    });
                    if (response.status === 200) {
                        resetForm();
                        setTimeout(() => {
                            let offset =
                                pagination.activePage === 0
                                    ? 0
                                    : (pagination.activePage - 1) * parseInt(pagination.items);
                            getAndSetUser(pagination.items, offset);
                        }, 2500);
                    } else {
                        setSnack({
                            open: true,
                            color: "error",
                            message: SOMETHING_WENT_WRONG,
                        });
                    }
                })
                .catch((err) => {
                    setSnack({
                        open: true,
                        color: "error",
                        message: SOMETHING_WENT_WRONG,
                    });
                });
        } else {
            // Add Hobbies Hierarchy
            axios
                .post(createUser(), payload)
                .then((response) => {
                    if (response.status === 201) {
                        setUserDialogOpen(false);
                        setSnack({
                            open: true,
                            color: "success",
                            message: CREATED_MESSAGE,
                        });
                        setTimeout(() => {
                            resetForm();
                            getAndSetUser(pagination.items, 0);
                            setPagination({
                                activePage: 1,
                                items: 10,
                            });
                        }, 2500);
                    } else {
                        setSnack({
                            open: true,
                            color: "error",
                            message: SOMETHING_WENT_WRONG,
                        });
                    }
                })
                .catch((err) => {
                    setSnack({
                        open: true,
                        color: "error",
                        message: SOMETHING_WENT_WRONG,
                    });
                });
        }
    };

    const formik = useFormik({
        initialValues: {
            first_name: "",
            last_name: "",
            username: "",
            email: "",
            password: "",
            role: "",
        },
        validationSchema: validationSchema,
        enableReinitialize: true,
        onSubmit: handleSave,
    });

    const {
        values,
        setValues,
        errors,
        touched,
        setFieldValue,
        handleChange,
        handleSubmit,
        resetForm,
    } = formik;

    const handleRoleChange = (event) => {
        setFieldValue('role', event.target.value); // Update role value
    };

    const getAndSetUser = (limit, offset, search = searchUser) => {
        setLoading(true);
        axios
            .get(getUser(), {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("tal_token")}`,
                },
                params: {
                    limit: limit,
                    offset: offset,
                    search: search,
                },
            })
            .then((response) => {
                setUserHierarchy(response.data);
                setTotalCount(response.data.length);
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
                setSnack({
                    open: true,
                    color: "error",
                    message: SOMETHING_WENT_WRONG,
                });
            });
    };

    useEffect(() => {
        getAndSetUser(pagination.items, 0);
    }, []);

    const onClickEdit = (id) => {
        axios
            .get(getSingleUser(id), {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("tal_token")}`,
                },
            })
            .then((response) => {
                setEditId(id);
                setValues({
                    first_name: response?.data.first_name || "",
                    last_name: response?.data.last_name || "",
                    username: response?.data.username || "",
                    email: response?.data.email || "",
                    role: response?.data.role || "",
                });
                setUserDialogOpen(true);
            })
            .catch((error) => {
                setSnack({
                    open: true,
                    color: "error",
                    message: SOMETHING_WENT_WRONG,
                });
            });
    };

    const onSelectPage = (e, value) => {
        let offset = value === 0 ? 0 : value - 1;
        getAndSetUser(pagination.items, offset * pagination.items);

        setPagination({
            ...pagination,
            activePage: value,
        });
    };

    const handlePageLimitChange = (e) => {
        const { value } = e.target;
        getAndSetUser(
            parseInt(value),
            (pagination.activePage - 1) * parseInt(value)
        );
        setPagination({
            ...pagination,
            items: parseInt(value),
        });
    };

    const handleDeleteClick = () => {
        axios
            .delete(deleteUser(deleteId))
            .then((response) => {
                setDeleteDialogOpen(false);
                setDeleteId(null);
                if (response.status === 204) {
                    setSnack({
                        open: true,
                        color: "success",
                        message: DELETED_MESSAGE,
                    });
                    setTimeout(() => {
                        let offset =
                            pagination.activePage === 0 ? 0 : pagination.activePage - 1;
                        getAndSetUser(
                            pagination.items,
                            offset * pagination.items
                        );
                    }, 2500);
                } else {
                    setSnack({
                        open: true,
                        color: "error",
                        message: SOMETHING_WENT_WRONG,
                    });
                }
            })
            .catch((err) => {
                setDeleteDialogOpen(false);
                setDeleteId(null);
                setSnack({
                    open: true,
                    color: "error",
                    message: SOMETHING_WENT_WRONG,
                });
            });
    };

    const handleSearchChange = (value) => {
        getAndSetUser(pagination.items, 0, value);
        setSearchUser(value);
    };

    const handleDeleteRowClick = (id) => {
        setDeleteId(id);
        setDeleteDialogOpen(true);
    };

    const handleDeleteCancel = () => {
        setDeleteDialogOpen(false);
        setDeleteId(null);
    };

    const handleAdd = () => {
        setUserDialogOpen(true);
    };

    const optimizedFn = useCallback(debounce(handleSearchChange), [pagination]);

    const handleUserDialogClose = () => {
        resetForm();
        setUserDialogOpen(false);
        setTimeout(() => {
            setEditId(null);
        }, 500)
    };

    return (
        <Box>
            <Grid container spacing={4}>
                <Grid item xs={12}>
                    <Grid container spacing={4} alignItems={"flex-end"}>
                        <Grid item xs>
                            <Typography style={{ paddingBottom: "5px" }}>
                                <b>{"Users"}</b>
                            </Typography>
                        </Grid>
                        <Grid item>
                            <TextField
                                id={"search"}
                                label={"Search"}
                                onChange={(e) => optimizedFn(e.target.value)}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton>
                                                <SearchIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                        <Grid item>
                            <Button
                                variant={"contained"}
                                color={"primary"}
                                onClick={handleAdd}
                            >
                                <b className={"capitalize"}>{"Add User"}</b>
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Table size={"small"} component={Paper} variant={"outlined"}>
                        <TableHead>
                            <TableRow>
                                {Header.map(({ name, className, align }, ind) => (
                                    <TableCell
                                        className={className}
                                        align={align || "inherit"}
                                        key={`head-${ind + 1}`}
                                    >
                                        <Typography className={"tableHead"}>{name}</Typography>
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {!loading && UserHierarchy?.length ? (
                                UserHierarchy.map(({ id, first_name, last_name, email, role }) => (
                                    <TableRow key={id}>
                                        <TableCell>{first_name || "-"}</TableCell>
                                        <TableCell>{last_name || "-"}</TableCell>
                                        <TableCell>{email || "-"}</TableCell>
                                        <TableCell>{role || "-"}</TableCell>
                                        <TableCell>
                                            <div className={"editTab"}>
                                                <IconButton
                                                    color={"primary"}
                                                    onClick={() => onClickEdit(id)}
                                                >
                                                    <EditIcon />
                                                </IconButton>
                                                <IconButton
                                                    color={"secondary"}
                                                    onClick={() => handleDeleteRowClick(id)}
                                                >
                                                    <DeleteIcon />
                                                </IconButton>
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                ))
                            ) : (
                                <TableRow>
                                    <TableCell colSpan={3} align={"center"}>
                                        {loading ? (
                                            <Loader />
                                        ) : (
                                            <Typography>
                                                <b>{"No results found"}</b>
                                            </Typography>
                                        )}
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </Grid>
                <Grid
                    item
                    container
                    xs={12}
                    alignItems={"center"}
                    justifyContent={"flex-end"}
                >
                    <Typography>
                        Total Count: <b>{totalCount}</b>
                    </Typography>
                </Grid>
                <Grid item xs={2} container alignItems={"center"}>
                    <FormControl style={{ width: 120 }}>
                        <InputLabel id={"demo-simple-select-label"}>
                            {"Rows per page"}
                        </InputLabel>
                        <Select
                            labelId={"demo-simple-select-label"}
                            id={"demo-simple-select"}
                            value={pagination.items}
                            onChange={handlePageLimitChange}
                        >
                            {Options.map(({ id, value }) => (
                                <MenuItem key={id} value={value}>
                                    {value}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid
                    item
                    xs={10}
                    container
                    alignItems={"center"}
                    justifyContent={"flex-end"}
                >
                    <Pagination
                        color={"primary"}
                        page={pagination.activePage}
                        onChange={onSelectPage}
                        count={Math.ceil(totalCount / pagination.items)}
                        showFirstButton
                        showLastButton
                    />
                </Grid>
            </Grid>
            <DeleteDialog
                dialogOpen={deleteDialogOpen}
                handleClose={handleDeleteCancel}
                handleYesClick={handleDeleteClick}
            />
            <SimpleModal
                open={UserDialogOpen}
                title={`${editId ? "Edit" : "Add"} User`}
                handleClose={handleUserDialogClose}
                handleSave={handleSubmit}
            >
                <Box minHeight={"80px"}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <InputField
                                id={"first_name"}
                                label={"First Name"}
                                name={"first_name"}
                                value={values.first_name || ""}
                                onChange={handleChange}
                                error={touched.first_name && Boolean(errors.first_name)}
                                helperText={touched.first_name && errors.first_name}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <InputField
                                id={"last_name"}
                                label={"Last Name"}
                                name={"last_name"}
                                value={values.last_name || ""}
                                onChange={handleChange}
                                error={touched.last_name && Boolean(errors.last_name)}
                                helperText={touched.last_name && errors.last_name}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <InputField
                                id={"username"}
                                label={"User Name"}
                                name={"username"}
                                value={values.username || ""}
                                onChange={handleChange}
                                error={touched.username && Boolean(errors.username)}
                                helperText={touched.username && errors.username}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <InputField
                                id={"email"}
                                label={"Email"}
                                name={"email"}
                                value={values.email || ""}
                                onChange={handleChange}
                                error={touched.email && Boolean(errors.email)}
                                helperText={touched.email && errors.email}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth
                                variant="outlined"
                                size="small"
                            >
                                <InputLabel id="demo-simple-select-label">Role</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={values.role}
                                    label="Role"
                                    onChange={handleRoleChange}
                                >
                                    {roles.map((role) => (
                                        <MenuItem key={role.value} value={role.value}>
                                            {role.label}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        {!editId && (
                            <Grid item xs={12}>
                                <InputField
                                    id={"password"}
                                    label={"Password"}
                                    name={"password"}
                                    type={"password"}
                                    InputProps={{ autoComplete: "new-password" }}
                                    value={values.password || ""}
                                    onChange={handleChange}
                                    error={touched.password && Boolean(errors.password)}
                                    helperText={touched.password && errors.password}
                                />
                            </Grid>
                        )}
                    </Grid>
                </Box>
            </SimpleModal >
            <CustomSnack
                open={snack.open}
                color={snack.color}
                message={snack.message}
                onClose={handleSnackClose}
            />
        </Box >
    );
}

export default UserSetting;
