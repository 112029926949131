import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import React from "react";
import CKEditor5 from "../../../commonComponents/CKEditor/CKEditor";
import SimpleModal from "../../../commonComponents/Modal/SimpleModal";
import { FlexView } from "../../../commonComponents/StyledComponents";

function PunctuationList({
  misSpelledCheck,
  misSpelledCheckData,
  punctuationList,
  handleMisSpelledCheck,
  handleMisSpelledCheckPopupClose,
  handleSaveMisSpelledCheck,
  handleEditorValueChange,
  handleIgnorePunctuationCheck,
}) {
  return (
    <Box p={1}>
      <Box pb={2}>
        <Typography align={"center"} variant={"h6"}>
          <b>{"Punctuation List"}</b>
        </Typography>
      </Box>
      <Box>
        {punctuationList && punctuationList.length !== 0 ? (
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Typography>
                      <b>{"Sentence"}</b>
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>
                      <b>{"Error Type"}</b>
                    </Typography>
                  </TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {punctuationList.map((punctuation, index) => {
                  return (
                    <TableRow key={`punctuation-${index}`}>
                      <TableCell>
                        <Typography>
                          <span>
                            {punctuation.line.split(punctuation.word)[0]}
                          </span>
                          {/* use the class to highlight the Incorrect Word */}
                          <span className="grammarCheckHighlightWord">
                            {punctuation.word}
                          </span>
                          {/* Grammar Check sentence after the check word */}
                          {punctuation.line.split(punctuation.word)[1]}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography>{punctuation.error_type}</Typography>
                      </TableCell>
                      <TableCell>
                        <FlexView gridGap={"24px"}>
                          <Button
                            variant={"contained"}
                            color={"primary"}
                            size={"small"}
                            onClick={() =>
                              handleMisSpelledCheck(
                                punctuation.line.replace(
                                  punctuation.word,
                                  `<mark class='marker-yellow'>${punctuation.word}</mark>`
                                ),
                                punctuation.line
                              )
                            }
                          >
                            <b>{"Edit"}</b>
                          </Button>
                          <Button
                            variant={"contained"}
                            color={"secondary"}
                            size={"small"}
                            onClick={() =>
                              handleIgnorePunctuationCheck(punctuation.line)
                            }
                            style={{ fontWeight: "bold", minWidth: "78px" }}
                          >
                            {"Ignore"}
                          </Button>
                        </FlexView>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Typography align={"center"}>
            <b>{"Punctuation error not found"}</b>
          </Typography>
        )}
      </Box>
      <SimpleModal
        title={"Edit The Punctuation Word"}
        open={misSpelledCheck}
        handleClose={handleMisSpelledCheckPopupClose}
        handleSave={handleSaveMisSpelledCheck}
      >
        <CKEditor5
          value={misSpelledCheckData.word}
          onChange={(val) =>
            handleEditorValueChange(val, "misSpelledCheckData")
          }
        />
      </SimpleModal>
    </Box>
  );
}

export default PunctuationList;
