import { Box, IconButton, Typography, Button } from "@material-ui/core";
import { CloudUpload, Delete } from "@material-ui/icons";
import React, { useMemo } from "react";
import { useDropzone } from "react-dropzone";

const borderImage = (color) => {
  return `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='10' ry='10' stroke='%23${color}FF' stroke-width='4' stroke-dasharray='10%2c 10' stroke-dashoffset='0' stroke-linecap='butt'/%3e%3c/svg%3e")`;
};

const baseStyle = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "26px 12px 36px",
  transition: "border .3s ease-in-out",
  background: "#ffffff",
  backgroundImage: borderImage("18AAE7"),
  borderRadius: "10px",
  gap: "10px",
  cursor: "move",
  overflow: "hidden",
  outline: "none",
};
const activeStyle = { backgroundImage: borderImage("2196F3") };
const acceptStyle = { backgroundImage: borderImage("00E676") };
const rejectStyle = { backgroundImage: borderImage("FF1744") };
const disabledStyle = { pointerEvents: "none", opacity: 0.5 };

function DropzoneComponent({
  acceptTypes,
  disabled,
  onDrop,
  file,
  onDelete,
  supportedFormatText = ".doc,.docx,.pdf",
  multiple = false,
  loading = false,
}) {
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    onDrop,
    accept: acceptTypes,
    disabled: disabled,
    multiple: multiple,
  });
  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
      ...(disabled ? disabledStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept, disabled]
  );
  return (
    <div>
      <section>
        <div {...getRootProps({ style })}>
          <input {...getInputProps()} />
          <CloudUpload style={{ fontSize: "50px" }} color={"primary"} />
          <Typography align={"center"} variant={"h6"}>
            {"Drag and drop file"} <br /> {"or"}
          </Typography>
          <Button variant={"contained"} color={"primary"} size={"small"}>
            <b>{"Browse"}</b>
          </Button>
          {supportedFormatText ? (
            <Typography align={"center"}>
              {`Supported Formats(${supportedFormatText})`}
            </Typography>
          ) : null}
        </div>
      </section>
      {file && (
        <Box>
          <Box
            display={"flex"}
            justifyContent={"flex-end"}
            alignItems={"center"}
            gap={1}
            pt={1}
          >
            <Typography>{file.name}</Typography>
            <IconButton onClick={onDelete} disabled={loading}>
              <Delete color={"error"} />
            </IconButton>
          </Box>
        </Box>
      )}
    </div>
  );
}

export default DropzoneComponent;
