import React, { Component } from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";

// Importing HTML Elements from Material UI
import { Box, Card } from "@material-ui/core";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";

// Importing Common utils file
import * as c from "../../utils/constants/contants";
import "./style.css";

class CompareFileUpload extends Component {
  render() {
    let {
      toggle,
      handleFileUpload,
      fileLen,
      isAllFileTypeMatch,
      isFileLimitReached,
      fileLength,
      folderUpload = undefined,
      uploadLimit = 20,
    } = this.props;
    //console.log(fileLen);

    return (
      <>
        <Card raised={toggle ? true : false} className="uploadFileCol">
          {/*--- Input Field for the File type and also Drag and Drop functionalities ----*/}

          {this.props.loading && (
            <input
              webkitdirectory={folderUpload}
              type="file"
              name="videofile"
              className="uploadFiles"
              title=" "
              /* multiple keyword lets you add multiple files */
              multiple
              disabled
              id="uploadFile"
              accept={c.SUPPORTING_FILE_TYPES}
              onChange={handleFileUpload}
              onDragOver={(event) =>
                event.target.parentNode.classList.add("fileDragOver")
              }
              onDragLeave={(event) =>
                event.target.parentNode.classList.remove("fileDragOver")
              }
              onDrop={(event) =>
                event.target.parentNode.classList.remove("fileDragOver")
              }
            />
          )}

          {!this.props.loading && (
            <input
              webkitdirectory={folderUpload}
              type="file"
              name="videofile"
              className="uploadFiles"
              title=" "
              /* multiple keyword lets you add multiple files */
              multiple
              disabled={this.props.files.name}
              id="uploadFile"
              accept={c.SUPPORTING_FILE_TYPES}
              onChange={handleFileUpload}
              onClick={(event) => {
                event.target.value = null;
              }}
              onDragOver={(event) =>
                event.target.parentNode.classList.add("fileDragOver")
              }
              onDragLeave={(event) =>
                event.target.parentNode.classList.remove("fileDragOver")
              }
              onDrop={(event) =>
                event.target.parentNode.classList.remove("fileDragOver")
              }
            />
          )}

          {/*------------------------------------------------------------*/}

          {/*---- Drag and Drop Lines to display in the UI ------*/}

          <Box align="center" p={0}>
            <CloudUploadIcon className="material-icons" />

            <div className="fileWordings">
              <p>{`DRAG AND DROP YOUR ${
                folderUpload ? "FOLDER" : "FILE(S)"
              } HERE`}</p>

              <p>OR</p>

              <div className="btn">
                {" "}
                {`Select ${folderUpload ? "Folder" : "File"}`}{" "}
              </div>
              <br />
              {!folderUpload && (
                <span>Supported Formats (.doc, .docx, .pdf)</span>
              )}
              <br />
              <span className="errorColor">
                {" "}
                File processing limit : {uploadLimit} files at a time
              </span>
              <br />
            </div>
          </Box>
          {/*------------------------------------------------------------*/}
        </Card>
        {console.log(
          this.props.fileLen,
          isFileLimitReached,
          fileLength,
          "############################123333"
        )}
        <Dialog
          open={this.props.dopen}
          onClose={this.props.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className="resumepopup"
        >
          <DialogTitle id="alert-dialog-title">Information</DialogTitle>
          <DialogContent>
            {!isAllFileTypeMatch && (
              <DialogContentText id="alert-dialog-description">
                {/* Files in unsupported formats are not processed */}
                You can upload only {uploadLimit} files at a time.
              </DialogContentText>
            )}
            {/* {fileLen >= 20 && (
              <DialogContentText id="alert-dialog-description">
                You can upload only 20 files at a time.
              </DialogContentText>
            )} */}
            {fileLen <= 1 && isAllFileTypeMatch && (
              <DialogContentText id="alert-dialog-description">
                You can upload min 2 files.
              </DialogContentText>
            )}
            {this.props.fileLen >= uploadLimit && fileLength ? (
              <DialogContentText id="alert-dialog-description">
                You can upload only {uploadLimit} files at a time.
              </DialogContentText>
            ) : (
              fileLen >= 2 &&
              fileLen <= uploadLimit &&
              isAllFileTypeMatch && (
                <DialogContentText id="alert-dialog-description">
                  Please select a title (max 1) and/or skills (max 9)
                </DialogContentText>
              )
            )}
          </DialogContent>
          <DialogActions>
            <Button variant="contained" onClick={this.props.handleClose}>
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

export default CompareFileUpload;
