import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import { FlexView } from "../StyledComponents";

export default function ConfirmDialog({
    dialogOpen,
    handleClose,
    handleButton1Click,
    handleButton2Click,
    message,
    button1Name,
    button2Name,
}) {
    return (
        <Dialog
            open={dialogOpen}
            onClose={handleClose}
            maxWidth={'sm'}
        >
            <DialogTitle>
                {message}
            </DialogTitle>
            <DialogActions style={{ justifyContent: 'center' }}>
                <Button onClick={handleButton1Click} color={"primary"} variant={'contained'}>
                    <b>{button1Name}</b>
                </Button>
                <Button onClick={handleButton2Click} color={"primary"} variant={'contained'}>
                    <b>{button2Name}</b>
                </Button>
            </DialogActions>
        </Dialog>
    );
}
