import { Container, Grid, Typography } from "@material-ui/core";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { getJobDesAndWeightage } from "../../api/api";
import ExtractSkillDisplayTable from "../ExtractSkills/ExtractSkillDisplayTable";

function JobDesWeightage() {
  const { jobCode } = useParams();

  const [jobDescription, setJobDescription] = useState("");
  const [weightage, setWeightage] = useState([]);
  const [prevExpValue, setPrevExpValue] = useState(0);
  const [compExpValue, setCompExpValue] = useState(0);
  const [id, setId] = useState("");

  useEffect(() => {
    axios
      .get(getJobDesAndWeightage(jobCode))
      .then((response) => {
        console.log(response, "******");
        if (response.status === 200) {
          setJobDescription(response.data.content);
          setWeightage(response.data.response);
          setPrevExpValue(response.data.msp_experience_weightage);
          setCompExpValue(response.data.preferred_company_weightage);
          setId(response.data.id);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <Container maxWidth={"md"} className={"mt-8 mb-4"}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography>
            <b>Ranking Tuner</b>
          </Typography>
          {weightage.length > 0 ? (
            <ExtractSkillDisplayTable
              skills={{ results: weightage, id: id }}
              prevExpValue={prevExpValue}
              compExpValue={compExpValue}
            />
          ) : (
            <></>
          )}
        </Grid>
        <Grid item xs={12}>
          <Typography>
            <b>Job Description</b>
          </Typography>
          <div dangerouslySetInnerHTML={{ __html: jobDescription }}></div>
        </Grid>
      </Grid>
    </Container>
  );
}

export default JobDesWeightage;
