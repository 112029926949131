import { Box, Container, Grid, TextField, Typography } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import axios from "axios";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import * as yup from "yup";
import { resetPassword, resetPasswordTokenVerification } from "../../api/api";
import Loader from "../../commonComponents/Loader/Loader";
import LoadingButton from "../../commonComponents/LoadingButton";
import CustomSnack from "../../commonComponents/Snack/CustomSnack";
import { MESSAGES } from "../../utils/constants/constants";

const RestPassword = () => {
  const { id, token } = useParams();

  const [snack, setSnack] = useState({
    open: false,
    color: "",
    message: "",
  });
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState("");
  const [restLoading, setRestLoading] = useState(false);
  const [severity, setSeverity] = useState("");

  const validationSchema = yup.object().shape({
    password: yup.string().nullable().required("New Password is required"),
  });

  const handleSnackOpen = (message = MESSAGES.error, color = "error") => {
    setSnack({
      open: true,
      color: color,
      message: message || MESSAGES.error,
    });
  };

  const handleSnackClose = () => {
    setSnack({
      open: false,
      color: "",
      message: "",
    });
  };

  const onSubmit = async (values) => {
    try {
      setRestLoading(true);
      let payload = {
        password: values.password,
        uidb64: id,
        token: token,
      };
      const response = await axios.patch(resetPassword(), payload);
      if (response.status === 200) {
        formik.resetForm();
        setMessage(MESSAGES.resetPasswordSuccessMessage);
        setSeverity("success");
      } else {
        handleSnackOpen();
      }
      setRestLoading(false);
    } catch (err) {
      setRestLoading(false);
      handleSnackOpen();
    }
  };

  const formik = useFormik({
    initialValues: {
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: onSubmit,
  });

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    formik;

  const checkTokenVerification = async () => {
    try {
      const response = await axios.get(
        resetPasswordTokenVerification(id, token)
      );
      if (response.status !== 200) {
        setMessage(MESSAGES.resetPasswordExpireMessage);
        setSeverity("error");
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setMessage(MESSAGES.resetPasswordExpireMessage);
      setSeverity("error");
    }
  };

  useEffect(() => {
    checkTokenVerification();
  }, []);

  return (
    <Container maxWidth={"sm"} className={"mb-4 mt-14"}>
      {loading ? (
        <Loader />
      ) : message ? (
        <Box pt={2}>
          <Alert severity={severity}>
            <p className={"text-base font-bold"}>{message}</p>
          </Alert>
        </Box>
      ) : (
        <Box className={"card"} p={4} pt={4}>
          <Typography variant={"h6"} align={"center"}>
            <b>{"Reset Password"}</b>
          </Typography>
          <Box pt={4}>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <TextField
                    type={"password"}
                    name={"password"}
                    label={"New Password"}
                    variant={"outlined"}
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.password && Boolean(errors.password)}
                    helperText={touched.password && errors.password}
                    size={"small"}
                    required
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <LoadingButton
                    type={"submit"}
                    variant={"contained"}
                    color={"primary"}
                    loading={restLoading}
                    fullWidth
                  >
                    {"Reset Password"}
                  </LoadingButton>
                </Grid>
              </Grid>
            </form>
          </Box>
        </Box>
      )}
      <CustomSnack
        open={snack.open}
        color={snack.color}
        message={snack.message}
        onClose={handleSnackClose}
      />
    </Container>
  );
};

export default RestPassword;
