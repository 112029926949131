import { Grid, Typography } from "@material-ui/core";
import React from "react";

function Report() {
  return (
    <Grid container spacing={2}>
        <Grid item xs={12}>
            <Typography><b>Report</b></Typography>
        </Grid>
      <Grid item xs={3}>
        <Typography>
          <b>Consultant ID:</b>
        </Typography>
      </Grid>
      <Grid item xs={9}>
        <Typography>09s0a9s-s98a9s8as89s-sas89a</Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography>
          <b>Name:</b>
        </Typography>
      </Grid>
      <Grid item xs={9}>
        <Typography>Selva</Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography>
          <b>Phone:</b>
        </Typography>
      </Grid>
      <Grid item xs={9}>
        <Typography>9150596306</Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography>
          <b>Sign In Date:</b>
        </Typography>
      </Grid>
      <Grid item xs={9}>
        <Typography>15/4/2023</Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography>
          <b>Location / Country / State / City:</b>
        </Typography>
      </Grid>
      <Grid item xs={9}>
        <Typography>Tirunelveli</Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography>
          <b>Title / Job Role:</b>
        </Typography>
      </Grid>
      <Grid item xs={9}>
        <Typography>SDE II</Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography>
          <b>Client:</b>
        </Typography>
      </Grid>
      <Grid item xs={9}>
        <Typography>Careerlabs</Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography>
          <b>Evaluated Date:</b>
        </Typography>
      </Grid>
      <Grid item xs={9}>
        <Typography>21/7/2000</Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography>
          <b>Evaluated By:</b>
        </Typography>
      </Grid>
      <Grid item xs={9}>
        <Typography>Selva</Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography>
          <b>Section Status:</b>
        </Typography>
      </Grid>
      <Grid item xs={9}>
        <Typography>COMPLETED</Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography>
          <b>Comment / Notes Section:</b>
        </Typography>
      </Grid>
      <Grid item xs={9}>
        <Typography>Some Notes</Typography>
      </Grid>
    </Grid>
  );
}

export default Report;
