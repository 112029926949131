import { Typography } from "@material-ui/core";
import React from "react";
import tick from "../../images/svgs/tick.svg";
import "./style.css";

function ModuleCard({
  moduleNumber,
  moduleTitle,
  handleModuleClick,
  selectedCard = false,
  count = 0,
  isModuleCount = true,
}) {
  return (
    <div
      onClick={handleModuleClick}
      className={`${
        selectedCard && "border-2 border-gray-300"
      } flex justify-between items-center rounded-2xl shadow p-5 cursor-pointer`}
    >
      <div className="flex flex-col">
        <Typography color="textSecondary" variant={"caption"}>
          {isModuleCount ? `Module - ${moduleNumber}` : "Module"}
        </Typography>
        <Typography>
          <b>{moduleTitle}</b>
        </Typography>
      </div>
      <Typography color="primary" variant="h5">
        <b>{count}</b>
      </Typography>
      {/* <img className="tickImg" src={tick} /> */}
    </div>
  );
}

export default ModuleCard;
