import React from "react";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { styled } from "@material-ui/core";

const StyledTabs = styled(({ className, ...other }) => {
  return (
    <Tabs
      {...other}
      classes={{
        root: className,
        indicator: "indicator",
      }}
      variant={"fullWidth"}
      centered
    />
  );
})(({ tab_count, indicator_color_code, active_text_color, active_tab }) => ({
  "& .indicator": {
    width: tab_count ? `calc(100% / ${tab_count}) !important` : "auto",
    left: active_tab ? `${(active_tab / tab_count) * 100}% !important` : 0,
    backgroundColor: indicator_color_code && indicator_color_code,
  },
  "& .MuiTab-wrapper": {
    textTransform: "capitalize",
    fontWeight: "bold",
  },
  "& .Mui-selected .MuiTab-wrapper": {
    color: active_text_color & active_text_color,
  },
}));

function SimpleTabs({
  value,
  onChange,
  tabList = [],
  indicatorColor,
  indicatorColorCode,
  activeTextColor,
  textColor,
}) {
  return (
    <AppBar position={"static"} color={"inherit"}>
      <StyledTabs
        value={value}
        onChange={onChange}
        tab_count={tabList.length}
        indicatorColor={indicatorColor}
        indicator_color_code={indicatorColorCode}
        active_text_color={activeTextColor}
        textColor={textColor}
        active_tab={tabList?.findIndex((el) => el.value === value) || 0}
      >
        {tabList.length
          ? tabList.map(({ label, value: tabValue, id }) => (
              <Tab label={label} key={id} value={tabValue} />
            ))
          : null}
      </StyledTabs>
    </AppBar>
  );
}

export default SimpleTabs;
