import { Box } from "@material-ui/core";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Stepper from "@material-ui/core/Stepper";
import React from "react";

export default function StepperComponent({ steps = [], activeStep = 0 }) {
  return (
    <Box className={"cover_sheet_form_stepper"}>
      <Stepper alternativeLabel activeStep={activeStep}>
        {steps?.length
          ? steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))
          : null}
      </Stepper>
    </Box>
  );
}
