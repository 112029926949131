import { createTheme } from "@material-ui/core";

export default function theme(){
    return createTheme({
        palette: {
            primary: {
                main: '#25a9da'
            }
        },
        overrides: {
            MuiButton: {
                containedPrimary: {
                    color: '#fff'
                }
            },
        }
    })
}