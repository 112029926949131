import { CLIENT_INTERVIEW } from "../actionTypes";

export const setClientInterviewState = (values) => ({
  type: CLIENT_INTERVIEW.setState,
  payload: values,
});

export const clearClientInterviewState = () => ({
  type: CLIENT_INTERVIEW.clearState,
});
