import { Menu, MenuItem } from "@material-ui/core";
import React from "react";

const ITEM_HEIGHT = 48;

function CustomMenu({
  id = "custom-menu",
  anchorEl,
  anchorOrigin = {
    horizontal: "left",
    vertical: "bottom",
  },
  onClose = () => {},
  menuOptions = [],
}) {
  return (
    <Menu
      id={id}
      anchorEl={anchorEl}
      anchorOrigin={anchorOrigin}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={onClose}
      getContentAnchorEl={null}
      PaperProps={{
        style: {
          maxHeight: ITEM_HEIGHT * 4.5,
          minWidth: "100px",
          boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
        },
      }}
    >
      {menuOptions && menuOptions.length ? (
        menuOptions.map(({ id, label, onClick }) => (
          <MenuItem key={id} id={id} onClick={onClick}>
            {label}
          </MenuItem>
        ))
      ) : (
        <MenuItem>{"No Options"}</MenuItem>
      )}
    </Menu>
  );
}

export default CustomMenu;
