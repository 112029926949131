import {
  Box,
  Button,
  Chip,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  debounce,
} from "@material-ui/core";
import { FilterList, MoreVert } from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";
import { Autocomplete } from "@material-ui/lab";
import Pagination from "@material-ui/lab/Pagination";
import SearchIcon from "@material-ui/icons/Search";
import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  deleteJobPostingDetail,
  getAllJobClients,
  getAllJobPosting,
  updateJobPostingStatus,
} from "../../api/api";
import DeleteDialog from "../../commonComponents/DeleteDialog/DeleteDialog";
import Loader from "../../commonComponents/Loader/Loader";
import Menu from "../../commonComponents/Menu/Menu";
import CustomSnack from "../../commonComponents/Snack/CustomSnack";
import {
  FlexView,
  StyledTableContainer,
  StyledTableHead,
} from "../../commonComponents/StyledComponents";
import { setFeedbackDashboardState } from "../../redux/actions/feedbackDashboard";
import { JOB_POSTING_STATUS, MESSAGES } from "../../utils/constants/constants";
import { FEEDBACK_LEVELS } from "../../utils/constants/feedbackLevelBasedAccess";
import {
  getOffset,
  getPageUsingRemovingAnElement,
} from "../../utils/constants/helpers";
import {
  ADMINS,
  ROLES,
  ROLE_BASED_PAGES,
  getRoleBasedAccess,
} from "../../utils/constants/roleBasedAccess";
import CloseIcon from '@material-ui/icons/Close';


const Options = [
  {
    id: 1,
    value: "10",
  },
  {
    id: 2,
    value: "20",
  },
  {
    id: 3,
    value: "30",
  },
  {
    id: 4,
    value: "40",
  },
  {
    id: 5,
    value: "50",
  },
  {
    id: 6,
    value: "100",
  },
];

const JOB_POSTING_STATUS_DIALOG_TEXT = {
  Active: " Are you sure to activate the job posting",
  Closed: " Are you sure to close the job posting",
  Hold: "Are you sure to hold the job posting",
};

const JOB_POSTING_STATUS_OPTIONS = [
  "All",
  JOB_POSTING_STATUS.active,
  JOB_POSTING_STATUS.hold,
  JOB_POSTING_STATUS.closed,
];

axios.defaults.xsrfHeaderName = "X-CSRFToken";
axios.defaults.xsrfCookieName = "csrftoken";

function FeedbackDashboard() {
  const history = useHistory();
  const role = localStorage.getItem("role");
  const isAdmin = ADMINS.includes(role);
  const {
    isAllowDelete = true,
    isAllowFilter = true,
    isAllowJobPostingStatus = true,
  } = getRoleBasedAccess(role, ROLE_BASED_PAGES.jobPostingLanding);

  const [totalCount, setTotalCount] = useState(0);
  const [jobPostingList, setJobPostingList] = useState([]);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [jobPostingStatusElement, setJobPostingStatusElement] = useState({
    modelOpen: false,
    id: null,
    status: null,
  });
  const [reduceMenuOptionStatus, setReduceMenuOptionStatus] = useState(null);
  const [filterDialogOpen, setFilterDialogOpen] = useState(false);
  const [clientNameFilter, setClientNameFilter] = useState(null);
  const [client, setClient] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [jobPostingStatus, setJobPostingStatus] = useState("Active");

  const [snack, setSnack] = useState({
    open: false,
    color: "",
    message: "",
  });

  const { searchJobPosting, pagination, filters } = useSelector(
    (state) => state.feedbackDashboard
  );
  const dispatch = useDispatch();

  const handleSnackClose = () => {
    setSnack({
      open: false,
      color: "",
      message: "",
    });
  };

  const handleSnackOpen = (message = MESSAGES.error, color = "error") => {
    setSnack({
      open: true,
      color: color,
      message: message || MESSAGES.error,
    });
  };

  const getAndSetClient = (
  ) => {
    setLoading(true);
    axios
      .get(getAllJobClients())
      .then((response) => {
        if (response.status === 200) {
          setClient(response.data);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  // Function to handle opening and closing of filter dialog
  const handleFilterDialogOpen = () => {
    setFilterDialogOpen(true);
  };

  const handleDeleteFilter = (index) => {
    const updatedFilters = [...selectedFilters];
    const removedFilter = updatedFilters.splice(index, 1)[0]; // Remove the filter at the specified index
    if (removedFilter.label === "Job Status")
      updatedFilters.push({
        label: "Job Status",
        value: "Active"
      })
    setSelectedFilters(updatedFilters);
    let filter = {
      ...filters
    }

    // Update the API call by removing the undone filter
    if (removedFilter.label === 'Client Name') {
      setClientNameFilter(null); // Clear client name filter
      filter.client_name = null;
    } else {
      filter.status = "Active"
      setJobPostingStatus("Active");
    }
    dispatch(
      setFeedbackDashboardState({
        filters: filter,
        pagination: { ...pagination, activePage: 1 },
      })
    );
    getAndSetJobPostingList(pagination.items, 1, searchJobPosting, filter);
  };

  const handleFilterDialogClose = () => {

    setFilterDialogOpen(false);
    if (!filters.client_name)
      setClientNameFilter(null);

  };

  const clearFilters = () => {
    let filter = {
      status: "Active",
      clinet_name: null,
    };
    setClientNameFilter(null); // Clear client name filter
    setJobPostingStatus("Active");
    setSelectedFilters([{
      label: "Job Status",
      value: "Active"
    }]);
    dispatch(
      setFeedbackDashboardState({
        filters: filter,
        pagination: { ...pagination, activePage: 1 },
      })
    );
    if (filters.client_name || filters.status !== "Active")
      getAndSetJobPostingList(pagination.items, 1, searchJobPosting, filter);
  };

  // Function to apply filters
  const applyFilters = () => {

    let filter = {
      ...filters
    }
    const filtersArr = [];
    if (clientNameFilter) {
      // Add client name filter to selectedFilters
      filtersArr.push({ label: 'Client Name', value: clientNameFilter });
      filter.client_name = clientNameFilter
    }

    if (jobPostingStatus) {
      // Add client name filter to selectedFilters
      filtersArr.push({ label: 'Job Status', value: jobPostingStatus });
      filter.status = jobPostingStatus
    }
    setSelectedFilters(filtersArr);

    dispatch(
      setFeedbackDashboardState({
        filters: filter,
        pagination: { ...pagination, activePage: 1 },
      })
    );

    // Call getAndSetJobPostingList with filter values
    // Remember to implement this function with your logic
    getAndSetJobPostingList(pagination.items, 1, searchJobPosting, filter);

    // Close the filter dialog
    setFilterDialogOpen(false);
  };

  const getAndSetJobPostingList = (
    limit,
    page,
    search = searchJobPosting,
    filter = filters
  ) => {
    setLoading(true);
    let job_posting_status = filter?.status === "All" ? "" : filter?.status ?? "";
    axios
      .get(
        getAllJobPosting(),
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("tal_token"),
          },
          params: {
            limit: limit,
            offset: getOffset(page, limit),
            search: search,
            jobposting_status: job_posting_status,
            client_name: filter?.client_name,
            role: isAdmin ? ROLES.admin : role
          }
        }
      )
      .then((response) => {
        setJobPostingList(response.data.results);
        setTotalCount(response.data.count);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        handleSnackOpen();
      });
  };

  const handleSearchChange = (value) => {
    getAndSetJobPostingList(pagination.items, 1, value);
    dispatch(
      setFeedbackDashboardState({
        searchJobPosting: value,
        pagination: { ...pagination, activePage: 1 },
      })
    );
  };

  const onSelectPage = (e, value) => {
    getAndSetJobPostingList(pagination.items, value);

    dispatch(
      setFeedbackDashboardState({
        pagination: { ...pagination, activePage: value },
      })
    );
  };

  const handlePageLimitChange = (e) => {
    const { value } = e.target;
    getAndSetJobPostingList(parseInt(value), pagination.activePage);
    dispatch(
      setFeedbackDashboardState({
        pagination: { ...pagination, items: parseInt(value) },
      })
    );
  };

  const optimizedFn = useCallback(debounce(handleSearchChange), [pagination]);

  const handleDeleteClick = (e, id) => {
    e.stopPropagation();
    setDeleteId(id);
    setDeleteDialogOpen(true);
  };

  const handleYesClick = () => {
    axios
      .delete(deleteJobPostingDetail(deleteId))
      .then((response) => {
        setDeleteDialogOpen(false);
        setDeleteId(null);
        if (response.status === 204) {
          setSnack({
            open: true,
            color: "success",
            message: "Deleted Successfully",
          });
          setTimeout(() => {
            let page = getPageUsingRemovingAnElement(
              totalCount,
              pagination.activePage,
              pagination.items
            );
            getAndSetJobPostingList(pagination.items, page);
            dispatch(
              setFeedbackDashboardState({
                pagination: { ...pagination, activePage: page },
              })
            );
          }, 2500);
        } else {
          handleSnackOpen();
        }
      })
      .catch((err) => {
        setDeleteDialogOpen(false);
        setDeleteId(null);
        handleSnackOpen();
      });
  };

  const handleCountClick = (jobCode, level, position_title) => {
    sessionStorage.setItem("jobTitle", position_title);
    sessionStorage.setItem("feedbackLevel", level);

    switch (level) {
      case FEEDBACK_LEVELS.funnel:
        history.push(`/dashboard/${jobCode}/applicants`);
        break;
      case FEEDBACK_LEVELS.qualified:
        history.push(`/dashboard/${jobCode}/qualify`);
        break;
      case FEEDBACK_LEVELS.rejected:
        history.push(`/dashboard/${jobCode}/applicants`);
        break;
      case FEEDBACK_LEVELS.movedToTest:
      case FEEDBACK_LEVELS.testPending:
      case FEEDBACK_LEVELS.testCompleted:
        history.push(`/dashboard/${jobCode}/testModule`);
        break;
      case FEEDBACK_LEVELS.recommend:
        history.push(`/dashboard/${jobCode}/recommended`);
        break;
      case FEEDBACK_LEVELS.clientInterview:
        history.push(`/dashboard/${jobCode}/clientInterview`);
        break;
      default:
        history.push(`/dashboard/${jobCode}/applicants`);
    }
  };

  const handleJobTitleClick = (jobCode) => {
    history.push(`/home/jobDesWeightage/${jobCode}`);
  };

  const applyInitialFilters = () => {
    const filtersArr = [];
    if (filters?.client_name) {
      // Add client name filter to selectedFilters
      filtersArr.push({ label: 'Client Name', value: filters.client_name });
      setClientNameFilter(filters.client_name)
    }
    filtersArr.push({ label: 'Job Status', value: filters?.status ?? "All" });
    setJobPostingStatus(filters?.status ?? "All");
    setSelectedFilters(filtersArr);
  }

  useEffect(() => {
    getAndSetJobPostingList(pagination.items, pagination.activePage);
    getAndSetClient();
    applyInitialFilters();
  }, []);

  const handleDeleteDialogClose = () => {
    setDeleteId(null);
    setDeleteDialogOpen(false);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMoreOptionClick = (e, id, status) => {
    setAnchorEl(e.currentTarget);
    setJobPostingStatusElement({
      ...jobPostingStatusElement,
      id: id,
    });
    setReduceMenuOptionStatus(status);
  };

  const handleMenuItemClick = (e) => {
    setAnchorEl(null);
    setJobPostingStatusElement({
      ...jobPostingStatusElement,
      modelOpen: true,
      status: e.target.id,
    });
  };

  const handleJobPostingStatusDialogClose = () => {
    setJobPostingStatusElement({
      ...jobPostingStatusElement,
      modelOpen: false,
    });
    setReduceMenuOptionStatus(null);
    setTimeout(() => {
      setJobPostingStatusElement({
        ...jobPostingStatusElement,
        modelOpen: false,
        id: null,
        status: null,
      });
    }, 100);
  };

  const handleJobPostingStatusUpdate = () => {
    let payload = {
      jobposting_status: jobPostingStatusElement.status,
    };
    axios
      .patch(updateJobPostingStatus(jobPostingStatusElement.id), payload)
      .then((response) => {
        handleJobPostingStatusDialogClose();
        if (response.status === 200) {
          setSnack({
            open: true,
            color: "success",
            message: "Status Updated Successfully",
          });
          setTimeout(() => {
            getAndSetJobPostingList(pagination.items, pagination.activePage);
          }, 2500);
        } else {
          handleSnackOpen();
        }
      })
      .catch((err) => {
        handleJobPostingStatusDialogClose();
        handleSnackOpen();
      });
  };

  const handleJobPostingStatus = (_, value) => {
    setJobPostingStatus(value);
  };

  const menuOptions = [
    {
      id: JOB_POSTING_STATUS.active,
      label: JOB_POSTING_STATUS.active,
      onClick: handleMenuItemClick,
    },
    {
      id: JOB_POSTING_STATUS.hold,
      label: JOB_POSTING_STATUS.hold,
      onClick: handleMenuItemClick,
    },
    {
      id: JOB_POSTING_STATUS.closed,
      label: JOB_POSTING_STATUS.closed,
      onClick: handleMenuItemClick,
    },
  ];

  return (
    <Container
      className={"container-style mt-5"}
      style={{ maxWidth: "1440px" }}
    >
      <Grid container spacing={2} alignItems={"center"}>
        <Grid item xs={12}>
          <Typography>
            <b>Job Postings</b>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <FlexView
            justifyContent={"space-between"}
          >

            {selectedFilters.length ? <FlexView gridGap={'10px'}>
              {selectedFilters.map((filter, index) => {
                let props = {};
                if (!(filter.label === 'Job Status' && filter.value === 'Active')) {
                  props.onDelete = () => handleDeleteFilter(index)
                }
                return (
                  <Chip
                    key={index}
                    label={<b>{`${filter.label}: ${filter.value}`}</b>}
                    {
                    ...props
                    }
                  />
                )
              })}
            </FlexView> : null}
            <FlexView justifyContent={'flex-end'} gridGap={'25px'}>
              <TextField
                id={"search"}
                label={"Search..."}
                onChange={(e) => optimizedFn(e.target.value)}
                defaultValue={searchJobPosting}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position={"end"}>
                      <IconButton>
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                size={"small"}
              />
              {isAllowFilter ? (
                <Tooltip title={"Filter"} arrow>
                  <IconButton
                    color="primary"
                    onClick={handleFilterDialogOpen}
                    style={{
                      top: "10px"
                    }}
                  >
                    <FilterList />
                  </IconButton>
                </Tooltip>
              ) : null}
            </FlexView>
          </FlexView>
        </Grid>
        <Grid item xs={12}>
          <StyledTableContainer component={Paper} variant={"outlined"}>
            <Table size={"small"}>
              <StyledTableHead>
                <TableRow>
                  <TableCell>Job Posting Title</TableCell>
                  <TableCell>Job Code</TableCell>
                  <TableCell>Client Name</TableCell>
                  <TableCell style={{ maxWidth: "110px" }}>
                    {"Funnel"}
                  </TableCell>
                  <TableCell style={{ maxWidth: "110px" }}>
                    {"Qualified"}
                  </TableCell>
                  <TableCell style={{ maxWidth: "110px" }}>
                    {"Rejected"}
                  </TableCell>
                  <TableCell style={{ maxWidth: "110px" }}>
                    {"Moved To Test"}
                  </TableCell>
                  <TableCell style={{ maxWidth: "110px" }}>
                    {"Test Pending"}
                  </TableCell>
                  <TableCell style={{ maxWidth: "110px" }}>
                    {"Test Completed"}
                  </TableCell>
                  <TableCell style={{ maxWidth: "110px" }}>
                    {"Recommend"}
                  </TableCell>
                  <TableCell style={{ maxWidth: "110px" }}>
                    {"Client Interview"}
                  </TableCell>
                  {isAllowDelete ? <TableCell>{"Action"}</TableCell> : null}
                  {isAllowJobPostingStatus ? (
                    <TableCell style={{ minWidth: "108px" }}>
                      {"Job Posting Status"}
                    </TableCell>
                  ) : null}
                </TableRow>
              </StyledTableHead>
              <TableBody>
                {!loading && jobPostingList?.length ? (
                  jobPostingList.map(
                    (
                      {
                        id,
                        position_title,
                        job_code,
                        client_name,
                        funnel,
                        rejected,
                        moved_to_test,
                        test_pending,
                        test_completed,
                        recommend,
                        client_interview,
                        qualified,
                        jobposting_status,
                      },
                      index
                    ) => {
                      const jobPostingStatus =
                        jobposting_status ?? JOB_POSTING_STATUS.active;
                      return (
                        <TableRow key={`feedback-table-row-${index}`}>
                          <TableCell
                          // className={"cursor-pointer"}
                          // onClick={() => handleJobTitleClick(job_code)}
                          >
                            {/* <b className={"link-text"}>{position_title}</b> */}
                            <b>{position_title}</b>
                          </TableCell>
                          <TableCell>{job_code}</TableCell>
                          <TableCell>{client_name}</TableCell>
                          <TableCell
                            onClick={() =>
                              handleCountClick(
                                job_code,
                                FEEDBACK_LEVELS.funnel,
                                position_title
                              )
                            }
                            className={"cursor-pointer"}
                          >
                            <p className={"link-text"}>{funnel}</p>
                          </TableCell>
                          <TableCell
                            onClick={() =>
                              handleCountClick(
                                job_code,
                                FEEDBACK_LEVELS.qualified,
                                position_title
                              )
                            }
                            className={"cursor-pointer"}
                          >
                            <p className={"link-text"}>{qualified}</p>
                          </TableCell>
                          <TableCell
                            onClick={() =>
                              handleCountClick(
                                job_code,
                                FEEDBACK_LEVELS.rejected,
                                position_title
                              )
                            }
                            className={"cursor-pointer"}
                          >
                            <p className={"link-text"}>{rejected}</p>
                          </TableCell>
                          <TableCell
                            onClick={() =>
                              handleCountClick(
                                job_code,
                                FEEDBACK_LEVELS.movedToTest,
                                position_title
                              )
                            }
                            className={"cursor-pointer"}
                          >
                            <p className={"link-text"}>{moved_to_test}</p>
                          </TableCell>
                          <TableCell
                            onClick={() =>
                              handleCountClick(
                                job_code,
                                FEEDBACK_LEVELS.testPending,
                                position_title
                              )
                            }
                            className={"cursor-pointer"}
                          >
                            <p className={"link-text"}>{test_pending}</p>
                          </TableCell>
                          <TableCell
                            onClick={() =>
                              handleCountClick(
                                job_code,
                                FEEDBACK_LEVELS.testCompleted,
                                position_title
                              )
                            }
                            className={"cursor-pointer"}
                          >
                            <p className={"link-text"}>{test_completed}</p>
                          </TableCell>
                          <TableCell
                            onClick={() =>
                              handleCountClick(
                                job_code,
                                FEEDBACK_LEVELS.recommend,
                                position_title
                              )
                            }
                            className={"cursor-pointer"}
                          >
                            <p className={"link-text"}>{recommend}</p>
                          </TableCell>
                          <TableCell
                            onClick={() =>
                              handleCountClick(
                                job_code,
                                FEEDBACK_LEVELS.clientInterview,
                                position_title
                              )
                            }
                            className={"cursor-pointer"}
                          >
                            <p className={"link-text"}>{client_interview}</p>
                          </TableCell>
                          {isAllowDelete ? (
                            <TableCell>
                              <IconButton
                                onClick={(e) => handleDeleteClick(e, id)}
                                color={"secondary"}
                                disabled={!isAllowDelete}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </TableCell>
                          ) : null}
                          {isAllowJobPostingStatus ? (
                            <TableCell>
                              <Grid
                                container
                                spacing={2}
                                alignItems={"center"}
                                justifyContent={"space-between"}
                                wrap={"nowrap"}
                                style={{ whiteSpace: "nowrap" }}
                              >
                                <Grid item>
                                  <b>{jobPostingStatus}</b>
                                </Grid>
                                <Grid item>
                                  <IconButton
                                    aria-label="more"
                                    onClick={(e) =>
                                      handleMoreOptionClick(
                                        e,
                                        id,
                                        jobPostingStatus
                                      )
                                    }
                                    color={"primary"}
                                  >
                                    <MoreVert />
                                  </IconButton>
                                </Grid>
                              </Grid>
                            </TableCell>
                          ) : null}
                        </TableRow>
                      );
                    }
                  )
                ) : (
                  <TableRow>
                    <TableCell colSpan={12} align={"center"}>
                      {loading ? (
                        <Loader />
                      ) : (
                        <Typography>
                          <b>No results found</b>
                        </Typography>
                      )}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </StyledTableContainer>
        </Grid>
        <Grid
          item
          xs={12}
          container
          justifyContent="flex-end"
          alignItems="center"
        >
          <Typography>
            Total Count: <b>{totalCount}</b>
          </Typography>
        </Grid>
        <Grid item xs={2} container alignItems="center">
          <FormControl style={{ width: 120 }}>
            <InputLabel id="demo-simple-select-label">Rows per page</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={pagination.items}
              onChange={handlePageLimitChange}
            >
              {Options.map(({ id, value }) => (
                <MenuItem key={id} value={value}>
                  {value}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid
          item
          xs={10}
          container
          alignItems="center"
          justifyContent="flex-end"
        >
          <Pagination
            color={"primary"}
            page={pagination.activePage}
            onChange={onSelectPage}
            count={Math.ceil(totalCount / pagination.items)}
            showFirstButton
            showLastButton
          />
        </Grid>
      </Grid>
      <Menu
        anchorEl={anchorEl}
        menuOptions={menuOptions.filter(
          (el) => el.id !== reduceMenuOptionStatus
        )}
        onClose={handleMenuClose}
      />
      <DeleteDialog
        dialogOpen={deleteDialogOpen}
        handleClose={handleDeleteDialogClose}
        handleYesClick={handleYesClick}
        message={`Are you sure you want to delete?`}
      />
      <DeleteDialog
        dialogOpen={jobPostingStatusElement.modelOpen}
        handleClose={handleJobPostingStatusDialogClose}
        handleYesClick={handleJobPostingStatusUpdate}
        message={
          jobPostingStatusElement.status
            ? `${JOB_POSTING_STATUS_DIALOG_TEXT[jobPostingStatusElement.status]
            }(${jobPostingStatusElement.id})?`
            : null
        }
      />
      <CustomSnack
        open={snack.open}
        color={snack.color}
        message={snack.message}
        onClose={handleSnackClose}
      />
      {/* Filter Dialog */}
      <Dialog
        open={filterDialogOpen}
        onClose={handleFilterDialogClose}
        aria-labelledby="filter-dialog-title"
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle id="filter-dialog-title">
          Filter Options
          <IconButton aria-label="close" onClick={handleFilterDialogClose} style={{ position: 'absolute', right: '8px', top: '8px' }}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {/* Client Name Filter */}
          <Autocomplete
            id="clientNameFilter"
            options={client}
            getOptionLabel={(option) => option}
            value={clientNameFilter || ''}
            onChange={(event, newValue) => setClientNameFilter(newValue)}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Client Name"
                size="small"
                fullWidth
                margin="normal"
              />
            )}
          />

          {/* Job Status Filter */}
          <Autocomplete
            id="jobPostingStatus"
            options={JOB_POSTING_STATUS_OPTIONS}
            getOptionLabel={(option) => option}
            value={jobPostingStatus || ''}
            onChange={handleJobPostingStatus}
            disableClearable
            renderInput={(params) => (
              <TextField
                {...params}
                label="Job Status"
                size="small"
                fullWidth
                margin="normal"
              />
            )}
          />
        </DialogContent>
        <DialogActions>
          {/* Clear button */}
          <Button onClick={clearFilters} color="secondary">
            Clear
          </Button>
          {/* Apply Filters button */}
          <Button onClick={applyFilters} color="primary">
            Apply Filters
          </Button>
        </DialogActions>
      </Dialog>
    </Container >
  );
}

export default FeedbackDashboard;
