import { Button, FormHelperText, Grid, Typography } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import axios from "axios";
import { Form, FormikProvider, useFormik } from "formik";
import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useParams } from "react-router-dom";
import * as yup from "yup";
import {
  getInterviewPhoneQuestionAnswer,
  postInterviewPhoneQuestionAnswer,
} from "../../../api/api";
import { MESSAGES } from "../../../utils/constants/constants";

function QuestionContainer({ question, children }) {
  return (
    <>
      <Grid item xs={12}>
        <Typography>
          <b>{question}</b>
        </Typography>
      </Grid>
      <Grid item xs={12}>
        {children}
      </Grid>
    </>
  );
}

const initialValues = {
  timeSlotOne: "",
  timeSlotTwo: "",
};

function Phone({
  isSubmitted,
  header_config,
  isClient,
  isAccessibleActionButton = true,
  ...props
}) {
  const { jobCode, applicantId } = useParams();

  const [alertBox, setAlertBox] = useState({
    open: false,
    success: false,
    message: "",
  });

  const requiredMessage = "Please fill the required field";
  const futureDateMessage = "Please enter valid date";

  const validationSchema = yup.object({
    timeSlotOne: yup
      .date()
      .min(new Date(), futureDateMessage)
      .required(requiredMessage)
      .nullable(),
    timeSlotTwo: yup
      .date()
      .min(new Date(), futureDateMessage)
      .required(requiredMessage)
      .nullable(),
  });

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: handleSubmit,
  });

  function handleSubmit(values) {
    let quesAnsArr = [];

    for (const key in values) {
      if (key === "timeSlotOne" || key === "timeSlotTwo") {
        quesAnsArr.push({ [key]: new Date(values[key]).toISOString() });
      }
    }

    let requestBody = {
      applicant_id: applicantId,
      job_code: jobCode,
      answers: quesAnsArr,
    };

    if (isClient) {
      requestBody.status = "submitted";
    }

    axios
      .post(postInterviewPhoneQuestionAnswer(), requestBody, header_config)
      .then((response) => {
        if (response.status === 200) {
          setAlertBox({
            open: true,
            success: true,
            message: MESSAGES.interviewScheduleViaPhoneOrFormLink,
          });

          // Show Thank You message on ChatForm after user successfully submitted.
          if (props.hasOwnProperty("showThankYou")) {
            props.showThankYou();
          }
        }
      })
      .catch((err) => {
        setAlertBox({
          open: true,
          success: false,
          message: "Something Went Wrong",
        });
      })
      .finally(() => {
        setTimeout(() => {
          setAlertBox({
            open: false,
            success: false,
            message: "",
          });
        }, 2000);
      });
  }

  const getAndSetPhoneQuestionAnswer = () => {
    axios
      .get(getInterviewPhoneQuestionAnswer(applicantId, jobCode), header_config)
      .then((response) => {
        if (response.status === 200) {
          let formattedObj = {};
          response.data.map((el, ind) => {
            if (el.title === "timeSlotOne" || el.title === "timeSlotTwo") {
              if (el.answer) {
                const formattedDate = new Date(el.answer);
                formattedObj[el.title] = formattedDate;
              } else {
                formattedObj[el.title] = el.answer;
              }
            } else {
              formattedObj[el.title] = el.answer;
            }
          });
          setValues({ ...initialValues, ...formattedObj });
        }
      });
  };

  useEffect(() => {
    getAndSetPhoneQuestionAnswer();
  }, []);

  const {
    values,
    handleChange,
    touched,
    errors,
    setFieldValue,
    handleBlur,
    setValues,
    setFieldTouched,
  } = formik;

  return (
    <FormikProvider value={formik}>
      <Form
        style={{ pointerEvents: isClient && isSubmitted ? "none" : "auto" }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <div className="bg-gray-200 p-5 mt-2 rounded-lg">
              <Typography>
                <b>
                  The Interview process for this position requires 1 hour of
                  your valuable time.
                </b>
              </Typography>
            </div>
          </Grid>
          <QuestionContainer
            question={"Please select the time slots suitable for you."}
          >
            <div className="flex items-center gap-10">
              <div className="dateTimeContainer flex flex-col">
                <Typography
                  className={`${
                    touched.timeSlotOne &&
                    Boolean(errors.timeSlotOne) &&
                    "text-red-500"
                  }`}
                  variant="caption"
                >
                  Time Slot One
                </Typography>
                <DatePicker
                  className={`outline-none border-b-2 ${
                    touched.timeSlotOne && Boolean(errors.timeSlotOne)
                      ? "border-red-500"
                      : "border-gray-300"
                  } `}
                  selected={values.timeSlotOne}
                  onChange={(date) => {
                    setFieldTouched("timeSlotOne", true);
                    setFieldValue("timeSlotOne", date);
                  }}
                  minDate={new Date()}
                  showTimeSelect
                  name={"timeSlotOne"}
                  customInput={<input name="timeSlotOne" />}
                  dateFormat="MMMM d, yyyy h:mm aa"
                  autoComplete={"off"}
                />
                <FormHelperText className="errorText">
                  {touched.timeSlotOne && errors.timeSlotOne}
                </FormHelperText>
              </div>
              <div className="dateTimeContainer flex flex-col">
                <Typography
                  className={`${
                    touched.timeSlotTwo &&
                    Boolean(errors.timeSlotTwo) &&
                    "text-red-500"
                  }`}
                  variant="caption"
                >
                  Time Slot Two
                </Typography>
                <DatePicker
                  className={`outline-none border-b-2 ${
                    touched.timeSlotTwo && Boolean(errors.timeSlotTwo)
                      ? "border-red-500"
                      : "border-gray-300"
                  } `}
                  name={"timeSlotTwo"}
                  minDate={
                    values.timeSlotOne
                      ? new Date(
                          new Date(values.timeSlotOne).setDate(
                            new Date(values.timeSlotOne).getDate() + 1
                          )
                        )
                      : new Date()
                  }
                  customInput={<input name="timeSlotTwo" />}
                  selected={values.timeSlotTwo}
                  onChange={(date) => {
                    setFieldTouched("timeSlotTwo", true);
                    setFieldValue("timeSlotTwo", date);
                  }}
                  showTimeSelect
                  dateFormat="MMMM d, yyyy h:mm aa"
                  autoComplete={"off"}
                />
                <FormHelperText className="errorText">
                  {touched.timeSlotTwo && errors.timeSlotTwo}
                </FormHelperText>
              </div>
            </div>
          </QuestionContainer>
          <Grid
            item
            xs={12}
            container
            alignItems="center"
            justifyContent="flex-end"
          >
            <Button
              variant={"contained"}
              color={"primary"}
              type={"submit"}
              disabled={!isAccessibleActionButton || (isSubmitted && isClient)}
            >
              Submit
            </Button>
          </Grid>
          {alertBox.open && (
            <Grid item xs={12}>
              <Alert severity={`${alertBox.success ? "success" : "error"}`}>
                {alertBox.message}
              </Alert>
            </Grid>
          )}
        </Grid>
      </Form>
    </FormikProvider>
  );
}

export default Phone;
