import {
  Button,
  Checkbox,
  Chip,
  Container,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import { Send } from "@material-ui/icons";
import { Autocomplete, createFilterOptions } from "@material-ui/lab";
import axios from "axios";
import dayjs from "dayjs";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import * as yup from "yup";
import { getAllJob, reportExportOrSendEmail } from "../../api/api";
import CustomDatePickers from "../../commonComponents/DatePicker/CustomDatePicker";
import LoadingButton from "../../commonComponents/LoadingButton";
import SimpleModal from "../../commonComponents/Modal/SimpleModal";
import CustomSnack from "../../commonComponents/Snack/CustomSnack";
import {
  convertTZ,
  formatDate,
  validateEmail,
} from "../../utils/constants/helpers";
import {
  ROLE_BASED_PAGES,
  getRoleBasedAccess,
} from "../../utils/constants/roleBasedAccess";
import { navigateTo } from "../../utils/constants/routePaths";

const filter = createFilterOptions();

const REPORT_TYPE = [
  {
    name: "Submission",
    value: "submission_report",
    emailType: "submission_report_mail",
    reportViewType: "submission_view",
  },
  {
    name: "Test",
    value: "test_export",
    emailType: "test_report_mail",
    reportViewType: "test_view",
  },
  {
    name: "Recommend",
    value: "recommend_export",
    emailType: "recommend_report_mail",
    reportViewType: "recommend_view",
  },
  // {
  //   name: "Rejected",
  //   value: "rejected_export",
  //   emailType: "rejected_report_mail",
  //   reportViewType: "rejected_view",
  // },
  {
    name: "Applicant History",
    value: "applicant_history_export",
    emailType: "applicant_history_mail",
    reportViewType: "applicant_history_view",
  },
];

const TIME_PERIOD = [
  {
    name: "Consolidated",
    value: "Consolidated",
  },
  // {
  //   name: "Month 1",
  //   value: "Month 1",
  // },
  // {
  //   name: "Month 2",
  //   value: "Month 2",
  // },
  // {
  //   name: "Month 3",
  //   value: "Month 3",
  // },
];

const DEFAULT_CLIENT = {
  job_code: "All",
  position_title: "All",
  client_name: "All",
};

const DEFAULT_JOB = {
  job_code: "All",
  position_title: "Select All",
  client_name: "All",
};

function Index() {
  const role = localStorage.getItem("role");
  const { isReportsLandingPageAllow = true } = getRoleBasedAccess(
    role,
    ROLE_BASED_PAGES.reportsLanding
  );
  const history = useHistory();
  const {
    state: {
      selected_job,
      qualified_from,
      qualified_to,
      report_type,
      time_period,
      selected_client
    } = {},
  } = useLocation();

  const [jobList, setJobList] = useState([]);
  const [snack, setSnack] = useState({
    open: false,
    color: "",
    message: "",
  });
  const [sendEmailModal, setSendEmailModal] = useState({
    open: false,
    toEmail: [],
    is_resume: false,
  });
  const [sendEmailLoading, setSendEmailLoading] = useState(false);
  const [exportLoading, setExportLoading] = useState(false);

  const validationSchema = yup.object({
    selectedJob: yup
      .array()
      .nullable()
      .required("Please select the job title").min(1, "Please select the job title"),
    reportType: yup
      .object()
      .nullable()
      .required("Please select the report type"),
    timePeriod: yup
      .object()
      .nullable()
      .required("Please select the time period"),
    fromDate: yup
      .string()
      .nullable()
      .required("Please select the qualified from date"),
    toDate: yup
      .string()
      .nullable()
      .required("Please select the qualified to date"),
  });

  const handleSnackClose = () => {
    setSnack({
      open: false,
      color: "",
      message: "",
    });
  };

  const formik = useFormik({
    initialValues: {
      selectedJob: [],
      reportType: REPORT_TYPE[0],
      fromDate: convertTZ("2023-06-01"),
      toDate: new Date(),
      timePeriod: TIME_PERIOD[0],
      selectedClient: DEFAULT_CLIENT,
    },
    validationSchema: validationSchema,
    onSubmit: () => { },
    validateOnMount: true,
  });

  const {
    values,
    touched,
    errors,
    setValues,
    setErrors,
    isValid,
    handleSubmit,
  } = formik;

  const filteredJobList = values.selectedClient?.client_name !== 'All' ? jobList.filter(job => job.client_name === values.selectedClient?.client_name) : jobList;

  useEffect(() => {
    axios
      .get(getAllJob())
      .then((response) => {
        if (response.status === 200) {
          setJobList(response.data);
        } else {
          setSnack({
            open: true,
            color: "error",
            message: "Something went wrong",
          });
        }
      })
      .catch((err) => {
        setSnack({
          open: true,
          color: "error",
          message: "Something went wrong",
        });
      });

    if (selected_job) {
      setValues({
        ...values,
        selectedJob: selected_job,
        reportType: report_type,
        fromDate: convertTZ(qualified_from),
        toDate: convertTZ(qualified_to),
        timePeriod: time_period,
        selectedClient: selected_client
      });
      history.replace(navigateTo.reports);
    }
  }, []);

  const handleExport = () => {
    if (isValid) {
      setExportLoading(true);
      let formData = new FormData();
      let selectedJob = `[${values.selectedJob.map((el) => `"${el.job_code}"`)}]`;
      formData.append("job_code", selectedJob);
      formData.append("client_name", values.selectedClient.client_name);
      formData.append("report_type", values.reportType.value);
      formData.append(
        "qualified_from",
        formatDate(values.fromDate, "YYYY-MM-DD")
      );
      formData.append("qualified_to", formatDate(values.toDate, "YYYY-MM-DD"));
      formData.append("time_period", values.timePeriod.value);
      console.log(values.selectedJob.client_name)
      axios
        .post(reportExportOrSendEmail(), formData, {
          responseType: "blob",
        })
        .then((response) => {
          const href = URL.createObjectURL(response.data);

          // create "a" HTML element with href to file & click
          const link = document.createElement("a");
          link.href = href;
          link.setAttribute(
            "download",
            `${values.reportType.name}_Report.xlsx`
          );
          document.body.appendChild(link);
          link.click();

          // clean up "a" element & remove ObjectURL
          document.body.removeChild(link);
        })
        .catch((err) => {
          setSnack({
            open: true,
            message: "Something went wrong",
            color: "error",
          });
        })
        .finally(() => {
          setExportLoading(false);
        });
    }
  };

  const handleSendEmail = () => {
    if (isValid) {
      setSendEmailModal({ ...sendEmailModal, open: true });
    }
  };

  // Function to remove duplicates based on client_name
  const uniqueClients = Array.from(new Set(jobList.map(option => option.client_name)))
    .map(client_name => {
      return jobList.find(option => option.client_name === client_name);
    });


  const handleChange = (val, name) => {
    setErrors({
      ...errors,
      [name]: null,
    });
    if (name === 'selectedClient') {
      setValues({
        ...values,
        [name]: val,
        selectedJob: [],
      });
    } else if (name === 'selectedJob') {
      if (val.some(option => option.job_code === "All")) {
        setValues({
          ...values,
          [name]: (val.length - 1) === filteredJobList.length ? [] : filteredJobList,
        });
      }
      else {
        setValues({
          ...values,
          [name]: val,
        });
      }
    } else {
      setValues({
        ...values,
        [name]: val,
      });
    }
  };

  const handleSendEmailModalClose = () => {
    setSendEmailModal({
      open: false,
      toEmail: [],
      is_resume: false,
    });
  };

  const handleSendEmailSubmit = () => {
    if (sendEmailModal.toEmail.length) {
      setSendEmailLoading(true);

      let arr = [...sendEmailModal.toEmail];
      arr = arr.map((i) => `"${i}"`);
      let formData = new FormData();
      let selectedJob = `[${values.selectedJob.map((el) => `"${el.job_code}"`)}]`;
      formData.append("job_code", selectedJob);
      formData.append("client_name", values.selectedClient.client_name);
      formData.append("report_type", values.reportType.emailType);
      formData.append(
        "qualified_from",
        formatDate(values.fromDate, "YYYY-MM-DD")
      );
      formData.append("qualified_to", formatDate(values.toDate, "YYYY-MM-DD"));
      formData.append("to_email", `[${arr}]`);
      formData.append("is_resume", sendEmailModal.is_resume);
      formData.append("time_period", values.timePeriod.value);

      axios
        .post(reportExportOrSendEmail(), formData)
        .then((response) => {
          if (response.status === 201) {
            handleSendEmailModalClose();
            setSnack({
              open: true,
              message: "Email send successfully",
              color: "success",
            });
          } else {
            setSnack({
              open: true,
              message: "Something went wrong",
              color: "error",
            });
          }
        })
        .catch((err) => {
          setSnack({
            open: true,
            message: "Something went wrong",
            color: "error",
          });
        })
        .finally(() => {
          setSendEmailLoading(false);
        });
    } else {
      setSnack({
        open: true,
        message: "Please fill the receiver email",
        color: "error",
      });
    }
  };

  const handleSendEmailChange = (val, name) => {
    if (name === "toEmail") {
      let isNotValid = val.some((el) => !validateEmail(el));
      if (val.length && isNotValid) {
        setSnack({
          open: true,
          message: "Please enter a valid email",
          color: "error",
        });
      } else {
        setSendEmailModal({
          ...sendEmailModal,
          [name]: val,
        });
      }
    } else {
      setSendEmailModal({
        ...sendEmailModal,
        [name]: val,
      });
    }
  };

  const handleViewReport = (e) => {
    if (isValid) {
      e.preventDefault();

      let obj = {
        selected_job: values.selectedJob,
        qualified_from: formatDate(values.fromDate, "YYYY-MM-DD"),
        qualified_to: formatDate(values.toDate, "YYYY-MM-DD"),
        report_type: values.reportType,
        time_period: values.timePeriod,
        selected_client: values.selectedClient
      };

      switch (values.reportType.value) {
        case "test_export":
          history.push({ pathname: navigateTo.viewTestReport, state: obj });
          break;
        case "recommend_export":
          history.push({
            pathname: navigateTo.viewRecommendReport,
            state: obj,
          });
          break;
        case "rejected_export":
          history.push({
            pathname: navigateTo.viewRejectedReport,
            state: obj,
          });
          break;
        case "applicant_history_export":
          history.push({
            pathname: navigateTo.viewApplicationHistoryReport,
            state: obj,
          });
          break;
        default:
          history.push({
            pathname: navigateTo.viewSubmissionReport,
            state: obj,
          });
      }
    }
  };

  return (
    <Container maxWidth={"md"} className={"mt-8"}>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Typography>
              <b>{"Reports"}</b>
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Autocomplete
              id={"client-list"}
              options={[DEFAULT_CLIENT, ...uniqueClients.filter(option => option.client_name && option.client_name.trim() !== '')]}
              value={values.selectedClient}
              getOptionLabel={(option) => option.client_name}
              onChange={(e, val) => handleChange(val, "selectedClient")}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={"Client Name"}
                  variant={"outlined"}
                  size={"medium"}
                  error={touched.selectedClient && Boolean(errors.selectedClient)}
                  helperText={touched.selectedClient && errors.selectedClient}
                  fullWidth
                />
              )}
              disableClearable
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <Autocomplete
              id={"job-list"}
              options={[DEFAULT_JOB, ...filteredJobList]}
              value={values.selectedJob}
              getOptionLabel={(option) => option.position_title}
              onChange={(e, val) => handleChange(val, "selectedJob")}
              renderOption={(option) => {
                let selected = option.job_code === "All" ? !!(values.selectedJob.length === filteredJobList.length) : values.selectedJob.some(el => el.job_code === option.job_code)
                return (
                  <li>
                    <Checkbox
                      checked={selected} />
                    {option.job_code === "All" ? option.position_title : `${option.position_title}(${option.job_code})`}
                  </li>)
              }

              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={"Job Title"}
                  variant={"outlined"}
                  size={"medium"}
                  placeholder="search job title"
                  error={touched.selectedJob && Boolean(errors.selectedJob)}
                  helperText={touched.selectedJob && errors.selectedJob}
                  fullWidth
                />
              )}
              multiple
              fullWidth
              limitTags={3}
              renderTags={(value, getTagProps) => {
                const moreCount = value.length - 3;
                return (
                  <>
                    {value.slice(0, 3).map((option, index) => (
                      <Chip
                        label={option.position_title}
                        {...getTagProps({ index })}
                      />
                    ))}
                    {moreCount > 0 && <Chip label={`+${moreCount}`} />}
                  </>
                );
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <Autocomplete
              id={"report-type"}
              options={REPORT_TYPE}
              value={values.reportType}
              getOptionLabel={(option) => option.name}
              onChange={(e, val) => handleChange(val, "reportType")}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={"Report Type"}
                  variant={"outlined"}
                  size={"medium"}
                  error={touched.reportType && Boolean(errors.reportType)}
                  helperText={touched.reportType && errors.reportType}
                  fullWidth
                />
              )}
              disableClearable
              fullWidth
            />
          </Grid>
          {/* <Grid item xs={6}>
            <Autocomplete
              id={"time-period"}
              options={TIME_PERIOD}
              value={values.timePeriod}
              getOptionLabel={(option) => option.name}
              onChange={(e, val) => handleChange(val, "timePeriod")}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={"Time Period"}
                  variant={"outlined"}
                  size={"medium"}
                  error={touched.timePeriod && Boolean(errors.timePeriod)}
                  helperText={touched.timePeriod && errors.timePeriod}
                  fullWidth
                />
              )}
              disableClearable
              fullWidth
            />
          </Grid> */}
          <Grid item xs={3}>
            <CustomDatePickers
              id={"fromDate"}
              label={"Qualified From"}
              name={"fromDate"}
              error={touched.fromDate && Boolean(errors.fromDate)}
              helperText={touched.fromDate && errors.fromDate}
              value={values.fromDate ? new Date(values.fromDate) : null}
              onChange={(date) => handleChange(date, "fromDate")}
              views={["year", "month", "day"]}
              openTo={"year"}
              format={"DD MMM YYYY"}
              disableFuture
              maxDate={
                values.toDate ? dayjs(new Date(values.toDate)) : undefined
              }
              size={"medium"}
            />
          </Grid>
          <Grid item xs={3}>
            <CustomDatePickers
              id={"toDate"}
              label={"Qualified To"}
              name={"toDate"}
              error={touched.toDate && Boolean(errors.toDate)}
              helperText={touched.toDate && errors.toDate}
              value={values.toDate ? new Date(values.toDate) : null}
              onChange={(date) => handleChange(date, "toDate")}
              views={["year", "month", "day"]}
              openTo={"year"}
              format={"DD MMM YYYY"}
              disableFuture
              minDate={
                values.fromDate ? dayjs(new Date(values.fromDate)) : undefined
              }
              size={"medium"}
            />
          </Grid>
          <Grid item xs={12}>
            <div className={"flex justify-end items-center gap-6"}>
              <Button
                size={"large"}
                variant={"contained"}
                type={"submit"}
                color={"primary"}
                onClick={handleViewReport}
              >
                {"View"}
              </Button>
              <LoadingButton
                size={"large"}
                variant={"contained"}
                type={"submit"}
                color={"primary"}
                onClick={handleExport}
                loading={exportLoading}
              >
                {"Export"}
              </LoadingButton>
              <Button
                size={"large"}
                variant={"contained"}
                type={"submit"}
                color={"primary"}
                onClick={handleSendEmail}
                startIcon={<Send />}
              >
                {"Send Email"}
              </Button>
            </div>
          </Grid>
        </Grid>
      </form>
      <SimpleModal
        title={"Send Email"}
        open={sendEmailModal.open}
        saveButtonText={"Submit"}
        handleClose={handleSendEmailModalClose}
        handleSave={handleSendEmailSubmit}
        maxWidth={"sm"}
        loading={sendEmailLoading}
      >
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Autocomplete
              id={"to-email"}
              options={[]}
              value={sendEmailModal.toEmail}
              getOptionLabel={(option) => option}
              onChange={(e, val) => handleSendEmailChange(val, "toEmail")}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={"To Email"}
                  variant={"outlined"}
                  size={"medium"}
                  required
                  fullWidth
                />
              )}
              filterOptions={(options, params) => {
                const filtered = filter(options, params);
                const { inputValue } = params;
                // Suggest the creation of a new value
                if (inputValue !== "") {
                  filtered.push(inputValue);
                }
                return filtered;
              }}
              selectOnFocus
              clearOnBlur
              handleHomeEndKeys
              multiple
              freeSolo
            />
          </Grid>
          {values.reportType?.value !== "applicant_history_export" ? (
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={sendEmailModal.is_resume}
                    onChange={(e) =>
                      handleSendEmailChange(e.target.checked, "is_resume")
                    }
                    name={"is_resume"}
                    color={"primary"}
                  />
                }
                label={"With Resume"}
              />
            </Grid>
          ) : (
            <Grid item xs={12} />
          )}
        </Grid>
      </SimpleModal>
      <CustomSnack
        open={snack.open}
        color={snack.color}
        message={snack.message}
        onClose={handleSnackClose}
      />
    </Container >
  );
}

export default Index;
