import axios from "axios";

axios.defaults.xsrfHeaderName = "X-CSRFToken";
axios.defaults.xsrfCookieName = "csrftoken";

export default {
  /*---- User Info and Report Bug and Signup happens in Home Page-----*/

  // Get user Info and user access based on the Login Details
  getUserInfo: function () {
    return axios.get("/customers/user_info/");
  },

  createUser: function (userInfo) {
    return axios.post("/signup/", userInfo);
  },

  // Report Issues from the User
  postReportBug: function (formData) {
    return axios.post("/customers/contact_support/", formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  },
  /*---------------------------------------------*/

  /*---- Get all the Graphs for Dashboard page-----*/

  // Get Dashboard Graph from the backend
  getDashboardDiv: function (graphNum) {
    return axios.get("/customers/graph" + graphNum);
  },

  /*---------------------------------------------*/

  /*---- Get the Question and answer and View File Attachments in Help Page -----*/

  // Get the FAQ File from backend
  getFAQfile: function () {
    return axios.get("/customers/faq/");
  },

  //Get FAQ View file
  viewFAQAttachments: function (path) {
    return axios.get("/customers/get_file?path=" + path);
  },

  /*---------------------------------------------*/

  /*---- Fetch Autocomplete Skills and custom Role Match list common for many pages -----*/

  // Fetch the Autocomplete skills Data from the backend
  fetchAutoCompleteSkillList: async function (data) {
    return axios
      .post("/resume-compare/get-skill-list/", data)
      .then((res) => {
        console.log("Auto complete Success");
        return res.data.data;
      })
      .catch((err) => {
        console.log("Auto complete Failure");
        // return sample.autocompleteskills;
      });
  },

  fetchCustomMatchRolelList: async function (data) {
    return axios
      .post("/resume-compare/get-role-list/", data)
      .then((res) => {
        console.log("Role List Request Success");
        return res.data;
      })
      .catch((err) => {
        console.log("Role List Request Failure");
        // return sample.autocompleteskills;
      });
  },
};
