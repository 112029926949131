import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import React from "react";

function RepeatedWorkExperience({ repeatedWorkExp }) {
  return (
    <Box p={1}>
      {repeatedWorkExp && repeatedWorkExp.length !== 0 ? (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography>
                    <b>{"Repeated Lines"}</b>
                  </Typography>
                </TableCell>
                <TableCell align={"center"}>
                  <Typography align={"center"}>
                    <b>{"Repeated in Projects"}</b>
                  </Typography>
                </TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {repeatedWorkExp.map((repeatedWorkExpLine, index) => {
                return (
                  <TableRow key={`repeated-work-exp-line-${index}`}>
                    <TableCell>
                      <Typography>{repeatedWorkExpLine.line}</Typography>
                    </TableCell>
                    <TableCell align={"center"}>
                      {repeatedWorkExpLine.project
                        .replace("Projects ", "")
                        .split(",")
                        .map((project) => (
                          <span className="chip">{project}</span>
                        ))}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Typography align={"center"}>
          <b>{"Repeated Work Experience not found"}</b>
        </Typography>
      )}
    </Box>
  );
}

export default RepeatedWorkExperience;
