import { Box, Dialog, IconButton, Tooltip } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import React from "react";

function CloseModal({ open, handleClose, maxWidth = "md", children }) {
  return (
    <Dialog
      open={open}
      maxWidth={maxWidth}
      onClose={handleClose}
      disableEnforceFocus
    >
      <Box position={"absolute"} right={5} top={5}>
        <Tooltip title={"Close"}>
          <IconButton color={"secondary"} onClick={handleClose}>
            <Close fontSize={"medium"} />
          </IconButton>
        </Tooltip>
      </Box>
      <Box px={7} py={4}>
        {children}
      </Box>
    </Dialog>
  );
}

export default CloseModal;
