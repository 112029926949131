import { QUALIFY } from "../actionTypes";

const initialState = {
  pagination: {
    activePage: 1,
    items: 10,
  },
};

const qualify = (state = initialState, { type, payload }) => {
  switch (type) {
    case QUALIFY.setState: {
      return { ...state, ...payload };
    }
    case QUALIFY.clearState: {
      return initialState;
    }
    default:
      return state;
  }
};

export default qualify;
