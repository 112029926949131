import { Box, ClickAwayListener, Popper } from "@material-ui/core";
import React from "react";

function CustomPopper({ anchorEl, placement, marginTop, onClose, children }) {
  return (
    <Popper open={Boolean(anchorEl)} anchorEl={anchorEl} placement={placement}>
      <ClickAwayListener onClickAway={onClose}>
        <Box className={"custom_popper"} marginTop={marginTop}>
          {children}
        </Box>
      </ClickAwayListener>
    </Popper>
  );
}

export default CustomPopper;
