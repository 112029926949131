import { Container, Grid, Typography } from "@material-ui/core";
import React, { useState } from "react";
import SimpleTabs from "../../commonComponents/Tabs/SimpleTabs";
import { useHistory, useParams } from "react-router-dom";
import SkillHierarchy from "./SkillHierarchy/Index";
import EducationalInstitutes from "./EducationalInstitutes/Index";
import PreferredCompany from "./PreferredCompany/Index";
import Hobbies from "./Hobbies/Index";
import Domain from "./Domain/Index";

const HIERARCHY_PATH_NAME = {
  skillHierarchy: "skill-hierarchy",
  educationalInstitutes: "educational-institutes",
  preferredCompany: "preferred-company",
  hobbies: "hobbies",
  domain: "domain"
};

const HIERARCHY = [
  {
    label: "Skill Hierarchy",
    value: HIERARCHY_PATH_NAME.skillHierarchy,
    id: HIERARCHY_PATH_NAME.skillHierarchy,
  },
  {
    label: "Educational Institutes",
    value: HIERARCHY_PATH_NAME.educationalInstitutes,
    id: HIERARCHY_PATH_NAME.educationalInstitutes,
  },
  {
    label: "Preferred Company",
    value: HIERARCHY_PATH_NAME.preferredCompany,
    id: HIERARCHY_PATH_NAME.preferredCompany,
  },
  {
    label: "Hobbies",
    value: HIERARCHY_PATH_NAME.hobbies,
    id: HIERARCHY_PATH_NAME.hobbies,
  },
  {
    label: "Domain",
    value: HIERARCHY_PATH_NAME.domain,
    id: HIERARCHY_PATH_NAME.domain,
  },
];

function Index() {
  const history = useHistory();
  const { name } = useParams();
  const [tabValue, setTabValue] = useState(
    name || HIERARCHY_PATH_NAME.skillHierarchy
  );
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    history.replace(`/home/hierarchy/${newValue}`);
  };

  const renderComponent = () => {
    switch (name) {
      case HIERARCHY_PATH_NAME.educationalInstitutes:
        return <EducationalInstitutes />;
      case HIERARCHY_PATH_NAME.preferredCompany:
        return <PreferredCompany />;
      case HIERARCHY_PATH_NAME.hobbies:
        return <Hobbies />;
      case HIERARCHY_PATH_NAME.domain:
        return <Domain />;
      default:
        return <SkillHierarchy />;
    }
  };

  return (
    <Container maxWidth={"md"} className={"mt-8"}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography>
            <b>{"Hierarchy"}</b>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <SimpleTabs
            value={tabValue}
            tabList={HIERARCHY}
            onChange={handleTabChange}
            indicatorColor={"primary"}
            textColor={"primary"}
          />
        </Grid>
        <Grid item xs={12}>
          {renderComponent()}
        </Grid>
        <Grid item xs={12} />
      </Grid>
    </Container>
  );
}

export default Index;
