import {
  Container,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { getAllQualify } from "../../api/api";
import Loader from "../../commonComponents/Loader/Loader";
import CustomSnack from "../../commonComponents/Snack/CustomSnack";
import {
  StyledTableContainer,
  StyledTableHead,
} from "../../commonComponents/StyledComponents";
import { setTestState } from "../../redux/actions/test";
import { MESSAGES } from "../../utils/constants/constants";
import { FEEDBACK_LEVELS } from "../../utils/constants/feedbackLevelBasedAccess";
import { errorResponse, getOffset } from "../../utils/constants/helpers";
import { History } from "@material-ui/icons";
import { ADMINS, ROLES } from "../../utils/constants/roleBasedAccess";

export const Options = [
  {
    id: 1,
    value: "10",
  },
  {
    id: 2,
    value: "20",
  },
  {
    id: 3,
    value: "30",
  },
  {
    id: 4,
    value: "40",
  },
  {
    id: 5,
    value: "50",
  },
  {
    id: 6,
    value: "100",
  },
];

axios.defaults.xsrfHeaderName = "X-CSRFToken";
axios.defaults.xsrfCookieName = "csrftoken";

function TestModule() {
  const { jobCode } = useParams();
  const history = useHistory();
  const role = localStorage.getItem("role");
  const isRecruiter = ROLES.recruiter === role;
  const isAdmin = ADMINS.includes(role);
  const isManager = ROLES.manager === role;
  const feedbackLevel = sessionStorage.getItem("feedbackLevel");

  const [totalCount, setTotalCount] = useState(0);
  const [qualifyList, setQualifyList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [snack, setSnack] = useState({
    open: false,
    color: "",
    message: "",
  });

  const { pagination } = useSelector((state) => state.test);
  const dispatch = useDispatch();

  const hasFeedbackStatus = (level) => {
    return level === feedbackLevel ? "True" : null;
  };

  const fetchQualifyList = async (limit, page) => {
    try {
      const res = await axios.get(getAllQualify(jobCode), {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("tal_token"),
        },
        params: {
          limit: limit,
          offset: getOffset(page, limit),
          is_selected:
            hasFeedbackStatus(FEEDBACK_LEVELS.movedToTest) ||
            hasFeedbackStatus(FEEDBACK_LEVELS.testPending),
          is_testcompleted:
            FEEDBACK_LEVELS.testPending === feedbackLevel
              ? "False"
              : hasFeedbackStatus(FEEDBACK_LEVELS.testCompleted),
          is_moved: isManager
            ? hasFeedbackStatus(FEEDBACK_LEVELS.recommend)
            : null,
          is_recommend: isManager
            ? null
            : hasFeedbackStatus(FEEDBACK_LEVELS.recommend),
        },
      });
      return res;
    } catch (error) {
      return errorResponse(error);
    }
  };

  const handleSnackClose = () => {
    setSnack({
      open: false,
      color: "",
      message: "",
    });
  };

  const handleSnackOpen = (message = MESSAGES.error, color = "error") => {
    setSnack({
      open: true,
      color: color,
      message: message || MESSAGES.error,
    });
  };

  const setData = (data) => {
    setQualifyList(data.results);
    setTotalCount(data.count);
    dispatch(
      setTestState({
        totalCount: data.count,
      })
    );
  };

  const getAndSetQualifyList = async (limit, page) => {
    setLoading(true);

    const response = await fetchQualifyList(limit, page);
    if (response.status === 200) {
      setData(response.data);
    } else {
      handleSnackOpen(response.message);
    }
    setLoading(false);
  };

  const onSelectPage = (e, value) => {
    getAndSetQualifyList(pagination.items, value);
    dispatch(
      setTestState({
        pagination: { ...pagination, activePage: value },
      })
    );
  };

  const handlePageLimitChange = (e) => {
    const { value } = e.target;
    getAndSetQualifyList(parseInt(value), pagination.activePage);
    dispatch(
      setTestState({
        pagination: { ...pagination, items: parseInt(value) },
      })
    );
  };

  const handleStatusClick = (applicantId, applicantsession, moduleName) => {
    history.push(
      `/dashboard/${jobCode}/test/${applicantId}/${applicantsession}/${moduleName}`
    );
  };

  const fetchAndCheck = async () => {
    setLoading(true);
    const response = await fetchQualifyList(
      pagination.items,
      pagination.activePage
    );

    if (response.status === 200) {
      // If the count and result data was not satisfied, then again call the same api with first page of the data
      if (!response.data.results.length && response.data.count) {
        getAndSetQualifyList(pagination.items, 1);
        dispatch(
          setTestState({
            pagination: { ...pagination, activePage: 1 },
          })
        );
        return;
      } else {
        setData(response.data);
      }
    } else {
      handleSnackOpen(response.message);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchAndCheck();
  }, []);

  const jobTitle = sessionStorage.getItem("jobTitle");

  const handleViewHistory = (id) => {
    history.push(`/applicant-history/${jobCode}/${id}`, {
      reference_name: "testModule",
    });
  };

  return (
    <Container maxWidth={"xl"} className={"mb-4 mt-7"}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography>
            <b>{`${jobTitle} - ${jobCode}`}</b>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <StyledTableContainer component={Paper} variant={"outlined"}>
            <Table size={"small"}>
              <StyledTableHead>
                <TableRow>
                  <TableCell>{"Applicant Name"}</TableCell>
                  <TableCell>{"Email"}</TableCell>
                  <TableCell>{"Phone"}</TableCell>
                  <TableCell>{"Scheduling"}</TableCell>
                  <TableCell>{"Test Event"}</TableCell>
                  <TableCell>{"Test Outcome"}</TableCell>
                  <TableCell align={"center"}>{"View History"}</TableCell>
                </TableRow>
              </StyledTableHead>
              <TableBody>
                {!loading && qualifyList && qualifyList.length > 0 ? (
                  qualifyList?.map(
                    (
                      {
                        file_name,
                        email,
                        phone,
                        applicant,
                        test_event_status,
                        test_outcome_status,
                        test_schedule_status,
                      },
                      index
                    ) => {
                      return (
                        <TableRow>
                          <TableCell>
                            {`${applicant?.firstname || ""} ${
                              applicant?.lastname || ""
                            }`.trim()}
                          </TableCell>
                          <TableCell>{email}</TableCell>
                          <TableCell>{phone}</TableCell>
                          <TableCell
                            className={"cursor-pointer"}
                            onClick={() =>
                              handleStatusClick(
                                applicant.id,
                                applicant.applicantsession,
                                "scheduling"
                              )
                            }
                          >
                            <b className={"link-text"}>
                              {test_schedule_status === "sent"
                                ? "Sent"
                                : test_schedule_status === "in_progress"
                                ? "In Progress"
                                : test_schedule_status === "submitted"
                                ? "Submitted"
                                : test_schedule_status === "opened"
                                ? "Opened"
                                : test_schedule_status === "new"
                                ? "New"
                                : test_schedule_status}
                            </b>
                          </TableCell>
                          <TableCell
                            className={"cursor-pointer"}
                            onClick={() =>
                              handleStatusClick(
                                applicant.id,
                                applicant.applicantsession,
                                "test-event"
                              )
                            }
                          >
                            <b className={"link-text"}>{test_event_status}</b>
                          </TableCell>
                          <TableCell
                            className={"cursor-pointer"}
                            onClick={() =>
                              handleStatusClick(
                                applicant.id,
                                applicant.applicantsession,
                                "test-outcome"
                              )
                            }
                          >
                            <b className={"link-text"}>{test_outcome_status}</b>
                          </TableCell>
                          <TableCell align={"center"}>
                            <IconButton
                              color={"primary"}
                              onClick={() => handleViewHistory(applicant?.id)}
                            >
                              <History />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      );
                    }
                  )
                ) : (
                  <TableRow>
                    <TableCell align={"center"} colSpan={7}>
                      {loading ? (
                        <Loader />
                      ) : (
                        <Typography>
                          <b>No Result Found!</b>
                        </Typography>
                      )}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </StyledTableContainer>
        </Grid>
        <Grid
          item
          xs={12}
          container
          justifyContent="flex-end"
          alignItems="center"
        >
          <Typography>
            Total Count: <b>{totalCount}</b>
          </Typography>
        </Grid>
        <Grid item xs={2} container alignItems="center">
          <FormControl style={{ width: 120 }}>
            <InputLabel>Rows per page</InputLabel>
            <Select value={pagination.items} onChange={handlePageLimitChange}>
              {Options.map(({ id, value }) => (
                <MenuItem key={id} value={value}>
                  {value}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid
          item
          xs={10}
          container
          alignItems="center"
          justifyContent="flex-end"
        >
          <Pagination
            color={"primary"}
            page={pagination.activePage}
            onChange={onSelectPage}
            count={Math.ceil(totalCount / pagination.items)}
            showFirstButton
            showLastButton
          />
        </Grid>
      </Grid>
      <CustomSnack
        open={snack.open}
        color={snack.color}
        message={snack.message}
        onClose={handleSnackClose}
      />
    </Container>
  );
}

export default TestModule;
