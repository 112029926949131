import {
  Box,
  Button,
  Chip,
  Container,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { Delete } from "@material-ui/icons";
import { Alert, Autocomplete, createFilterOptions } from "@material-ui/lab";
import axios from "axios";
import { Form, FormikProvider, useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import * as yup from "yup";
import {
  addIntakeForm,
  getAllCompetitorCompanyList,
  getAllIntakeList,
  getJobIntakeById,
  intakeSendNotify,
  updateJobIntakeForm,
} from "../../api/api";
import { clearIntakeState } from "../../redux/actions/intakeForm";
import { MESSAGES } from "../../utils/constants/constants";
import { isAllowNumberDotComma } from "../../utils/constants/validation";
import { ADMINS, ROLES } from "../../utils/constants/roleBasedAccess";
import { INTAKE_ACTIONS } from "../../utils/constants/constants";

const filter = createFilterOptions();

const experienceOption = [
  {
    name: 1,
    value: "1",
  },
  {
    name: 2,
    value: "2",
  },
  {
    name: 3,
    value: "3",
  },
  {
    name: 4,
    value: "4",
  },
  {
    name: 5,
    value: "5",
  },
  {
    name: 6,
    value: "6",
  },
  {
    name: 7,
    value: "7",
  },
  {
    name: 8,
    value: "8",
  },
  {
    name: 9,
    value: "9",
  },
  {
    name: 10,
    value: "10",
  },
  {
    name: 11,
    value: "11",
  },
  {
    name: 12,
    value: "12",
  },
  {
    name: 13,
    value: "13",
  },
  {
    name: 14,
    value: "14",
  },
  {
    name: 15,
    value: "15",
  },
  {
    name: 16,
    value: "16",
  },
  {
    name: 17,
    value: "17",
  },
  {
    name: 18,
    value: "18",
  },
  {
    name: 19,
    value: "19",
  },
  {
    name: "20+",
    value: "20",
  },
];

const testLevelOption = [
  {
    name: "Select",
    value: "",
  },
  {
    name: "Basic",
    value: "Basic",
  },
  {
    name: "Intermediate",
    value: "Intermediate",
  },
  {
    name: "Advanced",
    value: "Advanced",
  },
];

const SKILL_OBJECT = {
  skill_name: "",
  years: "",
};

const CRITICAL_SKILL_OBJECT = {
  name: "",
};

function AddIntakeForm() {
  const { id, roleName } = useParams();
  const { push } = useHistory();
  const username = localStorage.getItem("username");
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.up("md"));
  const role = localStorage.getItem("role");
  const isAdmin = ADMINS.includes(role);
  const isManager = role === ROLES.manager;
  const isManagerAccessLink = isManager && roleName === role;

  const [criticalSkillList, setCriticalSkillList] = useState([]);
  const [preferredCompanyList, setPreferredCompanyList] = useState([]);
  const [alertBox, setAlertBox] = useState({
    open: false,
    success: false,
    message: "",
  });

  const dispatch = useDispatch();

  const validationSchema = yup.object({
    critical_skills: yup.array().of(
      yup.object().shape({
        name: yup.string().optional().nullable(),
      })
    ),
    skills_req_years: yup.array().of(
      yup.object().shape({
        skill_name: yup.string().nullable().required(MESSAGES.required),
        years: yup.string().nullable().required(MESSAGES.required),
      })
    ),
    preferred: yup.array().of(
      yup.object().shape({
        company_name: yup.string().optional().nullable(),
      })
    ),
    job_code: yup.string().nullable().required(MESSAGES.required),
    pre_id: yup.string().optional().nullable(),
    job_title: yup.string().nullable().required(MESSAGES.required),
    job_location: yup.string().nullable().required(MESSAGES.required),
    business_title: yup.string().optional().nullable(),
    total_it: yup.string().optional().nullable(),
    job_family_group: yup.string().optional().nullable(),
    no_of_positions: yup.string().optional().nullable(),
    ref_zone: yup.string().optional().nullable(),
    pay_band: yup.string().optional().nullable(),
    countries: yup.string().optional().nullable(),
    work_option: yup.string().nullable().required(MESSAGES.required),
    tax_term: yup.string().nullable().required(MESSAGES.required),
    target_annual_salary: yup.string().optional().nullable(),
    interview: yup.string().optional().nullable(),
    technical: yup.string().nullable().required(MESSAGES.required),
    pre_screen: yup.string().optional().nullable(),
    job_description: yup.string().nullable().required(MESSAGES.required),
    other_comments: yup.string().optional().nullable(),
  });

  const sendNotify = ({ job_title, job_code, action }) => {
    let payload = {
      role: isAdmin ? ROLES.admin : role,
      username: username,
      job_title: job_title,
      job_code: job_code,
      action: action,
    };
    axios.post(intakeSendNotify(), payload);
  };

  const handleSubmitClick = (values) => {
    let formattedCritical = [];

    if (values.critical_skills.length) {
      for (let i = 0; i < values.critical_skills.length; i++)
        if (values.critical_skills[i].name)
          formattedCritical.push(values.critical_skills[i].name);
    }

    let formattedSkillsReqYears = [];
    if (values.skills_req_years.length) {
      for (let i = 0; i < values.skills_req_years.length; i++)
        if (values.skills_req_years[i].skill_name)
          formattedSkillsReqYears.push(values.skills_req_years[i]);
    }

    let formattedPreferred =
      values.preferred.length > 0
        ? values.preferred.map((el, ind) => {
          return el.inputValue ? el.inputValue : el.company_name;
        })
        : [];
    let requestBody = {
      ...values,
      critical_skills: formattedCritical,
      skills_req_years: formattedSkillsReqYears,
      preferred: formattedPreferred
    };

    if (id) {
      axios
        .post(updateJobIntakeForm(id), requestBody)
        .then((response) => {
          if (response.status === 201) {
            setAlertBox({
              open: true,
              success: true,
              message: "Updated Successfully.",
            });
            sendNotify({
              job_code: values.job_code,
              job_title: values.job_title,
              action: INTAKE_ACTIONS.edit,
            });
          } else {
            setAlertBox({
              open: true,
              success: false,
              message: "Something went wrong!",
            });
          }
        })
        .catch((err) => {
          setAlertBox({
            open: true,
            success: false,
            message: "Something went wrong!",
          });
        })
        .finally(() => {
          setTimeout(() => {
            setAlertBox({
              open: false,
              success: true,
              message: "",
            });
            push("/intake/form");
          }, 2000);
        });
    } else {
      axios
        .post(addIntakeForm(), requestBody)
        .then((response) => {
          if (response.status === 201) {
            setAlertBox({
              open: true,
              success: true,
              message: "Added Successfully.",
            });
            dispatch(clearIntakeState());
            sendNotify({
              job_code: values.job_code,
              job_title: values.job_title,
              action: INTAKE_ACTIONS.edit,
            });
            setTimeout(() => {
              push("/intake/form");
            }, 2000);
          } else {
            setAlertBox({
              open: true,
              success: false,
              message: "Something went wrong!",
            });
          }
        })
        .catch((err) => {
          setAlertBox({
            open: true,
            success: false,
            message: "Something went wrong!",
          });
        })
        .finally(() => {
          setTimeout(() => {
            setAlertBox({
              open: false,
              success: true,
              message: "",
            });
          }, 2000);
        });
    }
  };

  const formik = useFormik({
    initialValues: {
      id: "",
      critical_skills: [],
      skills_req_years: [],
      preferred: [],
      created: "",
      updated: "",
      job_code: "",
      pre_id: "",
      job_title: "",
      job_location: "",
      business_title: null,
      total_it: null,
      client_name: null,
      no_of_positions: null,
      ref_zone: null,
      pay_band: null,
      countries: null,
      work_option: "Onsite",
      tax_term: "Full time Employee",
      target_annual_salary: null,
      interview: null,
      technical: "",
      pre_screen: "",
      job_description: "",
      other_comments: null,
    },
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: handleSubmitClick,
  });

  const { values, handleChange, touched, errors, setFieldValue, handleBlur } =
    formik;

  useEffect(() => {
    if (id) {
      axios.get(getJobIntakeById(id)).then((response) => {
        if (response.status === 200) {
          let arr =
            response.data.skills_req_years?.length > 0
              ? response.data.skills_req_years
              : [SKILL_OBJECT];
          let critical_skills_arr =
            response.data.critical_skills?.length > 0
              ? response.data.critical_skills
              : [CRITICAL_SKILL_OBJECT];
          formik.setValues({
            ...response.data,
            skills_req_years: arr,
            critical_skills: critical_skills_arr,
          });
        }
      });
    } else {
      formik.setValues({
        ...formik.values,
        skills_req_years: [SKILL_OBJECT],
        critical_skills: [CRITICAL_SKILL_OBJECT],
      });
    }

    axios.get(getAllIntakeList()).then((response) => {
      if (response.status === 200) {
        setCriticalSkillList(response.data);
      }
    });

    axios.get(getAllCompetitorCompanyList()).then((response) => {
      if (response.status === 200) {
        setPreferredCompanyList(response.data);
      }
    });
  }, []);

  const handleSubmitErrorCheck = () => {
    if (errors && Object.keys(errors).length) {
      setTimeout(() => {
        let el = window.document.querySelector(".Mui-error");
        if (el) {
          el.scrollIntoView({
            block: "center",
            behavior: "smooth",
          });
        }
      }, 10);
    }
  };

  const skillList =
    criticalSkillList &&
      Array.isArray(criticalSkillList) &&
      criticalSkillList.length > 0
      ? criticalSkillList.map((el) => el.name)
      : [];
  return (
    <Container maxWidth={"md"} className={"mt-8 mb-8"}>
      {isManagerAccessLink || !roleName ? (
        <FormikProvider value={formik}>
          <Form noValidate>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography>
                  <b>Job</b>
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  value={values.job_title || ""}
                  name={"job_title"}
                  variant={"outlined"}
                  label={"Position Title"}
                  fullWidth
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.job_title && Boolean(errors.job_title)}
                  helperText={touched.job_title && errors.job_title}
                  required
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  value={values.job_location || ""}
                  name={"job_location"}
                  variant={"outlined"}
                  label={"Position/Project Location"}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.job_location && Boolean(errors.job_location)}
                  helperText={touched.job_location && errors.job_location}
                  required
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  disabled={!!id}
                  value={values.job_code || ""}
                  name={"job_code"}
                  variant={"outlined"}
                  label={"Position Code"}
                  fullWidth
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.job_code && Boolean(errors.job_code)}
                  helperText={touched.job_code && errors.job_code}
                  required
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  value={values.no_of_positions || ""}
                  name={"no_of_positions"}
                  variant={"outlined"}
                  label={"No. of Positions"}
                  fullWidth
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  value={values.client_name || ""}
                  name={"client_name"}
                  variant={"outlined"}
                  label={"Client Name"}
                  fullWidth
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  value={values.pay_band || ""}
                  name={"pay_band"}
                  variant={"outlined"}
                  label={"Target Pay Rate"}
                  fullWidth
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth variant="outlined">
                  <InputLabel id="demo-simple-select-outlined-label">
                    Pre ID
                  </InputLabel>
                  <Select
                    name="pre_id"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.pre_id}
                    fullWidth
                    label={"Pre-ID"}
                  >
                    <MenuItem value={""}>Select</MenuItem>
                    <MenuItem value={"Yes"}>Yes</MenuItem>
                    <MenuItem value={"No"}>No</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  value={values.ref_zone || ""}
                  name={"ref_zone"}
                  variant={"outlined"}
                  label={"Department"}
                  fullWidth
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </Grid>
              <Grid item xs={6}>
                <FormControl
                  fullWidth
                  variant="outlined"
                  error={touched.work_option && Boolean(errors.work_option)}
                  required
                >
                  <InputLabel id="demo-simple-select-outlined-label">
                    Work Option
                  </InputLabel>
                  <Select
                    name="work_option"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.work_option}
                    fullWidth
                    label={"Work Option"}
                  >
                    <MenuItem value={"Onsite"}>Onsite</MenuItem>
                    <MenuItem value={"Hybrid"}>Hybrid</MenuItem>
                    <MenuItem value={"Remote"}>Remote</MenuItem>
                  </Select>
                  <FormHelperText>
                    {touched.work_option && errors.work_option}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl
                  fullWidth
                  variant="outlined"
                  error={touched.tax_term && Boolean(errors.tax_term)}
                  required
                >
                  <InputLabel id="demo-simple-select-outlined-label">
                    Tax Term
                  </InputLabel>
                  <Select
                    name="tax_term"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.tax_term}
                    fullWidth
                    label={"Tax Term"}
                  >
                    <MenuItem value={"Full time Employee"}>
                      Full Time Employee
                    </MenuItem>
                    <MenuItem value={"Contract To Hire"}>
                      Contract To Hire
                    </MenuItem>
                    <MenuItem value={"Contract"}>Contract</MenuItem>
                  </Select>
                  <FormHelperText>
                    {touched.tax_term && errors.tax_term}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  value={values.target_annual_salary || ""}
                  name={"target_annual_salary"}
                  variant={"outlined"}
                  label={"Target Bill Rate"}
                  fullWidth
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  value={values.interview || ""}
                  name={"interview"}
                  variant={"outlined"}
                  label={"Interview Process"}
                  fullWidth
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </Grid>
              <Grid item xs={6}>
                <FormControl
                  variant={"outlined"}
                  error={touched.technical && Boolean(errors.technical)}
                  required
                  fullWidth
                >
                  <InputLabel id={"technical-test"}>
                    {"Technical Test"}
                  </InputLabel>
                  <Select
                    name={"technical"}
                    label={"Technical Test"}
                    value={values.technical || ""}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  >
                    <MenuItem value={"Yes"}>{"Yes"}</MenuItem>
                    <MenuItem value={"No"}>{"No"}</MenuItem>
                  </Select>
                  <FormHelperText>
                    {touched.technical && errors.technical}
                  </FormHelperText>
                </FormControl>
              </Grid>

              <Grid item xs={6}>
                <TextField
                  value={values.business_title || ""}
                  name={"business_title"}
                  variant={"outlined"}
                  label={"Local / Non Local Policy"}
                  fullWidth
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  value={values.countries || ""}
                  name={"countries"}
                  variant={"outlined"}
                  label={"Country"}
                  fullWidth
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  value={values.total_it || ""}
                  name={"total_it"}
                  variant={"outlined"}
                  label={"Total IT"}
                  fullWidth
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  value={values.pre_screen || ""}
                  name={"pre_screen"}
                  variant={"outlined"}
                  label={"Pre Screen Questions"}
                  fullWidth
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  value={values.job_description || ""}
                  name={"job_description"}
                  multiline
                  minRows={4}
                  variant={"outlined"}
                  label={"Position Description"}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={
                    touched.job_description && Boolean(errors.job_description)
                  }
                  helperText={touched.job_description && errors.job_description}
                  required
                  fullWidth
                />
              </Grid>
              {/* <Grid item xs={6}>
              <Autocomplete
                multiple
                fullWidth
                value={values.critical_skills}
                id="tags-filled"
                options={criticalSkillList}
                freeSolo
                onChange={(event, newValue) => {
                  if (typeof newValue === "string") {
                    setFieldValue("critical_skills", newValue);
                  } else if (newValue && newValue.inputValue) {
                    // Create a new value from the user input
                    setFieldValue("critical_skills", newValue.inputValue);
                  } else {
                    setFieldValue("critical_skills", newValue);
                  }
                }}
                filterOptions={(options, params) => {
                  const filtered = filter(options, params);

                  // Suggest the creation of a new value
                  if (params.inputValue !== "") {
                    filtered.push({
                      inputValue: params.inputValue,
                      name: `Add "${params.inputValue}"`,
                    });
                  }

                  return filtered;
                }}
                getOptionLabel={(option) => {
                  // Value selected with enter, right from the input
                  if (typeof option === "string") {
                    return option;
                  }
                  // Add "xxx" option created dynamically
                  if (option.inputValue) {
                    return option.inputValue;
                  }
                  // Regular option
                  return option.name;
                }}
                renderOption={(option) => option.name}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                filterSelectedOptions
                getOptionSelected={(option, value) => option.id === value.id}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => {
                    return (
                      <Chip
                        variant="outlined"
                        label={
                          option.inputValue ? option.inputValue : option.name
                        }
                        {...getTagProps({ index })}
                      />
                    );
                  })
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Critical Skills"
                    inputProps={{
                      ...params.inputProps,
                      onKeyDown: (e) => {
                        if (e.key === "Enter") {
                          e.stopPropagation();
                        }
                      },
                    }}
                  />
                )}
              />
            </Grid> */}
              <Grid item xs={12}>
                <Box
                  style={{
                    border: "1px solid rgba(0, 0, 0, 0.23)",
                    padding: "15px 20px 20px",
                  }}
                >
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                      <Box>
                        <Grid container spacing={2}>
                          <Grid item xs>
                            <Typography
                              variant={"body1"}
                              style={{ fontWeight: 600 }}
                            >
                              {"Skills"}
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            style={{
                              width: "150px",
                            }}
                          >
                            <Typography
                              variant={"body1"}
                              style={{ fontWeight: 600 }}
                            >
                              {"Years"}
                            </Typography>
                          </Grid>
                        </Grid>
                        {values.skills_req_years &&
                          Array.isArray(values.skills_req_years)
                          ? values.skills_req_years.map((val, index) => {
                            let skillArr = [...values.skills_req_years];
                            skillArr.splice(index, 1);

                            return index < 5 ? (
                              <Grid
                                container
                                spacing={2}
                                style={{
                                  paddingBottom:
                                    !md &&
                                      values.skills_req_years.length > 5 &&
                                      index === 4
                                      ? 0
                                      : "16px",
                                }}
                                alignItems={"flex-start"}
                              >
                                <Grid item xs>
                                  {/* <TextField
                                    value={val?.skill_name || ""}
                                    name={"skill_name"}
                                    variant={"outlined"}
                                    fullWidth
                                    onChange={(e) => {
                                      const { value } = e.target;
                                      let arr = [...values.critical_skills];
                                      arr[index]["name"] = value;
                                      let arr2 = [...values.skills_req_years];
                                      arr2[index]["skill_name"] = value;
                                      setFieldValue("critical_skills", arr);
                                      setFieldValue("skills_req_years", arr2);
                                    }}
                                    placeholder={"Skill"}
                                  /> */}
                                  <Autocomplete
                                    freeSolo
                                    options={skillList}
                                    value={val?.skill_name || ""}
                                    getOptionLabel={(option) => option}
                                    getOptionSelected={(option, value) =>
                                      option === value
                                    }
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        placeholder={"Skill"}
                                        variant={"outlined"}
                                        error={
                                          touched.skills_req_years?.[index]
                                            ?.skill_name &&
                                          Boolean(
                                            errors.skills_req_years?.[index]
                                              ?.skill_name
                                          )
                                        }
                                        helperText={
                                          touched.skills_req_years?.[index]
                                            ?.skill_name &&
                                          errors.skills_req_years?.[index]
                                            ?.skill_name
                                        }
                                      />
                                    )}
                                    onChange={(e, newVal) => {
                                      e.preventDefault();
                                      let arr = [...values.critical_skills];
                                      let arr2 = [...values.skills_req_years];
                                      let isExisting = skillArr.some(
                                        (option) =>
                                          option?.skill_name?.trim() ===
                                          newVal?.trim()
                                      );
                                      if (!isExisting) {
                                        arr[index]["name"] = newVal?.trim();
                                        arr2[index]["skill_name"] =
                                          newVal?.trim();
                                      } else {
                                        arr[index]["name"] = "";
                                        arr2[index]["skill_name"] = "";
                                      }
                                      setFieldValue("critical_skills", arr);
                                      setFieldValue("skills_req_years", arr2);
                                    }}
                                    filterOptions={(options, params) => {
                                      const filtered = filter(
                                        options,
                                        params
                                      );

                                      const { inputValue } = params;
                                      // Suggest the creation of a new value
                                      const isExisting = options.some(
                                        (option) => inputValue === option
                                      );

                                      if (inputValue !== "" && !isExisting) {
                                        filtered.push(inputValue);
                                      }

                                      return filtered;
                                    }}
                                    getOptionDisabled={(option) => {
                                      return skillArr.some(
                                        (option2) =>
                                          option2?.skill_name?.trim() ===
                                          option?.trim()
                                      );
                                    }}
                                    selectOnFocus
                                    clearOnBlur
                                    handleHomeEndKeys
                                  />
                                </Grid>
                                <Grid
                                  item
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "flex-end",
                                    width: "150px",
                                    gap: "8px",
                                  }}
                                >
                                  <FormControl
                                    fullWidth
                                    variant="outlined"
                                    error={
                                      touched.skills_req_years?.[index]
                                        ?.years &&
                                      Boolean(
                                        errors.skills_req_years?.[index]
                                          ?.years
                                      )
                                    }
                                  >
                                    <Select
                                      name={"years"}
                                      onChange={(e) => {
                                        const { value } = e.target;
                                        let arr = [
                                          ...values.skills_req_years,
                                        ];
                                        arr[index]["years"] = value;
                                        setFieldValue(
                                          "skills_req_years",
                                          arr
                                        );
                                      }}
                                      value={val?.years}
                                      fullWidth
                                    >
                                      {experienceOption.map(
                                        ({ name, value }) => (
                                          <MenuItem value={value}>
                                            {name}
                                          </MenuItem>
                                        )
                                      )}
                                    </Select>
                                  </FormControl>
                                  <IconButton
                                    color={"secondary"}
                                    onClick={() => {
                                      let arr = [...values.critical_skills];
                                      let arr2 = [...values.skills_req_years];
                                      arr.splice(index, 1);
                                      arr2.splice(index, 1);
                                      setFieldValue("critical_skills", arr);
                                      setFieldValue("skills_req_years", arr2);
                                    }}
                                    disabled={
                                      !(values.skills_req_years.length > 1)
                                    }
                                  >
                                    <Delete />
                                  </IconButton>
                                </Grid>
                              </Grid>
                            ) : null;
                          })
                          : null}
                        {values.skills_req_years?.length < 6 ? (
                          <Box textAlign={"right"}>
                            <Button
                              variant={"contained"}
                              color={"primary"}
                              onClick={() => {
                                let arr = [...values.critical_skills];
                                let arr2 = [...values.skills_req_years];
                                arr.push({ name: "" });
                                arr2.push({ skill_name: "", years: "" });
                                setFieldValue("critical_skills", arr);
                                setFieldValue("skills_req_years", arr2);
                              }}
                            >
                              Add
                            </Button>
                          </Box>
                        ) : null}
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Box>
                        {md && values.skills_req_years.length > 5 ? (
                          <Grid container spacing={2}>
                            <Grid item xs>
                              <Typography
                                variant={"body1"}
                                style={{ fontWeight: 600 }}
                              >
                                {"Skills"}
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              style={{
                                width: "150px",
                              }}
                            >
                              <Typography
                                variant={"body1"}
                                style={{ fontWeight: 600 }}
                              >
                                {"Years"}
                              </Typography>
                            </Grid>
                          </Grid>
                        ) : null}
                        {values.skills_req_years &&
                          Array.isArray(values.skills_req_years)
                          ? values.skills_req_years.map((val, index) => {
                            let skillArr = [...values.skills_req_years];
                            skillArr.splice(index, 1);

                            return index > 4 ? (
                              <Grid
                                container
                                spacing={2}
                                style={{ paddingBottom: "16px" }}
                                alignItems={"flex-start"}
                              >
                                <Grid item xs>
                                  {/* <TextField
                                    value={val?.skill_name || ""}
                                    name={"skill_name"}
                                    variant={"outlined"}
                                    fullWidth
                                    onChange={(e) => {
                                      const { value } = e.target;
                                      let arr = [...values.critical_skills];
                                      arr[index]["name"] = value;
                                      let arr2 = [...values.skills_req_years];
                                      arr2[index]["skill_name"] = value;
                                      setFieldValue("critical_skills", arr);
                                      setFieldValue("skills_req_years", arr2);
                                    }}
                                    placeholder={"Skill"}
                                  /> */}
                                  <Autocomplete
                                    freeSolo
                                    options={skillList}
                                    value={val?.skill_name || ""}
                                    getOptionLabel={(option) => option}
                                    getOptionSelected={(option, value) =>
                                      option === value
                                    }
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        placeholder={"Skill"}
                                        variant={"outlined"}
                                        error={
                                          touched.skills_req_years?.[index]
                                            ?.skill_name &&
                                          Boolean(
                                            errors.skills_req_years?.[index]
                                              ?.skill_name
                                          )
                                        }
                                        helperText={
                                          touched.skills_req_years?.[index]
                                            ?.skill_name &&
                                          errors.skills_req_years?.[index]
                                            ?.skill_name
                                        }
                                      />
                                    )}
                                    onChange={(e, newVal) => {
                                      e.preventDefault();
                                      let arr = [...values.critical_skills];
                                      let arr2 = [...values.skills_req_years];
                                      let isExisting = skillArr.some(
                                        (option) =>
                                          option?.skill_name?.trim() ===
                                          newVal?.trim()
                                      );
                                      if (!isExisting) {
                                        arr[index]["name"] = newVal?.trim();
                                        arr2[index]["skill_name"] =
                                          newVal?.trim();
                                      } else {
                                        arr[index]["name"] = "";
                                        arr2[index]["skill_name"] = "";
                                      }
                                      setFieldValue("critical_skills", arr);
                                      setFieldValue("skills_req_years", arr2);
                                    }}
                                    filterOptions={(options, params) => {
                                      const filtered = filter(
                                        options,
                                        params
                                      );

                                      const { inputValue } = params;
                                      // Suggest the creation of a new value
                                      const isExisting = options.some(
                                        (option) => inputValue === option
                                      );

                                      if (inputValue !== "" && !isExisting) {
                                        filtered.push(inputValue);
                                      }

                                      return filtered;
                                    }}
                                    getOptionDisabled={(option) => {
                                      return skillArr.some(
                                        (option2) =>
                                          option2?.skill_name?.trim() ===
                                          option?.trim()
                                      );
                                    }}
                                    selectOnFocus
                                    clearOnBlur
                                    handleHomeEndKeys
                                  />
                                </Grid>
                                <Grid
                                  item
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "flex-end",
                                    width: "150px",
                                    gap: "8px",
                                  }}
                                >
                                  <FormControl
                                    fullWidth
                                    variant="outlined"
                                    error={
                                      touched.skills_req_years?.[index]
                                        ?.years &&
                                      Boolean(
                                        errors.skills_req_years?.[index]
                                          ?.years
                                      )
                                    }
                                  >
                                    <Select
                                      name={"years"}
                                      onChange={(e) => {
                                        const { value } = e.target;
                                        let arr = [
                                          ...values.skills_req_years,
                                        ];
                                        arr[index]["years"] = value;
                                        setFieldValue(
                                          "skills_req_years",
                                          arr
                                        );
                                      }}
                                      value={val?.years}
                                      fullWidth
                                    >
                                      {experienceOption.map(
                                        ({ name, value }) => (
                                          <MenuItem value={value}>
                                            {name}
                                          </MenuItem>
                                        )
                                      )}
                                    </Select>
                                  </FormControl>
                                  <IconButton
                                    color={"secondary"}
                                    onClick={() => {
                                      let arr = [...values.critical_skills];
                                      let arr2 = [...values.skills_req_years];
                                      arr.splice(index, 1);
                                      arr2.splice(index, 1);
                                      setFieldValue("critical_skills", arr);
                                      setFieldValue("skills_req_years", arr2);
                                    }}
                                    disabled={
                                      !(values.skills_req_years.length > 1)
                                    }
                                  >
                                    <Delete />
                                  </IconButton>
                                </Grid>
                              </Grid>
                            ) : null;
                          })
                          : null}
                        {values.skills_req_years?.length > 5 &&
                          values.skills_req_years?.length < 10 ? (
                          <Box textAlign={"right"}>
                            <Button
                              variant={"contained"}
                              color={"primary"}
                              onClick={() => {
                                let arr = [...values.critical_skills];
                                let arr2 = [...values.skills_req_years];
                                arr.push({ name: "" });
                                arr2.push({ skill_name: "", years: "" });
                                setFieldValue("critical_skills", arr);
                                setFieldValue("skills_req_years", arr2);
                              }}
                            >
                              Add
                            </Button>
                          </Box>
                        ) : null}
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  multiple
                  fullWidth
                  value={values.preferred}
                  id="tags-filled"
                  options={preferredCompanyList}
                  freeSolo
                  onChange={(event, newValue) => {
                    if (typeof newValue === "string") {
                      setFieldValue("preferred", newValue);
                    } else if (newValue && newValue.inputValue) {
                      // Create a new value from the user input
                      setFieldValue("preferred", newValue.inputValue);
                    } else {
                      setFieldValue("preferred", newValue);
                    }
                  }}
                  filterOptions={(options, params) => {
                    const filtered = filter(options, params);

                    // Suggest the creation of a new value
                    if (params.inputValue !== "") {
                      filtered.push({
                        inputValue: params.inputValue,
                        company_name: `Add "${params.inputValue}"`,
                      });
                    }

                    return filtered;
                  }}
                  getOptionLabel={(option) => {
                    // Value selected with enter, right from the input
                    if (typeof option === "string") {
                      return option;
                    }
                    // Add "xxx" option created dynamically
                    if (option.inputValue) {
                      return option.inputValue;
                    }
                    // Regular option
                    return option.company_name;
                  }}
                  renderOption={(option) => option.company_name}
                  selectOnFocus
                  clearOnBlur
                  handleHomeEndKeys
                  getOptionSelected={(option, value) => option.id === value.id}
                  filterSelectedOptions
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        variant="outlined"
                        label={
                          option.inputValue
                            ? option.inputValue
                            : option.company_name
                        }
                        {...getTagProps({ index })}
                      />
                    ))
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Preferred Company Experience"
                      inputProps={{
                        ...params.inputProps,
                        onKeyDown: (e) => {
                          if (e.key === "Enter") {
                            e.stopPropagation();
                          }
                        },
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  value={values.other_comments || ""}
                  name={"other_comments"}
                  variant={"outlined"}
                  label={"Other Comments"}
                  fullWidth
                  onChange={handleChange}
                  onBlur-={handleBlur}
                />
              </Grid>
              <Grid
                item
                xs={12}
                container
                alignItems="center"
                justifyContent="flex-end"
              >
                <Button
                  variant={"contained"}
                  color={"primary"}
                  type={"submit"}
                  onClick={handleSubmitErrorCheck}
                >
                  Submit
                </Button>
              </Grid>
              {alertBox.open && (
                <Grid item xs={12}>
                  <Alert severity={`${alertBox.success ? "success" : "error"}`}>
                    {alertBox.message}
                  </Alert>
                </Grid>
              )}
            </Grid>
          </Form>
        </FormikProvider>
      ) : (
        <Box pt={2}>
          <Alert severity={"error"}>
            <p className="text-base font-bold">
              {`The link will not be accessible other than the manager Login`}
            </p>
          </Alert>
        </Box>
      )}
    </Container>
  );
}

export default AddIntakeForm;
