import React, { forwardRef } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

// Import API
import axios from "axios";
import { createNewAccount_API } from "../../api/api";

const CreateAccount = (_, ref) => {
  // Ref
  const { createAccountModal, signInModal } = ref;

  // React forms
  // Define form schema
  const schema = yup.object().shape({
    first_name: yup.string().required().min(3).max(30),
    last_name: yup.string().required().min(1).max(30),
    email: yup
      .string()
      .email("Invalid email address")
      .required()
      .min(3)
      .max(30),
    username: yup.string().required().min(3).max(30).matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),
    company: yup.string(),  
    password: yup.string()
               .required()
               .matches(
                  /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
                  "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
                ),
    confirm_password: yup
      .string()
      .required()
      .oneOf([yup.ref("password"), null], "Passwords must match"),
  });

  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema),
  });

  // Create new account
  const onSubmit = (data) => {
    console.log(data);
    var datanew={
      first_name:data.first_name,
      last_name: data.last_name,
      email: data.email,
      username:data.username,
      company:data.company,
      password:data.password
    }
    // call endpoint
    axios
      .post(createNewAccount_API, datanew)
      .then((response) => {
        console.log(data);
      })
      .then(() => {
        // if success then take back to login
        createAccountModal.current.close();
        signInModal.current.open();
      })
      .catch(() => {
        console.log(data);
        // remove below
        createAccountModal.current.close();
        signInModal.current.open();
      });
  };

  return (
    <div className="container mx-auto p-2 h-full">
      <div className="flex content-center items-center justify-center h-full">
        <div className="w-full lg:w-4/12 px-4">
          <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-primary-color border-0">
            <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
              <div className="text-white text-center my-3 font-bold">
                <small>Create a new account</small>
              </div>
              <form onSubmit={handleSubmit(onSubmit)}>
              <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-white text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    First Name *
                  </label>
                  <input
                    type="text"
                    className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full"
                    placeholder="First Name"
                    name="first_name"
                    style={{ transition: "all .15s ease" }}
                    ref={register}
                  />
                  <p className="text-red-500">{errors.first_name?.message}</p>
                </div>
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-white text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Last Name *
                  </label>
                  <input
                    type="text"
                    className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full"
                    placeholder="Last Name"
                    name="last_name"
                    style={{ transition: "all .15s ease" }}
                    ref={register}
                  />
                  <p className="text-red-500">{errors.last_name?.message}</p>
                </div>
               
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-white text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Email *
                  </label>
                  <input
                    type="email"
                    className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full"
                    placeholder="Email"
                    name="email"
                    style={{ transition: "all .15s ease" }}
                    ref={register}
                  />
                  <p className="text-red-500">{errors.email?.message}</p>
                </div>

                 
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-white text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Username *
                  </label>
                  <input
                    type="text"
                    className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full"
                    placeholder="Username"
                    name="username"
                    style={{ transition: "all .15s ease" }}
                    ref={register}
                  />
                  <p className="text-red-500">{errors.username?.message}</p>
                </div>

                 
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-white text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Company
                  </label>
                  <input
                    type="text"
                    className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full"
                    placeholder="Company"
                    name="company"
                    style={{ transition: "all .15s ease" }}
                    ref={register}
                  />
                  <p className="text-red-500">{errors.company?.message}</p>
                </div>

                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-white text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Password *
                  </label>
                  <input
                    type="password"
                    className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full"
                    placeholder="Password"
                    name="password"
                    style={{ transition: "all .15s ease" }}
                    ref={register}
                  />
                  <p className="text-red-500">{errors.password?.message}</p>
                </div>
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-white text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Confirm Password *
                  </label>
                  <input
                    type="password"
                    className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full"
                    placeholder="Confirm Password"
                    name="confirm_password"
                    style={{ transition: "all .15s ease" }}
                    ref={register}
                  />
                  <p className="text-red-500">
                    {errors.confirm_password?.message}
                  </p>
                </div>
                <div className="text-center mt-6">
                  <button
                    className="bg-secondary-color text-white active:bg-gray-700 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full"
                    type="submit"
                    style={{ transition: "all .15s ease" }}
                  >
                    Register
                  </button>
                </div>
              </form>

              <div className="flex flex-wrap mt-4">
                <div className="w-1/2">
                  <a
                    href="/#"
                    onClick={(e) => {
                      e.preventDefault();
                      createAccountModal.current.close();
                      signInModal.current.open();
                    }}
                    className="text-white"
                  >
                    <small> &#8592; Back to login</small>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default forwardRef(CreateAccount);
