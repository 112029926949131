import { Box, Container, Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import SimpleTabs from "../../commonComponents/Tabs/SimpleTabs";
import { navigateTo } from "../../utils/constants/routePaths";
import Settings from "./Settings";
import ScoreSettings from "./ScoreSettings";
import UserSetting from "./UserSettings";
import { ROLES } from "../../utils/constants/roleBasedAccess";
import PageLoader from '../../commonComponents/PageLoader'

const SETTINGS_TYPE = {
  mail: "mail",
  score: "score",
  user: "user"
};

const SETTINGS = [
  {
    label: "Mail Settings",
    value: SETTINGS_TYPE.mail,
    id: SETTINGS_TYPE.mail,
  },
  {
    label: "Score Settings",
    value: SETTINGS_TYPE.score,
    id: SETTINGS_TYPE.score,
  },
  {
    label: "User Settings",
    value: SETTINGS_TYPE.user,
    id: SETTINGS_TYPE.user,
  },
];

function Index() {
  const history = useHistory();
  const { type } = useParams();
  const role = localStorage.getItem("role");
  const isAdmin = role === ROLES.admin
  const [tabOptions, setTabOptions] = useState([SETTINGS[0]])
  const [loading, setloading] = useState(true)

  useEffect(() => {
    if (!type) {
      history.replace(navigateTo.settings(SETTINGS_TYPE.mail));
    }
    if (isAdmin) {
      setTabOptions(SETTINGS)
    }

    setloading(false)

  }, []);

  const handleTabChange = (_, newValue) => {
    history.replace(navigateTo.settings(newValue));
  };

  const tabValue = type || SETTINGS_TYPE.mail;

  const renderComponent = () => {
    switch (tabValue) {
      case SETTINGS_TYPE.score:
        return <ScoreSettings />;
      case SETTINGS_TYPE.user:
        return <UserSetting />;
      default:
        return <Settings />;
    }
  };

  return loading ? <PageLoader /> :
    <Container maxWidth={"md"} className={"mt-8"}>
      <Grid container spacing={2}>
        {isAdmin ?
          <Grid item xs={12}>
            <SimpleTabs
              value={tabValue}
              tabList={tabOptions}
              onChange={handleTabChange}
              indicatorColor={"primary"}
              textColor={"primary"}
            />
          </Grid> : null}
        <Grid item xs={12}>
          <Box py={1.5}>{renderComponent()}</Box>
        </Grid>
      </Grid>
    </Container>
}

export default Index;
