import { Box } from "@material-ui/core";
import ReactPDF, {
  Document,
  Image,
  Page,
  PDFViewer,
  StyleSheet,
  Text,
  View,
  Font,
} from "@react-pdf/renderer";
import React, { useEffect } from "react";
import titainLogo from "../../../images/logos/titan.png";
import { hyphenationCallback } from "../../../utils/constants/helpers";

const viewPdf = false;

Font.registerHyphenationCallback(hyphenationCallback);

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#ffffff",
    padding: "15px 30px",
    justifyContent: "flex-start",
  },
  headerContainer: {
    padding: "10px 0 10px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "row",
  },
  flexView: (gap = "0px") => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "row",
    gap: gap,
  }),
  captionText: {
    fontSize: "12px",
    color: "rgb(37, 42, 48)",
  },
  captionValueText: {
    fontSize: "12px",
    color: "rgb(37, 42, 48)",
    fontFamily: "Helvetica-Bold",
  },
  headerRightTextContainer: {
    display: "flex",
    alignItems: "flex-end",
    flexDirection: "column",
    justifyContent: "center",
    gap: "12px",
  },
  logo: {
    width: "36px !important",
    height: "42px !important",
  },
  image: {
    width: "100%",
    height: "auto",
  },
  divider: {
    borderBottom: "1px solid #e5e7eb",
  },
  headerText: {
    color: "#000000",
    fontSize: "16px",
  },
  firstSection: {
    padding: "20px 0",
  },
  firstSectionWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    paddingBottom: "10px",
  },
  firstSectionText: {
    paddingRight: "10px",
    fontSize: "14px",
    color: "rgb(37, 42, 48)",
  },
  firstSectionHighlightText: {
    paddingRight: "10px",
    fontSize: "14px",
    color: "rgb(37, 42, 48)",
    fontFamily: "Helvetica-Bold",
  },
  firstSectionValueText: {
    fontSize: "14px",
    fontFamily: "Helvetica-Bold",
    color: "rgb(39, 57, 74)",
  },
  subSection: {
    padding: "10px 0",
  },
  subSectionTitle: {
    fontSize: "18px",
    fontFamily: "Helvetica-Bold",
    color: "rgb(39, 57, 74)",
    paddingBottom: "10px",
  },
  subTitle: {
    fontSize: "16px",
    fontFamily: "Helvetica-Bold",
    color: "rgb(39, 57, 74)",
  },
  subSectionContainer: {
    padding: "20px 0 0",
  },
  subSectionWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    paddingBottom: "10px",
  },
  subSectionText: {
    paddingRight: "10px",
    fontSize: "14px",
    color: "rgb(37, 42, 48)",
  },
  subSectionValueText: {
    fontSize: "14px",
    fontFamily: "Helvetica-Bold",
    color: "rgb(39, 57, 74)",
  },
  tableStyle: {
    padding: "20px 0 10px",
  },
  tableFlex: {
    minWidth: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  tableHeadCell1: {
    width: "40%",
    padding: "8px 10px",
    backgroundColor: "rgb(226, 237, 252)",
    minHeight: "32px",
  },
  tableHeadCell2: {
    width: "30%",
    padding: "8px 10px",
    backgroundColor: "rgb(226, 237, 252)",
    minHeight: "32px",
  },
  tableHeadCell3: {
    width: "30%",
    padding: "8px 10px",
    backgroundColor: "rgb(226, 237, 252)",
    minHeight: "32px",
  },
  tableHeadCellFlexible: {
    padding: "8px 10px",
    backgroundColor: "rgb(226, 237, 252)",
    minHeight: "32px",
    flex: 1,
  },
  tableCustomHeadCell: (width) => ({
    width: width,
    padding: "8px 10px",
    backgroundColor: "rgb(226, 237, 252)",
    minHeight: "32px",
  }),
  tableHeadCellText: {
    fontSize: "12px",
    fontFamily: "Helvetica-Bold",
    color: "rgb(39, 57, 74)",
    textAlign: "left",
  },
  tableCell1: {
    width: "40%",
    padding: "8px 10px",
    borderBottom: "1px solid #e5e7eb",
    borderLeft: "1px solid #e5e7eb",
  },
  tableCell2: {
    width: "30%",
    padding: "8px 10px",
    borderBottom: "1px solid #e5e7eb",
    borderLeft: "1px solid #e5e7eb",
  },
  tableCustomCell: (width) => ({
    width: width,
    padding: "8px 10px",
    borderBottom: "1px solid #e5e7eb",
    borderLeft: "1px solid #e5e7eb",
  }),
  tableCell3: {
    width: "30%",
    padding: "8px 10px",
    borderBottom: "1px solid #e5e7eb",
    borderLeft: "1px solid #e5e7eb",
    borderRight: "1px solid #e5e7eb",
  },
  tableCellFlexible: {
    flex: 1,
    padding: "8px 10px",
    borderBottom: "1px solid #e5e7eb",
    borderLeft: "1px solid #e5e7eb",
    borderRight: "1px solid #e5e7eb",
  },
  tableCellText: {
    fontSize: "12px",
    textAlign: "left",
    fontFamily: "Helvetica",
    color: "rgb(37, 42, 48)",
  },
  tableBodySection: {
    minWidth: "100%",
    display: "flex",
    flexDirection: "row",
  },
  tableBodyFirstSectionCells: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
  },
  tableBodyFirstSectionCell: {
    width: "100%",
    padding: "8px 10px",
    borderBottom: "1px solid #e5e7eb",
    borderLeft: "1px solid #e5e7eb",
  },
  tableBodyFirstSectionCustomCell: (width) => ({
    width: width,
    padding: "8px 10px",
    borderBottom: "1px solid #e5e7eb",
    borderLeft: "1px solid #e5e7eb",
    height: "100%",
  }),
  tableBodySecondSectionCell: {
    minWidth: "30%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    height: "100%",
    borderBottom: "1px solid #e5e7eb",
    borderLeft: "1px solid #e5e7eb",
    borderRight: "1px solid #e5e7eb",
  },
  tableBodySecondSectionCustomCell: (width) => ({
    minWidth: width,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    height: "100%",
    borderBottom: "1px solid #e5e7eb",
    borderLeft: "1px solid #e5e7eb",
    borderRight: "1px solid #e5e7eb",
  }),
  tableBodySecondSectionSubCell: {
    padding: "8px 10px",
  },
});

// Create Document Component
const MyDocument = ({ skills_display, finalSkills, jobCode }) => {
  const applicantName = skills_display.full_name || "";
  const bonusScore = skills_display?.bonus_score || 0;
  const soft_skills = skills_display?.soft_skill?.[0]?.["soft_skills"];
  const coding_text = skills_display?.soft_skill?.[1]?.["coding_text"]?.trim();

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        {/* Header */}
        <View>
          <View style={styles.headerContainer}>
            <Image style={styles.logo} src={titainLogo} />
            <View style={styles.headerRightTextContainer}>
              <Text style={styles.headerText}>{applicantName}</Text>
              <View style={styles.flexView("5px")}>
                <Text style={styles.captionText}>{"Job ID:"}</Text>
                <Text style={styles.captionValueText}>{jobCode}</Text>
              </View>
            </View>
          </View>
        </View>
        <View style={styles.divider} />

        {/* OVERALL SCORE */}
        <View style={styles.firstSection}>
          <Text style={styles.subSectionTitle}>{"OVERALL SCORE"}</Text>
          <View style={styles.divider} />

          <View style={styles.divider} />
          <View style={styles.subSectionContainer}>
            <View style={styles.subSectionWrapper}>
              <Text style={styles.subSectionText}>{"Resume Score:"}</Text>
              <Text style={styles.subSectionValueText}>
                {skills_display?.["resume_score(91/100)"] || 0}
              </Text>
            </View>
          </View>
          <View style={styles.firstSectionWrapper}>
            <Text style={styles.firstSectionText}>
              {"Preferred Company Score:"}
            </Text>
            <Text style={styles.firstSectionValueText}>
              {skills_display?.["preferred_company_weightage(2/100)"] || 0}
            </Text>
          </View>
          <View style={styles.firstSectionWrapper}>
            <Text style={styles.firstSectionText}>{"Bonus Score:"}</Text>
            <Text style={styles.firstSectionValueText}>{bonusScore}</Text>
          </View>
          <View style={styles.firstSectionWrapper}>
            <Text style={styles.firstSectionHighlightText}>
              {"Overall Score:"}
            </Text>
            <Text style={styles.firstSectionValueText}>
              {skills_display?.["total_score(100/100)"] || 0}
            </Text>
          </View>
        </View>

        {/* SCORE ANALYSIS */}
        <View style={styles.subSection}>
          <Text style={styles.subSectionTitle}>{"SCORE ANALYSIS"}</Text>
          <View style={styles.divider} />
          <View style={styles.subSectionContainer}>
            <View style={styles.subSectionWrapper}>
              <Text style={styles.subSectionText}>{"Resume Score:"}</Text>
              <Text style={styles.subSectionValueText}>
                {skills_display?.["resume_score(91/100)"] || 0}
              </Text>
            </View>
          </View>
        </View>

        {/* Technical Skills */}
        <View style={styles.subSection}>
          <Text style={styles.subTitle}>{"Technical Skills:"}</Text>
          <View style={styles.tableStyle}>
            <View style={styles.tableFlex}>
              <View style={styles.tableHeadCell1}>
                <Text style={styles.tableHeadCellText}>{"Skill"}</Text>
              </View>
              <View style={styles.tableCustomHeadCell("18%")}>
                <Text style={styles.tableHeadCellText}>{"Yr./M"}</Text>
              </View>
              <View style={styles.tableCustomHeadCell("18%")}>
                <Text style={styles.tableHeadCellText}>{"Skill Count"}</Text>
              </View>
              <View style={styles.tableHeadCellFlexible}>
                <Text style={styles.tableHeadCellText}>{"Assigned Score"}</Text>
              </View>
            </View>
            {finalSkills?.length ? (
              finalSkills.map(
                ({ time, skill_name, Calculated_Weightage_score, count }) => (
                  <View style={styles.tableFlex}>
                    <View style={styles.tableCell1}>
                      <Text style={styles.tableCellText}>{skill_name}</Text>
                    </View>
                    <View style={styles.tableCustomCell("18%")}>
                      <Text style={styles.tableCellText}>
                        {time?.[1] === "-"
                          ? time?.[0] ?? "-"
                          : time?.join(", ") || "-"}
                      </Text>
                    </View>
                    <View style={styles.tableCustomCell("18%")}>
                      <Text style={styles.tableCellText}>{count ?? "-"}</Text>
                    </View>
                    <View style={styles.tableCellFlexible}>
                      <Text style={styles.tableCellText}>
                        {Calculated_Weightage_score ?? "-"}
                      </Text>
                    </View>
                  </View>
                )
              )
            ) : (
              <View style={styles.tableFlex}>
                <View style={styles.tableCell1}>
                  <Text style={styles.tableCellText}>{"-"}</Text>
                </View>
                <View style={styles.tableCustomCell("18%")}>
                  <Text style={styles.tableCellText}>{"-"}</Text>
                </View>
                <View style={styles.tableCustomCell("18%")}>
                  <Text style={styles.tableCellText}>{"-"}</Text>
                </View>
                <View style={styles.tableCellFlexible}>
                  <Text style={styles.tableCellText}>{"-"}</Text>
                </View>
              </View>
            )}
          </View>
        </View>

        {/* Preferred Company Score */}
        <View style={styles.subSection}>
          <Text style={styles.subSectionTitle}>
            {"Preferred Company Score"}
          </Text>
          <View style={styles.divider} />
          <View style={styles.tableStyle}>
            <View style={styles.tableFlex}>
              <View style={styles.tableHeadCell1}>
                <Text style={styles.tableHeadCellText}>{"Company/Client"}</Text>
              </View>
              <View style={styles.tableHeadCell2}>
                <Text style={styles.tableHeadCellText}>{"Domain"}</Text>
              </View>
              <View style={styles.tableHeadCell3}>
                <Text style={styles.tableHeadCellText}>{"Yr./M"}</Text>
              </View>
            </View>
            {skills_display?.preferrred_company?.length ? (
              skills_display.preferrred_company.map(
                ({ domain, total_years, company_name }) => (
                  <View style={styles.tableFlex}>
                    <View style={styles.tableCell1}>
                      <Text style={styles.tableCellText}>
                        {company_name || "-"}
                      </Text>
                    </View>
                    <View style={styles.tableCell2}>
                      <Text style={styles.tableCellText}>{domain || "-"}</Text>
                    </View>
                    <View style={styles.tableCell3}>
                      <Text style={styles.tableCellText}>
                        {total_years || "-"}
                      </Text>
                    </View>
                  </View>
                )
              )
            ) : (
              <View style={styles.tableFlex}>
                <View style={styles.tableCell1}>
                  <Text style={styles.tableCellText}>{"-"}</Text>
                </View>
                <View style={styles.tableCell2}>
                  <Text style={styles.tableCellText}>{"-"}</Text>
                </View>
                <View style={styles.tableCell3}>
                  <Text style={styles.tableCellText}>{"-"}</Text>
                </View>
              </View>
            )}
          </View>
        </View>

        {/* Bonus Score */}
        <View style={styles.subSection}>
          <Text style={styles.subSectionTitle}>{"Bonus Score"}</Text>
          <View style={styles.divider} />
        </View>

        {/* Education */}
        <View style={styles.subSection}>
          <Text style={styles.subTitle}>{"Education:"}</Text>
          <View style={styles.tableStyle}>
            <View style={styles.tableFlex}>
              <View style={styles.tableHeadCell1}>
                <Text style={styles.tableHeadCellText}>{"Degree"}</Text>
              </View>
              <View style={styles.tableCustomHeadCell("38%")}>
                <Text style={styles.tableHeadCellText}>{"University"}</Text>
              </View>
              <View style={styles.tableHeadCellFlexible}>
                <Text style={styles.tableHeadCellText}>{"Assigned Score"}</Text>
              </View>
            </View>
            {skills_display?.education?.length ? (
              <View style={styles.tableBodySection}>
                <View style={styles.tableBodyFirstSectionCells}>
                  {skills_display.education.map(
                    ({ major, degree, university, year_of_passinig }) => (
                      <View style={styles.tableFlex}>
                        <View
                          style={styles.tableBodyFirstSectionCustomCell("51%")}
                        >
                          <Text style={styles.tableCellText}>
                            {degree || "-"}
                          </Text>
                        </View>
                        <View
                          style={styles.tableBodyFirstSectionCustomCell("49%")}
                        >
                          <Text style={styles.tableCellText}>
                            {university || "-"}
                          </Text>
                        </View>
                      </View>
                    )
                  )}
                </View>
                <View style={styles.tableBodySecondSectionCustomCell("22%")}>
                  <View style={styles.tableBodySecondSectionSubCell}>
                    <Text style={styles.tableCellText}>
                      {skills_display?.["education_weightage(2/100)"] ?? "-"}
                    </Text>
                  </View>
                </View>
              </View>
            ) : (
              <View style={styles.tableBodySection}>
                <View style={styles.tableBodyFirstSectionCells}>
                  <View style={styles.tableFlex}>
                    <View style={styles.tableBodyFirstSectionCustomCell("51%")}>
                      <Text style={styles.tableCellText}>{"-"}</Text>
                    </View>
                    <View style={styles.tableBodyFirstSectionCustomCell("49%")}>
                      <Text style={styles.tableCellText}>{"-"}</Text>
                    </View>
                  </View>
                </View>
                <View style={styles.tableBodySecondSectionCustomCell("22%")}>
                  <View style={styles.tableBodySecondSectionSubCell}>
                    <Text style={styles.tableCellText}>{"-"}</Text>
                  </View>
                </View>
              </View>
            )}
          </View>
        </View>

        {/* Certification */}
        <View style={styles.subSection}>
          <Text style={styles.subTitle}>{"Certifications:"}</Text>
          <View style={styles.tableStyle}>
            <View style={styles.tableFlex}>
              <View style={styles.tableHeadCell1}>
                <Text style={styles.tableHeadCellText}>
                  {"Certification Name"}
                </Text>
              </View>
              <View style={styles.tableHeadCell2}>
                <Text style={styles.tableHeadCellText}>
                  {"Year of Passing"}
                </Text>
              </View>
              <View style={styles.tableHeadCell3}>
                <Text style={styles.tableHeadCellText}>{"Assigned Score"}</Text>
              </View>
            </View>
            {skills_display?.certification?.length ? (
              <View style={styles.tableBodySection}>
                <View style={styles.tableBodyFirstSectionCells}>
                  {skills_display.certification.map(
                    ({ name, year_of_passinig }) => (
                      <View style={styles.tableFlex}>
                        <View
                          style={styles.tableBodyFirstSectionCustomCell("57%")}
                        >
                          <Text style={styles.tableCellText}>
                            {name || "-"}
                          </Text>
                        </View>
                        <View
                          style={styles.tableBodyFirstSectionCustomCell("43%")}
                        >
                          <Text style={styles.tableCellText}>
                            {year_of_passinig || "-"}
                          </Text>
                        </View>
                      </View>
                    )
                  )}
                </View>
                <View style={styles.tableBodySecondSectionCell}>
                  <View style={styles.tableBodySecondSectionSubCell}>
                    <Text style={styles.tableCellText}>
                      {skills_display?.["certification_weightage(1/100)"] ??
                        "-"}
                    </Text>
                  </View>
                </View>
              </View>
            ) : (
              <View style={styles.tableBodySection}>
                <View style={styles.tableBodyFirstSectionCells}>
                  <View style={styles.tableFlex}>
                    <View style={styles.tableBodyFirstSectionCustomCell("57%")}>
                      <Text style={styles.tableCellText}>{"-"}</Text>
                    </View>
                    <View style={styles.tableBodyFirstSectionCustomCell("43%")}>
                      <Text style={styles.tableCellText}>{"-"}</Text>
                    </View>
                  </View>
                </View>
                <View style={styles.tableBodySecondSectionCell}>
                  <View style={styles.tableBodySecondSectionSubCell}>
                    <Text style={styles.tableCellText}>{"-"}</Text>
                  </View>
                </View>
              </View>
            )}
          </View>
        </View>

        {/* Domain Experience */}
        <View style={styles.subSection}>
          <Text style={styles.subTitle}>{"Domain Experience:"}</Text>
          <View style={styles.tableStyle}>
            <View style={styles.tableFlex}>
              <View style={styles.tableHeadCellFlexible}>
                <Text style={styles.tableHeadCellText}>{"Domain Name"}</Text>
              </View>
              <View style={styles.tableHeadCell3}>
                <Text style={styles.tableHeadCellText}>{"Assigned Score"}</Text>
              </View>
            </View>
            {/* Table body */}
            {skills_display?.domain?.length ? (
              <View style={styles.tableBodySection}>
                <View style={styles.tableBodyFirstSectionCells}>
                  {skills_display.domain.map((el) => (
                    <View style={styles.tableFlex}>
                      <View style={styles.tableBodyFirstSectionCell}>
                        <Text style={styles.tableCellText}>{el || "-"}</Text>
                      </View>
                    </View>
                  ))}
                </View>
                <View style={styles.tableBodySecondSectionCell}>
                  <View style={styles.tableBodySecondSectionSubCell}>
                    <Text style={styles.tableCellText}>
                      {skills_display?.["domain_weightage(2/100)"] ?? "-"}
                    </Text>
                  </View>
                </View>
              </View>
            ) : (
              <View style={styles.tableBodySection}>
                <View style={styles.tableBodyFirstSectionCells}>
                  <View style={styles.tableFlex}>
                    <View style={styles.tableBodyFirstSectionCell}>
                      <Text style={styles.tableCellText}>{"-"}</Text>
                    </View>
                  </View>
                </View>
                <View style={styles.tableBodySecondSectionCell}>
                  <View style={styles.tableBodySecondSectionSubCell}>
                    <Text style={styles.tableCellText}>{"-"}</Text>
                  </View>
                </View>
              </View>
            )}
          </View>
        </View>

        {/* Soft Skill */}
        <View style={styles.subSection}>
          <Text style={styles.subTitle}>{"Soft Skills:"}</Text>
          <View style={styles.tableStyle}>
            <View style={styles.tableFlex}>
              <View style={styles.tableHeadCellFlexible}>
                <Text style={styles.tableHeadCellText}>{"Skill Name"}</Text>
              </View>
              <View style={styles.tableHeadCell3}>
                <Text style={styles.tableHeadCellText}>{"Assigned Score"}</Text>
              </View>
            </View>
            {/* Table body */}
            <View style={styles.tableBodySection}>
              <View style={styles.tableBodyFirstSectionCells}>
                {soft_skills?.length || !coding_text?.length ? (
                  <View style={styles.tableFlex}>
                    <View style={styles.tableBodyFirstSectionCell}>
                      <Text style={styles.tableCellText}>
                        {soft_skills?.length ? soft_skills.join(", ") : "-"}
                      </Text>
                    </View>
                  </View>
                ) : null}
                {coding_text?.length ? (
                  <View style={styles.tableFlex}>
                    <View style={styles.tableBodyFirstSectionCell}>
                      <Text style={styles.tableCellText}>{coding_text}</Text>
                    </View>
                  </View>
                ) : null}
              </View>
              <View style={styles.tableBodySecondSectionCell}>
                <View style={styles.tableBodySecondSectionSubCell}>
                  <Text style={styles.tableCellText}>
                    {skills_display?.["soft_skill_weightage(2/100)"] ?? "-"}
                  </Text>
                </View>
              </View>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

function QualifySkillMatchReport({ data, setData = () => {}, jobCode }) {
  const skills_display = data?.result?.skill?.skills_display?.[0] || {};

  // Preferred Skills
  let preferredSkills = data?.search_criteria?.skill?.values;
  if (!Array.isArray(preferredSkills)) preferredSkills = [];
  // Applicant Skills
  let skills = skills_display?.skills_display;
  if (!Array.isArray(skills)) skills = [];

  // Combine of preferred & applicant skills
  let finalSkills = [];
  if (preferredSkills.length) {
    for (let i = 0; i < preferredSkills.length; i++) {
      let skillObj = skills.find((el) => el.skill_name === preferredSkills[i]);
      let obj = {
        skill_name: null,
        time: null,
        Calculated_Weightage_score: null,
      };
      if (skillObj) {
        obj = {
          ...obj,
          ...skillObj,
        };
      } else {
        obj.skill_name = preferredSkills[i];
      }
      finalSkills.push(obj);
    }
  }

  let full_name = skills_display.full_name?.split(" ")?.join("_");
  const file_name = `Qualify_Skill_Match_Report_${full_name} (${jobCode}).pdf`;

  const download = () => {
    new Promise(async (resolve, reject) => {
      const blob = await ReactPDF.pdf(
        <MyDocument
          skills_display={skills_display}
          finalSkills={finalSkills}
          jobCode={jobCode}
        />
      ).toBlob();
      const url = URL.createObjectURL(blob);
      if (url && url.length > 0) {
        resolve(url);
      }
    })
      .then((generatedUrl) => {
        if (generatedUrl) {
          let aTag = document.createElement("a");
          aTag.href = generatedUrl;
          aTag.style = "display: none";
          aTag.download = file_name;
          document.body.appendChild(aTag);
          aTag.click();
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    if (data) {
      download();
      setData(null);
    }
  }, [data]);

  return viewPdf ? (
    <Box position={"fixed"} top={0} left={0} right={0} bottom={0}>
      <PDFViewer width={"100%"} height={"700px"}>
        <MyDocument
          skills_display={skills_display}
          finalSkills={finalSkills}
          jobCode={jobCode}
        />
      </PDFViewer>
    </Box>
  ) : null;
}

export default QualifySkillMatchReport;
