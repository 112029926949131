import React, { useEffect, useRef } from "react";
import "./style.css";
import * as XLSX from "xlsx";

const ResumeHeaderReport = ({
  skillMatchSkillObj,
  resumeNames,
  skillValues,
  isSkillClicked,
  weightage = [],
  hide = false,
  title = "Skill Match Report",
  needCount = true,
}) => {
  const tableRef = useRef(null);

  const exportToExcel = () => {
    const table = tableRef.current;
    const worksheet = XLSX.utils.table_to_sheet(table);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, `${title}.xlsx`);
  };

  useEffect(() => {
    if (isSkillClicked) {
      exportToExcel();
    }
  }, [isSkillClicked]);

  console.log(skillValues, "skillValues++++++++++++");
  console.log(skillMatchSkillObj, "skillMatchSkillObj++++++++++");
  return (
    <div className={`fixedTableHeadSkill ${hide ? "hidden" : ""}`}>
      <table ref={tableRef} className="skillTable">
        <thead>
          <tr>
            <th className="stickyHead elev"> </th>
            <th className="stickyHead elev"> </th>

            {resumeNames.map((skill, index) => (
              <th key={index} className="boldPara stickyHead" colSpan="2">
                {skill}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {weightage.length !== 0
            ? weightage.map((el) => {
                return (
                  <tr>
                    <td className="boldPara">{el.title}</td>
                    <td></td>
                    {el.data.map((elem) => {
                      return <td colSpan={"2"}>{elem}</td>;
                    })}
                  </tr>
                );
              })
            : null}
          {skillValues.map((matchSkill, index) => (
            <>
              <tr className={index % 2 !== 0 ? "stripedBlue" : ""}>
                <th
                  className="boldPara tableRightBorder tableBottomBorder center-align"
                  rowSpan={needCount ? "3" : "2"}
                >
                  {matchSkill}{" "}
                </th>
                {/* <td className='boldPara'> Skill </td> */}
                {Object.entries(skillMatchSkillObj[matchSkill]).map(
                  ([resumeName, resumeValue], ind) =>
                    resumeValue.length !== 0 ? (
                      <>
                        {ind === 0 && (
                          <td className="boldPara noHide tableRightBorder">
                            Skill :{" "}
                          </td>
                        )}

                        <td className="tableRightBorder" colSpan={"2"}>
                          {resumeValue[0].skill_name}
                        </td>
                      </>
                    ) : resumeValue.length === 0 && ind === 0 ? (
                      <>
                        <td className="boldPara noHide tableRightBorder">
                          Skill :{" "}
                        </td>
                        <td
                          rowSpan={needCount ? "3" : "2"}
                          colSpan="2"
                          className="tableRightBorder  tableBottomBorder center-align"
                        >
                          <p className="errorColor boldPara dash">-</p>
                        </td>
                      </>
                    ) : (
                      <td
                        rowSpan={needCount ? "3" : "2"}
                        colSpan="2"
                        className="tableRightBorder  tableBottomBorder center-align"
                      >
                        <p className="errorColor boldPara dash">-</p>
                      </td>
                    )
                )}
              </tr>
              {needCount ? (
                <tr className={index % 2 !== 0 ? "stripedBlue" : ""}>
                  {Object.entries(skillMatchSkillObj[matchSkill]).map(
                    ([resumeName, resumeValue], ind) =>
                      resumeValue.length !== 0 ? (
                        <>
                          {ind === 0 && (
                            <td className="boldPara noHide tableRightBorder">
                              Count :{" "}
                            </td>
                          )}
                          <td className="tableRightBorder" colSpan={"2"}>
                            {resumeValue[0].count}
                          </td>
                        </>
                      ) : resumeValue.length === 0 && ind === 0 ? (
                        <>
                          <td className="boldPara noHide tableRightBorder">
                            Count :{" "}
                          </td>
                        </>
                      ) : null
                  )}
                </tr>
              ) : (
                <></>
              )}
              <tr
                className={
                  index % 2 !== 0
                    ? "stripedBlue tableBottomBorder"
                    : "tableBottomBorder"
                }
              >
                {Object.entries(skillMatchSkillObj[matchSkill]).map(
                  ([resumeName, resumeValue], ind) =>
                    resumeValue.length !== 0 ? (
                      <>
                        {ind === 0 && (
                          <td className="boldPara noHide tableRightBorder">
                            {" "}
                            Duration:
                          </td>
                        )}
                        <td className="tableRightBorder" colSpan={"2"}>
                          {resumeValue[0].time
                            .toString()
                            .replace(",", " , ")
                            .replace("yr(s)", "years")
                            .replace("month(s)", "months")}
                        </td>
                      </>
                    ) : resumeValue.length === 0 && ind === 0 ? (
                      <>
                        <td className="boldPara noHide tableRightBorder">
                          Duration :{" "}
                        </td>
                      </>
                    ) : null
                )}
              </tr>
            </>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ResumeHeaderReport;
