//////////////////////////////////////////////////////////////////////////////////////////
//                        Action for login
//////////////////////////////////////////////////////////////////////////////////////////

export function userLoginStatus(val) {
  return {
    type: "LOGIN_STATUS",
    payload: val,
  };
}
