import { Grid, TextField } from "@material-ui/core";
import React from "react";
import SimpleModal from "./Modal/SimpleModal";

function ShareJobToCandidate({
  open,
  candidateEmail,
  handleClose,
  handleSubmit,
  handleEmailChange,
  loading,
}) {
  return (
    <SimpleModal
      title={"Share Job To Candidate"}
      open={open}
      saveButtonText={"Submit"}
      handleClose={handleClose}
      handleSave={handleSubmit}
      maxWidth={"sm"}
      loading={loading}
    >
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextField
            id={"candidate-email"}
            label={"Candidate Email"}
            value={candidateEmail}
            size={"medium"}
            variant={"outlined"}
            onChange={handleEmailChange}
            required
            fullWidth
          />
        </Grid>
        <Grid item xs />
      </Grid>
    </SimpleModal>
  );
}

export default ShareJobToCandidate;
