import { TextField } from "@material-ui/core";
import React from "react";

function InputField({
  id,
  label,
  name,
  value,
  onChange = () => {},
  error,
  helperText,
  placeholder,
  variant = "outlined",
  size = "small",
  ...props
}) {
  return (
    <TextField
      id={id}
      label={label}
      name={name}
      value={value}
      onChange={onChange}
      variant={variant}
      error={error}
      helperText={helperText}
      placeholder={placeholder}
      size={size}
      fullWidth
      {...props}
    />
  );
}

export default InputField;
