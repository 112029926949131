import { Box, Button, Typography } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import axios from "axios";
import { Form, FormikProvider, useFormik } from "formik";
import React, { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { useHistory } from "react-router-dom";
import * as yup from "yup";
import {
  getCoverSheetFormQuestionAnswer,
  getCoverSheetQuestionAnswer,
  postCoverSheetFormQuestionAnswer,
  postResumeByJobCodeApplicantId,
} from "../../api/api";
import DropzoneComponent from "../../commonComponents/DropzoneComponent";
import LoadingButton from "../../commonComponents/LoadingButton";
import CustomSnack from "../../commonComponents/Snack/CustomSnack";
import { MESSAGES } from "../../utils/constants/constants";
import {
  convertTZ,
  formatDate,
  formattedDateStringToDate,
  requiredSchema,
} from "../../utils/constants/helpers";
import { ADMINS } from "../../utils/constants/roleBasedAccess";
import { navigateTo } from "../../utils/constants/routePaths";
import "../TestEvent/style.css";
import AdditionalDetails from "./CoverSheetComponents/AdditionalDetails";
import EducationAndCertification from "./CoverSheetComponents/EducationAndCertification";
import EmployeeHistory from "./CoverSheetComponents/EmployeeHistory";
import HobbiesAndExtraCurricularActivities from "./CoverSheetComponents/HobbiesAndExtraCurricularActivities";
import PersonalDetails from "./CoverSheetComponents/PersonalDetails";
import SkillSummary from "./CoverSheetComponents/SkillSummary";
import StepperComponent from "./CoverSheetComponents/StepperComponent";

const singleProjectDetailsObj = {
  client: "",
  location: "",
  domain: "",
  duration: "",
  durationStartDate: "",
  durationEndDate: "",
  project_experience: "",
  responsibilities: "",
};

const skillObj = {
  skill: "",
  year: "",
  month: "",
};

const projectDetailsObj = {
  how_many_projects: "1",
  project_details: [singleProjectDetailsObj],
};

const educationObj = {
  graduation: "",
  degree: "",
  major: "",
  university: "",
  year_of_passing: "",
  cgpa: "",
};

const certificationObj = {
  name: "",
  year_of_passing: "",
};

const empObj = {
  company_name: "",
  job_title: "",
  duration_from: "",
  duration_to: "",
};

const personalDetails = {
  first_name: "",
  last_name: "",
  email: "",
  phone: "",
  linkedin_profile: "",
};

const hobbies = {
  coding_test: { answer: "", explain: "" },
  hobbies: { explain: "" },
};

const pastExperience = {
  answer: "",
  explain: "",
};

const employmentChange = {
  answer: [],
  explain: "",
};

const steps = ["Personal Details", "Employment & Skills"];

const applyFormSteps = [
  "Upload Resume",
  "Personal Details",
  "Employment & Skills",
  "Additional Details",
];

function CoverSheetForm({
  skillList,
  header_config,
  isClient,
  resume,
  applicant_id,
  job_code,
  isCandidateApplyForm,
  token,
  ...props
}) {
  const history = useHistory();
  const role = localStorage.getItem("role");
  let isAdmin = !isClient && ADMINS.includes(role);

  const [alertBox, setAlertBox] = useState({
    open: false,
    success: false,
    message: "",
  });
  const [activeStep, setActiveStep] = useState(0);
  const [resumeUploadLoading, setResumeUploadLoading] = useState(false);
  const [file, setFile] = useState(null);
  const [snack, setSnack] = useState({
    open: false,
    color: "",
    message: "",
  });

  const uploadResume = activeStep === 0 && isCandidateApplyForm;
  const allowCoverSheetSubmit = isCandidateApplyForm
    ? activeStep === 3
    : activeStep === 1;
  const allowBack = isCandidateApplyForm ? activeStep > 1 : activeStep > 0;

  const personalDetailsRequired = isClient
    ? isCandidateApplyForm
      ? activeStep === 1
      : activeStep === 0
    : false;
  const educationRequired = isClient
    ? isCandidateApplyForm
      ? activeStep === 1
      : activeStep === 0
    : false;
  const certificationRequired = isClient
    ? isCandidateApplyForm
      ? activeStep === 1
      : activeStep === 0
    : false;
  const additionDetailsRequired = isClient
    ? isCandidateApplyForm
      ? activeStep === 3
      : activeStep === 0
    : false;
  // const hobbiesRequired = isClient
  //   ? isCandidateApplyForm
  //     ? activeStep === 1
  //     : activeStep === 0
  //   : false;
  const previousEmployerRequired = isClient
    ? isCandidateApplyForm
      ? activeStep === 2
      : activeStep === 1
    : false;
  const skillDetailsRequired = isClient
    ? isCandidateApplyForm
      ? activeStep === 2
      : activeStep === 1
    : false;

  const graduationRequiredSchema = yup
    .string()
    .nullable()
    .optional()
    .test(
      "oneOfRequired",
      "Please fill the required field",
      (item, testContext) => {
        return Boolean(
          (testContext.parent.degree ||
            testContext.parent.major ||
            testContext.parent.university ||
            testContext.parent.year_of_passing ||
            testContext.parent.cgpa) &&
            educationRequired
            ? item
            : true
        );
      }
    );

  const degreeRequiredSchema = yup
    .string()
    .nullable()
    .optional()
    .test(
      "oneOfRequired",
      "Please fill the required field",
      (item, testContext) => {
        return Boolean(
          (testContext.parent.graduation ||
            testContext.parent.major ||
            testContext.parent.university ||
            testContext.parent.year_of_passing ||
            testContext.parent.cgpa) &&
            educationRequired
            ? item
            : true
        );
      }
    );

  const majorRequiredSchema = yup
    .string()
    .nullable()
    .optional()
    .test(
      "oneOfRequired",
      "Please fill the required field",
      (item, testContext) => {
        return Boolean(
          (testContext.parent.graduation ||
            testContext.parent.degree ||
            testContext.parent.university ||
            testContext.parent.year_of_passing ||
            testContext.parent.cgpa) &&
            educationRequired
            ? item
            : true
        );
      }
    );

  const universityRequiredSchema = yup
    .string()
    .nullable()
    .optional()
    .test(
      "oneOfRequired",
      "Please fill the required field",
      (item, testContext) => {
        return Boolean(
          (testContext.parent.graduation ||
            testContext.parent.degree ||
            testContext.parent.major ||
            testContext.parent.year_of_passing ||
            testContext.parent.cgpa) &&
            educationRequired
            ? item
            : true
        );
      }
    );

  const yearOfPassingRequiredSchema = yup
    .string()
    .nullable()
    .optional()
    .test(
      "oneOfRequired",
      "Please fill the required field",
      (item, testContext) => {
        return Boolean(
          (testContext.parent.graduation ||
            testContext.parent.degree ||
            testContext.parent.major ||
            testContext.parent.university ||
            testContext.parent.cgpa) &&
            educationRequired
            ? item
            : true
        );
      }
    );

  const cgpaRequiredSchema = yup
    .string()
    .nullable()
    .optional()
    .test(
      "oneOfRequired",
      "Please fill the required field",
      (item, testContext) => {
        return Boolean(
          (testContext.parent.graduation ||
            testContext.parent.degree ||
            testContext.parent.major ||
            testContext.parent.university ||
            testContext.parent.year_of_passing) &&
            educationRequired
            ? item
            : true
        );
      }
    );

  const certificationNameRequiredSchema = yup
    .string()
    .nullable()
    .optional()
    .test(
      "oneOfRequired",
      "Please fill the required field",
      (item, testContext) => {
        return Boolean(
          testContext.parent.year_of_passing && certificationRequired
            ? item
            : true
        );
      }
    );

  const certificationYearOfPassingRequiredSchema = yup
    .string()
    .nullable()
    .optional()
    .test(
      "oneOfRequired",
      "Please fill the required field",
      (item, testContext) => {
        return Boolean(
          testContext.parent.name && certificationRequired ? item : true
        );
      }
    );

  const validationSchema = yup.object({
    personal_details: yup.object().shape({
      first_name: requiredSchema(
        yup.string().nullable().optional(),
        personalDetailsRequired
      ),
      last_name: requiredSchema(
        yup.string().nullable().optional(),
        personalDetailsRequired
      ),
      phone: requiredSchema(
        yup.string().nullable().optional(),
        personalDetailsRequired
      ),
      email: requiredSchema(
        yup.string().email("Enter valid email").nullable().optional(),
        personalDetailsRequired
      ),
      linkedin_profile: yup.string().nullable().optional(),
    }),
    education: yup.array().of(
      yup.object().shape({
        graduation: graduationRequiredSchema,
        degree: degreeRequiredSchema,
        major: majorRequiredSchema,
        university: universityRequiredSchema,
        year_of_passing: yearOfPassingRequiredSchema,
        cgpa: cgpaRequiredSchema,
      })
    ),
    certification: yup.array().of(
      yup.object().shape({
        name: certificationNameRequiredSchema,
        year_of_passing: certificationYearOfPassingRequiredSchema,
      })
    ),
    current_employer: yup.object().shape({
      company_name: yup.string().nullable().optional(),
      job_title: yup.string().nullable().optional(),
      duration_from: yup.string().nullable().optional(),
      duration_to: yup.string().nullable().optional(),
    }),
    previous_employer: yup.array().of(
      yup.object().shape({
        company_name: requiredSchema(
          yup.string().nullable().optional(),
          previousEmployerRequired
        ),
        job_title: requiredSchema(
          yup.string().nullable().optional(),
          previousEmployerRequired
        ),
        duration_from: requiredSchema(
          yup.string().nullable().optional(),
          previousEmployerRequired
        ),
        duration_to: requiredSchema(
          yup.string().nullable().optional(),
          previousEmployerRequired
        ),
      })
    ),
    isPreviousEmployer: yup.string().nullable().optional(),
    currentLocation: requiredSchema(
      yup.string().nullable().optional(),
      additionDetailsRequired
    ),
    reLocation: requiredSchema(
      yup.string().nullable().optional(),
      additionDetailsRequired
    ),
    workOption: requiredSchema(
      yup.string().nullable().optional(),
      additionDetailsRequired
    ),
    variablePay: requiredSchema(
      yup.string().nullable().optional(),
      additionDetailsRequired
    ),
    annualSalary: requiredSchema(
      yup.string().nullable().optional(),
      additionDetailsRequired
    ),
    currentCtc: requiredSchema(
      yup.string().nullable().optional(),
      additionDetailsRequired
    ),
    currentNoticePeriod: requiredSchema(
      yup.string().nullable().optional(),
      additionDetailsRequired
    ),
    backgroundCheck: requiredSchema(
      yup.string().nullable().optional(),
      additionDetailsRequired
    ),
    project_details: yup.array().of(
      yup.object().shape({
        how_many_projects: yup.string().nullable().optional(),
        project_details: yup
          .array()
          .of(
            yup.object().shape({
              client: yup.string().nullable().optional(),
              location: yup.string().nullable().optional(),
              domain: yup.string().nullable().optional(),
              duration: yup.string().nullable().optional(),
              durationStartDate: yup.string().nullable().optional(),
              durationEndDate: yup.string().nullable().optional(),
              project_experience: yup.string().nullable().optional(),
              responsibilities: yup.string().nullable().optional(),
            })
          )
          .when(["how_many_projects"], {
            is: (how_many_projects) => Number(how_many_projects) > 1,
            then: yup.array().of(
              yup.object().shape({
                client: requiredSchema(
                  yup.string().nullable().optional(),
                  skillDetailsRequired
                ),
                location: requiredSchema(
                  yup.string().nullable().optional(),
                  skillDetailsRequired
                ),
                domain: requiredSchema(
                  yup.string().nullable().optional(),
                  skillDetailsRequired
                ),
                duration: yup.string().nullable().optional(),
                durationStartDate: requiredSchema(
                  yup.string().nullable().optional(),
                  skillDetailsRequired
                ),
                durationEndDate: requiredSchema(
                  yup.string().nullable().optional(),
                  skillDetailsRequired
                ),
                project_experience: yup.string().nullable().optional(),
                responsibilities: requiredSchema(
                  yup.string().nullable().optional(),
                  skillDetailsRequired
                ),
              })
            ),
          }),
      })
    ),
    skill_details: yup.array().of(
      yup.object().shape({
        skill: yup.string().nullable().optional(),
        year: yup.string().nullable().optional(),
        month: yup.string().nullable().optional(),
      })
    ),
    // hobbies: yup.object().shape({
    //   coding_test: yup.object().shape({
    //     answer: yup.string().nullable().optional(),
    //     explain: yup
    //       .string()
    //       .nullable()
    //       .optional()
    //       .when("answer", {
    //         is: (val) => val === "Yes" && hobbiesRequired,
    //         then: requiredSchema(yup.string().nullable().optional()),
    //       }),
    //   }),
    //   hobbies: yup.object().shape({
    //     explain: requiredSchema(
    //       yup.string().nullable().optional(),
    //       hobbiesRequired
    //     ),
    //   }),
    // }),
    past_experience: yup.object().shape({
      answer: requiredSchema(
        yup.string().nullable().optional(),
        additionDetailsRequired
      ),
      explain: yup
        .string()
        .nullable()
        .optional()
        .when("answer", {
          is: (val) => val === "Yes" && additionDetailsRequired,
          then: requiredSchema(yup.string().nullable().optional()),
        }),
    }),
    employment_change: yup.object().shape({
      answer: additionDetailsRequired
        ? yup.array().min(1, "Please fill the required field")
        : yup.array().optional(),
      explain: yup
        .string()
        .nullable()
        .optional()
        .when("answer", {
          is: (val) => val && val.includes("Other") && additionDetailsRequired,
          then: requiredSchema(yup.string().nullable().optional()),
        }),
    }),
  });

  const formik = useFormik({
    initialValues: {
      personal_details: personalDetails,
      education: [educationObj],
      certification: [certificationObj],
      project_details: [],
      skill_details: [],
      hobbies: hobbies,
      current_employer: empObj,
      isPreviousEmployer: false,
      previous_employer: [],
      homePhoneNumber: "+919677000212",
      currentLocation: "",
      reLocation: "",
      workOption: "",
      variablePay: "",
      annualSalary: "",
      currentCtc: "",
      currentNoticePeriod: "",
      backgroundCheck: "",
      education_score: "",
      soft_skill_score: "",
      past_experience: pastExperience,
      employment_change: employmentChange,
    },
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: handleSubmit,
  });

  const handleSnackClose = () => {
    setSnack({
      open: false,
      color: "",
      message: "",
    });
  };

  const handleSnackOpen = (message = MESSAGES.error, color = "error") => {
    setSnack({
      open: true,
      color: color,
      message: message || MESSAGES.error,
    });
  };

  function handleSubmit(values) {
    if (uploadResume) {
      if (file) {
        setResumeUploadLoading(true);

        let formData = new FormData();
        formData.append("files[]", file);
        formData.append("job_code", job_code);

        axios
          .post(postResumeByJobCodeApplicantId, formData, {
            headers: { "Content-Type": "multipart/form-data" },
          })
          .then((response) => {
            if (response.status === 200) {
              if (response.data.applicant[0]) {
                handleSnackOpen(MESSAGES.resumeUploadSuccessMessage, "success");
                setFile(null);
                history.replace(
                  navigateTo.applyJobWithApplicant(
                    job_code,
                    token,
                    response.data.applicant[0]
                  )
                );
                setTouched({});
                setActiveStep(activeStep + 1);
              } else {
                handleSnackOpen();
              }
            } else {
              handleSnackOpen();
            }
            setResumeUploadLoading(false);
          })
          .catch((err) => {
            setResumeUploadLoading(false);
            handleSnackClose();
          });
      } else {
        handleSnackOpen("Please select a resume");
      }
    } else if (allowCoverSheetSubmit) {
      let valuesObj = JSON.parse(JSON.stringify(values));
      let quesAnsArr = [];
      delete valuesObj.isPreviousEmployer;
      let employment_history = { current_employer: {}, previous_employer: [] };

      for (const key in valuesObj) {
        if (key === "timeSlotOne" || key === "timeSlotTwo") {
          quesAnsArr.push({ [key]: new Date(valuesObj[key]).toISOString() });
        } else if (key === "skill_details") {
          let arr = [];
          if (valuesObj[key] && valuesObj[key].length) {
            arr = [...valuesObj[key]];
            if (isClient) {
              for (let x = 0; x < valuesObj[key].length; x++) {
                arr[x]["year"] = arr[x]["year"] || "0";
                arr[x]["month"] = arr[x]["month"] || "0";
              }
            }
          }

          quesAnsArr.push({
            [key]:
              valuesObj[key] && valuesObj[key].length
                ? JSON.stringify(arr)
                : null,
          });
        } else if (key === "project_details") {
          let arr = [];
          if (valuesObj[key] && valuesObj[key].length) {
            arr = [...valuesObj[key]];
            for (let i = 0; i < valuesObj[key].length; i++) {
              if (valuesObj[key][i]["project_details"]) {
                for (
                  let j = 0;
                  j < valuesObj[key][i]["project_details"].length;
                  j++
                ) {
                  let duration = valuesObj[key][i]["project_details"][j][
                    "durationEndDate"
                  ]
                    ? `${valuesObj[key][i]["project_details"][j][
                      "durationStartDate"
                    ]
                      ? formatDate(
                        valuesObj[key][i]["project_details"][j][
                        "durationStartDate"
                        ]
                      )
                      : ""
                    } - ${formatDate(
                      valuesObj[key][i]["project_details"][j][
                      "durationEndDate"
                      ]
                    )}`
                    : valuesObj[key][i]["project_details"][j][
                      "durationStartDate"
                    ]
                      ? formatDate(
                        valuesObj[key][i]["project_details"][j][
                        "durationStartDate"
                        ]
                      )
                      : "";
                  arr[i]["project_details"][j]["duration"] = duration;
                  delete arr[i]["project_details"][j]["durationStartDate"];
                  delete arr[i]["project_details"][j]["durationEndDate"];
                }
              }
            }
          }

          quesAnsArr.push({
            [key]:
              valuesObj[key] && valuesObj[key].length
                ? JSON.stringify(arr)
                : null,
          });
        } else if (key === "personal_details") {
          quesAnsArr.push({
            [key]: valuesObj[key] ? JSON.stringify([valuesObj[key]]) : "",
          });
        } else if (key === "current_employer") {
          let obj = { ...valuesObj[key] };
          obj.duration =
            obj.duration_from && obj.duration_to
              ? `${formatDate(obj.duration_from)} - ${formatDate(
                obj.duration_to
              )}`
              : "";
          delete obj.duration_from;
          delete obj.duration_to;
          // Employment history with current employer
          employment_history.current_employer = obj;
        } else if (key === "previous_employer") {
          let arr = [...valuesObj[key]];
          for (let i = 0; i < arr.length; i++) {
            arr[i].duration =
              arr[i].duration_from && arr[i].duration_to
                ? `${formatDate(arr[i].duration_from)} - ${formatDate(
                  arr[i].duration_to
                )}`
                : "";
            delete arr[i].duration_from;
            delete arr[i].duration_to;
          }
          // Employment history with previous employer
          employment_history.previous_employer = arr;
        } else if (key === "education") {
          let arr = [...valuesObj[key]];
          for (let i = 0; i < arr.length; i++) {
            arr[i].year_of_passing = arr[i].year_of_passing
              ? formatDate(arr[i].year_of_passing, "YYYY")
              : "";
          }
          quesAnsArr.push({
            [key]: JSON.stringify(arr),
          });
        } else if (key === "certification") {
          let arr = [...valuesObj[key]];
          for (let i = 0; i < arr.length; i++) {
            arr[i].year_of_passing = arr[i].year_of_passing
              ? formatDate(arr[i].year_of_passing, "YYYY")
              : "";
          }
          quesAnsArr.push({
            [key]: JSON.stringify(arr),
          });
        } else if (key === "hobbies") {
          if (isClient) {
            valuesObj[key]["coding_test"]["answer"] =
              valuesObj[key]["coding_test"]["answer"] || "No";
          }
          const hobbiesText = valuesObj[key]["hobbies"]["explain"] || "";
          const parseLinesWithAddComma = hobbiesText.replace(/(\n)/g, ",\n");
          const parseComma = parseLinesWithAddComma.replace(/(,,)/g, ",");
          valuesObj[key]["hobbies"]["explain"] = parseComma;
          quesAnsArr.push({
            [key]: valuesObj[key] ? JSON.stringify([valuesObj[key]]) : "",
          });
        } else if (key === "employment_history") {
        } else if (key === "past_experience") {
          quesAnsArr.push({
            [key]: valuesObj[key] ? JSON.stringify(valuesObj[key]) : "",
          });
        } else if (key === "employment_change") {
          quesAnsArr.push({
            [key]: valuesObj[key] ? JSON.stringify(valuesObj[key]) : "",
          });
        } else {
          quesAnsArr.push({ [key]: valuesObj[key] });
        }
      }

      quesAnsArr.push({
        employment_history: JSON.stringify([employment_history]),
      });

      let requestBody = {
        applicant_id: applicant_id,
        answers: quesAnsArr,
      };

      if (job_code) {
        requestBody.job_code = job_code
      }

      if (isClient) {
        requestBody.status = "submitted";
      }

      axios
        .post(postCoverSheetFormQuestionAnswer(), requestBody, header_config)
        .then((response) => {
          if (response.status === 200) {
            setAlertBox({
              open: true,
              success: true,
              message: "Saved Successfully",
            });

            if (isClient) {
              // Show Thank You message on ChatForm after user successfully submitted.
              if (props.hasOwnProperty("showThankYou")) {
                props.showThankYou();
              }
            } else {
              setTimeout(() => {
                if (job_code)
                  history.push(`/dashboard/${job_code}/applicants`);
                else
                  history.push(`/home/applicants`);
              }, 2000);
            }
          }
        })
        .catch((err) => {
          setAlertBox({
            open: true,
            success: false,
            message: "Something Went Wrong",
          });
        })
        .finally(() => {
          setTimeout(() => {
            setAlertBox({
              open: false,
              success: false,
              message: "",
            });
          }, 3000);
        });
    } else {
      setTouched({});
      setActiveStep(activeStep + 1);
    }
  }

  const getAndSetPhoneQuestionAnswer = () => {
    axios
      .get(
        getCoverSheetQuestionAnswer(), {
        ...header_config,
        params: {
          applicant_id: applicant_id,
          job_code: job_code,
        }
      }
      )
      .then(async (response) => {
        if (response.status === 200) {
          if (token) {
            setActiveStep(1);
          }
          let formattedObj = {};
          await response.data.map((el, ind) => {
            if (el.title === "timeSlotOne" || el.title === "timeSlotTwo") {
              if (el.answer) {
                const formattedDate = new Date(el.answer);
                formattedObj[el.title] = formattedDate;
              } else {
                formattedObj[el.title] = el.answer;
              }
            } else {
              formattedObj[el.title] = el.answer;
            }
            return el;
          });

          // Skill Details
          let arr = [];
          let parsed_skill_details = formattedObj.skill_details
            ? JSON.parse(formattedObj.skill_details)
            : null;
          if (parsed_skill_details && parsed_skill_details.length) {
            if (skillList && skillList.length) {
              for (let i = 0; i < skillList.length; i++) {
                let obj = { ...skillObj };
                obj.skill = skillList[i];
                let findSkillObj = parsed_skill_details.find(
                  (el) => el.skill === skillList[i]
                );
                if (findSkillObj) {
                  obj = {
                    ...obj,
                    ...findSkillObj,
                  };
                }
                arr.push(obj);
              }
            }
          } else {
            if (skillList && skillList.length) {
              for (let i = 0; i < skillList.length; i++) {
                let obj = { ...skillObj };
                obj.skill = skillList[i];
                arr.push(obj);
              }
            }
          }

          // Project Details
          let projectDetailsArr = [];
          let parsed_project_details = formattedObj.project_details
            ? JSON.parse(formattedObj.project_details)
            : null;
          if (parsed_project_details && parsed_project_details.length) {
            projectDetailsArr = [...parsed_project_details];
            for (let i = 0; i < parsed_project_details.length; i++) {
              if (
                parsed_project_details[i]["project_details"] &&
                parsed_project_details[i]["project_details"].length
              ) {
                for (
                  let j = 0;
                  j < parsed_project_details[i]["project_details"].length;
                  j++
                ) {
                  let duration =
                    projectDetailsArr[i]["project_details"][j][
                      "duration"
                    ]?.split(" - ");
                  let durationStartDate = duration?.[0];
                  let durationEndDate = duration?.[1];
                  projectDetailsArr[i]["project_details"][j][
                    "durationStartDate"
                  ] = formattedDateStringToDate(durationStartDate)
                      ? convertTZ(formattedDateStringToDate(durationStartDate))
                      : "";
                  projectDetailsArr[i]["project_details"][j][
                    "durationEndDate"
                  ] = formattedDateStringToDate(durationEndDate)
                      ? convertTZ(formattedDateStringToDate(durationEndDate))
                      : "";
                }
              }
            }
          } else {
            projectDetailsArr.push(projectDetailsObj);
          }

          // Education value set
          let parsed_education = formattedObj.education
            ? JSON.parse(formattedObj.education)
            : null;
          if (!(parsed_education && parsed_education.length)) {
            formattedObj.education = [educationObj];
          } else {
            let arr = [...parsed_education];

            for (let i = 0; i < parsed_education.length; i++) {
              arr[i] = {
                ...educationObj,
                ...arr[i],
                year_of_passing: arr[i]["year_of_passing"]
                  ? convertTZ(arr[i]["year_of_passing"])
                  : "",
              };
            }
            formattedObj.education = arr;
          }

          // Certification value set
          let parsed_certification = formattedObj.certification
            ? JSON.parse(formattedObj.certification)
            : null;
          if (!(parsed_certification && parsed_certification.length)) {
            formattedObj.certification = [certificationObj];
          } else {
            let arr = [...parsed_certification];

            for (let i = 0; i < parsed_certification.length; i++) {
              arr[i]["year_of_passing"] = arr[i]["year_of_passing"]
                ? convertTZ(arr[i]["year_of_passing"])
                : "";
            }
            formattedObj.certification = arr;
          }

          // Employment history
          let parsed_employment_history = formattedObj.employment_history
            ? JSON.parse(formattedObj.employment_history)
            : null;
          if (parsed_employment_history && parsed_employment_history.length) {
            let employment_history_obj = { ...parsed_employment_history[0] };

            // Current employer value set
            if (employment_history_obj.current_employer) {
              let obj = { ...employment_history_obj.current_employer };
              let duration = obj["duration"]?.split(" - ");
              let durationStartDate = duration?.[0];
              let durationEndDate = duration?.[1];
              obj["duration_from"] = formattedDateStringToDate(
                durationStartDate
              )
                ? convertTZ(formattedDateStringToDate(durationStartDate))
                : "";
              obj["duration_to"] = formattedDateStringToDate(durationEndDate)
                ? convertTZ(formattedDateStringToDate(durationEndDate))
                : "";
              delete obj["duration"];
              formattedObj.current_employer = obj;
            } else {
              formattedObj.current_employer = empObj;
            }

            // Previous employer value set
            if (
              employment_history_obj.previous_employer &&
              employment_history_obj.previous_employer.length
            ) {
              let arr = [...employment_history_obj.previous_employer];

              for (
                let i = 0;
                i < employment_history_obj.previous_employer.length;
                i++
              ) {
                let duration = arr[i]["duration"]?.split(" - ");
                let durationStartDate = duration?.[0];
                let durationEndDate = duration?.[1];
                arr[i]["duration_from"] = formattedDateStringToDate(
                  durationStartDate
                )
                  ? convertTZ(formattedDateStringToDate(durationStartDate))
                  : "";
                arr[i]["duration_to"] = formattedDateStringToDate(
                  durationEndDate
                )
                  ? convertTZ(formattedDateStringToDate(durationEndDate))
                  : "";
                delete arr[i]["duration"];
              }

              formattedObj.previous_employer = arr;
              formattedObj.isPreviousEmployer = true;
            } else {
              formattedObj.previous_employer = [];
              formattedObj.isPreviousEmployer = false;
            }
          } else {
            formattedObj.current_employer = empObj;
            formattedObj.previous_employer = [];
            formattedObj.isPreviousEmployer = false;
          }

          // Personal details
          let personal_details_obj = personalDetails;
          if (resume)
            personal_details_obj = {
              ...personal_details_obj,
              ...resume,
            };
          personal_details_obj.first_name =
            personal_details_obj.first_name || "";
          personal_details_obj.last_name = personal_details_obj.last_name || "";
          personal_details_obj.email = personal_details_obj.email || "";
          personal_details_obj.phone = personal_details_obj.phone || "";
          personal_details_obj.linkedin_profile =
            personal_details_obj.linkedin_profile || "";
          formattedObj.personal_details = personal_details_obj;

          // Hobbies
          let parsed_hobbies = formattedObj.hobbies
            ? JSON.parse(formattedObj.hobbies)
            : null;
          if (!(parsed_hobbies && parsed_hobbies.length)) {
            formattedObj.hobbies = hobbies;
          } else {
            formattedObj.hobbies = parsed_hobbies[0];
          }

          // Past Experience change
          let parsed_past_experience = formattedObj.past_experience
            ? JSON.parse(formattedObj.past_experience)
            : null;

          if (parsed_past_experience) {
            formattedObj.past_experience = parsed_past_experience;
          } else {
            formattedObj.past_experience = pastExperience;
          }

          // Employment change
          let parsed_employment_change = formattedObj.employment_change
            ? JSON.parse(formattedObj.employment_change)
            : null;

          if (parsed_employment_change) {
            formattedObj.employment_change = parsed_employment_change;
          } else {
            formattedObj.employment_change = employmentChange;
          }

          setValues({
            ...values,
            ...formattedObj,
            skill_details: arr,
            project_details: projectDetailsArr,
          });
        }
      });
  };

  useEffect(() => {
    if (skillList) {
      getAndSetPhoneQuestionAnswer();
    }
  }, [skillList]);

  const {
    values,
    handleChange,
    touched,
    errors,
    handleBlur,
    setValues,
    setTouched,
    setFieldValue,
  } = formik;

  const handleSkillDetails = (e, index) => {
    const { value, name } = e.target;
    let obj = JSON.parse(JSON.stringify(values));
    let arr = [...obj.skill_details];
    arr[index][name] = value;
    setValues({ ...values, skill_details: arr });
  };

  const handlePersonalDetails = (e) => {
    const { value, name } = e.target;
    let obj = JSON.parse(JSON.stringify(values.personal_details));
    obj[name] = value;
    setValues({ ...values, personal_details: obj });
  };

  const handleProjectDetails = (e, index, project_details_index) => {
    const { value, name } = e.target;
    let obj = JSON.parse(JSON.stringify(values));
    let arr = [...obj.project_details];
    arr[index]["project_details"][project_details_index][name] = value;
    setValues({ ...values, project_details: arr });
  };

  const handleDurationStartDate = (value, index, project_details_index) => {
    let obj = JSON.parse(JSON.stringify(values));
    let arr = [...obj.project_details];
    arr[index]["project_details"][project_details_index]["durationStartDate"] =
      value;
    setValues({ ...values, project_details: arr });
  };

  const handleDurationEndDate = (value, index, project_details_index) => {
    let obj = JSON.parse(JSON.stringify(values));
    let arr = [...obj.project_details];
    arr[index]["project_details"][project_details_index]["durationEndDate"] =
      value;
    setValues({ ...values, project_details: arr });
  };

  const handleProjectCount = (e, index) => {
    const { value } = e.target;
    let obj = JSON.parse(JSON.stringify(values));
    let arr = [...obj.project_details];
    let projectArr = [];

    let count = parseInt(value);

    if (count) {
      for (let i = 0; i < count; i++) {
        if (arr[index]["project_details"][i]) {
          projectArr.push(arr[index]["project_details"][i]);
        } else {
          projectArr.push(singleProjectDetailsObj);
        }
      }
    }

    arr[index]["project_details"] = projectArr;
    arr[index]["how_many_projects"] = value;
    setValues({ ...values, project_details: arr });
  };

  const handleAddCertification = () => {
    let obj = JSON.parse(JSON.stringify(values));
    let arr = [...obj.certification];
    arr.push(certificationObj);
    setValues({ ...values, certification: arr });
  };

  const handleRemoveCertification = (index) => {
    let obj = JSON.parse(JSON.stringify(values));
    let arr = [...obj.certification];
    arr.splice(index, 1);
    setValues({ ...values, certification: arr });
  };

  const handleAddEduction = () => {
    let obj = JSON.parse(JSON.stringify(values));
    let arr = [...obj.education];
    arr.push(educationObj);
    setValues({ ...values, education: arr });
  };

  const handleRemoveEduction = (index) => {
    let obj = JSON.parse(JSON.stringify(values));
    let arr = [...obj.education];
    arr.splice(index, 1);
    setValues({ ...values, education: arr });
  };

  const handleInputChange = (e, index, parentName) => {
    const { value, name } = e.target;
    if (values?.[parentName]?.[index]) {
      let obj = JSON.parse(JSON.stringify(values));
      let arr = [...obj[parentName]];
      arr[index][name] = value;
      setValues({ ...values, [parentName]: arr });
    }
  };

  const handleInputValueChange = (value, name, index, parentName) => {
    if (values?.[parentName]?.[index]) {
      let obj = JSON.parse(JSON.stringify(values));
      let arr = [...obj[parentName]];
      arr[index][name] = value;
      setValues({ ...values, [parentName]: arr });
    }
  };

  const handleCurrentEmployerInputChange = (e) => {
    const { value, name } = e.target;
    let obj = JSON.parse(JSON.stringify(values));
    let currentEmployerObj = { ...obj.current_employer };
    currentEmployerObj[name] = value;
    setValues({ ...values, current_employer: currentEmployerObj });
  };

  const handleAddPreviousEmployment = () => {
    let obj = JSON.parse(JSON.stringify(values));
    let arr = [...obj.previous_employer];
    arr.push(empObj);
    setValues({ ...values, previous_employer: arr });
  };

  const handleRemovePreviousEmployment = (index) => {
    let obj = JSON.parse(JSON.stringify(values));
    let arr = [...obj.previous_employer];
    arr.splice(index, 1);
    setValues({ ...values, previous_employer: arr });
  };

  const handleIsPreviousEmployerChange = (e) => {
    const { name, checked } = e.target;
    setValues({
      ...values,
      [name]: checked,
      previous_employer: checked ? [empObj] : [],
    });
  };

  const handleHobbiesInputChange = (e, childName) => {
    const { value, name } = e.target;
    let obj = JSON.parse(JSON.stringify(values));
    let hobbiesObj = { ...obj.hobbies };
    if (childName === "coding_test" && name === "answer") {
      hobbiesObj[childName]["explain"] = "";
    }
    hobbiesObj[childName][name] = value;
    setValues({ ...values, hobbies: hobbiesObj });
  };

  const handleDynamicValueChange = (e) => {
    const { value, name } = e.target;
    setFieldValue(name, value);

    if (name === "past_experience.answer") {
      setFieldValue("past_experience.explain", "");
    }
  };

  const handleEmploymentOptionChange = (e) => {
    const { name, checked } = e.target;

    let obj = { ...values.employment_change };
    if (name === "Other") {
      if (checked) obj["answer"] = [name];
      else obj["answer"] = [];
    } else {
      let indexOf = obj["answer"].indexOf(name);
      if (indexOf >= 0) obj["answer"].splice(indexOf, 1);
      else if (obj["answer"].includes("Other")) obj["answer"] = [name];
      else obj["answer"].push(name);
    }
    obj["explain"] = "";
    setFieldValue("employment_change", obj);
  };

  const renderContent = () => {
    switch (activeStep) {
      case 0:
        return (
          <>
            <PersonalDetails
              values={values}
              handlePersonalDetails={handlePersonalDetails}
              touched={touched}
              errors={errors}
            />
            <EducationAndCertification
              values={values}
              handleChange={handleChange}
              handleAddCertification={handleAddCertification}
              handleRemoveCertification={handleRemoveCertification}
              handleAddEduction={handleAddEduction}
              handleRemoveEduction={handleRemoveEduction}
              handleInputChange={handleInputChange}
              handleInputValueChange={handleInputValueChange}
              touched={touched}
              errors={errors}
              isAdmin={isAdmin}
            />
            <AdditionalDetails
              values={values}
              handleChange={handleChange}
              touched={touched}
              errors={errors}
              handleBlur={handleBlur}
              handleDynamicValueChange={handleDynamicValueChange}
              handleEmploymentOptionChange={handleEmploymentOptionChange}
            />
            <HobbiesAndExtraCurricularActivities
              values={values}
              handleChange={handleChange}
              handleHobbiesInputChange={handleHobbiesInputChange}
              touched={touched}
              errors={errors}
              isAdmin={isAdmin}
            />
          </>
        );

      default:
        return (
          <>
            <EmployeeHistory
              values={values}
              handleChange={handleChange}
              handleCurrentEmployerInputChange={
                handleCurrentEmployerInputChange
              }
              handleAddPreviousEmployment={handleAddPreviousEmployment}
              handleRemovePreviousEmployment={handleRemovePreviousEmployment}
              handleInputChange={handleInputChange}
              handleInputValueChange={handleInputValueChange}
              handleIsPreviousEmployerChange={handleIsPreviousEmployerChange}
              touched={touched}
              errors={errors}
            />
            <SkillSummary
              values={values}
              handleProjectDetails={handleProjectDetails}
              handleDurationEndDate={handleDurationEndDate}
              handleDurationStartDate={handleDurationStartDate}
              handleSkillDetails={handleSkillDetails}
              handleProjectCount={handleProjectCount}
              touched={touched}
              errors={errors}
              isSkill={!!job_code}
            />
          </>
        );
    }
  };

  const handleDrop = (files) => {
    if (files.length) {
      setFile(files[0]);
    } else {
      handleSnackOpen(MESSAGES.validFileErrorMessage);
    }
  };

  const handleRemoveFile = () => {
    setFile(null);
  };

  const renderCandidateApplyFormContent = () => {
    switch (activeStep) {
      case 0:
        return (
          <Box p={1} pb={0} minHeight={"325px"}>
            <DropzoneComponent
              disabled={resumeUploadLoading || Boolean(file)}
              file={file}
              acceptTypes={{
                "application/msword": [".doc"],
                "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
                  [".docx"],
                "application/pdf": [".pdf"],
              }}
              onDrop={handleDrop}
              onDelete={handleRemoveFile}
              loading={resumeUploadLoading}
            />
          </Box>
        );
      case 1:
        return (
          <>
            <PersonalDetails
              values={values}
              handlePersonalDetails={handlePersonalDetails}
              touched={touched}
              errors={errors}
            />
            <EducationAndCertification
              values={values}
              handleChange={handleChange}
              handleAddCertification={handleAddCertification}
              handleRemoveCertification={handleRemoveCertification}
              handleAddEduction={handleAddEduction}
              handleRemoveEduction={handleRemoveEduction}
              handleInputChange={handleInputChange}
              handleInputValueChange={handleInputValueChange}
              touched={touched}
              errors={errors}
              isAdmin={isAdmin}
            />
            <HobbiesAndExtraCurricularActivities
              values={values}
              handleChange={handleChange}
              handleHobbiesInputChange={handleHobbiesInputChange}
              touched={touched}
              errors={errors}
              isAdmin={isAdmin}
            />
          </>
        );
      case 2:
        return (
          <>
            <EmployeeHistory
              values={values}
              handleChange={handleChange}
              handleCurrentEmployerInputChange={
                handleCurrentEmployerInputChange
              }
              handleAddPreviousEmployment={handleAddPreviousEmployment}
              handleRemovePreviousEmployment={handleRemovePreviousEmployment}
              handleInputChange={handleInputChange}
              handleInputValueChange={handleInputValueChange}
              handleIsPreviousEmployerChange={handleIsPreviousEmployerChange}
              touched={touched}
              errors={errors}
            />
            <SkillSummary
              values={values}
              handleProjectDetails={handleProjectDetails}
              handleDurationEndDate={handleDurationEndDate}
              handleDurationStartDate={handleDurationStartDate}
              handleSkillDetails={handleSkillDetails}
              handleProjectCount={handleProjectCount}
              touched={touched}
              errors={errors}
            />
          </>
        );
      default:
        return (
          <AdditionalDetails
            values={values}
            handleChange={handleChange}
            touched={touched}
            errors={errors}
            handleBlur={handleBlur}
          />
        );
    }
  };

  const handleBack = () => {
    setTouched({});
    setActiveStep(activeStep - 1);
  };

  const handleSubmitClick = () => {
    if (errors && Object.keys(errors).length) {
      setTimeout(() => {
        let el = window.document.querySelector(
          ".MuiFormHelperText-root.Mui-error"
        );
        if (el) {
          el.scrollIntoView({
            block: "center",
            behavior: "smooth",
          });
        }
      }, 100);
    }
  };

  const actionButtonText = isCandidateApplyForm
    ? activeStep === 0
      ? "Upload"
      : activeStep === 3
        ? "Submit"
        : "Next"
    : activeStep === 1
      ? "Submit"
      : "Next";

  return (
    <Box>
      <Box style={styles.card} margin={"20px 0"} overflow={"auto"}>
        <StepperComponent
          steps={isCandidateApplyForm ? applyFormSteps : steps}
          activeStep={activeStep}
        />
      </Box>
      {uploadResume ? null : (
        <Box margin={"20px 0"}>
          <Typography>
            <b>{`Note: `}</b>
            {"Feel free to change or edit"}
          </Typography>
        </Box>
      )}

      <Box style={styles.card} padding={"20px"}>
        <FormikProvider value={formik}>
          <Form>
            {isCandidateApplyForm
              ? renderCandidateApplyFormContent()
              : renderContent()}
            <Box pt={3} display={"flex"} justifyContent={"space-between"}>
              {allowBack ? (
                <Button
                  variant={"contained"}
                  color={"primary"}
                  onClick={handleBack}
                >
                  <b>{"Back"}</b>
                </Button>
              ) : (
                <Box />
              )}

              <LoadingButton
                type={"submit"}
                onClick={handleSubmitClick}
                loading={resumeUploadLoading}
              >
                <b>{actionButtonText}</b>
              </LoadingButton>
            </Box>
            {alertBox.open && (
              <Box pt={2}>
                <Alert severity={`${alertBox.success ? "success" : "error"}`}>
                  {alertBox.message}
                </Alert>
              </Box>
            )}
          </Form>
        </FormikProvider>
      </Box>
      <CustomSnack
        open={snack.open}
        color={snack.color}
        message={snack.message}
        onClose={handleSnackClose}
      />
    </Box>
  );
}

export default CoverSheetForm;

const styles = {
  card: {
    background: "rgb(255, 255, 255)",
    border: "1px solid rgb(232, 232, 232)",
    boxShadow: "rgba(47, 47, 47, 0.15) 0px 10px 50px",
    borderRadius: "10px",
  },
};
