import { Grid, TextField } from "@material-ui/core";
import { Autocomplete, createFilterOptions } from "@material-ui/lab";
import React from "react";
import SimpleModal from "./Modal/SimpleModal";

const filter = createFilterOptions();

function SendToManagerPopup({
  title,
  open,
  options,
  toEmail,
  handleClose,
  handleSubmit,
  handleEmailChange,
  loading,
}) {
  return (
    <SimpleModal
      title={title}
      open={open}
      saveButtonText={"Submit"}
      handleClose={handleClose}
      handleSave={handleSubmit}
      maxWidth={"sm"}
      loading={loading}
    >
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Autocomplete
            id={"to-email"}
            options={options || []}
            value={toEmail}
            getOptionLabel={(option) => option}
            onChange={(e, val) => handleEmailChange(val)}
            renderInput={(params) => (
              <TextField
                {...params}
                label={"To Email"}
                variant={"outlined"}
                size={"medium"}
                required
                fullWidth
              />
            )}
            filterOptions={(options, params) => {
              const filtered = filter(options, params);
              const { inputValue } = params;
              // Suggest the creation of a new value
              if (inputValue !== "") {
                filtered.push(inputValue);
              }
              return filtered;
            }}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            multiple
            freeSolo
          />
        </Grid>
        <Grid item xs />
      </Grid>
    </SimpleModal>
  );
}

export default SendToManagerPopup;
