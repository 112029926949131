import React, { useState, forwardRef, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useAlert } from "react-alert";

import { Circular } from "styled-loaders-react";

// import images
import icon_microsoft from "../../images/svgs/microsoft.svg";
import icon_google from "../../images/svgs/google.svg";

// Import API
import axios from "axios";
import { userAuth_API, userInfo_API } from "../../api/api";

// Redux
import { userLoginStatus } from "../../redux/actions/actionCreator";

// axios csrf
axios.defaults.xsrfHeaderName = "X-CSRFToken";
axios.defaults.xsrfCookieName = "csrftoken";

const Login = (_, ref) => {
  const { createAccountModal, signInModal, forgotPasswordModal } = ref;
  const [loadingState, setloadingState] = useState(false);

  // dispatch login status to redux
  const dispatch = useDispatch();

  // use alert hook
  const alert = useAlert();

  // React forms
  // Define form schema
  const schema = yup.object().shape({
    username: yup
      .string()
      //.email("Invalid email address")
      .required()
      .min(3)
      .max(100),
    password: yup.string().required().min(4).max(20),
  });

  const { register, handleSubmit, setValue, errors } = useForm({
    resolver: yupResolver(schema),
  });

  // Capture field entries
  const [loginInfo, setLoginInfo] = useState({
    code: -1,
    login: false,
    msg: "",
  });

  const [rememberMe] = useState(false);

  const handleRememberMeCookieEvent = (data) => {
    // If remember me is checked then store cookie
    if (rememberMe) {
      // var md5 = require('md5');
      //var epas=md5(data.password);

      //setPass({pass:data.password})
      setloadingState(true);

      //const Cryptr = require('cryptr');
      //const cryptr = new Cryptr('myTotalySecretKey');

      //const encryptedString = cryptr.encrypt(data.password);
      //const decryptedString = cryptr.decrypt(encryptedString);

      //console.log(encryptedString); // e7b75a472b65bc4a42e7b3f78833a4d00040beba796062bf7c13d9533b149e5ec3784813dc20348fdf248d28a2982df85b83d1109623bce45f08238f6ea9bd9bb5f406427b2a40f969802635b8907a0a57944f2c12f334bd081d5143a357c173a611e1b64a
      //console.log(decryptedString);

      localStorage.tal_username = data.username;
      localStorage.tal_password = data.password;
      //localStorage.logintokenpa = epas;
    }
  };

  // When comp mounts, check cookie
  useEffect(() => {
    //if (getValues("username") === "" || getValues("password") === "") {

    /*if(localStorage.tal_password){
      const Cryptr = require('cryptr');
      const cryptr = new Cryptr('myTotalySecretKey');
      const decryptedString = cryptr.decrypt(localStorage.tal_password);
      setValue("username", localStorage.tal_username);
      setValue("password",decryptedString);
    }else{
      setValue("username", localStorage.tal_username);
      setValue("password",localStorage.tal_password);
    }*/

    setValue("username", localStorage.tal_username);
    setValue("password", localStorage.tal_password);

    //}
    dispatch(userLoginStatus(loginInfo));
    // eslint-disable-next-line
  }, [loginInfo]);

  // Authorize user
  const authUser = (data) => {
    // Store cookies

    setloadingState(true);

    handleRememberMeCookieEvent(data);
    // call endpoint
    axios
      .post(
        userAuth_API,
        data

        // login scenarios: use redux as login loses state when re-rendered
        // 1. Wrong username and password
        // 2. Not registered. Handle 400 status.
        // 3. Already logged in message
        // 4. Refresh page and already logged in
        //*** for 4. useEffect local storage in App.js and authenticate using any endpoint
      )
      .then((response) => {
        axios
          .get(
            userInfo_API,
            {
              headers: {
                "Content-Type": "multipart/form-data",
                Authorization: "Bearer " + response.data.access,
              },
            }

            // login scenarios: use redux as login loses state when re-rendered
            // 1. Wrong username and password
            // 2. Not registered. Handle 400 status.
            // 3. Already logged in message
            // 4. Refresh page and already logged in
            //*** for 4. useEffect local storage in App.js and authenticate using any endpoint
          )
          .then((response2) => {
            console.log(response2);
            localStorage.setItem("user_name", response2.data.username);
            localStorage.setItem("tal_user", response2.data.username);
          });

        // login success
        setloadingState(false);
        localStorage.setItem("tal_token", response.data.access);
        localStorage.setItem("role", response.data.role);
        localStorage.setItem("user_email", response.data.email);
        localStorage.setItem("username", response.data.username);
        // localStorage.setItem("tal_user",response.data.username);
        localStorage.setItem("tal_refresh", response.data.refresh);
        // localStorage.setItem("tal_user", response2.data.username);
        setLoginInfo({ code: 200, login: true, msg: "User logged in." });
        signInModal.current.close();
        /*alert.show("You have been successfully logged in.", {
          header: "Successfully logged in!",
          ctype: "success",
        });*/
        //window.location.reload();
      })
      .catch((error) => {
        if (error.response?.status === 401) {
          setLoginInfo({
            code: 401,
            login: false,
            msg: "Login failed. Please verify or register first.",
          });
          alert.show(
            "Failed to log in. Please verify your credentials or register first.",
            {
              header: "Login Failed!",
              ctype: "failure",
            }
          );
        } else if (
          error.response?.status === 404 ||
          error.response?.status === 500
        ) {
          setLoginInfo({
            code: 404,
            login: false,
            msg: "No response from server. Please try again later.",
          });
          alert.show("No response from server. Please try again later.", {
            header: "Server Error!",
            ctype: "failure",
          });
        } else {
          setLoginInfo({
            code: 502,
            login: false,
            msg: "No response from server. Please try again later.",
          });
          alert.show("No response from server. Please try again later.", {
            header: "Server Error!",
            ctype: "failure",
          });
        }
      });
  };

  const handleForgotPassword = (e) => {
    e.preventDefault();
    signInModal.current.close();
    forgotPasswordModal.current.open();
  };

  return (
    <div className="container mx-auto px-4 h-full">
      <div className="flex content-center items-center justify-center h-full">
        <div className="w-full lg:w-4/12 px-4">
          <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-primary-color border-0">
            <div className="rounded-t mb-0 px-6 py-6">
              <div className="text-center mb-3">
                <h6 className="text-white text-sm font-bold">Sign in with</h6>
              </div>
              <div className="btn-wrapper text-center">
                <button
                  className="bg-white active:bg-gray-100 text-gray-800 px-4 py-2 rounded outline-none focus:outline-none mr-2 mb-1 uppercase shadow hover:shadow-md inline-flex items-center font-bold text-xs"
                  type="button"
                  style={{ transition: "all .15s ease" }}
                >
                  <img alt="" className="mr-1" src={icon_microsoft} />
                  Outlook
                </button>
                <button
                  className="bg-white active:bg-gray-100 text-gray-800 px-4 py-2 rounded outline-none focus:outline-none mr-1 mb-1 uppercase shadow hover:shadow-md inline-flex items-center font-bold text-xs"
                  type="button"
                  style={{ transition: "all .15s ease" }}
                >
                  <img alt="" className="mr-1" src={icon_google} />
                  Google
                </button>
              </div>
              <hr className="mt-6 border-b-1 border-gray-400" />
            </div>
            <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
              <div className="text-white text-center mb-3 font-bold">
                <small>Or sign in with credentials</small>
              </div>
              <form onSubmit={handleSubmit(authUser)}>
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-white text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Username Or Email
                  </label>
                  <input
                    type="text"
                    name="username"
                    className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full"
                    placeholder="Username Or Email"
                    style={{ transition: "all .15s ease" }}
                    ref={register}
                  />
                  <p className="text-red-500">{errors.username?.message}</p>
                </div>

                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-white text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Password
                  </label>
                  <input
                    type="password"
                    name="password"
                    className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full"
                    placeholder="Password"
                    style={{ transition: "all .15s ease" }}
                    ref={register}
                  />
                  <p className="text-red-500">{errors.password?.message}</p>
                </div>
                {/*<div>
                  <label className="inline-flex items-center cursor-pointer">
                    <input
                      id="customCheckLogin"
                      type="checkbox"
                      className="text-gray-800 ml-1 w-5 h-5"
                      style={{ transition: "all .15s ease" }}
                      onChange={handleRememberMeEvent}
                    />
                    <span className="ml-2 text-sm font-semibold text-white">
                      Remember me
                    </span>
                  </label>
                </div>*/}

                <div className="text-center mt-6">
                  <button
                    className="bg-secondary-color text-white active:bg-gray-700 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full"
                    type="submit"
                    style={{ transition: "all .15s ease" }}
                  >
                    Sign In
                  </button>
                  {loadingState ? <Circular color="#fff" size="40px" /> : null}
                  {loginInfo.code > 0 ? (
                    <p className="text-red-500">{loginInfo.msg}</p>
                  ) : null}
                </div>
              </form>

              <div className="flex flex-wrap mt-6">
                <div className="w-1/2">
                  <a
                    href="/#"
                    onClick={handleForgotPassword}
                    className="text-white"
                  >
                    <small>Forgot password?</small>
                  </a>
                </div>
                <div className="w-1/2 text-right">
                  <a
                    href="/#"
                    onClick={(e) => {
                      e.preventDefault();
                      signInModal.current.close();
                      createAccountModal.current.open();
                    }}
                    className="text-white"
                  >
                    <small>Create new account</small>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default forwardRef(Login);
