import React, { Component } from "react";

import {
  Card,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";

import CompareFileUpload from "../../commonComponents/CompareFileUpload";
import CustomMatch from "../../commonComponents/CustomMatch";
import CustomMatchReport from "../../components/UploadParseResumeComponents/CustomMatchReport";

import CloseIcon from "@material-ui/icons/Close";

import $ from "jquery";
import API from "../../utils/API/resumeAPI";
import * as c from "../../utils/constants/constants";
import * as urc from "../../utils/constants/uploadResumeConstants";
import * as sampleJson from "../../utils/sampleJson/sampleUploadParseResumeJson";
import UploadInstruction from "../UploadInstruction/UploadInstruction";
import "./style.css";

class CompareResume extends Component {
  state = {
    uploadedResumes: {},
    processedResumes: {},
    updatedProcessedResumes: {},
    overWrittenFiles: {},
    savedResumeFileNames: [],
    ErrorResumeFileNames: [],
    saveProcessedResumesArr: [],
    customMatchQuery: {
      id: "",
      action: "",
      values: [],
      resumeNames: [],
      date: "",
    },
    customMatchResultSkillTableValues: [],
    isHidden: true,
    customMatchReportAction: { action: "", timeStamp: "" },
    isAllFileTypeMatch: true,
    editable: false,
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    address: "",
    linkedin: "",
    summary: "",
    skill_matrix: "",
    education: "",
    certification: "",
    work_experience: [],
    flags: [],
    grammar_check: [],
    inferred_title: [],
    irrelevant_line_items: [],
    keyword_score: [],
    other_links: [],
    pdf_link: "",
    punctuation_check_list: [],
    repeated_work_exp: [],
    top_skills_hierarchy: [],
    top_skills_raw: [],
    disabled: false,
    loadData: {},
    loading: false,
    dopen: false,
    cloading: false,
    fileLen: 0,
    uploadResumes: [],
    skillSetList: [],
    userNameList: [],
    errorResumes: [],
    inferred_title: [],
    fileLength: false,
    category: "",
  };

  handleFileUpload = (event) => {
    this.setState({ processedResumes: {} });
    this.setState({ uploadedResumes: {} });

    let files = event.target.files;
    console.log(files, "|||||||");

    if (event.target.files.length >= 1) {
      this.setState({ loading: true });
      let fileLen = files.length;
      this.setState({ fileLen: fileLen });

      //let timeoutDiff = 0;

      // let uploadedResumeLength = Object.keys(this.state.uploadedResumes).length;
      //
      //let uploadedResumesObjLength = this.state.uploadedResumes ? Object.keys(this.state.uploadedResumes).length : 0;

      let filesLeftToReachLimit = 20 - this.state.uploadResumes.length;
      var uploadedata = this.state.uploadResumes;
      console.log(this.state.uploadResumes.length, "***********fa");
      //

      if (fileLen <= filesLeftToReachLimit) {
        for (let fileIndex = 0; fileIndex < files.length; fileIndex++) {
          var eachFile = files[fileIndex];
          //
          //eslint-disable-next-line
          let fileName = eachFile.name.replace(/[\/\\+'"–]/g, "_");

          // let fileName = files[fileIndex].name;
          var isFileTypeMatch = fileName
            .toLowerCase()
            .match(
              /^[\w~`!@#$%^&*(){}[\]|:";'<>,.?+= _\-–]+(.doc|.docx|.pdf)$/
            );

          if (isFileTypeMatch) {
            //('test11');
            this.setState({ isAllFileTypeMatch: true });
            uploadedata.push(eachFile);

            let uploadedResumesObj = this.state.uploadResumes;
            let processedResumesObj = this.state.processedResumes;
            let overWrittenFilesObj = this.state.overWrittenFiles;

            if (uploadedResumesObj.hasOwnProperty(fileName)) {
              //
              //
              delete processedResumesObj[fileName];
              delete uploadedResumesObj[fileName];

              overWrittenFilesObj[fileName] = true;

              this.setState({ processedResumes: processedResumesObj });
              this.setState({ loading: false });
              this.setState({ uploadedResumes: uploadedResumesObj });
            }
            //
            //
            //this.setState({uploadResumes:files});
            else this.setState({ loading: false });
            overWrittenFilesObj[fileName] = false;
            /*	this.uploadResumeAPI(eachFile,fileName);
					setTimeout(() => {
						this.updateProgressBar(fileName, 10);
					}, timeoutDiff);
					timeoutDiff += 1000;*/
          } else {
            //
            this.setState({ isAllFileTypeMatch: false });
            this.setState({ dopen: true });
            this.setState({ loading: false });
          }
        }
        this.setState({ uploadResumes: uploadedata });
      } else if (this.state.uploadResumes.length >= 21) {
        this.setState({ dopen: true });
        this.setState({ loading: false });
        this.setState({ isAllFileTypeMatch: false });
      } else if (this.state.uploadResumes.length === 20) {
        this.setState({ dopen: true });
        this.setState({ loading: false });
        this.setState({ fileLength: true });
        this.setState({ isAllFileTypeMatch: false });
      } else {
        this.setState({ dopen: true });
        this.setState({ loading: false });
        this.setState({ fileLength: false });
        this.setState({ isAllFileTypeMatch: false });
      }
    }

    // else if (isAllFilesMatch === false)
    // {
    // 	this.setState({isAllFileTypeMatch : isAllFilesMatch});
    // 	$('#openFileUploadErrorMsgPopUp').click();
    // }
  };

  handleClose = () => {
    this.setState({ dopen: false });
  };

  handleFileClose = (name) => {
    var newupload = [];
    this.state.uploadResumes.map((item) => {
      if (item.name !== name) {
        newupload.push(item);
      }
      return null;
    });
    this.setState({ uploadResumes: newupload });
    this.setState({ fileLen: this.state.uploadResumes.length - 1 });
  };

  handleCheckAll = (event) => {
    let isChecked = event.target.checked;
    let uploadedResumes = this.state.uploadedResumes;
    for (let fileName in uploadedResumes) {
      if (this.isResumeValid(fileName)) {
        let changeValueObj = {};
        changeValueObj[urc.UPLOADED_RESUME_RESULTS_OBJ_IS_SELECTED] = isChecked;
        this.updateUploadedResumeDetails(fileName, changeValueObj);
      }
    }
  };
  checkAllValidResumes = () => {
    let checked = 0;
    let unchecked = 0;
    let uploadedResumes = this.state.uploadedResumes;
    for (let fileName in uploadedResumes) {
      if (this.isResumeValid(fileName)) {
        uploadedResumes[fileName][
          urc.UPLOADED_RESUME_RESULTS_OBJ_IS_SELECTED
        ] === true
          ? checked++
          : unchecked++;
      }
    }
    if (unchecked === 0) return true;
    else return false;
  };

  handleResumeResultsCheckBox = (event, fileName) => {
    let uploadedResumes = this.state.uploadedResumes;
    let currentFileValues = uploadedResumes[fileName];
    currentFileValues[urc.UPLOADED_RESUME_RESULTS_OBJ_IS_SELECTED] =
      event.target.checked;
    this.updateUploadedResumeDetails(fileName, currentFileValues);
  };

  isResumeValid = (fileName) => {
    let uploadedResumes = this.state.uploadedResumes;
    let currentFileValues = uploadedResumes[fileName];

    if (
      currentFileValues[urc.UPLOADED_RESUME_RESULTS_OBJ_PROCESSED_STATUS] !==
        c.FILE_STATUS_ERROR &&
      currentFileValues[urc.UPLOADED_RESUME_RESULTS_OBJ_PROCESSED_STATUS] !==
        c.FILE_STATUS_PROCESSING
    ) {
      if (
        this.props.inUploadResumePage === true &&
        currentFileValues[
          urc.UPLOADED_RESUME_RESULTS_OBJ_UPLOADED_USER_MSG_CATERGORY
        ] !== urc.PROCESSED_RESUME_JSON_WORK_EXPERIENCE &&
        currentFileValues[
          urc.UPLOADED_RESUME_RESULTS_OBJ_UPLOADED_USER_MSG_CATERGORY
        ] !== ""
      )
        return false;
      else return true;
    } else return false;
  };

  updateUploadedResumeDetails = (fileName, changeValues) => {
    let resumes = this.state.uploadedResumes;
    let fileValues = resumes[fileName] ? resumes[fileName] : {};
    //

    // fileValues = {
    // 	progress: progress,
    // 	isSelected: isSelected,
    // 	status: status,
    // 	userMsg: userMsg,
    // 	iscustomMatched : iscustomMatched
    // };

    if (!fileValues) {
      fileValues[urc.UPLOADED_RESUME_RESULTS_OBJ_PROGRESS] = 0;
      fileValues[urc.UPLOADED_RESUME_RESULTS_OBJ_IS_SELECTED] = false;
      fileValues[urc.UPLOADED_RESUME_RESULTS_OBJ_PROCESSED_STATUS] =
        c.FILE_STATUS_PROCESSING;
      fileValues[urc.UPLOADED_RESUME_RESULTS_OBJ_UPLOADED_USER_MSG] = "";
      fileValues[urc.UPLOADED_RESUME_RESULTS_OBJ_UPLOADED_USER_MSG_CATERGORY] =
        "";
      fileValues[urc.UPLOADED_RESUME_RESULTS_OBJ_IS_CUSTOM_MATCHED] = false;
    }

    for (let key in changeValues) {
      fileValues[key] = changeValues[key];
    }

    resumes[fileName] = fileValues;
    this.setState({ uploadedResumes: resumes });
  };

  updateProgressBar = (fileName, progress) => {
    let interval = setInterval(() => {
      let resumes = this.state.uploadedResumes;
      let fileValues = resumes[fileName];

      //

      if (
        progress < 95 &&
        (fileValues && fileValues.progress === 100 ? false : true)
      ) {
        let changeValueObj = {};
        changeValueObj[urc.UPLOADED_RESUME_RESULTS_OBJ_PROGRESS] = progress;
        changeValueObj[urc.UPLOADED_RESUME_RESULTS_OBJ_IS_SELECTED] = false;
        changeValueObj[urc.UPLOADED_RESUME_RESULTS_OBJ_PROCESSED_STATUS] =
          c.FILE_STATUS_PROCESSING;
        changeValueObj[urc.UPLOADED_RESUME_RESULTS_OBJ_UPLOADED_USER_MSG] = "";
        changeValueObj[
          urc.UPLOADED_RESUME_RESULTS_OBJ_UPLOADED_USER_MSG_CATERGORY
        ] = "";
        changeValueObj[
          urc.UPLOADED_RESUME_RESULTS_OBJ_IS_CUSTOM_MATCHED
        ] = false;

        this.updateUploadedResumeDetails(fileName, changeValueObj);
        progress += 5;
      } else clearInterval(interval);
    }, 500);
  };

  uploadResumeAPI = (file, fileName) => {
    var formData = new FormData();
    formData.append("files[0]", file, fileName);

    API.uploadResume(formData)
      .then((res) => {
        //

        let status = c.FILE_STATUS_COMPLETED;

        if (this.state.overWrittenFiles[fileName])
          status = c.FILE_STATUS_OVERWRITTEN;

        //

        // this.setState({name:res.data.first_name});
        this.setState({ first_name: res.data.first_name });
        this.setState({ last_name: res.data.last_name });
        this.setState({ email: res.data.email });
        this.setState({ phone: res.data.phone });
        this.setState({ address: res.data.address });
        this.setState({ linkedin: res.data.linked_in });
        this.setState({ summary: res.data.summary });
        this.setState({ skill_matrix: res.data.skill_matrix });
        this.setState({ education: res.data.education });
        this.setState({ certification: res.data.certification });
        this.setState({ work_experience: res.data.work_experience });

        this.setState({ flags: res.data.insights.flags });
        this.setState({ grammar_check: res.data.insights.grammar_check });
        this.setState({ inferred_title: res.data.insights.inferred_title });
        this.setState({
          irrelevant_line_items: res.data.insights.irrelevant_line_items,
        });
        this.setState({ keyword_score: res.data.insights.keyword_score });
        this.setState({ other_links: res.data.insights.other_links });

        this.setState({ pdf_link: res.data.pdf_link });
        this.setState({
          punctuation_check_list: res.data.insights.punctuation_check_list,
        });
        this.setState({
          repeated_work_exp: res.data.insights.repeated_work_exp,
        });
        this.setState({ top_skills_hierarchy: res.data.top_skills_hierarchy });
        this.setState({ top_skills_raw: res.data.top_skills_raw });

        this.setState({ loadData: res.data });
        this.setState({ loading: false });

        this.updateUploadResumeFromResponse(res.data, fileName, status, true);
      })
      .catch((error) => {
        let status = c.FILE_STATUS_ERROR;
        if (window.location.host === c.LOCAL_ENVIORNMENT) {
          status = c.FILE_STATUS_COMPLETED;
          // let status = c.FILE_STATUS_COMPLETED;
          this.updateUploadResumeFromResponse(
            sampleJson.uploadResumeResponseJson2,
            fileName,
            status,
            false
          );
        } else this.updateUploadResumeFromResponse("", fileName, status, false);
        // Error
        c.APIErrorResponse(error, "Upload Resume Request");
      });
  };

  updateUploadResumeFromResponse = (
    uploadResponse,
    fileName,
    status,
    isSelected
  ) => {
    this.updateProgressBar(fileName, 100);
    let changeValueObj = urc.validationBeforeSavingResume(
      "uploadJson",
      uploadResponse,
      isSelected,
      status,
      this.props.inUploadResumePage
    );
    this.updateUploadedResumeDetails(fileName, changeValueObj);

    if (uploadResponse !== "") {
      let processedResumesObj = this.state.processedResumes;
      processedResumesObj[fileName] = uploadResponse;
      this.setState({ processedResumes: processedResumesObj });
    }
  };

  handleUpdateProcessedResume = (fileName, updatedResume) => {
    let isSelected = true;

    let changeValueObj = urc.validationBeforeSavingResume(
      "saveJson",
      updatedResume,
      isSelected,
      "",
      this.props.inUploadResumePage
    );
    this.updateUploadedResumeDetails(fileName, changeValueObj);

    let updatedProcessedResumes = this.state.updatedProcessedResumes;
    updatedProcessedResumes[fileName] = updatedResume;

    //
    this.setState({ updatedProcessedResumes: updatedProcessedResumes });
  };

  saveProcessedResume = () => {
    // event.target.disabled = true;

    let saveProcessedResumesArr = [];

    let uploadedResumes = this.state.uploadedResumes;
    let savedResumeFileNames = [];
    let ErrorResumeFileNames = [];
    let savedCandidateNames = [];
    for (let key in uploadedResumes) {
      if (uploadedResumes[key]["isSelected"]) {
        let updatedResumeForSaving = this.state.updatedProcessedResumes[key];
        //

        let fullName = updatedResumeForSaving.name.trim();
        if (fullName.indexOf(" ") > 0) {
          savedCandidateNames.push(fullName.split(" ")[0]);
          savedCandidateNames.push(fullName.split(" ")[1]);
        } else savedCandidateNames.push(fullName);

        saveProcessedResumesArr.push(updatedResumeForSaving);
        savedResumeFileNames.push(key);
      } else ErrorResumeFileNames.push(key);
    }
    if (this.props.inUploadResumePage)
      this.props.sendCandidateNames(savedCandidateNames);

    this.setState({ savedResumeFileNames: savedResumeFileNames });
    this.setState({ ErrorResumeFileNames: ErrorResumeFileNames });
    this.setState({ saveProcessedResumesArr: saveProcessedResumesArr });
  };

  saveProcessedResumeAPI = () => {
    let savedResumeFileNames = this.state.savedResumeFileNames;

    if (this.props.inUploadResumePage) this.props.loadingPage(true);
    else {
      for (let key in savedResumeFileNames) {
        let changeValueObj = {};
        changeValueObj[urc.UPLOADED_RESUME_RESULTS_OBJ_PROCESSED_STATUS] =
          c.FILE_STATUS_PROCESSING;
        this.updateUploadedResumeDetails(
          savedResumeFileNames[key],
          changeValueObj
        );
      }
    }

    let saveProcessedResumesArr = this.state.saveProcessedResumesArr;

    //

    // if (saveProcessedResumesArr.length !== 0) {
    if (localStorage.getItem("tal_token") != null) {
      var options = {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + localStorage.getItem("tal_token"),
        },
      };
    } else {
      var options = {
        headers: { "Content-Type": "multipart/form-data" },
      };
    }

    API.saveProcessedResumes(saveProcessedResumesArr)
      .then((res) => {
        //

        if (this.props.inUploadResumePage) this.props.loadingPage(false);
        else {
          let savedResumeFileNames = this.state.savedResumeFileNames;
          for (let key in savedResumeFileNames) {
            let changeValueObj = {};
            changeValueObj[urc.UPLOADED_RESUME_RESULTS_OBJ_PROCESSED_STATUS] =
              c.FILE_STATUS_SAVED;
            this.updateUploadedResumeDetails(
              savedResumeFileNames[key],
              changeValueObj
            );
          }
        }
      })
      .catch((error) => {
        if (this.props.inUploadResumePage) this.props.loadingPage(false);
        c.APIErrorResponse(error, "Save Processed Resumes");
      });
    // }
  };

  clearValues = () => {
    //
    this.setState({ customMatchResultSkillTableValues: [] });
  };

  formatCustomMatchSkillJson = () => {
    //('updatedProcessedResumes',this.state.updatedProcessedResumes);
    //
    this.setState({ fileLength: false });
    let customMatchQueryObj = this.state.customMatchQuery;
    customMatchQueryObj.resumeNames = [];

    this.state.uploadResumes.map((item) => {
      customMatchQueryObj.resumeNames.push(item.name);
    });

    let selectedResumes = Object.entries(
      this.state.updatedProcessedResumes
    ).filter(
      ([key, value]) => customMatchQueryObj.resumeNames.indexOf(key) >= 0
    );
    //
    let customMatchContentArr = [];

    for (let index in selectedResumes) {
      customMatchContentArr.push({
        file_name: selectedResumes[index][0],
        skill_list:
          selectedResumes[index][1][
            urc.PROCESSED_RESUME_JSON_TOP_SKILLS_HIERARCHY
          ],
      });
    }
    //

    // let customMatchRequestJson = {
    // 		skill:{action : 'skill', values : ['Junit','HTML']},
    // 		title:{action : 'title', values : ['Java Developer']},
    // 		match_content : [
    // 							{file_name : '', skill_list : []},
    // 							{file_name : '', skill_list : []}
    // 						],
    // 	};

    //

    let customMatchRequestJson = {
      skill: customMatchQueryObj.skill,
      title: customMatchQueryObj.title,
    };

    //

    if (this.state.uploadResumes.length <= 1) {
      this.setState({ dopen: true });
    } else if (customMatchQueryObj.action === "") {
      this.setState({ dopen: true });
    } else {
      this.customMatchSkillAPI(customMatchRequestJson, customMatchQueryObj);
    }
  };

  customMatchSkillAPI = async (customMatchRequestJson, customMatchQueryObj) => {
    console.log(customMatchRequestJson, "customMatchRequestJson-------");
    let formattedCustomMatchResultSkillTableValues = [
      ...this.state.customMatchResultSkillTableValues,
    ];

    var formData = new FormData();

    for (let i = 0; i < this.state.uploadResumes.length; i++) {
      formData.append("file", this.state.uploadResumes[i]);
    }

    formData.append("skill", JSON.stringify(customMatchRequestJson.skill));
    formData.append("title", JSON.stringify(customMatchRequestJson.title));
    formData.append("category", this.state.category);

    this.setState({
      cloading: true,
      errorResumes: [],
    });

    // formData.append('skill',customMatchRequestJson.skill);
    // formData.append('title',customMatchRequestJson.title);
    if (localStorage.getItem("tal_token") != null) {
      var options = {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + localStorage.getItem("tal_token"),
        },
        timeout: 300000,
      };
    } else {
      var options = {
        headers: { "Content-Type": "multipart/form-data" },
        timeout: 300000,
      };
    }

    const isTitleEmpty = Object.keys(customMatchRequestJson.title).length === 0;
    const isSkillEmpty = Object.keys(customMatchRequestJson.skill).length === 0;

    const findPromiseArr = () => {
      if (!isTitleEmpty && !isSkillEmpty) {
        return [
          API.getInferredTitle(formData, options),
          API.customMatchSkillResumes(formData, options),
        ];
      } else if (!isSkillEmpty) {
        return [API.customMatchSkillResumes(formData, options)];
      } else {
        return [API.getInferredTitle(formData, options)];
      }
    };

    Promise.allSettled(findPromiseArr()).then((response) => {
      let res = {
        data: {
          result: [],
        },
      };
      if (!response.find((el) => el.status === "rejected")) {
        console.log(response, "|||||||||||||");

        let inferredRes = null;
        let matchSkillRes = null;

        if (!isTitleEmpty && !isSkillEmpty) {
          inferredRes = !isTitleEmpty ? response[0].value.data : null;
          matchSkillRes = !isSkillEmpty ? response[1].value.data : null;
        } else if (!isTitleEmpty) {
          inferredRes = !isTitleEmpty ? response[0].value.data : null;
        } else {
          matchSkillRes = !isSkillEmpty ? response[0].value.data : null;
        }

        if (inferredRes?.failed_resumes) {
          this.setState({
            errorResumes: inferredRes.failed_resumes,
            cloading: false,
            customMatchResultSkillTableValues: [],
          });
        } else if (matchSkillRes?.failed_resumes) {
          this.setState({
            errorResumes: matchSkillRes.failed_resumes,
            cloading: false,
            customMatchResultSkillTableValues: [],
          });
        } else {
          console.log("matchSkillRes: ", matchSkillRes);
          console.log("inferredRes: ", inferredRes);

          if (!isSkillEmpty && !isTitleEmpty) {
            res.data.result = matchSkillRes.result.map((el, ind) => {
              return {
                ...el,
                infered_title: inferredRes.result[ind].infered_title,
              };
            });
          } else if (!isSkillEmpty) {
            if (matchSkillRes.failed_resumes) {
              res.data.result = matchSkillRes.failed_resumes.map((el, ind) => {
                return {
                  ...el,
                  infered_title: [],
                };
              });
            } else {
              res.data.result = matchSkillRes.result.map((el, ind) => {
                return {
                  ...el,
                  infered_title: [],
                };
              });
            }
          } else {
            res.data.result = inferredRes.result.map((el, ind) => {
              return {
                ...el,
              };
            });
          }

          console.log(res.data.result, "$$$$$$$$$$");

          if (res.data.result) {
            let copyOfNameList = [...this.state.userNameList];
            let copyOfInferred_title = [];

            console.log(res.data.result, "++++++++");

            res.data.result.map((el) => {
              copyOfNameList.push(el.file_name);
              if (el.infered_title?.length !== 0) {
                if (el.infered_title.length > 1) {
                  let tempTitle = el.infered_title
                    .map((elem) => elem.title)
                    .join(", ");
                  copyOfInferred_title.push(tempTitle);
                } else {
                  let tempTitle = el.infered_title[0].title;
                  copyOfInferred_title.push(tempTitle);
                }
              } else {
                copyOfInferred_title.push("");
              }
            });
            console.log(
              copyOfInferred_title,
              "copyOfInferred_title---------------------"
            );

            this.setState({
              userNameList: copyOfNameList,
              inferred_title: copyOfInferred_title,
            });
          } else if (res.data.failed_resumes) {
            this.setState({
              errorResumes: res.data.failed_resumes,
            });
          }

          this.setState({ customMatchResultSkillTableValues: [] });
          let reportObj = urc.formatCustomSkillMatchJsonFromResponse(
            res.data.result,
            customMatchQueryObj
          );

          formattedCustomMatchResultSkillTableValues.unshift(reportObj);
          console.log(
            "formattedCustomMatchResultSkillTableValues: ",
            formattedCustomMatchResultSkillTableValues
          );

          if (
            formattedCustomMatchResultSkillTableValues.length !== 0 &&
            !isSkillEmpty
          ) {
            let skillSetList = [];
            formattedCustomMatchResultSkillTableValues[0].titleMatchResumeList.map(
              (el, ind) => {
                console.log(el, "el++++");
                if (el.skills_display) {
                  el.skills_display.map((elem, ind) => {
                    skillSetList.push(elem.skill_name);
                  });
                }
              }
            );
            let uniqueList = [...new Set(skillSetList)];

            console.log(
              formattedCustomMatchResultSkillTableValues,
              "formattedCustomMatchResultSkillTableValues^^^^^^^^^^"
            );

            for (
              let i = 0;
              i <
              formattedCustomMatchResultSkillTableValues[0].titleMatchResumeList
                .length;
              i++
            ) {
              let copyOfSkillDisplay =
                formattedCustomMatchResultSkillTableValues[0]
                  .titleMatchResumeList[i].skills_display;

              for (let j = 0; j < skillSetList.length; j++) {
                if (
                  !copyOfSkillDisplay.find(
                    (el) => el.skill_name === skillSetList[j]
                  )
                ) {
                  copyOfSkillDisplay.push({
                    skill_name: skillSetList[j],
                    isNotPresent: true,
                  });
                }
              }
              // copyOfSkillDisplay.sort((a, b) =>
              //   a.skill_name > b.skill_name ? 1 : -1
              // );
              formattedCustomMatchResultSkillTableValues[0].titleMatchResumeList[
                i
              ].skills_display = copyOfSkillDisplay;
            }

            console.log(
              formattedCustomMatchResultSkillTableValues,
              "*********_____*******"
            );

            this.setState({
              skillSetList: uniqueList,
              customMatchResultSkillTableValues:
                formattedCustomMatchResultSkillTableValues,
              cloading: false,
            });
          } else {
            this.setState({
              cloading: false,
              customMatchResultSkillTableValues:
                formattedCustomMatchResultSkillTableValues,
            });
          }
        }
      } else {
        // c.APIErrorResponse(error, "compare resume");
        console.log(response, "failed response+++++++++++++");
        this.setState({
          cloading: false,
          customMatchResultSkillTableValues: [],
        });
      }
    });
  };

  customMatchReportUpdate = (action, timeStamp, downloadFlag) => {
    let formattedValues = this.state.customMatchResultSkillTableValues;
    let updatedformattedValues = [];

    if (
      (action === "download" && timeStamp !== "" && downloadFlag === false) ||
      (action === "downloadAll" &&
        formattedValues.filter((element) => element.downloadFlag === false)
          .length > 0)
    ) {
      urc.downloadReport(formattedValues, timeStamp);
      formattedValues.map((element) => {
        if (timeStamp === "" || timeStamp === element.timeStamp)
          element.downloadFlag = true;

        return null;
      });
      this.setState({ customMatchResultSkillTableValues: formattedValues });
    } else if (
      (action === "delete" && timeStamp !== "" && downloadFlag === true) ||
      (action === "deleteAll" &&
        formattedValues.filter((element) => element.downloadFlag === false)
          .length === 0)
    ) {
      updatedformattedValues =
        timeStamp !== ""
          ? formattedValues.filter((element) => element.timeStamp !== timeStamp)
          : [];
      this.setState({
        customMatchResultSkillTableValues: updatedformattedValues,
      });
    } else if (
      (action === "delete" && timeStamp !== "" && downloadFlag === false) ||
      (action === "download" && timeStamp !== "" && downloadFlag === true) ||
      (action === "deleteAll" &&
        timeStamp === "" &&
        formattedValues.filter((element) => element.downloadFlag === false)
          .length > 0) ||
      (action === "downloadAll" &&
        formattedValues.filter((element) => element.downloadFlag === false)
          .length === 0)
    ) {
      this.setState({
        customMatchReportAction: {
          action: action,
          timeStamp: timeStamp,
          downloadFlag: downloadFlag,
        },
      });
      $("#skillMatchReportPopupTrigger").click();
    }
  };

  handleEditable = () => {
    this.setState({ editable: !this.state.editable });
  };

  handleSubmit = (event) => {
    this.setState({ editable: true });
    this.setState({ [event.target.name]: event.target.value });
  };
  handleWorkSubmit = (event) => {
    this.setState({ editable: true });

    const work = this.state.work_experience.slice();

    work[event.target.id][event.target.name] = event.target.value;
    //
    this.setState({ work_experience: work });
  };

  handleFormSubmit = () => {
    var formData = new FormData();

    formData.append("first_name", this.state.first_name);
    formData.append("last_name", this.state.last_name);
    formData.append("email", this.state.email);
    formData.append("phone", this.state.phone);
    formData.append("address", this.state.address);
    formData.append("linkedin", this.state.linkedin);
    formData.append("summary", this.state.summary);
    formData.append("skill_matrix", this.state.skill_matrix);
    formData.append("education", this.state.education);
    formData.append("certification", this.state.certification);
    formData.append(
      "work_experience",
      JSON.stringify(this.state.work_experience)
    );

    formData.append(
      "top_skills_hierarchy",
      JSON.stringify(this.state.top_skills_hierarchy)
    );
    formData.append(
      "top_skills_raw",
      JSON.stringify(this.state.top_skills_raw)
    );

    API.inferResume(formData)
      .then((res) => {
        //
      })
      .catch((error) => {
        // Error
        c.APIErrorResponse(error, "Upload Resume Request");
      });
  };

  handleClear = () => {
    this.setState({
      uploadResumes: [],
      fileLen: 0,
    });
  };

  handleCategoryChange = (e) => {
    this.setState({ category: e.target.value });
  };

  render() {
    console.log(this.state.fileLen, "uploadResumes__________________________");

    //let isHidden = ((this.props.inUploadResumePage === false) && (this.state.isHidden=== true));
    //let isCustomMatchDisabled = this.state.customMatchResultSkillTableValues.length >=urc.CUSTOM_MATCH_REPORT_LIMIT ? true : false;
    //
    // let isCustomMatchDisabled =  true;
    //
    return (
      <section className="w-full ">
        <section className="w-full px-6 lg:w-6/12" style={{ margin: "auto" }}>
          <div className="demoinput">
            <div className="category_container">
              <div className="heading_container">
                <h5 className="upload_title compare_resume">Compare Resume</h5>
              </div>
              {/* Select */}
              <FormControl style={{ minWidth: 120 }}>
                <InputLabel id="demo-simple-select-label">
                  Choose One
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={this.state.category}
                  onChange={this.handleCategoryChange}
                >
                  <MenuItem value={"Seasonal Workers Jobs"}>
                    Seasonal Workers Jobs
                  </MenuItem>
                  <MenuItem value={"IT Jobs"}>IT Jobs</MenuItem>
                  <MenuItem value={"Engineer Jobs"}>Engineer Jobs</MenuItem>
                </Select>
              </FormControl>
              {/* <select
                className="select_option"
                value={this.state.category}
                onChange={this.handleCategoryChange}
              >
                <option value={""}>Choose One</option>
                <option value={"Seasonal Workers Jobs"}>
                  Seasonal Workers Jobs
                </option>
                <option value={"IT Jobs"}>IT Jobs</option>
                <option value={"Engineer Jobs"}>Engineer Jobs</option>
              </select> */}
            </div>
            {this.state.category ? (
              <>
                <UploadInstruction />
                <CompareFileUpload
                  isFileLimitReached={
                    this.state.uploadedResumes &&
                    Object.keys(this.state.uploadedResumes).length <
                      urc.FILE_UPLOAD_RESUME_LIMIT
                      ? false
                      : true
                  }
                  fileLength={this.state.fileLength}
                  handleFileUpload={this.handleFileUpload}
                  uploadLimit={20}
                  alreadyUploadedFilesLen={this.state.fileLen}
                  isResumePage={true}
                  isAllFileTypeMatch={this.state.isAllFileTypeMatch}
                  files={this.state.uploadedResumes}
                  loading={this.state.loading}
                  dopen={this.state.dopen}
                  handleClose={this.handleClose}
                  fileLen={this.state.uploadResumes.length}
                />
              </>
            ) : null}
          </div>
          <div id="uploadResume" className="navPages"></div>
        </section>

        {this.state.uploadResumes &&
          this.state.uploadResumes.length >= 1 &&
          this.state.fileLen >= 1 &&
          this.state.fileLen < 21 &&
          // this.state.isAllFileTypeMatch &&
          !this.state.loading && (
            <section
              className="w-full px-6 lg:w-6/12"
              style={{ margin: "auto" }}
            >
              <div className="custominput">
                <h2 className="upload_title">Uploaded Resume</h2>
                {this.state.uploadResumes.map((item) => (
                  <div className="fileresult">
                    <h2>{item.name}</h2>
                    <CloseIcon
                      onClick={() => this.handleFileClose(item.name)}
                    />
                  </div>
                ))}
              </div>
            </section>
          )}

        {this.state.fileLen >= 1 &&
          this.state.fileLen < 21 &&
          // this.state.isAllFileTypeMatch &&
          !this.state.loading && (
            <section
              className="w-full px-6 lg:w-6/12"
              style={{ margin: "auto" }}
            >
              <div className="custominput">
                <h2 className="upload_title">Custom Match</h2>
                <Card>
                  <CustomMatch
                    actionPage={c.MAIN_SECTION_DIV_PARSE_RESUME}
                    //isResumeNotSelected	= {Object.entries(this.state.uploadedResumes).filter(([key,value]) => value.isSelected === true).length === 0}
                    formatCustomMatchSkillJson={this.formatCustomMatchSkillJson}
                    updateCustomMatchQuery={(value) =>
                      this.setState({ customMatchQuery: value })
                    }
                    selectedResumeNames={Object.keys(
                      this.state.processedResumes
                    )}
                    clearValues={this.clearValues}
                    handleClear={this.handleClear}
                    category={{ category: this.state.category }}
                  />
                </Card>
              </div>
            </section>
          )}

        {this.state.errorResumes.length > 0 && (
          <div className="compareloading">
            <div
              style={{ textAlign: "center", display: "block", width: "100%" }}
            >
              <p className="text-red-500 font-bold text-lg">
                {`The following files are not parsed successfully (${this.state.errorResumes.join(
                  ", "
                )}). Kindly remove these files.`}
              </p>
            </div>
          </div>
        )}

        {this.state.cloading && (
          <div className="compareloading">
            <div
              style={{ textAlign: "center", display: "block", width: "100%" }}
            >
              <CircularProgress
                color={"#25a9da"}
                loading={this.state.cloading}
                size={30}
              />
            </div>
          </div>
        )}

        {this.state.customMatchResultSkillTableValues.length > 0 &&
          !this.state.cloading && (
            <section
              className="w-full px-6 lg:w-12/12"
              style={{ margin: "auto" }}
            >
              <div className="creport">
                <CustomMatchReport
                  inferred_title={this.state.inferred_title}
                  userNameList={this.state.userNameList}
                  skillSetList={this.state.skillSetList}
                  customMatchResultSkillTableValues={
                    this.state.customMatchResultSkillTableValues
                  }
                  customMatchReportUpdate={this.customMatchReportUpdate}
                />
              </div>
            </section>
          )}
      </section>
    );
  }
}
export default CompareResume;
