import { Button, Grid, TextField, Typography } from "@material-ui/core";
import axios from "axios";
import { Form, FormikProvider, useFormik } from "formik";
import React, { useEffect, useState } from "react";
import * as yup from "yup";
import { clientEmailSetting } from "../../api/api";

const validationSchema = yup.object({
  client_manager_email_1: yup
    .string()
    .nullable()
    .email("Enter a valid email")
    .optional(),
  client_manager_email_2: yup
    .string()
    .nullable()
    .email("Enter a valid email")
    .optional(),
});

function ClientSettings() {
  const getAndSetClientEmailSettings = () => {
    axios.get(clientEmailSetting()).then((response) => {
      if (response.status === 200) {
        if (response.data.length > 0) {
          setValues({
            ...response.data[0],
          });
        }
      }
    });
  };

  const [success, setSuccess] = useState(false);

  useEffect(() => {
    getAndSetClientEmailSettings();
  }, []);

  const formik = useFormik({
    initialValues: {
      id: "",
      client_manager_email_1: "",
      client_manager_email_2: "",
    },
    validationSchema: validationSchema,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });

  function handleSubmit(validValues) {
    let requestBody = {};
    requestBody.client_manager_email_1 =
      validValues.client_manager_email_1 || null;
    requestBody.client_manager_email_2 =
      validValues.client_manager_email_2 || null;

    if (values.id) {
      requestBody.id = values.id;
    }
    axios.post(clientEmailSetting(), requestBody).then((response) => {
      if (response.status === 201) {
        setValues({ ...response.data });
        setSuccess(true);
      }
    });
  }

  const { values, touched, errors, setValues, handleBlur, handleChange } =
    formik;

  return (
    <FormikProvider value={formik}>
      <Form>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography>
              <b>Client Settings</b>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              name={"client_manager_email_1"}
              variant={"outlined"}
              label={"Client Manager 1 Mail Id"}
              value={values.client_manager_email_1 || ""}
              onChange={handleChange}
              onBlur={handleBlur}
              error={
                touched.client_manager_email_1 &&
                Boolean(errors.client_manager_email_1)
              }
              helperText={
                touched.client_manager_email_1 && errors.client_manager_email_1
              }
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              name={"client_manager_email_2"}
              variant={"outlined"}
              label={"Client Manager 2 Mail Id"}
              value={values.client_manager_email_2 || ""}
              onChange={handleChange}
              onBlur={handleBlur}
              error={
                touched.client_manager_email_2 &&
                Boolean(errors.client_manager_email_2)
              }
              helperText={
                touched.client_manager_email_2 && errors.client_manager_email_2
              }
              fullWidth
            />
          </Grid>
          <Grid item xs={12} container alignItems="center">
            <Button
              size="large"
              type={"submit"}
              variant={"contained"}
              color={"primary"}
            >
              Submit
            </Button>
          </Grid>
          <Grid item xs={12} container alignItems="center">
            <Typography
              style={{
                color: "#008000",
                visibility: success ? "visible" : "hidden",
              }}
            >
              {success ? "Saved successfully" : "_"}
            </Typography>
          </Grid>
        </Grid>
      </Form>
      <br />
    </FormikProvider>
  );
}

export default ClientSettings;
