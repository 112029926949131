import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import ExtractSkillDisplayTable from "./ExtractSkillDisplayTable";
import { useAlert } from "react-alert";
import useOutsideClick from "./OutSideAlert";

// Spinners
import { DotScale } from "styled-loaders-react";

// Import API
import axios from "axios";
import { extractSkills_API, extractSkills_API_NOTLOGIN } from "../../api/api";

// auth service
import AuthService from "../Login/AuthService";
import "./style.css";
// axios csrf
axios.defaults.xsrfHeaderName = "X-CSRFToken";
axios.defaults.xsrfCookieName = "csrftoken";

// Test values without backend
/* const test_skills = [
  {
    skill: "Java",
    experience: "3-5 years",
  },
  {
    skill: "Python",
    experience: "Atleast 2 years",
  },
  {
    skill: "Cloud - AWS, GCP",
    experience: "5+ years",
  },
  {
    skill: "Ideation to Sunsetting",
    experience: "1 year",
  },
  {
    skill: "Digital Product Management",
    experience: "Atleast 2 years",
  },
  {
    skill: "MongoDB",
    experience: "Atleast 2 years",
  },
  {
    skill: "Digital Transformation in consumer products",
    experience: "3 - 5 years",
  },
]; */

const ExtractSkills = () => {
  const ref = useRef();
  const [skills, setSkills] = useState("");
  const [inputJD, setinputJD] = useState("");
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [errorState, setErrorState] = useState({
    code: -1,
    state: null,
    msg: "",
  });

  // constants for pending state
  const [loadingState, setloadingState] = useState(false);

  // use alert hook
  const alert = useAlert();

  // redux
  // const dispatch = useDispatch();

  // **** FOR AUTHSERVICE ****
  // Get redux state and initialize dispatcher
  const userLoginStatusVal = useSelector((state) => state.loginStatus);

  //console.log(userLoginStatusVal);

  //console.log(userLoginStatusVal.code)

  // get input value of job
  const handleChange = (e) => {
    let value = e.target.value;
    let target = value.trim();
    if (target === "") {
      setButtonDisabled(true);
    } else {
      setButtonDisabled(false);
    }

    setinputJD(e.target.value);
    e.preventDefault();
  };

  useOutsideClick(ref, () => {
    setErrorState({
      code: 200,
      state: false, // I have changed this flag to false becoz when i changing the dropdown value the table gets dissappear
      msg: "",
    });
    // document.getElementById("jd").value = ""; I have commented this line because when i click outside the the JD gets blank
  });

  useEffect(() => {
    /*
    Read redux state for login
    if not logged in:
      Validate endpoint by calling auth service.
      Make sure to update redux state with the returned response
    Do the same for app.js

        Other apis scenarios:
         1. Logged in for extract skills
          a. Handle token and refresh using 403 access
          b. If failed Show please log in (login modal?)
         2. Not logged in for extract skills: Show please log in message (login modal?)

     */
    //console.log("Extract skills mounted");
    //console.log("Login Status: " + userLoginStatusVal.login);
    // **** FOR AUTHSERVICE ****
    /*if (!userLoginStatusVal.login) {
      const authStatus = async () => {
        var resp = await AuthService();

        if (resp.code === 200) {
          // on success, enable show button
          document.getElementById("parseButton").disabled = false;
        } else if (resp.code !== 200 && userLoginStatusVal.code !== 0) {
          // 0 code is for log out
          alert.show(resp.msg, {
            header: "Error!",
            ctype: "failure",
          });
          document.getElementById("parseButton").disabled = true;
          // enforce logout
          if (resp.code === 404) {
            dispatch(
              userLoginStatus({
                code: 0,
                login: false,
                msg: "User logged out!",
              })
            );
          }
        }
      };
      authStatus();
    } else {
      document.getElementById("parseButton").disabled = false;
    } */
    // eslint-disable-next-line
  }, [userLoginStatusVal.login]);

  const getSkills = () => {
    // start loader
    setloadingState(true);
    document.getElementById("parseButton").disabled = true;

    const authStatus = async () => {
      var resp = await AuthService();

      if (resp.code === 200) {
        axios
          .post(
            extractSkills_API,
            {
              text_content: inputJD,
            },
            {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("tal_token"),
              },
            }
          )
          .then((response) => {
            setSkills(JSON.parse(JSON.stringify(response.data)));
            setloadingState(false);

            setErrorState({
              code: 200,
              state: false,
              msg: "Success",
            });
            document.getElementById("parseButton").disabled = false;
            //setparseButtonState("enabled");
          })
          .catch((error) => {
            console.log(error);
            if (error.response) {
              if (error.response.status === 429) {
                alert.show(
                  "Sorry you have reached your limit. Please upgrade your account to have unrestricted access.",
                  {
                    header: "Error!",
                    ctype: "failure",
                  }
                );
              }
              setErrorState({
                code: error.response.status,
                state: true,
                msg: "",
              });
            }
            document.getElementById("parseButton").disabled = false;
            setloadingState(false);
          });
      } else if (resp.code !== 200) {
        axios
          .post(
            extractSkills_API_NOTLOGIN,
            {
              text_content: inputJD,
            }
            // {
            //   headers: {
            //     Authorization: "Bearer " + localStorage.getItem("tal_token"),
            //   },
            // }
          )
          .then((response) => {
            setSkills(JSON.parse(JSON.stringify(response.data)));
            setloadingState(false);

            setErrorState({
              code: 200,
              state: false,
              msg: "Success",
            });
            document.getElementById("parseButton").disabled = false;
            //setparseButtonState("enabled");
          })
          .catch((error) => {
            if (error.response) {
              if (error.response.status === 429) {
                alert.show(
                  "Sorry you have reached your limit. Please wait for 5 minutes before trying again or register to increase your limit.",
                  {
                    header: "Error!",
                    ctype: "failure",
                  }
                );
              }
              setErrorState({
                code: error.response.status,
                state: true,
                msg: "",
              });
            }
            document.getElementById("parseButton").disabled = false;
            setloadingState(false);
          });
      }
    };
    authStatus();

    // call endpoint
  };

  //console.log(loginState);

  // get API response
  /*const getSkills = () => {
    // start loader
    setloadingState(true);
    document.getElementById("parseButton").disabled = true;
    //setparseButtonState("disabled");

    // call endpoint
    axios
      .post(
        extractSkills_API,
        {
          text_content: inputJD,
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("tal_token"),
          },
        }
      )
      .then((response) => {
        setSkills(JSON.parse(JSON.stringify(response.data)));
        setloadingState(false);

        setErrorState({
          code: 200,
          state: false,
          msg: "Success",
        });

        document.getElementById("parseButton").disabled = false;
        //setparseButtonState("enabled");
      })
      .catch(() => {
        //setTimeout(() => {}, 3000);
        //setSkills(test_skills);

        // Check if the token is expired and needs a refresh
        // **** FOR AUTHSERVICE ****
        const authStatus = async () => {
          var resp = await AuthService();

          if (resp.code === 200) {
            // on success, enable show button
            document.getElementById("parseButton").disabled = false;
          } else if (resp.code !== 200) {
            setErrorState({
              code: resp.code,
              state: true,
              msg: resp.msg,
            });
            document.getElementById("parseButton").disabled = true;
            // enforce logout
            if (resp.code === 404) {
              dispatch(
                userLoginStatus({
                  code: 0,
                  login: false,
                  msg: "User logged out!",
                })
              );
            }
          }
        };
        authStatus();

        setloadingState(false); // must be always set to false

        document.getElementById("parseButton").disabled = false;
        //setparseButtonState("enabled");
      });
  };*/

  return (
    <section className="w-full px-6 lg:w-6/12 m-auto" ref={ref}>
      <p className="text-center my-1">Add job description to get top skills</p>
      <div className="flex flex-col">
        <div className="py-1">
          <textarea
            id="jd"
            className="p-4 h-64 border border-gray-200 focus:outline-none w-full shadow-md rounded-lg"
            placeholder="Type/Paste job description here..."
            onChange={handleChange}
          ></textarea>
        </div>
        <div
          className={
            buttonDisabled
              ? "pointer-event flex justify-center"
              : "flex justify-center"
          }
        >
          <input
            id="parseButton"
            type="submit"
            value="Show"
            className="c-btn c-btn-secondary c-btn-outline"
            onClick={(e) => {
              e.preventDefault();
              getSkills();
            }}
          />
        </div>
        <div className="inline-block h-0">
          {loadingState ? <DotScale color="#25a9da" /> : null}
        </div>
        {errorState.state === true ? (
          <p className="text-center my-1">{errorState.msg}</p>
        ) : errorState.state === false && skills ? (
          <ExtractSkillDisplayTable skills={skills} />
        ) : null}
      </div>
    </section>
  );
};

export default ExtractSkills;
