import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import axios from "axios";
import { Form, FormikProvider, useFormik } from "formik";
import React, { useEffect, useState } from "react";
import * as yup from "yup";
import { getAllJob, scoreSetting } from "../../api/api";
import { Autocomplete } from "@material-ui/lab";
import { MESSAGES } from "../../utils/constants/constants";
import CustomSnack from "../../commonComponents/Snack/CustomSnack";
import { errorResponse } from "../../utils/constants/helpers";

const SCORE_OPTIONS = [
  {
    id: "preferred_company_score",
    name: "is_preferred_company",
    label: "Preferred Company Score",
  },
  {
    id: "domain_score",
    name: "is_domain",
    label: "Domain Score",
  },
  {
    id: "eduction_score",
    name: "is_education",
    label: "Education Score",
  },
  {
    id: "certification_score",
    name: "is_certification",
    label: "Certification Score",
  },
  {
    id: "hobbies_score",
    name: "is_hobbies",
    label: "Hobbies Score",
  },
];

const validationSchema = yup.object({
  job: yup.object().nullable().required("Please select the job title"),
});

function ScoreSettings() {
  const [jobList, setJobList] = useState([]);
  const [snack, setSnack] = useState({
    open: false,
    color: "",
    message: "",
  });

  const handleSnackClose = () => {
    setSnack({
      open: false,
      color: "",
      message: "",
    });
  };

  const handleSnackOpen = (message = MESSAGES.error, color = "error") => {
    setSnack({
      open: true,
      color: color,
      message: message || MESSAGES.error,
    });
  };

  const handleSubmit = (validValues) => {
    let requestBody = {
      job_code: validValues.job?.job_code,
      is_education: validValues.is_education ? "True" : "False",
      is_certification: validValues.is_certification ? "True" : "False",
      is_domain: validValues.is_domain ? "True" : "False",
      is_preferred_company: validValues.is_preferred_company ? "True" : "False",
      is_hobbies: validValues.is_hobbies ? "True" : "False",
    };
    axios
      .post(scoreSetting(), requestBody)
      .then((response) => {
        if (response.status === 200) {
          handleSnackOpen("Score settings updated successfully", "success");
        } else {
          handleSnackOpen();
        }
      })
      .catch((error) => {
        handleSnackOpen(errorResponse(error).message);
      });
  };

  const formik = useFormik({
    initialValues: {
      job: null,
      is_preferred_company: false,
      is_domain: false,
      is_education: false,
      is_certification: false,
      is_hobbies: false,
    },
    validationSchema: validationSchema,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });

  const { values, touched, errors, setValues, handleChange } = formik;

  const getAndSetScoreSettings = () => {
    axios
      .get(scoreSetting(), {
        params: {
          job_code: values.job?.job_code,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          setValues({
            ...values,
            is_preferred_company: response.data.is_preferred_company,
            is_domain: response.data.is_domain,
            is_education: response.data.is_education,
            is_certification: response.data.is_certification,
            is_hobbies: response.data.is_hobbies,
          });
        } else {
          handleSnackOpen();
        }
      })
      .catch((error) => {
        handleSnackOpen(errorResponse(error).message);
      });
  };

  const getAllJobList = () => {
    axios
      .get(getAllJob())
      .then((response) => {
        if (response.status === 200) {
          if (response.data.length) {
            setJobList(response.data);
            setValues({
              ...values,
              job: response.data[0],
            });
          }
        } else {
          handleSnackOpen();
        }
      })
      .catch((error) => {
        handleSnackOpen(errorResponse(error).message);
      });
  };

  useEffect(() => {
    getAllJobList();
  }, []);

  useEffect(() => {
    if (values.job) {
      getAndSetScoreSettings();
    }
  }, [values.job]);

  const handleDropdownValueChange = (newVal, name) => {
    setValues({
      ...values,
      [name]: newVal,
    });
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography>
          <b>{"Score Settings"}</b>
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <FormikProvider value={formik}>
          <Form>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <Autocomplete
                  id={"job-code"}
                  options={jobList}
                  value={values.job}
                  getOptionLabel={(option) => option.job_code}
                  onChange={(e, newVal) =>
                    handleDropdownValueChange(newVal, "job")
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={"Job Code"}
                      variant={"outlined"}
                      size={"medium"}
                      error={touched.job && Boolean(errors.job)}
                      helperText={touched.job && errors.job}
                      fullWidth
                    />
                  )}
                  disableClearable
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label={"Job Title"}
                  value={values.job?.position_title || ""}
                  disabled
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <Typography className={"relative top-2"}>
                  <b>{"Note: "}</b>
                  {
                    "Based on the above selected job posting, the scoring parameters will be differed."
                  }
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <div
                  className={
                    "flex items-start flex-col gap-3 border border-gray-300 rounded-lg p-6"
                  }
                >
                  {SCORE_OPTIONS.map((el) => (
                    <FormControlLabel
                      key={el.id}
                      name={el.name}
                      label={el.label}
                      checked={values[el.name]}
                      onChange={handleChange}
                      control={<Checkbox color={"primary"} />}
                    />
                  ))}
                </div>
              </Grid>
              <Grid item xs={12} container alignItems="center">
                <Button
                  size="large"
                  type={"submit"}
                  variant={"contained"}
                  color={"primary"}
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </Form>
        </FormikProvider>
      </Grid>
      <Grid item xs={12} />
      <CustomSnack
        open={snack.open}
        color={snack.color}
        message={snack.message}
        onClose={handleSnackClose}
      />
    </Grid>
  );
}

export default ScoreSettings;
