import { Box, Button, Grid, Typography } from "@material-ui/core";
import React from "react";
import CKEditor5 from "../../../commonComponents/CKEditor/CKEditor";
import SimpleModal from "../../../commonComponents/Modal/SimpleModal";

function MisspelledWords({
  grammarCheck,
  grammarCheckData,
  wordHighlightList,
  handleGrammarCheck,
  handleGrammarCheckPopupClose,
  handleSaveGrammarCheckWork,
  handleIgnoreGrammarCheck,
  handleEditorValueChange,
}) {
  return (
    <Box p={1}>
      <Box pb={2}>
        <Typography align={"center"} variant={"h6"}>
          <b>{"Misspelled Words"}</b>
        </Typography>
      </Box>
      {wordHighlightList && wordHighlightList.length !== 0 ? (
        wordHighlightList.map((wordHightLight, index) => {
          return (
            <Grid
              container
              spacing={3}
              style={{ paddingBottom: "10px" }}
              alignItems={"center"}
              key={`misspelled-${index}`}
            >
              {/* Grammar Check Sentence */}
              <Grid item xs>
                <Typography>
                  {/* Grammar Check sentence before the check word */}
                  {wordHightLight.line.split(wordHightLight.word)[0]}

                  {/* use the class to highlight the Incorrect Word */}
                  <span className="grammarCheckHighlightWord">
                    {wordHightLight.word}
                  </span>

                  {/* Grammar Check sentence after the check word */}
                  {wordHightLight.line.split(wordHightLight.word)[1]}
                </Typography>
              </Grid>

              {/* Grammar Check Ignore button, will add the ignored word to dictionaries*/}
              <Grid item>
                <Button
                  variant={"contained"}
                  color={"primary"}
                  size={"small"}
                  onClick={() =>
                    handleGrammarCheck(
                      wordHightLight.line.replace(
                        wordHightLight.word,
                        `<mark class='marker-yellow'>${wordHightLight.word}</mark>`
                      ),
                      wordHightLight.line
                    )
                  }
                >
                  <b>{"Edit"}</b>
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant={"contained"}
                  color={"secondary"}
                  size={"small"}
                  onClick={() => handleIgnoreGrammarCheck(wordHightLight.line)}
                  style={{ fontWeight: "bold", minWidth: "78px" }}
                >
                  {"Ignore"}
                </Button>
              </Grid>
            </Grid>
          );
        })
      ) : (
        <Typography align={"center"}>
          <b>{"Misspelled Words not found"}</b>
        </Typography>
      )}
      <SimpleModal
        title={"Edit The Misspelled Word"}
        open={grammarCheck}
        handleClose={handleGrammarCheckPopupClose}
        handleSave={handleSaveGrammarCheckWork}
      >
        <CKEditor5
          value={grammarCheckData.word}
          onChange={(val) => handleEditorValueChange(val, "grammarCheckData")}
        />
      </SimpleModal>
    </Box>
  );
}

export default MisspelledWords;
