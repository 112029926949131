import { FEEDBACK_DASHBOARD } from "../actionTypes";

export const setFeedbackDashboardState = (values) => ({
  type: FEEDBACK_DASHBOARD.setState,
  payload: values,
});

export const clearFeedbackDashboardState = () => ({
  type: FEEDBACK_DASHBOARD.clearState,
});
