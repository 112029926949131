import {
  Button,
  Grid,
  LinearProgress,
  TextField,
  Typography,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import axios from "axios";
import { Form, FormikProvider, useFormik } from "formik";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import * as yup from "yup";
import { postApplicantInterviewSession } from "../../../api/api";
import { MESSAGES } from "../../../utils/constants/constants";

function ChatbotAndFormLink({
  buttonLabel,
  sessionData,
  getAndSetApplicantSessionData,
  isAccessibleActionButton = true,
  isSendBot,
}) {
  const { jobCode, applicantId, applicant_session } = useParams();
  const [alertBox, setAlertBox] = useState({
    open: false,
    success: false,
    message: "",
  });

  const requiredMsg = "Please fill the required field";
  const invalidMail = "Invalid Mail ID";

  const validationSchema = yup.object().shape({
    email: yup.string().required(requiredMsg).email(invalidMail),
  });

  const formik = useFormik({
    initialValues: {
      email: sessionData?.applicant.email,
    },
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: handleSubmit,
  });

  function handleSubmit(values) {
    let reqBodyKey =
      buttonLabel === "Send Bot"
        ? "send_interview_chat_bot"
        : "send_interview_form_link";

    let choice = buttonLabel === "Send Bot" ? "chat_bot" : "form_link";

    let requestBody = {
      [reqBodyKey]: true,
      interview_session_choice: choice,
    };

    axios
      .post(postApplicantInterviewSession(applicant_session), requestBody)
      .then((response) => {
        if (response.status === 200) {
          setAlertBox({
            open: true,
            success: true,
            message: isSendBot
              ? MESSAGES.interviewScheduleChatbot
              : MESSAGES.interviewScheduleFormLink,
          });
          getAndSetApplicantSessionData();
        }
      })
      .catch((err) => {
        setAlertBox({
          open: true,
          success: false,
          message: "Something Went Wrong",
        });
      })
      .finally(() => {
        setTimeout(() => {
          setAlertBox({
            open: false,
            success: false,
            message: "",
          });
        }, 2000);
      });
  }

  const genrateValueObj = (
    new_ = 0,
    sent = 0,
    opened = 0,
    inprogress = 0,
    submitted = 0
  ) => {
    return {
      new_,
      sent,
      opened,
      inprogress,
      submitted,
    };
  };

  const getStatusValue = () => {
    if (sessionData) {
      let rootObjKey =
        buttonLabel === "Send Bot"
          ? "interview_chat_bot"
          : "interview_form_link";
      let objKey =
        buttonLabel === "Send Bot"
          ? "interview_chat_bot_choice"
          : "interview_form_link_choice";
      if (sessionData[rootObjKey]) {
        if (sessionData[rootObjKey][objKey] === "new") {
          return genrateValueObj(100, 0, 0, 0, 0);
        } else if (sessionData[rootObjKey][objKey] === "sent") {
          return genrateValueObj(100, 100, 0, 0, 0);
        } else if (sessionData[rootObjKey][objKey] === "opened") {
          return genrateValueObj(100, 100, 100, 0, 0);
        } else if (sessionData[rootObjKey][objKey] === "in_progress") {
          return genrateValueObj(100, 100, 100, 100, 0);
        } else {
          return genrateValueObj(100, 100, 100, 100, 100);
        }
      } else {
        return genrateValueObj(0, 0, 0, 0);
      }
    } else {
      return genrateValueObj(0, 0, 0, 0);
    }
  };

  const { values, handleChange, touched, errors, setFieldValue, handleBlur } =
    formik;

  return (
    <FormikProvider value={formik}>
      <Form>
        <Grid container spacing={4}>
          <Grid item xs={6}>
            <TextField
              fullWidth
              variant="outlined"
              label={"Mail ID"}
              size={"small"}
              disabled
              value={values.email}
              InputLabelProps={{ shrink: true }}
              name={"email"}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.email && Boolean(errors.email)}
              helperText={touched.email && errors.email}
            />
          </Grid>
          <Grid item xs={6}>
            <Button
              type={"submit"}
              variant="contained"
              color={"primary"}
              disabled={!isAccessibleActionButton}
            >
              {buttonLabel}
            </Button>
          </Grid>
          <Grid item xs={2}>
            <LinearProgress
              variant="determinate"
              value={getStatusValue().new}
            />
            <Typography className="typoStyle">New</Typography>
          </Grid>
          <Grid item xs={2}>
            <LinearProgress
              variant="determinate"
              value={getStatusValue().sent}
            />
            <Typography className="typoStyle">Sent</Typography>
          </Grid>
          <Grid item xs={2}>
            <LinearProgress
              variant="determinate"
              value={getStatusValue().opened}
            />
            <Typography className="typoStyle">Opened</Typography>
          </Grid>
          <Grid item xs={2}>
            <LinearProgress
              variant="determinate"
              value={getStatusValue().inprogress}
            />
            <Typography className="typoStyle">In Progress</Typography>
          </Grid>
          <Grid item xs={2}>
            <LinearProgress
              variant="determinate"
              value={getStatusValue().submitted}
            />
            <Typography className="typoStyle">Submitted</Typography>
          </Grid>
          {alertBox.open && (
            <Grid item xs={12}>
              <Alert severity={`${alertBox.success ? "success" : "error"}`}>
                {alertBox.message}
              </Alert>
            </Grid>
          )}
        </Grid>
      </Form>
    </FormikProvider>
  );
}

export default ChatbotAndFormLink;
