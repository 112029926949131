import { Button, Grid, TextField, Typography } from "@material-ui/core";
import axios from "axios";
import { Form, FormikProvider, useFormik } from "formik";
import React, { useEffect, useState } from "react";
import * as yup from "yup";
import { clientEmailSetting } from "../../api/api";

const validationSchema = yup.object({
  hm_email_1: yup.string().nullable().email("Enter a valid email").optional(),
  hm_email_2: yup.string().nullable().email("Enter a valid email").optional(),
  hm_email_3: yup.string().nullable().email("Enter a valid email").optional(),
  hm_email_4: yup.string().nullable().email("Enter a valid email").optional(),
});

function HiringManagerSettings() {
  const getAndSetClientEmailSettings = () => {
    axios.get(clientEmailSetting()).then((response) => {
      if (response.status === 200) {
        if (response.data.length > 0) {
          setValues({
            ...response.data[0],
          });
        }
      }
    });
  };

  const [success, setSuccess] = useState(false);

  useEffect(() => {
    getAndSetClientEmailSettings();
  }, []);

  const formik = useFormik({
    initialValues: {
      id: "",
      hm_email_1: "",
      hm_email_2: "",
      hm_email_3: "",
      hm_email_4: "",
    },
    validationSchema: validationSchema,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });

  function handleSubmit(validValues) {
    let requestBody = {};
    requestBody.hm_email_1 = validValues.hm_email_1 || null;
    requestBody.hm_email_2 = validValues.hm_email_2 || null;
    requestBody.hm_email_3 = validValues.hm_email_3 || null;
    requestBody.hm_email_4 = validValues.hm_email_4 || null;

    if (values.id) {
      requestBody.id = values.id;
    }
    axios.post(clientEmailSetting(), requestBody).then((response) => {
      if (response.status === 201) {
        setValues({ ...response.data });
        setSuccess(true);
      }
    });
  }

  const { values, touched, errors, setValues, handleBlur, handleChange } =
    formik;

  return (
    <FormikProvider value={formik}>
      <Form>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography>
              <b>{"Hiring Manager Settings"}</b>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              name={"hm_email_1"}
              variant={"outlined"}
              label={"HM 1 Mail Id"}
              value={values.hm_email_1 || ""}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.hm_email_1 && Boolean(errors.hm_email_1)}
              helperText={touched.hm_email_1 && errors.hm_email_1}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              name={"hm_email_2"}
              variant={"outlined"}
              label={"HM 2 Mail Id"}
              value={values.hm_email_2 || ""}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.hm_email_2 && Boolean(errors.hm_email_2)}
              helperText={touched.hm_email_2 && errors.hm_email_2}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              name={"hm_email_3"}
              variant={"outlined"}
              label={"HM 3 Mail Id"}
              value={values.hm_email_3 || ""}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.hm_email_3 && Boolean(errors.hm_email_3)}
              helperText={touched.hm_email_3 && errors.hm_email_3}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              name={"hm_email_4"}
              variant={"outlined"}
              label={"HM 4 Mail Id"}
              value={values.hm_email_4 || ""}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.hm_email_4 && Boolean(errors.hm_email_4)}
              helperText={touched.hm_email_4 && errors.hm_email_4}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} container alignItems="center">
            <Button
              size="large"
              type={"submit"}
              variant={"contained"}
              color={"primary"}
            >
              Submit
            </Button>
          </Grid>
          <Grid item xs={12} container alignItems="center">
            <Typography
              style={{
                color: "#008000",
                visibility: success ? "visible" : "hidden",
              }}
            >
              {success ? "Saved successfully" : "_"}
            </Typography>
          </Grid>
        </Grid>
      </Form>
      <br />
    </FormikProvider>
  );
}

export default HiringManagerSettings;
