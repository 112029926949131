import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Container,
  Dialog,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  Menu,
  MenuItem,
  Paper,
  Select,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import {
  FiberManualRecord,
  History,
  Info,
  Spellcheck,
} from "@material-ui/icons";
import AddIcon from "@material-ui/icons/Add";
import CloseIcon from "@material-ui/icons/Close";
import DeleteIcon from "@material-ui/icons/Delete";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { Alert, Autocomplete, Pagination } from "@material-ui/lab";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import {
  applyJobToCandidate,
  deleteApplicantPageApplicant,
  deleteFunnelPageApplicant,
  downloadResume,
  getAllJob,
  getApplicantsByJobCode,
  getRoleList,
  getSkillList,
  postApplicantSession,
  postResumeByJobCodeApplicantId,
  rejectApplicant,
  restoreCandidate,
  updateFunnelStatus,
} from "../../api/api";
import CompareFileUpload from "../../commonComponents/CompareFileUpload";
import DeleteDialog from "../../commonComponents/DeleteDialog/DeleteDialog";
import FileUpload from "../../commonComponents/FileUpload";
import Loader from "../../commonComponents/Loader/Loader";
import SimpleModal from "../../commonComponents/Modal/SimpleModal";
import ShareJobToCandidate from "../../commonComponents/ShareJobToCandidate";
import CustomSnack from "../../commonComponents/Snack/CustomSnack";
import {
  FlexColumnView,
  StyledList,
  StyledTableContainer,
  StyledTableHead,
} from "../../commonComponents/StyledComponents";
import { setFunnelState } from "../../redux/actions/funnel";
import {
  MESSAGES,
  REJECT_MODULE,
  RESUME_UPLOAD_INSTRUCTION,
} from "../../utils/constants/constants";
import {
  FEEDBACK_LEVELS,
  FEEDBACK_RELATED_PAGES,
  getFeedbackLevelBasedAccess,
} from "../../utils/constants/feedbackLevelBasedAccess";
import {
  errorResponse,
  getOffset,
  getPageUsingRemovingAnElement,
  validateEmail,
} from "../../utils/constants/helpers";
import {
  ROLES,
  ROLE_BASED_PAGES,
  getRoleBasedAccess,
} from "../../utils/constants/roleBasedAccess";
import * as urc from "../../utils/constants/uploadResumeConstants";
import "./style.css";
import { navigateTo } from "../../utils/constants/routePaths";
import Upload from "./Components/Upload";
import ConfirmDialog from "../../commonComponents/Modal/ConfirmModal";

export const Options = [
  {
    id: 1,
    value: "10",
  },
  {
    id: 2,
    value: "20",
  },
  {
    id: 3,
    value: "30",
  },
  {
    id: 4,
    value: "40",
  },
  {
    id: 5,
    value: "50",
  },
  {
    id: 6,
    value: "100",
  },
];

const ITEM_HEIGHT = 48;

const tableHead = ["Name", "Mobile No", "Status", "Download / Upload"];

const th = [
  // 'S.No',
  "Applicant Name",
  "Parsing Error",
  "Mail ID",
  "Mobile No.",
  "Status",
  "Action",
  "Reject Note",
  "Resume",
  "Completion Status",
  "Module Source Status",
  "View History",
  "Applicant Details",
];

axios.defaults.xsrfHeaderName = "X-CSRFToken";
axios.defaults.xsrfCookieName = "csrftoken";

function Applicants({ getAndSetCount = () => { } }) {
  const { jobCode, status } = useParams();
  const role = localStorage.getItem("role");
  const user_email = localStorage.getItem("user_email");
  const isRecruiter = ROLES.recruiter === role;
  const isManager = ROLES.manager === role;
  const {
    isAllowCoverSheetUpdateForm = true,
    isAllowSentBot = true,
    isAllowDelete = true,
    isAllowAddResume = true,
    isAllowStatusUpdate = true,
    isAllowApplyJobToCandidate = true,
  } = getRoleBasedAccess(role, ROLE_BASED_PAGES.funnelLanding);
  const feedbackLevel = sessionStorage.getItem("feedbackLevel");
  const {
    isReject = () => false,
    isCompletionStatus,
    isAddResume,
    isSendBot,
    isUpdateForm,
    isRestoreCandidate,
    isModuleStatus,
    isEditAccess = () => false,
    isChatbotStatus,
    isCommonDelete,
  } = getFeedbackLevelBasedAccess(
    feedbackLevel,
    FEEDBACK_RELATED_PAGES.funnelLanding
  );

  const [applicantList, setApplicantList] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const [uploadOpen, setUploadOpen] = useState(false);
  const [selectedSkill, setSelectedSkill] = useState([]);
  const [selectedTitle, setSelectedTitle] = useState([]);
  const [skillList, setSkillList] = useState([]);
  const [titleList, setTitleList] = useState([]);
  const [errormatch, setErrormatch] = useState("");
  const [resume, setResume] = useState(null);
  const [applicantId, setApplicantId] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({
    isError: false,
    message: "",
    success: false,
  });
  const [resumeDialog, setResumeDialog] = useState(false);
  const [fileLength, setFileLength] = useState(false);
  const [fileLen, setFileLen] = useState(0);
  const [isAllFileTypeMatch, setIsAllFileTypeMatch] = useState(true);
  const [uploadedResumes, setUploadedResumes] = useState({});
  const [multipleFileLoading, setMultipleFileLoading] = useState(false);
  const [processedResumes, setProcessedResumes] = useState({});
  const [dopen, setDOpen] = useState(false);
  const [uploadResumes, setUploadResumes] = useState([]);
  const [overWrittenFiles, setOverWrittenFiles] = useState({});
  const [multiLoad, setMultiLoad] = useState(false);
  const [multiFileErr, setMultiFileErr] = useState({
    status: false,
    message: "",
    hasError: false,
  });
  const [customMatchUpload, setCustomMatchUpload] = useState(false)
  const [resumePath, setResumePath] = useState("");
  const [alertBox, setAlertBox] = useState({
    open: false,
    success: false,
    message: "",
  });
  const [snack, setSnack] = useState({
    open: false,
    color: "",
    message: "",
  });
  const [tableDataLoading, setTableDataLoading] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [selectedIds, setSelectedIds] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [rejectNote, setRejectNote] = useState("");
  const [reStoreModalOpen, setReStoreModalOpen] = useState(false);
  const [jobList, setJobList] = useState([]);
  const [restoreJob, setRestoreJob] = useState(null);
  const [restoreJobError, setRestoreJobError] = useState(null);
  const [candidateEmail, setCandidateEmail] = useState([]);
  const [shareJobToCandidatePopupOpen, setShareJobToCandidatePopupOpen] =
    useState(false);
  const [shareJobToCandidateLoading, setShareJobToCandidateLoading] =
    useState(false);
  const [countrySeletcModalOpen, setCountrySeletcModalOpen] =
    useState(false);
  const [customMatchCountrySeletcModalOpen, setCustomMatchCountrySeletcModalOpen] =
    useState(false);
  const [country, setCountry] =
    useState("");

  const { push } = useHistory();

  const open = Boolean(anchorEl);

  const { pagination } = useSelector((state) => state.funnel);
  const dispatch = useDispatch();

  const handleSnackOpen = (message = MESSAGES.error, color = "error") => {
    setSnack({
      open: true,
      color: color,
      message: message || MESSAGES.error,
    });
  };

  const setData = (data) => {
    setApplicantList(data.results);
    setTotalCount(data.count);
  };

  const handleDownloadClick = () => {
    let payload = {
      applicant_id: applicantId,
    };
    axios
      .post(downloadResume(), payload)
      .then((response) => {
        window.open(response.data.url);
      })
      .catch((error) => {
        handleSnackOpen();
      })
      .finally(() => {
        handleClose();
      });
  };

  const handleSnackClose = () => {
    setSnack({
      open: false,
      color: "",
      message: "",
    });
  };

  const handleClick = (event, applicant_id, resume_path) => {
    setApplicantId(applicant_id);
    setResumePath(resume_path);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const hasFeedbackStatus = (level) => {
    return level === feedbackLevel ? "True" : null;
  };

  const history = useHistory();
  const findIsClient = () => {
    const { pathname } = history.location;
    return pathname.includes("yes");
  };
  const fetchApplicantsList = async (limit, page) => {
    try {
      const res = await axios.get(getApplicantsByJobCode(jobCode), {
        params: {
          limit: limit,
          offset: getOffset(page, limit),
          status: status ? status : "",
          is_applied: hasFeedbackStatus(FEEDBACK_LEVELS.funnel),
          is_qualified: hasFeedbackStatus(FEEDBACK_LEVELS.qualified),
          is_rejected: hasFeedbackStatus(FEEDBACK_LEVELS.rejected),
          is_selected:
            hasFeedbackStatus(FEEDBACK_LEVELS.movedToTest) ||
            hasFeedbackStatus(FEEDBACK_LEVELS.testPending),
          is_testcompleted:
            FEEDBACK_LEVELS.testPending === feedbackLevel
              ? "False"
              : hasFeedbackStatus(FEEDBACK_LEVELS.testCompleted),
          is_moved: isManager
            ? hasFeedbackStatus(FEEDBACK_LEVELS.recommend)
            : null || hasFeedbackStatus(FEEDBACK_LEVELS.clientInterview),
          is_recommend: isManager
            ? null
            : hasFeedbackStatus(FEEDBACK_LEVELS.recommend),
          is_manager: isManager || findIsClient() ? "True" : null,
        },
      });
      return res;
    } catch (error) {
      return errorResponse(error);
    }
  };

  const getAndSetApplicantsList = async (limit, page) => {
    setTableDataLoading(true);
    const response = await fetchApplicantsList(limit, page);
    if (response.status === 200) {
      setData(response.data);
    } else {
      handleSnackOpen(response.message);
    }
    setTableDataLoading(false);
  };

  const fetchSkillList = () => {
    axios.get(getSkillList).then((res) => {
      if (res.status === 200) {
        const uniqueData = filterDuplicates(res?.data?.data);
        setSkillList(uniqueData);
      }
    });
  };
  const filterDuplicates = (data) => {
    const uniqueDisplays = new Set();
    return data.filter((item) => {
      if (!uniqueDisplays.has(item?.display)) {
        uniqueDisplays.add(item?.display);
        return true;
      }
      return false;
    });
  };
  const fetchTitleList = () => {
    axios.get(getRoleList).then((res) => {
      if (res.status === 200) {
        setTitleList(JSON.parse(res.data));
      }
    });
  };

  const onSelectPage = (e, value) => {
    getAndSetApplicantsList(pagination.items, value);
    dispatch(
      setFunnelState({
        pagination: { ...pagination, activePage: value },
      })
    );
  };

  const handlePageLimitChange = (e) => {
    const { value } = e.target;
    getAndSetApplicantsList(parseInt(value), pagination.activePage);
    dispatch(
      setFunnelState({
        pagination: { ...pagination, items: parseInt(value) },
      })
    );
  };

  const handleSingleFileUpload = (event) => {
    let files = event.target.files;

    for (let fileIndex = 0; fileIndex < files.length; fileIndex++) {
      var eachFile = files[fileIndex];
      //eslint-disable-next-line
      let fileName = eachFile.name.replace(/[\/\\+'"–]/g, "_");

      // let fileName = files[fileIndex].name;
      var isFileTypeMatch = fileName
        .toLowerCase()
        .match(/^[\w~`!@#$%^&*(){}[\]|:";'<>,.?+= _\-–]+(.doc|.docx|.pdf)$/);
      if (isFileTypeMatch) {
        setResume(eachFile);
      } else {
        //   this.setState({
        //     isAllFileTypeMatch: false,
        //     uploadedResumes: {},
        //     loading: false,
        //     format: true,
        //     message: "Files in unsupported formats are not processed.",
        //   });
        //   $("#openFileUploadErrorMsgPopUp").click();
      }
    }
  };

  const handleSingleFileUploadClick = () => {
    setUploadOpen(true);
    handleClose();
  };

  const handleFileClose = () => {
    setResume(null);
  };

  const handleSingleFileSubmit = () => {
    setLoading(true);

    let formData = new FormData();
    formData.append("files[]", resume);
    formData.append("job_code", jobCode);
    formData.append("applicant_id", applicantId);

    axios
      .post(postResumeByJobCodeApplicantId, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((response) => {
        setLoading(false);
        setError({
          isError: false,
          message: "File uploaded successfully.",
          success: true,
        });
        setResume(null);
        setTimeout(() => {
          setUploadOpen(false);
          setError({
            isError: false,
            message: "",
            success: false,
          });
          getAndSetApplicantsList(pagination.items, pagination.activePage);
          getAndSetCount();
        }, 2000);
      })
      .catch((err) => {
        setLoading(false);
        setError({
          isError: true,
          message: "File not uploaded please try again.",
          success: false,
        });
      });
  };

  const handleMultipleResumeUploadClose = () => {
    setResumeDialog(false);
    setMultiLoad(false);
    setFileLen(0);
    setFileLength(false);
    setIsAllFileTypeMatch(true);
    setUploadedResumes({});
    setMultipleFileLoading(false);
    setProcessedResumes({});
    setDOpen(false);
    setUploadResumes([]);
    setOverWrittenFiles({});
  };

  const handleAddMultipleResumeClick = () => {
    setCountrySeletcModalOpen(true)
  };

  const handleResumeSelection = (countryName) => {
    setCountry(countryName);
    setCountrySeletcModalOpen(false)
    setCustomMatchCountrySeletcModalOpen(false)
    if (countrySeletcModalOpen) {
      setResumeDialog(true);
    }
    else {
      setCustomMatchUpload(!customMatchUpload)
      setSelectedTitle([])
      setSelectedSkill([])
    }

  }

  const handleResumeSelectionModalClose = () => {
    setCountry("");
    setCountrySeletcModalOpen(false)
    setCustomMatchCountrySeletcModalOpen(false)
  }

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];

    if (!selectedFile) {
      // Handle the case where no file is selected
      setErrormatch("Please choose a file");
      return;
    }

    // Checking file type
    const isFileTypeMatch = selectedFile.name
      .toLowerCase()
      .match(/^[\w~`!@#$%^&*(){}[\]|:";'<>,.?+= _\-–]+(.doc|.docx|.pdf)$/);

    // Check other conditions after checking the file type
    if (!selectedSkill.length && !selectedTitle.length) {
      setErrormatch("Please choose at least one skill or title.");
      return;
    }

    if (!selectedSkill.length > 9) {
      setErrormatch("Maximum 9 skills allowed");
      event.target.value = "";
      return;
    }
    if (!isFileTypeMatch) {
      setErrormatch("File type not valid");
      event.target.value = "";
      return;
    }
    setErrormatch("");
    setLoading(true);

    let formData = new FormData();
    formData.append("files[]", selectedFile);
    formData.append("job_code", jobCode);
    formData.append("parser", country);
    formData.append(
      "skill",
      JSON.stringify({
        action: "skill",
        values: selectedSkill.map((option) => option.display),
      })
    );
    formData.append(
      "title",
      JSON.stringify({ action: "title", values: selectedTitle })
    );

    axios
      .post(postResumeByJobCodeApplicantId, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((response) => {
        setSkillList([]);
        setTitleList([]);
        setLoading(false);

        setSnack({
          open: true,
          color: "success",
          message: "File uploaded successfully.",
        });

        setTimeout(() => {
          setCustomMatchUpload(false);
          getAndSetApplicantsList(pagination.items, pagination.activePage);
          getAndSetCount();
        }, 2000);
      })
      .catch((err) => {
        setLoading(false);
        setSnack({
          open: true,
          color: "error",
          message: "File not uploaded. Please try again.",
        });
        event.target.value = "";
      });
  };

  const handleMultipleFileUpload = (event) => {
    setProcessedResumes({});
    setUploadedResumes({});

    let files = event.target.files;

    if (event.target.files.length >= 1) {
      setMultipleFileLoading(true);
      let fileLen = files.length;
      setFileLen(fileLen);

      let filesLeftToReachLimit = 10 - uploadResumes.length;
      var uploadedata = [...uploadResumes];

      if (fileLen <= filesLeftToReachLimit) {
        for (let fileIndex = 0; fileIndex < files.length; fileIndex++) {
          var eachFile = files[fileIndex];
          //
          //eslint-disable-next-line
          let fileName = eachFile.name.replace(/[\/\\+'"–]/g, "_");

          // let fileName = files[fileIndex].name;
          var isFileTypeMatch = fileName
            .toLowerCase()
            .match(
              /^[\w~`!@#$%^&*(){}[\]|:";'<>,.?+= _\-–]+(.doc|.docx|.pdf)$/
            );

          if (isFileTypeMatch) {
            //('test11');
            setIsAllFileTypeMatch(true);
            uploadedata.push(eachFile);

            let uploadedResumesObj = uploadResumes;
            let processedResumesObj = processedResumes;
            let overWrittenFilesObj = overWrittenFiles;

            if (uploadedResumesObj.hasOwnProperty(fileName)) {
              //
              //
              delete processedResumesObj[fileName];
              delete uploadedResumesObj[fileName];

              overWrittenFilesObj[fileName] = true;

              setProcessedResumes(processedResumesObj);
              setMultipleFileLoading(false);
              setUploadResumes(uploadedResumesObj);
            } else setMultipleFileLoading(false);
            overWrittenFilesObj[fileName] = false;
          } else {
            setIsAllFileTypeMatch(false);
            setDOpen(true);
            setMultipleFileLoading(false);
          }
        }
        setUploadResumes(uploadedata);
      } else if (uploadResumes.length >= 11) {
        setDOpen(true);
        setMultipleFileLoading(false);
        setIsAllFileTypeMatch(false);
      } else if (uploadResumes.length === 10) {
        setDOpen(true);
        setMultipleFileLoading(false);
        setFileLength(true);
        setIsAllFileTypeMatch(false);
      } else {
        setDOpen(true);
        setMultipleFileLoading(false);
        setFileLength(true);
        setIsAllFileTypeMatch(false);
      }
    }
  };

  const handleCloseMultipleResume = () => {
    setDOpen(false);
  };

  const handleMultipleFileClose = (name) => {
    var newupload = [];
    uploadResumes.map((item) => {
      if (item.name !== name) {
        newupload.push(item);
      }
      return null;
    });
    setUploadResumes(newupload);
    setFileLen(uploadResumes.length - 1);
  };

  const multipleFileSubmitClick = () => {
    setMultiLoad(true);
    let formData = new FormData();

    uploadResumes.map((el) => {
      formData.append("files[]", el);
    });
    formData.append("job_code", jobCode);
    formData.append("parser", country);

    axios
      .post(postResumeByJobCodeApplicantId, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((response) => {
        getAndSetApplicantsList(pagination.items, pagination.activePage);
        getAndSetCount();
        setMultiFileErr({
          status: true,
          message: "Files uploaded successfully.",
          hasError: false,
        });
        setMultiLoad(false);
        setFileLen(0);
        setFileLength(false);
        setIsAllFileTypeMatch(true);
        setUploadedResumes({});
        setMultipleFileLoading(false);
        setProcessedResumes({});
        setDOpen(false);
        setUploadResumes([]);
        setOverWrittenFiles({});
        setTimeout(() => {
          handleMultipleResumeUploadClose();
          setMultiFileErr({
            status: false,
            message: "",
            hasError: false,
          });
        }, 2000);
      })
      .catch((err) => {
        setMultiFileErr({
          status: true,
          message: "Something went wrong! Please try again",
          hasError: true,
        });
        setMultiLoad(false);
        setTimeout(() => {
          setMultiFileErr({
            status: false,
            message: "",
            hasError: false,
          });
        }, 2000);
      });
  };

  const applicantNameClick = (applicant_id) => {
    push(`/dashboard/${jobCode}/applicants/${applicant_id}`);
  };

  const fetchAndCheck = async () => {
    setTableDataLoading(true);
    const response = await fetchApplicantsList(
      pagination.items,
      pagination.activePage
    );

    if (response.status === 200) {
      // If the count and result data was not satisfied, then again call the same api with first page of the data
      if (!response.data.results.length && response.data.count) {
        getAndSetApplicantsList(pagination.items, 1);
        dispatch(
          setFunnelState({
            pagination: { ...pagination, activePage: 1 },
          })
        );
        return;
      } else {
        setData(response.data);
      }
    } else {
      handleSnackOpen(response.message);
    }
    setTableDataLoading(false);
  };

  useEffect(() => {
    fetchAndCheck();
    fetchSkillList();
    fetchTitleList();
  }, []);

  const handleSendBot = (applicantsession) => {
    let requestBody = { send_coversheet_chat_bot: true };
    axios
      .post(postApplicantSession(applicantsession), requestBody)
      .then((response) => {
        if (response.status === 200) {
          setSnack({
            open: true,
            color: "success",
            message: "Coversheet chatbot sent successfully",
          });
          setTimeout(() => {
            getAndSetApplicantsList(pagination.items, pagination.activePage);
            handleSnackClose();
          }, 2500);
        }
      })
      .catch((err) => {
        handleSnackOpen();
      });
  };

  const handleRevertChatBot = (applicantsession) => {
    // let requestBody = { send_coversheet_chat_bot: true };
    // axios
    //   .post(postApplicantSession(applicantsession), requestBody)
    //   .then((response) => {
    //     if (response.status === 200) {
    //       setSnack({
    //         open: true,
    //         color: "success",
    //         message: "Send Bot Successfully",
    //       });
    //       setTimeout(() => {
    //         getAndSetApplicantsList(pagination.items, pagination.activePage);
    //         handleSnackClose();
    //       }, 2500);
    //     }
    //   })
    //   .catch((err) => {
    //      handleSnackOpen();
    //   });
  };

  const jobTitle = sessionStorage.getItem("jobTitle");

  const handleNavigateCoverSheetForm = (id) => {
    push(`/coversheetform`, {
      job_code: jobCode,
      applicant_id: id,
    });
  };

  const handleDeleteClick = () => {
    setDeleteId(selectedIds[0]["id"]);
    setDeleteDialogOpen(true);
  };

  const handleSingleDeleteClick = (e, id) => {
    e.stopPropagation();
    setDeleteId(id);
    setDeleteDialogOpen(true);
  };

  const handleDeleteDialogClose = () => {
    setDeleteId(null);
    setDeleteDialogOpen(false);
  };

  const handleYesClick = () => {
    axios
      .delete(deleteApplicantPageApplicant(deleteId))
      .then((response) => {
        setDeleteDialogOpen(false);
        setDeleteId(null);
        setSelectedIds([]);
        if (response.status === 204) {
          setSnack({
            open: true,
            color: "success",
            message: "Deleted Successfully",
          });
          setTimeout(() => {
            let page = getPageUsingRemovingAnElement(
              totalCount,
              pagination.activePage,
              pagination.items
            );
            getAndSetApplicantsList(pagination.items, page);
            dispatch(
              setFunnelState({
                pagination: { ...pagination, activePage: page },
              })
            );
            getAndSetCount();
          }, 2500);
        } else {
          handleSnackOpen();
        }
      })
      .catch((err) => {
        setDeleteDialogOpen(false);
        setDeleteId(null);
        setSelectedIds([]);
        handleSnackOpen();
      });
  };

  const handleStatusUpdate = (e, { index, id }) => {
    const { checked } = e.target;

    let arr = [...applicantList];
    let prev_funnel_status = applicantList[index]["funnel_status"];
    arr[index]["funnel_status"] = checked;
    setApplicantList(arr);

    let payload = {
      funnel_status: checked ? "True" : "False",
    };
    axios
      .patch(updateFunnelStatus(jobCode, id), payload)
      .then((response) => {
        if (response.status === 200) {
          setSnack({
            open: true,
            color: "success",
            message: "Updated Successfully",
          });
        } else {
          arr[index]["funnel_status"] = prev_funnel_status;
          handleSnackOpen();
        }
        setApplicantList(arr);
      })
      .catch((err) => {
        arr[index]["funnel_status"] = prev_funnel_status;
        setApplicantList(arr);
        handleSnackOpen();
      });
  };

  const handleCheckboxClick = (e, { id, job_code, job_title }) => {
    let arr = [...selectedIds];

    let indexOfId = arr.findIndex((el) => el.id === id);

    if (indexOfId !== -1) {
      arr.splice(indexOfId, 1);
    } else {
      arr.push({
        id,
        job_code,
        job_title,
      });
    }

    setSelectedIds(arr);
  };

  const handleSelectAllClick = (e) => {
    let { checked } = e.target;
    let tempSelectedIds = [...selectedIds];
    if (checked) {
      applicantList.map((el) => {
        if (!tempSelectedIds.some((x) => x.id === el.id)) {
          tempSelectedIds.push({
            id: el.id,
            job_code: el.job_code,
            job_title: el.job_title,
          });
        }
      });
    } else {
      tempSelectedIds = [];
    }
    setSelectedIds(tempSelectedIds);
  };

  const handleRejectClick = () => {
    setModalOpen(true);
  };

  const handleRestoreClick = () => {
    setReStoreModalOpen(true);
    axios
      .get(getAllJob())
      .then((response) => {
        if (response.status === 200) {
          setJobList(response.data);
        } else {
          setJobList([]);
          handleSnackOpen();
        }
      })
      .catch((err) => {
        setJobList([]);
        handleSnackOpen();
      });
  };

  const handleModalClose = () => {
    setModalOpen(false);
    setRejectNote(null);
  };

  const handleReject = () => {
    let obj = {
      ...selectedIds[0],
    };
    let payload = {
      applicant_id: obj.id,
      job_code: obj.job_code,
      reject_note: rejectNote,
      reject_module: REJECT_MODULE.funnel,
    };
    axios
      .post(rejectApplicant(), payload)
      .then((response) => {
        setModalOpen(false);
        setRejectNote(null);
        setSelectedIds([]);
        if (response.status === 200) {
          setSnack({
            open: true,
            color: "success",
            message: "Applicant Rejected Successfully",
          });
          setTimeout(() => {
            let page = getPageUsingRemovingAnElement(
              totalCount,
              pagination.activePage,
              pagination.items
            );
            getAndSetApplicantsList(pagination.items, page);
            dispatch(
              setFunnelState({
                pagination: { ...pagination, activePage: page },
              })
            );
            getAndSetCount();
          }, 2500);
        } else {
          handleSnackOpen();
        }
      })
      .catch((err) => {
        setModalOpen(false);
        setRejectNote(null);
        setSelectedIds([]);
        handleSnackOpen();
      });
  };

  const handleChange = (e) => {
    const { value } = e.target;
    setRejectNote(value);
  };

  const handleRestoreJob = (e, newVal) => {
    setRestoreJob(newVal);
    setRestoreJobError(null);
  };

  const handleRestoreModalClose = () => {
    setReStoreModalOpen(false);
    setRestoreJob(null);
    setRestoreJobError(null);
  };

  const handleRestore = () => {
    let obj = {
      ...selectedIds[0],
    };
    if (restoreJob) {
      let payload = {
        jobdetail: restoreJob.job_code,
        is_qualified: true,
        is_rejected: false,
      };
      setRestoreJobError(null);
      axios
        .patch(restoreCandidate(obj.job_code, obj.id), payload)
        .then((response) => {
          setReStoreModalOpen(false);
          setRestoreJob(null);
          setSelectedIds([]);
          if (response.status === 200) {
            setSnack({
              open: true,
              color: "success",
              message: "Applicant Restored Successfully",
            });
            setTimeout(() => {
              let page = getPageUsingRemovingAnElement(
                totalCount,
                pagination.activePage,
                pagination.items
              );
              getAndSetApplicantsList(pagination.items, page);
              dispatch(
                setFunnelState({
                  pagination: { ...pagination, activePage: page },
                })
              );
              getAndSetCount();
            }, 2500);
          } else {
            handleSnackOpen();
          }
        })
        .catch((err) => {
          setReStoreModalOpen(false);
          setRestoreJob(null);
          setSelectedIds([]);
          handleSnackOpen();
        });
    } else {
      setRestoreJobError("Please select the job title");
    }
  };

  const handleShareJobToCandidateClick = () => {
    setShareJobToCandidatePopupOpen(true);
  };

  const handleEmailChange = (val) => {
    let isNotValid = val.some((el) => !validateEmail(el));
    if (val.length && isNotValid) {
      handleSnackOpen("Please enter a valid email");
    } else {
      setCandidateEmail(val);
    }
  };

  const handleShareJobToCandidatePopupClose = () => {
    setShareJobToCandidatePopupOpen(false);
    setCandidateEmail([]);
  };

  const handleShareJobToCandidate = () => {
    if (candidateEmail.length) {
      setShareJobToCandidateLoading(true);
      let payload = {
        to_email: candidateEmail,
        job_code: jobCode,
        from_email: user_email,
      };
      axios
        .post(applyJobToCandidate(), payload)
        .then((response) => {
          if (response.status === 200) {
            handleSnackOpen(
              MESSAGES.shareJobToCandidateEmailSuccessMessage,
              "success"
            );
            handleShareJobToCandidatePopupClose();
          } else {
            handleSnackOpen();
          }
          setShareJobToCandidateLoading(false);
        })
        .catch((error) => {
          setShareJobToCandidateLoading(false);
          handleSnackOpen();
        });
    } else {
      handleSnackOpen("Please select the To Email");
    }
  };

  const handleViewHistory = (id) => {
    history.push(`/applicant-history/${jobCode}/${id}`, {
      reference_name: "applicants",
    });
  };

  const UploadInstructions = () => {
    return (
      <FlexColumnView gridGap={"12px"} pl={3}>
        <Typography variant={"h6"}>
          <b>{"Instructions For Upload"}</b>
        </Typography>
        <Typography variant={"body1"}>
          <b>
            {
              "Please make sure the below changes are done before uploading the resumes for accurate parsing:"
            }
          </b>
        </Typography>
        <StyledList>
          {RESUME_UPLOAD_INSTRUCTION.map((el, index) => (
            <li key={`instruction-${index}`}>
              <FiberManualRecord
                style={{ fontSize: "16px" }}
                color={"primary"}
              />
              <Typography color={"textPrimary"}>{el}</Typography>
            </li>
          ))}
        </StyledList>
      </FlexColumnView>
    );
  };

  const viewGrammarCheck = (id) => {
    history.push(navigateTo.viewGrammarChecker(jobCode, id), {
      reference_name: "applicants",
    });
  };

  const showCheckbox =
    isReject(role) || isRestoreCandidate || isEditAccess(role);
  const isAction = isUpdateForm || isSendBot || !isCommonDelete;

  const handleCustomMatchModal = () => {
    setCustomMatchCountrySeletcModalOpen(true)
  }

  return (
    <Container maxWidth={"xl"} className={"container-style mt-7"}>
      <Grid container spacing={4}>
        <Grid item xs={6} container alignItems="center">
          <Typography>
            <b>{`${jobTitle} - ${jobCode}`}</b>
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Grid
            container
            spacing={1}
            alignItems={"center"}
            justifyContent={"flex-end"}
          >
            {/* {isAddResume ? (
              <Grid item>
                <Button
                  onClick={handleCustomMatchModal}
                  variant="contained"
                  color="primary"
                  disabled={!isAllowAddResume}
                >
                  Custom Match
                </Button>
              </Grid>
            ) : null}
            {isAddResume ? (
              <Grid item>
                <Button
                  onClick={handleAddMultipleResumeClick}
                  variant="contained"
                  color="primary"
                  startIcon={<AddIcon />}
                  disabled={!isAllowAddResume}
                >
                  Add Resume
                </Button>
              </Grid>
            ) : null} */}
            {/* {isAllowApplyJobToCandidate ? (
              <Grid item>
                <Button
                  onClick={handleShareJobToCandidateClick}
                  variant={"contained"}
                  color={"primary"}
                >
                  {"Share Job To Candidate"}
                </Button>
              </Grid>
            ) : null} */}
            {isReject(role) ? (
              <Grid item>
                <Button
                  onClick={handleRejectClick}
                  variant={"contained"}
                  color={"primary"}
                  disabled={!(selectedIds.length === 1)}
                >
                  {"Reject"}
                </Button>
              </Grid>
            ) : null}
            {isReject(role) ? (
              <Grid item>
                <Tooltip
                  title={
                    <Typography>
                      <b>{"Note: "}</b>
                      {"One Applicant at a time can be Rejected"}
                    </Typography>
                  }
                  classes={{ tooltip: "tooltip_custom_style" }}
                  arrow
                >
                  <Info color={"primary"} style={{ cursor: "pointer" }} />
                </Tooltip>
              </Grid>
            ) : null}
            {isRestoreCandidate ? (
              <Grid item>
                <Button
                  onClick={handleRestoreClick}
                  variant={"contained"}
                  color={"primary"}
                  disabled={!(selectedIds.length === 1) || !isEditAccess(role)}
                >
                  {"Restore Candidate"}
                </Button>
              </Grid>
            ) : null}
            {isCommonDelete ? (
              <Grid item>
                <Button
                  onClick={handleDeleteClick}
                  variant={"contained"}
                  color={"primary"}
                  disabled={!(selectedIds.length === 1)}
                >
                  {"Delete"}
                </Button>
              </Grid>
            ) : null}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <StyledTableContainer component={Paper} variant={"outlined"}>
            <Table size={"small"}>
              <StyledTableHead>
                <TableRow>
                  {showCheckbox ? (
                    <TableCell align="center">
                      <Checkbox
                        checked={applicantList.length === selectedIds.length}
                        onChange={handleSelectAllClick}
                      />
                    </TableCell>
                  ) : null}
                  {th.map((el, ind) =>
                    ("Resume" === el && isManager) ||
                      (!isCompletionStatus && "Completion Status" === el) ||
                      (!isModuleStatus && "Module Source Status" === el) ||
                      (!isModuleStatus && "Reject Note" === el) ||
                      (!isChatbotStatus && "Status" === el) ||
                      (!isAction && "Action" === el) ? null : (
                      <TableCell
                        align={
                          [
                            "Action",
                            "Module Source Status",
                            "View History",
                            "Applicant Details",
                          ].includes(el)
                            ? "center"
                            : "left"
                        }
                      >
                        {el}
                      </TableCell>
                    )
                  )}
                </TableRow>
              </StyledTableHead>
              <TableBody>
                {!tableDataLoading &&
                  applicantList &&
                  applicantList.length > 0 ? (
                  applicantList.map((el, index) => {
                    return (
                      <TableRow>
                        {showCheckbox ? (
                          <TableCell align="center" padding="none">
                            <Checkbox
                              checked={selectedIds.some((x) => x.id === el.id)}
                              onClick={(e) => {
                                handleCheckboxClick(e, el);
                              }}
                            />
                          </TableCell>
                        ) : null}
                        <TableCell
                        // onClick={() => applicantNameClick(el.applicant_id)}
                        // className={"cursor-pointer"}
                        >
                          <Typography>
                            <b>{`${el.firstname || ""} ${el.lastname || ""
                              }`}</b>
                          </Typography>
                          {/* <Typography>{el.job_title}</Typography> */}
                        </TableCell>
                        <TableCell>
                          <div className="flex justify-between items-center">
                            <Tooltip
                              arrow
                              title={`${!el.parsed ? "Unsupported file format!" : ""
                                }`}
                            >
                              <Typography>
                                {el.parsed ? "No" : "Yes"}
                              </Typography>
                            </Tooltip>
                            {/* {!el.parsed && (
                              <IconButton
                                aria-label="more"
                                aria-controls="long-menu"
                                aria-haspopup="true"
                                onClick={(e) =>
                                  handleClick(
                                    e,
                                    el.applicant_id,
                                    el.resume_path
                                  )
                                }
                                color={"primary"}
                              >
                                <MoreVertIcon />
                              </IconButton>
                            )} */}
                          </div>
                        </TableCell>
                        <TableCell>
                          <Typography>{el.email}</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography>{el.mobile_number || "-"}</Typography>
                        </TableCell>
                        {isChatbotStatus ? (
                          <TableCell>
                            <Typography style={{ textTransform: "capitalize" }}>
                              {el.coversheet_formlink_status ||
                                el.formlink_status}
                            </Typography>
                          </TableCell>
                        ) : null}
                        {isAction ? (
                          <TableCell padding={"checkbox"}>
                            <Grid
                              container
                              spacing={2}
                              alignItems={"center"}
                              wrap={"nowrap"}
                              style={{ whiteSpace: "nowrap" }}
                            >
                              {isUpdateForm ? (
                                <Grid item>
                                  <Button
                                    variant={"contained"}
                                    color={"primary"}
                                    onClick={() =>
                                      handleNavigateCoverSheetForm(el.id)
                                    }
                                    size={"small"}
                                    disabled={
                                      !isAllowCoverSheetUpdateForm ||
                                      (isRecruiter && el.funnel_status)
                                    }
                                  >
                                    {"Update Form"}
                                  </Button>
                                </Grid>
                              ) : null}
                              {isSendBot ? (
                                <Grid item>
                                  <Button
                                    variant={"contained"}
                                    color={"primary"}
                                    onClick={() => {
                                      handleSendBot(el.applicantsession);
                                    }}
                                    disabled={
                                      !isAllowSentBot ||
                                      el.chat_bot_status !== "new"
                                    }
                                    size={"small"}
                                  >
                                    {"Send Bot"}
                                  </Button>
                                </Grid>
                              ) : null}
                              {/* <Grid item>
                              <Button
                                variant={"contained"}
                                color={"primary"}
                                onClick={() => {
                                  handleRevertChatBot(el.applicantsession);
                                }}
                                disabled={
                                  !isAllowSentBot ||
                                  ["new", "submitted"].includes(
                                    el.chat_bot_status
                                  )
                                }
                                size={"small"}
                              >
                                {"Revert Bot"}
                              </Button>
                            </Grid> */}
                              {/* <Grid item>
                              <IconButton
                                color={"primary"}
                                onClick={() => {
                                  downloadClick(el.resume_path);
                                }}
                                size={"small"}
                              >
                                <GetApp />
                              </IconButton>
                            </Grid> */}
                              {!isCommonDelete ? (
                                <Grid item>
                                  <IconButton
                                    onClick={(e) =>
                                      handleSingleDeleteClick(e, el.id)
                                    }
                                    color={"secondary"}
                                    size={"small"}
                                    disabled={!isAllowDelete}
                                  >
                                    <DeleteIcon />
                                  </IconButton>
                                </Grid>
                              ) : null}
                            </Grid>
                          </TableCell>
                        ) : null}
                        {isModuleStatus ? (
                          <TableCell>
                            <Typography>
                              {el.rejection?.reject_note ||
                                "No notes for the rejection"}
                            </Typography>
                          </TableCell>
                        ) : null}
                        {isManager ? null : (
                          <TableCell>
                            <IconButton
                              aria-label="more"
                              aria-controls="long-menu"
                              aria-haspopup="true"
                              onClick={(e) =>
                                handleClick(e, el.id, el.resume_path)
                              }
                              color={"primary"}
                            >
                              <MoreVertIcon />
                            </IconButton>
                          </TableCell>
                        )}
                        {isCompletionStatus ? (
                          <TableCell>
                            <Switch
                              checked={el.funnel_status}
                              disabled={
                                !isAllowStatusUpdate ||
                                (isRecruiter && el.funnel_status)
                              }
                              color={"default"}
                              classes={{
                                root: "custom_toggle_switch_style",
                              }}
                              onChange={(e) =>
                                handleStatusUpdate(e, { ...el, index })
                              }
                              title={
                                el.funnel_status ? "Completed" : "Not Completed"
                              }
                            />
                          </TableCell>
                        ) : null}
                        {isModuleStatus ? (
                          <TableCell align={"center"}>
                            <Typography>
                              {el.rejection?.reject_module ?? "-"}
                            </Typography>
                          </TableCell>
                        ) : null}
                        <TableCell align={"center"}>
                          <IconButton
                            color={"primary"}
                            onClick={() => handleViewHistory(el.id)}
                          >
                            <History />
                          </IconButton>
                        </TableCell>
                        <TableCell align={"center"}>
                          <IconButton
                            color={"primary"}
                            onClick={() => viewGrammarCheck(el.id)}
                          >
                            <Spellcheck />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })
                ) : (
                  <TableRow>
                    <TableCell colSpan={10} align={"center"}>
                      {tableDataLoading ? (
                        <Loader />
                      ) : (
                        <Typography>
                          <b> No data found.</b>
                        </Typography>
                      )}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </StyledTableContainer>
        </Grid>
        <Grid
          item
          xs={12}
          container
          justifyContent="flex-end"
          alignItems="center"
        >
          <Typography>
            Total Count: <b>{totalCount}</b>
          </Typography>
        </Grid>
        <Grid item xs={2} container alignItems="center">
          <FormControl style={{ width: 120 }}>
            <InputLabel id="demo-simple-select-label">Rows per page</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={pagination.items}
              onChange={handlePageLimitChange}
            >
              {Options.map(({ id, value }) => (
                <MenuItem key={id} value={value}>
                  {value}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid
          item
          xs={10}
          container
          alignItems="center"
          justifyContent="flex-end"
        >
          <Pagination
            color={"primary"}
            page={pagination.activePage}
            onChange={onSelectPage}
            count={Math.ceil(totalCount / pagination.items)}
            showFirstButton
            showLastButton
          />
        </Grid>
      </Grid>
      <Dialog
        fullWidth
        maxWidth={"md"}
        open={uploadOpen}
        onClose={() => {
          setUploadOpen(false);
          setApplicantId("");
        }}
      >
        <Grid container className="p-5">
          <Grid item xs={12}>
            <FileUpload
              isFileLimitReached={false}
              handleFileUpload={handleSingleFileUpload}
              uploadLimit={1}
              alreadyUploadedFilesLen={0}
              isResumePage={true}
              files={resume ? resume : ""}
              loading={false}
            />
          </Grid>
          {error.isError ||
            (error.success && (
              <Grid item xs={12}>
                <Alert severity={`${error.isError ? "error" : "success"}`}>
                  {error.message}
                </Alert>
              </Grid>
            ))}
          {resume && !loading && (
            <>
              <Grid item xs={12}>
                <div className="">
                  <div className="fileresult">
                    <h2>{resume.name}</h2>
                    <CloseIcon onClick={handleFileClose} />
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} container justifyContent="flex-end">
                <Button
                  onClick={handleSingleFileSubmit}
                  className="btnStyle"
                  variant="contained"
                  color="primary"
                >
                  Upload
                </Button>
              </Grid>
            </>
          )}
          {loading && (
            <Grid
              item
              xs={12}
              container
              justifyContent="center"
              alignItems="center"
            >
              <CircularProgress />
            </Grid>
          )}
        </Grid>
      </Dialog>
      <Dialog
        maxWidth={"lg"}
        fullWidth
        open={resumeDialog}
        onClose={handleMultipleResumeUploadClose}
      >
        <Grid container spacing={2} className={"dialogContainer"}>
          <Grid item xs={6}>
            <CompareFileUpload
              folderUpload={"true"}
              isFileLimitReached={
                uploadedResumes &&
                  Object.keys(uploadedResumes).length <
                  urc.FILE_UPLOAD_RESUME_LIMIT
                  ? false
                  : true
              }
              fileLength={fileLength}
              handleFileUpload={handleMultipleFileUpload}
              uploadLimit={10}
              alreadyUploadedFilesLen={fileLen}
              isResumePage={true}
              isAllFileTypeMatch={isAllFileTypeMatch}
              files={uploadedResumes}
              loading={multipleFileLoading}
              dopen={dopen}
              handleClose={handleCloseMultipleResume}
              fileLen={uploadResumes.length}
            />
          </Grid>
          <Grid item xs={6}>
            <CompareFileUpload
              isFileLimitReached={
                uploadedResumes &&
                  Object.keys(uploadedResumes).length <
                  urc.FILE_UPLOAD_RESUME_LIMIT
                  ? false
                  : true
              }
              fileLength={fileLength}
              handleFileUpload={handleMultipleFileUpload}
              uploadLimit={10}
              alreadyUploadedFilesLen={fileLen}
              isResumePage={true}
              isAllFileTypeMatch={isAllFileTypeMatch}
              files={uploadedResumes}
              loading={multipleFileLoading}
              dopen={dopen}
              handleClose={handleCloseMultipleResume}
              fileLen={uploadResumes.length}
            />
          </Grid>
          <Grid item xs={12}>
            {uploadResumes &&
              uploadResumes.length >= 1 &&
              fileLen >= 1 &&
              fileLen < 21 &&
              // this.state.isAllFileTypeMatch &&
              !multipleFileLoading ? (
              <section
                className="w-full px-6 lg:w-6/12"
                style={{ margin: "auto" }}
              >
                <div className="custominput">
                  <h2 className="upload_title">Uploaded Resume</h2>
                  {uploadResumes.map((item) => (
                    <div className="fileresult">
                      <h2>{item.name}</h2>
                      <CloseIcon
                        onClick={() => handleMultipleFileClose(item.name)}
                      />
                    </div>
                  ))}
                </div>
              </section>
            ) : null}
          </Grid>
          {uploadResumes &&
            !multiLoad &&
            uploadResumes.length >= 1 &&
            fileLen >= 1 &&
            fileLen < 21 && (
              <Grid
                container
                item
                xs={12}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <Button
                  variant={"contained"}
                  color={"primary"}
                  onClick={multipleFileSubmitClick}
                >
                  Parse
                </Button>
              </Grid>
            )}
          {multiLoad && (
            <Grid
              item
              xs={12}
              container
              justifyContent="center"
              alignItems="center"
            >
              <CircularProgress />
            </Grid>
          )}
          {multiFileErr.status && (
            <Grid item xs={12}>
              <Alert
                severity={`${multiFileErr.hasError ? "error" : "success"}`}
              >
                {multiFileErr.message}
              </Alert>
            </Grid>
          )}
          {alertBox.open && (
            <Grid item xs={12}>
              <Alert severity={`${alertBox.success ? "success" : "error"}`}>
                {alertBox.message}
              </Alert>
            </Grid>
          )}
        </Grid>
      </Dialog>
      <DeleteDialog
        dialogOpen={deleteDialogOpen}
        handleClose={handleDeleteDialogClose}
        handleYesClick={handleYesClick}
        message={`Are you sure you want to delete?`}
      />
      <CustomSnack
        open={snack.open}
        color={snack.color}
        message={snack.message}
        onClose={handleSnackClose}
      />
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        anchorOrigin={{
          horizontal: "right",
          vertical: "bottom",
        }}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: "20ch",
            boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
          },
        }}
      >
        {/* <MenuItem onClick={handleSingleFileUploadClick}>Upload</MenuItem> */}
        <MenuItem onClick={handleDownloadClick}>Download</MenuItem>
      </Menu>
      <SimpleModal
        open={modalOpen}
        title={"Reject Applicant"}
        saveButtonText={"Submit"}
        handleClose={handleModalClose}
        handleSave={handleReject}
        maxWidth={"md"}
      >
        <TextField
          name={"rejectNote"}
          label={"Reject Note"}
          value={rejectNote || ""}
          onChange={handleChange}
          variant={"outlined"}
          multiline
          fullWidth
        />
      </SimpleModal>
      <SimpleModal
        open={reStoreModalOpen}
        title={"Restore Applicant"}
        saveButtonText={"Submit"}
        handleClose={handleRestoreModalClose}
        handleSave={handleRestore}
        maxWidth={"md"}
      >
        <Grid container spacing={2}>
          <Grid item xs>
            <Autocomplete
              id={"combo-box-demo"}
              options={jobList}
              value={restoreJob}
              getOptionLabel={(option) => option.position_title}
              onChange={handleRestoreJob}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={"Job Title"}
                  variant={"outlined"}
                  size={"medium"}
                  error={Boolean(restoreJobError)}
                  helperText={restoreJobError || " "}
                  required
                  fullWidth
                />
              )}
              disableClearable
              fullWidth
            />
          </Grid>
          <Grid item xs>
            <TextField
              label={"Job Code"}
              value={restoreJob?.job_code || ""}
              variant={"standard"}
              InputProps={{ readOnly: true }}
              size={"medium"}
              fullWidth
            />
          </Grid>
        </Grid>
      </SimpleModal>
      <Dialog
        maxWidth={"md"}
        open={customMatchUpload}
        onClose={() => { setCustomMatchUpload(false) }}
        PaperProps={{
          style: {
            width: '700px'
          }
        }}
      >
        <Box p={2} bgcolor={"#65c0e1"}>
          <Upload
            setSelectedSkill={setSelectedSkill}
            setSelectedTitle={setSelectedTitle}
            handleFileChange={handleFileChange}
            selectedSkill={selectedSkill}
            selectedTitle={selectedTitle}
            skillList={skillList}
            titleList={titleList}
            errormatch={errormatch}
            loading={loading}
          />
        </Box>
      </Dialog>
      <ConfirmDialog
        dialogOpen={countrySeletcModalOpen || customMatchCountrySeletcModalOpen}
        handleClose={handleResumeSelectionModalClose}
        handleButton1Click={() => handleResumeSelection('US')}
        handleButton2Click={() => handleResumeSelection('IN')}
        message={"Please select the type of resume parsing"}
        button1Name={"US Parser"}
        button2Name={"Ind Parser"}
      />

      <ShareJobToCandidate
        open={shareJobToCandidatePopupOpen}
        candidateEmail={candidateEmail}
        handleEmailChange={handleEmailChange}
        handleClose={handleShareJobToCandidatePopupClose}
        handleSubmit={handleShareJobToCandidate}
        loading={shareJobToCandidateLoading}
      />
    </Container>
  );
}

export default Applicants;
