import { Box, Container, Grid, TextField } from "@material-ui/core";
import React, { useEffect, useState } from "react";

import axios from "axios";
import { useHistory, useLocation } from "react-router-dom";
import {
  reportExportOrSendEmail,
  updateApplicationHistoryReport,
} from "../../api/api";
import BackNavigator from "../../commonComponents/BackNavigator/BackNavigator";
import MaterialTable from "../../commonComponents/MaterialTable/CustomMaterialTable";
import PageLoader from "../../commonComponents/PageLoader";
import CustomSnack from "../../commonComponents/Snack/CustomSnack";
import { formatDate } from "../../utils/constants/helpers";
import { navigateTo } from "../../utils/constants/routePaths";
import ReportLoadingMessage from "./ReportLoadingMessage";

const customEditField = ({
  value,
  onChange,
  columnDef: {
    cellStyle: { minWidth },
  },
}) => {
  return (
    <Box minWidth={minWidth}>
      <TextField
        value={value}
        onChange={({ target: { value } }) => onChange(value)}
        size={"small"}
        fullWidth
        multiline
      />
    </Box>
  );
};

function ApplicationHistoryReport() {
  const columns = [
    {
      field: "applicant_name",
      title: "Applicant Name",
      editable: "never",
      cellStyle: {
        minWidth: 290,
        textAlign: "center",
      },
    },
    {
      field: "job_code",
      title: "Job Code",
      editable: "never",
      cellStyle: {
        minWidth: 160,
        textAlign: "center",
      },
    },
    {
      field: "job_title",
      title: "Job Title",
      editable: "never",
      cellStyle: {
        minWidth: 290,
        textAlign: "center",
      },
    },
    {
      field: "onboard",
      title: "Onboarded",
      editable: "never",
      cellStyle: {
        minWidth: 160,
        textAlign: "center",
      },
    },
    // {
    //   field: "onboard_comment",
    //   title: "Onboard Comment",
    //   editable: "never",
    //   cellStyle: {
    //     minWidth: 290,
    //     textAlign: "center",
    //   },
    // },
    {
      field: "coversheet_sent",
      title: "Coversheet Sent",
      editable: "never",
      cellStyle: {
        minWidth: 160,
        textAlign: "center",
      },
    },
    // {
    //   field: "coversheet_sent_comment",
    //   title: "Coversheet Sent Comment",
    //   editable: "never",
    //   cellStyle: {
    //     minWidth: 290,
    //     textAlign: "center",
    //   },
    // },
    {
      field: "coversheet_submit",
      title: "Coversheet Submited",
      editable: "never",
      cellStyle: {
        minWidth: 160,
        textAlign: "center",
      },
    },
    // {
    //   field: "coversheet_submit_comment",
    //   title: "Coversheet Submit Comment",
    //   editable: "never",
    //   cellStyle: {
    //     minWidth: 290,
    //     textAlign: "center",
    //   },
    // },
    {
      field: "qualified",
      title: "Qualified",
      editable: "never",
      cellStyle: {
        minWidth: 160,
        textAlign: "center",
      },
    },
    // {
    //   field: "qualified_comment",
    //   title: "Qualified Comment",
    //   editable: "never",
    //   cellStyle: {
    //     minWidth: 290,
    //     textAlign: "center",
    //   },
    // },
    {
      field: "moved_to_test",
      title: "Moved To Test",
      editable: "never",
      cellStyle: {
        minWidth: 160,
        textAlign: "center",
      },
    },
    // {
    //   field: "moved_to_test_comment",
    //   title: "Moved To Test Comment",
    //   editable: "never",
    //   cellStyle: {
    //     minWidth: 290,
    //     textAlign: "center",
    //   },
    // },
    {
      field: "test_event_sent",
      title: "Test Event Sent",
      editable: "never",
      cellStyle: {
        minWidth: 160,
        textAlign: "center",
      },
    },
    // {
    //   field: "testevent_sent_comment",
    //   title: "Test Event Sent Comment",
    //   editable: "never",
    //   cellStyle: {
    //     minWidth: 290,
    //     textAlign: "center",
    //   },
    // },
    {
      field: "test_event_submitted",
      title: "Test Event Submitted",
      editable: "never",
      cellStyle: {
        minWidth: 160,
        textAlign: "center",
      },
    },
    // {
    //   field: "test_event_submitted_comment",
    //   title: "Test Event Submitted Comment",
    //   editable: "never",
    //   cellStyle: {
    //     minWidth: 290,
    //     textAlign: "center",
    //   },
    // },
    {
      field: "test_invite_sent",
      title: "Test Invite Sent",
      editable: "never",
      cellStyle: {
        minWidth: 160,
        textAlign: "center",
      },
    },
    // {
    //   field: "test_invite_sent_comment",
    //   title: "Test Invite Sent Comment",
    //   editable: "never",
    //   cellStyle: {
    //     minWidth: 290,
    //     textAlign: "center",
    //   },
    // },
    {
      field: "test_completed",
      title: "Test Completed",
      editable: "never",
      cellStyle: {
        minWidth: 160,
        textAlign: "center",
      },
    },
    // {
    //   field: "test_completed_comment",
    //   title: "Test Completed Comment",
    //   editable: "never",
    //   cellStyle: {
    //     minWidth: 290,
    //     textAlign: "center",
    //   },
    // },
    {
      field: "recommended",
      title: "Recommended",
      editable: "never",
      cellStyle: {
        minWidth: 160,
        textAlign: "center",
      },
    },
    // {
    //   field: "recommended_comment",
    //   title: "Recommended Comment",
    //   editable: "never",
    //   cellStyle: {
    //     minWidth: 290,
    //     textAlign: "center",
    //   },
    // },
    {
      field: "moved_to_interviewed",
      title: "Moved To Interview",
      editable: "never",
      cellStyle: {
        minWidth: 160,
        textAlign: "center",
      },
    },
    {
      field: "rejected",
      title: "Rejected",
      editable: "never",
      cellStyle: {
        minWidth: 160,
        textAlign: "center",
      },
    },
    // {
    //   field: "rejected_comment",
    //   title: "Rejected Comment",
    //   editable: "never",
    //   cellStyle: {
    //     minWidth: 290,
    //     textAlign: "center",
    //   },
    // },
  ];

  const {
    state: {
      selected_job,
      report_type,
      qualified_from,
      qualified_to,
      time_period,
      selected_client
    } = {},
  } = useLocation();
  const { push: navigate } = useHistory();

  const [snack, setSnack] = useState({
    open: false,
    color: "",
    message: "",
  });
  const [historyReportData, setHistoryReportData] = useState(null);

  const handleSnackClose = () => {
    setSnack({
      open: false,
      color: "",
      message: "",
    });
  };

  const fetchReport = () => {
    let formData = new FormData();
    let selectedJob = `[${selected_job.map((el) => `"${el.job_code}"`)}]`;
    formData.append("job_code", selectedJob);
    formData.append("client_name", selected_client.client_name);
    formData.append("report_type", "applicant_history_view");
    formData.append("qualified_from", formatDate(qualified_from, "YYYY-MM-DD"));
    formData.append("qualified_to", formatDate(qualified_to, "YYYY-MM-DD"));
    formData.append("time_period", time_period?.value);
    axios
      .post(reportExportOrSendEmail(), formData)
      .then((response) => {
        if (response.status === 200 && Array.isArray(response.data)) {
          setHistoryReportData(response.data);
        } else {
          setHistoryReportData([]);
          setSnack({
            open: true,
            message: response.data?.Error_Message || "Something went wrong",
            color: "error",
          });
        }
      })
      .catch((err) => {
        setHistoryReportData([]);
        setSnack({
          open: true,
          message: "Something went wrong",
          color: "error",
        });
      });
  };

  const tableContentScroll = () => {
    document.querySelector("body").addEventListener("click", (event) => {
      try {
        var editButtonElements = [
          ...document.querySelectorAll("tbody tr:not([mode]) td button"),
        ];
        var editApproveOrCancelButtonElements = [
          ...document.querySelectorAll("tbody tr[mode] td button"),
        ];
        var tableRows = [...document.querySelectorAll("tbody tr")];

        if (editButtonElements.some((el) => el.contains(event.target))) {
          for (let el of tableRows) {
            if (el.contains(event.target)) {
              let scrollEl = el.querySelector("td:last-of-type");
              scrollEl.scrollIntoView({
                behavior: "smooth",
                block: "nearest",
                inline: "center",
              });
            }
          }
        } else if (
          editApproveOrCancelButtonElements.some((el) =>
            el.contains(event.target)
          )
        ) {
          for (let el of tableRows) {
            if (el.contains(event.target)) {
              let scrollEl = el.querySelector("td:nth-of-type(2)");
              scrollEl.scrollIntoView({
                behavior: "smooth",
                block: "nearest",
                inline: "center",
              });
            }
          }
        }
      } catch (error) {
        console.log(error, "error");
      }
    });
  };

  useEffect(() => {
    if (selected_job) {
      fetchReport();
      tableContentScroll();
    } else {
      handleBack();
    }
  }, []);

  const handleSaveReport = (newData, dataUpdate) => {
    let payload = [
      {
        applicant_id: newData["applicant_id"],
      },
    ];
    axios
      .post(updateApplicationHistoryReport(), payload)
      .then((response) => {
        if (response.status === 201) {
          setHistoryReportData(dataUpdate);
          setSnack({
            open: true,
            message: "Applicant history report updated  successfully",
            color: "success",
          });
        } else {
          setSnack({
            open: true,
            message: "Something went wrong",
            color: "error",
          });
        }
      })
      .catch((err) => {
        setSnack({
          open: true,
          message: "Something went wrong",
          color: "error",
        });
      });
  };

  const handleBack = () => {
    let obj = {
      selected_job,
      report_type,
      qualified_from,
      qualified_to,
      time_period,
      selected_client
    };
    navigate({ pathname: navigateTo.reports, state: obj });
  };

  const handleRowUpdate = (newData, oldData) =>
    new Promise((resolve, reject) => {
      setTimeout(() => {
        let dataUpdate = [...historyReportData];
        let index = oldData.tableData.id;
        dataUpdate[index] = newData;
        handleSaveReport(newData, dataUpdate);

        resolve();
      }, 1000);
    });

  return (
    <Container
      className={"container-style mt-5"}
      style={{ maxWidth: "1440px" }}
    >
      {historyReportData ? (
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <BackNavigator
              handleBack={handleBack}
              title={"Applicant History Report"}
            />
          </Grid>
          <Grid item xs={12}>
            <MaterialTable
              columns={columns.map((column) => ({
                ...column,
                render: (rowData) => rowData[column.field] || "-",
              }))}
              data={historyReportData}
              // editable={{
              //   onRowUpdate: handleRowUpdate,
              // }}
              options={{
                pageSize: 10,
                pageSizeOptions: [10, 25, 50],
                showTitle: false,
              }}
              localization={{
                body: {
                  emptyDataSourceMessage: (
                    <p className={"table_empty_text"}>{"Data not found"}</p>
                  ),
                },
              }}
            />
          </Grid>
          <Grid item xs />
        </Grid>
      ) : (
        <PageLoader>
          <ReportLoadingMessage />
        </PageLoader>
      )}
      <CustomSnack
        open={snack.open}
        color={snack.color}
        message={snack.message}
        onClose={handleSnackClose}
      />
    </Container>
  );
}

export default ApplicationHistoryReport;
