import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';


  class WorkExperience extends Component {

    duration = (duration) => {
      var text = duration.replace("2999", "Present");
        if(text.includes('Present') === true){
           //text = text.substring(4);
           text = text.slice(0, 8);
           text = text + " - Present";
           //text = text.slice(0, 15) + text.slice(5,8);
        }



  return text;


    }

    render(){
  var wdatas =this.props.WorkData.work_experience;

  return (
    <form className="basic_form" noValidate autoComplete="off">
     {wdatas.map((row,index) => (
       <div className="work_exp">
<div className="bcontent">
          <label className="blabe">Client</label>
        <TextField
          id={index}
          defaultValue={row.client}
          variant="outlined"
          size="small"
          name="client"
          disabled = {(this.props.disabled)? "disabled" : ""}
          onChange={this.props.handleWorkSubmit}
          className="btextfied"        />
      </div>
      <div className="bcontent">
          <label className="blabe">Location:</label>
        <TextField
            id={index}
          defaultValue={row.location}
          variant="outlined"
          size="small"
          name="location"
          disabled = {(this.props.disabled)? "disabled" : ""}
          onChange={this.props.handleWorkSubmit}
          className="btextfied"
        />
      </div>
      <div className="bcontent">
          <label className="blabe">Duration:</label>
        <TextField
             id={index}
          defaultValue={this.duration(row.duration)}
          variant="outlined"
          size="small"
          name="duration"
          disabled = {(this.props.disabled)? "disabled" : ""}
          onChange={this.props.handleWorkSubmit}
          className="btextfied"
        />
      </div>
      <div className="bcontent">
          <label className="blabe">Role:</label>
        <TextField
             id={index}
          defaultValue={row.role}
          variant="outlined"
          size="small"
          name="role"
          disabled = {(this.props.disabled)? "disabled" : ""}
          onChange={this.props.handleWorkSubmit}
          className="btextfied"
        />
      </div>
      <div className="bcontent">
          <label className="blabe">Responsibilities: </label>
          <TextareaAutosize         disabled = {(this.props.disabled)? "disabled" : ""}       id={index}      onChange={this.props.handleWorkSubmit}   name="project_details" className="btextfied" aria-label="minimum height" rowsMin={3} defaultValue={row.project_details} />

      </div>
      <div className="bcontent">
          <label className="blabe">Environment:</label>
          <TextareaAutosize              disabled = {(this.props.disabled)? "disabled" : ""}      id={index}   onChange={this.props.handleWorkSubmit}     name="environment"  className="btextfied" aria-label="minimum height" rowsMin={3} defaultValue={row.environment} />

      </div>
       </div>

 ))}
    </form>
  );
     }
}

export default WorkExperience;