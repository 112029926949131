import {
  Button,
  Checkbox,
  Container,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { History, Info } from "@material-ui/icons";
import GetAppIcon from "@material-ui/icons/GetApp";
import { Alert, Pagination } from "@material-ui/lab";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import {
  clientEmailSetting,
  downloadQualifyReport,
  downloadResume,
  getAllQualify,
  getCoverSheetFormQuestionAnswer,
  postSelectedIds,
  recommendForInterview,
  rejectApplicant,
  sendForApproval,
  sendToManager,
  updateQualifyStatus,
} from "../../api/api";
import Loader from "../../commonComponents/Loader/Loader";
import SimpleModal from "../../commonComponents/Modal/SimpleModal";
import SendToManagerPopup from "../../commonComponents/SendToManagerPopup";
import CustomSnack from "../../commonComponents/Snack/CustomSnack";
import {
  StyledTableContainer,
  StyledTableHead,
} from "../../commonComponents/StyledComponents";
import { setQualifyState } from "../../redux/actions/qualify";
import { MESSAGES, REJECT_MODULE } from "../../utils/constants/constants";
import {
  FEEDBACK_LEVELS,
  FEEDBACK_RELATED_PAGES,
  getFeedbackLevelBasedAccess,
} from "../../utils/constants/feedbackLevelBasedAccess";
import {
  errorResponse,
  getOffset,
  getPageUsingRemovingAnElement,
  validateEmail,
} from "../../utils/constants/helpers";
import {
  ADMINS,
  ROLES,
  ROLE_BASED_PAGES,
  SEND_TO_MANAGER_BUTTON_TEXT_QUALIFY_PAGE,
  SEND_TO_MANAGER_SUCCESS_MESSAGE,
  getRoleBasedAccess,
} from "../../utils/constants/roleBasedAccess";
import ResumeHeaderReport from "../UploadParseResumeComponents/CustomMatchReport/SkillMatchResumeHeaderReport";
import CoverSheetPDF from "./Qualify/CoverSheetPDF";
import QualifySkillMatchReport from "./Qualify/QualifySkillMatchReport";
import "./style.css";

const COVER_SHEET_FORM = {
  personal_details: {},
  education: [],
  certification: [],
  project_details: [],
  skill_details: [],
  hobbies: {},
  current_employer: {},
  previous_employer: [],
  currentLocation: "",
  reLocation: "",
  workOption: "",
  variablePay: "",
  annualSalary: "",
  currentCtc: "",
  currentNoticePeriod: "",
  backgroundCheck: "",
  past_experience: {},
  employment_change: {},
};

export const Options = [
  {
    id: 1,
    value: "10",
  },
  {
    id: 2,
    value: "20",
  },
  {
    id: 3,
    value: "30",
  },
  {
    id: 4,
    value: "40",
  },
  {
    id: 5,
    value: "50",
  },
  {
    id: 6,
    value: "100",
  },
];

axios.defaults.xsrfHeaderName = "X-CSRFToken";
axios.defaults.xsrfCookieName = "csrftoken";

function Qualify({ getAndSetCount = () => {} }) {
  const history = useHistory();
  const { jobCode, jobTitle } = useParams();
  const role = localStorage.getItem("role");
  const isRecruiter = ROLES.recruiter === role;
  const isAdmin = ADMINS.includes(role);
  const isManager = ROLES.manager === role;
  const positionTitle = sessionStorage.getItem("jobTitle");
  const job_title = positionTitle ? positionTitle : jobTitle;

  const {
    isAllowSendToManager = true,
    isAllowMoveToRecommend = true,
    isAllowStatusUpdate = true,
  } = getRoleBasedAccess(role, ROLE_BASED_PAGES.qualifyLanding);

  const feedbackLevel = sessionStorage.getItem("feedbackLevel");
  const {
    isSendToManager,
    isReject = () => false,
    isApprovalStatus,
    isModuleStatus,
  } = getFeedbackLevelBasedAccess(
    feedbackLevel,
    FEEDBACK_RELATED_PAGES.qualifyLanding
  );

  const [totalCount, setTotalCount] = useState(0);
  const [qualifyList, setQualifyList] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);
  const [selectedObjects, setSelectedObjects] = useState([]);
  const [reportData, setReportData] = useState(undefined);
  const [skills, setSkills] = useState([]);
  const [resumeNames, setResumeNames] = useState([]);
  const [weightage, setWeightage] = useState([]);
  const [isSkillClicked, setIsSkillClicked] = useState(false);
  const [error, setError] = useState({
    message: "",
    success: false,
  });
  const [loading, setLoading] = useState(false);
  const [coverSheetFormData, setCoverSheetFormData] = useState(null);
  const [skillMatchPDFData, setSkillMatchPDFData] = useState(null);
  const [snack, setSnack] = useState({
    open: false,
    color: "",
    message: "",
  });
  const [modalOpen, setModalOpen] = useState(false);
  const [rejectNote, setRejectNote] = useState("");
  const [rejectedApplicant, setRejectedApplicant] = useState({});
  const [sendToManagerEmail, setSendToManagerEmail] = useState([]);
  const [sendToManagerPopupOpen, setSendToManagerPopupOpen] = useState(false);
  const [sendToManagerLoading, setSendToManagerLoading] = useState(false);
  const [managerEmailList, setManagerEmailList] = useState([]);

  const { pagination } = useSelector((state) => state.qualify);
  const dispatch = useDispatch();

  const findIsClient = () => {
    const { pathname } = history.location;
    return pathname.includes("yes");
  };

  const header_config = findIsClient()
    ? {}
    : {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("tal_token")}`,
        },
      };

  const handleSnackClose = () => {
    setSnack({
      open: false,
      color: "",
      message: "",
    });
  };

  const handleSnackOpen = (message = MESSAGES.error, color = "error") => {
    setSnack({
      open: true,
      color: color,
      message: message || MESSAGES.error,
    });
  };

  const setData = (data) => {
    setQualifyList(data.results);
    setTotalCount(data.count);
  };

  const hasFeedbackStatus = (level) => {
    return level === feedbackLevel ? "True" : null;
  };

  const fetchQualifyList = async (limit, page) => {
    try {
      const res = await axios.get(getAllQualify(jobCode), {
        params: {
          limit: limit,
          offset: getOffset(page, limit),
          is_applied: hasFeedbackStatus(FEEDBACK_LEVELS.funnel),
          is_qualified: hasFeedbackStatus(FEEDBACK_LEVELS.qualified),
          is_rejected: hasFeedbackStatus(FEEDBACK_LEVELS.rejected),
          is_selected:
            hasFeedbackStatus(FEEDBACK_LEVELS.movedToTest) ||
            hasFeedbackStatus(FEEDBACK_LEVELS.testPending),
          is_testcompleted:
            FEEDBACK_LEVELS.testPending === feedbackLevel
              ? "False"
              : hasFeedbackStatus(FEEDBACK_LEVELS.testCompleted),
          is_moved: isManager
            ? hasFeedbackStatus(FEEDBACK_LEVELS.recommend)
            : null || hasFeedbackStatus(FEEDBACK_LEVELS.clientInterview),
          is_recommend: isManager
            ? null
            : hasFeedbackStatus(FEEDBACK_LEVELS.recommend),
          is_manager: isManager || findIsClient() ? "True" : null,
          // is_manager: findIsClient() ? "True" : null,
        },
      });
      return res;
    } catch (error) {
      return errorResponse(error);
    }
  };

  const getAndSetQualifyList = async (limit, page) => {
    setLoading(true);
    const response = await fetchQualifyList(limit, page);
    if (response.status === 200) {
      setData(response.data);
    } else {
      handleSnackOpen(response.message);
    }
    setLoading(false);
  };

  const onSelectPage = (e, value) => {
    getAndSetQualifyList(pagination.items, value);
    dispatch(
      setQualifyState({
        pagination: { ...pagination, activePage: value },
      })
    );
  };

  const handlePageLimitChange = (e) => {
    const { value } = e.target;
    getAndSetQualifyList(parseInt(value), pagination.activePage);
    dispatch(
      setQualifyState({
        pagination: { ...pagination, items: parseInt(value) },
      })
    );
  };

  const handleCheckboxClick = (e, id, selectedObj) => {
    let tempSelectedIds = [...selectedIds];
    let tempSelectedObjects = [...selectedObjects];

    let indexOfId = tempSelectedIds.findIndex((el) => el === selectedObj.id);

    if (indexOfId !== -1) {
      tempSelectedIds.splice(indexOfId, 1);
      tempSelectedObjects.splice(indexOfId, 1);
    } else {
      tempSelectedIds.push(selectedObj.id);
      tempSelectedObjects.push(selectedObj);
    }

    setSelectedIds(tempSelectedIds);
    setSelectedObjects(tempSelectedObjects);
  };

  const handleMoveToTestClick = () => {
    axios
      .post(postSelectedIds(jobCode), { ids: selectedIds })
      .then((response) => {
        if (response.status === 201 || response.status === 200) {
          setError({
            message: "Candidates successfully moved to the test module.",
            success: true,
          });
          getAndSetQualifyList(pagination.items, pagination.activePage);
          if (!findIsClient()) {
            getAndSetCount();
          }
          setSelectedIds([]);
        }
      })
      .catch((err) => {
        console.log(err);
        setError({
          message: "Something went wrong.",
          success: false,
        });
      })
      .finally(() => {
        setTimeout(() => {
          setError({
            message: "",
            success: false,
          });
        }, 2000);
      });
  };

  const handleMoveToInterview = () => {
    let applicantIds = [];
    for (let i = 0; i < selectedIds.length; i++) {
      let applicantObj = qualifyList.find((el) => el.id === selectedIds[i]);
      applicantIds.push(applicantObj?.applicant?.id);
    }
    let formData = new FormData();
    formData.append("job_code", jobCode);
    formData.append("applicant", applicantIds.join(","));
    formData.append("is_interviewed", "True");

    axios
      .post(recommendForInterview(), formData)
      .then((response) => {
        if (response.status === 201) {
          setError({
            message: "Candidates successfully moved to the interview module.",
            success: true,
          });
          getAndSetQualifyList(pagination.items, pagination.activePage);
          if (!findIsClient()) {
            getAndSetCount();
          }
          setSelectedIds([]);
        }
      })
      .catch((err) => {
        console.log(err);
        setError({
          message: "Something went wrong.",
          success: false,
        });
      })
      .finally(() => {
        setTimeout(() => {
          setError({
            message: "",
            success: false,
          });
        }, 2000);
      });
  };

  const handleSendToManagerClick = () => {
    if (isRecruiter && !findIsClient()) {
      let applicantIds = [];
      for (let i = 0; i < selectedIds.length; i++) {
        let applicantObj = qualifyList.find((el) => el.id === selectedIds[i]);
        if (applicantObj?.applicant?.id)
          applicantIds.push(applicantObj.applicant.id);
      }

      let formData = new FormData();
      formData.append("job_code", jobCode);
      formData.append("job_title", job_title);
      formData.append("applicant_ids", `[${applicantIds.join(",")}]`);

      axios
        .post(sendForApproval(), formData)
        .then((response) => {
          if (response.status === 200 || response.status === 201) {
            setError({
              message:
                SEND_TO_MANAGER_SUCCESS_MESSAGE[
                  findIsClient() ? ROLES.manager : role
                ],
              success: true,
            });
            getAndSetQualifyList(pagination.items, pagination.activePage);
            getAndSetCount();
            setSelectedIds([]);
          }
        })
        .catch((err) => {
          console.log(err);
          setError({
            message: "Something went wrong.",
            success: false,
          });
        })
        .finally(() => {
          setTimeout(() => {
            setError({
              message: "",
              success: false,
            });
          }, 2000);
        });
    } else {
      axios
        .get(clientEmailSetting())
        .then((response) => {
          if (response.status === 200) {
            setSendToManagerPopupOpen(true);
            let obj = response.data[0];
            if (obj) {
              let options = [];
              for (const [key, value] of Object.entries(obj)) {
                if (findIsClient() || isManager) {
                  // If check the hiring manager email
                  if (key.includes("hm_email") && validateEmail(value))
                    options.push(value);
                } else {
                  // If check the client manager email
                  if (
                    key.includes("client_manager_email") &&
                    validateEmail(value)
                  )
                    options.push(value);
                }
              }
              setManagerEmailList(options);
            }
          } else {
            handleSnackOpen();
          }
        })
        .catch((error) => {
          handleSnackOpen();
        });
    }
  };

  const handleExportClick = (applicantId) => {
    let formData = new FormData();
    formData.append("job_code", jobCode);
    formData.append("resume_ids", `["${applicantId}"]`);

    axios
      .post(downloadQualifyReport(), formData)
      .then((response) => {
        if (response.status === 201) {
          setSkillMatchPDFData(response.data);
        } else {
          handleSnackOpen();
        }
      })
      .catch((err) => {
        handleSnackOpen();
      });
  };

  const filterBy =
    findIsClient() || isManager
      ? "is_selected"
      : isRecruiter
      ? "is_recruiter_approved"
      : isAdmin
      ? "is_manager"
      : "is_moved";

  const handleSelectAllClick = (e) => {
    let { checked } = e.target;
    let tempSelectedIds = [...selectedIds];
    if (checked) {
      let filteredQualifyList = qualifyList.filter(
        (el) => !el.applicant[filterBy]
      );

      filteredQualifyList.map((el) => {
        if (!tempSelectedIds.includes(el.id)) {
          tempSelectedIds.push(el.id);
        }
      });
    } else {
      tempSelectedIds = [];
    }

    setSelectedIds(tempSelectedIds);
  };

  const fetchAndCheck = async () => {
    setLoading(true);
    const response = await fetchQualifyList(
      pagination.items,
      pagination.activePage
    );

    if (response.status === 200) {
      // If the count and result data was not satisfied, then again call the same api with first page of the data
      if (!response.data.results.length && response.data.count) {
        getAndSetQualifyList(pagination.items, 1);
        dispatch(
          setQualifyState({
            pagination: { ...pagination, activePage: 1 },
          })
        );
        return;
      } else {
        if (!job_title) {
          sessionStorage.setItem(
            "jobTitle",
            response.data?.results?.[0]?.applicant?.job_title
          );
        }
        setData(response.data);
      }
    } else {
      handleSnackOpen(response.message);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchAndCheck();
  }, []);

  useEffect(() => {
    if (isSkillClicked) {
      setIsSkillClicked(false);
    }
  }, [isSkillClicked]);

  let concatData = [
    ...qualifyList
      .filter((el) => el.applicant[filterBy])
      .map((el) => `${el.id}`),
    ...selectedIds,
  ];

  const handleExportCoverSheet = (applicant) => {
    const resume = applicant.resume;
    const applicantId = applicant.id;
    axios
      .get(getCoverSheetFormQuestionAnswer(applicantId, jobCode), header_config)
      .then(async (response) => {
        if (response.status === 200) {
          let formattedObj = {
            ...COVER_SHEET_FORM,
          };
          await response.data.map((el) => {
            formattedObj[el.title] = el.answer;
          });

          // Skill Details
          let arr = [];
          let parsed_skill_details = formattedObj.skill_details
            ? JSON.parse(formattedObj.skill_details)
            : null;
          if (parsed_skill_details && parsed_skill_details.length) {
            arr = [...parsed_skill_details];
          }

          // Project Details
          let projectDetailsArr = [];
          let parsed_project_details = formattedObj.project_details
            ? JSON.parse(formattedObj.project_details)
            : null;
          if (parsed_project_details && parsed_project_details.length) {
            projectDetailsArr = [...parsed_project_details];
            for (let i = 0; i < parsed_project_details.length; i++) {
              if (
                parsed_project_details[i]["project_details"] &&
                parsed_project_details[i]["project_details"].length
              ) {
                for (
                  let j = 0;
                  j < parsed_project_details[i]["project_details"].length;
                  j++
                ) {
                  let duration =
                    projectDetailsArr[i]["project_details"][j][
                      "duration"
                    ]?.split(" - ");
                  let durationStartDate = duration?.[0];
                  let durationEndDate = duration?.[1];
                  projectDetailsArr[i]["project_details"][j]["duration_from"] =
                    durationStartDate ? durationStartDate : "";
                  projectDetailsArr[i]["project_details"][j]["duration_to"] =
                    durationEndDate ? durationEndDate : "";
                }
              }
            }
          }

          // Education value set
          let parsed_education = formattedObj.education
            ? JSON.parse(formattedObj.education)
            : null;
          if (parsed_education && parsed_education.length) {
            formattedObj.education = parsed_education;
          }

          // Certification value set
          let parsed_certification = formattedObj.certification
            ? JSON.parse(formattedObj.certification)
            : null;
          if (parsed_certification && parsed_certification.length) {
            formattedObj.certification = parsed_certification;
          }

          // Employment history
          let parsed_employment_history = formattedObj.employment_history
            ? JSON.parse(formattedObj.employment_history)
            : null;
          if (parsed_employment_history && parsed_employment_history.length) {
            let employment_history_obj = { ...parsed_employment_history[0] };

            // Current employer value set
            if (employment_history_obj.current_employer) {
              let obj = { ...employment_history_obj.current_employer };
              let duration = obj["duration"]?.split(" - ");
              let durationStartDate = duration?.[0];
              let durationEndDate = duration?.[1];
              obj["duration_from"] = durationStartDate || "";
              obj["duration_to"] = durationEndDate || "";
              formattedObj.current_employer = obj;
            }

            // Previous employer value set
            if (
              employment_history_obj.previous_employer &&
              Array.isArray(employment_history_obj.previous_employer) &&
              employment_history_obj.previous_employer.length
            ) {
              let arr = [...employment_history_obj.previous_employer];
              for (
                let i = 0;
                i < employment_history_obj.previous_employer.length;
                i++
              ) {
                let duration = arr[i]["duration"]?.split(" - ");
                let durationStartDate = duration?.[0];
                let durationEndDate = duration?.[1];
                arr[i]["duration_from"] = durationStartDate || "";
                arr[i]["duration_to"] = durationEndDate || "";
              }

              formattedObj.previous_employer = arr;
            }
            delete formattedObj.employment_history;
          }

          // Personal details
          let parsed_personal_details = formattedObj.personal_details
            ? JSON.parse(formattedObj.personal_details)
            : null;
          if (parsed_personal_details && parsed_personal_details.length) {
            formattedObj.personal_details = parsed_personal_details[0];
          } else {
            formattedObj.personal_details = resume ? resume : {};
          }

          // Hobbies
          let parsed_hobbies = formattedObj.hobbies
            ? JSON.parse(formattedObj.hobbies)
            : null;
          if (parsed_hobbies && parsed_hobbies.length) {
            formattedObj.hobbies = parsed_hobbies[0];
          }

          // Past Experience change
          let parsed_past_experience = formattedObj.past_experience
            ? JSON.parse(formattedObj.past_experience)
            : null;
          if (parsed_past_experience) {
            formattedObj.past_experience = parsed_past_experience;
          }

          // Employment change
          let parsed_employment_change = formattedObj.employment_change
            ? JSON.parse(formattedObj.employment_change)
            : null;
          if (parsed_employment_change) {
            formattedObj.employment_change = parsed_employment_change;
          }

          setCoverSheetFormData({
            ...formattedObj,
            skill_details: arr,
            project_details: projectDetailsArr,
          });
        }
      });
  };

  const handleStatusUpdate = (e, { index, applicant }) => {
    const { name, checked } = e.target;

    let newChecked = checked;
    if (name === "admin_is_recruiter_approved") newChecked = !checked;

    let isAdminApprovedSwitch = name === "is_admin_approved";
    let arr = [...qualifyList];
    let prev_is_recruiter_approved =
      qualifyList[index]["applicant"]["is_recruiter_approved"];
    let prev_is_admin_approved =
      qualifyList[index]["applicant"]["is_admin_approved"];

    if (isAdminApprovedSwitch) {
      arr[index]["applicant"]["is_admin_approved"] = newChecked;
      arr[index]["applicant"]["is_recruiter_approved"] = newChecked
        ? true
        : prev_is_recruiter_approved;
    } else {
      arr[index]["applicant"]["is_recruiter_approved"] = newChecked;
      arr[index]["applicant"]["is_admin_approved"] = false;
    }
    setQualifyList(arr);

    let payload = isAdminApprovedSwitch
      ? {
          is_admin_approved: newChecked ? "True" : "False",
          is_recruiter_approved: newChecked
            ? "True"
            : prev_is_recruiter_approved
            ? "True"
            : "False",
        }
      : {
          is_recruiter_approved: newChecked ? "True" : "False",
          is_admin_approved: "False",
        };

    axios
      .patch(updateQualifyStatus(jobCode, applicant?.id), payload)
      .then((response) => {
        if (response.status === 200) {
          setSnack({
            open: true,
            color: "success",
            message: "Updated Successfully",
          });
        } else {
          arr[index]["applicant"]["is_admin_approved"] = prev_is_admin_approved;
          arr[index]["applicant"]["is_recruiter_approved"] =
            prev_is_recruiter_approved;

          handleSnackOpen();
        }
        setQualifyList(arr);
      })
      .catch((err) => {
        arr[index]["applicant"]["is_admin_approved"] = prev_is_admin_approved;
        arr[index]["applicant"]["is_recruiter_approved"] =
          prev_is_recruiter_approved;
        setQualifyList(arr);
        handleSnackOpen();
      });
  };

  const handleUnMoveStatusUpdate = ({ index, applicant }) => {
    let arr = [...qualifyList];

    arr[index]["applicant"]["is_manager"] = false;
    setQualifyList(arr);

    let payload = {
      is_manager: "False",
    };

    axios
      .patch(updateQualifyStatus(jobCode, applicant?.id), payload)
      .then((response) => {
        if (response.status === 200) {
          setSnack({
            open: true,
            color: "success",
            message: "Updated Successfully",
          });
        } else {
          arr[index]["applicant"]["is_manager"] = true;
          handleSnackOpen();
        }
        setQualifyList(arr);
      })
      .catch((err) => {
        arr[index]["applicant"]["is_manager"] = true;
        setQualifyList(arr);
        handleSnackOpen();
      });
  };

  const isMoveToInterview = qualifyList?.some(
    (el) => el.technical_test?.toLocaleLowerCase() === "no"
  );

  const handleResumeDownload = (applicantId) => {
    let payload = {
      applicant_id: applicantId,
    };
    axios
      .post(downloadResume(), payload)
      .then((response) => {
        window.open(response.data.url);
      })
      .catch((error) => {
        handleSnackOpen();
      });
  };

  const handleRejectClick = (applicant) => {
    setModalOpen(true);
    setRejectedApplicant(applicant);
  };

  const handleModalClose = () => {
    setModalOpen(false);
    setRejectNote(null);
    setRejectedApplicant({});
  };

  const handleReject = () => {
    let payload = {
      applicant_id: rejectedApplicant.id,
      job_code: rejectedApplicant.job_code,
      reject_note: rejectNote,
      reject_module: REJECT_MODULE.qualify,
    };
    axios
      .post(rejectApplicant(), payload)
      .then((response) => {
        setModalOpen(false);
        setRejectNote(null);
        setSelectedIds([]);
        setRejectedApplicant({});
        if (response.status === 200) {
          setSnack({
            open: true,
            color: "success",
            message: "Applicant Rejected Successfully",
          });
          setTimeout(() => {
            let page = getPageUsingRemovingAnElement(
              totalCount,
              pagination.activePage,
              pagination.items
            );
            getAndSetQualifyList(pagination.items, page);
            dispatch(
              setQualifyState({
                pagination: { ...pagination, activePage: page },
              })
            );
            getAndSetCount();
          }, 2500);
        } else {
          handleSnackOpen();
        }
      })
      .catch((err) => {
        setModalOpen(false);
        setRejectNote(null);
        setRejectedApplicant({});
        handleSnackOpen();
      });
  };

  const handleChange = (e) => {
    const { value } = e.target;
    setRejectNote(value);
  };

  const handleEmailChange = (val) => {
    let isNotValid = val.some((el) => !validateEmail(el));
    if (val.length && isNotValid) {
      setSnack({
        open: true,
        message: "Please enter a valid email",
        color: "error",
      });
    } else {
      setSendToManagerEmail(val);
    }
  };

  const handleSentToManagerPopupClose = () => {
    setSendToManagerPopupOpen(false);
    setSendToManagerEmail([]);
  };

  const handleSentToManager = () => {
    if (sendToManagerEmail.length) {
      setSendToManagerLoading(true);

      let stringData = [...selectedIds.map((el) => `"${el}"`)];
      let to_email = `[${sendToManagerEmail.map((email) => `"${email}"`)}]`;

      let formData = new FormData();
      formData.append("job_code", jobCode);
      formData.append(
        "subject",
        `Qualified Candidates for Job Code ${jobCode}`
      );
      formData.append("file_name", "send_qualified_candidates");
      formData.append("applicant_ids", `[${stringData}]`);
      formData.append(
        "role",
        findIsClient() ? ROLES.manager : isAdmin ? ROLES.admin : role
      );
      formData.append("to_email", to_email);

      axios
        .post(sendToManager(), formData)
        .then((response) => {
          if (response.status === 200 || response.status === 201) {
            handleSnackOpen(
              SEND_TO_MANAGER_SUCCESS_MESSAGE[
                findIsClient() ? ROLES.manager : role
              ],
              "success"
            );
            getAndSetQualifyList(pagination.items, pagination.activePage);
            getAndSetCount();
            setSelectedIds([]);
            handleSentToManagerPopupClose();
          } else {
            handleSnackOpen();
          }
          setSendToManagerLoading(false);
        })
        .catch((err) => {
          setSendToManagerLoading(false);
          handleSnackOpen();
        });
    } else {
      handleSnackOpen("Please select the To Email");
    }
  };

  const handleViewHistory = (id) => {
    history.push(`/applicant-history/${jobCode}/${id}`, {
      reference_name: "qualify",
    });
  };

  return (
    <Container maxWidth={"xl"} className={"container-style mt-7"}>
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          container
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography>
            <b>{`${job_title ? `${job_title} - ` : ""}${jobCode}`}</b>
          </Typography>
          <div className="flex items-center gap-2">
            {findIsClient() ? (
              <>
                <Button
                  variant={"contained"}
                  onClick={handleSendToManagerClick}
                  color={"primary"}
                  disabled={selectedIds.length === 0}
                >
                  {SEND_TO_MANAGER_BUTTON_TEXT_QUALIFY_PAGE[ROLES.manager]}
                </Button>
                <Button
                  variant={"contained"}
                  onClick={() =>
                    isMoveToInterview
                      ? handleMoveToInterview()
                      : handleMoveToTestClick()
                  }
                  color={"primary"}
                  disabled={selectedIds.length === 0}
                >
                  {isMoveToInterview ? "Move To Interview" : "Move To Test"}
                </Button>
              </>
            ) : (
              <>
                {isSendToManager ? (
                  <Button
                    variant={"contained"}
                    onClick={handleSendToManagerClick}
                    color={"primary"}
                    disabled={!isAllowSendToManager || selectedIds.length === 0}
                  >
                    {SEND_TO_MANAGER_BUTTON_TEXT_QUALIFY_PAGE[role] ||
                      "Send To Manager"}
                  </Button>
                ) : null}
                {isManager ? (
                  <Button
                    variant={"contained"}
                    onClick={() =>
                      isMoveToInterview
                        ? handleMoveToInterview()
                        : handleMoveToTestClick()
                    }
                    color={"primary"}
                    disabled={
                      !isAllowMoveToRecommend || selectedIds.length === 0
                    }
                  >
                    {isMoveToInterview ? "Move To Interview" : "Move To Test"}
                  </Button>
                ) : null}
                {isManager ? (
                  <Tooltip
                    title={
                      <Typography>
                        <b>{"Note: "}</b>
                        {
                          "If you wish to send the profiles to hiring manager, make sure to add the emails in the settings page."
                        }
                      </Typography>
                    }
                    classes={{ tooltip: "tooltip_custom_style" }}
                    arrow
                  >
                    <Info color={"primary"} style={{ cursor: "pointer" }} />
                  </Tooltip>
                ) : null}
              </>
            )}
          </div>
        </Grid>
        {error.message ? (
          <Grid item xs={12}>
            <Alert severity={`${!error.success ? "error" : "success"}`}>
              {error.message}
            </Alert>
          </Grid>
        ) : (
          <></>
        )}
        <Grid item xs={12}>
          <StyledTableContainer component={Paper} variant={"outlined"}>
            <Table size={"small"}>
              <StyledTableHead>
                <TableRow>
                  <TableCell align="center">
                    <Checkbox
                      checked={
                        qualifyList.length ===
                        selectedIds.length +
                          qualifyList.filter((el) => el.applicant[filterBy])
                            .length
                      }
                      onChange={handleSelectAllClick}
                    />
                  </TableCell>
                  <TableCell>{"Applicant Name"}</TableCell>
                  <TableCell>{"Resume Score"}</TableCell>
                  <TableCell>{"Preferred Company Score"}</TableCell>
                  <TableCell>{"Bonus Score"}</TableCell>
                  <TableCell>Total Score</TableCell>
                  <TableCell>Resume</TableCell>
                  <TableCell>Skill Match Report</TableCell>
                  <TableCell>Cover Sheet</TableCell>
                  {isApprovalStatus && isAdmin ? (
                    <TableCell>{"Recruiter Approval Status"}</TableCell>
                  ) : null}
                  {isApprovalStatus &&
                  isAllowStatusUpdate &&
                  !findIsClient() ? (
                    <TableCell>
                      {isAdmin
                        ? "Reject Recruiter Approval"
                        : "Approval Status"}
                    </TableCell>
                  ) : null}
                  {isModuleStatus ? (
                    <TableCell align="center">
                      {"Module Source Status"}
                    </TableCell>
                  ) : null}
                  {isReject(role) ? (
                    <TableCell align={"center"}>{"Reject Applicant"}</TableCell>
                  ) : null}
                  <TableCell align={"center"}>{"View History"}</TableCell>
                </TableRow>
              </StyledTableHead>
              <TableBody>
                {!loading && qualifyList.length > 0 ? (
                  qualifyList?.map(
                    (
                      {
                        file_name,
                        overall_calculated_weightage,
                        preferred_company_weightage,
                        msp_company_weightage,
                        bonus_score,
                        total_score,
                        id,
                        applicant,
                      },
                      index
                    ) => {
                      const disabledCheckbox =
                        findIsClient() || isManager
                          ? applicant.is_manager &&
                            (applicant.is_selected || applicant.is_moved)
                          : isRecruiter
                          ? applicant.is_recruiter_approved
                          : isAdmin
                          ? false
                          : applicant.is_moved;
                      const checked =
                        findIsClient() || isManager
                          ? applicant.is_selected ||
                            applicant.is_moved ||
                            selectedIds.includes(id)
                          : isRecruiter
                          ? applicant.is_recruiter_approved ||
                            selectedIds.includes(id)
                          : isAdmin
                          ? applicant.is_manager || selectedIds.includes(id)
                          : applicant.is_moved || selectedIds.includes(id);
                      return (
                        <TableRow>
                          <TableCell align="center" padding="none">
                            <Checkbox
                              disabled={disabledCheckbox}
                              checked={checked}
                              onClick={(e) => {
                                if (isAdmin) {
                                  if (applicant.is_manager) {
                                    handleUnMoveStatusUpdate({
                                      applicant,
                                      index,
                                    });
                                  } else {
                                    handleCheckboxClick(e, applicant.id, {
                                      file_name,
                                      overall_calculated_weightage,
                                      preferred_company_weightage,
                                      bonus_score,
                                      total_score,
                                      id,
                                    });
                                  }
                                } else {
                                  handleCheckboxClick(e, applicant.id, {
                                    file_name,
                                    overall_calculated_weightage,
                                    preferred_company_weightage,
                                    bonus_score,
                                    total_score,
                                    id,
                                  });
                                }
                              }}
                            />
                          </TableCell>
                          <TableCell>
                            {`${applicant?.firstname || ""} ${
                              applicant?.lastname || ""
                            }`.trim()}
                          </TableCell>
                          <TableCell>{overall_calculated_weightage}</TableCell>
                          <TableCell>{preferred_company_weightage}</TableCell>
                          <TableCell>{bonus_score}</TableCell>
                          <TableCell>{total_score}</TableCell>
                          <TableCell>
                            <IconButton
                              color={"primary"}
                              onClick={() => {
                                handleResumeDownload(applicant.id);
                              }}
                            >
                              <GetAppIcon />
                            </IconButton>
                          </TableCell>
                          <TableCell>
                            <IconButton
                              color={"primary"}
                              onClick={() => handleExportClick(id)}
                            >
                              <GetAppIcon />
                            </IconButton>
                          </TableCell>
                          <TableCell>
                            <IconButton
                              color={"primary"}
                              onClick={() => handleExportCoverSheet(applicant)}
                              // disabled
                            >
                              <GetAppIcon />
                            </IconButton>
                          </TableCell>
                          {isApprovalStatus && isAdmin ? (
                            <TableCell>
                              <b>
                                {applicant.is_recruiter_approved
                                  ? "Approved"
                                  : "Pending"}
                              </b>
                            </TableCell>
                          ) : null}
                          {isApprovalStatus &&
                          isAllowStatusUpdate &&
                          !findIsClient() ? (
                            <TableCell>
                              {ROLES.recruiter === role ? (
                                <Switch
                                  checked={applicant.is_recruiter_approved}
                                  disabled={
                                    ROLES.recruiter === role &&
                                    applicant.is_recruiter_approved
                                  }
                                  color={"default"}
                                  classes={{
                                    root: "custom_toggle_switch_style",
                                  }}
                                  name={"is_recruiter_approved"}
                                  onChange={(e) =>
                                    handleStatusUpdate(e, { applicant, index })
                                  }
                                />
                              ) : null}
                              {isAdmin ? (
                                // (
                                //   <>
                                //     <FormControlLabel
                                //       control={
                                //         <Switch
                                //           checked={applicant.is_admin_approved}
                                //           disabled={!isAllowStatusUpdate}
                                //           name={"is_admin_approved"}
                                //           color={"default"}
                                //           classes={{
                                //             root: applicant.is_recruiter_approved
                                //               ? "custom_toggle_switch_style"
                                //               : "",
                                //           }}
                                //           onChange={(e) =>
                                //             handleStatusUpdate(e, {
                                //               applicant,
                                //               index,
                                //             })
                                //           }
                                //         />
                                //       }
                                //       label={"Accept"}
                                //       labelPlacement={"top"}
                                //     />
                                //     <FormControlLabel
                                //       control={
                                //         <Switch
                                //           checked={
                                //             applicant.is_recruiter_approved
                                //           }
                                //           disabled={
                                //             ROLES.recruiter === role &&
                                //             applicant.is_recruiter_approved
                                //           }
                                //           color={"default"}
                                //           classes={{
                                //             root: applicant.is_recruiter_approved
                                //               ? "custom_toggle_switch_style"
                                //               : "",
                                //           }}
                                //           name={"is_recruiter_approved"}
                                //           onChange={(e) =>
                                //             handleStatusUpdate(e, {
                                //               applicant,
                                //               index,
                                //             })
                                //           }
                                //         />
                                //       }
                                //       label={"Reject"}
                                //       labelPlacement={"top"}
                                //     />
                                //   </>
                                // )
                                <Switch
                                  checked={!applicant.is_recruiter_approved}
                                  color={"default"}
                                  classes={{
                                    root: "custom_red_toggle_switch_style",
                                  }}
                                  name={"admin_is_recruiter_approved"}
                                  onChange={(e) =>
                                    handleStatusUpdate(e, { applicant, index })
                                  }
                                />
                              ) : null}
                            </TableCell>
                          ) : null}
                          {isModuleStatus ? (
                            <TableCell align={"center"}>
                              <Tooltip
                                arrow
                                title={
                                  <Typography>
                                    <b>{"Note: "}</b>
                                    {`${
                                      applicant.rejection?.reject_note ||
                                      "No notes for the rejection"
                                    }`}
                                  </Typography>
                                }
                                classes={{
                                  tooltip: "reject_note_tooltip_custom_style",
                                  arrow:
                                    "reject_note_tooltip_arrow_custom_style",
                                }}
                              >
                                <Typography style={{ cursor: "pointer" }}>
                                  {applicant.rejection?.reject_module ?? "-"}
                                </Typography>
                              </Tooltip>
                            </TableCell>
                          ) : null}
                          {isReject(role) ? (
                            <TableCell align={"center"}>
                              <Button
                                variant={"contained"}
                                color={"secondary"}
                                size={"small"}
                                onClick={() => handleRejectClick(applicant)}
                              >
                                {"Reject"}
                              </Button>
                            </TableCell>
                          ) : null}
                          <TableCell align={"center"}>
                            <IconButton
                              color={"primary"}
                              onClick={() => handleViewHistory(applicant?.id)}
                            >
                              <History />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      );
                    }
                  )
                ) : (
                  <TableRow>
                    <TableCell align={"center"} colSpan={12}>
                      {loading ? (
                        <Loader />
                      ) : (
                        <Typography>
                          <b>No Result Found!</b>
                        </Typography>
                      )}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </StyledTableContainer>
        </Grid>
        <Grid
          item
          xs={12}
          container
          justifyContent={isManager ? "space-between" : "flex-end"}
          alignItems="center"
        >
          {isManager ? (
            <Typography style={{ padding: "10px 0" }}>
              <b>{"Note: "}</b>
              {
                "If you wish to send the profiles to hiring manager, make sure to add the emails in the settings page."
              }
            </Typography>
          ) : null}
          <Typography>
            Total Count: <b>{totalCount}</b>
          </Typography>
        </Grid>
        <Grid item xs={2} container alignItems="center">
          <FormControl style={{ width: 120 }}>
            <InputLabel id="demo-simple-select-label">Rows per page</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={pagination.items}
              onChange={handlePageLimitChange}
            >
              {Options.map(({ id, value }) => (
                <MenuItem key={id} value={value}>
                  {value}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid
          item
          xs={10}
          container
          alignItems="center"
          justifyContent="flex-end"
        >
          <Pagination
            color={"primary"}
            page={pagination.activePage}
            onChange={onSelectPage}
            count={Math.ceil(totalCount / pagination.items)}
            showFirstButton
            showLastButton
          />
        </Grid>
        <Grid item xs={12}>
          <ResumeHeaderReport
            skillMatchSkillObj={reportData}
            resumeNames={resumeNames}
            skillValues={skills}
            weightage={weightage}
            hide
            needCount={false}
            isSkillClicked={isSkillClicked}
            title={`Qualified Candidates(${jobCode})`}
          />
        </Grid>
      </Grid>
      {skillMatchPDFData ? (
        <QualifySkillMatchReport
          data={skillMatchPDFData}
          setData={setSkillMatchPDFData}
          jobCode={jobCode}
        />
      ) : null}
      {coverSheetFormData ? (
        <CoverSheetPDF
          data={coverSheetFormData}
          setData={setCoverSheetFormData}
          jobCode={jobCode}
        />
      ) : null}
      <CustomSnack
        open={snack.open}
        color={snack.color}
        message={snack.message}
        onClose={handleSnackClose}
      />
      <SimpleModal
        open={modalOpen}
        title={"Reject Applicant"}
        saveButtonText={"Submit"}
        handleClose={handleModalClose}
        handleSave={handleReject}
        maxWidth={"md"}
      >
        <TextField
          name={"rejectNote"}
          label={"Reject Note"}
          value={rejectNote || ""}
          onChange={handleChange}
          variant={"outlined"}
          multiline
          fullWidth
        />
      </SimpleModal>
      <SendToManagerPopup
        title={
          findIsClient() || isManager
            ? "Send To Hiring Manager"
            : "Send To Client Manager"
        }
        open={sendToManagerPopupOpen}
        options={managerEmailList}
        toEmail={sendToManagerEmail}
        handleClose={handleSentToManagerPopupClose}
        handleSubmit={handleSentToManager}
        handleEmailChange={handleEmailChange}
        loading={sendToManagerLoading}
      />
    </Container>
  );
}

export default Qualify;
