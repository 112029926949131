import { Box, Container, Grid, TextField } from "@material-ui/core";
import React, { useEffect, useState } from "react";

import axios from "axios";
import { useHistory, useLocation } from "react-router-dom";
import { reportExportOrSendEmail, updateSubmissionReport } from "../../api/api";
import BackNavigator from "../../commonComponents/BackNavigator/BackNavigator";
import MaterialTable from "../../commonComponents/MaterialTable/CustomMaterialTable";
import PageLoader from "../../commonComponents/PageLoader";
import CustomSnack from "../../commonComponents/Snack/CustomSnack";
import { formatDate } from "../../utils/constants/helpers";
import { navigateTo } from "../../utils/constants/routePaths";
import ReportLoadingMessage from "./ReportLoadingMessage";

const customEditField = ({
  value,
  onChange,
  columnDef: {
    cellStyle: { minWidth },
  },
}) => {
  return (
    <Box minWidth={minWidth}>
      <TextField
        value={value}
        onChange={({ target: { value } }) => onChange(value)}
        size={"small"}
        fullWidth
        multiline
      />
    </Box>
  );
};

function SubmissionReport() {
  const columns = [
    {
      field: "Sl#",
      title: "Sl#",
      editable: "never",
      cellStyle: {
        minWidth: 110,
        textAlign: "center",
      },
    },
    {
      field: "Date",
      title: "Date",
      editable: "never",
      cellStyle: {
        minWidth: 115,
        textAlign: "center",
      },
    },
    {
      field: "SDG",
      title: "SDG",
      editable: "never",
      cellStyle: {
        minWidth: 80,
        textAlign: "center",
      },
    },
    {
      field: "Pod",
      title: "Pod",
      editable: "never",
      cellStyle: {
        minWidth: 85,
        textAlign: "center",
      },
    },
    {
      field: "Req#",
      title: "Req#",
      editable: "never",
      cellStyle: {
        minWidth: 120,
        textAlign: "center",
      },
    },
    {
      field: "Title",
      title: "Title",
      editable: "never",
      cellStyle: {
        minWidth: 290,
        textAlign: "center",
      },
    },
    {
      field: "Candidate Name",
      title: "Candidate Name",
      editable: "never",
      cellStyle: {
        minWidth: 290,
        textAlign: "center",
      },
    },
    {
      field: "Contact",
      title: "Contact",
      editable: "never",
      cellStyle: {
        minWidth: 150,
        textAlign: "center",
      },
    },
    {
      field: "Email ID",
      title: "Email ID",
      editable: "never",
      cellStyle: {
        minWidth: 150,
        textAlign: "center",
      },
    },
    {
      field: "Current Org",
      title: "Current Org",
      editable: "never",
      cellStyle: {
        minWidth: 290,
        textAlign: "center",
      },
    },
    {
      field: "Total Exp",
      title: "Total Exp",
      editable: "never",
      cellStyle: {
        minWidth: 120,
        textAlign: "center",
      },
    },
    {
      field: "Rel Exp",
      title: "Rel Exp",
      editable: "never",
      cellStyle: {
        minWidth: 115,
        textAlign: "center",
      },
    },
    {
      field: "CCTC",
      title: "CCTC",
      editable: "never",
      cellStyle: {
        minWidth: 110,
        textAlign: "center",
      },
    },
    {
      field: "ECTC",
      title: "ECTC",
      editable: "never",
      cellStyle: {
        minWidth: 110,
        textAlign: "center",
      },
    },
    {
      field: "Notice period",
      title: "Notice period",
      editable: "never",
      cellStyle: {
        minWidth: 140,
        textAlign: "center",
      },
    },
    {
      field: "Qualification",
      title: "Qualification",
      editable: "never",
      cellStyle: {
        minWidth: 180,
        textAlign: "center",
      },
    },
    {
      field: "Current Location",
      title: "Current Location",
      editable: "never",
      cellStyle: {
        minWidth: 180,
        textAlign: "center",
      },
    },
    {
      field: "Preferred Location",
      title: "Preferred Location",
      editable: "never",
      cellStyle: {
        minWidth: 190,
        textAlign: "center",
      },
    },
    {
      field: "Remarks/offers in pipeline etc",
      title: "Remarks/offers in pipeline etc",
      cellStyle: {
        minWidth: 270,
        textAlign: "center",
      },
      editComponent: customEditField,
    },
  ];

  const {
    state: {
      selected_job,
      report_type,
      qualified_from,
      qualified_to,
      time_period,
      selected_client
    } = {},
  } = useLocation();
  const { push: navigate } = useHistory();

  const [snack, setSnack] = useState({
    open: false,
    color: "",
    message: "",
  });
  const [submissionReportData, setSubmissionReportData] = useState(null);

  const handleSnackClose = () => {
    setSnack({
      open: false,
      color: "",
      message: "",
    });
  };

  const fetchReport = () => {
    let formData = new FormData();
    let selectedJob = `[${selected_job.map((el) => `"${el.job_code}"`)}]`;
    formData.append("job_code", selectedJob);
    formData.append("client_name", selected_client.client_name);
    formData.append("report_type", "submission_view");
    formData.append("qualified_from", formatDate(qualified_from, "YYYY-MM-DD"));
    formData.append("qualified_to", formatDate(qualified_to, "YYYY-MM-DD"));
    formData.append("time_period", time_period?.value);
    axios
      .post(reportExportOrSendEmail(), formData)
      .then((response) => {
        if (response.status === 200 && Array.isArray(response.data)) {
          setSubmissionReportData(response.data);
        } else {
          setSubmissionReportData([]);
          setSnack({
            open: true,
            message: response.data?.Error_Message || "Something went wrong",
            color: "error",
          });
        }
      })
      .catch((err) => {
        setSubmissionReportData([]);
        setSnack({
          open: true,
          message: "Something went wrong",
          color: "error",
        });
      });
  };

  const tableContentScroll = () => {
    document.querySelector("body").addEventListener("click", (event) => {
      try {
        var editButtonElements = [
          ...document.querySelectorAll("tbody tr:not([mode]) td button"),
        ];
        var editApproveOrCancelButtonElements = [
          ...document.querySelectorAll("tbody tr[mode] td button"),
        ];
        var tableRows = [...document.querySelectorAll("tbody tr")];

        if (editButtonElements.some((el) => el.contains(event.target))) {
          for (let el of tableRows) {
            if (el.contains(event.target)) {
              let scrollEl = el.querySelector("td:last-of-type");
              scrollEl.scrollIntoView({
                behavior: "smooth",
                block: "nearest",
                inline: "center",
              });
            }
          }
        } else if (
          editApproveOrCancelButtonElements.some((el) =>
            el.contains(event.target)
          )
        ) {
          for (let el of tableRows) {
            if (el.contains(event.target)) {
              let scrollEl = el.querySelector("td:nth-of-type(2)");
              scrollEl.scrollIntoView({
                behavior: "smooth",
                block: "nearest",
                inline: "center",
              });
            }
          }
        }
      } catch (error) {
        console.log(error, "error");
      }
    });
  };

  useEffect(() => {
    if (selected_job) {
      fetchReport();
      tableContentScroll();
    } else {
      handleBack();
    }
  }, []);

  const handleSaveReport = (newData, dataUpdate) => {
    let payload = [
      {
        applicant_id: newData["applicant_id"],
        "Remarks/offers in pipeline etc":
          newData["Remarks/offers in pipeline etc"],
      },
    ];
    axios
      .post(updateSubmissionReport(), payload)
      .then((response) => {
        if (response.status === 201) {
          setSubmissionReportData(dataUpdate);
          setSnack({
            open: true,
            message: "Submission report updated  successfully",
            color: "success",
          });
        } else {
          setSnack({
            open: true,
            message: "Something went wrong",
            color: "error",
          });
        }
      })
      .catch((err) => {
        setSnack({
          open: true,
          message: "Something went wrong",
          color: "error",
        });
      });
  };

  const handleBack = () => {
    let obj = {
      selected_job,
      report_type,
      qualified_from,
      qualified_to,
      time_period,
      selected_client
    };
    navigate({ pathname: navigateTo.reports, state: obj });
  };

  const handleRowUpdate = (newData, oldData) =>
    new Promise((resolve, reject) => {
      setTimeout(() => {
        let dataUpdate = [...submissionReportData];
        let index = oldData.tableData.id;
        dataUpdate[index] = newData;
        handleSaveReport(newData, dataUpdate);

        resolve();
      }, 1000);
    });

  return (
    <Container
      className={"container-style mt-5"}
      style={{ maxWidth: "1440px" }}
    >
      {submissionReportData ? (
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <BackNavigator
              handleBack={handleBack}
              title={"Submission Report"}
            />
          </Grid>
          <Grid item xs={12}>
            <MaterialTable
              columns={columns}
              data={submissionReportData}
              editable={{
                onRowUpdate: handleRowUpdate,
              }}
              options={{
                pageSize: 10,
                pageSizeOptions: [10, 25, 50],
                showTitle: false,
              }}
              localization={{
                body: {
                  emptyDataSourceMessage: (
                    <p className={"table_empty_text"}>{"Data not found"}</p>
                  ),
                },
              }}
            />
          </Grid>
          <Grid item xs />
        </Grid>
      ) : (
        <PageLoader>
          <ReportLoadingMessage />
        </PageLoader>
      )}
      <CustomSnack
        open={snack.open}
        color={snack.color}
        message={snack.message}
        onClose={handleSnackClose}
      />
    </Container>
  );
}

export default SubmissionReport;
