const COVER_SHEET_FORM = {
  personal_details: {},
  education: [],
  certification: [],
  project_details: [],
  skill_details: [],
  hobbies: {},
  current_employer: {},
  previous_employer: [],
  currentLocation: "",
  reLocation: "",
  workOption: "",
  variablePay: "",
  annualSalary: "",
  currentCtc: "",
  currentNoticePeriod: "",
  backgroundCheck: "",
  past_experience: {},
  employment_change: {},
};

const coverSheetDataFormat = async (response) => {
  let formattedObj = {
    ...COVER_SHEET_FORM,
  };
  await response.data.map((el) => {
    formattedObj[el.title] = el.answer;
  });

  // Skill Details
  let arr = [];
  let parsed_skill_details = formattedObj.skill_details
    ? JSON.parse(formattedObj.skill_details)
    : null;
  if (parsed_skill_details && parsed_skill_details.length) {
    arr = [...parsed_skill_details];
  }

  // Project Details
  let projectDetailsArr = [];
  let parsed_project_details = formattedObj.project_details
    ? JSON.parse(formattedObj.project_details)
    : null;
  if (parsed_project_details && parsed_project_details.length) {
    projectDetailsArr = [...parsed_project_details];
    for (let i = 0; i < parsed_project_details.length; i++) {
      if (
        parsed_project_details[i]["project_details"] &&
        parsed_project_details[i]["project_details"].length
      ) {
        for (
          let j = 0;
          j < parsed_project_details[i]["project_details"].length;
          j++
        ) {
          let duration =
            projectDetailsArr[i]["project_details"][j]["duration"]?.split(
              " - "
            );
          let durationStartDate = duration?.[0];
          let durationEndDate = duration?.[1];
          projectDetailsArr[i]["project_details"][j]["duration_from"] =
            durationStartDate ? durationStartDate : "";
          projectDetailsArr[i]["project_details"][j]["duration_to"] =
            durationEndDate ? durationEndDate : "";
        }
      }
    }
  }

  // Education value set
  let parsed_education = formattedObj.education
    ? JSON.parse(formattedObj.education)
    : null;
  if (parsed_education && parsed_education.length) {
    formattedObj.education = parsed_education;
  }

  // Certification value set
  let parsed_certification = formattedObj.certification
    ? JSON.parse(formattedObj.certification)
    : null;
  if (parsed_certification && parsed_certification.length) {
    formattedObj.certification = parsed_certification;
  }

  // Employment history
  let parsed_employment_history = formattedObj.employment_history
    ? JSON.parse(formattedObj.employment_history)
    : null;
  if (parsed_employment_history && parsed_employment_history.length) {
    let employment_history_obj = { ...parsed_employment_history[0] };

    // Current employer value set
    if (employment_history_obj.current_employer) {
      let obj = { ...employment_history_obj.current_employer };
      let duration = obj["duration"]?.split(" - ");
      let durationStartDate = duration?.[0];
      let durationEndDate = duration?.[1];
      obj["duration_from"] = durationStartDate || "";
      obj["duration_to"] = durationEndDate || "";
      formattedObj.current_employer = obj;
    }

    // Previous employer value set
    if (
      employment_history_obj.previous_employer &&
      Array.isArray(employment_history_obj.previous_employer) &&
      employment_history_obj.previous_employer.length
    ) {
      let arr = [...employment_history_obj.previous_employer];
      for (
        let i = 0;
        i < employment_history_obj.previous_employer.length;
        i++
      ) {
        let duration = arr[i]["duration"]?.split(" - ");
        let durationStartDate = duration?.[0];
        let durationEndDate = duration?.[1];
        arr[i]["duration_from"] = durationStartDate || "";
        arr[i]["duration_to"] = durationEndDate || "";
      }

      formattedObj.previous_employer = arr;
    }
    delete formattedObj.employment_history;
  }

  // Personal details
  let parsed_personal_details = formattedObj.personal_details
    ? JSON.parse(formattedObj.personal_details)
    : null;
  if (parsed_personal_details && parsed_personal_details.length) {
    formattedObj.personal_details = parsed_personal_details[0];
  } else {
    formattedObj.personal_details = {};
  }

  // Hobbies
  let parsed_hobbies = formattedObj.hobbies
    ? JSON.parse(formattedObj.hobbies)
    : null;
  if (parsed_hobbies && parsed_hobbies.length) {
    formattedObj.hobbies = parsed_hobbies[0];
  }

  // Past Experience change
  let parsed_past_experience = formattedObj.past_experience
    ? JSON.parse(formattedObj.past_experience)
    : null;
  if (parsed_past_experience) {
    formattedObj.past_experience = parsed_past_experience;
  }

  // Employment change
  let parsed_employment_change = formattedObj.employment_change
    ? JSON.parse(formattedObj.employment_change)
    : null;
  if (parsed_employment_change) {
    formattedObj.employment_change = parsed_employment_change;
  }

  return {
    ...formattedObj,
    skill_details: arr,
    project_details: projectDetailsArr,
  };
};

export default coverSheetDataFormat;
