import { Box, Container } from "@material-ui/core";
import Step from "@material-ui/core/Step";
import StepContent from "@material-ui/core/StepContent";
import StepLabel from "@material-ui/core/StepLabel";
import Stepper from "@material-ui/core/Stepper";
import Typography from "@material-ui/core/Typography";
import { History } from "@material-ui/icons";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { getApplicantByID, getApplicantHistory } from "../../api/api";
import BackNavigator from "../../commonComponents/BackNavigator/BackNavigator";
import PageLoader from "../../commonComponents/PageLoader";
import CustomSnack from "../../commonComponents/Snack/CustomSnack";
import { MESSAGES } from "../../utils/constants/constants";
import { errorResponse } from "../../utils/constants/helpers";
import { Alert } from "@material-ui/lab";

axios.defaults.xsrfHeaderName = "X-CSRFToken";
axios.defaults.xsrfCookieName = "csrftoken";

function ApplicantHistory() {
  const { jobCode, applicantId } = useParams();
  const history = useHistory();
  const { reference_name } = history.location.state || {};

  const [historyList, setHistoryList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [snack, setSnack] = useState({
    open: false,
    color: "",
    message: "",
  });
  const [applicantDetails, setApplicantDetails] = useState(null);

  const fetchApplicantHistoryList = async () => {
    try {
      const res = await axios.get(getApplicantHistory(), {
        params: {
          applicant_id: applicantId,
        },
      });
      return res;
    } catch (error) {
      return errorResponse(error);
    }
  };

  const handleSnackClose = () => {
    setSnack({
      open: false,
      color: "",
      message: "",
    });
  };

  const handleSnackOpen = (message = MESSAGES.error, color = "error") => {
    setSnack({
      open: true,
      color: color,
      message: message || MESSAGES.error,
    });
  };

  const getAndSetApplicantHistoryList = async () => {
    setLoading(true);
    const response = await fetchApplicantHistoryList();
    if (response.status === 200) {
      setHistoryList(response.data);
    } else {
      handleSnackOpen(response.message);
    }
    setLoading(false);
  };

  const getApplicant = () => {
    axios.get(getApplicantByID(applicantId)).then((response) => {
      if (response.status === 200) {
        setApplicantDetails(response.data);
      }
    });
  };

  useEffect(() => {
    getAndSetApplicantHistoryList();
    getApplicant();
  }, []);

  const handleBack = () => {
    history.push(`/dashboard/${jobCode}/${reference_name || "applicants"}`);
  };

  return (
    <Container maxWidth={"md"} className={"mt-8 mb-8"}>
      <Box
        display={"flex"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <BackNavigator handleBack={handleBack} title={"Applicant History"} />
        {applicantDetails ? (
          <Typography>
            <b>{`${applicantDetails.firstname}${applicantDetails.lastname ? ` ${applicantDetails.lastname}` : ""
              } (${applicantDetails.job_code})`}</b>
          </Typography>
        ) : null}
      </Box>
      {loading ? (
        <PageLoader />
      ) : (
        <Box className={"card"} mt={2}>
          {historyList.length ? (
            <Box
              pl={3}
              display={"flex"}
              justifyContent={"center"}
              position={"relative"}
              left={"12%"}
            >
              <Stepper orientation={"vertical"}>
                {historyList.map(({ date, comment }, index) => {
                  let odd = (index + 1) % 2;
                  return (
                    <Step
                      key={`step-${index}`}
                      active={true}
                      className={"relative"}
                    >
                      <StepLabel
                        StepIconComponent={() => <History color={"primary"} />}
                      >
                        <Typography
                          className={odd ? "history-stepper-content-left" : ""}
                        >
                          <b>{comment}</b>
                        </Typography>
                      </StepLabel>
                      <StepContent>
                        <Typography
                          className={odd ? "history-stepper-content-left" : ""}
                        >
                          {date}
                        </Typography>
                      </StepContent>
                    </Step>
                  );
                })}
              </Stepper>
            </Box>
          ) : (
            <Box minHeight={"400px"} p={5}>
              <Alert severity={"info"}>
                <p className={"text-base font-bold"}>
                  {`Applicant history has not yet been discovered`}
                </p>
              </Alert>
            </Box>
          )}
        </Box>
      )}
      <CustomSnack
        open={snack.open}
        color={snack.color}
        message={snack.message}
        onClose={handleSnackClose}
      />
    </Container>
  );
}

export default ApplicantHistory;
